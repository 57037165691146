<div class="ml-3 mr-3 flex items-end">
    <app-select
        title="{{ 'PERIOD' | translate }}"
        [options]="periodOptions"
        [initialValue]="statisticsRange"
        (selectionChange)="changeFilter('statisticsRange', $event)"
        class="mx-1 w-full"></app-select>
    <app-select
        title="{{ 'GAME_TYPE' | translate }}"
        [options]="gameTypeOptions"
        [initialValue]="statisticsGameType"
        (selectionChange)="changeFilter('statisticsGameType', $event)"
        class="mx-1 w-full"></app-select>
    <app-select
        title="{{ 'STARTSCORE' | translate }}"
        [options]="startScoreOptions"
        [initialValue]="startScoreOption"
        (selectionChange)="changeFilter('startScoreOption', $event)"
        class="mx-1 w-full"></app-select>
</div>

<div>
    @if (statisticsRange === 'custom' || startScoreOption === 'custom') {
        <ion-row style="color: white; margin: 10px 10px 0">
            @if (statisticsRange === 'custom') {
                <ion-col style="padding: 0; display: flex">
                    @if (auth.user.is_ultimate) {
                        <ion-col style="padding: 0">
                            <ion-select
                                style="overflow: auto; padding: 0px 12px; font-size: 13px; margin-top: 4px"
                                interface="popover"
                                [(ngModel)]="year"
                                (ionChange)="changeStatisticsRange('custom')">
                                <ion-select-option *ngFor="let yearOption of years" [value]="yearOption">
                                    {{ yearOption }}
                                </ion-select-option>
                            </ion-select>
                        </ion-col>
                        <ion-col style="padding: 0">
                            <ion-select
                                style="overflow: auto; padding: 0px 12px; font-size: 13px; margin-top: 4px"
                                interface="popover"
                                [(ngModel)]="month"
                                (ionChange)="changeStatisticsRange('custom')">
                                <ion-select-option *ngFor="let monthOption of months" [value]="monthOption">
                                    {{ monthOption }}
                                </ion-select-option>
                            </ion-select>
                        </ion-col>
                    } @else {
                        <ion-col style="padding: 0" (click)="OpenUltimateDialog()">
                            <ion-select
                                style="overflow: auto; padding: 0px 12px; font-size: 13px; margin-top: 4px"
                                interface="popover"
                                disabled
                                [(ngModel)]="year">
                                <ion-select-option *ngFor="let yearOption of years" [value]="yearOption">
                                    {{ yearOption }}
                                </ion-select-option>
                            </ion-select>
                        </ion-col>
                        <ion-col style="padding: 0" (click)="OpenUltimateDialog()">
                            <ion-select
                                style="overflow: auto; padding: 0px 12px; font-size: 13px; margin-top: 4px"
                                interface="popover"
                                disabled
                                [(ngModel)]="month">
                                <ion-select-option *ngFor="let monthOption of months" [value]="monthOption">
                                    {{ monthOption }}
                                </ion-select-option>
                            </ion-select>
                        </ion-col>
                    }
                </ion-col>
            }
            @if (statisticsRange !== 'custom') {
                <ion-col></ion-col>
            }

            <ion-col></ion-col>

            <ion-col style="padding: 0" *ngIf="startScoreOption === 'custom'">
                <ion-col style="padding: 0" *ngIf="auth.user.is_ultimate" (click)="changeStartScore()">
                    <div style="overflow: auto; font-size: 13px; width: 100%; text-align: center">{{ startScore }}</div>
                </ion-col>
                <ion-col
                    style="padding: 0"
                    *ngIf="!auth.user.is_ultimate"
                    class="text-center"
                    (click)="OpenUltimateDialog()">
                    <span style="overflow: auto; font-size: 13px; width: 100%; text-align: center">{{
                        startScore
                    }}</span>
                </ion-col>
            </ion-col>
            <ion-col *ngIf="startScoreOption !== 'custom'"></ion-col>
        </ion-row>
    }

    <ion-row *ngIf="resultWidths" style="margin: 5px 10px">
        <ion-col class="head-to-head-results mt-2">
            <div class="chart">
                <span class="block" [style.width.%]="resultWidths[0]">
                    <span class="inner wins1">
                        <ion-spinner
                            style="height: 18px; margin-top: 3.5px"
                            name="dots"
                            color="light"
                            *ngIf="!loadedOverallStats"></ion-spinner>
                        <span class="value" *ngIf="loadedOverallStats">{{ overallStats.results.wins }}</span>
                    </span>
                </span>
                <span class="block" [style.width.%]="resultWidths[1]">
                    <span class="inner draws">
                        <ion-spinner
                            style="height: 18px; margin-top: 3.5px"
                            name="dots"
                            color="light"
                            *ngIf="!loadedOverallStats"></ion-spinner>
                        <span class="value" *ngIf="loadedOverallStats">{{ overallStats.results.draws }}</span>
                    </span>
                </span>
                <span class="block" [style.width.%]="resultWidths[2]">
                    <span class="inner wins2">
                        <ion-spinner
                            style="height: 18px; margin-top: 3.5px"
                            name="dots"
                            color="light"
                            *ngIf="!loadedOverallStats"></ion-spinner>
                        <span class="value" *ngIf="loadedOverallStats">{{ overallStats.results.losses }}</span>
                    </span>
                </span>
            </div>

            <div class="x-axis">
                <ul class="legend">
                    <li class="wins1 text-black" [translate]="'WINS'" i18n="@@WINS">Wins</li>
                    <li class="draws text-black" [translate]="'DRAWS'" i18n="@@DRAWS">Draws</li>
                    <li class="wins2 text-black" [translate]="'LOSSES'" i18n="@@LOSSES">Losses</li>
                </ul>
            </div>
        </ion-col>
    </ion-row>

    <div class="m-3 text-black">
        <div class="grid grid-cols-9 gap-1 whitespace-nowrap text-sm">
            <div class="col-span-3"></div>
            <div class="col-span-2 text-center font-normal uppercase" [translate]="'AVG'" i18n="@@AVG">Avg.</div>
            <div class="col-span-2 text-center font-normal uppercase" [translate]="'BEST'" i18n="@@BEST">Best</div>
            <div class="col-span-2 text-center font-normal uppercase" [translate]="'WORST'" i18n="@@WORST">Worst</div>
        </div>

        <!-- 501  -->
        <div class="game-stat-main-table-container">
            <div class="col-span-3" [translate]="'AVERAGE'" i18n="@@AVERAGE">3-dart avg.</div>
            <div class="col-span-2 py-1 text-center text-sm">
                @if (!loadedOverallStats) {
                    <app-loader mode="dark" size="6"></app-loader>
                } @else {
                    {{ overallStats.threeDartAvg.average || 0 | number: '1.2-2' }}
                }
            </div>
            <div
                class="game-specific-detail-stat"
                (click)="goToDetails('threeDartAvg', 'best')"
                joyrideStep="firstStep"
                title="{{ 'STATISTICS' | translate }}"
                text="{{ 'TUT_TOP25' | translate }}">
                @if (!loadedOverallStats) {
                    <app-loader mode="orange" size="6"></app-loader>
                } @else {
                    {{ overallStats.threeDartAvg.best || 0 | number: '1.2-2' }}
                }
            </div>
            <div class="game-specific-detail-stat" (click)="goToDetails('threeDartAvg', 'worst')">
                @if (!loadedOverallStats) {
                    <app-loader mode="orange" size="6"></app-loader>
                } @else {
                    {{ overallStats.threeDartAvg.worst || 0 | number: '1.2-2' }}
                }
            </div>
        </div>

        <div class="relative">
            <div class="game-stat-table-container">
                <!-- First 9 avg -->
                <div class="col-span-3" [translate]="'FIRST_9_AVG'" i18n="@@FIRST_9_AVG">First 9 avg.</div>
                <div class="col-span-2 py-1 text-center text-sm">
                    @if (!loadedOverallStats) {
                        <app-loader mode="dark" size="6"></app-loader>
                    } @else {
                        {{ overallStats.firstNineAvg.average || 0 | number: '1.2-2' }}
                    }
                </div>
                <div
                    class="game-specific-detail-stat"
                    (click)="goToDetails('firstNineAvg', 'best')"
                    joyrideStep="firstStep"
                    title="{{ 'STATISTICS' | translate }}"
                    text="{{ 'TUT_TOP25' | translate }}">
                    @if (!loadedOverallStats) {
                        <app-loader mode="orange" size="6"></app-loader>
                    } @else {
                        {{ overallStats.firstNineAvg.best || 0 | number: '1.2-2' }}
                    }
                </div>
                <div class="game-specific-detail-stat" (click)="goToDetails('firstNineAvg', 'worst')">
                    @if (!loadedOverallStats) {
                        <app-loader mode="orange" size="6"></app-loader>
                    } @else {
                        {{ overallStats.firstNineAvg.worst || 0 | number: '1.2-2' }}
                    }
                </div>

                <!-- Checkout rate -->
                <div class="col-span-3" [translate]="'CHECKOUT_RATE'" i18n="@@CHECKOUT_RATE">Checkout rate</div>
                <div class="col-span-2 py-1 text-center text-sm">
                    @if (!loadedOverallStats) {
                        <app-loader mode="dark" size="6"></app-loader>
                    } @else {
                        {{ overallStats.checkoutRate.average || 0 | number: '1.2-2' }}%
                    }
                </div>
                <div class="game-specific-detail-stat" (click)="goToDetails('checkoutRate', 'best')">
                    @if (!loadedOverallStats) {
                        <app-loader mode="orange" size="6"></app-loader>
                    } @else {
                        {{ overallStats.checkoutRate.best || 0 | number: '1.2-2' }}%
                    }
                </div>
                <div class="game-specific-detail-stat" (click)="goToDetails('checkoutRate', 'worst')">
                    @if (!loadedOverallStats) {
                        <app-loader mode="orange" size="6"></app-loader>
                    } @else {
                        {{ overallStats.checkoutRate.worst || 0 | number: '1.2-2' }}%
                    }
                </div>

                <!-- Checkouts -->
                <div class="col-span-3" [translate]="'CHECKOUTS'" i18n="@@CHECKOUTS">Checkouts</div>
                <div class="col-span-2 py-1 text-center text-sm">
                    @if (!loadedOverallStats) {
                        <app-loader mode="dark" size="6"></app-loader>
                    } @else {
                        {{ overallStats.checkouts.average || 0 | number: '1.0-2' }}
                    }
                </div>
                <div class="game-specific-detail-stat" (click)="goToDetails('checkouts', 'best')">
                    @if (!loadedOverallStats) {
                        <app-loader mode="orange" size="6"></app-loader>
                    } @else {
                        {{ overallStats.checkouts.best || 0 }}
                    }
                </div>
                <div class="game-specific-detail-stat" (click)="goToDetails('checkouts', 'worst')">
                    @if (!loadedOverallStats) {
                        <app-loader mode="orange" size="6"></app-loader>
                    } @else {
                        {{ overallStats.checkouts.worst || 0 }}
                    }
                </div>

                <!-- Legs -->
                <div class="col-span-3" [translate]="'LEGS'" i18n="@@LEGS">Legs</div>
                <div class="col-span-2 py-1 text-center text-sm">
                    @if (!loadedOverallStats) {
                        <app-loader mode="dark" size="6"></app-loader>
                    } @else {
                        {{ overallStats.legs.average || 0 | number: '1.2-2' }}
                    }
                </div>
                <div class="game-specific-detail-stat" (click)="goToDetails('legs', 'best')">
                    @if (!loadedOverallStats) {
                        <app-loader mode="orange" size="6"></app-loader>
                    } @else {
                        {{ overallStats.legs.best || 0 }}
                    }
                </div>
                <div class="game-specific-detail-stat" (click)="goToDetails('legs', 'worst')">
                    @if (!loadedOverallStats) {
                        <app-loader mode="orange" size="6"></app-loader>
                    } @else {
                        {{ overallStats.legs.worst || 0 }}
                    }
                </div>

                <!-- Upgrade -->
                @if (auth.user.is_ultimate !== true) {
                    <div class="absolute bottom-0 left-0 right-0 top-0 grid w-full grid-cols-9">
                        <div class="locked-section top-0 col-span-6 col-start-4 rounded-md">
                            <div
                                (click)="OpenUltimateDialog()"
                                class="flex h-full flex-col items-center justify-center py-0.5">
                                <ion-icon name="lock-closed-outline" color="light"></ion-icon>
                                <ion-button
                                    size="small"
                                    color="primary"
                                    [translate]="'UPGRADE_BTN'"
                                    i18n="@@UPGRADE_BTN">
                                    Upgrade</ion-button
                                >
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
</div>
