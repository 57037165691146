<div
    class="flex relative bottom-0 w-full flex-col overflow-y-hidden overscroll-none rounded-t-lg bg-gray-50 text-black">
    <div class="relative flex items-center rounded-t-lg py-4 px-safe-offset-4">
        <ion-buttons slot="start">
            <div
                class="ml-1 text-center text-sm font-bold text-black cursor-pointer"
                [translate]="'CLOSE'"
                i18n="@@CLOSE"
                (click)="dismiss()">
                Close
            </div>
        </ion-buttons>
        <div
            class="absolute left-0 right-0 my-auto w-full text-center font-bebas-neue text-2xl text-black"
            [translate]="'OMNI_SETTINGS'"
            i18n="@@OMNI_SETTING">
            Omni settings
        </div>
    </div>
    <div class="flex flex-1 flex-col bg-gray-50 p-4 overflow-y-auto pb-safe-offset-4">
        <div class="flex flex-col space-y-2">
            <app-toggle-switch
                class="block w-full rounded-md bg-white p-3"
                [model]="omniCommunicationService.omniSettings.disableSingleDartCountdown"
                (itemChange)="omniCommunicationService.toggleOmniSetting('disableSingleDartCountdown')">
                Disable single dart countdown
            </app-toggle-switch>
        </div>

        <div class="flex justify-end my-4" (click)="refreshOmni()">
            <app-primary-button size="normal" color="orange" [translate]="'REFRESH_OMNI'" i18n="@@REFRESH_OMNI">
                Refresh OMNI
            </app-primary-button>
        </div>
    </div>
</div>
