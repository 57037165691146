import { inject, Injectable } from '@angular/core';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { environment } from 'src/environments/environment';

import { AppFeaturesService } from '../core/app-features/services/app-features.service';

@Injectable()
export class EchoService {
    public echo: Echo = null;

    private appFeaturesService: AppFeaturesService = inject(AppFeaturesService);

    init(accessToken: string) {
        if (!this.appFeaturesService.enabledAppFeatures.private_chat) {
            return;
        }

        if (!this.echo && accessToken) {
            (window as any).Pusher = Pusher;

            this.echo = new Echo({
                broadcaster: 'reverb',
                key: environment.echo.key,
                wsHost: environment.echo.wsHost,
                wsPort: environment.echo.wsPort,
                wssPort: environment.echo.wssPort,
                forceTLS: environment.echo.scheme === 'https',
                enabledTransports: ['ws', 'wss'],
                authEndpoint: environment.apiUrl + '/broadcasting/auth',
                auth: {
                    headers: {
                        Authorization: 'Bearer ' + accessToken,
                    },
                },
            });
        }
    }

    listen(channel: string, event: string, callback: any) {
        if (!this.appFeaturesService.enabledAppFeatures.private_chat) {
            return;
        }

        this.echo.channel(channel).listen(event, (data: any) => {
            callback(data);
        });
    }

    privateChannel(channel: string, event: string, callback: any) {
        if (!this.appFeaturesService.enabledAppFeatures.private_chat) {
            return;
        }

        this.echo.private(channel).listen(event, (data: any) => {
            callback(data);
        });
    }

    leave(channel: string) {
        if (!this.appFeaturesService.enabledAppFeatures.private_chat) {
            return;
        }

        this.echo.leave(channel);
    }

    disconnect() {
        if (!this.appFeaturesService.enabledAppFeatures.private_chat) {
            return;
        }

        this.echo.disconnect();
        this.echo = null;
    }
}
