<app-page-header mainNavigation="back" defaultBack="chats" color="white">
    <div title>{{ chat?.name }}</div>
    <app-option-button right icon="more_vert" (click)="openOptionsPopover()" color="text-white"></app-option-button>
</app-page-header>

<div class="relative flex h-full flex-col bg-white-pattern overflow-hidden">
    @if (chat) {
        @if (!loaded) {
            <app-icon icon="loading" size="extralarge" class="animate-spin text-orange mx-auto mt-8"></app-icon>
        }

        <div
            class="flex-1 overflow-y-scroll overflow-x-hidden p-4 space-y-3 scrollbar-hide"
            [class.invisible]="!loaded"
            appSmallWebContainer
            appScrollToBottom
            infiniteScroll
            [scrollWindow]="false"
            (scrolledUp)="getMessages()">
            <div
                class="text-black font-bebas-neue text-xl text-center px-2 py-1"
                [translate]="'FIRST_CHAT_MESSAGE_INFO'"
                i18n="@@FIRST_CHAT_MESSAGE_INFO">
                All messages will be saved for 14 days. For your safety and privacy, please avoid sharing sensitive
                information.
            </div>

            @for (message of messages; track $index) {
                @if (message) {
                    <div
                        class="flex"
                        [ngClass]="{
                            'justify-end pl-4 flex-row-reverse': message.participant.user_id === auth.user.id,
                            'justify-start pr-4': message.participant.user_id !== auth.user.id
                        }">
                        <app-profile-photo
                            class="h-fit"
                            [size]="22"
                            [ultimate]="message.participant.user.is_ultimate"
                            [badge]="message.participant.user.profile?.badge"
                            [url]="message.participant.user.profile?.profile_photo_url"></app-profile-photo>
                        <div
                            class="w-full space-y-2"
                            [ngClass]="{
                                'mr-2': message.participant.user_id === auth.user.id,
                                'ml-2': message.participant.user_id !== auth.user.id
                            }">
                            <div
                                class="w-full bg-white py-2 px-4 text-black flex justify-between space-x-1"
                                [ngClass]="{
                                    'rounded-l-2xl rounded-br-2xl': message.participant.user_id === auth.user.id,
                                    'rounded-r-2xl rounded-bl-2xl': message.participant.user_id !== auth.user.id
                                }">
                                <div class="text-black text-sm pb-1 break-words break-all whitespace-normal">
                                    {{ message.body }}
                                </div>
                                <div class="text-neutral-400 text-xs mt-auto">
                                    {{ message.updated_at | date: 'HH:mm' }}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            }
        </div>

        <div class="flex-none bg-white pt-2 px-2 pb-safe-offset-2">
            <div class="flex space-x-2 items-center" appSmallWebContainer>
                <div class="w-full rounded-3xl py-1 pl-4 pr-4 bg-neutral-50 inline-flex items-center space-x-1">
                    <textarea
                        #chatInput
                        class="text-black placeholder:text-neutral-800 dark:placeholder:text-neutral-200 w-full !min-h-7 text-[0.9rem]"
                        style="background: none; border: none; resize: none"
                        matInput
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="3"
                        [placeholder]="chatPlaceholder"
                        [(ngModel)]="chatMessage"
                        (keydown)="onKeydown($event)"
                        appMaintainHeight></textarea>
                </div>
                <div
                    class="text-orange h-8 w-8 inline-flex justify-center items-center cursor-pointer"
                    (click)="sendChatMessage()">
                    <app-icon icon="send"></app-icon>
                </div>
            </div>
        </div>
    }
</div>
