<app-dialog-view [showCloseButton]="true" (onDismiss)="DismissModal()">
    <span title [translate]="'PLAYER_OPTIONS'" i18n="@@PLAYER_OPTIONS"> Player options </span>

    @if (user) {
        <ion-list class="centerList text-black" *ngIf="user">
            <ion-row>
                <ion-col>
                    <ion-avatar>
                        <app-profile-photo
                            [ultimate]="user.is_ultimate"
                            [badge]="user.profile?.badge"
                            [url]="user.profile?.profile_photo_url">
                        </app-profile-photo>
                    </ion-avatar>
                </ion-col>
                <ion-col>
                    <ion-row>
                        <ion-col class="noPadding">
                            <div class="name capitalize" *ngIf="user.id > 0">{{ user.first_name }}</div>
                            <div *ngIf="!user.id">
                                <ion-label [translate]="'NAME_OF_PLAYER'" i18n="@@NAME_OF_PLAYER"
                                    >Player name</ion-label
                                >
                                <ion-input
                                    name="playerName"
                                    ngDefaultControl
                                    [(ngModel)]="user.first_name"
                                    type="text"></ion-input>
                            </div>
                        </ion-col>
                    </ion-row>
                    <ion-row *ngIf="user.id > 0">
                        <ion-col class="noPadding">
                            <p class="avgTitle">{{ '501_AVG' | translate }}</p>
                            <h4 class="avgValue">{{ average || 0 | number: '1.2-2' }}</h4>
                        </ion-col>
                    </ion-row>
                    <ion-row *ngIf="user.id > 0 && user.is_ultimate">
                        <ion-col class="noPadding sounds">
                            <div class="centerFlex" *ngIf="!user.profile?.sound">
                                <ion-icon name="volume-mute"></ion-icon>
                                <ion-button
                                    (click)="ShowLinkSound()"
                                    color="primary"
                                    size="small"
                                    [translate]="'LINK_SOUND'"
                                    i18n="@@LINK_SOUND">
                                    Link name
                                </ion-button>
                            </div>
                            <div class="centerFlex" *ngIf="user.profile?.sound">
                                <ion-icon name="volume-high-outline"></ion-icon>
                                <ion-button
                                    (click)="RemoveSound()"
                                    color="dark"
                                    size="small"
                                    [translate]="'UNLINK'"
                                    i18n="@@UNLINK">
                                    Unlink
                                </ion-button>
                            </div>
                        </ion-col>
                    </ion-row>
                </ion-col>
            </ion-row>
        </ion-list>
        @if (!user.id) {
            <div class="button-container">
                <app-primary-button
                    size="normal"
                    addedClasses="w-full"
                    color="black"
                    [translate]="'CONFIRM'"
                    i18n="@@CONFIRM"
                    (click)="DismissModal(user)">
                    Confirm
                </app-primary-button>
            </div>
        }
    }
</app-dialog-view>
