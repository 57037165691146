import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SoundApiService } from '@dc-api/sound.api.service';
import { Sound, User } from '@dc-core/dc-backend/dc-classes';
import { SoundPath } from '@dc-core/dc-backend/dc-enums';
import { DartCounterAudioService } from '@dc-core/dc-services/audio/audio.service';
import { NavController } from '@ionic/angular';
import { ModalController, Platform } from '@ionic/angular';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { DartCounterAlertService } from 'dc-core/dc-services/alert.service';
import { take } from 'rxjs';
import { GA_EVENTACTIONS, GA_EVENTCATEGORIES } from 'src/app/app.globals';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/services/auth.service';

import { UltimateSubscriptionDialogComponent } from '../ultimate-subscription/ultimate-subscription';

@Component({
    selector: 'app-link-sound-dialog',
    templateUrl: 'link-sound.html',
    styleUrls: ['link-sound.scss'],
})
export class LinkSoundDialogComponent {
    user: User;
    sounds: Sound[] = [];
    public searchString = '';
    selectedSound: Sound;
    public searchLoading = false;
    public relink = false;
    public hideImage;

    constructor(
        public platform: Platform,
        public view: ModalController,
        public nav: NavController,
        public auth: AuthService,
        private ga: DartCounterAnalyticsService,
        private dcAudio: DartCounterAudioService,
        public modal: ModalController,
        public _modal: ModalController,
        public route: ActivatedRoute,
        private _alertService: DartCounterAlertService,
        private soundApiService: SoundApiService
    ) {
        this.route.queryParams.pipe(take(1)).subscribe((params) => {
            this.user = params.user;
            this.relink = params.relink;
        });
    }

    public dismiss(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }

    SearchSounds(searchString: string) {
        if (searchString.length >= 1) {
            this.searchLoading = true;

            this.soundApiService
                .getSounds({ name: searchString })
                .then((res) => {
                    this.searchLoading = false;
                    this.sounds = res.data;
                })
                .catch((e) => {
                    this.searchLoading = false;
                    this._alertService.createAlert({
                        title: e,
                        icon: 'error',
                    });
                });
        }
    }

    selectSound(sound: Sound) {
        this.selectedSound = sound;
        this.dcAudio.playAudioFromBackend(SoundPath.NAMES, sound.file);
        this.dcAudio.addDeviceToAudioStack(SoundPath.YOU_REQUIRE, '170.mp3');
    }

    LinkSound(sound: Sound) {
        if (!sound) {
            return false;
        }

        if (sound && (this.user.is_ultimate || this.user.is_admin)) {
            this.dismiss(sound);
        } else {
            this.ga.trackEvent(GA_EVENTCATEGORIES.MASTERCALLER, GA_EVENTACTIONS.OPENULTIMATE);

            this._modal
                .create({
                    component: UltimateSubscriptionDialogComponent,
                    cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
                    showBackdrop: true,
                })
                .then((elem) => elem.present());

            this.dismiss(sound);
        }
    }

    contact() {
        this.nav.navigateForward('contact');
        this.dismiss('dismiss');
    }

    UnlinkSound(): void {
        this.dismiss(false);
    }
}
