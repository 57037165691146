<modal-content scrollY="false" fullscreen class="bg-white-pattern text-black">
    <div class="auto-height-dialog">
        <app-icon icon="close" class="text-black close-button" (click)="close()"></app-icon>

        <div class="flex w-full flex-col items-center space-y-2">
            <app-profile-photo
                class="block h-full"
                [size]="72"
                [big]="true"
                [url]="user.profile?.profile_photo_url"></app-profile-photo>
            <div class="truncate font-semibold text-black text-2xl">
                {{ user.first_name || ('NO_NAME' | translate) }}
            </div>
        </div>

        <div class="mt-8 space-y-4 mx-auto flex flex-col items-center">
            <div class="flex justify-center space-x-2">
                @for (logItem of cricketTacticsTurn.logItems; track $index) {
                    <div
                        class="relative border-2 border-neutral-50 bg-neutral-50 px-2 pt-1 w-[5rem]"
                        [class.rounded-l-xl]="$index === 0"
                        [class.rounded-r-xl]="$index === cricketTacticsTurn.logItems.length - 1">
                        <app-keyboard-dart class="block w-2/3 text-black"></app-keyboard-dart>

                        <div class="in-game-score-field">{{ getMptext(logItem.multiplier) }}{{ logItem.number }}</div>

                        @if (logItem.score) {
                            <div class="absolute bottom-1 right-1 font-bebas-neue text-green">+{{ logItem.score }}</div>
                        }
                    </div>
                }
            </div>

            @if (!hitNumbers.length) {
                <div
                    class="font-bebas-neue text-2xl text-neutral-400 leading-none text-center"
                    [translate]="'NO_SCORE'"
                    i18n="@@NO_SCORE">
                    No score
                </div>
            }

            @if (score > 0) {
                <div class="flex justify-center items-center space-x-2 w-full">
                    <div
                        class="font-bebas-neue text-2xl text-black leading-none"
                        [translate]="'TOTAL_POINTS'"
                        i18n="@@TOTAL_POINTS">
                        Points
                    </div>
                    <div class="rounded-md px-1.5 py-1 bg-green flex items-center space-x-2">
                        <div class="text-white text-xl leading-none font-bebas-neue">+{{ score }}</div>
                    </div>
                </div>
            }
        </div>
    </div>
</modal-content>
