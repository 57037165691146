import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-save-or-delete-dialog',
  templateUrl: 'save-or-delete.html',
  styleUrls: ['save-or-delete.scss'],
})
export class SaveOrDeleteDialogComponent {
  constructor(public view: ModalController) {}

  public DismissModal(returnValue: any = null): void {
    this.view.dismiss(returnValue);
  }
}
