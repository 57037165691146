import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
    selector: 'app-primary-button',
    standalone: true,
    templateUrl: 'primary-button.component.html',
    imports: [CommonModule],
})
export class PrimaryButtonComponent implements OnInit, OnChanges {
    /**
     * Keep for TailwindCSS
     *
     * bg-orange border-orange text-orange hover:bg-orange-accent
     * bg-black border-black text-black hover:bg-black-accent
     * bg-red border-red text-red hover:bg-red-accent
     * bg-green border-green text-green hover:bg-green-accent
     * bg-blue border-blue text-blue hover:bg-blue-accent
     * bg-white border-white text-white hover:bg-white-accent
     */

    @Input() color: 'orange' | 'black' | 'red' | 'green' | 'blue' | 'white' = 'orange';
    @Input() size: 'large' | 'medium' | 'normal' | 'small' | 'extrasmall' = 'large';
    @Input() fill: 'full' | 'outline' | 'clear' = 'full';
    @Input() type: 'submit' | 'button' = 'button';
    @Input() disabled = false;
    @Input() bold = true;
    @Input() underline = false;
    @Input() addedClasses = '';
    @Input() addedSlotClasses = '';

    height: string;
    classes = '';
    slotClasses = '';

    ngOnInit(): void {
        this.setComponent();
    }

    ngOnChanges(): void {
        this.setComponent();
    }

    setComponent(): void {
        this.classes = '';
        this.slotClasses = '';

        switch (this.fill) {
            case 'full':
                this.classes += ' border-2';
                this.classes += ' border-' + this.color;
                this.classes += ' bg-' + this.color + ' hover:bg-' + this.color + '-accent duration-200';
                break;
            case 'outline':
                this.classes += ' border-2 border-solid';
                this.classes += ' border-' + this.color;
                this.slotClasses += ' text-' + this.color;
                break;
        }

        switch (this.size) {
            case 'large':
                this.classes += ' w-full';
                this.slotClasses += ' px-6 py-3.5 text-xl';
                break;
            case 'medium':
                this.slotClasses += ' px-3 py-3.5 text-lg';
                break;
            case 'normal':
                this.slotClasses += ' px-3 py-3 text-base';
                break;
            case 'small':
                this.slotClasses += ' px-3 py-2.5 text-sm';
                break;
            case 'extrasmall':
                this.slotClasses += ' px-3 py-2 text-xs';
                break;
        }

        if (this.fill === 'outline' && this.size === 'extrasmall') {
            this.classes += ' !border-1';
        }

        if (this.fill !== 'outline') {
            if (this.color === 'white' || this.fill === 'clear') {
                this.classes += ' text-black';
            } else {
                this.classes += ' text-white';
            }
        }

        if (this.color === 'orange' || this.color === 'blue' || this.color === 'red' || this.color === 'green') {
            this.classes += ' !text-white';
        }

        if (this.addedClasses) {
            this.classes += ' ' + this.addedClasses;
        }

        if (this.addedSlotClasses) {
            this.slotClasses += ' ' + this.addedSlotClasses;
        }
    }
}
