<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="DismissModal()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title" [translate]="'PAGE_HELP'" i18n="@@PAGE_HELP">Explanation</div>
    </div>
    <div class="slide-modal-content text-black">
        @for (slide of slides; track slide) {
            <h2 class="text-black !text-xl" translate>{{ slide.title }}</h2>
            <p class="text-black !text-md mb-4" translate>{{ slide.description }}</p>
        }
    </div>
</div>
