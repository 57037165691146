<!-- Translation containers-->
@if (false) {
    <ng-container i18n="@@SEARCH">Search</ng-container>
    <ng-container i18n="@@COUNTRY">Country</ng-container>
}
<!-- End of translation containers-->

<app-page-header [mainNavigation]="showBottomNav ? 'menu' : 'back'" defaultBack="dashboard">
    <div title [translate]="'PROFILE'" i18n="@@PROFILE">Profile</div>
    @if (loaded && user && !user.is_self) {
        <app-option-button right icon="more_vert" (click)="showPopover()" color="text-white"></app-option-button>
    }
    @if (loaded && user && user.is_self) {
        <app-option-button right icon="edit" (click)="OpenAccount()" color="text-white"></app-option-button>
    }
</app-page-header>

<div class="flex flex-col h-full overflow-hidden bg-white-pattern -mt-[1px] text-black">
    <div class="flex flex-col flex-1 overflow-y-auto">
        <div class="w-full h-8 bg-orange flex-none"></div>
        <div class="flex-none" appWebContainer>
            <div class="w-full h-8 -mt-[1.9rem] justify-end items-end flex pr-[1rem]">
                <div class="w-32">
                    <svg class="w-full h-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 159.81 40.33">
                        <path
                            [attr.fill]="themeService.theme() === 'dark' ? '#2d2d2d' : '#E9E9E9'"
                            d="M159.81,40.33H.03C18.11,15.86,47.16,0,79.92,0s61.81,15.86,79.89,40.33Z" />
                    </svg>
                </div>
            </div>
            <div class="flex items-end justify-between px-6 relative -mt-[1.9rem]">
                <div class="px-2 overflow-hidden">
                    @if (user) {
                        @if (loaded) {
                            <div class="text-xl font-bold text-black truncate">
                                {{ user.full_name }}
                            </div>
                            <div class="text-sm mt-1 font-semibold text-neutral-800 truncate">
                                {{ user.profile?.nickname || ('NO_NICKNAME' | translate) }}
                            </div>
                        }
                        @if (!loaded) {
                            <div class="text-xl">
                                <ngx-skeleton-loader [theme]="skeletonThemes.info" animation="progress-dark">
                                </ngx-skeleton-loader>
                            </div>
                            <div class="text-sm mt-1">
                                <ngx-skeleton-loader [theme]="skeletonThemes.info2" animation="progress-dark">
                                </ngx-skeleton-loader>
                            </div>
                        }
                    }
                </div>

                @if (!loaded) {
                    <ngx-skeleton-loader [theme]="skeletonThemes.profilepic" animation="progress-dark" class="mt-3">
                    </ngx-skeleton-loader>
                }
                @if (loaded && user) {
                    <ion-avatar
                        class="flex justify-center w-28 h-28 editable pt-4"
                        [class.editable]="user.is_self"
                        style="height: 8.5rem">
                        <app-profile-photo
                            class="cursor-pointer"
                            [size]="112"
                            [ultimate]="user.is_ultimate"
                            [badge]="user.is_self ? null : user?.profile?.badge"
                            [big]="true"
                            [url]="user.profile?.profile_photo_url"
                            (click)="updateProfilePhoto($event)">
                            @if (user.is_self) {
                                <div class="editButton">
                                    <ion-button class="editImage">
                                        <app-icon icon="camera_alt" size="small"></app-icon>
                                    </ion-button>
                                </div>
                            }
                        </app-profile-photo>
                    </ion-avatar>
                }
            </div>
        </div>

        @if (user && loaded) {
            @if (!user.is_self || user.is_blocked) {
                <div class="p-6 pb-0" appWebContainer>
                    @if (!user.is_blocked && !user.is_self && user?.friend_status === 'incoming') {
                        <app-primary-button
                            (click)="AcceptFriend(user?.id)"
                            fill="outline"
                            color="green"
                            class="space-y-6"
                            size="extrasmall"
                            [translate]="'ACCEPT_FRIEND'"
                            i18n="@@ACCEPT_FRIEND">
                            Accept friend
                        </app-primary-button>
                        <app-primary-button
                            (click)="DeclineFriend(user?.id)"
                            fill="outline"
                            color="red"
                            class="space-y-6 ml-1"
                            size="extrasmall"
                            [translate]="'DECLINE_FRIEND'"
                            i18n="@@DECLINE_FRIEND">
                            Decline friend
                        </app-primary-button>
                    }
                    @if (user.is_blocked) {
                        <app-primary-button
                            (click)="showPopover()"
                            fill="outline"
                            color="black"
                            class="space-y-6 ml-1"
                            size="extrasmall"
                            [translate]="'BLOCKED'"
                            i18n="@@BLOCKED">
                            Blocked
                        </app-primary-button>
                    }
                    @if (!user.is_self && user?.friend_status === 'pending') {
                        <app-primary-button
                            (click)="ShowPendingOptions(user?.id)"
                            fill="outline"
                            color="black"
                            class="space-y-6 ml-1"
                            size="extrasmall"
                            [translate]="'PENDING'"
                            i18n="@@PENDING">
                            Pending
                        </app-primary-button>
                    }
                    @if (!user.is_self && auth.user?.is_admin) {
                        <app-primary-button
                            (click)="loginAsUser()"
                            fill="outline"
                            color="black"
                            class="space-y-6 ml-1"
                            size="extrasmall">
                            Login as user
                        </app-primary-button>
                    }
                </div>
            }

            <div class="mt-5 px-4 space-y-2" appWebContainer>
                @if (loadedHeadToHead) {
                    <div
                        class="flex items-center justify-start space-x-2 text-black text-xs uppercase font-bold leading-none h-6">
                        <div class="rounded-full p-2 flex items-center bg-white space-x-0.5 h-full">
                            <div class="bg-[#44B29D] rounded-full w-2 h-2"></div>
                            <div>{{ headToHeadInfo.first_user_wins }}</div>
                            <div [translate]="'WINS'" i18n="@@WINS">Wins</div>
                        </div>
                        <div class="rounded-full p-2 flex items-center bg-white space-x-0.5 h-full">
                            <div class="bg-[#727272] rounded-full w-2 h-2"></div>
                            <div>{{ headToHeadInfo.draws }}</div>
                            <div [translate]="'DRAWS'" i18n="@@DRAWS">Draws</div>
                        </div>
                        <div class="rounded-full p-2 flex items-center bg-white space-x-0.5 h-full">
                            <div class="bg-[#E3403E] rounded-full w-2 h-2"></div>
                            <div>{{ headToHeadInfo.second_user_wins }}</div>
                            <div [translate]="'LOSSES'" i18n="@@LOSSES">Losses</div>
                        </div>
                    </div>
                    <div class="flex h-3 w-full">
                        <div class="bg-[#44B29D]" [style.width.%]="headToHeadWidths[0]"></div>
                        <div class="bg-[#727272]" [style.width.%]="headToHeadWidths[1]"></div>
                        <div class="bg-[#E3403E]" [style.width.%]="headToHeadWidths[2]"></div>
                    </div>
                }
            </div>
        }

        <div appWebContainer>
            <div class="bg-neutral-50 flex justify-around p-4 mx-4 text-center rounded-md">
                <div class="w-1/3 flex justify-center">
                    <div (click)="showAmountsDialog()" class="cursor-pointer">
                        @if (!loadedStats) {
                            <ngx-skeleton-loader
                                [theme]="skeletonThemes.stats"
                                animation="progress-dark"></ngx-skeleton-loader>
                        } @else {
                            <div class="font-bebas-neue text-black text-4xl">
                                {{ maximums }}
                            </div>
                        }
                        <div class="text-orange">180s</div>
                        <app-icon
                            icon="visibility"
                            size="small"
                            class="text-neutral-700 flex justify-center"></app-icon>
                    </div>
                </div>

                <div class="w-1/3 flex justify-center">
                    <div (click)="showStatsDialog()" class="cursor-pointer">
                        @if (!loadedStats || !statistics) {
                            <ngx-skeleton-loader
                                [theme]="skeletonThemes.stats"
                                animation="progress-dark"></ngx-skeleton-loader>
                        } @else {
                            <div class="font-bebas-neue text-black text-4xl">
                                {{ statistics.darts_thrown || 0 }}
                            </div>
                            <div class="text-orange" [translate]="'DARTS'" i18n="@@DARTS">Darts</div>
                            <app-icon
                                icon="visibility"
                                size="small"
                                class="text-neutral-700 flex justify-center"></app-icon>
                        }
                    </div>
                </div>

                <div class="w-1/3 flex justify-center">
                    <div (click)="showFriends()" class="cursor-pointer">
                        @if (!loadedStats) {
                            <ngx-skeleton-loader
                                [theme]="skeletonThemes.stats"
                                animation="progress-dark"
                                class="w-5 h-5"></ngx-skeleton-loader>
                        } @else {
                            <div class="font-bebas-neue text-black text-4xl relative">
                                <div>{{ user.total_friends || 0 }}</div>
                                @if (user.is_self && incomingFriends?.length) {
                                    <ion-badge
                                        mode="md"
                                        class="ion-bg-yellow ion-text-black absolute top-0 -right-2"
                                        style="transform: translateY(40%)">
                                        {{ incomingFriends.length }}
                                    </ion-badge>
                                }
                            </div>
                        }
                        <div class="text-orange" [translate]="'FRIENDS'" i18n="@@FRIENDS">Friends</div>
                        <app-icon
                            icon="visibility"
                            size="small"
                            class="text-neutral-700 flex justify-center"></app-icon>
                    </div>
                </div>
            </div>
        </div>
        <div appWebContainer>
            <div class="grid grid-cols-2 mx-6 my-8">
                <div class="p-4 border-r-1 border-b-1 border-white">
                    <div class="font-bebas-neue text-2xl mb-1" [translate]="'COUNTRY'" i18n="@@COUNTRY">Country</div>
                    @if (!loaded) {
                        <ngx-skeleton-loader [theme]="skeletonThemes.info2" animation="progress-dark">
                        </ngx-skeleton-loader>
                    }
                    @if (loaded && user) {
                        @if (user.is_self && !user.profile?.country) {
                            <span class="relative ml-2 flex h-2.5 w-2.5">
                                <span
                                    class="absolute inline-flex h-full w-full rounded-full bg-yellow-icon-alert opacity-75"></span>
                                <span class="relative inline-flex h-2.5 w-2.5 rounded-full bg-yellow-icon-alert"></span>
                            </span>
                        }
                        @if (user.profile?.country !== null) {
                            <div class="flex items-center space-x-3">
                                <img
                                    src="assets/images/flags/rect/{{ getFlag(user.profile?.country) }}"
                                    class="h-5 w-5 rounded-full" />
                                <div>{{ getCountry(user.profile?.country) }}</div>
                            </div>
                        }
                    }
                </div>
                <div class="p-4 border-b-1 border-white">
                    <div class="font-bebas-neue text-2xl mb-1" [translate]="'DARTS'" i18n="@@DARTS">Darts</div>
                    @if (!loaded) {
                        <ngx-skeleton-loader [theme]="skeletonThemes.info2" animation="progress-dark">
                        </ngx-skeleton-loader>
                    }
                    @if (loaded && user) {
                        <div class="truncate">
                            {{ user?.profile.darts || '-' }}
                        </div>
                    }
                </div>
                <div class="p-4 border-r-1 border-b-1 border-white">
                    <div class="font-bebas-neue text-2xl mb-1" [translate]="'TEAM'" i18n="@@TEAM">Team</div>
                    @if (!loaded) {
                        <ngx-skeleton-loader [theme]="skeletonThemes.info2" animation="progress-dark">
                        </ngx-skeleton-loader>
                    }
                    @if (loaded && user) {
                        <div class="truncate">
                            {{ user.profile.team || '-' }}
                        </div>
                    }
                </div>
                <div class="p-4 border-b-1 border-white">
                    <div class="font-bebas-neue text-2xl mb-1" [translate]="'LEAGUE'" i18n="@@LEAGUE">League</div>
                    @if (!loaded) {
                        <ngx-skeleton-loader [theme]="skeletonThemes.info2" animation="progress-dark">
                        </ngx-skeleton-loader>
                    }
                    @if (loaded && user) {
                        <div class="truncate">
                            {{ user.profile.league || '-' }}
                        </div>
                    }
                </div>
                <div class="p-4 border-r-1 border-white">
                    <div class="font-bebas-neue text-2xl mb-1" [translate]="'DIVISION'" i18n="@@DIVISION">Division</div>
                    @if (!loaded) {
                        <ngx-skeleton-loader [theme]="skeletonThemes.info2" animation="progress-dark">
                        </ngx-skeleton-loader>
                    }
                    @if (loaded && user) {
                        <div class="truncate">
                            {{ user.profile.division || '-' }}
                        </div>
                    }
                </div>
                <div class="p-4 border-white">
                    <div class="font-bebas-neue text-2xl mb-1" [translate]="'MEMBER_SINCE'" i18n="@@MEMBER_SINCE">
                        Member since
                    </div>
                    @if (!loaded) {
                        <ngx-skeleton-loader [theme]="skeletonThemes.info2" animation="progress-dark">
                        </ngx-skeleton-loader>
                    }
                    @if (loaded && user) {
                        <div class="truncate">
                            {{ user.created_at | date: 'd MMM yyyy' : '' : translateService.currentLang }}
                        </div>
                    }
                </div>
            </div>
        </div>

        @if (user && loaded) {
            @if (user.is_self && auth.user.is_ultimate === false) {
                <div class="m-4">
                    <app-primary-button
                        addedClasses="w-full"
                        color="blue"
                        size="normal"
                        (click)="OpenSubscriptionDialog()">
                        <div class="flex space-x-2 items-center">
                            <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#ultimate_ribbon_clip_path_profile)">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M30.5079 15.9178L27.2712 12.2168L27.7222 7.32191L22.9334 6.23416L20.4263 1.98926L15.9161 3.92599L11.4059 1.98926L8.89871 6.22089L4.10993 7.29538L4.56095 12.2035L1.32422 15.9178L4.56095 19.6188L4.10993 24.527L8.89871 25.6148L11.4059 29.8464L15.9161 27.8964L20.4263 29.8331L22.9334 25.6015L27.7222 24.5137L27.2712 19.6188L30.5079 15.9178ZM15.9161 15.9176C17.3813 15.9176 18.5691 14.7298 18.5691 13.2645C18.5691 11.7993 17.3813 10.6114 15.9161 10.6114C14.4508 10.6114 13.263 11.7993 13.263 13.2645C13.263 14.7298 14.4508 15.9176 15.9161 15.9176ZM10.611 19.8977C10.611 18.1334 14.1462 17.2446 15.9171 17.2446C17.688 17.2446 21.2232 18.1334 21.2232 19.8977V20.5609C21.2232 20.9257 20.9247 21.2242 20.5599 21.2242H11.2742C10.9094 21.2242 10.611 20.9257 10.611 20.5609V19.8977Z"
                                        fill="url(#ultimate_ribbon_linear_gradient_profile)" />
                                </g>
                                <defs>
                                    <linearGradient
                                        id="ultimate_ribbon_linear_gradient_profile"
                                        x1="15.9161"
                                        y1="1.98926"
                                        x2="15.9161"
                                        y2="29.8464"
                                        gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#ECD676" />
                                        <stop offset="0.24" stop-color="#D39E3A" />
                                        <stop offset="0.655" stop-color="#EDB948" />
                                        <stop offset="1" stop-color="#BB8B32" />
                                    </linearGradient>
                                    <clipPath id="ultimate_ribbon_clip_path_profile">
                                        <rect width="31.8367" height="31.8367" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <span [translate]="'UPGRADE_TO_ULTIMATE'" i18n="@@UPGRADE_TO_ULTIMATE" class="!text-white"
                                >Upgrade to Ultimate</span
                            >
                        </div>
                    </app-primary-button>
                </div>
            }
        }
    </div>

    <!-- @if (showBottomNav) { -->
    <app-bottom-nav class="w-full" active="profile"></app-bottom-nav>
    <!-- } -->
</div>
