/* eslint-disable max-len */
import { AfterViewInit, ChangeDetectorRef, Component, inject, NgZone, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CameraStreamApiService } from '@dc-api/camera-stream.api.service';
import { SmartDeviceApiService } from '@dc-api/smart-device.api.service';
import { SmartDevice, SmartDeviceNetwork, User } from '@dc-core/dc-backend/dc-classes';
import { AlertPayload, DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { JanusVideoRoomService } from '@dc-core/dc-services/dc-janus/janus-video-room.service';
import { ModalController, NavController, Platform, PopoverController } from '@ionic/angular';
import { PopoverService } from '@services/popover.service';
import { SmartDeviceService } from '@services/smart-device.service';
import {
    CapacitorSmartDeviceClient,
    VirtCamCommandRequest,
    VirtCamCommands,
    VirtCamHelper,
    VirtCamResponses,
    VirtCamStartWiFiAPRequest,
} from 'capacitor-smart-device-client/dist/esm';
import _ from 'lodash';
import { Subject, take, takeUntil } from 'rxjs';
import { PromptDialogComponent } from 'src/dialogs/prompt/prompt.dialog';
import { RenameDialogComponent, RenameDialogPayload } from 'src/dialogs/rename/rename.dialog';
import { SearchPlayerDialogComponent } from 'src/dialogs/search-player/search-player.component';
import { AuthService } from 'src/services/auth.service';

import {
    ShutdownPopoverComponent,
    SMARTDEVICE_SHUTDOWN_RESPONSE,
} from '../OMNI/omni-shutdown-popover/omni-shutdown-popover.component';

export type SmartDeviceView = 'camera' | 'more';

@Component({
    selector: 'app-virtcam',
    templateUrl: 'virtcam.component.html',
})
export class VirtCamComponent implements AfterViewInit, OnDestroy {
    smartDeviceNetwork: SmartDeviceNetwork;
    smartDevice: SmartDevice;

    view: SmartDeviceView = 'camera';

    isAdmin: boolean;
    networkOnlyAccess: boolean;

    version: string = null;

    public externalCode: number = null;
    public externalPassword: string = null;

    private popoverService: PopoverService = inject(PopoverService);

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        public auth: AuthService,
        public smartDeviceService: SmartDeviceService,
        private _ngZone: NgZone,
        public modal: ModalController,
        private _nav: NavController,
        private _route: ActivatedRoute,
        private _platform: Platform,
        private _alertService: DartCounterAlertService,
        private _smartDeviceApiService: SmartDeviceApiService,
        private _videoRoomService: JanusVideoRoomService,
        private _popoverController: PopoverController,
        private _changeDetectorRef: ChangeDetectorRef,
        private _cameraStreamApiService: CameraStreamApiService
    ) {}

    ngAfterViewInit(): void {
        this._route.params.pipe(take(1)).subscribe(async (params) => {
            if (params.id) {
                this._smartDeviceApiService.smartDeviceNetworks.forEach((network) => {
                    network.devices.forEach((device) => {
                        if (device.id == params.id) {
                            this.smartDeviceNetwork = network;
                            this.smartDevice = device;

                            this.isAdmin = network.pivot?.is_admin || device.pivot?.is_admin;
                            this.networkOnlyAccess = network.pivot && !device.pivot;

                            this.loadSmartDevice();
                        }
                    });
                });

                if (!this.smartDeviceNetwork || !this.smartDevice) {
                    this.goBack();
                }
            } else {
                this.goBack();
            }
        });
    }

    goBack(): void {
        this._nav.back();
    }

    loadSmartDevice(): void {
        this.smartDeviceService.initSmartDevice(this.smartDevice);
        this.smartDeviceService
            .startStreaming(this.smartDevice, false)
            .then((janusRoom) => {
                this._ngZone.run(() => {
                    this.smartDeviceService.userMedia.startRemoteEvents();
                    this.smartDeviceService.userMedia.remoteEvents$
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe(() => {
                            this._changeDetectorRef.detectChanges();
                        });

                    this._videoRoomService
                        .spectateRoom(janusRoom, 'video', null, this.smartDeviceService.userMedia, true, false, false)
                        .then(() => {
                            this.smartDeviceService.userMedia.janusRoom = janusRoom;
                        })
                        .catch(console.error);
                });
            })
            .catch(console.error);
    }

    rename(): void {
        if (!this.isAdmin) {
            return;
        }

        this.modal
            .create({
                component: RenameDialogComponent,
                backdropDismiss: true,
                componentProps: {
                    title: $localize`:@@RENAME_YOUR_DEVICE:Rename your device`,
                    renamable: _.cloneDeep(this.smartDevice.name),
                } as RenameDialogPayload,
                cssClass: 'auto-height',
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.smartDevice.name = dialogRes.data;
                        this._smartDeviceApiService
                            .updateSmartDeviceById({
                                smartDeviceId: this.smartDevice.id,
                                name: this.smartDevice.name,
                            })
                            .finally(() => {
                                this._alertService.createAlert({
                                    title: $localize`:@@RENAMED:Renamed`,
                                    icon: 'success',
                                });
                            });
                    }
                });
            });
    }

    showShutdownPopover() {
        this._popoverController
            .create({
                event,
                component: ShutdownPopoverComponent,
            })
            .then((popover) => {
                popover.present();
                this.popoverService.init(popover);
                popover.onDidDismiss().then((res) => {
                    this.popoverService.destroy();

                    const result = res.data as SMARTDEVICE_SHUTDOWN_RESPONSE;
                    switch (result) {
                        case 'reboot':
                            this.reboot();
                            break;
                        case 'shutdown':
                            this.shutdown();
                            break;
                    }
                });
            });
    }

    private checkTLSSupport(): boolean {
        if (this._platform.is('capacitor')) {
            return true;
        }

        this._alertService.createAlert({
            title: 'Not supported',
            text: 'This feature is not implemented in the web-browser yet.',
            icon: 'error',
        });

        return false;
    }

    public reboot() {
        if (this.checkTLSSupport()) {
            this._alertService.createAlert({
                title: 'Rebooting VIRT',
                icon: 'loading',
            });

            const data: VirtCamCommandRequest = {
                command: VirtCamCommands.SystemReboot,
            };

            CapacitorSmartDeviceClient.sendTLSRequest({
                host: this.smartDevice.ip_address,
                port: 443,
                data: JSON.stringify(data),
            })
                .then((res) => {
                    const status = VirtCamHelper.getResponse(res).status;
                    if (status == VirtCamResponses.Success) {
                        this._alertService.createAlert({
                            title: 'Successfully rebooted the VIRT',
                            icon: 'success',
                        });
                    } else {
                        this._alertService.createAlert({
                            title: 'SystemReboot: ERROR',
                            icon: 'error',
                        });
                    }
                })
                .catch((err) => {
                    console.error('Error: ' + JSON.stringify(err));

                    this._alertService.createAlert({
                        title: 'SystemReboot: ERROR',
                        icon: 'error',
                    });
                });
        }
    }

    public shutdown() {
        if (this.checkTLSSupport()) {
            this._alertService.createAlert({
                title: 'Shutting down VIRT',
                icon: 'loading',
            });

            const data: VirtCamCommandRequest = {
                command: VirtCamCommands.SystemShutdown,
            };

            CapacitorSmartDeviceClient.sendTLSRequest({
                host: this.smartDevice.ip_address,
                port: 443,
                data: JSON.stringify(data),
            })
                .then((res) => {
                    const status = VirtCamHelper.getResponse(res).status;
                    if (status == VirtCamResponses.Success) {
                        this._alertService.createAlert({
                            title: 'Successfully shut down VIRT',
                            icon: 'success',
                        });
                    } else {
                        this._alertService.createAlert({
                            title: 'SystemShutdown: ERROR',
                            icon: 'error',
                        });
                    }
                })
                .catch((err) => {
                    console.error('Error: ' + JSON.stringify(err));
                });
        }
    }

    invitePlayer(): void {
        this.modal
            .create({
                component: SearchPlayerDialogComponent,
                componentProps: {},
                cssClass: 'auto-height',
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    const user: User = dialogRes.data;
                    if (user) {
                        this._smartDeviceApiService
                            .inviteUserToSmartDeviceNetwork({
                                smartDeviceNetworkId: this.smartDeviceNetwork.id,
                                user_id: user.id,
                                device_id: this.smartDevice?.id,
                                has_full_access: this.smartDevice ? false : true,
                                is_admin: false,
                                expires_at: null,
                            })
                            .then(() => {
                                this._alertService.createAlert({
                                    title: $localize`:@@PLAYER_INVITED:Player invited`,
                                    icon: 'success',
                                });
                            })
                            .catch((err) => this._alertService.errorFromApi(err));
                    }
                });
            });
    }

    removeDevice(): void {
        this.modal
            .create({
                component: PromptDialogComponent,
                componentProps: {
                    title: $localize`:@@DELETE_DEVICE:Remove device`,
                    text: $localize`:@@DELETING_DEVICE:Do you want to remove this device?`,
                    cancelText: $localize`:@@CANCEL:Cancel`,
                    confirmText: $localize`:@@CONFIRM:Confirm`,
                    confirmColor: 'red',
                } as AlertPayload,
                cssClass: 'auto-height',
                showBackdrop: true,
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this._smartDeviceApiService
                            .deleteSmartDeviceById({ smartDeviceId: this.smartDevice.id })
                            .then(() => {
                                this.goBack();
                            });
                    }
                });
            });
    }

    generateCredentials(): void {
        if (this.smartDeviceService.userMedia.janusRoom) {
            this._cameraStreamApiService
                .storeCameraStreamByRoomNumber({
                    room: this.smartDeviceService.userMedia.janusRoom.roomID,
                    janus_server_hostname: this.smartDeviceService.userMedia.janusRoom.janusServerHost,
                    virt_cam_id: this.smartDevice.id,
                })
                .then((res) => {
                    this.externalCode = res.data.code;
                    this.externalPassword = res.data.password;
                });
        }
    }

    disableCode(): void {
        if (this.smartDeviceService.userMedia.janusRoom && this.externalCode && this.externalPassword) {
            this._cameraStreamApiService
                .deleteCameraStream({
                    room: this.smartDeviceService.userMedia.janusRoom.roomID,
                    code: this.externalCode,
                    password: this.externalPassword,
                })
                .then(() => {
                    this.externalCode = null;
                    this.externalPassword = null;
                });
        }
    }

    public virtCamStartWiFiAP() {
        this._alertService.createAlert({
            title: 'Resetting VirtCam to hotspot mode',
            icon: 'loading',
        });

        const data: VirtCamCommandRequest = {
            command: VirtCamCommands.StartWiFiAP,
        };

        CapacitorSmartDeviceClient.sendTLSRequest({
            host: this.smartDevice.ip_address,
            port: this.smartDeviceService.cameraPort,
            data: JSON.stringify(data),
        })
            .then((res) => {
                const status = VirtCamHelper.getResponse(res).status;
                if (status == VirtCamResponses.Success) {
                    this._alertService.createAlert({
                        title: 'Successfully resetted to hotspot mode',
                        icon: 'success',
                    });
                } else {
                    this._alertService.createAlert({
                        title: 'StartWiFiAP: ERROR',
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                console.error('Error: ' + JSON.stringify(err));
            });
    }

    public virtCamSystemShutdown() {
        this._alertService.createAlert({
            title: 'Shutting down VirtCam',
            icon: 'loading',
        });

        const data: VirtCamCommandRequest = {
            command: VirtCamCommands.SystemShutdown,
        };

        CapacitorSmartDeviceClient.sendTLSRequest({
            host: this.smartDevice.ip_address,
            port: this.smartDeviceService.cameraPort,
            data: JSON.stringify(data),
        })
            .then((res) => {
                const status = VirtCamHelper.getResponse(res).status;
                if (status == VirtCamResponses.Success) {
                    this._alertService.createAlert({
                        title: 'Successfully shut down VirtCam',
                        icon: 'success',
                    });
                } else {
                    this._alertService.createAlert({
                        title: 'SystemShutdown: ERROR',
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                console.error('Error: ' + JSON.stringify(err));
            });
    }

    public virtCamSystemReboot() {
        this._alertService.createAlert({
            title: 'Rebooting VirtCam',
            icon: 'loading',
        });

        const data: VirtCamCommandRequest = {
            command: VirtCamCommands.SystemReboot,
        };

        CapacitorSmartDeviceClient.sendTLSRequest({
            host: this.smartDevice.ip_address,
            port: this.smartDeviceService.cameraPort,
            data: JSON.stringify(data),
        })
            .then((res) => {
                const status = VirtCamHelper.getResponse(res).status;
                if (status == VirtCamResponses.Success) {
                    this._alertService.createAlert({
                        title: 'Successfully rebooted VirtCam',
                        icon: 'success',
                    });
                } else {
                    this._alertService.createAlert({
                        title: 'SystemReboot: ERROR',
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                console.error('Error: ' + JSON.stringify(err));
            });
    }

    public virtCamStartUV4L() {
        this._alertService.createAlert({
            title: 'Starting UV4L',
            icon: 'loading',
        });

        const data: VirtCamStartWiFiAPRequest = {
            command: VirtCamCommands.StartUV4L,
            tls: 1,
        };

        CapacitorSmartDeviceClient.sendTLSRequest({
            host: this.smartDevice.ip_address,
            port: this.smartDeviceService.cameraPort,
            data: JSON.stringify(data),
        })
            .then((res) => {
                const status = VirtCamHelper.getResponse(res).status;
                if (status == VirtCamResponses.Success) {
                    this._alertService.createAlert({
                        title: 'Successfully started UV4L',
                        icon: 'success',
                    });
                } else {
                    this._alertService.createAlert({
                        title: 'StartUV4L: ERROR',
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                console.error('Error: ' + JSON.stringify(err));
            });
    }

    public virtCamStopUV4L() {
        this._alertService.createAlert({
            title: 'Stopping UV4L',
            icon: 'loading',
        });

        const data: VirtCamCommandRequest = {
            command: VirtCamCommands.StopUV4L,
        };

        CapacitorSmartDeviceClient.sendTLSRequest({
            host: this.smartDevice.ip_address,
            port: this.smartDeviceService.cameraPort,
            data: JSON.stringify(data),
        })
            .then((res) => {
                const status = VirtCamHelper.getResponse(res).status;
                if (status == VirtCamResponses.Success) {
                    this._alertService.createAlert({
                        title: 'Successfully stopped UV4L',
                        icon: 'success',
                    });
                } else {
                    this._alertService.createAlert({
                        title: 'StopUV4L: ERROR',
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                console.error('Error: ' + JSON.stringify(err));
            });
    }

    public virtCamReadGUID() {
        this._alertService.createAlert({
            title: 'Reading GUID',
            icon: 'loading',
        });

        const data: VirtCamCommandRequest = {
            command: VirtCamCommands.ReadGUID,
        };

        CapacitorSmartDeviceClient.sendTLSRequest({
            host: this.smartDevice.ip_address,
            port: this.smartDeviceService.cameraPort,
            data: JSON.stringify(data),
        })
            .then((res) => {
                const response = VirtCamHelper.getResponse(res);
                const status = response.status;
                if (status == VirtCamResponses.Success) {
                    this._alertService.createAlert({
                        title: 'GUID: ' + response.guid,
                        icon: 'success',
                    });
                } else {
                    this._alertService.createAlert({
                        title: 'ReadGUID: ERROR',
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                console.error('Error: ' + JSON.stringify(err));
            });
    }

    async ngOnDestroy(): Promise<void> {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        if (this.smartDeviceService.userMedia.janusRoom.roomID) {
            await this._videoRoomService
                .leaveRoomAndDetachAllHandles(this.smartDeviceService.userMedia.janusRoom.roomID, true)
                .catch(console.error);
            this.smartDeviceService.userMedia.janusRoom.roomID = null;
            this.smartDeviceService.userMedia.janusRoom.janusServerHost = null;
        }
        this.smartDeviceService.userMedia.cleanupUserMedia(true, true);
        this.smartDeviceService.userMedia.stopRemoteEvents();
    }
}
