import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthApiService } from '@dc-api/auth.api.service';
import { UserApiService } from '@dc-api/user.api.service';
import { User } from '@dc-core/dc-backend/dc-classes';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DCLoadingService } from '@providers/DCLoadingService';
import { AuthService } from '@services/auth.service';
import moment from 'moment';
import { ApiResponse } from 'openapi-typescript-fetch';
import { PrimaryButtonComponent } from 'src/app/primary-button/primary-button.component';
import { DialogViewComponent } from 'src/app/shared/components/dialog/dialog-view/dialog-view.component';
import { DatePickerDialogComponent } from 'src/dialogs/date-picker/date-picker.dialog';
import { PolicyDialogComponent } from 'src/dialogs/policy/policy';

import { IconComponent } from '../../../../shared/components/icon/icon.component';

@Component({
    selector: 'app-user-birthday-email-dialog',
    standalone: true,
    templateUrl: './user-birthday-email-dialog.component.html',
    imports: [
        CommonModule,
        DialogViewComponent,
        PrimaryButtonComponent,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        IconComponent,
        IonicModule,
    ],
})
export class UserBirthdayEmailDialogComponent implements OnInit {
    public translateService: TranslateService = inject(TranslateService);

    private modalController: ModalController = inject(ModalController);
    private userApiService: UserApiService = inject(UserApiService);
    private authApiService: AuthApiService = inject(AuthApiService);
    private authService: AuthService = inject(AuthService);
    private loadingService: DCLoadingService = inject(DCLoadingService);

    public form: FormGroup;
    public validationForm: FormGroup = new FormGroup({
        code: new FormControl('', [Validators.required]),
    });
    public emailVerified: boolean = false;
    public existingEmail: string;
    public hasInitialEmail: boolean = false;
    public showVerify: boolean = false;
    public invalidCode: boolean = false;

    constructor(public modal: ModalController) {}

    public ngOnInit(): void {
        const user = this.authService.user;
        this.hasInitialEmail = !!user.email;

        this.initForm(user);
    }

    public changeBirthdate(): void {
        this.modalController
            .create({
                component: DatePickerDialogComponent,
                cssClass: 'auto-height',
                componentProps: {
                    title: $localize`:@@DATE_OF_BIRTH:Date of birth`,
                    date: this.form.get('birthdate').value,
                    maxDate: moment().format('YYYY-MM-DD'),
                },
                showBackdrop: true,
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRef) => {
                    if (dialogRef.data) {
                        this.form.get('birthdate').patchValue(dialogRef.data);
                    }
                });
            });
    }

    public save(): void {
        this.loadingService.ShowDefaultLoader();

        const birthdate = this.form.get('birthdate')?.value;
        const email = this.form.get('email')?.value;

        const birthdateReq = birthdate ? this.updateBirthdate(birthdate) : Promise.resolve();
        const emailReq = email ? this.updateEmail(email) : Promise.resolve();

        Promise.all([birthdateReq, emailReq])
            .then((response) => {
                this.loadingService.DismissLoader();
                if (response[0]) {
                    this.authService.user.profile = {
                        ...this.authService.user.profile,
                        birthdate: (response[0] as ApiResponse<User>)?.data.profile?.birthdate,
                    };
                }

                if (response[1]) {
                    this.authService.user = {
                        ...this.authService.user,
                        email: email,
                    };

                    this.showVerify = true;
                } else {
                    this.modalController.dismiss(true);
                }
            })
            .catch((err) => {
                const error = err.getActualType();
                if (error.status === 422) this.existingEmail = email;

                this.loadingService.DismissLoader();
            });
    }

    public tryEmailVerification(): void {
        this.loadingService.ShowDefaultLoader();

        this.verifyEmail(this.validationForm.get('code').value)
            .then(() => {
                this.loadingService.DismissLoader();

                this.authService.user = {
                    ...this.authService.user,
                    email_verified_at: moment().toISOString(),
                };

                this.modalController.dismiss(true);
            })
            .catch((err) => {
                const error = err.getActualType();
                if (error.status === 422) this.invalidCode = true;

                this.loadingService.DismissLoader();
            });
    }

    public OpenPolicyDialog(): void {
        this.modalController.create({ component: PolicyDialogComponent }).then((elem) => elem.present());
    }

    private updateBirthdate(birthdate: string): Promise<ApiResponse<User>> {
        return this.userApiService.updateUser({
            profile: {
                birthdate: birthdate,
            },
        });
    }

    private updateEmail(email: string): Promise<ApiResponse<{ message?: string }>> {
        return this.authApiService.changeEmail({
            email: email,
            type: 'code',
        });
    }

    private verifyEmail(code: string): Promise<ApiResponse<{ message?: string }>> {
        return this.authApiService.verifyEmailByCode({
            code: parseInt(code),
        });
    }

    private initForm(user: User): void {
        const birthdate = user.profile?.birthdate ? moment(user.profile.birthdate).utc().toISOString() : null;

        this.emailVerified = !!user.email_verified_at;

        this.form = new FormGroup({});

        if (!this.emailVerified) {
            this.form.addControl('email', new FormControl(user.email, [Validators.required, Validators.email]));
        }
        if (!birthdate) {
            this.form.addControl('birthdate', new FormControl('', [Validators.required]));
        }
    }

    dismiss() {
        this.modal.dismiss();
    }
}
