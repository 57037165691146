import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IonicModule } from '@ionic/angular';

@Component({
    selector: 'app-help-modal',
    templateUrl: './help-modal.component.html',
    standalone: true,
    imports: [IonicModule],
})
export class HelpModalComponent {
    title: string;
    contents: string;

    constructor(public modal: ModalController) {}

    dismiss() {
        this.modal.dismiss();
    }
}
