<modal-content class="bg-white-pattern text-black">
    <app-page-header mainNavigation="back" defaultBack="account-settings" color="white">
        <div title [translate]="'UNFINISHED_GAMES'" i18n="@@UNFINISHED_GAMES">Unfinished games</div>
    </app-page-header>

    <ng-container>
        <ion-row>
            <ion-col>
                <ion-button
                    class="extra-btn"
                    color="dark"
                    expand="block"
                    fill="outline"
                    (click)="RemoveAll()"
                    [translate]="'REMOVE_ALL'"
                    i18n="@@REMOVE_ALL"
                    >Remove all</ion-button
                >
            </ion-col>
        </ion-row>
        <!-- Unfinished section -->
        <ion-item *ngFor="let unfinished of unfinisheds; let i = index">
            <ion-label style="font-size: 13px">
                <span>{{ getName(unfinished) }} - </span>
                <span class="smallLabel">{{
                    unfinished.started_at | date: 'd MMM yyyy' : '' : translateService.currentLang
                }}</span>
            </ion-label>
            <ion-button style="margin: 0 2px" slot="end" color="primary" (click)="ContinueGame(unfinished, i)">
                <div class="imageicon">
                    <app-icon icon="dartboard"></app-icon>
                </div>
            </ion-button>
            <ion-button
                style="margin: 0 2px"
                slot="end"
                class="trashbutton"
                color="danger"
                (click)="RemoveItemFromStorageList(i)">
                <ion-icon size="small" name="trash-outline" slot="icon-only"></ion-icon>
            </ion-button>
        </ion-item>
        <!-- end of Unfinished -->
    </ng-container>
</modal-content>

<ion-footer class="ion-no-border">
    <ion-toolbar class="footer" style="height: 72px">
        <div class="bottomCTA" style="position: relative" (click)="dismiss()">
            <p class="text-center" style="height: 72px" [translate]="'CLOSE'" i18n="@@CLOSE">Close</p>
        </div>
    </ion-toolbar>
</ion-footer>
