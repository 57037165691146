import { Injectable } from '@angular/core';
import {
    CollectionReference,
    DocumentReference,
    DocumentSnapshot,
    Firestore,
    Timestamp,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    limit,
    onSnapshot,
    orderBy,
    query,
    setDoc,
    startAfter,
    updateDoc,
    where,
} from '@angular/fire/firestore';
import { Subject } from 'rxjs';
import { OnlineGameplay, PlayerInterim, PublicOnlineGameplay } from '../../dc-backend/dc-interfaces';
import { DartCounterCricketTacticsGame } from '../../dc-gamelogic/in-game/cricket-tactics/cricket-tactics.in-game.classes';
import { DartCounterPlayingMatch } from '../../dc-gamelogic/in-game/match/match.in-game.classes';
import { CAMERA_TYPE } from '../../dc-services/camera/camera.models';
import { FireStoreCollectionsService } from '../firestore-collections.service';
import { FIRESTORE_COLLECTION, FireStoreOnlineVersion } from '../globals/firestore.tables';

@Injectable()
export class PublicGamesCollectionService extends FireStoreCollectionsService {
    private defaultClass = new PublicOnlineGameplay();
    private collection_name: FIRESTORE_COLLECTION = FIRESTORE_COLLECTION.PUBLIC_GAMES;
    private firestore_collection: CollectionReference<PublicOnlineGameplay>;
    public currentDocumentID: string;

    private publicGamesSubject = new Subject<PublicOnlineGameplay[]>();
    public publicGames$ = this.publicGamesSubject.asObservable(); // This is what components will subscribe to
    private lastVisible = null;

    constructor(private firestore: Firestore) {
        super(firestore);
        this.firestore_collection = this.getConvertedData<PublicOnlineGameplay>(this.collection_name);
    }

    /**
     * Fetches public Games with pagination and optional player name filtering.
     *
     * @param {number} skip - The number of documents to skip.
     * @param {number} take - The number of documents to take (limit).
     * @param {string} [playerName] - Optional player name for filtering.
     */
    fetchGames(take) {
        const hasVirtCamClause = this.getAttributeString(
            this.defaultClass,
            (obj: PublicOnlineGameplay) => obj.has_virt_cam
        );
        const hasOmniClause = this.getAttributeString(this.defaultClass, (obj: PublicOnlineGameplay) => obj.has_omni);
        const hasCameraClause = this.getAttributeString(
            this.defaultClass,
            (obj: PublicOnlineGameplay) => obj.has_camera
        );
        const onlineGameVersion = this.getAttributeString(
            this.defaultClass,
            (obj: PublicOnlineGameplay) => obj.onlineGameVersion
        );

        let publicGamesQuery = query(
            this.firestore_collection,
            orderBy(hasOmniClause, 'desc'),
            orderBy(hasVirtCamClause, 'desc'),
            orderBy(hasCameraClause, 'desc'),
            where(onlineGameVersion, '==', FireStoreOnlineVersion)
        );

        if (this.lastVisible) {
            // Assuming this.lastVisible is the last document snapshot from the previous query
            publicGamesQuery = query(publicGamesQuery, startAfter(this.lastVisible));
        }

        publicGamesQuery = query(publicGamesQuery, limit(take));

        getDocs(publicGamesQuery).then((querySnapshot) => {
            let currentPublicGames = [];

            querySnapshot.forEach((doc) => {
                const publicGame = doc.data();
                publicGame.doc_id = doc.id;
                currentPublicGames.push(publicGame);
            });

            if (currentPublicGames.length > 0) {
                this.lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
            }
            this.publicGamesSubject.next(currentPublicGames); // Emit the updated list
        });
    }

    clearFetchedGames() {
        this.lastVisible = null;
    }

    searchGamesByPlayer(playerName) {
        this.lastVisible = null;

        const hasOmniClause = this.getAttributeString(this.defaultClass, (obj: PublicOnlineGameplay) => obj.has_omni);
        const hasVirtCamClause = this.getAttributeString(
            this.defaultClass,
            (obj: PublicOnlineGameplay) => obj.has_virt_cam
        );
        const hasCameraClause = this.getAttributeString(
            this.defaultClass,
            (obj: PublicOnlineGameplay) => obj.has_camera
        );
        const onlineGameVersion = this.getAttributeString(
            this.defaultClass,
            (obj: PublicOnlineGameplay) => obj.onlineGameVersion
        );
        const playerNamesClause = this.getAttributeString(
            this.defaultClass,
            (obj: PublicOnlineGameplay) => obj.playernames
        );

        let publicGamesQuery = query(
            this.firestore_collection,
            orderBy(hasOmniClause, 'desc'),
            orderBy(hasVirtCamClause, 'desc'),
            orderBy(hasCameraClause, 'desc'),
            where(onlineGameVersion, '==', FireStoreOnlineVersion),
            where(playerNamesClause, 'array-contains', playerName.toLowerCase())
        );

        getDocs(publicGamesQuery).then((querySnapshot) => {
            let currentPublicGames = [];

            querySnapshot.forEach((doc) => {
                const publicGame = doc.data();
                publicGame.doc_id = doc.id;
                currentPublicGames.push(publicGame);
            });

            this.publicGamesSubject.next(currentPublicGames); // Emit the updated list
        });
    }

    addItem(newDoc: PublicOnlineGameplay, firestoreID): Promise<void> {
        this.currentDocumentID = firestoreID;
        return setDoc(doc(this.firestore_collection, firestoreID), newDoc);
    }

    getPublicGameByGameplay(gameplay: OnlineGameplay): PublicOnlineGameplay {
        // Extracting fullnames
        let playernames = gameplay.players.map((player) => player.first_name.toLowerCase());
        // Checking if any player has a room
        let has_camera = gameplay.players.some((player) => player.room !== null);
        let has_virt_cam = gameplay.players.some((player) => player.room?.camType === CAMERA_TYPE.SMART_DEVICE);
        let has_omni = gameplay.players.some((player) => player.has_omni);

        const publicGame: PublicOnlineGameplay = {
            type: gameplay.type,
            owners: gameplay.owners,
            players: gameplay.players,

            // Additional lobby settings
            onlineGameVersion: FireStoreOnlineVersion,
            isFinished: false,
            playernames,
            has_camera,
            has_virt_cam,
            has_omni,
            // Params that will be updated
            last_updated: Timestamp.now(),
            player_interims: [{ ...new PlayerInterim() }, { ...new PlayerInterim() }],
        };

        switch (gameplay.type) {
            case 'match':
                const match = gameplay.game as DartCounterPlayingMatch;
                publicGame.is_best_of = match.is_best_of;
                publicGame.has_sets = match.has_sets;
                publicGame.goal_amount = match.goal_amount;
                publicGame.start_score = match.start_score;
                break;
            case 'cricket_tactics':
                const cricketTactics = gameplay.game as DartCounterCricketTacticsGame;
                publicGame.settings = cricketTactics.settings;
                break;
        }

        return publicGame;
    }

    getDocByID(id: string): DocumentReference<PublicOnlineGameplay> {
        return doc(this.firestore_collection, id);
    }

    watchDoc(id: string, observerFn) {
        let docRef = this.getDocByID(id);
        return onSnapshot(docRef, observerFn);
    }

    remove(docId = null): void {
        if (docId == null) {
            docId = this.currentDocumentID;
        }
        deleteDoc(this.getDocByID(docId));

        this.currentDocumentID = null;
    }

    updateItem(ref: DocumentReference<PublicOnlineGameplay>, updatedValues: PublicOnlineGameplay): Promise<void> {
        return updateDoc(ref, { ...updatedValues });
    }

    updateOrAddPublicGame(gameplay: OnlineGameplay, player_interims: PlayerInterim[], isFinished: boolean = false) {
        if (!this.currentDocumentID) {
            return;
        }

        const fsGameplay = {
            player_interims,
            last_updated: Timestamp.now(),
            isFinished: isFinished,
            onlineGameVersion: FireStoreOnlineVersion,
        } as PublicOnlineGameplay;

        let docRef = this.getDocByID(this.currentDocumentID);

        getDoc(docRef).then((docSnapshot: DocumentSnapshot<PublicOnlineGameplay>) => {
            if (!docSnapshot.exists()) {
                let publicGame = this.getPublicGameByGameplay(gameplay);
                publicGame = { ...publicGame, ...fsGameplay };

                this.addItem(publicGame, this.currentDocumentID);
            } else {
                this.updateItem(docRef, fsGameplay);
            }
        });
    }
}
