<span
    class="flex"
    [class]="'icon-' + icon()"
    [class.!font-bold]="bold()"
    [ngClass]="{
        'text-[0.75rem]': size() === 'extrasmall',
        'text-[0.9rem]': size() === 'small',
        'text-[1.25rem]': size() === 'medium',
        'text-[1.5rem]': size() === 'normal',
        'text-[1.75rem]': size() === 'large',
        'text-[2.75rem]': size() === 'extralarge'
    }"></span>
