/* eslint-disable max-len */
import { Component, Input } from '@angular/core';
import { JanusVideoRoomService } from '@dc-core/dc-services/dc-janus/janus-video-room.service';
import { Platform } from '@ionic/angular';
import { LocalCameraService } from '@services/local-camera.service';

@Component({
    selector: 'app-control-camera-icon',
    templateUrl: 'control-camera-icon.component.html',
})
export class ControlCameraIconComponent {
    @Input() refreshGame: boolean;

    constructor(
        public platform: Platform,
        public videoRoomService: JanusVideoRoomService,
        private _localCamerService: LocalCameraService
    ) {}

    async linkCamera() {
        this._localCamerService.linkCamera(this.refreshGame);
    }

    unlinkCamera() {
        this._localCamerService.unlinkCamera(this.refreshGame);
    }
}
