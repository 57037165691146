<ion-app [class.web-scroll-container]="isWeb">
    <app-alerts></app-alerts>
    <app-ingame-menu></app-ingame-menu>
    <app-event-overlay></app-event-overlay>
    <app-ingame-animation></app-ingame-animation>

    <div
        class="transition bg-white w-full h-full sticky top-0 bottom-0"
        [ngClass]="{ visible: preferences?.transition }">
        <div class="flex flex-col justify-center items-center w-full h-full">
            <img
                class="max-w-xs mx-auto w-full mb-6"
                [src]="
                    themeService.theme() === 'dark'
                        ? 'assets/icon/dctarget_stacked_orange.png'
                        : 'assets/icon/dctarget_stacked_white.png'
                " />
            <ion-spinner style="height: 40px; width: 40px" color="secondary" name="crescent"></ion-spinner>
        </div>
    </div>

    <ion-router-outlet [environmentInjector]="environmentInjector" id="main"></ion-router-outlet>
</ion-app>
