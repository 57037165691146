import { Injectable } from '@angular/core';
import Janus, { JanusJS } from 'janus-gateway';
import { environment } from 'src/environments/environment';
import adapter from 'webrtc-adapter';
import { JanusServerApiService } from '../../dc-api/janus-server.api.service';
import { CameraStream, JanusServer, SmartDevice } from '../../dc-backend/dc-classes';
import { DCFireStoreUser } from '../../dc-firestore/globals/firestore.tables';
import { CAMERA_TYPE, DCStreamTrack, FacingModes, JanusRoom, StreamDisplayMetaData } from '../camera/camera.models';
import { UserMedia } from './DartCounterUserMedia';
import { JanusEvent, JanusEventService, JanusEventType } from './janus-events.service';
import { OwnJanusService } from './own-janus.service';

function randomString(len) {
    var charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var randomString = '';
    for (var i = 0; i < len; i++) {
        var randomPoz = Math.floor(Math.random() * charSet.length);
        randomString += charSet.substring(randomPoz, randomPoz + 1);
    }
    return randomString;
}

export interface JanusRoomPublisher {
    id: number;
    display: string;
    video_codec: JanusVideoCodec;
    streams: MediaStreamInfo[];
}

export interface MediaStreamInfo {
    type: string;
    mindex: number;
    mid: string;
    codec: JanusVideoCodec;
}

export interface PublishingMediaDevices {
    audio: { deviceId: { exact: string } } | boolean;
    video:
        | {
              facingMode?: string;
              deviceId?: { exact: string };
              aspectRatio?: { exact?: number; ideal?: number };
              height?: { max?: number; ideal?: number };
              frameRate?: { max?: number; ideal?: number };
              zoom?: boolean;
          }
        | boolean;
}

export type JanusPublisherMessage =
    | {
          description: string;
          id: number;
          private_id: number;
          publishers: JanusRoomPublisher[];
          room: number;
          videoroom: 'joined';
      }
    | {
          room: number;
          publishers: JanusRoomPublisher[];
          videoroom: 'event';
      }
    | {
          room: number;
          unpublished: number;
          videoroom: 'event';
      }
    | {
          room: number;
          leaving: number;
          videoroom: 'event';
      };

export type JanusOwnRoomMessage =
    | {
          description: string;
          id: number;
          private_id: number;
          publishers: JanusRoomPublisher[];
          room: number;
          videoroom: 'joined';
      }
    | {
          configured: 'ok';
          room: number;
          streams: MediaStreamInfo[];
          video_codec: JanusVideoCodec;
          videoroom: 'event';
      };

export type JanusVideoCodec = 'h264' | 'vp9' | 'vp8';
export type JanusJoinRoomType = 'spectator' | 'external';
export type PublishType = 'video' | 'audio';

@Injectable()
export class JanusVideoRoomService {
    public janusServers: JanusServer[] = [];
    private janusInstances: Map<string, Janus> = new Map(); // Key: Server ID, Value: Janus instance

    // Own cam
    public ownJanusServer: JanusServer = null;
    public ownVideoPublisherRoomHandle: JanusJS.PluginHandle = null;
    public ownAudioPublisherRoomHandle: JanusJS.PluginHandle = null;
    public ownUserMedia = new UserMedia();
    public ownAudioUserMedia = new UserMedia();
    public ownVideoPublisherId: number = null;
    public ownAudioPublisherId: number = null;
    public isPublishingVideo = false;
    public isPublishingAudio = false;
    public videoMedia: PublishingMediaDevices = null;
    public audioMedia: PublishingMediaDevices = null;
    public currentVideoTracks: JanusJS.TrackOption[] = null;
    public currentAudioTracks: JanusJS.TrackOption[] = null;
    public smartDevice: SmartDevice = null;
    public smartDeviceInactivityTimeout: ReturnType<typeof setTimeout> = null;

    // External cam
    public externalPublisherRoomHandle: JanusJS.PluginHandle = null;
    public ownSubscribersRoomHandles = new Map<number, JanusJS.PluginHandle>();
    public usingExternal = false;
    public externalCameraStream: CameraStream = null;
    public useOldCameraStream = false;

    // Is external cam
    public isExternal = false;
    public externalCode: number = null;
    public externalPassword: string = null;

    // Spectators
    private publishersRoomHandleMap = new Map<number, JanusJS.PluginHandle>();
    private subscribersRoomHandlesMap = new Map<number, JanusJS.PluginHandle[]>();
    private subscribersHandleMap = new Map<number, JanusJS.PluginHandle>();

    public serverRoomUserMap = new Map<string, Map<number, DCFireStoreUser>>();
    public roomToUserMap = new Map<number, DCFireStoreUser>();
    public isRestartingOwnStream = false;

    private opaqueId = 'dcdartsroom-' + randomString(12);
    private _eventAfterInitInstances: Map<string, JanusEvent> = new Map();
    private _isInitializing: Map<string, boolean> = new Map();
    private _isReconnectingInstances: Map<string, boolean> = new Map();
    private _forceReconnectAfterDestroy: Map<string, boolean> = new Map();
    private _reconnectErrorInstances: Map<string, boolean> = new Map();
    private _reconnectTries: Map<string, number> = new Map();

    private _debug = environment.debug; // environment.debug

    constructor(
        private _ownJanusService: OwnJanusService,
        private _janusEventService: JanusEventService,
        private _janusServerApiService: JanusServerApiService
    ) {}

    init(janusServerHost: string): Promise<void> {
        return new Promise(async (resolve, reject) => {
            if (this._isInitializing.has(janusServerHost)) {
                this.log('Janus instance already initializing:', janusServerHost);
                reject();
                return; // Instance already initializing
            }

            if (this.janusInstances.has(janusServerHost)) {
                this.log('Janus instance already initialized for:', janusServerHost);
                resolve();
                return; // Instance already exists
            }

            if (!this.janusServers.length) {
                try {
                    await this.getJanusServers();
                } catch (err) {
                    this.log(err);
                }
            }

            let serverInfo = this.janusServers.find((server) => server.hostname === janusServerHost);
            if (!serverInfo) {
                try {
                    await this.getJanusServers();
                } catch (err) {
                    this.log(err);
                }

                serverInfo = this.janusServers.find((server) => server.hostname === janusServerHost);
                if (!serverInfo) {
                    this.error('Server not found:', janusServerHost);
                    reject(new Error(`Server not found: ${janusServerHost}`));
                    return;
                }
            }

            this._isInitializing.set(janusServerHost, true);

            this.log('Initializing Janus for:', janusServerHost);

            Janus.init({
                debug: this._debug,
                dependencies: Janus.useDefaultDependencies({ adapter: adapter }),
                callback: () => this.onJanusInitialized(serverInfo, janusServerHost, resolve, reject),
            });
        });
    }

    get ownCamera(): JanusRoom {
        return this._ownJanusService.ownCamera;
    }

    set ownCamera(ownCamera: JanusRoom) {
        this._ownJanusService.ownCamera = ownCamera;
    }

    get ownVoiceCall(): JanusRoom {
        return this._ownJanusService.ownVoiceCall;
    }

    set ownVoiceCall(ownVoiceCall: JanusRoom) {
        this._ownJanusService.ownVoiceCall = ownVoiceCall;
    }

    async getJanusServers(): Promise<void> {
        return new Promise(async (resolve, reject) => {
            this._janusServerApiService
                .getJanusServers({ is_prod: environment.production })
                .then((res) => {
                    this.janusServers = res.data;
                    resolve();
                })
                .catch((err) => {
                    reject(new Error(`Error retrieving Janus servers: ${err}`));
                });
        });
    }

    getJanusServer(janusServerHost): JanusServer {
        return this.janusServers.find((server) => server.hostname === janusServerHost);
    }

    getJanusEndpoint(janusServer: JanusServer): string {
        return 'wss://' + janusServer.hostname + ':' + janusServer.ws_port + '/ws';
    }

    onJanusInitialized(serverInfo: JanusServer, janusServerHost: string, resolve, reject) {
        if (!Janus.isWebrtcSupported()) {
            this.error('WebRTC is not supported by this browser.');
            return;
        }

        const serverUrl = 'wss://' + janusServerHost + ':' + serverInfo.ws_port + '/ws';
        const newJanus = new Janus({
            server: serverUrl,
            destroyOnUnload: false,
            success: () => {
                this.log('Janus connected to:', serverUrl);
                this.janusInstances.set(janusServerHost, newJanus);
                this._isInitializing.delete(janusServerHost);
                this.emitEventAfterConnected(janusServerHost);
                resolve(); // Janus is successfully initialized
            },
            error: (error: any) => {
                this.error('Janus connection error:', error);

                if (
                    String(error).includes('Is the server down?') ||
                    String(error).includes('Lost connection to the server')
                ) {
                    if (!this._reconnectErrorInstances.has(janusServerHost)) {
                        this._reconnectErrorInstances.set(janusServerHost, true);

                        let canRetry = true;
                        const numOfTries = this._reconnectTries.get(janusServerHost);
                        if (numOfTries) {
                            if (numOfTries < 5) {
                                this._reconnectTries.set(janusServerHost, numOfTries + 1);
                            } else {
                                canRetry = false;
                            }
                        } else {
                            this._reconnectTries.set(janusServerHost, 1);
                        }

                        setTimeout(() => {
                            this._isInitializing.delete(janusServerHost);
                            if (canRetry) {
                                this.reconnect(janusServerHost);
                            } else {
                                this.error('Maximum number of retries reached for ' + janusServerHost);
                            }
                            this._reconnectErrorInstances.delete(janusServerHost);
                        }, 2000);
                    }
                }

                reject(error); // There was an error initializing Janus
            },
            destroyed: () => {
                this.log('Janus connection destroyed for:', serverUrl);
                this.janusInstances.delete(janusServerHost);
            },
        });
    }

    reconnect(janusServerHost: string) {
        if (janusServerHost) {
            this.log('Attempting to reconnect to Janus instance', janusServerHost);

            const janus = this.janusInstances.get(janusServerHost);
            if (!janus) {
                this._isReconnectingInstances.delete(janusServerHost);
                this.init(janusServerHost);
            } else {
                if (
                    this._isReconnectingInstances.has(janusServerHost) &&
                    !this._forceReconnectAfterDestroy.has(janusServerHost)
                ) {
                    this.log('Already reconnecting', janusServerHost);
                    return;
                }

                this._isReconnectingInstances.set(janusServerHost, true);

                this.log('Reconnecting to', janusServerHost, janus.isConnected());

                janus.reconnect({
                    success: () => {
                        this.log(`Reconnected Janus instance ${janusServerHost}`);
                        // Handle successful reconnection for this instance
                        this._eventAfterInitInstances.set(janusServerHost, {
                            type: JanusEventType.ServerReconnected,
                            janusServerHost,
                        });
                        this.emitEventAfterConnected(janusServerHost);
                    },
                    error: (error) => {
                        this.error(`Error reconnecting Janus instance ${janusServerHost}, reinitializing:`, error);
                        this.destroy(janus, janusServerHost, true);
                    },
                });
            }
        } else {
            this.log('Attempting to reconnect to all Janus instances');

            this.janusInstances.forEach((janus, janusServerHost) => {
                if (
                    this._isReconnectingInstances.has(janusServerHost) &&
                    !this._forceReconnectAfterDestroy.has(janusServerHost)
                ) {
                    this.log('Already reconnecting', janusServerHost);
                    return;
                }

                this._isReconnectingInstances.set(janusServerHost, true);

                this.log('Reconnecting to', janusServerHost, janus.isConnected());

                janus.reconnect({
                    success: () => {
                        this.log(`Reconnected Janus instance ${janusServerHost}`);
                        // Handle successful reconnection for this instance
                        this._eventAfterInitInstances.set(janusServerHost, {
                            type: JanusEventType.ServerReconnected,
                            janusServerHost,
                        });
                        this.emitEventAfterConnected(janusServerHost);
                    },
                    error: (error) => {
                        this.error(`Error reconnecting Janus instance ${janusServerHost}, reinitializing:`, error);
                        this.destroy(janus, janusServerHost, true);
                    },
                });
            });
        }
    }

    destroy(janus: Janus, janusServerHost: string, reinitAfter: boolean): void {
        if (janus) {
            janus.destroy({
                cleanupHandles: true,
                notifyDestroyed: true,
                unload: true,
                success: () => {
                    this.log(`Destroyed Janus instance ${janusServerHost}`);
                    this.janusInstances.delete(janusServerHost);
                    if (reinitAfter) {
                        setTimeout(() => {
                            this.reinitAfterDestroy(janusServerHost);
                        }, 1000);
                    } else {
                        this._isReconnectingInstances.delete(janusServerHost);
                        this._forceReconnectAfterDestroy.delete(janusServerHost);
                    }
                },
                error: (error) => {
                    this.error(`Error destroying Janus instance ${janusServerHost}:`, error);
                    this.janusInstances.delete(janusServerHost);
                    if (reinitAfter) {
                        setTimeout(() => {
                            this.reinitAfterDestroy(janusServerHost);
                        }, 1000);
                    } else {
                        this._isReconnectingInstances.delete(janusServerHost);
                        this._forceReconnectAfterDestroy.delete(janusServerHost);
                    }
                },
            });
        } else {
            this.janusInstances.forEach((janus, janusServerHost) => {
                janus.destroy({
                    cleanupHandles: true,
                    notifyDestroyed: true,
                    unload: true,
                    success: () => {
                        this.log(`Destroyed Janus instance ${janusServerHost}`);
                        this.janusInstances.delete(janusServerHost);
                        if (reinitAfter) {
                            setTimeout(() => {
                                this.reinitAfterDestroy(janusServerHost);
                            }, 1000);
                        } else {
                            this._isReconnectingInstances.delete(janusServerHost);
                            this._forceReconnectAfterDestroy.delete(janusServerHost);
                        }
                    },
                    error: (error) => {
                        this.error(`Error destroying Janus instance ${janusServerHost}:`, error);
                        this.janusInstances.delete(janusServerHost);
                        if (reinitAfter) {
                            setTimeout(() => {
                                this.reinitAfterDestroy(janusServerHost);
                            }, 1000);
                        } else {
                            this._isReconnectingInstances.delete(janusServerHost);
                            this._forceReconnectAfterDestroy.delete(janusServerHost);
                        }
                    },
                });
            });
        }
    }

    reinitAfterDestroy(janusServerHost: string): void {
        this._forceReconnectAfterDestroy.set(janusServerHost, true);
        this._eventAfterInitInstances.set(janusServerHost, {
            type: JanusEventType.ServerDestroyed,
            janusServerHost,
        });
        this.init(janusServerHost);
    }

    emitEventAfterConnected(janusServerHost: string): void {
        this._isReconnectingInstances.delete(janusServerHost);
        this._reconnectTries.delete(janusServerHost);
        this._forceReconnectAfterDestroy.delete(janusServerHost);
        if (this._eventAfterInitInstances.has(janusServerHost)) {
            this._janusEventService.emitEvent(this._eventAfterInitInstances.get(janusServerHost));
            this._eventAfterInitInstances.delete(janusServerHost);
        }
    }

    public addUserToRoom(janusServerHost: string, roomId: number, user: DCFireStoreUser) {
        let roomUserMap = this.serverRoomUserMap.get(janusServerHost);
        if (!roomUserMap) {
            roomUserMap = new Map<number, DCFireStoreUser>();
            this.serverRoomUserMap.set(janusServerHost, roomUserMap);
        }
        roomUserMap.set(roomId, user);
    }

    public removeUserFromRoom(roomId: number) {
        this.roomToUserMap.delete(roomId);
    }

    public startPublishing(
        publishType: PublishType,
        media: PublishingMediaDevices,
        userMedia: UserMedia,
        forceServerHost: string = null
    ): Promise<JanusRoom> {
        return new Promise(async (resolve, reject) => {
            if (!this.janusServers.length) {
                try {
                    await this.getJanusServers();
                } catch (err) {
                    this.log(err);
                }
            }

            // Shuffle array to randomize server selection order for load distribution
            let shuffledServers = this.janusServers
                .filter((server) => !server.is_virt_only && server.is_active)
                .sort(() => 0.5 - Math.random());

            if (forceServerHost) {
                shuffledServers = this.janusServers.filter((server) => server.hostname === forceServerHost);
            }

            if (!shuffledServers.length) {
                reject(new Error('Failed to start publishing with forced server host: ' + forceServerHost));
                return;
            }

            for (const janusServer of shuffledServers) {
                try {
                    this.log('Trying to connect to ' + janusServer.hostname);
                    const janusRoom = await this.createRoom(janusServer, publishType, CAMERA_TYPE.CURRENT_DEVICE);

                    if (publishType === 'video') {
                        this.ownCamera.janusServerHost = janusServer.hostname;
                        this.ownCamera.roomID = janusRoom.roomID;
                        this.ownCamera.camType = janusRoom.camType;

                        this.videoMedia = media;
                    } else if (publishType === 'audio') {
                        this.ownVoiceCall.janusServerHost = janusServer.hostname;
                        this.ownVoiceCall.roomID = janusRoom.roomID;

                        this.audioMedia = media;
                    }

                    // Room now exists. Now join & publish
                    this.publishOwnFeed(publishType, userMedia);
                    resolve(janusRoom);
                    return; // Exit the loop and function on success
                } catch (error) {
                    this.error(`Error during room creation/joining on server ${janusServer.hostname}:`, error);
                    // Continue to the next iteration in case of error
                }
            }

            // Only reject if all servers have been tried and none succeeded
            reject(new Error('Failed to start publishing after trying all servers.'));
        });
    }

    public async startSmartDevice(): Promise<JanusRoom> {
        return new Promise(async (resolve, reject) => {
            if (!this.janusServers.length) {
                try {
                    await this.getJanusServers();
                } catch (err) {
                    this.log(err);
                }
            }

            // Shuffle array to randomize Virt only server selection order for load distribution
            const virtOnlyShuffledServers = this.janusServers
                .filter((server) => server.is_virt_only && server.is_active)
                .sort(() => 0.5 - Math.random());

            for (const janusServer of virtOnlyShuffledServers) {
                try {
                    const janusRoom = await this.createRoom(janusServer, 'video', CAMERA_TYPE.SMART_DEVICE);
                    resolve(janusRoom);
                    return; // Successfully created a room, so exit the loop
                } catch (error) {
                    this.error(`Error during room creation/joining on server ${janusServer.hostname}:`, error);
                    // Don't reject yet, try the next server
                }
            }

            // If Virt only servers do not work, continue to use the other servers that are not Virt only
            // Shuffle array to randomize all server selection order for load distribution
            const shuffledServers = this.janusServers
                .filter((server) => server.is_active)
                .sort(() => 0.5 - Math.random());

            for (const janusServer of shuffledServers) {
                try {
                    const janusRoom = await this.createRoom(janusServer, 'video', CAMERA_TYPE.SMART_DEVICE);
                    resolve(janusRoom);
                    return; // Successfully created a room, so exit the loop
                } catch (error) {
                    this.error(`Error during room creation/joining on server ${janusServer.hostname}:`, error);
                    // Don't reject yet, try the next server
                }
            }

            // If all servers have been tried and none succeeded, reject the Promise
            reject(new Error('Failed to start publishing after trying all servers.'));
        });
    }

    useExternalRoom(janusRoom: JanusRoom, camType: CAMERA_TYPE): Promise<JanusRoom> {
        this.ownCamera.janusServerHost = janusRoom.janusServerHost;
        this.ownCamera.roomID = janusRoom.roomID;
        if (camType) {
            this.ownCamera.camType = camType;
        }
        janusRoom.camType = this.ownCamera.camType;

        this.usingExternal = true;
        return this.joinRoom(janusRoom, 'video', 'external', null, null, true, false, false);
    }

    spectateRoom(
        janusRoom: JanusRoom,
        publishType: PublishType,
        user: DCFireStoreUser,
        userMedia: UserMedia,
        checkForSetup: boolean,
        record: boolean,
        isSpectating: boolean
    ): Promise<JanusRoom> {
        return this.joinRoom(janusRoom, publishType, 'spectator', user, userMedia, checkForSetup, record, isSpectating);
    }

    // Join the room as a publisher
    joinRoom(
        janusRoom: JanusRoom,
        publishType: PublishType,
        type: JanusJoinRoomType,
        user: DCFireStoreUser,
        userMedia: UserMedia,
        checkForSetup: boolean,
        record = false,
        isSpectating = false
    ): Promise<JanusRoom> {
        return new Promise(async (resolve, reject) => {
            // First, ensure the Janus instance for the desired server is initialized
            if (!this.janusInstances.has(janusRoom.janusServerHost)) {
                // If the instance doesn't exist, initialize it
                // Note: Assuming `init` method is adapted to work asynchronously and properly handle instance setup
                await this.init(janusRoom.janusServerHost); // You may need to adjust this to actually wait for initialization to complete
            }

            const janus = this.janusInstances.get(janusRoom.janusServerHost);
            if (!janus) {
                reject(new Error(`Failed to get Janus instance for server: ${janusRoom.janusServerHost}`));
                return;
            }

            this.log('Joining room', janusRoom.roomID, janusRoom.janusServerHost);

            let streamMetaData: StreamDisplayMetaData = {};

            if (type === 'spectator') {
                streamMetaData.user = type;
            } else if (type === 'external') {
                this.isPublishingVideo = false;
                streamMetaData.user = type;
            }

            let joinAsPublisher = {
                request: 'join',
                room: janusRoom.roomID,
                ptype: 'publisher',
                display: encodeURIComponent(JSON.stringify(streamMetaData)),
            };

            janus.attach({
                plugin: 'janus.plugin.videoroom',
                opaqueId: this.opaqueId,
                success: (pluginHandle: JanusJS.PluginHandle) => {
                    if (type === 'spectator' && userMedia) {
                        this.publishersRoomHandleMap.set(janusRoom.roomID, pluginHandle);
                    } else if (type === 'external' && this.ownUserMedia) {
                        this.externalPublisherRoomHandle = pluginHandle;
                    }

                    pluginHandle.send({
                        message: joinAsPublisher,
                        success: () => {
                            this.log('Joined room as publisher:', janusRoom.roomID);
                            resolve(janusRoom);
                        },
                        error: (error: any) => {
                            this.error('Error joining room as publisher:', error);
                            reject();
                        },
                    });
                },
                onmessage: (msg: JanusPublisherMessage, jsep: JanusJS.JSEP) => {
                    this.log('ONMESSAGE-p', msg);

                    if (msg.videoroom) {
                        if (msg.videoroom === 'joined') {
                            // Handle joined event
                            const { publishers } = msg;
                            publishers.forEach((publisher) => {
                                if (
                                    publisher.id !== this.ownVideoPublisherId &&
                                    publisher.id !== this.ownAudioPublisherId
                                ) {
                                    this.subscribeToPublisher(
                                        janusRoom.janusServerHost,
                                        publisher,
                                        janusRoom.roomID,
                                        type,
                                        user,
                                        userMedia,
                                        record,
                                        isSpectating
                                    );
                                }
                            });
                        } else if (msg.videoroom === 'event') {
                            if ('publishers' in msg) {
                                // Handle new publishers
                                const { publishers } = msg;
                                publishers.forEach((publisher) => {
                                    if (
                                        publisher.id !== this.ownVideoPublisherId &&
                                        publisher.id !== this.ownAudioPublisherId
                                    ) {
                                        this.subscribeToPublisher(
                                            janusRoom.janusServerHost,
                                            publisher,
                                            janusRoom.roomID,
                                            type,
                                            user,
                                            userMedia,
                                            record,
                                            isSpectating
                                        );
                                    }
                                });
                            } else if ('unpublished' in msg) {
                                // Handle a publisher stopping their stream
                                const { unpublished } = msg;
                                this.leaveSubscriber(unpublished, janusRoom.roomID, type, 'unpublished');
                            } else if ('leaving' in msg) {
                                const { leaving } = msg;
                                this.leaveSubscriber(leaving, janusRoom.roomID, type, 'leaving');
                            } else if ('error_code' in msg && 'error' in msg) {
                                if (publishType === 'video' && (checkForSetup || type === 'external')) {
                                    const { error_code, error } = msg;
                                    switch (error_code) {
                                        case 426:
                                            if (String(error).includes('No such room')) {
                                                this.restartOwnStream(janusRoom.camType);
                                            }
                                            break;
                                    }
                                }
                            }
                        }
                    }

                    if (jsep) {
                        this.log('DC JSEP', jsep);
                    }
                },
                onremotetrack: (track, mid, on) => {
                    this.log('Handle 1: Received remote track', track);
                },
                error: (error: string) => {
                    this.error(`Error attaching to the before joining room ${janusRoom.roomID}`, error);
                },
            });
        });
    }

    restartOwnStream(camType: CAMERA_TYPE): void {
        this.log('restartOwnStream', camType);

        const media = this.videoMedia;
        const smartDevice = this.smartDevice;
        this.stopOwnStream().finally(() => {
            switch (camType) {
                case CAMERA_TYPE.CURRENT_DEVICE:
                    this._janusEventService.emitEvent({
                        type: JanusEventType.ResetCurrentDevice,
                        media,
                    });
                    break;
                case CAMERA_TYPE.EXTERNAL_DEVICE:
                    this.useOldCameraStream = true;
                    this._janusEventService.emitEvent({
                        type: JanusEventType.ResetExternalDevice,
                    });
                    break;
                case CAMERA_TYPE.SMART_DEVICE:
                    this._janusEventService.emitEvent({
                        type: JanusEventType.ResetSmartDevice,
                        smartDevice,
                    });
                    break;
            }
        });
    }

    leaveSubscriber(
        publisherId: number,
        roomId: number,
        type: JanusJoinRoomType,
        event: 'unpublished' | 'leaving'
    ): void {
        this.log('leaveSubscriber', publisherId, roomId, type, event);
        if (type === 'spectator') {
            if (this.subscribersHandleMap.has(publisherId)) {
                if (event === 'leaving') {
                    const subscriber = this.subscribersHandleMap.get(publisherId);
                    if (!subscriber.detached) {
                        this.leaveRoomHandle(subscriber).finally(() => {
                            this.detachRoomHandle(subscriber).finally(() => {
                                this.subscribersHandleMap.delete(publisherId);
                            });
                        });
                    } else {
                        this.subscribersHandleMap.delete(publisherId);
                    }
                }
            }
        } else if (type === 'external') {
            if (this.ownSubscribersRoomHandles.has(publisherId)) {
                if (event === 'leaving') {
                    const subscriber = this.ownSubscribersRoomHandles.get(publisherId);
                    if (!subscriber.detached) {
                        this.leaveRoomHandle(subscriber).finally(() => {
                            this.detachRoomHandle(subscriber).finally(() => {
                                this.ownSubscribersRoomHandles.delete(publisherId);
                            });
                        });
                    } else {
                        this.ownSubscribersRoomHandles.delete(publisherId);
                    }
                }

                if (event === 'unpublished' && this.usingExternal) {
                    this._janusEventService.emitEvent({
                        type: JanusEventType.ExternalConnectionLost,
                    });
                }
            }
        }
    }

    private async subscribeToPublisher(
        janusServerHost: string,
        publisher: JanusRoomPublisher,
        roomId: number,
        type: JanusJoinRoomType,
        user: DCFireStoreUser,
        userMedia: UserMedia,
        record = false,
        isSpectating = false
    ): Promise<void> {
        return new Promise(async (resolve, reject) => {
            // Extract the feed_id and other details from the publisher object
            const feedId = publisher.id;
            let feedPluginHandle: JanusJS.PluginHandle = null;

            // First, ensure the Janus instance for the desired server is initialized
            if (!this.janusInstances.has(janusServerHost)) {
                // If the instance doesn't exist, initialize it
                // Note: Assuming `init` method is adapted to work asynchronously and properly handle instance setup
                await this.init(janusServerHost); // You may need to adjust this to actually wait for initialization to complete
            }

            const janus = this.janusInstances.get(janusServerHost);
            if (!janus) {
                reject(new Error(`Failed to get Janus instance for server: ${janusServerHost}`));
                return;
            }

            let streams = publisher.streams;
            let subscription = [];
            streams.forEach((stream) => {
                subscription.push({
                    feed: feedId, // This is mandatory
                    mid: stream.mid, // This is optional (all streams, if missing)
                });
            });

            const decodedMetaData = decodeURIComponent(publisher.display);
            const displayMetaData: StreamDisplayMetaData = JSON.parse(decodedMetaData);

            const subscribe = {
                room: roomId,
                ptype: 'subscriber',
                request: 'join',
                streams: subscription,
            };

            if (type === 'spectator' && userMedia) {
                if (this.subscribersHandleMap.has(feedId)) {
                    this.log('Existing subscriber for publisher ' + feedId);
                    const existingHandle = this.subscribersHandleMap.get(feedId);
                    if (!existingHandle.detached) {
                        await this.leaveAsSubscriber(existingHandle).catch(this.error);
                    }
                }
            } else if (type === 'external' && this.ownUserMedia) {
                if (this.ownSubscribersRoomHandles.has(feedId)) {
                    this.log('Existing subscriber for external ' + feedId);
                    const existingHandle = this.ownSubscribersRoomHandles.get(feedId);
                    if (!existingHandle.detached) {
                        await this.leaveAsSubscriber(existingHandle).catch(this.error);
                    }
                }
            }

            janus.attach({
                plugin: 'janus.plugin.videoroom',
                opaqueId: this.opaqueId,
                success: (pluginHandle: JanusJS.PluginHandle) => {
                    this.log('Attached for subscriber', pluginHandle.getId(), roomId);

                    feedPluginHandle = pluginHandle;

                    if (type === 'spectator' && userMedia) {
                        if (this.subscribersRoomHandlesMap.has(roomId)) {
                            this.subscribersRoomHandlesMap.get(roomId).push(feedPluginHandle);
                        } else {
                            this.subscribersRoomHandlesMap.set(roomId, [feedPluginHandle]);
                        }
                        this.subscribersHandleMap.set(feedId, feedPluginHandle);
                    } else if (type === 'external' && this.ownUserMedia) {
                        this.ownSubscribersRoomHandles.set(feedId, feedPluginHandle);
                    }

                    this.log(
                        'Plugin attached! (' + feedPluginHandle.getPlugin() + ', id=' + feedPluginHandle.getId() + ')'
                    );
                    this.log('  -- This is a subscriber');

                    feedPluginHandle.send({
                        message: subscribe,
                        success: () => {
                            this.log('Subscriber handle success', feedId);

                            feedPluginHandle.send({
                                message: {
                                    request: 'subscribe',
                                    streams: [{ feed: feedId }],
                                },
                                success: () => {
                                    this.log('Subscribed to feed:', feedId);
                                },
                                error: (error: any) => {
                                    this.error('Error joining room as subscriber:', error);
                                },
                            });
                        },
                        error: (error: any) => {
                            this.error('Error joining room as subscriber:', error);
                        },
                    });
                },
                error: (error: string) => {
                    this.error(`Error attaching to the room ${roomId}`, error);
                },
                iceState: (state) => {
                    this.log('ICE state (feed #' + feedId + ') changed to ' + state);
                },
                webrtcState: (on) => {
                    this.log(
                        'Janus says this WebRTC PeerConnection (feed #' +
                            feedId +
                            ') is ' +
                            (on ? 'up' : 'down') +
                            ' now'
                    );
                },
                slowLink: (uplink, lost, mid) => {
                    this.error(
                        'Janus reports problems ' +
                            (uplink ? 'sending' : 'receiving') +
                            ' packets on mid ' +
                            mid +
                            ' (' +
                            lost +
                            ' lost packets)'
                    );
                },
                onmessage: (msg, jsep: JanusJS.JSEP) => {
                    this.log('ONMESSAGE-s', msg, jsep);

                    if (jsep && jsep.type === 'offer') {
                        this.log('HANDLING JSEP', jsep);
                        this.handleSubscriberJsep(feedPluginHandle, jsep, roomId);
                    }
                },
                onremotetrack: (track: DCStreamTrack, mid, on, event: { reason: string }) => {
                    this.log(
                        'Handle 2: Remote track handler: ' + feedPluginHandle.getId(),
                        feedPluginHandle.detached,
                        on,
                        userMedia,
                        publisher
                    );
                    this.log('Handle 2: Received remote track', track.kind, track, 'from feed', feedId, mid, on, event);
                    if (on) {
                        track.feedId = feedId;
                        if (type === 'spectator' && userMedia) {
                            this.log('Binding users userMedia', displayMetaData.facingMode, mid, track.id);
                            if (track.kind === 'video') {
                                let forceTrack = true;
                                if (
                                    displayMetaData.facingMode === FacingModes.PLAYER &&
                                    ((isSpectating && user?.room?.disablePlayerCamForSpectators === true) ||
                                        user?.room?.disablePlayerCamForAll === true)
                                ) {
                                    if (!userMedia.videoStreams.noticedPlayerCam) {
                                        userMedia.videoStreams.noticedPlayerCam = true;
                                        this._janusEventService.emitEvent({
                                            type: JanusEventType.PlayerCamNotEnabled,
                                            user: user,
                                        });
                                    }
                                    forceTrack = false;
                                }

                                if (forceTrack) {
                                    userMedia.addVideoTrack(track, mid, displayMetaData, publisher.video_codec);

                                    if (userMedia.remoteEvents$) {
                                        userMedia.remoteEvents$.next(mid);
                                    }
                                }
                            } else if (track.kind === 'audio') {
                                userMedia.addAudioTrack(track, displayMetaData);
                            }
                        } else if (type === 'external' && this.ownUserMedia) {
                            this.log('Binding own userMedia', displayMetaData.facingMode, mid, track.id);
                            if (track.kind === 'video' && !this.isPublishingVideo) {
                                this.ownUserMedia.addVideoTrack(track, mid, displayMetaData, publisher.video_codec);

                                if (this.ownUserMedia.remoteEvents$) {
                                    this.ownUserMedia.remoteEvents$.next(mid);
                                }
                            } else if (track.kind === 'audio' && !this.isPublishingAudio) {
                                this.ownUserMedia.addAudioTrack(track, displayMetaData);
                            }

                            if (this._janusEventService && janus?.isConnected()) {
                                this._janusEventService.emitEvent({
                                    type: JanusEventType.OwnExternalDeviceConnected,
                                    user: user,
                                });
                            }
                        }
                    } else {
                        if (type === 'spectator' && userMedia) {
                            userMedia.removeTrack(track, displayMetaData);
                        } else if (type === 'external' && this.ownUserMedia) {
                            this.ownUserMedia.removeTrack(track, displayMetaData);
                        }
                    }

                    if (track.kind === 'video') {
                        if (type === 'spectator' && userMedia) {
                            if (
                                displayMetaData.facingMode === FacingModes.BOARD &&
                                userMedia.videoStreams?.board?.feedId === feedId
                            ) {
                                if (displayMetaData.scale) {
                                    userMedia.videoStreams.board.scale = displayMetaData.scale;
                                }
                            }

                            let activeStreams = null;
                            if (activeStreams !== false && userMedia.videoStreams.board) {
                                activeStreams = userMedia.videoStreams.board.isActive;
                            }
                            if (activeStreams !== false && userMedia.videoStreams.player) {
                                activeStreams = userMedia.videoStreams.player.isActive;
                            }
                            userMedia.videoStreams.activeStreams = activeStreams;
                        } else if (type === 'external' && this.ownUserMedia) {
                            if (
                                displayMetaData.facingMode === FacingModes.BOARD &&
                                this.ownUserMedia.videoStreams?.board?.feedId === feedId
                            ) {
                                if (displayMetaData.scale) {
                                    this.ownUserMedia.videoStreams.board.scale = displayMetaData.scale;
                                }
                            }

                            let activeStreams = null;
                            if (activeStreams !== false && this.ownUserMedia.videoStreams.board) {
                                activeStreams = this.ownUserMedia.videoStreams.board.isActive;
                            }
                            if (activeStreams !== false && this.ownUserMedia.videoStreams.player) {
                                activeStreams = this.ownUserMedia.videoStreams.player.isActive;
                            }
                            this.ownUserMedia.videoStreams.activeStreams = activeStreams;
                        }

                        if (on) {
                            if (type === 'spectator' && userMedia) {
                                userMedia.videoStreams.kind = displayMetaData.kind;
                            } else if (type === 'external' && this.ownUserMedia) {
                                this.ownUserMedia.videoStreams.kind = displayMetaData.kind;
                            }
                        }
                    }
                },
                oncleanup: () => {
                    this.log(' ::: Got a cleanup notification (remote feed ' + feedId + ') :::');
                },
            });
        });
    }

    leaveAsSubscriber(pluginHandle: JanusJS.PluginHandle): Promise<void> {
        this.log('***** leaveAsSubscriber', pluginHandle.getId());
        return new Promise((resolve, reject) => {
            pluginHandle.send({
                message: { request: 'leave' },
                success: () => {
                    this.log('***** leaveAsSubscriber: Left as subscriber');
                    pluginHandle.detach({
                        success: () => {
                            this.log('***** leaveAsSubscriber: Previous handle detached successfully');
                            resolve();
                        },
                        error: () => {
                            reject();
                        },
                    });
                },
                error: (error) => {
                    this.error('***** leaveAsSubscriber: Error leaving room:', error);
                    reject();
                },
            });
        });
    }

    handleSubscriberJsep(pluginHandle: JanusJS.PluginHandle, jsep: JanusJS.JSEP, room: number) {
        if (!pluginHandle) {
            return;
        }

        if (jsep.type === 'offer') {
            pluginHandle.createAnswer({
                jsep,
                success: (jsep) => {
                    // Got our jsep, now send our answer back to the peer
                    pluginHandle.send({ message: { request: 'start', room }, jsep: jsep });
                },
                error: (error) => {
                    // An error occurred while creating the answer
                    this.error('WebRTC error:', error);
                },
            });
        }
    }

    createRoom(janusServer: JanusServer, publishType: PublishType, camType: CAMERA_TYPE): Promise<JanusRoom> {
        return new Promise(async (resolve, reject) => {
            this.log(`Attempting to create a room on server: ${janusServer.hostname}`);

            if (!this.janusInstances.has(janusServer.hostname)) {
                this.log(`${janusServer.hostname} not found in instances. Initializing...`);
                try {
                    await this.init(janusServer.hostname);
                    this.log(`${janusServer.hostname} initialization succeeded.`);
                } catch (error) {
                    this.error(`Failed to initialize Janus on server ${janusServer.hostname}:`, error);
                    reject(error);
                    return;
                }
            }

            const janus = this.janusInstances.get(janusServer.hostname);
            if (!janus) {
                const errorMsg = `Failed to get Janus instance for server: ${janusServer.hostname} after initialization.`;
                this.error(errorMsg);
                reject(new Error(errorMsg));
                return;
            }

            let create = {
                request: 'create',
                description: 'DartCounter Room',
                is_private: false,
                secret: 'dcsecretpass',
                publishers: 6,
                bitrate: janusServer.room_bitrate * 1000,
                bitrate_cap: janusServer.room_bitrate_cap,
                audiocodec: janusServer.room_audiocodec,
                videocodec: janusServer.room_videocodec,
                h264_profile: janusServer.room_h264_profile,
                videoorient_ext: false,
                destroy_if_empty: true,
                empty_timeout: 3600,
                threads: janusServer.room_threads,
            };

            janus.attach({
                plugin: 'janus.plugin.videoroom',
                opaqueId: this.opaqueId,
                success: (pluginHandle: JanusJS.PluginHandle) => {
                    this.log(`Attached to plugin on ${janusServer.hostname}, attempting to create room...`);
                    if (publishType === 'video') {
                        this.ownVideoPublisherRoomHandle = pluginHandle;
                    } else if (publishType === 'audio') {
                        this.ownAudioPublisherRoomHandle = pluginHandle;
                    }
                    this.setOwnRoomCallbacks(janus, publishType, pluginHandle);

                    pluginHandle.send({
                        message: create,
                        success: (result: any) => {
                            this.log(`Room created on ${janusServer.hostname}. Room ID: ${result.room}`);

                            this.ownJanusServer = janusServer;

                            resolve({
                                roomID: result.room,
                                janusServerHost: janusServer.hostname,
                                camType,
                            });
                        },
                        error: (error: any) => {
                            this.error(`Error creating room on ${janusServer.hostname}:`, error);
                            reject(error);
                        },
                    });
                },
                error: (error) => {
                    this.error(`Error attaching to plugin on ${janusServer.hostname}:`, error);
                    reject(error);
                },
            });
        });
    }

    async publishOwnFeed(publishType: PublishType, userMedia: UserMedia): Promise<void> {
        if (publishType === 'video') {
            this.usingExternal = false;
        }

        this.log('Publishing own feed', publishType, this.isPublishingVideo, this.isPublishingAudio);

        if (
            (this.isPublishingVideo && publishType === 'video') ||
            (this.isPublishingAudio && publishType === 'audio')
        ) {
            this.log('Republish and offer');
            this.createOfferAndUpdateStream(publishType, userMedia);
        } else {
            const handle = this.getPublisherHandle(publishType);
            this.log('Publishing with handle', handle);
            if (handle) {
                this.joinOwnRoomAsPublisherAndCreateOffer(
                    publishType,
                    false,
                    userMedia,
                    publishType === 'video',
                    publishType === 'audio'
                );
            } else {
                let janusServerHost = this.ownCamera.janusServerHost;
                if (publishType === 'audio') {
                    janusServerHost = this.ownVoiceCall.janusServerHost;
                }
                if (!this.janusInstances.has(janusServerHost)) {
                    // If the instance doesn't exist, initialize it
                    await this.init(janusServerHost);
                }

                const janus = this.janusInstances.get(janusServerHost);
                if (!janus) {
                    this.error(`Failed to get Janus instance for server: ${janusServerHost}`);
                    return;
                }

                janus.attach({
                    plugin: 'janus.plugin.videoroom',
                    opaqueId: this.opaqueId,
                    success: (pluginHandle: JanusJS.PluginHandle) => {
                        this.log('Published own feed');

                        if (publishType === 'video') {
                            this.ownVideoPublisherRoomHandle = pluginHandle;
                        } else if (publishType === 'audio') {
                            this.ownAudioPublisherRoomHandle = pluginHandle;
                        }
                        this.setOwnRoomCallbacks(janus, publishType, pluginHandle); // Set the callbacks

                        this.joinOwnRoomAsPublisherAndCreateOffer(
                            publishType,
                            false,
                            userMedia,
                            publishType === 'video',
                            publishType === 'audio'
                        );
                    },
                });
            }
        }
    }

    createOfferAndUpdateStream(publishType: PublishType, userMedia: UserMedia): void {
        this.log('Creating offer and configure', userMedia);
        this.log('Current tracks', this.currentVideoTracks, this.currentAudioTracks);

        const tracks: JanusJS.TrackOption[] = [];
        if (this.isPublishingVideo && publishType === 'video' && userMedia.videoStreams.board) {
            const hasTrack = this.currentVideoTracks?.some((track) => track.group === FacingModes.BOARD);
            tracks.push({
                add: this.currentVideoTracks === null ? true : hasTrack === false,
                replace: this.currentVideoTracks === null ? false : hasTrack === true,
                type: 'video',
                group: FacingModes.BOARD,
                capture: userMedia.videoStreams.board.stream.getVideoTracks()[0],
                recv: false,
            });
        }

        if (this.isPublishingVideo && publishType === 'video' && userMedia.videoStreams.player) {
            const hasTrack = this.currentVideoTracks?.some((track) => track.group === FacingModes.PLAYER);
            tracks.push({
                add: this.currentVideoTracks === null ? true : hasTrack === false,
                replace: this.currentVideoTracks === null ? false : hasTrack === true,
                type: 'video',
                group: FacingModes.PLAYER,
                capture: userMedia.videoStreams.player.stream.getVideoTracks()[0],
                recv: false,
            });
        }

        if (this.isPublishingAudio && publishType === 'audio' && userMedia.audioStream) {
            const hasTrack = this.currentAudioTracks?.some((track) => track.group === 'audio');
            tracks.push({
                add: this.currentAudioTracks === null ? true : hasTrack === false,
                replace: this.currentAudioTracks === null ? false : hasTrack === true,
                type: 'audio',
                group: 'audio',
                capture: userMedia.audioStream.getAudioTracks()[0],
                recv: false,
            });
        }

        this.log('New tracks for ' + publishType, tracks);

        if (publishType === 'video') {
            this.currentVideoTracks = tracks;
        } else if (publishType === 'audio') {
            this.currentAudioTracks = tracks;
        }

        const handle = this.getPublisherHandle(publishType);
        handle.createOffer({
            tracks,
            success: (jsep: JanusJS.JSEP) => {
                let publish = {
                    request: 'configure',
                    audio: publishType === 'audio' && this.isPublishingAudio,
                    video: publishType === 'video' && this.isPublishingVideo,
                };

                this.log('Configure with Janus server', this.ownJanusServer);
                if (this.ownJanusServer) {
                    if (publishType === 'video' && this.isPublishingVideo) {
                        if (this.ownJanusServer.config_bitrate > 0) {
                            publish['bitrate'] = this.ownJanusServer.config_bitrate * 1000;
                        }
                        publish['videocodec'] = this.ownJanusServer.config_videocodec;
                    } else if (publishType === 'audio' && this.isPublishingAudio) {
                        publish['audiocodec'] = this.ownJanusServer.config_audiocodec;
                    }
                }

                this.log('Offer created', publish);
                handle.send({ message: publish, jsep: jsep });
            },
            error: (error) => {
                this.error('Error creating offer:', error);
            },
        });
    }

    getPublisherHandle(publishType: PublishType): JanusJS.PluginHandle {
        if (publishType === 'video') {
            return this.ownVideoPublisherRoomHandle;
        } else if (publishType === 'audio') {
            return this.ownAudioPublisherRoomHandle;
        }
    }

    joinOwnRoomAsPublisherAndCreateOffer(
        publishType: PublishType,
        tryOfferWhenError: boolean,
        userMedia: UserMedia,
        setVideoPublishing: boolean,
        setAudioPublishing: boolean
    ): void {
        this.log('Joining as publisher');
        const streamMetaData: StreamDisplayMetaData = {
            facingMode: FacingModes.BOARD,
            scale: this.ownCamera.scale,
            kind: 'app',
        };

        let joinAsPublisher = {
            request: 'join',
            room: publishType === 'video' ? this.ownCamera.roomID : this.ownVoiceCall.roomID,
            ptype: 'publisher',
            display: encodeURIComponent(JSON.stringify(streamMetaData)),
        };

        if (publishType === 'video') {
            this.currentVideoTracks = null;
        } else if (publishType === 'audio') {
            this.currentAudioTracks = null;
        }

        this.getPublisherHandle(publishType).send({
            message: joinAsPublisher,
            success: () => {
                this.log('** Joined OWN room as publisher:', joinAsPublisher.room);

                if (setVideoPublishing && userMedia.videoStreams?.hasStreams) {
                    this.isPublishingVideo = true;
                }

                if (setAudioPublishing && userMedia.audioStream) {
                    this.isPublishingAudio = true;
                }

                this.createOfferAndUpdateStream(publishType, userMedia);
            },
            error: (error: any) => {
                this.error('Error joining room as publisher:', error);
                if (tryOfferWhenError) {
                    this.createOfferAndUpdateStream(publishType, userMedia);
                }
            },
        });
    }

    async attachToOwnCameraRoomAndPublish(): Promise<void> {
        this.log('********** Attaching to own room and publish');
        if (!this.janusInstances.has(this.ownCamera.janusServerHost)) {
            // If the instance doesn't exist, initialize it
            await this.init(this.ownCamera.janusServerHost);
        }

        const janus = this.janusInstances.get(this.ownCamera.janusServerHost);
        if (!janus) {
            this.error(`Failed to get Janus instance for server: ${this.ownCamera.janusServerHost}`);
            return;
        }

        this.unpublishAndLeaveRoomAndDetachHandle(this.getPublisherHandle('video')).finally(() => {
            this.ownVideoPublisherRoomHandle = null;

            janus.attach({
                plugin: 'janus.plugin.videoroom',
                opaqueId: this.opaqueId,
                success: (pluginHandle: JanusJS.PluginHandle) => {
                    this.ownVideoPublisherRoomHandle = pluginHandle;
                    this.setOwnRoomCallbacks(janus, 'video', pluginHandle); // Set the callbacks

                    this.joinOwnRoomAsPublisherAndCreateOffer('video', true, this.ownUserMedia, false, false);
                },
            });
        });
    }

    async attachToOwnVoiceCallRoomAndPublish(): Promise<void> {
        this.log('********** Attaching to own voice chat room and publish');
        if (!this.janusInstances.has(this.ownVoiceCall.janusServerHost)) {
            // If the instance doesn't exist, initialize it
            await this.init(this.ownVoiceCall.janusServerHost);
        }

        const janus = this.janusInstances.get(this.ownVoiceCall.janusServerHost);
        if (!janus) {
            this.error(`Failed to get Janus instance for server: ${this.ownVoiceCall.janusServerHost}`);
            return;
        }

        this.unpublishAndLeaveRoomAndDetachHandle(this.getPublisherHandle('audio')).finally(() => {
            this.ownAudioPublisherRoomHandle = null;

            janus.attach({
                plugin: 'janus.plugin.videoroom',
                opaqueId: this.opaqueId,
                success: (pluginHandle: JanusJS.PluginHandle) => {
                    this.ownAudioPublisherRoomHandle = pluginHandle;
                    this.setOwnRoomCallbacks(janus, 'audio', pluginHandle); // Set the callbacks

                    this.joinOwnRoomAsPublisherAndCreateOffer('audio', true, this.ownUserMedia, false, false);
                },
            });
        });
    }

    async checkOwnRoomAndPublish(publishType: PublishType): Promise<void> {
        this.log('********** Attaching to own room and publish');
        const handle = this.getPublisherHandle(publishType);
        if (!handle || handle.detached) {
            if (!this.janusInstances.has(this.ownCamera.janusServerHost)) {
                // If the instance doesn't exist, initialize it
                await this.init(this.ownCamera.janusServerHost);
            }

            const janus = this.janusInstances.get(this.ownCamera.janusServerHost);
            if (!janus) {
                this.error(`Failed to get Janus instance for server: ${this.ownCamera.janusServerHost}`);
                return;
            }

            janus.attach({
                plugin: 'janus.plugin.videoroom',
                opaqueId: this.opaqueId,
                success: (pluginHandle: JanusJS.PluginHandle) => {
                    if (publishType === 'video') {
                        this.ownVideoPublisherRoomHandle = pluginHandle;
                    } else if (publishType === 'audio') {
                        this.ownAudioPublisherRoomHandle = pluginHandle;
                    }
                    this.setOwnRoomCallbacks(janus, publishType, pluginHandle); // Set the callbacks

                    this.joinOwnRoomAsPublisherAndCreateOffer(publishType, true, this.ownUserMedia, false, false);
                },
            });
        } else {
            this.unpublishRoomHandle(handle).finally(() => {
                this.createOfferAndUpdateStream(publishType, this.ownUserMedia);
            });
        }
    }

    async stopMicrophone(): Promise<void> {
        this.ownAudioUserMedia?.cleanupUserMedia(true, true);

        this.ownAudioPublisherId = null;
        this.isPublishingAudio = false;
        this.audioMedia = null;

        this.ownVoiceCall.janusServerHost = null;
        this.ownVoiceCall.roomID = null;

        if (this.ownAudioPublisherRoomHandle) {
            await this.unpublishAndLeaveRoomAndDetachHandle(this.ownAudioPublisherRoomHandle).catch(this.error);
            this.ownAudioPublisherRoomHandle = null;
        }
    }

    // Create a separate function to set the plugin callbacks
    setOwnRoomCallbacks(janus: Janus, publishType: PublishType, pluginHandle: any) {
        pluginHandle.onlocaltrack = (track: DCStreamTrack, on: boolean) => {
            this.log('Local tracks update', pluginHandle, track, on);
            const trackId = track.id.replace(/[{}]/g, '');
            if (on) {
                if (track.kind === 'video' && this.ownUserMedia?.videoStreams) {
                    if (this._janusEventService && janus?.isConnected()) {
                        this._janusEventService.emitEvent({
                            type: JanusEventType.OwnDeviceConnected,
                        });
                    }

                    this.ownUserMedia.videoStreams.activeStreams = true;
                }
            } else {
                // if (track.kind === 'video') {
                //     if (this.ownUserMedia?.videoStreams?.board?.mid === trackId) {
                //         this._janusEventService.emitEvent({
                //             type: JanusEventType.OwnDeviceDisconnected,
                //         });
                //     }
                // } else if (track.kind === 'audio') {
                //     if (this.ownUserMedia?.audioTrack?.id.replace(/[{}]/g, '') === trackId) {
                //         this._janusEventService.emitEvent({
                //             type: JanusEventType.OwnAudioDeviceDisconnected,
                //         });
                //     }
                // }
            }
        };

        pluginHandle.onmessage = (msg: JanusOwnRoomMessage, jsep: JanusJS.JSEP) => {
            this.log('OWNROOM MSG', msg, jsep);

            if (msg.videoroom) {
                if (msg.videoroom === 'joined') {
                    const { id } = msg;
                    if (publishType === 'video') {
                        this.ownVideoPublisherId = id;
                    } else if (publishType === 'audio') {
                        this.ownAudioPublisherId = id;
                    }
                } else if (msg.videoroom === 'event') {
                    if ('streams' in msg) {
                        const { streams } = msg;
                        streams.forEach((stream) => {
                            if (stream.type === 'video') {
                                if (this.ownUserMedia?.videoStreams?.board) {
                                    this.ownUserMedia.videoStreams.board.videoCodec = stream.codec;
                                }
                                if (this.ownUserMedia?.videoStreams?.player) {
                                    this.ownUserMedia.videoStreams.player.videoCodec = stream.codec;
                                }
                            }
                        });
                    } else if ('error_code' in msg && 'error' in msg) {
                        const { error_code, error } = msg;
                        switch (error_code) {
                            case 426:
                                if (
                                    String(error).includes('No such room') &&
                                    this.isPublishingVideo &&
                                    publishType === 'video'
                                ) {
                                    this.restartOwnStream(CAMERA_TYPE.CURRENT_DEVICE);
                                }
                                break;
                        }
                    }
                }
            }

            if (jsep) {
                this.log('Handling own room JSEP', jsep);
                pluginHandle.handleRemoteJsep({
                    jsep: jsep,
                    success: () => {
                        // Remote JSEP processed successfully
                        this.log('Remote JSEP processed successfully');
                    },
                    error: (error: any) => {
                        // An error occurred while processing the remote JSEP
                        this.error('Failed to process remote JSEP', error);
                    },
                });
            }
        };

        pluginHandle.error = (error: string) => {
            this.error(`Error attaching for publishing own feed`, error);
        };
    }

    //Kill the stream and clean the service
    async stopOwnStream(clearExternalCredentials = false): Promise<void> {
        return new Promise(async (resolve) => {
            this.log('Stopping own stream');
            this.ownJanusServer = null;
            this.ownCamera.janusServerHost = null;
            this.ownCamera.roomID = null;
            this.ownCamera.camType = null;
            this.ownVideoPublisherId = null;

            this.isPublishingVideo = false;
            this.videoMedia = null;
            this.usingExternal = false;

            if (clearExternalCredentials) {
                this.smartDevice = null;
                this.externalCameraStream = null;
                this.useOldCameraStream = false;
            }

            if (this.ownUserMedia) {
                if (this.externalPublisherRoomHandle) {
                    await this.unpublishAndLeaveRoomAndDetachHandle(this.externalPublisherRoomHandle).catch(this.error);
                }

                this.ownSubscribersRoomHandles.forEach(async (subscriberRoomHandle) => {
                    await this.unpublishAndLeaveRoomAndDetachHandle(subscriberRoomHandle).catch(this.error);
                });
                this.ownSubscribersRoomHandles.clear();

                if (this.ownVideoPublisherRoomHandle) {
                    await this.unpublishAndLeaveRoomAndDetachHandle(this.ownVideoPublisherRoomHandle).catch(this.error);
                }

                this.ownUserMedia.cleanupUserMedia(true, true, true);
                this.currentVideoTracks = null;
                this.currentAudioTracks = null;
                this.ownVideoPublisherRoomHandle = null;
                this.externalPublisherRoomHandle = null;
                resolve();
            } else {
                this.log('Stopping own stream not working');
                resolve();
            }
        });
    }

    leaveSpectatingRooms(removeUsersFromRoomMap: boolean): Promise<void> {
        return new Promise((resolve) => {
            this.subscribersRoomHandlesMap.forEach(async (roomHandles, key) => {
                roomHandles.forEach(async (roomHandle) => {
                    await this.leaveRoomAndDetachSubscriberHandle(roomHandle, key, removeUsersFromRoomMap).catch(
                        this.error
                    );
                });
            });
            this.subscribersRoomHandlesMap.clear();

            this.subscribersHandleMap.forEach(async (roomHandle, key) => {
                await this.leaveRoomAndDetachPublisherHandle(roomHandle, key, removeUsersFromRoomMap).catch(this.error);
            });
            this.subscribersHandleMap.clear();

            this.publishersRoomHandleMap.forEach(async (roomHandle, key) => {
                await this.leaveRoomAndDetachPublisherHandle(roomHandle, key, removeUsersFromRoomMap).catch(this.error);
            });
            this.publishersRoomHandleMap.clear();

            this.roomToUserMap.clear();
            resolve();
        });
    }

    unpublishAndLeaveRoomAndDetachHandle(roomHandle: JanusJS.PluginHandle): Promise<void> {
        this.log('unpublishAndLeaveRoomAndDetachHandle');
        return new Promise((resolve, reject) => {
            if (!roomHandle) {
                reject('Room handle cannot be  null');
                return;
            }

            if (roomHandle.detached) {
                reject('Room handle cannot be detached because it already is');
                return;
            }

            this.unpublishRoomHandle(roomHandle).finally(() => {
                this.leaveRoomHandle(roomHandle).finally(() => {
                    this.detachRoomHandle(roomHandle).finally(() => {
                        this.log('unpublishAndLeaveRoomAndDetachHandle', roomHandle);
                        resolve();
                    });
                });
            });
        });
    }

    unpublishAndDetachHandle(roomHandle: JanusJS.PluginHandle): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!roomHandle) {
                reject('Room handle cannot be  null');
                return;
            }

            if (roomHandle.detached) {
                reject('Room handle cannot be detached because it already is');
                return;
            }

            this.unpublishRoomHandle(roomHandle).finally(() => {
                this.detachRoomHandle(roomHandle).finally(() => {
                    this.log('unpublishAndLeaveRoomAndDetachHandle', roomHandle);
                    resolve();
                });
            });
        });
    }

    leaveRoomAndDetachAllOwnHandles(): Promise<void> {
        return new Promise(async (resolve, reject) => {
            await this.unpublishAndLeaveRoomAndDetachHandle(this.externalPublisherRoomHandle).catch(this.error);

            this.ownSubscribersRoomHandles.forEach(async (subscriberRoomHandle) => {
                await this.unpublishAndLeaveRoomAndDetachHandle(subscriberRoomHandle).catch(this.error);
            });
            resolve();
        });
    }

    leaveRoomAndDetachAllHandles(roomId: number, removeUsersFromRoomMap: boolean): Promise<void> {
        return new Promise(async (resolve, reject) => {
            const subscriberHandles = this.subscribersRoomHandlesMap.get(roomId);
            if (subscriberHandles) {
                subscriberHandles.forEach(async (roomHandle) => {
                    if (roomHandle) {
                        await this.leaveRoomAndDetachSubscriberHandle(roomHandle, roomId, removeUsersFromRoomMap).catch(
                            this.error
                        );
                    }
                });
            }

            const publisherHandle = this.publishersRoomHandleMap.get(roomId);
            if (publisherHandle) {
                await this.leaveRoomAndDetachPublisherHandle(publisherHandle, roomId, removeUsersFromRoomMap).catch(
                    this.error
                );
            }

            resolve();
        });
    }

    leaveRoomAndDetachPublisherHandle(
        roomHandle: JanusJS.PluginHandle,
        roomId: number,
        removeUserFromRoomMap: boolean
    ): Promise<void> {
        return new Promise((resolve, reject) => {
            this.leaveRoomHandle(roomHandle).finally(() => {
                this.detachRoomHandle(roomHandle).finally(() => {
                    this.removePublisherRoomHandle(roomId, removeUserFromRoomMap);
                    resolve();
                });
            });
        });
    }

    leaveRoomAndDetachSubscriberHandle(
        roomHandle: JanusJS.PluginHandle,
        roomId: number,
        removeUserFromRoomMap: boolean
    ): Promise<void> {
        return new Promise((resolve, reject) => {
            this.leaveRoomHandle(roomHandle).finally(() => {
                this.detachRoomHandle(roomHandle).finally(() => {
                    this.removeSubscriberRoomHandle(roomId, removeUserFromRoomMap);
                    resolve();
                });
            });
        });
    }

    unpublishRoomHandle(roomHandle: JanusJS.PluginHandle): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!roomHandle) {
                reject('Room handle cannot be null when trying to unpublish room handle');
                return;
            }

            if (roomHandle.detached) {
                reject('Already detached when trying to unpublish room handle');
                return;
            }

            roomHandle.send({
                message: {
                    request: 'unpublish',
                },
                success: () => {
                    this.log('Unpublished room handle', roomHandle);
                    resolve();
                },
                error: (error) => {
                    this.log('Error unpublishing room handle', error);
                    reject();
                },
            });
        });
    }

    leaveRoomHandle(roomHandle: JanusJS.PluginHandle): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!roomHandle) {
                reject('Room handle cannot be null when trying to leave room handle');
                return;
            }

            if (roomHandle.detached) {
                reject('Already detached when trying to leave room handle');
                return;
            }

            roomHandle.send({
                message: {
                    request: 'leave',
                },
                success: () => {
                    this.log('Left room handle', roomHandle);
                    resolve();
                },
                error: (error) => {
                    this.log('Error leaving room handle', error);
                    reject();
                },
            });
        });
    }

    detachRoomHandle(roomHandle: JanusJS.PluginHandle): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!roomHandle) {
                reject('Room handle cannot be null when trying to detach room handle');
                return;
            }

            if (roomHandle.detached) {
                reject('Already detached when trying to detach room handle');
                return;
            }

            roomHandle.detach({
                success: () => {
                    this.log('Detached room handle', roomHandle);
                    resolve();
                },
                error: (error) => {
                    this.log('Error detaching room handle', error);
                    reject();
                },
            });
        });
    }

    removePublisherRoomHandle(room: number, removeUserFromRoomMap: boolean): void {
        this.publishersRoomHandleMap.delete(room);
        if (removeUserFromRoomMap) {
            this.removeUserFromRoom(room);
        }
    }

    removeSubscriberRoomHandle(room: number, removeUserFromRoomMap: boolean): void {
        this.subscribersRoomHandlesMap.delete(room);
        if (removeUserFromRoomMap) {
            this.removeUserFromRoom(room);
        }
    }

    log(message?: any, ...optionalParams: any[]): void {
        if (this?._debug) {
            console.log(message, ...optionalParams);
        }
    }

    error(message?: any, ...optionalParams: any[]): void {
        if (this?._debug) {
            console.error(message, ...optionalParams);
        }
    }
}
