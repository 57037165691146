import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StatisticsApiService } from '@dc-api/statistics.api.service';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DartCounterAlertService } from 'dc-core/dc-services/alert.service';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/services/auth.service';

import { DatePickerDialogComponent } from '../date-picker/date-picker.dialog';

@Component({
    selector: 'app-reset-account-dialog',
    templateUrl: 'reset-account.dialog.html',
})
export class ResetAccountDialogComponent {
    public translateService: TranslateService = inject(TranslateService);

    startDate: any;
    endDate: any;

    isWeb: boolean = environment.isWeb;

    constructor(
        public view: ModalController,
        public auth: AuthService,
        public modal: ModalController,
        public modal2: ModalController,
        public translate: TranslateService,
        public route: ActivatedRoute,
        private _alertService: DartCounterAlertService,
        private statisticsApiService: StatisticsApiService
    ) {
        this.startDate = moment(this.auth.user.created_at).toISOString();
        this.endDate = moment(new Date()).toISOString();
    }

    changeResetFrom(): void {
        this.modal2
            .create({
                component: DatePickerDialogComponent,
                cssClass: 'auto-height',
                componentProps: {
                    title: $localize`:@@RESET_FROM:Reset from`,
                    date: this.startDate,
                    maxDate: moment().format('YYYY-MM-DD'),
                },
                showBackdrop: true,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRef) => {
                    if (dialogRef.data) {
                        this.startDate = dialogRef.data;
                    }
                });
            });
    }

    changeResetUntil(): void {
        this.modal2
            .create({
                component: DatePickerDialogComponent,
                cssClass: 'auto-height',
                componentProps: {
                    title: $localize`:@@RESET_UNTIL:Reset until`,
                    date: this.endDate,
                    maxDate: moment().format('YYYY-MM-DD'),
                },
                showBackdrop: true,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRef) => {
                    if (dialogRef.data) {
                        this.endDate = dialogRef.data;
                    }
                });
            });
    }

    ResetAccount() {
        this.statisticsApiService.resetStatistics({
            from_date: moment(this.startDate).toISOString(),
            to_date: moment(this.endDate).toISOString(),
        });

        $localize`:@@ACCOUNT_RESET_BUSY:Your account will be reset in the background, you can continue to use the app`;
        this._alertService.createAlert({
            title: this.translate.instant('ACCOUNT_RESET_BUSY'),
            timer: 3000,
        });

        setTimeout(() => {
            this.dismiss();
        }, 3000);
    }

    public dismiss(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }
}
