import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface AppFeatures {
    action_replays?: boolean;
    private_chat?: boolean;
    report_recording?: boolean;
    speech_to_score?: boolean;
    chromecast?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class AppFeaturesService {
    public enabledAppFeatures: AppFeatures;

    constructor(private http: HttpClient) {}

    public registerEnabledAppFeatures(): Promise<void> {
        const enabledAppFeatures$ = this.http.get<AppFeatures>(`${environment.apiUrl}/app-features`);

        return firstValueFrom(enabledAppFeatures$).then((appFeatures) => {
            this.enabledAppFeatures = appFeatures;
        });
    }
}
