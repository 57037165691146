import { GameMode } from '@dc-core/dc-backend/dc-enums';

export type StatisticsRange = 'all_time' | 'this_month' | 'this_year' | 'custom';

export type StatisticsGameType = 'all' | 'local' | 'online' | 'vs_cpu';

export type StatisticsRequireNumber = 'all' | 'yes' | 'no';

export type StatisticsRequireTriple = 'all' | 'yes' | 'no' | 'specific_number';

export type StartScoreOption = 'all' | '501' | '701' | 'custom';
export interface StatisticsRangeLabelType {
    label: string;
    tag: string;
    type: StatisticsRange;
}

export interface StatisticsGameTypeLabelType {
    label: string;
    tag: string;
    type: StatisticsGameType;
}

export interface StatisticsStartScoreLabelType {
    label: string;
    tag: string;
    type: StartScoreOption;
}

export const ALL_TIME_STAT_RANGE: StatisticsRangeLabelType = {
    label: $localize`:@@ALL_TIME:All time`,
    tag: 'ALL_TIME',
    type: 'all_time',
};

export const THIS_MONTH_STAT_RANGE: StatisticsRangeLabelType = {
    label: $localize`:@@THIS_MONTH:This month`,
    tag: 'THIS_MONTH',
    type: 'this_month',
};

export const THIS_YEAR_STAT_RANGE: StatisticsRangeLabelType = {
    label: $localize`:@@THIS_YEAR:This year`,
    tag: 'THIS_YEAR',
    type: 'this_year',
};

export const CUSTOM_STAT_RANGE: StatisticsRangeLabelType = {
    label: $localize`:@@CUSTOM:Custom`,
    tag: 'CUSTOM',
    type: 'custom',
};

export var statisticsRanges: StatisticsRangeLabelType[] = [
    ALL_TIME_STAT_RANGE,
    THIS_MONTH_STAT_RANGE,
    THIS_YEAR_STAT_RANGE,
    CUSTOM_STAT_RANGE,
];

export const ALL_GAME_TYPE: StatisticsGameTypeLabelType = {
    label: $localize`:@@ALL:All`,
    tag: 'ALL',
    type: 'all',
};

export const LOCAL_GAME_TYPE: StatisticsGameTypeLabelType = {
    label: $localize`:@@LOCAL:Local`,
    tag: 'LOCAL',
    type: 'local',
};

export const ONLINE_GAME_TYPE: StatisticsGameTypeLabelType = {
    label: $localize`:@@ONLINE:Online`,
    tag: 'ONLINE',
    type: 'online',
};

export const VS_DARTBOT_GAME_TYPE: StatisticsGameTypeLabelType = {
    label: $localize`:@@VS_DARTBOT:vs. Dartbot`,
    tag: 'VS_DARTBOT',
    type: 'vs_cpu',
};

export var statisticsGameTypes: StatisticsGameTypeLabelType[] = [
    ALL_GAME_TYPE,
    LOCAL_GAME_TYPE,
    ONLINE_GAME_TYPE,
    VS_DARTBOT_GAME_TYPE,
];

export const ALL_STAT_RANGE: StatisticsStartScoreLabelType = {
    label: $localize`:@@ALL:All`,
    tag: 'ALL',
    type: 'all',
};

export const FIVE_ZERO_ONE_STAT_RANGE: StatisticsStartScoreLabelType = {
    label: '501',
    tag: null,
    type: '501',
};

export const SEVEN_ZERO_ONE_STAT_RANGE: StatisticsStartScoreLabelType = {
    label: '701',
    tag: null,
    type: '701',
};

export const CUSTOM_START_SCORE_STAT_RANGE: StatisticsStartScoreLabelType = {
    label: $localize`:@@CUSTOM:Custom`,
    tag: 'CUSTOM',
    type: 'custom',
};

export var startScoreRanges: StatisticsStartScoreLabelType[] = [
    ALL_STAT_RANGE,
    FIVE_ZERO_ONE_STAT_RANGE,
    SEVEN_ZERO_ONE_STAT_RANGE,
    CUSTOM_START_SCORE_STAT_RANGE,
];

export interface ListGameType extends DropdownSelectItem {
    value: string;
    gameMode: GameMode;
    path: string;
    label: string;
    ga: {
        category: string;
        action: string;
    };
}

export interface DropdownSelectItem {
    label: string;
    value: string;
    hidden?: boolean;
    ultimateOnly?: boolean;
    unverifiedGames?: number;
}
