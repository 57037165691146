import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { SingleDartKeyboardOptions } from 'dc-core/dc-gamelogic/game-interfaces';
import { SetSingleDartPayload } from 'dc-core/dc-gamelogic/in-game/ingame.globals';
import { DartCounterPreferenceService } from 'dc-core/dc-services/preference/preference.service';

@Component({
    selector: 'app-singledart-keyboard',
    templateUrl: 'singledartkeyboard.html',
    styleUrls: ['singledartkeyboard.scss'],
})
export class SingleDartKeyboardComponent implements OnInit {
    // inputs
    @Input() options: SingleDartKeyboardOptions;
    @Input() visible = false;
    @Input() myTurn = false;
    @Input() timer;

    @Output() setSingleDart = new EventEmitter<SetSingleDartPayload>();
    @Output() undoSingleDart = new EventEmitter();
    @Output() confirmSingleDarts = new EventEmitter();

    public multiplier = 1;
    public amount: number = null;

    constructor(
        private _sanitizer: DomSanitizer,
        public translate: TranslateService,
        public preferenceService: DartCounterPreferenceService
    ) {}

    ngOnInit() {
        if (this.options === undefined || this.options === null) {
            console.error('[DartKeyboard] options are not defined.');
        }
    }

    selectMultiplier(multiplier) {
        this.multiplier = multiplier;
    }

    selectAmount(amount, multiplier = null) {
        this.amount = amount;

        if (this.myTurn && amount === 25 && this.multiplier === 1) {
            //Do nothing
        } else if (multiplier) {
            this.multiplier = multiplier;
        }

        this.setSingleDart.emit({
            amount: this.amount,
            multiplier: this.multiplier,
            needConfirm: false,
            isOmni: false,
        });

        this.multiplier = 1;
        this.amount = null;
    }

    submit() {
        this.confirmSingleDarts.emit();
    }

    undo() {
        this.undoSingleDart.emit();
    }
}
