<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="dismiss()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title">
            {{ title }}
        </div>
    </div>
    <div class="slide-modal-content text-black">
        {{ contents }}
    </div>
</div>
