<button
    class="bg-white dark:!bg-white border-2 border-black cursor-pointer grid grid-cols-12 items-center rounded-sm text-black dark:!text-black w-full py-1.5 box-border"
    (click)="handleGoogleLogin()">
    <div class="flex justify-center col-span-3">
        <img class="max-h-8" src="assets/images/socials/google.png" />
    </div>
    <div class="font-bold col-span-6 w-full text-center whitespace-nowrap">
        @if (type() === 'continue') {
            <span [translate]="'CONTINUE_WITH_GOOGLE'" i18n="@@CONTINUE_WITH_GOOGLE">Continue with Google</span>
        } @else if (type() === 'sign_up') {
            <span [translate]="'SIGN_UP_WITH_GOOGLE'" i18n="@@SIGN_UP_WITH_GOOGLE">Sign up with Google</span>
        }
    </div>
    <div class="col-span-3"></div>
</button>
