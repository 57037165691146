import { ElementRef, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface IngameAnimationSettings {
    message?: string;
    imagePath?: string;
    showAnimation?: string;
    hideAnimation?: string;
    duration?: number; // in milliseconds
    delay?: number; // in milliseconds
    hideDuration?: number; // in milliseconds
    top?: number; // position from top
    left?: number; // position from left
}

export interface AnimationStatus {
    isVisible: boolean;
    animationSettings: IngameAnimationSettings;
    duration: number;
}

@Injectable({
    providedIn: 'root',
})
export class IngameAnimationService {
    private animationStatusSource = new Subject<AnimationStatus>();
    animationStatus = this.animationStatusSource.asObservable();

    private hideAnimationsManuallySource = new Subject<void>();
    hideAnimationsManually = this.hideAnimationsManuallySource.asObservable();

    private _defaultDuration: number = 2000;

    showAnimation(settings: IngameAnimationSettings, duration: number = null) {
        this.animationStatusSource.next({
            isVisible: true,
            animationSettings: settings,
            duration: duration ?? this._defaultDuration,
        });
    }

    hideOverlay() {
        this.hideAnimationsManuallySource.next();
    }

    getPointsAnimationSettings(points: number, element: ElementRef | string): IngameAnimationSettings {
        let rect: DOMRect;
        if (typeof element === 'string') {
            const el = document.querySelector(element);
            if (el) {
                rect = el.getBoundingClientRect();
            }
        } else if (element.nativeElement) {
            rect = element.nativeElement.getBoundingClientRect();
        }

        return {
            message: `+${points}`,
            showAnimation: 'animate__animated animate__customFadeInUp',
            hideAnimation: 'animate__animated animate__customFadeOut',
            hideDuration: 500,
            top: rect.top + 20,
            left: rect.right + 50,
        };
    }
}
