import { CommonModule } from '@angular/common';
import { Component, input, InputSignal } from '@angular/core';

@Component({
    selector: 'app-icon',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './icon.component.html',
})
export class IconComponent {
    public icon: InputSignal<string> = input.required();
    public size: InputSignal<'extrasmall' | 'small' | 'medium' | 'normal' | 'large' | 'extralarge'> = input('normal');
    public bold: InputSignal<boolean> = input(false);
}
