<div class="flex justify-evenly">
    <ng-container *ngTemplateOutlet="teamContainer; context: { team: teams[0], i: 0 }"></ng-container>
    <div class="mb-9 mt-auto h-10 w-[0.0625rem] bg-neutral-50"></div>
    <ng-container *ngTemplateOutlet="teamContainer; context: { team: teams[1], i: 1 }"></ng-container>
</div>

<ng-template #teamContainer let-team="team" let-i="i">
    <div class="flex basis-1/2 flex-col items-center">
        <div class="text-center font-bebas-neue text-lg text-black">{{ 'TEAM' | translate }} {{ i + 1 }}</div>
        <div class="flex items-start justify-evenly">
            @for (user of team.users; track user; let userIndex = $index) {
                <div class="basis-1/2 p-2">
                    <div class="flex flex-col items-center">
                        <div class="new-game-player-container">
                            <app-profile-photo
                                class="h-fit"
                                [size]="52"
                                [ultimate]="user.is_ultimate"
                                [badge]="user.profile?.badge"
                                [url]="user.profile?.profile_photo_url"
                                (click)="showPlayerOptions(user, i, userIndex)">
                            </app-profile-photo>
                            @if (team.users.length > 1) {
                                <div class="circle cursor-pointer" (click)="removePlayer(userIndex, i)">
                                    <app-icon icon="close" class="text-white" size="small"></app-icon>
                                </div>
                            }
                            @if (auth.user && user.is_ultimate && user.profile?.sound) {
                                <div
                                    class="circle high orange cursor-pointer"
                                    (click)="showPlayerOptions(user, i, userIndex)">
                                    <ion-icon name="volume-high-outline" color="light"></ion-icon>
                                </div>
                            }
                            @if (auth.user && user.is_ultimate && !user.profile?.sound) {
                                <div
                                    class="circle high orange cursor-pointer"
                                    (click)="showPlayerOptions(user, i, userIndex)">
                                    <ion-icon name="volume-mute" color="light"></ion-icon>
                                </div>
                            }
                        </div>
                        <div
                            class="mt-1 text-xs font-bold text-neutral-900"
                            [ngClass]="{ 'max-w-16 truncate': !user.is_cpu, 'whitespace-nowrap': user.is_cpu }">
                            {{ user.first_name || ('NO_NAME' | translate) }}
                        </div>
                    </div>
                </div>
            }

            @if (team.users.length < 2) {
                <div class="inline-flex basis-1/2 justify-center px-2 pt-3">
                    <div
                        class="inline-flex h-[3.25rem] w-[3.25rem] items-center justify-center rounded-full border-2 border-neutral-50"
                        (click)="addPlayer(null, team)">
                        <app-icon icon="add" size="large" class="text-neutral-400"></app-icon>
                    </div>
                </div>
            }
        </div>
    </div>
</ng-template>
