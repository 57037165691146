<div
    class="flex cursor-pointer"
    [class.flex-row-reverse]="position === 'right'"
    [class.justify-between]="space === 'between'"
    [class.text-white]="color === 'white'"
    [class.text-black]="color === 'black'"
    (click)="changeItem()">
    <div
        class="relative block h-fit rounded-sm border-2"
        [class.mr-3]="position === 'left'"
        [class.ml-3]="position === 'right'"
        [ngClass]="{
            'border-neutral-200': color === 'white' && !model,
            'border-white': color === 'black' && !model,
            'border-opacity-60 bg-transparent': !model,
            'border-orange bg-orange': model
        }">
        <div class="h-[1.25rem] w-[1.25rem] rounded-sm text-[1.25rem] leading-[1.25rem]">
            <ion-icon *ngIf="model" name="checkmark-outline" class="ion-text-white" slot="start"> </ion-icon>
        </div>
    </div>
    <div class="mt-0.5 block whitespace-normal text-sm leading-1">
        <ng-content></ng-content>
    </div>
</div>
