<div class="zoomed-omni-dart w-full" (click)="editZoomedPoint()">
    <div class="zoomed-container">
        @if (omniThrowResponseState === 'WAITING') {
            <div class="placeholder">Waiting for dart</div>
        } @else if (omniThrowResponseState === 'THROW') {
            <div [innerHTML]="svgContent"></div>
        } @else if (omniThrowResponseState === 'BOUNCEOUT') {
            <div class="placeholder">Bounce-out</div>
        } @else if (omniThrowResponseState === 'MISS') {
            <div class="placeholder">Miss</div>
        }
    </div>
    <div class="bottomPart">
        <div
            class="relative w-full border-2 !border-neutral-50 !bg-neutral-50 px-2 pt-1"
            style="border-radius: 0 0 9px 9px">
            <app-keyboard-dart class="block w-2/3 !text-black"></app-keyboard-dart>
            <div class="in-game-score-field">
                {{ point()?.singleDart.amount * point()?.singleDart.multiplier || '' }}
            </div>

            <div class="absolute bottom-1 right-1 font-bebas-neue text-black">
                {{ getScoreEvent(point()) }}
            </div>
        </div>
    </div>
</div>
