import '@angular/localize/init';
import { CartesianCoordinate } from '@dc-core/dc-services/omni/omni-ingame.service';
import { DartCounterMatchThrow } from './match/match.in-game.classes';

export function genUniqueId(): string {
    const dateStr = Date.now().toString(36); // convert num to base 36 and stringify

    const randomStr = Math.random().toString(36).substring(2, 8); // start at index 2 to skip decimal point

    return `${dateStr}-${randomStr}`;
}

export interface SingleDart {
    entered: boolean;
    amount: number;
    mptext: string;
    multiplier: number;
    text: string;
    total: number;
    is_opportunity?: boolean;
    is_throwout?: boolean;
    coordinates?: CartesianCoordinate;
}

export interface SetSingleDartPayload {
    amount: number;
    multiplier: number;
    needConfirm: boolean;
    isBounceout?: boolean;
    isOmni: boolean;
    coordinates?: CartesianCoordinate;
}

export type SingleDartIngameEvent = { event: SingleDartEvent; data?: any };

export enum SingleDartEvent {
    RemoveDarts = 'RemoveDarts',
    BustedScore = 'BustedScore',
    DoubleInError = 'DoubleInError',
    DoubleOutError = 'DoubleOutError',
    MasterInError = 'MasterInError',
    MasterOutError = 'MasterOutError',
}

export class X01ScoreEvent {
    throws: DartCounterMatchThrow[];
    score: number = 0;
    dartsOnDouble?: number;
    dartsForCheckout?: number;
    allowCaller?: boolean;

    isThrowout: boolean;
}

export enum GameEvent {
    CURRENT_PLAYER = 'current_player',
    ENDED = 'ended',
    UPDATE = 'update',
    TURN_OVER = 'turn_over',
}

export const SCORES_LIST: ScoreAmountStatistic[] = [
    { label: '180', from: 180, to: 180, amount: 0 },
    { label: '160+', from: 160, to: 179, amount: 0 },
    { label: '140+', from: 140, to: 159, amount: 0 },
    { label: '120+', from: 120, to: 139, amount: 0 },
    { label: '100+', from: 100, to: 119, amount: 0 },
    { label: '80+', from: 80, to: 99, amount: 0 },
    { label: '60+', from: 60, to: 79, amount: 0 },
    { label: '40+', from: 40, to: 59, amount: 0 },
];

export interface ScoreAmountStatistic {
    label: string;
    from: number;
    to: number;
    amount: number;
}

export interface ChatMessage {
    chat_msg: CHAT_MSG;
    text: string;
}

export enum CHAT_MSG {
    GOOD_DARTS = 'good_darts',
    GOOD_LUCK = 'good_luck',
    TOO_BAD = 'too_bad',
    DOUBLE_TROUBLE = 'double_trouble',
    BOUNCE_OUT = 'bounce_out',
    THAT_WAS_EPIC = 'that_was_epic',
    OMG = 'omg',
    I_AM_SO_HAPPY = 'so_happy',
    THIS_NEVER_HAPPENS = 'never_happens',
    NICE_ONE = 'nice_one',
    HAVE_TO_GO_SORRY = 'have_to_go',
    SHORT_BREAK = 'short_break',
    BE_RIGHT_BACK = 'be_right_back',
    THANKS = 'thanks',
    NO_PROBLEM = 'no_problem',
    ALRIGHT = 'alright',
    I_RATE_FIVE_STARS = 'i_rate_five_stars',
    LOST_MY_FLIGHT = 'lost_my_flight',
    CHANGE_OF_MATERIAL = 'change_of_material',
    CALL_YOU_SOON = 'call_you_soon',

    // AfterMatch Chat
    GOOD_GAME = 'good_game',
    NICE_FINISH = 'nice_finish',
    CONGRATS = 'congrats',
    SEE_YA = 'see_ya',
    I_ADD_YOU = 'i_add_you',
}

export const IN_GAME_COMMS: ChatMessage[] = [
    {
        chat_msg: CHAT_MSG.GOOD_DARTS,
        text: $localize`:@@CHAT_GOOD_DARTS:Good darts!`,
    },
    {
        chat_msg: CHAT_MSG.GOOD_LUCK,
        text: $localize`:@@CHAT_GOOD_LUCK:Good luck!`,
    },
    {
        chat_msg: CHAT_MSG.TOO_BAD,
        text: $localize`:@@CHAT_TOO_BAD:Too bad!`,
    },
    {
        chat_msg: CHAT_MSG.DOUBLE_TROUBLE,
        text: $localize`:@@CHAT_DOUBLE_TROUBLE:Double trouble!`,
    },
    {
        chat_msg: CHAT_MSG.BOUNCE_OUT,
        text: $localize`:@@CHAT_BOUNCE_OUT:Bounce-out!`,
    },
    {
        chat_msg: CHAT_MSG.THAT_WAS_EPIC,
        text: $localize`:@@CHAT_THAT_WAS_EPIC:That was epic!`,
    },
    {
        chat_msg: CHAT_MSG.OMG,
        text: $localize`:@@CHAT_OMG:OMG!`,
    },
    {
        chat_msg: CHAT_MSG.I_AM_SO_HAPPY,
        text: $localize`:@@CHAT_I_AM_SO_HAPPY:I'm so happy!`,
    },
    {
        chat_msg: CHAT_MSG.THIS_NEVER_HAPPENS,
        text: $localize`:@@CHAT_THIS_NEVER_HAPPENS:This never happens!`,
    },
    {
        chat_msg: CHAT_MSG.NICE_ONE,
        text: $localize`:@@CHAT_NICE_ONE:Nice one!`,
    },
    {
        chat_msg: CHAT_MSG.HAVE_TO_GO_SORRY,
        text: $localize`:@@CHAT_HAVE_TO_GO_SORRY:Have to go, sorry!`,
    },
    {
        chat_msg: CHAT_MSG.SHORT_BREAK,
        text: $localize`:@@CHAT_SHORT_BREAK:Short break!`,
    },
    {
        chat_msg: CHAT_MSG.BE_RIGHT_BACK,
        text: $localize`:@@CHAT_BE_RIGHT_BACK:Be right back!`,
    },
    {
        chat_msg: CHAT_MSG.THANKS,
        text: $localize`:@@CHAT_THANKS:Thanks!`,
    },
    {
        chat_msg: CHAT_MSG.NO_PROBLEM,
        text: $localize`:@@CHAT_NO_PROBLEM:No problem!`,
    },
    {
        chat_msg: CHAT_MSG.ALRIGHT,
        text: $localize`:@@CHAT_ALRIGHT:Alright!`,
    },
    {
        chat_msg: CHAT_MSG.I_RATE_FIVE_STARS,
        text: $localize`:@@CHAT_I_WILL_RATE_FIVE_STARS:I'll rate 5 stars!`,
    },
    {
        chat_msg: CHAT_MSG.LOST_MY_FLIGHT,
        text: $localize`:@@CHAT_LOST_MY_FLIGHT:Lost my flight!`,
    },
    {
        chat_msg: CHAT_MSG.CHANGE_OF_MATERIAL,
        text: $localize`:@@CHAT_CHANGE_OF_MATERIAL:Change of material!`,
    },
    {
        chat_msg: CHAT_MSG.CALL_YOU_SOON,
        text: $localize`:@@CHAT_CALL_YOU_SOON:Call you soon!`,
    },
];

export const POST_GAME_COMMS: ChatMessage[] = [
    {
        chat_msg: CHAT_MSG.GOOD_GAME,
        text: $localize`:@@CHAT_GOOD_GAME:Good game!`,
    },
    {
        chat_msg: CHAT_MSG.TOO_BAD,
        text: $localize`:@@CHAT_TOO_BAD:Too bad!`,
    },
    {
        chat_msg: CHAT_MSG.NICE_FINISH,
        text: $localize`:@@CHAT_NICE_FINISH:Nice finish!`,
    },
    {
        chat_msg: CHAT_MSG.CONGRATS,
        text: $localize`:@@CHAT_CONGRATS:Congrats!`,
    },
    {
        chat_msg: CHAT_MSG.THANKS,
        text: $localize`:@@CHAT_THANKS:Thanks!`,
    },
    {
        chat_msg: CHAT_MSG.NO_PROBLEM,
        text: $localize`:@@CHAT_NO_PROBLEM:No problem!`,
    },
    {
        chat_msg: CHAT_MSG.ALRIGHT,
        text: $localize`:@@CHAT_ALRIGHT:Alright!`,
    },
    {
        chat_msg: CHAT_MSG.SEE_YA,
        text: $localize`:@@CHAT_SEE_YA:See ya!`,
    },
    {
        chat_msg: CHAT_MSG.I_RATE_FIVE_STARS,
        text: $localize`:@@CHAT_I_WILL_RATE_FIVE_STARS:I'll rate 5 stars!`,
    },
    {
        chat_msg: CHAT_MSG.I_ADD_YOU,
        text: $localize`:@@CHAT_ILL_ADD_YOU_AS_A_FRIEND:I'll add you as a friend!`,
    },
];

export type TeamSwiperStat = {
    swiperStatsSmall: number;
    swiperStatsExpanded: number;
};
