import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { IonicModule, ModalController } from '@ionic/angular';
import { PrimaryButtonComponent } from 'src/app/primary-button/primary-button.component';
import { ValidationLabelComponent } from 'src/app/shared/components/validation-label/validation-label.component';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        ValidationLabelComponent,
        FormsModule,
        ReactiveFormsModule,
        PrimaryButtonComponent,
    ],
    selector: 'app-timer-duration-dialog',
    templateUrl: 'timerduration.dialog.html',
})
export class TimerDurationDialogComponent implements OnInit {
    @Input() timerDuration: number;

    private modalController: ModalController = inject(ModalController);
    private formBuilder: FormBuilder = inject(FormBuilder);

    public timerForm: FormGroup;

    public ngOnInit(): void {
        this.timerForm = this.formBuilder.group({
            timer: [this.timerDuration, [Validators.required, Validators.min(30), Validators.max(80)]],
        });
    }

    public save(): void {
        const timerValue = parseInt(this.timerForm.get('timer').value, 10);
        this.modalController.dismiss(isNaN(timerValue) ? null : timerValue);
    }

    public dismiss(): void {
        this.modalController.dismiss();
    }
}
