/* eslint-disable max-len */
import { CommonModule } from '@angular/common';
import {
    Component,
    computed,
    DestroyRef,
    effect,
    inject,
    input,
    InputSignal,
    OnDestroy,
    OnInit,
    output,
    OutputEmitterRef,
    Signal,
    signal,
    untracked,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { OmniOpponentViewComponent } from '@components/omni/omni-opponent-view/omni-opponent-view.component';
import { ProfilePhotoComponent } from '@components/profile-photo/profile-photo.component';
import { User } from '@dc-core/dc-backend/dc-classes';
import { GameMode } from '@dc-core/dc-backend/dc-enums';
import { OnlineGameplay } from '@dc-core/dc-backend/dc-interfaces';
import { ActionsLogicService } from '@dc-core/dc-firestore/actions.service';
import { ActionsCollectionService } from '@dc-core/dc-firestore/collection-helpers/actions.collection.service';
import { ActiveGamesCollectionService } from '@dc-core/dc-firestore/collection-helpers/active_games.collection.service';
import { GameEventsCollectionService } from '@dc-core/dc-firestore/collection-helpers/game_events.collection.service';
import { InviteCollectionService } from '@dc-core/dc-firestore/collection-helpers/invite.collection.service';
import { PublicGamesCollectionService } from '@dc-core/dc-firestore/collection-helpers/public_games.collection.service';
import { SpectatorsCollectionService } from '@dc-core/dc-firestore/collection-helpers/spectators.collection.service';
import { UsersCollectionService } from '@dc-core/dc-firestore/collection-helpers/users.collection.service';
import { FireStoreAuthService } from '@dc-core/dc-firestore/firestore-auth.service';
import {
    DCFireStoreSpectator,
    DCFireStoreUser,
    DCGameAction,
    FSCameraAction,
    FSOmniStateAction,
    ONLINESTATUS,
} from '@dc-core/dc-firestore/globals/firestore.tables';
import { InGameKeyboardType } from '@dc-core/dc-gamelogic/game-interfaces';
import {
    DartCounterCricketTacticsGame,
    DartCounterCricketTacticsTeam,
    DartCounterCricketTacticsTurn,
    DartCounterCricketTacticsUser,
} from '@dc-core/dc-gamelogic/in-game/cricket-tactics/cricket-tactics.in-game.classes';
import { TeamSwiperStat } from '@dc-core/dc-gamelogic/in-game/ingame.globals';
import {
    DartCounterMatchTeam,
    DartCounterMatchUser,
    DartCounterPlayingMatch,
} from '@dc-core/dc-gamelogic/in-game/match/match.in-game.classes';
import { DCOnlineCore } from '@dc-core/dc-gamelogic/online-core.functions';
import { ONLINE_GAME_STATE_ACTIONS } from '@dc-core/dc-gamelogic/online-game/online.ingame.globals';
import { CAMERA_TYPE } from '@dc-core/dc-services/camera/camera.models';
import { InGameCameraService } from '@dc-core/dc-services/camera/ingame-camera.service';
import { JanusVideoRoomService } from '@dc-core/dc-services/dc-janus/janus-video-room.service';
import { OmniIngameService } from '@dc-core/dc-services/omni/omni-ingame.service';
import { DartCounterPreferenceService } from '@dc-core/dc-services/preference/preference.service';
import { VoiceCallService } from '@dc-core/dc-services/voice/voice-call.service';
import {
    IonicModule,
    IonRouterOutlet,
    MenuController,
    ModalController,
    ModalOptions,
    NavController,
} from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { DocumentReference } from 'firebase/firestore';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { Device_Images } from 'src/app/app.globals';
import { FullscreenIconComponent } from 'src/app/fullscreen-icon/fullscreen-icon.component';
import { InputFocusManagerService } from 'src/app/shared/services/input-focus-manager.service';
import { LiveCameraPreviewDialogPayload } from 'src/dialogs/live-camera-preview/live-camera-preview.dialog';
import { ReportUserDialogComponent } from 'src/dialogs/report-user/report-user.dialog';
import {
    SaveOnlineGameDialogComponent,
    SaveOnlineGameDialogResponse,
} from 'src/dialogs/save-online-game/save-online-game';
import { SpectatorsDialogComponent } from 'src/dialogs/spectators/spectators.dialog';
import { YourVirtCamViewDialogComponent } from 'src/dialogs/your-virt-cam-view/your-virt-cam-view.dialog';
import { environment } from 'src/environments/environment';
import { LeaveAfterReportDialogComponent } from 'src/pages/online/leave-after-report/leave-after-report.dialog';
import { OpponentQuitDialogComponent } from 'src/pages/online/opponent-quit/opponent-quit.dialog';
import { ProfileDialogComponent } from 'src/pages/online/profileDialog/profileDialog';

import { MatchInterimScoreComponent } from '../../../../match-interim-score/match-interim-score.component';
import { CricketTacticsTeamScoreComponent } from '../../cricket-tactics/components/cricket-tactics-team-score/cricket-tactics-team-score.component';
import { MatchTeamScoreComponent } from '../../match/components/match-team-score/match-team-score.component';
import { GamesLeaveBusterService } from '../../services/games-leave-buster.service';
import { GamesOnlineActionReplayComponent } from '../games-online-action-replay/games-online-action-replay.component';
import { GamesOnlineBoardCameraComponent } from '../games-online-board-camera/games-online-board-camera.component';
import { GamesOnlineChatComponent } from '../games-online-chat/games-online-chat.component';
import { GamesOnlineOwnCameraComponent } from '../games-online-own-camera/games-online-own-camera.component';
import { GamesOnlinePauseComponent } from '../games-online-pause/games-online-pause.component';
import { GamesOnlinePlayerCameraComponent } from '../games-online-player-camera/games-online-player-camera.component';
import { ScoreCamSegment } from '../games-online-spectator-layout/games-online-spectator-layout.component';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';
import { PageHeaderComponent } from '@components/page-header/page-header.component';

@Component({
    selector: 'app-games-online-page-layout',
    standalone: true,
    templateUrl: './games-online-page-layout.component.html',
    providers: [ScreenOrientation],
    imports: [
        CommonModule,
        IonicModule,
        ProfilePhotoComponent,
        TranslateModule,
        CricketTacticsTeamScoreComponent,
        GamesOnlinePauseComponent,
        GamesOnlineChatComponent,
        GamesOnlineBoardCameraComponent,
        GamesOnlinePlayerCameraComponent,
        GamesOnlineOwnCameraComponent,
        MatchInterimScoreComponent,
        MatchTeamScoreComponent,
        GamesOnlineActionReplayComponent,
        OmniOpponentViewComponent,
        FullscreenIconComponent,
        IconComponent,
        PageHeaderComponent,
    ],
})
export class GamesOnlinePageLayoutComponent implements OnInit, OnDestroy {
    public liveGameplay: InputSignal<OnlineGameplay> = input.required<OnlineGameplay>();
    public title: InputSignal<string> = input.required<string>();
    public currentGame: InputSignal<DartCounterPlayingMatch | DartCounterCricketTacticsGame> = input.required<
        DartCounterPlayingMatch | DartCounterCricketTacticsGame
    >();
    public currentUser: InputSignal<DartCounterMatchUser | DartCounterCricketTacticsUser> = input.required<
        DartCounterMatchUser | DartCounterCricketTacticsUser
    >();
    public currentTeam: InputSignal<DartCounterMatchTeam | DartCounterCricketTacticsTeam> = input.required<
        DartCounterMatchTeam | DartCounterCricketTacticsTeam
    >();
    public currentTurn: InputSignal<DartCounterCricketTacticsTurn> = input<DartCounterCricketTacticsTurn>();
    public currentStartingPlayerId: InputSignal<number> = input<number>();
    public gameType: InputSignal<GameMode> = input.required<GameMode>();
    public gameFinished: InputSignal<boolean> = input.required<boolean>();
    public isExtraLargeDevice: InputSignal<boolean> = input.required<boolean>();

    // Match
    public keyboard: InputSignal<InGameKeyboardType> = input<InGameKeyboardType>();
    public teamSwiperStats: InputSignal<TeamSwiperStat> = input<TeamSwiperStat>();

    public editScores: OutputEmitterRef<void> = output<void>();
    public saveGame: OutputEmitterRef<boolean> = output<boolean>();

    public onlineCore: DCOnlineCore = inject(DCOnlineCore);
    public voiceCallService: VoiceCallService = inject(VoiceCallService);

    protected actionsLogicService: ActionsLogicService = inject(ActionsLogicService);
    protected ingameCameraService: InGameCameraService = inject(InGameCameraService);
    protected ingameOmniService: OmniIngameService = inject(OmniIngameService);
    protected activeGamesCollectionService: ActiveGamesCollectionService = inject(ActiveGamesCollectionService);

    private videoRoomService: JanusVideoRoomService = inject(JanusVideoRoomService);
    private fireStoreAuthService: FireStoreAuthService = inject(FireStoreAuthService);
    private authService: AuthService = inject(AuthService);
    private screenOrientation: ScreenOrientation = inject(ScreenOrientation);
    private publicGamesCollectionService: PublicGamesCollectionService = inject(PublicGamesCollectionService);
    private spectatorsCollectionService: SpectatorsCollectionService = inject(SpectatorsCollectionService);
    private actionsCollectionService: ActionsCollectionService = inject(ActionsCollectionService);
    private modalController: ModalController = inject(ModalController);
    private navController: NavController = inject(NavController);
    private menuController: MenuController = inject(MenuController);
    private translateService: TranslateService = inject(TranslateService);
    private leaveBusterService: GamesLeaveBusterService = inject(GamesLeaveBusterService);
    private usersCollectionService: UsersCollectionService = inject(UsersCollectionService);
    private preferenceService: DartCounterPreferenceService = inject(DartCounterPreferenceService);
    private dcInvites: InviteCollectionService = inject(InviteCollectionService);
    private gameEventsCollectionService: GameEventsCollectionService = inject(GameEventsCollectionService);
    private routerOutlet: IonRouterOutlet = inject(IonRouterOutlet);
    private inputFocusManagerService: InputFocusManagerService = inject(InputFocusManagerService);

    public isWeb = environment.isWeb;

    public authenticatedUserId: number;
    public authenticatedUserUUID: string;
    public activeTab: WritableSignal<ScoreCamSegment> = signal('camera');
    public togglePause$: Subject<void> = new Subject<void>();
    public spectators: Signal<DCFireStoreSpectator[]> = computed(() => this.spectatorsCollectionService.spectators());

    public isLandscape: boolean;
    public deviceImages = Device_Images;
    public maxPlayerCamHeight: number = 200;

    protected destroyRef: DestroyRef = inject(DestroyRef);

    constructor() {
        effect(
            () => {
                const currentUser = this.currentUser();
                this.leaveBusterService.currentUserId = currentUser.user_id;

                this.ingameCameraService.checkReportRecording(currentUser.user_id, this.authenticatedUserId); // Before setting current user
                this.ingameCameraService.setCurrentUserMedia(currentUser.user_id);
                this.ingameCameraService.checkActionReplayAndStartRecording(this.authenticatedUserId, true);

                const activeTab = untracked(this.activeTab);
                if (activeTab === 'auto_scores' || activeTab === 'auto_camera' || activeTab == 'omni_scoring') {
                    if (
                        _.find(
                            untracked(this.liveGameplay).players,
                            (player) => player.user_id === this.currentUser().user_id
                        )?.has_omni &&
                        !this.isExtraLargeDevice()
                    ) {
                        this.toggleTab('omni_scoring');
                    } else if (
                        _.find(
                            untracked(this.liveGameplay).players,
                            (player) => player.user_id === this.currentUser().user_id
                        )?.room?.roomID &&
                        this.hasBoardCameraStream()
                    ) {
                        this.toggleTab('auto_camera');
                    } else {
                        this.toggleTab('auto_scores');
                    }
                }
            },
            { allowSignalWrites: true }
        );

        effect(() => {
            this.maxPlayerCamHeight = this.ingameCameraService.playerCamMaxHeight();
        });
    }

    public ngOnInit(): void {
        this.menuController.enable(false, 'mainmenu');
        this.menuController.enable(true, 'ingamemenu');

        this.preferenceService.ingameMode = this.gameType();
        this.preferenceService.ingameData = this.currentGame();

        this.getAuthenticatedUserIds();
        this.initOrientationHandler();

        this.dcInvites.allowInvites = false;
        this.activeGamesCollectionService.inGame = true;
        this.onlineCore.isSpectating = false;

        this.initLeaveBuster(this.currentUser().user_id, this.liveGameplay());

        this.ingameCameraService.init();
        this.checkIfVIRT(this.authService.user.id);

        this.routerOutlet.swipeGesture = false;

        this.liveGameplay().players.forEach((player) => {
            if (player.user_id === this.authService.user.id) {
                this.usersCollectionService.updateUserStatus(ONLINESTATUS.PLAYING);
            }
        });

        this.activeGamesCollectionService.activeGameRef$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((gameRef: DocumentReference<OnlineGameplay> | null) => {
                if (gameRef) {
                    this.spectatorsCollectionService.enableSpectatorsFeature(gameRef);
                    this.initActionHandler(gameRef, this.onlineCore.isSpectating, this.authenticatedUserId);
                    this.initOnlineGameStateHandler(this.authenticatedUserId);

                    //Camera setup
                    this.initCameraActionHandler(this.authenticatedUserId, this.liveGameplay().players);
                    //Omni setup
                    this.initOmniActionHandler(this.authenticatedUserId, this.liveGameplay().players);
                }
            });

        if (this.liveGameplay().players.some((player) => player.room !== null)) {
            if (
                _.find(this.liveGameplay().players, (player) => player.user_id === this.currentUser().user_id)?.room
                    ?.roomID
            ) {
                this.toggleTab('auto_camera');
            } else {
                this.toggleTab('auto_scores');
            }
        } else if (this.hasOmni() && !this.isWeb) {
            this.toggleTab('omni_scoring');
        } else {
            this.toggleTab('none');
        }
    }

    public ngOnDestroy(): void {
        this.menuController.enable(true, 'mainmenu');
        this.menuController.enable(false, 'ingamemenu');

        this.routerOutlet.swipeGesture = true;

        this.usersCollectionService.removeActiveGameRef(ONLINESTATUS.ONLINE);

        this.onlineCore.pauseTimer();
        this.onlineCore.clearSubjects();

        this.spectatorsCollectionService.disableSpectatorsFeature();
        this.actionsCollectionService.disableActions();
        this.ingameCameraService.cleanService();
        this.gameEventsCollectionService.disableGameEvents();
        this.voiceCallService.hangup(false);

        if (this.ingameCameraService.hasNetworkChangeListener)
            this.ingameCameraService.hasNetworkChangeListener = false;

        this.leaveBusterService.resetOnlineLeaveBuster();

        this.dcInvites.allowInvites = true;
        this.activeGamesCollectionService.inGame = false;
        this.activeGamesCollectionService.isJoining = false;
    }

    public toggleTab(segment: ScoreCamSegment): void {
        this.activeTab.set(segment);
    }

    public hasOmni(): boolean {
        return !!this.ingameOmniService.usersWithOmni.find((userID) => userID === this.currentUser().user_id);
    }

    public isDualStream(): boolean {
        return this.ingameCameraService.currentUserMedia?.videoStreams.dualStreams;
    }

    public hasBoardCameraStream(): boolean {
        return !!this.ingameCameraService.currentUserMedia?.videoStreams.board;
    }

    public tabsVisible(): boolean {
        return (
            (this.isExtraLargeDevice()
                ? this.hasBoardCameraStream() && this.hasOmni()
                : this.hasBoardCameraStream() || this.hasOmni()) &&
            !this.onlineCore.pause &&
            this.currentUser().user_id !== this.authenticatedUserId
        );
    }

    public displayLegScores(): boolean {
        if (
            this.liveGameplay().type === 'match' &&
            (this.keyboard() === 'none' || this.currentUser().user_id !== this.authenticatedUserId)
        ) {
            if (this.tabsVisible()) {
                return this.activeTab() === 'auto_scores' || this.activeTab() === 'scores';
            }
            return true;
        }
        return false;
    }

    public displayBoardCam(): boolean {
        if (this.hasBoardCameraStream()) {
            if (this.tabsVisible()) {
                return (
                    this.activeTab() === 'auto_camera' ||
                    this.activeTab() === 'camera' ||
                    (this.activeTab() === 'omni_scoring' && !this.hasOmni())
                );
            }
            return true;
        }
        return false;
    }

    public displayPlayerCam(): boolean {
        if (this.isDualStream() && this.displayBoardCam()) {
            if (this.tabsVisible()) {
                return (
                    this.activeTab() === 'auto_camera' ||
                    this.activeTab() === 'camera' ||
                    (this.activeTab() === 'omni_scoring' && !this.hasOmni())
                );
            }
            return true;
        }
        return false;
    }

    public displayOmni(): boolean {
        if (this.hasOmni() && this.liveGameplay().type === 'match') {
            if (this.tabsVisible()) {
                return (
                    // OMNI is always visible on a large device
                    this.isExtraLargeDevice() ||
                    this.activeTab() === 'omni_scoring' ||
                    ((this.activeTab() === 'camera' || this.activeTab() === 'auto_camera') &&
                        !this.hasBoardCameraStream())
                );
            }
            return true;
        }
        return false;
    }

    public showPlayerInfo(user: User, authenticatedUserId: number): void {
        this.modalController
            .create({
                component: ProfileDialogComponent,
                componentProps: {
                    user,
                    canReport: authenticatedUserId !== user.id,
                    showMoreButton: false,
                },
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                backdropDismiss: true,
                showBackdrop: true,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data === 'report') {
                        this.modalController
                            .create({
                                component: ReportUserDialogComponent,
                                cssClass: 'auto-height',
                                componentProps: {
                                    user,
                                },
                            })
                            .then((elem) => {
                                elem.present();
                                elem.onDidDismiss().then((dialogRes) => {
                                    const reported: boolean = dialogRes.data;
                                    if (reported) {
                                        this.modalController
                                            .create({
                                                component: LeaveAfterReportDialogComponent,
                                                cssClass: 'auto-height',
                                                showBackdrop: true,
                                                backdropDismiss: false,
                                            } as ModalOptions)
                                            .then((elem) => {
                                                elem.present();
                                                elem.onDidDismiss().then((dialogRes) => {
                                                    if (dialogRes.data) {
                                                        this.leaveBusterService.leaveAfterReport = true;
                                                        this.goToGlobalLobby();
                                                    }
                                                });
                                            });
                                    }
                                });
                            });
                    }
                });
            });
    }

    public showSpectators(): void {
        this.modalController
            .create({
                component: SpectatorsDialogComponent,
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then(() => this.inputFocusManagerService.triggerFocusInput());
            });
    }

    public goToGlobalLobby(): void {
        this.navController.navigateBack(this.activeGamesCollectionService.redirectBack);
    }

    public opponentThrowLabel(user: string): string {
        return this.translateService.instant('USERS_TURN_TO_THROW', { user });
    }

    private checkIfVIRT(authenticatedUserId: number): void {
        if (
            this.videoRoomService.ownCamera.roomID &&
            this.videoRoomService.ownCamera.camType === CAMERA_TYPE.SMART_DEVICE
        ) {
            this.modalController
                .create({
                    component: YourVirtCamViewDialogComponent,
                    componentProps: {
                        userId: authenticatedUserId,
                    } as LiveCameraPreviewDialogPayload,
                    cssClass: 'auto-height',
                })
                .then((elem) => {
                    elem.present();
                });
        }
    }

    private getAuthenticatedUserIds(): void {
        this.authenticatedUserId = this.authService.user.id;

        setTimeout(() => {
            this.authenticatedUserUUID = this.fireStoreAuthService.getCurrentUID();
            if (!this.authenticatedUserUUID) {
                this.getAuthenticatedUserIds();
            }
        }, 100);
    }

    private initOrientationHandler(): void {
        this.isLandscape = this.screenOrientation.type.indexOf('landscape') >= 0;
        this.screenOrientation
            .onChange()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                this.isLandscape = this.screenOrientation.type.indexOf('landscape') >= 0;
            });
    }

    private initCameraActionHandler(authenticatedUserId: number, players: DCFireStoreUser[]): void {
        this.ingameCameraService.checkPlayerRooms(players, this.currentUser().user_id);

        this.actionsLogicService.cameraActions$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((camAction: FSCameraAction) => {
                if (camAction) {
                    players.forEach((player) => {
                        if (player.user_id === camAction.sender_user_id) {
                            player.room = camAction.camera;
                            this.ingameCameraService.addUserToGame(player, this.currentUser().user_id);

                            if (player.user_id === authenticatedUserId) {
                                this.activeGamesCollectionService.updateOnlinePlayers(null, players);
                            }
                        }
                    });

                    if (this.liveGameplay().players.some((player) => player.room !== null)) {
                        if (
                            this.activeTab() === 'none' ||
                            this.activeTab() === 'auto_scores' ||
                            this.activeTab() === 'auto_camera'
                        ) {
                            if (
                                _.find(
                                    this.liveGameplay().players,
                                    (player) => player.user_id === this.currentUser().user_id
                                )?.room?.roomID
                            ) {
                                this.toggleTab('auto_camera');
                            } else {
                                this.toggleTab('auto_scores');
                            }
                        }
                    }
                }
            });
    }

    private initOmniActionHandler(authenticatedUserId: number, players: DCFireStoreUser[]): void {
        this.ingameOmniService.checkPlayerOmnis(players);

        this.actionsLogicService.omniStateActions$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((omniState: FSOmniStateAction) => {
                players.forEach((player) => {
                    if (player.user_id === omniState.sender_user_id) {
                        player.has_omni = omniState.isActive;

                        if (player.has_omni) {
                            this.toggleTab('omni_scoring');
                        }

                        this.ingameOmniService.checkPlayerOmnis(players);
                        this.activeGamesCollectionService.updateOnlinePlayers(null, players);
                    }
                });
            });
    }

    private initActionHandler(
        gameRef: DocumentReference<OnlineGameplay>,
        isSpectating: boolean,
        authenticatedUserId: number
    ): void {
        this.actionsCollectionService.enableActions(gameRef, isSpectating);

        this.actionsCollectionService.onlineActions$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((gameEvent: DCGameAction) => {
                if (gameEvent.sender_user_id !== authenticatedUserId || gameEvent.toSelf) {
                    this.actionsLogicService.checkAction(gameEvent);
                }
            });
    }

    private initOnlineGameStateHandler(authenticatedUserId: number): void {
        this.actionsLogicService.gameStateActions$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((gameStateAction) => {
                if (gameStateAction && gameStateAction.sender_user_id != authenticatedUserId) {
                    this.onlineCore.pauseTimer();

                    switch (gameStateAction.state) {
                        case ONLINE_GAME_STATE_ACTIONS.OPPONENT_QUIT:
                        case ONLINE_GAME_STATE_ACTIONS.OPPONENT_SAVED_TO_STATS:
                            this.showOpponentQuitDialog();
                            break;
                        case ONLINE_GAME_STATE_ACTIONS.OPPONENT_SAVED_TO_CONTINUE:
                            this.showOpponentQuitWithSaveDialog();
                            break;
                    }
                }
            });
    }

    private initLeaveBuster(currentUserId: number, onlineGame: OnlineGameplay): void {
        this.leaveBusterService.initOnlineLeaveBuster(currentUserId, onlineGame);

        this.leaveBusterService.saveGame$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((gameFinished: boolean) => {
                this.saveGame.emit(gameFinished);
            });
    }

    private showOpponentQuitDialog(): void {
        this.ingameCameraService.cleanService();

        this.modalController
            .create({
                component: OpponentQuitDialogComponent,
                cssClass: 'auto-height',
                showBackdrop: true,
                backdropDismiss: false,
            } as ModalOptions)
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    this.leaveBusterService.allowedToLeave = true;

                    if (dialogRes.data === 'save') {
                        this.saveGame.emit(false);
                    }
                    this.quitOnlineGame();

                    this.goToGlobalLobby();
                });
            });
    }

    private showOpponentQuitWithSaveDialog(): void {
        this.ingameCameraService.cleanService();

        this.modalController
            .create({
                component: OpponentQuitDialogComponent,
                cssClass: 'auto-height',
                showBackdrop: true,
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data === 'quit') {
                        this.leaveBusterService.allowedToLeave = true;

                        this.quitOnlineGame();

                        this.goToGlobalLobby();
                    } else if (dialogRes.data === 'save') {
                        this.modalController
                            .create({
                                component: SaveOnlineGameDialogComponent,
                                cssClass: 'auto-height',
                            } as ModalOptions)
                            .then((elem) => {
                                elem.present();

                                elem.onDidDismiss().then((dialogRes) => {
                                    const result: SaveOnlineGameDialogResponse = dialogRes.data;
                                    if (result === 'save_to_continue') {
                                        this.leaveBusterService.allowedToLeave = true;

                                        this.leaveOnlineGame();

                                        this.goToGlobalLobby();
                                    } else if (result === 'save_to_stats') {
                                        this.leaveBusterService.allowedToLeave = true;

                                        this.saveGame.emit(false);
                                        this.quitOnlineGame();

                                        this.goToGlobalLobby();
                                    }
                                });
                            });
                    }
                });
            });
    }

    public getCurrentUserInfo() {
        return this.liveGameplay().players.find((fsUser) => fsUser.user_id == this.currentUser().user_id);
    }

    private quitOnlineGame(): void {
        this.activeGamesCollectionService.quitOnlineGame();
        this.publicGamesCollectionService.remove();
    }

    private leaveOnlineGame(): void {
        this.activeGamesCollectionService.leaveOnlineGame();
        this.publicGamesCollectionService.remove();
    }
}
