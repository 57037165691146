/* eslint-disable max-len */
import { inject, Injectable } from '@angular/core';
import { UserApiService } from '@dc-api/user.api.service';
import { SuspensionFeature } from '@dc-core/dc-backend/dc-enums';
import { CheckSuspensionResponse } from '@dc-core/dc-backend/dc-responses';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import moment from 'moment';
import { catchError, EMPTY, from, Observable, of, switchMap } from 'rxjs';

import { environment } from 'src/environments/environment';
import { UserBirthdayEmailDialogComponent } from '../components/user-birthday-email-dialog/user-birthday-email-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private modalController: ModalController = inject(ModalController);
    private alertService: DartCounterAlertService = inject(DartCounterAlertService);
    private translateService: TranslateService = inject(TranslateService);
    private authService: AuthService = inject(AuthService);
    private userApiService: UserApiService = inject(UserApiService);

    private MIN_AGE: number = 18;

    public canUseSocialFeatures(allowProfileUpdate: boolean = false, showAlert = true): Observable<boolean> {
        if (allowProfileUpdate) {
            if (
                !this.authService.user.email ||
                !this.authService.user.profile?.birthdate ||
                !this.authService.user.email_verified_at
            ) {
                return from(
                    this.modalController.create({
                        component: UserBirthdayEmailDialogComponent,
                        componentProps: {
                            user: this.authService.user,
                        },
                        cssClass: environment.isWeb ? ['slide-modal', 'web' ] : ['slide-modal', 'from-bottom'],
                    })
                ).pipe(
                    switchMap((elem) => {
                        elem.present();

                        return from(elem.onDidDismiss()).pipe(
                            switchMap((dialogRes: { data: boolean }) => {
                                if (dialogRes.data) {
                                    return this.checkSocialFeaturesAccess(showAlert);
                                }

                                return of(false);
                            })
                        );
                    }),
                    catchError(() => {
                        return EMPTY;
                    })
                );
            } else {
                return this.checkSocialFeaturesAccess(showAlert);
            }
        } else {
            return this.checkSocialFeaturesAccess(showAlert);
        }
    }

    public checkSuspension(feature: SuspensionFeature, showAlert: boolean): Promise<CheckSuspensionResponse> {
        return new Promise<CheckSuspensionResponse>((resolve) => {
            this.userApiService
                .checkSuspension({ feature })
                .then((res) => {
                    if (showAlert && res.data.suspended) {
                        $localize`:@@ACCOUNT_BLOCKED_FROM_USING_FEATURE_UNTIL_DATE:Your account has been blocked from using this feature until ${res.data.until}:date:.`;
                        this.alertService.createAlert({
                            title: res.data.until
                                ? this.translateService.instant('ACCOUNT_BLOCKED_FROM_USING_FEATURE_UNTIL_DATE', {
                                      date: moment(res.data.until).format('DD MMM yyyy HH:mm'),
                                  })
                                : $localize`:@@ACCOUNT_BLOCKED_FROM_USING_FEATURE:Your account has been blocked from using this feature.`,
                            text: $localize`:@@INVALID_SUSPENSION_CONTACT_OUR_SUPPORT_TEAM:If you believe this is an error, please contact our support team.`,
                            icon: 'error',
                            timer: null,
                            showCloseButton: true,
                        });
                    }
                    resolve(res.data);
                })
                .catch(() => resolve({ suspended: false }));
        });
    }

    private checkSocialFeaturesAccess(showAlert: boolean): Observable<boolean> {
        const validEmail = !!this.authService.user.email && !!this.authService.user.email_verified_at;
        const validBirthday = !!this.authService.user.profile?.birthdate;

        if (!validEmail && !validBirthday) {
            if (showAlert) {
                this.alertService.createAlert({
                    title: $localize`:@@FEATURE_ONLY_AVAILABLE_WITH_VALID_EMAIL_AND_BIRTHDATE:This feature is only available for users that have set a birthdate and have verified their email.`,
                    icon: 'error',
                    timer: null,
                    showCloseButton: true,
                });
            }
            return of(false);
        } else if (!validEmail) {
            if (showAlert) {
                this.alertService.createAlert({
                    title: $localize`:@@FEATURE_ONLY_AVAILABLE_WITH_VALID_EMAIL:This feature is only available for users that have verified their email.`,
                    icon: 'error',
                    timer: null,
                    showCloseButton: true,
                });
            }
            return of(false);
        } else if (!validBirthday) {
            if (showAlert) {
                this.alertService.createAlert({
                    title: $localize`:@@FEATURE_ONLY_AVAILABLE_WITH_VALID_BIRTHDATE:This feature is only available for users that have set a birthdate.`,
                    icon: 'error',
                    timer: null,
                    showCloseButton: true,
                });
            }
            return of(false);
        } else if (moment().diff(this.authService.user.profile?.birthdate, 'years') < this.MIN_AGE) {
            $localize`:@@FEATURE_ONLY_AVAILABLE_FOR_AGE:This feature is only available for users older than ${this.MIN_AGE}:age:.`;
            if (showAlert) {
                this.alertService.createAlert({
                    title: this.translateService.instant('FEATURE_ONLY_AVAILABLE_FOR_AGE', { age: this.MIN_AGE }),
                    icon: 'error',
                    timer: null,
                    showCloseButton: true,
                });
            }
            return of(false);
        } else {
            return from(this.checkSuspension('camera_and_chat', showAlert)).pipe(
                switchMap((suspension) => {
                    return of(!suspension.suspended);
                })
            );
        }
    }
}
