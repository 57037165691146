<ng-container *ngIf="0" i18n="@@BILLED_ANNUALLY">Billed annually</ng-container>

<div class="flex h-full flex-col overflow-y-auto overscroll-none bg-white-pattern">
    <app-page-header mainNavigation="custom" color="white">
        <div left>
            <div
                class="text-center text-sm font-bold text-black"
                (click)="dismissModal()"
                [translate]="'CANCEL'"
                i18n="@@CANCEL">
                Cancel
            </div>
        </div>
        @if (currentSlide) {
            <div title>{{ currentSlide.title }}</div>
        }
    </app-page-header>

    <div class="mt-2 flex-1 rounded-t-4xl bg-white pt-6 px-safe" *ngIf="currentSlide">
        <div class="space-y-3 bg-white px-5" *ngIf="!isEnd">
            <div class="dialog-input-label">{{ currentSlide.name }}</div>
            <div class="flex items-center justify-between space-x-2">
                <div
                    class="h-1.5 w-full rounded-full"
                    [class.bg-orange-50]="slide.index > currentSlide.index"
                    [class.bg-orange]="slide.index <= currentSlide.index"
                    *ngFor="let slide of slides"></div>
            </div>
        </div>

        <div class="mt-8 flex overflow-x-scroll bg-white scrollbar-hide">
            <div class="flex-remain space-y-4 px-5" *ngIf="currentSlide.type === 'how_it_works'" [@slideAnimation]>
                <div class="grid grid-cols-12 gap-3">
                    <div class="col-span-2 inline-flex items-center">
                        <div class="inline-flex h-7 w-7 items-center justify-center rounded-full bg-orange">
                            <div class="font-bebas-neue text-lg">1</div>
                        </div>
                    </div>
                    <div class="col-span-10">
                        <div
                            class="text-xl font-bold text-black"
                            [translate]="'UNLOCK_DARTCOUNTER_ULTIMATE'"
                            i18n="@@UNLOCK_DARTCOUNTER_ULTIMATE">
                            Instantly unlock DartCounter Ultimate
                        </div>
                        <div
                            class="text-neutral-400"
                            [translate]="'UNLOCK_DARTCOUNTER_ULTIMATE_DESCRIPTION'"
                            i18n="@@UNLOCK_DARTCOUNTER_ULTIMATE_DESCRIPTION">
                            Instantly play unlimited online, have the MasterCaller call your name and view all game
                            details and personal statistics
                        </div>
                    </div>
                </div>
                <div class="grid grid-cols-12 gap-3">
                    <div class="col-span-2 inline-flex items-center">
                        <div class="inline-flex h-7 w-7 items-center justify-center rounded-full bg-orange">
                            <div class="font-bebas-neue text-lg">2</div>
                        </div>
                    </div>
                    <div class="col-span-10">
                        <div class="text-xl font-bold text-black" [translate]="'AFTER_28_DAYS'" i18n="@@AFTER_28_DAYS">
                            After 28 days
                        </div>
                        <div
                            class="text-neutral-400"
                            [translate]="'AFTER_28_DAYS_DESCRIPTION'"
                            i18n="@@AFTER_28_DAYS_DESCRIPTION">
                            Your membership will be transferred to a full DartCounter Ultimate subscription.
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex-remain space-y-4 px-5" *ngIf="currentSlide.type === 'benefits'" [@slideAnimation]>
                <div class="grid grid-cols-12 gap-3">
                    <div class="col-span-2 inline-flex items-center">
                        <div class="inline-flex h-7 w-7 items-center justify-center rounded-full bg-orange">
                            <div class="font-bebas-neue text-lg">1</div>
                        </div>
                    </div>
                    <div class="col-span-10">
                        <div
                            class="text-xl font-bold text-black"
                            [translate]="'FEATURE_PLAYONLINE'"
                            i18n="@@FEATURE_PLAYONLINE">
                            Play unlimited online games
                        </div>
                        <div
                            class="text-neutral-400"
                            [translate]="'FEATURE_PLAYONLINE_DESCRIPTION'"
                            i18n="@@FEATURE_PLAYONLINE_DESCRIPTION">
                            Play unlimited online games against others on a daily basis
                        </div>
                    </div>
                </div>
                <div class="grid grid-cols-12 gap-3">
                    <div class="col-span-2 inline-flex items-center">
                        <div class="inline-flex h-7 w-7 items-center justify-center rounded-full bg-orange">
                            <div class="font-bebas-neue text-lg">2</div>
                        </div>
                    </div>
                    <div class="col-span-10">
                        <div
                            class="text-xl font-bold text-black"
                            [translate]="'FEATURE_CALLER'"
                            i18n="@@FEATURE_CALLER">
                            MasterCaller calls your name
                        </div>
                        <div
                            class="text-neutral-400"
                            [translate]="'FEATURE_CALLER_DESCRIPTION'"
                            i18n="@@FEATURE_CALLER">
                            Let MasterCaller Ray Martin call your name during your games.
                        </div>
                    </div>
                </div>
                <div class="grid grid-cols-12 gap-3">
                    <div class="col-span-2 inline-flex items-center">
                        <div class="inline-flex h-7 w-7 items-center justify-center rounded-full bg-orange">
                            <div class="font-bebas-neue text-lg">3</div>
                        </div>
                    </div>
                    <div class="col-span-10">
                        <div
                            class="text-xl font-bold text-black"
                            [translate]="'FEATURE_ALLTIME'"
                            i18n="@@FEATURE_ALLTIME">
                            Detailed all-time statistics
                        </div>
                        <div
                            class="text-neutral-400"
                            [translate]="'FEATURE_ALLTIME_DESCRIPTION'"
                            i18n="@@FEATURE_ALLTIME_DESCRIPTION">
                            View in-game statistics of all your played games
                        </div>
                    </div>
                </div>
                <div class="grid grid-cols-12 gap-3">
                    <div class="col-span-2 inline-flex items-center">
                        <div class="inline-flex h-7 w-7 items-center justify-center rounded-full bg-orange">
                            <div class="font-bebas-neue text-lg">4</div>
                        </div>
                    </div>
                    <div class="col-span-10">
                        <div
                            class="text-xl font-bold text-black"
                            [translate]="'FEATURE_CHARTS'"
                            i18n="@@FEATURE_CHARTS">
                            Unlimited statistics on our website
                        </div>
                        <div
                            class="text-neutral-400"
                            [translate]="'FEATURE_CHARTS_DESCRIPTION'"
                            i18n="@@FEATURE_CHARTS_DESCRIPTION">
                            View all your personal and highly-detailed statistics on the DartCounter website.
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="flex-remain space-y-4 px-5"
                *ngIf="currentSlide.type === 'choose_subscription'"
                [@slideAnimation]>
                <div
                    class="grid grid-cols-12 items-center rounded-lg border-2 bg-neutral-50 p-3 text-black"
                    [class.border-orange]="recurring === 'yearly'"
                    [class.border-neutral-50]="recurring !== 'yearly'"
                    (click)="setRecurring('yearly')">
                    <!-- *ngIf="purchases.yearlyProduct && !isIOS"> -->
                    <div class="col-span-7">
                        <div [translate]="'YEARLY'" i18n="@@YEARLY" class="text-xl font-semibold">Yearly</div>
                        <div class="mt-1 text-xxs font-extralight text-neutral-400">
                            {{ 'BILLED_ANNUALLY' | translate }} ({{
                                removeSpaces(purchases.yearlyProduct?.pricing?.price)
                            }})
                        </div>
                        <div
                            *ngIf="purchases.yearlyProduct?.pricing?.price"
                            class="mt-2 inline-flex flex-row items-end rounded-md border-2 px-3 py-2 font-bebas-neue text-black"
                            [class.border-orange]="recurring === 'yearly'"
                            [class.border-black]="recurring !== 'yearly'">
                            <div class="text-4xl">{{ yearPricePerMonth(purchases.yearlyProduct?.pricing) }}</div>
                            <div class="ml-1 text-lg" [translate]="'PER_MONTH'" i18n="@@PER_MONTH">per month</div>
                        </div>
                    </div>
                    <div class="col-span-3 col-start-8 inline-flex items-center">
                        <div
                            class="sale inline-flex w-full flex-col items-center rounded-md bg-orange p-2 text-center font-bebas-neue text-white">
                            <div class="text-1xl" [translate]="'SAVE_DISCOUNT'" i18n="@@SAVE_DISCOUNT">Save</div>
                            <div class="text-4xl">38%</div>
                        </div>
                    </div>
                    <div class="col-span-1 col-start-12 inline-flex items-center justify-end text-neutral-400">
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                    </div>
                </div>
                <div
                    class="mt-3 grid grid-cols-12 items-center rounded-lg border-2 bg-neutral-50 p-3 text-black"
                    [class.border-orange]="recurring === 'monthly'"
                    [class.border-neutral-50]="recurring !== 'monthly'"
                    (click)="setRecurring('monthly')">
                    <!-- *ngIf="purchases.yearlyProduct && !isIOS"> -->
                    <div class="col-span-7">
                        <div [translate]="'MONTHLY'" i18n="@@MONTHLY" class="text-xl font-semibold">Monthly</div>
                        <div
                            [translate]="'MONTHLY_PAYMENT'"
                            i18n="@@MONTHLY_PAYMENT"
                            class="mt-1 text-xxs font-extralight text-neutral-400">
                            Monthly payment
                        </div>
                        <div
                            *ngIf="purchases.monthlyProduct?.pricing?.price"
                            class="mt-2 inline-flex flex-row items-end rounded-md border-2 px-3 py-2 font-bebas-neue text-black"
                            [class.border-orange]="recurring === 'monthly'"
                            [class.border-black]="recurring !== 'monthly'">
                            <div class="text-4xl">{{ removeSpaces(purchases.monthlyProduct?.pricing?.price) }}</div>
                            <div class="ml-1 text-lg" [translate]="'PER_MONTH'" i18n="@@PER_MONTH">per month</div>
                        </div>
                    </div>
                    <div class="col-span-1 col-start-12 inline-flex items-center justify-end text-neutral-400">
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="flex flex-col space-y-2 bg-white px-5 pl-10 pr-10 pt-5 pb-safe-offset-5">
        <app-primary-button size="normal" addedClasses="w-full" (click)="nextSlide()">
            {{ currentSlide.nextText }}
        </app-primary-button>
        <div class="pl-10 pr-10" *ngIf="currentSlide.type === 'benefits' || currentSlide.type === 'how_it_works'">
            <app-primary-button
                size="extrasmall"
                color="white"
                addedClasses="w-full text-neutral-200 font-extralight"
                fill="clear"
                [underline]="false"
                [translate]="'CANCEL_ANYTIME'"
                i18n="@@CANCEL_ANYTIME"
                [bold]="false">
                Not convinced? You can cancel your subscription at any time.
            </app-primary-button>
        </div>
    </div>
</div>
