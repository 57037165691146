<app-page-header mainNavigation="back" defaultBack="dashboard" color="transparent">
    <div title class="flex justify-center items-center centerLogo">
        <img src="assets/icon/dctarget-line-grey.png" alt="dartcounter logo dark" cs />
    </div>
</app-page-header>

<ion-content scrollY="false" fullscreen="true">
    <!-- Options to choose from before going to an online Lobby -->
    <div id="fullheight">
        <div class="DCScalable">
            <div class="circle" (click)="OpenGlobalLobby()">
                <div class="circletext" [translate]="'ONLINE_GLOBAL'" i18n="@@ONLINE_GLOBAL">Online global</div>
            </div>
        </div>
        <div class="DCScalable">
            <div class="DCScalable">
                <div class="circle" (click)="OpenFriendsLobby()">
                    <div class="circletext" [translate]="'FRIENDS'" i18n="@@FRIENDS">Friends</div>
                </div>
            </div>
        </div>
    </div>
</ion-content>
