<ion-row style="text-transform: uppercase">
    <ion-col
        size="4"
        class="dcKeyboardKey"
        style="font-size: 14px"
        [class.selectedKey]="multiplier === 1"
        (click)="selectMultiplier(1)">
        <span [translate]="'SINGLE'" i18n="@@SINGLE">Single</span>
    </ion-col>
    <ion-col
        size="4"
        class="dcKeyboardKey"
        style="font-size: 14px"
        [class.selectedKey]="multiplier === 2"
        (click)="selectMultiplier(2)">
        <span [translate]="'DOUBLE'" i18n="@@DOUBLE">Double</span>
    </ion-col>
    <ion-col
        size="4"
        class="dcKeyboardKey"
        style="font-size: 14px"
        [class.selectedKey]="multiplier === 3"
        (click)="selectMultiplier(3)">
        <span [translate]="'TREBLE'" i18n="@@TREBLE">Treble</span>
    </ion-col>
</ion-row>
<ion-row>
    <ion-col class="dcKeyboardKey" (click)="selectAmount(amount)" *ngFor="let amount of [1,2,3,4,5]">
        <span>{{ amount }}</span>
        <span class="multiplier-hint" *ngIf="multiplier > 1">({{ amount * multiplier }})</span>
    </ion-col>
</ion-row>
<ion-row>
    <ion-col class="dcKeyboardKey" (click)="selectAmount(amount)" *ngFor="let amount of [6,7,8,9,10]">
        <span>{{ amount }}</span>
        <span class="multiplier-hint" *ngIf="multiplier > 1">({{ amount * multiplier }})</span>
    </ion-col>
</ion-row>
<ion-row>
    <ion-col class="dcKeyboardKey" (click)="selectAmount(amount)" *ngFor="let amount of [11,12,13,14,15]">
        <span>{{ amount }}</span>
        <span class="multiplier-hint" *ngIf="multiplier > 1">({{ amount * multiplier }})</span>
    </ion-col>
</ion-row>
<ion-row>
    <ion-col class="dcKeyboardKey" (click)="selectAmount(amount)" *ngFor="let amount of [16,17,18,19,20]">
        <span>{{ amount }}</span>
        <span class="multiplier-hint" *ngIf="multiplier > 1">({{ amount * multiplier }})</span>
    </ion-col>
</ion-row>
<ion-row>
    <ion-col class="dcKeyboardKey">
        <ion-button (click)="undo()" expand="full" class="iconButton" color="danger">
            <ion-icon slot="icon-only" style="font-size: 24px" name="arrow-undo-outline"></ion-icon>
        </ion-button>
    </ion-col>
    <ion-col class="dcKeyboardKey">
        @if(timer && myTurn) {
            <div class="defaultTime">
            <app-icon icon="stopwatch"></app-icon>
            <span class="timerValue">{{timer}}</span>
        </div>
        }
    </ion-col>
    <ion-col
        *ngIf="!timer"
        [class.selectedKey]="amount === 25 && multiplier === 1"
        (click)="selectAmount(25)"
        class="dcKeyboardKey selectKey">
        25
    </ion-col>
    <ion-col (click)="selectAmount(25, 2)" class="dcKeyboardKey selectKey">
        <span [translate]="'BULL'" i18n="@@BULL">Bull</span>
        <span class="multiplier-hint" *ngIf="multiplier > 1">(50)</span>
    </ion-col>
    <ion-col
        [class.selectedKey]="amount === 0"
        (click)="selectAmount(0,0)"
        class="dcKeyboardKey selectKey"
        [translate]="'MISS'"
        i18n="@@MISS"
        >Miss</ion-col
    >
    <ion-col class="dcKeyboardKey" (click)="submit()">
        <ion-button class="iconButton" color="primary">
            <ion-icon slot="icon-only" style="font-size: 24px" name="arrow-forward-outline"></ion-icon>
        </ion-button>
    </ion-col>
</ion-row>
