import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, inject, input, InputSignal, OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { OmniSingleDart } from '@dc-core/dc-services/omni/omni-ingame.service';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';

import { KeyboardDartComponent } from '../../app/keyboard-dart/keyboard-dart.component';
import { EditOmniScoreDialogComponent, EditOmniScorePayload } from './edit-omni-score/edit-omni-score.dialog';

export interface CartesianCoordinate {
    x: number;
    y: number;
}

export enum OmniThrowResponseState {
    WAITING = 'WAITING',
    THROW = 'THROW',
    MISS = 'MISS',
    BOUNCEOUT = 'BOUNCEOUT',
}

@Component({
    selector: 'app-zoomed-point',
    standalone: true,
    templateUrl: './zoomed-point.component.html',
    styleUrls: ['./zoomed-point.component.scss'],
    imports: [KeyboardDartComponent],
})
export class ZoomedPointComponent implements OnInit {
    public point: InputSignal<OmniSingleDart> = input<OmniSingleDart>(null);
    public zoomLevel: InputSignal<number> = input<number>(3);
    public dartIndex: InputSignal<number> = input<number>(null);

    public animate: boolean = false;
    public omniThrowResponseState: OmniThrowResponseState = OmniThrowResponseState.WAITING;

    public svgContent: string = null;
    private dartboardSvgUrl: string = 'assets/images/tor_board.svg'; // Path to your SVG file
    private static cachedSvg: SVGElement | null = null; // Cache static variable for the SVG
    private retryCount = 0;
    private maxRetries = 10;

    private modalController: ModalController = inject(ModalController);
    private _http: HttpClient = inject(HttpClient);
    private elementRef: ElementRef = inject(ElementRef);
    private renderer: Renderer2 = inject(Renderer2);
    private sanitizer: DomSanitizer = inject(DomSanitizer);

    ngOnInit(): void {
        this.checkValidPoint();
    }

    public getScoreEvent(point: OmniSingleDart): string {
        if (!point) {
            return '';
        }

        if (point.singleDart.amount === 0 || point.singleDart.multiplier === null) {
            return $localize`:@@MISS:Miss`;
        }

        if (point.singleDart.amount === 25) {
            if (point.singleDart.multiplier === 1) {
                return 'S-BULL';
            }
            return 'BULL';
        } else {
            if (point.singleDart.multiplier === 1) {
                return 'S' + point.singleDart.amount;
            } else if (point.singleDart.multiplier === 2) {
                return 'D' + point.singleDart.amount;
            } else if (point.singleDart.multiplier === 3) {
                return 'T' + point.singleDart.amount;
            }
        }
    }

    public editZoomedPoint(): void {
        //Disable for now
        return;

        if (this.omniThrowResponseState !== 'THROW') {
            return;
        }

        this.modalController
            .create({
                component: EditOmniScoreDialogComponent,
                componentProps: {
                    dartIndex: this.dartIndex(),
                    point: this.point(),
                    svgContent: this.sanitizer.bypassSecurityTrustHtml(this.svgContent),
                } as EditOmniScorePayload,
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                showBackdrop: true,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                    }
                });
            });
    }

    private createZoomedView(): void {
        if (!this.point()) {
            return;
        }

        // If the SVG is already cached, use the cached version
        if (ZoomedPointComponent.cachedSvg) {
            this.renderZoomedSvg(ZoomedPointComponent.cachedSvg.cloneNode(true) as SVGElement);
        } else {
            // Fetch the SVG from the server if not cached
            this._http.get(this.dartboardSvgUrl, { responseType: 'text' }).subscribe((dartboardSvg) => {
                const tempDiv = document.createElement('div');
                tempDiv.innerHTML = dartboardSvg.trim();
                const svgElement = tempDiv.querySelector('svg');

                if (svgElement) {
                    ZoomedPointComponent.cachedSvg = svgElement.cloneNode(true) as SVGElement; // Cache the SVG
                    this.renderZoomedSvg(svgElement);
                }
            });
        }
    }

    private renderZoomedSvg(svgElement: SVGElement): void {
        // Retry logic to ensure the container element is available before manipulating the DOM
        if (!this.elementRef.nativeElement.querySelector('.zoomed-container') && this.retryCount < this.maxRetries) {
            this.retryCount++;
            setTimeout(() => this.renderZoomedSvg(svgElement), 50);
            return;
        } else if (this.retryCount >= this.maxRetries) {
            console.error('Failed to initialize zoomed view after multiple attempts.');
            return;
        }
        this.retryCount = 0;

        // Remove existing transforms from the SVG
        // const groups = svgElement.querySelectorAll('g');
        // groups.forEach((group) => {
        //     group.removeAttribute('transform');
        // });

        const dartboardSize = 453; // Set this to the actual width of your SVG
        // Translate from center-origin to top-left origin
        const translatedX = this.point().coordinates.x + dartboardSize / 2;
        const translatedY = this.point().coordinates.y + dartboardSize / 2;

        const zoomFactor = 453 / this.zoomLevel(); // Adjust zoom level as before

        // Calculate the viewBox to center around the translated point
        const viewBoxX = this.clamp(translatedX - zoomFactor / 2, 0, dartboardSize - zoomFactor);
        const viewBoxY = this.clamp(translatedY - zoomFactor / 2, 0, dartboardSize - zoomFactor);

        // Set the viewBox with the translated coordinates
        svgElement.setAttribute('viewBox', `${viewBoxX} ${viewBoxY} ${zoomFactor} ${zoomFactor}`);
        svgElement.setAttribute('preserveAspectRatio', 'xMidYMid meet');

        // Ensure the blue dot stays in the same place relative to the viewBox
        const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
        // Position the circle based on the translated coordinates
        circle.setAttribute('cx', `${translatedX}`);
        circle.setAttribute('cy', `${translatedY}`);
        circle.setAttribute('r', '5'); // Set radius to 5
        circle.setAttribute('style', 'fill: #4c8dff; stroke: #ffffff; stroke-width: 1;');

        svgElement.appendChild(circle);

        // Clear the existing content and append the modified SVG
        const container = this.elementRef.nativeElement.querySelector('.zoomed-container');
        container.innerHTML = ''; // Clear existing content
        container.appendChild(svgElement);

        // Apply a class to the SVG
        this.renderer.addClass(svgElement, 'zoomed-svg');
        this.renderer.addClass(svgElement, 'animate__animated');
        this.renderer.addClass(svgElement, 'animate__fadeIn');
        this.svgContent = svgElement.outerHTML;
    }

    private clamp(value: number, min: number, max: number): number {
        return Math.max(min, Math.min(max, value));
    }

    private checkValidPoint() {
        if (!this.point()) {
            return;
        }

        if (this.point().singleDart.isBounceout) {
            this.omniThrowResponseState = OmniThrowResponseState.BOUNCEOUT;
        } else if (
            this.point().coordinates.x > 250 ||
            this.point().coordinates.x < -250 ||
            this.point().coordinates.y > 250 ||
            this.point().coordinates.y < -250 ||
            (!this.point().coordinates.x && !this.point().singleDart.amount)
        ) {
            this.omniThrowResponseState = OmniThrowResponseState.MISS;
        } else {
            this.omniThrowResponseState = OmniThrowResponseState.THROW;
            this.createZoomedView();
        }
    }
}
