@if (options.topBarKeys && options.topBarKeys.length) {
    <ion-row class="ion-numeric-keyboard-top-bar">
        <ion-col class="key top-bar-key {{ topBarKey.keyClass }}" *ngFor="let topBarKey of options.topBarKeys">
            <ion-button color="light" fill="clear" (click)="onClick(topBarKey.keyContent, topBarKey.keySource)">{{
                topBarKey.keyContent
            }}</ion-button>
        </ion-col>
    </ion-row>
}

<div class="grid grid-cols-3 text-black divide-x-1 divide-white border-b-1 border-white">
    <div>
        @if (!editMode) {
            <ion-button
                class="bg-neutral-50 dark:bg-neutral-700 text-black !h-12"
                expand="full"
                fill="clear"
                (click)="onClick(hotkeys[0], 'full')"
                >{{ hotkeys[0] }}</ion-button
            >
        } @else {
            <input
                type="number"
                (click)="selected = 11"
                [class]="selected === 11 ? 'text-white bg-neutral-700' : 'bg-neutral-100 dark:bg-neutral-700'"
                class="h-12 text-md text-center w-full"
                required
                (ionChange)="check(0)"
                [(ngModel)]="hotkeys[0]" />
        }
    </div>
    <div>
        @if (!editMode) {
            <ion-button
                class="bg-neutral-50 dark:bg-neutral-700 text-black !h-12"
                expand="full"
                fill="clear"
                (click)="onClick(hotkeys[1], 'full')"
                >{{ hotkeys[1] }}</ion-button
            >
        } @else {
            <input
                type="number"
                (click)="selected = 1"
                [class]="selected === 1 ? 'text-white bg-neutral-700' : 'bg-neutral-100 dark:bg-neutral-700'"
                class="h-12 text-md text-center w-full"
                required
                (ionChange)="check(1)"
                [(ngModel)]="hotkeys[1]" />
        }
    </div>
    <div>
        @if (!editMode) {
            <ion-button
                class="bg-neutral-50 dark:bg-neutral-700 text-black !h-12"
                expand="full"
                fill="clear"
                (click)="onClick(hotkeys[2], 'full')"
                >{{ hotkeys[2] }}</ion-button
            >
        } @else {
            <input
                type="number"
                (click)="selected = 2"
                [class]="selected === 2 ? 'text-white bg-neutral-700' : 'bg-neutral-100 dark:bg-neutral-700'"
                class="h-12 text-md text-center w-full"
                required
                (ionChange)="check(2)"
                [(ngModel)]="hotkeys[2]" />
        }
    </div>
</div>
<div class="grid grid-cols-3 text-black divide-x-1 divide-white border-b-1 border-white">
    <div>
        @if (!editMode) {
            <ion-button
                class="bg-neutral-50 dark:bg-neutral-700 text-black !h-12"
                expand="full"
                fill="clear"
                (click)="onClick(hotkeys[3], 'full')"
                >{{ hotkeys[3] }}</ion-button
            >
        } @else {
            <input
                type="number"
                (click)="selected = 3"
                [class]="selected === 3 ? 'text-white bg-neutral-700' : 'bg-neutral-100 dark:bg-neutral-700'"
                class="h-12 text-md text-center w-full"
                required
                (ionChange)="check(3)"
                [(ngModel)]="hotkeys[3]" />
        }
    </div>
    <div>
        @if (!editMode) {
            <ion-button
                class="bg-neutral-50 dark:bg-neutral-700 text-black !h-12"
                expand="full"
                fill="clear"
                (click)="onClick(hotkeys[4], 'full')"
                >{{ hotkeys[4] }}</ion-button
            >
        } @else {
            <input
                type="number"
                (click)="selected = 4"
                [class]="selected === 4 ? 'text-white bg-neutral-700' : 'bg-neutral-100 dark:bg-neutral-700'"
                class="h-12 text-md text-center w-full"
                required
                (ionChange)="check(4)"
                [(ngModel)]="hotkeys[4]" />
        }
    </div>
    <div>
        @if (!editMode) {
            <ion-button
                class="bg-neutral-50 dark:bg-neutral-700 text-black !h-12"
                expand="full"
                fill="clear"
                (click)="onClick(hotkeys[5], 'full')"
                >{{ hotkeys[5] }}</ion-button
            >
        } @else {
            <input
                type="number"
                (click)="selected = 5"
                [class]="selected === 5 ? 'text-white bg-neutral-700' : 'bg-neutral-100 dark:bg-neutral-700'"
                class="h-12 text-md text-center w-full"
                required
                (ionChange)="check(5)"
                [(ngModel)]="hotkeys[5]" />
        }
    </div>
</div>
<div class="grid grid-cols-3 text-black divide-x-1 divide-white border-b-1 border-white">
    <div>
        @if (!editMode) {
            <ion-button
                class="bg-neutral-50 dark:bg-neutral-700 text-black !h-12"
                expand="full"
                fill="clear"
                (click)="onClick(hotkeys[6], 'full')"
                >{{ hotkeys[6] }}</ion-button
            >
        } @else {
            <input
                type="number"
                (click)="selected = 6"
                [class]="selected === 6 ? 'text-white bg-neutral-700' : 'bg-neutral-100 dark:bg-neutral-700'"
                class="h-12 text-md text-center w-full"
                required
                (ionChange)="check(6)"
                [(ngModel)]="hotkeys[6]" />
        }
    </div>
    <div>
        @if (!editMode) {
            <ion-button
                class="bg-neutral-50 dark:bg-neutral-700 text-black !h-12"
                expand="full"
                fill="clear"
                (click)="onClick(hotkeys[7], 'full')"
                >{{ hotkeys[7] }}</ion-button
            >
        } @else {
            <input
                type="number"
                (click)="selected = 7"
                [class]="selected === 7 ? 'text-white bg-neutral-700' : 'bg-neutral-100 dark:bg-neutral-700'"
                class="h-12 text-md text-center w-full"
                required
                (ionChange)="check(7)"
                [(ngModel)]="hotkeys[7]" />
        }
    </div>
    <div>
        @if (!editMode) {
            <ion-button
                class="bg-neutral-50 dark:bg-neutral-700 text-black !h-12"
                expand="full"
                fill="clear"
                (click)="onClick(hotkeys[8], 'full')"
                >{{ hotkeys[8] }}</ion-button
            >
        } @else {
            <input
                type="number"
                (click)="selected = 8"
                [class]="selected === 8 ? 'text-white bg-neutral-700' : 'bg-neutral-100 dark:bg-neutral-700'"
                class="h-12 text-md text-center w-full"
                required
                (ionChange)="check(8)"
                [(ngModel)]="hotkeys[8]" />
        }
    </div>
</div>

<div class="grid grid-cols-3 text-black divide-x-1 divide-white">
    <div class="h-12 text-black items-center justify-center flex">
        @if (myTurn) {
            <app-icon icon="timer"></app-icon>
            <span class="timerValue">{{ timer }}</span>
        }
    </div>
    <div>
        @if (!editMode) {
            <ion-button
                class="bg-neutral-50 dark:bg-neutral-700 text-black !h-12"
                expand="full"
                fill="clear"
                (click)="onClick(hotkeys[9], 'full')"
                >{{ hotkeys[9] }}</ion-button
            >
        } @else {
            <input
                type="number"
                (click)="selected = 9"
                [class]="selected === 9 ? 'text-white bg-neutral-700' : 'bg-neutral-100 dark:bg-neutral-700'"
                class="h-12 text-md text-center w-full"
                required
                (ionChange)="check(9)"
                [(ngModel)]="hotkeys[9]" />
        }
    </div>
    <div>
        @if (!editMode) {
            <ion-button class="!text-black !h-12" expand="full" fill="clear" (click)="toggleEditmode()">
                <app-icon icon="edit"></app-icon
            ></ion-button>
        } @else {
            <ion-button class="text-black !h-12" expand="full" fill="clear" (click)="toggleEditmode()"
                ><ion-icon name="save-outline"></ion-icon
            ></ion-button>
        }
    </div>
</div>
