/* eslint-disable max-len */
import { DestroyRef, inject, Injectable, NgZone } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Camera } from '@capacitor/camera';
import { SmartDevice } from '@dc-core/dc-backend/dc-classes';
import { LobbySettingsCollectionService } from '@dc-core/dc-firestore/collection-helpers/lobby_settings.collection.service';
import { AlertPayload, DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { CAMERA_TYPE, JanusRoom } from '@dc-core/dc-services/camera/camera.models';
import { InGameCameraService } from '@dc-core/dc-services/camera/ingame-camera.service';
import { JanusVideoRoomService } from '@dc-core/dc-services/dc-janus/janus-video-room.service';
import { UserMediaService } from '@dc-core/dc-services/dc-janus/usermedia.service';
import { OnlineGamesService } from '@dc-core/dc-services/online-games.service';
import { ModalController, Platform } from '@ionic/angular';
import { take } from 'rxjs';
import { UserService } from 'src/app/modules/user/services/user.service';
import { ActivateCameraDialogComponent } from 'src/dialogs/activate-camera/activate-camera.dialog';
import { PromptDialogComponent } from 'src/dialogs/prompt/prompt.dialog';
import { environment } from 'src/environments/environment';
import { SmartDeviceStatus } from 'src/pages/online/online-games/online-games.component';

import { AuthService } from './auth.service';
import { SmartDeviceService } from './smart-device.service';

@Injectable()
export class LocalCameraService {
    private userService: UserService = inject(UserService);
    private modalController: ModalController = inject(ModalController);
    private platform: Platform = inject(Platform);
    private authService: AuthService = inject(AuthService);
    private ngZone: NgZone = inject(NgZone);
    private alertService: DartCounterAlertService = inject(DartCounterAlertService);
    private videoRoomService: JanusVideoRoomService = inject(JanusVideoRoomService);
    private lobbySettingsService: LobbySettingsCollectionService = inject(LobbySettingsCollectionService);
    private online: OnlineGamesService = inject(OnlineGamesService);
    private smartDeviceService: SmartDeviceService = inject(SmartDeviceService);
    private userMediaService: UserMediaService = inject(UserMediaService);
    private ingameCameraService: InGameCameraService = inject(InGameCameraService);

    private isLinkingCamera = false;

    private destroyRef: DestroyRef = inject(DestroyRef);

    async linkCamera(refreshGame: boolean, cameraType: CAMERA_TYPE = null, preSelectedSmartDevice: SmartDevice = null) {
        try {
            if (this.platform.is('capacitor')) {
                const camResult = await Camera.requestPermissions({ permissions: ['camera'] });
                if (camResult.camera === 'denied') {
                    throw 'No permission';
                }
            }
        } catch (_) {
            this.alertService.createAlert({
                title: $localize`:@@ENABLE_CAM_APP_PERMS:Please enable camera permissions in your device settings.`,
                icon: 'error',
                timer: 2000,
                timerProgressBar: true,
                showCloseButton: true,
            });
            return;
        }

        if (this.isLinkingCamera) {
            return;
        }

        this.isLinkingCamera = true;

        this.userService
            .canUseSocialFeatures(true)
            .pipe(takeUntilDestroyed(this.destroyRef), take(1))
            .subscribe((valid: boolean) => {
                if (valid) {
                    this.modalController
                        .create({
                            component: ActivateCameraDialogComponent,
                            componentProps: {
                                cameraType,
                                preSelectedSmartDevice,
                            },
                            backdropDismiss: false,
                            showBackdrop: true,
                            cssClass: !environment.isWeb ? 'ion-fullscreen-modal' : '',
                        })
                        .then((elem) => {
                            elem.present();
                            elem.onDidDismiss().then((dialogRes) => {
                                this.isLinkingCamera = false;

                                const camera: JanusRoom = dialogRes.data;
                                if (camera) {
                                    this.alertService.createAlert({
                                        title: $localize`:@@CAMERA_IS_ACTIVATED:Your camera is activated for online games!`,
                                        icon: 'success',
                                        timer: 'default',
                                    });

                                    if (camera.camType === CAMERA_TYPE.SMART_DEVICE) {
                                        this.smartDeviceService.virtStatus.set(SmartDeviceStatus.ACTIVATED);
                                    }

                                    if (refreshGame && this.lobbySettingsService.currentGameInLobby) {
                                        this.online.updateLobbyGame();
                                    }
                                } else {
                                    this.videoRoomService.stopOwnStream(true);
                                }
                            });
                        });
                } else {
                    this.isLinkingCamera = false;
                }
            });
    }

    unlinkCamera(refreshGame: boolean) {
        this.modalController
            .create({
                component: PromptDialogComponent,
                componentProps: {
                    title: $localize`:@@DISABLE_CAMERA:Disable camera`,
                    text: $localize`:@@DISABLE_CAMERA_QUESTION:Do you want to disable your camera stream?`,
                    cancelText: $localize`:@@CANCEL:Cancel`,
                    confirmText: $localize`:@@CONFIRM:Confirm`,
                    confirmColor: 'orange',
                } as AlertPayload,
                cssClass: 'auto-height',
                showBackdrop: true,
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        if (
                            !environment.isWeb &&
                            this.videoRoomService.usingExternal &&
                            this.videoRoomService.ownCamera.camType === CAMERA_TYPE.SMART_DEVICE &&
                            this.videoRoomService.smartDevice
                        ) {
                            this.smartDeviceService
                                .stopStreaming(this.videoRoomService.smartDevice, false, false)
                                .catch(console.error);

                            this.smartDeviceService.virtStatus.set(SmartDeviceStatus.OWNED);
                        }

                        this.videoRoomService.stopOwnStream(true).finally(() => {
                            if (refreshGame && this.lobbySettingsService.currentGameInLobby) {
                                this.online.updateLobbyGame();
                            }
                        });
                    }
                });
            });
    }

    checkPublishingVideo(actionReplay = false): void {
        if (
            this.platform.is('capacitor') &&
            this.platform.is('ios') &&
            this.videoRoomService.ownCamera.roomID &&
            this.videoRoomService.ownUserMedia
        ) {
            this.ngZone.run(() => {
                if (this.videoRoomService.isPublishingVideo && this.videoRoomService.videoMedia) {
                    this.videoRoomService.ownUserMedia.cleanupUserMedia(
                        { video: true, audio: false },
                        { video: true, audio: false },
                        actionReplay
                    );
                    this.userMediaService.getUserMedia(this.videoRoomService.videoMedia).then((stream) => {
                        this.userMediaService.setStreamAsOwnUserMedia(this.videoRoomService.ownUserMedia, stream, true);
                        this.videoRoomService.attachToOwnCameraRoomAndPublish();

                        if (actionReplay) {
                            setTimeout(() => {
                                this.ingameCameraService.checkActionReplayAndStartRecording(
                                    this.authService.user.id,
                                    true
                                );
                            }, 1000);
                        }
                    });
                }
            });
        }
    }
}
