<modal-content class="bg-white-pattern text-black">
    <app-page-header mainNavigation="custom" color="white"> </app-page-header>

    <div *ngIf="possibleDartsAtDouble?.length" class="overflow-y-auto">
        <h2 [translate]="'DARTS_ON_DOUBLE'" i18n="@@DARTS_ON_DOUBLE">Darts used on a double</h2>
        <ion-row class="dartrow">
            <ion-col
                class="dart text-center"
                [ngClass]="{ selected: selectedDartAtDouble === dart, focussed: isWeb && !doubleEntered }"
                (click)="setSelectedDartAtDouble(dart)"
                *ngFor="let dart of possibleDartsAtDouble">
                {{ dart }}
            </ion-col>
        </ion-row>
    </div>

    <div *ngIf="possibleDarts?.length">
        <h2 [translate]="'DARTS_FOR_FINISH'" i18n="@@DARTS_FOR_FINISH">Darts used for checkout</h2>
        <ion-row class="dartrow">
            <ion-col
                class="dart text-center"
                [ngClass]="{ selected: selectedDart === dart, focussed: isWeb && doubleEntered }"
                (click)="setSelectedDart(dart)"
                *ngFor="let dart of possibleDarts">
                {{ dart }}
            </ion-col>
        </ion-row>
    </div>
</modal-content>

<ion-footer class="ion-no-border">
    <ion-toolbar class="footer" style="height: 72px">
        <div class="bottomCTA orange" style="position: relative" (click)="ReturnDarts()">
            <p class="text-center" style="height: 72px" [translate]="'CONFIRM'" i18n="@@CONFIRM">Confirm</p>
        </div>
    </ion-toolbar>
</ion-footer>
