<modal-content fullscreen>
    <app-page-header mainNavigation="custom" color="transparent">
        <div title>{{ title | translate }}</div>
        <app-icon icon="close" (click)="DismissModal()"></app-icon>
    </app-page-header>

    <div *ngIf="auth.user.id !== 2703">
        <swiper-container class="dc-swiper" [pagination]="true">
            <swiper-slide class="space-y-4">
                <h5>1. {{ 'FEATURE_PLAYONLINE' | translate }}</h5>
                <img src="assets/images/features/1-playonline.png" />
            </swiper-slide>
            <swiper-slide class="space-y-4">
                <h5>2. {{ 'FEATURE_CALLER' | translate }}</h5>
                <img src="assets/images/features/2-callname.png" />
            </swiper-slide>
            <swiper-slide class="space-y-4">
                <h5>3. {{ 'FEATURE_ALLTIME' | translate }}</h5>
                <img src="assets/images/features/3-alltime.png" />
            </swiper-slide>
            <swiper-slide class="space-y-4">
                <h5>4. {{ 'FEATURE_MATCHDETAILS' | translate }}</h5>
                <img src="assets/images/features/4-details.png" />
            </swiper-slide>
            <swiper-slide class="space-y-4">
                <h5>5. {{ 'FEATURE_CHARTS' | translate }}</h5>
                <img src="assets/images/features/5-charts.png" />
            </swiper-slide>
        </swiper-container>

        <div style="font-size: 11px; padding: 0 10px; color: var(--white); margin-bottom: 0">
            <p class="text-center">
                <span style="font-weight: semibold">{{ title }}</span>
                <span style="margin-left: 3px" [translate]="'DISCOUNT_FIRST_YEAR'" i18n="@@DISCOUNT_FIRST_YEAR"
                    >so you'll pay €9,99 in the first year!</span
                >
            </p>
        </div>

        <ion-row class="prices">
            <ion-col class="price" (click)="setRecurring('monthly')" [ngClass]="{ selected: recurring === 'monthly' }">
                <h5 class="recurring" [translate]="'MONTHLY'" i18n="@@MONTHLY">Monthly</h5>
                <div class="costs">
                    <span class="amount">{{ pricing.monthly | currency: 'EUR' }}</span>
                    <span class="timeframe" [translate]="'PER_MONTH'" i18n="@@PER_MONTH">per month</span>
                </div>
                <p class="billing" [translate]="'BILLED_MONTHLY'" i18n="@@BILLED_MONTHLY">Billed monthly</p>
            </ion-col>

            <ion-col class="price" (click)="setRecurring('yearly')" [ngClass]="{ selected: recurring === 'yearly' }">
                <div class="sale" *ngIf="auth.user">25% OFF</div>
                <h5 class="recurring highlight" [translate]="'YEARLY'" i18n="@@YEARLY">Yearly</h5>
                <div class="costs">
                    <span class="amount" *ngIf="auth.user"> {{ pricing.discount / 12 | currency: 'EUR' }} </span>
                    <span class="timeframe" [translate]="'PER_MONTH'" i18n="@@PER_MONTH">per month</span>
                </div>
                <p class="billing" [translate]="'BILLED_ANNUALLY'" i18n="@@BILLED_ANNUALLY">Billed annually</p>
            </ion-col>
        </ion-row>

        <div class="link-section">
            <span (click)="OpenAgreementDialog()" [translate]="'BECOME_ULTIMATE'" i18n="@@BECOME_ULTIMATE"
                >Become Ultimate!</span
            >
            <span (click)="OpenPolicyDialog()" [translate]="'PRIVACY_POLICY'" i18n="@@PRIVACY_POLICY"
                >Privacy policy</span
            >
        </div>
    </div>
</modal-content>

<ion-footer class="ion-no-border">
    <ion-toolbar class="footer" style="height: 72px">
        <div class="bottomCTA orange" style="position: relative" (click)="BuyUltimate()">
            <p class="text-center" style="height: 72px" [translate]="'BECOME_ULTIMATE'" i18n="@@BECOME_ULTIMATE">
                Become Ultimate!
            </p>
        </div>
    </ion-toolbar>
</ion-footer>
