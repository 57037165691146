import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LobbySettingsCollectionService } from '@dc-core/dc-firestore/collection-helpers/lobby_settings.collection.service';
import { IntervalManager } from '@dc-core/dc-logging/interval.manager';
import { INTERVAL_KEY } from '@dc-core/dc-logging/subscription_enums';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { CAMERA_TYPE } from '@dc-core/dc-services/camera/camera.models';
import { JanusVideoRoomService } from '@dc-core/dc-services/dc-janus/janus-video-room.service';
import { OnlineGamesService } from '@dc-core/dc-services/online-games.service';
import { IonicModule, ModalController } from '@ionic/angular';
import { SmartDeviceService } from '@services/smart-device.service';
import { PrimaryButtonComponent } from 'src/app/primary-button/primary-button.component';

@Component({
    selector: 'app-virt-cam-inactivity-dialog',
    templateUrl: 'virt-cam-inactivity.dialog.html',
    imports: [CommonModule, IonicModule, FormsModule, ReactiveFormsModule, PrimaryButtonComponent],
    standalone: true,
})
export class VirtCamInactivityDialogComponent implements OnInit {
    @Input() autoStopStreaming: boolean;

    public seconds: number = 30;

    private alertService: DartCounterAlertService = inject(DartCounterAlertService);
    private videoRoomService: JanusVideoRoomService = inject(JanusVideoRoomService);
    private smartDeviceService: SmartDeviceService = inject(SmartDeviceService);
    private lobbySettingsService: LobbySettingsCollectionService = inject(LobbySettingsCollectionService);
    private online: OnlineGamesService = inject(OnlineGamesService);
    private intervalManager: IntervalManager = inject(IntervalManager);
    private modalController: ModalController = inject(ModalController);

    protected destroyRef: DestroyRef = inject(DestroyRef);

    public ngOnInit(): void {
        if (this.autoStopStreaming) {
            this.startTimer();
        }
    }

    public stopStreaming(): void {
        if (
            this.videoRoomService.usingExternal &&
            this.videoRoomService.ownCamera.camType === CAMERA_TYPE.SMART_DEVICE &&
            this.videoRoomService.smartDevice
        ) {
            this.smartDeviceService.stopStreaming(this.videoRoomService.smartDevice, false, false).catch(console.error);
        }

        this.videoRoomService.stopOwnStream(true).finally(() => {
            if (this.lobbySettingsService.currentGameInLobby) {
                this.online.updateLobbyGame();
            }
        });

        this.alertService.createAlert({
            title: $localize`:@@THE_VIRT_STOPPED_STREAMING:The Target VirtCam stopped streaming`,
            icon: 'success',
        });

        this.close();
    }

    public close(returnValue: any = null): void {
        this.modalController.dismiss(returnValue);
    }

    private startTimer(): void {
        this.intervalManager.clear(INTERVAL_KEY.WAITFORSUBMIT_TIMER);

        const waitingTimerInterval = setInterval(() => {
            if (this.seconds >= 1) {
                this.seconds--;
            } else {
                this.stopStreaming();
            }
        }, 1000);

        this.intervalManager.track('waitingDialog', INTERVAL_KEY.WAITFORSUBMIT_TIMER, waitingTimerInterval);
    }

    ionViewWillLeave() {
        this.intervalManager.clear(INTERVAL_KEY.WAITFORSUBMIT_TIMER);
    }
}
