@if (smallLayout()) {
    <div
        class="flex flex-row items-center gap-1 font-bebas-neue px-1.5 pt-1.5 pb-1 rounded-sm leading-none text-black text-[1.3rem]">
        @if (currentScore() && !omniActive()) {
            <div class="totalScore leading-none text-black px-2 py-1 bg-orange font-bebas-neue">
                +{{ currentScore() }}
            </div>
        }
        {{ team().currentTotalScore + (currentScore() || 0) }}
    </div>
} @else {
    <div class="py-2.5">
        <div class="flex flex-col items-center justify-center">
            <div
                class="totalScore font-bebas-neue font-normal leading-none text-black relative text-[4rem] sm:text-7xl">
                {{ team().currentTotalScore + (currentScore() || 0) }}
            </div>
        </div>
        @if (currentScore() && !omniActive()) {
            <div
                class="flex-col items-center bg-orange px-1 py-1 font-bebas-neue absolute top-1/2 -translate-y-1/2 left-1">
                <div class="text-lg leading-none text-black"><span class="text-lg">+</span>{{ currentScore() }}</div>
            </div>
        }
    </div>
}
