<!-- Translation containers-->
@if (false) {
    <ng-container i18n="@@LOADING">Loading</ng-container>
}
<!-- End of translation containers-->

<app-page-header mainNavigation="back" defaultBack="statistics">
    <div title [translate]="'SHANGHAI'" i18n="@@SHANGHAI">Shanghai</div>
</app-page-header>

<ion-content class="bg-white-pattern">
    <div class="m-4 flex text-sm">
        <div class="mr-1 w-full">
            <div class="mb-1 text-center text-sm uppercase text-black" [translate]="'START_DATE'" i18n="@@START_DATE">
                Start date
            </div>
            <div class="rounded-lg bg-white p-2 text-black cursor-pointer" (click)="changeStartDate()">
                {{ startDate | date: 'd MMM yyyy' : '' : translateService.currentLang }}
            </div>
        </div>
        <div class="ml-1 w-full">
            <div class="mb-1 text-center text-sm uppercase text-black" [translate]="'END_DATE'" i18n="@@END_DATE">
                End date
            </div>
            <div class="rounded-lg bg-white p-2 text-black cursor-pointer" (click)="changeEndDate()">
                {{ endDate | date: 'd MMM yyyy' : '' : translateService.currentLang }}
            </div>
        </div>
    </div>

    <ion-segment mode="md" *ngIf="itemsUnverified?.length" [(ngModel)]="segment">
        <ion-segment-button value="verified" [translate]="'GAMES'" i18n="@@GAMES">GAMES</ion-segment-button>
        <ion-segment-button value="unverified">
            {{ 'UNVERIFIED_MATCHES' | translate }}
            <ion-badge mode="md" class="ion-bg-yellow ion-text-black">{{ itemsUnverified?.length }}</ion-badge>
        </ion-segment-button>
    </ion-segment>

    <div [ngSwitch]="segment">
        <div *ngSwitchCase="'verified'">
            <ion-row style="margin-top: 20px" *ngIf="items === null">
                <ion-col class="text-center">
                    <ion-spinner style="height: 40px; width: 40px" color="light" name="crescent"></ion-spinner>
                </ion-col>
            </ion-row>

            <ion-row style="margin-top: 20px" *ngIf="items && items.length === 0">
                <ion-col
                    class="text-center font-semibold text-white"
                    [translate]="'NO_GAMES_PLAYED'"
                    i18n="@@NO_GAMES_PLAYED">
                    No games played
                </ion-col>
            </ion-row>

            <div class="m-4">
                <div *ngFor="let item of items">
                    <app-shanghai-card [game]="item" (click)="ShowDetail(item)"></app-shanghai-card>
                </div>
            </div>

            <div class="m-3">
                <app-primary-button
                    *ngIf="!auth.user.is_ultimate && total > 0"
                    size="normal"
                    addedClasses="w-full mt-3"
                    color="blue"
                    (click)="OpenUltimateDialog()"
                    [translate]="'WANT_TO_VIEW_ALL_GAMES'"
                    i18n="@@WANT_TO_VIEW_ALL_GAMES">
                    Want to view all games? Become Ultimate!
                </app-primary-button>
            </div>

            <ion-infinite-scroll
                (ionInfinite)="GetItems($event)"
                *ngIf="items !== null && total !== null && items.length < total">
                <ion-row style="margin-top: 20px">
                    <ion-col class="text-center">
                        <ion-spinner style="height: 40px; width: 40px" color="light" name="crescent"></ion-spinner>
                    </ion-col>
                </ion-row>
            </ion-infinite-scroll>
        </div>

        <div *ngSwitchCase="'unverified'">
            <ion-row>
                <ion-col class="text-center" style="color: white">
                    <p [translate]="'UNVERIFIED_TEXT'" i18n="@@UNVERIFIED_TEXT">
                        Your account has been used for local games. Tap 'Save' if you have played the game. Tap 'Remove'
                        if you haven't played the game.
                    </p>
                </ion-col>
            </ion-row>

            <ion-row>
                <ion-col style="padding-top: 0px">
                    <ion-button
                        class="extra-btn"
                        color="light"
                        expand="block"
                        fill="outline"
                        (click)="TrySaveAll()"
                        [translate]="'SAVE_ALL'"
                        i18n="@@SAVE_ALL"
                        >Save all</ion-button
                    >
                </ion-col>
                <ion-col style="padding-top: 0px">
                    <ion-button
                        class="extra-btn"
                        color="light"
                        expand="block"
                        fill="outline"
                        (click)="TryRemoveAll()"
                        [translate]="'REMOVE_ALL'"
                        i18n="@@REMOVE_ALL"
                        >Remove all</ion-button
                    >
                </ion-col>
            </ion-row>

            <div class="m-3">
                <app-shanghai-card
                    *ngFor="let item of itemsUnverified; let i = index"
                    [game]="item"
                    [showArrow]="false">
                    <div class="flex items-center justify-end space-x-1 py-1">
                        <app-primary-button
                            color="orange"
                            size="extrasmall"
                            (click)="TrySave(item, i)"
                            [translate]="'SAVE'"
                            i18n="@@SAVE">
                            Save
                        </app-primary-button>
                        <app-primary-button
                            color="red"
                            size="extrasmall"
                            (click)="TryRemove(item, i)"
                            [translate]="'Remove'"
                            i18n="@@Remove">
                            Remove
                        </app-primary-button>
                        <app-primary-button
                            color="blue"
                            size="extrasmall"
                            (click)="ShowDetail(item)"
                            [translate]="'MORE_INFO'"
                            i18n="@@MORE_INFO">
                            More
                        </app-primary-button>
                    </div>
                </app-shanghai-card>
            </div>

            <ion-infinite-scroll
                (ionInfinite)="GetUnverifiedItems($event)"
                *ngIf="
                    itemsUnverified !== null && totalUnverified !== null && itemsUnverified.length < totalUnverified
                ">
                <ion-row style="margin-top: 20px">
                    <ion-col class="text-center">
                        <ion-spinner style="height: 40px; width: 40px" color="light" name="crescent"></ion-spinner>
                    </ion-col>
                </ion-row>
            </ion-infinite-scroll>
        </div>
    </div>
</ion-content>
