<app-dialog-view [showCloseButton]="true" (onDismiss)="DismissModal()">
    <div title>
        <span i18n="@@SHARE_MATCH" [translate]="'SHARE_MATCH'">Share match </span>
    </div>
    <div sub-title class="flex flex-wrap gap-1">
        <span i18n="@@CHOOSE_IMAGE_TO_SHARE" [translate]="'CHOOSE_IMAGE_TO_SHARE'">Choose your image to share </span>
    </div>
    <div class="flex gap-2 items-start">
        <div (click)="share('square')" class="flex flex-col w-1/2 justify-center text-center">
            <div [translate]="'SQUARE'" i18n="@@SQUARE" class="mb-2 text-md text-black">Square</div>
            <div>
                @if (squareSharable) {
                    <img class="cursor-pointer" [src]="squareSharable" />
                } @else {
                    <ion-spinner style="height: 20px; width: 20px" color="secondary" name="crescent"> </ion-spinner>
                }
            </div>
        </div>
        <div (click)="share('portrait')" class="flex flex-col w-1/2 justify-center text-center">
            <div [translate]="'PORTRAIT'" i18n="@@PORTRAIT" class="mb-2 text-md text-black">Portrait</div>
            <div>
                @if (squareSharable) {
                    <img class="cursor-pointer" [src]="portraitSharable" />
                } @else {
                    <ion-spinner style="height: 20px; width: 20px" color="secondary" name="crescent"> </ion-spinner>
                }
            </div>
        </div>
    </div>
</app-dialog-view>
