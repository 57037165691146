import { Component, NgZone } from '@angular/core';
import { ThemePreferenceModalComponent } from '@components/theme-preference/theme-preference-modal.component';
import { AuthApiService } from '@dc-api/auth.api.service';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { ModalOptions } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { DartCounterAlertService } from 'dc-core/dc-services/alert.service';
import { DartCounterPreferenceService } from 'dc-core/dc-services/preference/preference.service';
import OneSignal from 'onesignal-cordova-plugin';
import { DC_LANGUAGES, GA_EVENTACTIONS, GA_EVENTCATEGORIES } from 'src/app/app.globals';
import { AgreementDialogComponent } from 'src/dialogs/agreement/agreement';
import { PolicyDialogComponent } from 'src/dialogs/policy/policy';
import { PreferencesDialogComponent } from 'src/dialogs/preferences/preferences';
import { ResetAccountDialogComponent } from 'src/dialogs/reset-account/reset-account.dialog';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/services/auth.service';

/**
 * Generated class for the Settings page.
 *
 * See http://ionicframework.com/docs/components/#navigation for more info
 * on Ionic pages and navigation.
 */

@Component({
    selector: 'app-page-app-settings',
    templateUrl: 'app-settings.html',
    styleUrls: ['app-settings.scss'],
})
export class AppSettingsViewComponent {
    public allowCaller: boolean;
    public languages = [];

    constructor(
        public nav: NavController,
        public auth: AuthService,
        public preferenceService: DartCounterPreferenceService,
        private ga: DartCounterAnalyticsService,
        private platform: Platform,
        public translate: TranslateService,
        private _alertService: DartCounterAlertService,
        private authApiService: AuthApiService,
        public modal: ModalController,
        private modal2: ModalController,
        private _ngZone: NgZone
    ) {
        this.languages = DC_LANGUAGES;
    }

    showLanguages() {
        this.modal2
            .create({
                component: PreferencesDialogComponent,
                componentProps: {
                    firsttime: false,
                },
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
            })
            .then((elem) => elem.present());
    }

    showGlobalGameSettings() {
        this.nav.navigateForward('game-settings');
    }

    showAccount() {
        this.nav.navigateForward('account-settings');
    }

    blockedPlayers() {
        this.nav.navigateForward('blocked-players');
    }

    subscriptions() {
        this.nav.navigateForward('subscriptions');
    }

    ShowAccountReset() {
        this.modal2
            .create({
                component: ResetAccountDialogComponent,
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss();
            });
    }

    OpenAgreementDialog() {
        this.ga.trackEvent(GA_EVENTCATEGORIES.ULTIMATE, GA_EVENTACTIONS.TERMSOFUSE);
        const modal = this.modal2.create({
            component: AgreementDialogComponent,
        } as ModalOptions);
        modal.then((elem) => elem.present());
    }

    OpenPolicyDialog() {
        this.ga.trackEvent(GA_EVENTCATEGORIES.ULTIMATE, GA_EVENTACTIONS.PRIVACYPOLICY);
        const modal = this.modal2.create({
            component: PolicyDialogComponent,
        } as ModalOptions);
        modal.then((elem) => elem.present());
    }

    Logout() {
        this.ga.trackEvent(GA_EVENTCATEGORIES.MENU, GA_EVENTACTIONS.LOGOUT);

        if (this.platform.is('capacitor') && !this.platform.is('desktop')) {
            try {
                // When the user logs out from a device, we want to remove this token from the database
                // We no longer need to send a push notification to this device for this user.
                OneSignal.getDeviceState((response) => {
                    if (response?.userId && response?.pushToken) {
                        this.authApiService
                            .unlinkPushToken({ one_signal_id: response.userId, token: response.pushToken })
                            .then(() => {
                                this.logoutAndRedirect();
                            })
                            .catch((err) => {
                                this._alertService.errorFromApi(err);
                            });
                    } else {
                        this.logoutAndRedirect();
                    }
                });
            } catch (e) {
                this.logoutAndRedirect();
            }
        } else {
            this.logoutAndRedirect();
        }
    }

    logoutAndRedirect(): void {
        this.auth.signOut();
        setTimeout(() => {
            this._ngZone.run(() => {
                this.nav.navigateRoot('/login');
            });
        }, 200);
    }

    changeTheme(): void {
        this.modal
            .create({
                component: ThemePreferenceModalComponent,
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
            })
            .then((elem) => {
                elem.present();
            });
    }
}
