<app-page-header mainNavigation="back" defaultBack="smart-devices">
    <div title>VIRT: {{ smartDevice?.name }}</div>
    <app-option-button
        right
        icon="power_settings_new"
        (click)="showShutdownPopover()"
        color="text-white"></app-option-button>
</app-page-header>

<div class="flex h-full flex-col bg-white-pattern text-black">
    <!-- Camera -->
    <div class="flex flex-1 flex-col px-4 py-5 overflow-y-auto" *ngIf="view === 'camera'">
        @if (smartDeviceService.userMedia?.videoStreams.hasStreams === false && !smartDeviceService.startStreamError) {
            <app-icon icon="loading" size="extralarge" class="block mt-8 mx-auto animate-spin text-orange"></app-icon>
        }

        <div
            class="mt-6 text-center text-lg font-semibold text-neutral-400"
            *ngIf="
                smartDeviceService.userMedia?.videoStreams.hasStreams === false &&
                smartDeviceService.startStreamStatus &&
                !smartDeviceService.startStreamError
            ">
            <span
                *ngIf="smartDeviceService.startStreamStatus === 'server'"
                [translate]="'WAITING_FOR_SERVER_DOTS'"
                i18n="@@WAITING_FOR_SERVER_DOTS">
                Waiting for server...
            </span>
            <span
                *ngIf="smartDeviceService.startStreamStatus === 'client'"
                [translate]="'STARTING_CAMERA_STREAMS_DOTS'"
                i18n="@@STARTING_CAMERA_STREAMS_DOTS">
                Starting camera streams...
            </span>
            <span
                *ngIf="smartDeviceService.startStreamStatus === 'restarting'"
                [translate]="'RESTARTING_CAMERA_STREAMS_DOTS'"
                i18n="@@RESTARTING_CAMERA_STREAMS_DOTS">
                Restarting camera streams...
            </span>
        </div>
        <div
            class="mt-6 text-center text-lg font-semibold text-neutral-400"
            *ngIf="
                smartDeviceService.userMedia?.videoStreams.hasStreams === false && smartDeviceService.startStreamError
            ">
            <span
                *ngIf="smartDeviceService.startStreamError === 'server'"
                [translate]="'SERVER_CONNECTION_FAILED'"
                i18n="@@SERVER_CONNECTION_FAILED">
                Server connection failed
            </span>
            <span
                *ngIf="smartDeviceService.startStreamError === 'client'"
                [translate]="'STARTING_CAMERA_STREAMS_FAILED'"
                i18n="@@STARTING_CAMERA_STREAMS_FAILED">
                Starting camera streams failed
            </span>
            <span
                *ngIf="smartDeviceService.startStreamError === 'restarting'"
                [translate]="'RESTARTING_CAMERA_STREAMS_FAILED_RESTART_MANUALLY'"
                i18n="@@RESTARTING_CAMERA_STREAMS_FAILED_RESTART_MANUALLY">
                Restarting camera streams failed, please restart manually
            </span>
        </div>

        <div
            class="flex gap-4 portrait:flex-col portrait:sm:flex-row landscape:flex-row"
            *ngIf="smartDeviceService.userMedia?.videoStreams.hasStreams === true">
            <div
                class="relative overflow-hidden landscape:basis-1/2"
                *ngIf="smartDeviceService.userMedia.videoStreams.board">
                <video
                    *ngIf="smartDeviceService.userMedia.videoStreams.board.stream"
                    class="ingame-camera w-full"
                    [srcObject]="smartDeviceService.userMedia.videoStreams.board.stream"
                    [style.transform]="smartDeviceService.userMedia.videoStreams.board.scale"
                    autoplay
                    playsinline
                    webkit-playsinline
                    muted></video>
                <div
                    class="absolute left-4 top-4 h-7 w-7 rounded-md bg-orange p-1 inline-flex justify-center items-center">
                    <app-icon icon="videocam" size="medium" class="!text-white"></app-icon>
                </div>
            </div>
            <div
                class="relative overflow-hidden landscape:basis-1/2"
                *ngIf="smartDeviceService.userMedia.videoStreams.player">
                <video
                    *ngIf="smartDeviceService.userMedia.videoStreams.player.stream"
                    class="ingame-camera w-full"
                    [srcObject]="smartDeviceService.userMedia.videoStreams.player.stream"
                    autoplay
                    playsinline
                    webkit-playsinline
                    muted></video>
                <div
                    class="absolute right-4 top-4 h-7 w-7 rounded-md bg-orange p-1 inline-flex justify-center items-center">
                    <app-icon icon="videocam" size="medium" class="!text-white"></app-icon>
                </div>
            </div>
        </div>

        @if (smartDeviceService.userMedia?.videoStreams.hasStreams === true) {
            <div *ngIf="externalCode && externalPassword">
                <div class="dcSegmentedInputWrapper">
                    <div
                        class="text-base font-bold uppercase text-black font-open-sans text-center"
                        [translate]="'CODE'"
                        i18n="@@CODE">
                        Code
                    </div>
                    <div class="segmentContainer">
                        <div class="inputSegment">
                            <div class="character">
                                {{ externalCode?.toString()[0] || 1 }}
                            </div>
                        </div>
                        <div class="inputSegment">
                            <div class="character">
                                {{ externalCode?.toString()[1] || 2 }}
                            </div>
                        </div>
                        <div class="inputSegment">
                            <div class="character">
                                {{ externalCode?.toString()[2] || 3 }}
                            </div>
                        </div>
                        <div class="inputSegment">
                            <div class="character">
                                {{ externalCode?.toString()[3] || 4 }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="dcSegmentedInputWrapper">
                    <div
                        class="text-base font-bold uppercase text-black font-open-sans text-center"
                        [translate]="'PASSWORD'"
                        i18n="@@PASSWORD">
                        Password
                    </div>
                    <div class="segmentContainer">
                        <div class="inputSegment">
                            <div class="character">
                                {{ externalPassword?.toString()[0] || 'A' }}
                            </div>
                        </div>
                        <div class="inputSegment">
                            <div class="character">
                                {{ externalPassword?.toString()[1] || 'B' }}
                            </div>
                        </div>
                        <div class="inputSegment">
                            <div class="character">
                                {{ externalPassword?.toString()[2] || 'C' }}
                            </div>
                        </div>
                        <div class="inputSegment">
                            <div class="character">
                                {{ externalPassword?.toString()[3] || 'D' }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex justify-center mt-2">
                @if (externalCode && externalPassword) {
                    <app-primary-button
                        size="small"
                        (click)="disableCode()"
                        [translate]="'DISABLE_CODE'"
                        i18n="@@DISABLE_CODE">
                        Disable code
                    </app-primary-button>
                }

                @if (!externalCode && !externalPassword) {
                    <app-primary-button
                        size="small"
                        (click)="generateCredentials()"
                        [translate]="'GET_CODE'"
                        i18n="@@GET_CODE">
                        Get code
                    </app-primary-button>
                }
            </div>
        }
    </div>

    <!-- More -->
    <div class="flex flex-1 flex-col px-4 py-5 overflow-y-auto" *ngIf="view === 'more'">
        <div class="rounded-md bg-white px-4 py-3">
            <div class="flex w-full items-center justify-between" (click)="rename()">
                <div class="font-semibold text-black" [translate]="'DEVICE_NAME'" i18n="@@DEVICE_NAME">Device name</div>
                <div class="flex items-center justify-end space-x-2">
                    <div class="text-sm text-neutral-900">{{ smartDevice.name }}</div>
                    @if (isAdmin) {
                        <app-icon icon="keyboard_arrow_right"></app-icon>
                    }
                </div>
            </div>
        </div>

        <app-primary-button
            class="mt-8 block"
            size="normal"
            addedClasses="w-full"
            (click)="invitePlayer()"
            [translate]="'INVITE_PLAYER'"
            i18n="@@INVITE_PLAYER"
            *ngIf="isAdmin">
            Invite player
        </app-primary-button>

        <app-primary-button
            class="mt-2"
            size="small"
            color="white"
            addedClasses="w-full"
            fill="clear"
            [underline]="true"
            (click)="removeDevice()"
            [translate]="'REMOVE_DEVICE'"
            i18n="@@REMOVE_DEVICE"
            *ngIf="isAdmin || !networkOnlyAccess">
            Remove device
        </app-primary-button>

        <div class="mt-2 text-center text-sm text-neutral-600" *ngIf="smartDevice?.version">
            <span>v{{ smartDevice.version }}</span>
        </div>
    </div>

    <!-- Bottom bar -->
    <div class="flex flex-none items-center justify-evenly bg-white px-1 font-open-sans pb-safe">
        <div
            class="flex w-16 flex-col items-center justify-center space-y-0.5 py-1.5 text-neutral-200"
            [class.border-t-2]="view === 'camera'"
            [class.border-orange]="view === 'camera'"
            [class.text-orange]="view === 'camera'"
            (click)="view = 'camera'">
            <app-icon icon="photo_camera"></app-icon>
            <div
                class="text-zinc-400 text-center text-xs"
                [class.font-semibold]="view !== 'camera'"
                [class.font-bold]="view === 'camera'"
                [translate]="'CAMERA'"
                i18n="@@CAMERA">
                Camera
            </div>
        </div>

        <div
            class="flex w-16 flex-col items-center justify-center space-y-0.5 py-1.5 text-neutral-200"
            [class.border-t-2]="view === 'more'"
            [class.border-orange]="view === 'more'"
            [class.text-orange]="view === 'more'"
            (click)="view = 'more'">
            <div class="flex items-center justify-center">
                <app-icon icon="more_horiz"></app-icon>
            </div>
            <div
                class="text-zinc-400 text-center text-xs"
                [class.font-semibold]="view !== 'more'"
                [class.font-bold]="view === 'more'"
                [translate]="'MORE'"
                i18n="@@MORE">
                More
            </div>
        </div>
    </div>
</div>
