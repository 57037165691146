import { environment } from 'src/environments/environment';

export const Device_Images: {
    virt_cam: string;
    virt_logo: string;
    omni_scoring: string;
    omni_logo: string;
} = {
    virt_cam: 'assets/images/ftr/virt-cam-setup/Virt-setup-device-sm.jpg',
    virt_logo: 'assets/icon/ftr/virt_cam_logo.webp',
    omni_scoring: 'assets/images/ftr/omni-scoring-setup/Omni-setup-device-sm.jpg',
    omni_logo: 'assets/icon/ftr/omni_scoring_logo.webp',
};

export const BOARD_VALUES = [
    { val: 1, title: 1 },
    { val: 2, title: 2 },
    { val: 3, title: 3 },
    { val: 4, title: 4 },
    { val: 5, title: 5 },
    { val: 6, title: 6 },
    { val: 7, title: 7 },
    { val: 8, title: 8 },
    { val: 9, title: 9 },
    { val: 10, title: 10 },
    { val: 11, title: 11 },
    { val: 12, title: 12 },
    { val: 13, title: 13 },
    { val: 14, title: 14 },
    { val: 15, title: 15 },
    { val: 16, title: 16 },
    { val: 17, title: 17 },
    { val: 18, title: 18 },
    { val: 19, title: 19 },
    { val: 20, title: 20 },
    { val: 25, title: 'BULL' },
];

export const DEFAULT_HOTKEYS = [26, 41, 45, 60, 66, 81, 85, 100, 140, 180];
export const _CLOUD_SOUND_PREFIX = environment.apiUrl + '/sounds/names/';

export const ULTIMATE_MONTHLY_SUBSCRIPTION = 'ultimate_monthly';
export const ULTIMATE_YEARLY_SUBSCRIPTION = 'ultimate_yearly';

//6.2.x
export const FIRESTORE_TABLES = {
    matches: 'SB_onlineGames',
    invitations: 'SB_invitations',
    actions: 'SB_actions',
    onlineplayers: 'SB_onlinePlayers',
    pauses: 'SB_pauseDetails',
};

//TESTS
// export const FIRESTORE_TABLES = {
//   matches: 'TEST_onlineGames',
//   invitations: 'TEST_invitations',
//   actions: 'TEST_actions',
//   onlineplayers: 'TEST_onlinePlayers',
//   pauses: 'TEST_pauseDetails',
// };

export const TUTORIALS = {
    BOBS: 3,
    SCORES: 1,
    TACTICS: 4,
    SINGLES: 1,
    DOUBLES: 1,
    AROUND_THE_CLOCK: 1,
    SHANGHAI: 1,
};

export const DC_LANGUAGES = [
    {
        key: 'en',
        flag: 'gb.png',
        title: 'English',
    },
    {
        key: 'nl',
        flag: 'nl.png',
        title: 'Nederlands',
    },
    {
        key: 'de',
        flag: 'de.png',
        title: 'Deutsch',
    },
    {
        key: 'bg',
        flag: 'bg.png',
        title: 'Bulgarian (български)',
    },
    {
        key: 'zh-CN',
        flag: 'cn.png',
        title: 'Chinese',
    },
    {
        key: 'cs',
        flag: 'cz.png',
        title: 'Czech (Čeština)',
    },
    {
        key: 'da',
        flag: 'dk.png',
        title: 'Dansk',
    },
    {
        key: 'el',
        flag: 'gr.png',
        title: 'Greek',
    },
    {
        key: 'es',
        flag: 'es.png',
        title: 'Español',
    },
    {
        key: 'fr',
        flag: 'fr.png',
        title: 'Français',
    },
    {
        key: 'hr',
        flag: 'hr.png',
        title: 'Hrvatski',
    },
    {
        key: 'it',
        flag: 'it.png',
        title: 'Italiano',
    },
    {
        key: 'ja',
        flag: 'jp.png',
        title: 'Japanese (日本人)',
    },
    {
        key: 'hu',
        flag: 'hu.png',
        title: 'Magyar',
    },
    {
        key: 'pl',
        flag: 'pl.png',
        title: 'Polski',
    },
    // {
    //     key: 'pt',
    //     title: 'Português'
    // },
    {
        key: 'sr',
        flag: 'sr.png',
        title: 'Serbian (Српски)',
    },
    {
        key: 'fi',
        flag: 'fi.png',
        title: 'Suomi',
    },
    {
        key: 'sv',
        flag: 'sv.png',
        title: 'Svenska',
    },
    {
        key: 'tr',
        flag: 'tr.png',
        title: 'Türkçe',
    },
];

export const GA_EVENTCATEGORIES = {
    LAUNCH: 'launch_screen',
    DASHBOARD: 'dashboard',
    BOTTOMNAV: 'bottom_nav',
    CTAMODULE: 'cta_module',
    MENU: 'menu',
    GENERAL: 'general_settings',
    PROFILE: 'profile',
    ULTIMATE: 'ultimate_dialog',
    DISCOUNT: 'discount_dialog',
    PRO: 'pro_dialog',
    NEWGAME: 'new_game',
    X01SETUP: 'x01_match_setup',
    CRICKETSETUP: 'cricket_tactics_setup',
    SINGLESSETUP: 'singles_training_setup',
    DOUBLESSETUP: 'doubles_training_setup',
    BOBSSETUP: 'bobs_27_training_setup',
    SCORESSETUP: 'score_training_setup',
    ONLINEGAMES: 'online_games',
    PREONLINE: 'pre_online_lobby',
    FRIENDSLOBBY: 'friends_lobby',
    GLOBALLOBBY: 'global_lobby',
    MASTERCALLER: 'master_caller',
    STATISTICS: 'statistics',
    ACTION_REPLAYS: 'action_replays',
    TOPBEST501: 'top_25_best_501_avg',
    TOPWORST501: 'top_25_worst_501_avg',
    TOPBESTF9: 'top_25_best_first_9_avg',
    TOPWORSTF9: 'top_25_worst_first_9_avg',
    TOPBESTCHECKOUTRATE: 'top_25_best_checkout_rate',
    TOPWORSTCHECKOUTRATE: 'top_25_worst_checkout_rate',
    TOPBESTCHECKOUTS: 'top_25_best_checkouts',
    TOPWORSTCHECKOUTS: 'top_25_worst_checkouts',
    MATCHSTATS: 'matches_statistics',
    CRICKETSTATS: 'cricket_tactics_statistics',
    SINGLESSTATS: 'singles_training_statistics',
    DOUBLESSTATS: 'doubles_training_statistics',
    BOBSSTATS: 'bobs_27_statistics',
    SCORESSTATS: 'score_training_statistics',
    GAMEDETAILSX01: 'game_detail_x01',
    SHAREMATCH: 'share_match',
    ACCOUNTSETTINGS: 'account_settings',
    SEARCHFRIENDS: 'search_friends',
    INGAMEMENU: 'in_game_menu',
    INGAMEX01: 'in_game_x01',
    INGAMEONLINE: 'in_game_online',
    INGAMECRICKET: 'in_game_cricket_tactics',
    INGAMESINGLES: 'in_game_singles_raining',
    INGAMEDOUBLES: 'in_game_doubles_training',
    INGAMEBOBS: 'in_game_bobs_27',
    INGAMESCORES: 'in_game_score_training',
    ENDSCREENX01: 'end_screen_x01',
    ENDSCREENONLINE: 'end_screen_online_match',
    PLAYER_OPTIONS: 'player_options_in_settings',
    SUBSCRIPTIONS: 'subscriptions',
    PROFILEDIALOG: 'profile_dialog',
    PURCHASES: 'purchases',
};

export const GA_EVENTACTIONS = {
    CHOOSELANG: 'choose_language',
    FBLOGIN: 'facebook_login',
    LOGIN: 'regular_ogin',
    CREATEACCOUNT: 'create_account',
    GUEST: 'continue_without_account',
    FORGOTPASS: 'forgot_password',
    OPENDASHBOARD: 'open_dashboard',
    OPEN_SMART_DEVICES: 'open_smart_devices',
    SMART_DEVICES_NEXT_SLIDE: 'next_slide',
    SMART_DEVICES_PREVIOUS_SLIDE: 'previous_slide',
    OPENPROFILE: 'open_profile',
    OPENULTIMATE: 'open_ultimate_dialog',
    STARTNEWGAME: 'start_a_new_game',
    OPENONLINE: 'open_friends_lobby',
    OPENGLOBAL: 'open_global_lobby',
    OPENPREONLINE: 'open_pre_online_lobby',
    ADDTOLOBBY: 'add_to_lobby',
    REMOVEFROMLOBBY: 'remove_from_lobby',
    OPENOFFLINEGAMES: 'open_offline_games',
    OPENONLINEGAMES: 'open_online_games',
    OPENCHATS: 'open_chats',
    OPENMASTERCALLER: 'open_master_caller_dialog',
    OPENONLINESETUP: 'open_online_setup',
    OPENSTATISTICS: 'open_statistics',
    CTAPRESS: 'press_cta_button',
    OPENMENU: 'open_menu',
    OPENGENERAL: 'open_general_settings',
    SEARCHFRIENDS: 'search_friends',
    ACCOUNTSETTINGS: 'open_account_settings',
    LOGOUT: 'log_out',
    MASTERCALLEROFF: 'master_caller_off',
    MASTERCALLERON: 'master_caller_on',
    CHANGELANG: 'change_language',
    TERMSOFUSE: 'open_terms_of_service',
    PRIVACYPOLICY: 'open_privacy_policy',
    BUYMONTHLY: 'buy_ultimate_on_website_monthly',
    BUYYEARLY: 'buy_ultimate_on_website_yearly',
    BUYPRO: 'buy_pro',
    OPENDISCOUNT: 'open_discount_dialog',
    ALLFEATURES: 'show_all_features_dialog',
    OPENMATCH: 'open_match_setup',
    OPENCRICKET: 'open_cricket_tactics_setup',
    OPENSINGLES: 'open_singles_training_setup',
    OPENDOUBLES: 'open_doubles_training_setup',
    OPENBOBS: 'open_bobs_27_setup',
    OPENSCORES: 'open_score_training_setup',
    OPENBANNERLINK: 'open_banner_link',
    LINKFRIEND: 'link_a_friend',
    LINKOTHER: 'link_other_account',
    LINKOWN: 'link_own_account',
    UNLINKPLAYER: 'unlink_player',
    LINKSOUND: 'link_sound',
    TWOLEGSDIFF: 'two_legs_difference',
    SUDDENDEATHMODE: 'sudden_death_mode',
    OPENINFOSLIDES: 'open_info_slides',
    CREATEGAME: 'create_a_game',
    EDITGAME: 'edit_created_game',
    INVITEFRIEND: 'invite_riend',
    JOINMATCH: 'join_global_match',
    CANCELJOINMATCH: 'cancel_join_global_match',
    SUBSCRIPTIONS: 'account_subscriptions',
    SUBSCRIPTION_CANCELLED: 'subscription_cancelled',
    CHALLENGEFRIENDS: 'challenge_friends',
    SHOWCAMERAPREVIEW: 'show_camera_preview',
    SHOWMATCHINFO: 'show_match_info',
    SHOWLASTGAME: 'show_last_game',

    THISMONTH: 'view_this_month',
    THISYEAR: 'view_this_year',
    CUSTOMRANGE: 'view_custom_range',
    CHANGERANGE: 'change_custom_date_range',
    VIEWBEST501: 'view_top_25_best_501_avg',
    VIEWWORST501: 'view_top_25_worst_501_avg',
    VIEWBESTF9: 'view_top_25_best_first_9_avg',
    VIEWWORSTF9: 'view_top_25_worst_first_9_avg',
    VIEWBESTCHECKOUTRATE: 'view_top_25_best_checkout_rate',
    VIEWWORSTCHECKOUTRATE: 'view_top_25_worst_checkout_rate',
    VIEWBESTCHECKOUTS: 'view_top_25_best_checkouts',
    VIEWWORSTCHECKOUTS: 'view_top_25_worst_checkouts',
    OPENMATCHSTATS: 'open_matches_statistics',
    OPENCRICKETSTATS: 'open_cricket_tactics_statistics',
    OPENSINGLESSTATS: 'open_singles_training_statistics',
    OPENDOUBLESSTATS: 'open_doubles_training_statistics',
    OPENBOBSSTATS: 'open_bobs_27_statistics',
    OPENSCORESSTATS: 'open_score_training_statistics',
    OPENAROUNDTHECLOCKSTATS: 'open_around_the_clock_statistics',
    OPENSHANGHAISTATS: 'open_shanghai_statistics',
    OPENMATCHSTATSTABLE: 'open_matches_statistics_table',
    OPENCRICKETSTATSTABLE: 'open_cricket_tactics_statistics_table',
    OPENSINGLESSTATSTABLE: 'open_singles_training_statistics_table',
    OPENDOUBLESSTATSTABLE: 'open_doubles_training_statistics_table',
    OPENBOBSSTATSTABLE: 'open_bobs_27_statistics_table',
    OPENSCORESSTATSTABLE: 'open_score_training_statistics_table',
    OPENAROUNDTHECLOCKSTATSTABLE: 'open_around_the_clock_statistics_table',
    OPENSHANGHAISTATSTABLE: 'open_shanghai_statistics_table',
    OPENGAMEDETAILS: 'open_gam_details',
    CHANGEMATCHFILTER: 'change_matches_filter',
    CHANGECRICKETFILTER: 'change_cricket_tactics_filter',
    CHANGESINGLESFILTER: 'change_singles_training_filter',
    CHANGEDOUBLESFILTER: 'change_doubles_training_filter',
    CHANGEBOBSFILTER: 'change_bobs_27_filter',
    CHANGESCOREFILTER: 'change_score_training_filter',
    CHANGEAROUNDTHECLOCKFILTER: 'change_around_the_clock_filter',
    CHANGESHAINGHAIFILTER: 'change_shanghai_filter',

    SHARESOCIAL: 'share_match_on_social_media',
    SHAREFB: 'share_match_on_facebook',
    SHARETWITTER: 'share_match_on_twitter',
    SHAREINSTAFEED: 'share_match_on_instagram_feed',
    SHAREINSTASTORY: 'share_match_on_instagram_story',
    CHANGEPASS: 'change_password',
    CHANGEEMAIL: 'change_email_address',
    ADDFRIEND: 'add_friend',
    ACCEPTFRIEND: 'accept_friend',
    SHOWFRIENDOPTIONS: 'show_friend_options',
    REMOVEFRIEND: 'remove_friend',
    CANCELPENDING: 'cancel_pending_request',
    OPENPROFILEUSER: 'open_profile_user',

    INGAMESTATS: 'view_in_game_statistics',
    INGAMEMENU: 'view_in_game_menu',
    TAKEBREAK: 'take_a_break',
    EDITSCORE: 'edit_score',
    QUITDELETE: 'quit_and_delete',
    SAVEQUITTEDGAME: 'save_deleted_game',
    DELETEQUITTEDGAME: 'delete_quitted_game',
    QUITGAME: 'quit_game',
    LOCALSAVEGAME: 'locally_save_game',
    SAVEGAME: 'save_game',
    SELECTIMAGE: 'select_image',
    TAKEPICTURE: 'take_picture',
    VIEWFRIENDS: 'view_friends',
    OPENFRIEND: 'open_profile_friend',
    EDITPROFILE: 'edit_profile',
    STARTINGGAME: 'start_new_game',
    SHOWPROFILEPOPUP: 'show_profile_popup',
    SHOWTOUR: 'show_tour',
    SAVEDREPLAY: 'saved_replay',
    DOWNLOAD_REPLAY: 'download_replay',
    REACHEDMAXREPLAYS: 'reached_max_replays',
    REACHEDMAXFAVORITES: 'reached_max_favorites',
    FAVORITENOTULTIMATE: 'favorite_not_ultimate',
    FAVORITED: 'favorited',
    UNFAVORITED: 'unfavorited',
};

export const INGAMECOMMS = [
    {
        text: 'Good darts!',
    },
    {
        text: 'Good luck!',
    },
    {
        text: 'Too bad!',
    },
    {
        text: 'Double trouble!',
    },
    {
        text: 'Bounce-out!',
    },
    {
        text: 'That was epic!',
    },
    {
        text: 'OMG!',
    },
    {
        text: "I'm so happy!",
    },
    {
        text: 'This never happens!',
    },
    {
        text: 'Nice one!',
    },
    {
        text: 'Have to go, sorry!',
    },
    {
        text: 'Short break!',
    },
    {
        text: 'Be right back!',
    },
    {
        text: 'Thanks!',
    },
    {
        text: 'No problem!',
    },
    {
        text: 'Alright!',
    },
    {
        text: "I'll rate 5 stars!",
    },
];

export const POSTGAMECOMMS = [
    {
        text: 'Good game!',
    },
    {
        text: 'Too bad!',
    },
    {
        text: 'Nice finish!',
    },
    {
        text: 'Congrats!',
    },
    {
        text: 'Have to go, sorry!',
    },
    {
        text: 'Thanks!',
    },
    {
        text: 'No problem!',
    },
    {
        text: 'Alright!',
    },
    {
        text: 'See ya!',
    },
    {
        text: "I'll rate 5 stars!",
    },
    {
        text: "I'll add you as a friend!",
    },
];
