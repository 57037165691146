/* eslint-disable max-len */
import { HttpClient } from '@angular/common/http';
import { Component, DestroyRef, ElementRef, inject, NgZone, OnInit, Renderer2, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { OmniIngameService, OmniSingleDart } from '@dc-core/dc-services/omni/omni-ingame.service';
import * as d3 from 'd3';

@Component({
    selector: 'app-omni-dartboard',
    standalone: true,
    imports: [],
    templateUrl: './omni-dartboard.component.html',
    styleUrls: ['./omni-dartboard.component.scss'],
})
export class OmniDartBoardComponent implements OnInit {
    @ViewChild('omniDartboardWrapper') public svgWrapper: ElementRef;
    private dartboardSvgUrl: string = 'assets/images/tor_board.svg'; // Path to your SVG file
    public dartboardSvg: SVGElement;

    public svgSize: number;
    public svgContent: string;
    private static cachedSvg: SVGElement | null = null; // Cache static variable for the SVG

    private destroyRef: DestroyRef = inject(DestroyRef);
    private screenOrientation: ScreenOrientation = inject(ScreenOrientation);
    private ngZone: NgZone = inject(NgZone);
    private retryCount = 0;
    private maxRetries = 10;

    constructor(
        private _http: HttpClient,
        private _renderer: Renderer2,
        private _elementRef: ElementRef,
        private _ingameOmniService: OmniIngameService
    ) {}

    ngOnInit(): void {
        this.loadSvg();

        this._ingameOmniService.omniIngameEvent$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            this.clearSvg();
        });

        this.screenOrientation
            .onChange()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                this.setSVGSize(false);
            });
    }

    private loadSvg(): void {
        if (OmniDartBoardComponent.cachedSvg) {
            // Use cached SVG
            this.dartboardSvg = OmniDartBoardComponent.cachedSvg.cloneNode(true) as SVGElement;
            this.setSVGSize();
        } else {
            // Load SVG via HTTP only if not cached
            this._http.get(this.dartboardSvgUrl, { responseType: 'text' }).subscribe((dartboardSvg) => {
                this.svgContent = dartboardSvg;

                const tempDiv = document.createElement('div');
                tempDiv.innerHTML = dartboardSvg.trim();
                this.dartboardSvg = tempDiv.querySelector('svg');

                if (this.dartboardSvg) {
                    OmniDartBoardComponent.cachedSvg = this.dartboardSvg.cloneNode(true) as SVGElement; // Cache the SVG
                    this.setSVGSize();
                }
            });
        }
    }

    private setSVGSize(initDartHits = true) {
        if (!this.svgWrapper && this.retryCount < this.maxRetries) {
            this.retryCount++;
            setTimeout(() => this.setSVGSize(initDartHits), 50);
            return;
        } else if (this.retryCount >= this.maxRetries) {
            console.error('Failed to initialize SVG after multiple attempts.');
            return;
        }
        this.retryCount = 0;

        const viewportWidth = this.svgWrapper.nativeElement.clientWidth;
        const viewportHeight = this.svgWrapper.nativeElement.clientHeight;

        if (!viewportWidth || !viewportHeight) {
            return;
        }

        const svgSize = Math.min(viewportWidth, viewportHeight);
        this.svgSize = svgSize;

        this.dartboardSvg.setAttribute('width', `${svgSize}`);
        this.dartboardSvg.setAttribute('height', `${svgSize}`);

        const container = this._elementRef.nativeElement.querySelector('.dartboard-container');
        container.innerHTML = '';
        container.appendChild(this.dartboardSvg);

        this._renderer.addClass(this.dartboardSvg, 'dartboard-svg');

        if (initDartHits) {
            this._ingameOmniService.dartHits$.subscribe((dartHits) => {
                this.showHitPoints(dartHits);
            });
        }
    }

    public showHitPoints(dartHits: OmniSingleDart[]): void {
        if (this.dartboardSvg) {
            const svg = d3.select(this.dartboardSvg);

            const viewBox = svg.attr('viewBox');
            if (viewBox) {
                const [_minX, _minY, width, height] = viewBox.split(' ').map(Number);
                this.svgSize = width;
                this.svgSize = height;
            } else {
                console.error('SVG dimensions could not be determined.');
                return;
            }

            const center = { x: this.svgSize / 2, y: this.svgSize / 2 };
            const dartboardGroup = svg.append('g').attr('transform', `translate(${center.x}, ${center.y})`);

            const hitpoints = dartHits.filter((dart) => this.isValidThrow(dart));

            dartboardGroup
                .selectAll('circle')
                .data(hitpoints)
                .enter()
                .append('circle')
                .attr('class', 'hitpoint-circle')
                .attr('cx', (d: OmniSingleDart) => d.coordinates.x)
                .attr('cy', (d: OmniSingleDart) => d.coordinates.y)
                .attr('r', 8)
                .style('fill', '#4c8dff')
                .style('stroke', '#ffffff')
                .style('stroke-width', '2');
        } else {
            console.warn('DC Warning: SVG not yet initialized, skipping...');
        }
    }

    private isValidThrow(dart: OmniSingleDart) {
        if (!dart || dart?.singleDart.isBounceout) {
            return false;
        } else if (
            dart.coordinates.x > 250 ||
            dart.coordinates.x < -250 ||
            dart.coordinates.y > 250 ||
            dart.coordinates.y < -250 ||
            (!dart.coordinates.x && !dart.singleDart.amount)
        ) {
            return false;
        }

        return true;
    }

    public clearSvg() {
        const svg = d3.select(this.dartboardSvg);
        svg.selectAll('circle.hitpoint-circle').remove();
    }
}
