<app-page-header mainNavigation="custom" color="transparent">
    <div title [translate]="'LOGIN_OR_CREATE_TITLE'" i18n="@@LOGIN_OR_CREATE_TITLE">Login or create an account</div>
    <app-icon icon="close" (click)="DismissModal()"></app-icon>
</app-page-header>

<ion-content padding>
    <ion-row>
        <ion-col>
            <p style="font-weight: semibold" [translate]="'LOGIN_OR_CREATE_INFO'" i18n="@@LOGIN_OR_CREATE_INFO">
                Login or create an account to keep track of your progress, see your statistics and discover more
                features!
            </p>
        </ion-col>
    </ion-row>

    <ion-row>
        <ion-col>
            <p [translate]="'WITH_ACCOUNT_YOU_CAN'" i18n="@@WITH_ACCOUNT_YOU_CAN">
                With an account you can add friends and see all your statistics.
            </p>
        </ion-col>
    </ion-row>

    <ion-row>
        <ion-col class="text-center">
            <ion-button
                size="small"
                (click)="DismissModal(1)"
                fill="outline"
                [translate]="'CONTINUE_WITHOUT_ACCOUNT'"
                i18n="@@CONTINUE_WITHOUT_ACCOUNT"
                >Continue without account</ion-button
            >
            <ion-button size="small" (click)="DismissModal(2)" color="secondary" [translate]="'LOGIN'" i18n="@@LOGIN"
                >Login</ion-button
            >
        </ion-col>
    </ion-row>
</ion-content>
