<modal-content scrollY="false" fullscreen>
    <div class="auto-height-dialog flex flex-col overflow-y-auto" *ngIf="invite">
        <div class="title-container">
            <div class="title" [translate]="'INCOMING_GAME_INVITE'" i18n="@@INCOMING_GAME_INVITE">
                Incoming game invite
            </div>
        </div>
        <div class="mt-8 flex w-full flex-col" *ngIf="gameplay && invite.user">
            <div class="flex items-center justify-between rounded-t-md bg-neutral-50 dark:bg-black-full px-4 py-2.5">
                <div class="font-bold text-black">{{ onlineFunctions.onlineGameplayName(gameplay) }}</div>
                <div class="flex justify-center space-x-2" *ngIf="invite.user.room">
                    <div class="flex items-center rounded-sm bg-black-full p-1.5">
                        <app-icon icon="videocam" size="small" class="text-white"></app-icon>
                        <div
                            class="ml-0.5 text-xxxs font-bold uppercase"
                            *ngIf="invite.user.room.camType === 'smart_device'">
                            Virt Cam
                        </div>
                    </div>
                </div>
            </div>
            <div class="relative flex items-center justify-between rounded-b-md bg-neutral-100 px-4 py-2">
                <div class="flex items-center justify-start space-x-2">
                    <app-profile-photo
                        class="h-fit"
                        [ultimate]="invite.user.is_ultimate"
                        [badge]="invite.user.badge"
                        [url]="invite.user.profile_photo_url"
                        [size]="32">
                    </app-profile-photo>
                    <div class="space-y-0.5 overflow-hidden">
                        <div class="flex items-center space-x-1">
                            <div class="truncate text-xs font-bold text-white">
                                {{ invite.user.first_name || ('NO_NAME' | translate) }}
                            </div>
                        </div>
                        <app-online-player-stats [player]="invite.user"></app-online-player-stats>
                    </div>
                </div>
            </div>
        </div>
        <div class="relative flex mx-auto mt-4 max-w-xs overflow-hidden h-full" *ngIf="userMedia?.videoStreams.board">
            <video
                class="ingame-camera w-full"
                [srcObject]="userMedia?.videoStreams.board.stream"
                [style.transform]="userMedia?.videoStreams.board.scale"
                autoplay
                playsinline
                webkit-playsinline
                muted></video>
            <div class="absolute left-4 top-4 h-7 w-7 rounded-md bg-orange p-1 inline-flex justify-center items-center">
                <app-icon icon="videocam" size="medium" class="!text-white"></app-icon>
            </div>
        </div>
        @if (canPlayOnline()) {
            <div class="titledetailbar !mt-4">
                <ion-row>
                    <ion-col class="text bold text-center" [translate]="'LAST_FIVE_GAMES'" i18n="@@LAST_FIVE_GAMES"
                        >Last results</ion-col
                    >
                </ion-row>
                <ion-row>
                    <ion-col class="text text-center" *ngIf="invite.user.last_results?.length > 0">
                        <span
                            *ngFor="let result of invite.user.last_results"
                            class="dot"
                            [ngClass]="{
                                lose: result === 'lost',
                                win: result === 'won',
                                draw: result === 'tie'
                            }"></span>
                    </ion-col>
                    <ion-col
                        class="text text-center"
                        *ngIf="!invite.user.last_results?.length"
                        [translate]="'NO_ONLINE_MATCHES'"
                        i18n="@@NO_ONLINE_MATCHES"
                        >No online matches played
                    </ion-col>
                </ion-row>
            </div>
            <div class="button-container flex justify-between space-x-2">
                <app-primary-button
                    size="normal"
                    addedClasses="px-1"
                    color="red"
                    (click)="decline()"
                    [translate]="'DECLINE'"
                    i18n="@@DECLINE">
                    Decline
                </app-primary-button>
                <app-primary-button
                    size="normal"
                    addedClasses="px-1"
                    color="green"
                    (click)="accept()"
                    [translate]="'ACCEPT'"
                    i18n="@@ACCEPT">
                    Accept
                </app-primary-button>
            </div>
        } @else {
            <div class="titledetailbar !mt-4">
                <ion-row>
                    <ion-col
                        class="text bold text-center"
                        [translate]="'NO_MORE_FREE_GAMES_THIS_WEEK'"
                        i18n="@@NO_MORE_FREE_GAMES_THIS_WEEK">
                        Your free online games for this week have been used</ion-col
                    >
                </ion-row>
            </div>
            <div class="flex justify-center mt-2">
                <app-primary-button
                    size="normal"
                    addedClasses="px-1"
                    color="blue"
                    (click)="decline()"
                    [translate]="'OK'"
                    i18n="@@OK">
                    OK
                </app-primary-button>
            </div>
        }
    </div>
</modal-content>
