import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { OnlineGameplay } from '@dc-core/dc-backend/dc-interfaces';
import { LobbySettingsCollectionService } from '@dc-core/dc-firestore/collection-helpers/lobby_settings.collection.service';
import { DCFireStoreInvite } from '@dc-core/dc-firestore/globals/firestore.tables';
import { OnlineFunctions } from '@dc-core/dc-gamelogic/online-game/online.functions';
import { LocalStorageKey } from '@dc-core/dc-localstorage';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { UserMedia } from '@dc-core/dc-services/dc-janus/DartCounterUserMedia';
import { JanusVideoRoomService } from '@dc-core/dc-services/dc-janus/janus-video-room.service';
import { FreeMatchesService } from '@dc-core/dc-services/free-matches.service';
import { ModalController } from '@ionic/angular';
import { onSnapshot, QueryDocumentSnapshot, Unsubscribe } from 'firebase/firestore';
import { GamesLeaveBusterService } from 'src/app/modules/games/services/games-leave-buster.service';
import { JoyrideService } from 'src/providers/ngx-joyride/src/lib/services/joyride.service';
import { AuthService } from 'src/services/auth.service';

export interface AcceptDenyGameInviteRequestDialogResponse {
    acceptedInvite: boolean;
}

@Component({
    selector: 'app-accept-deny-game-invite-request-dialog',
    templateUrl: 'accept-deny-game-invite-request.html',
    styleUrls: ['accept-deny-game-invite-request.scss'],
})
export class AcceptDenyGameInviteRequestDialogComponent implements OnInit, OnDestroy {
    @Input() queryInvite: QueryDocumentSnapshot<DCFireStoreInvite>;
    invite: DCFireStoreInvite;
    gameplay: OnlineGameplay;
    unsubscribe: Unsubscribe;

    userMedia = new UserMedia();

    private freeMatchesService: FreeMatchesService = inject(FreeMatchesService);

    constructor(
        public view: ModalController,
        public onlineFunctions: OnlineFunctions,
        private readonly joyrideService: JoyrideService,
        private _alertService: DartCounterAlertService,
        public auth: AuthService,
        private _videoRoomService: JanusVideoRoomService,
        private _lobbySettingsCollectionService: LobbySettingsCollectionService,
        private gamesLeaveBusterService: GamesLeaveBusterService
    ) {}

    ngOnInit() {
        this.invite = this.queryInvite.data();

        this.unsubscribe = onSnapshot(this.queryInvite.ref, (docSnapshot) => {
            if (!docSnapshot.exists()) {
                this._alertService.createAlert({
                    title: $localize`:@@INVITE_IS_CANCELLED:The invite has been cancelled`,
                    icon: 'info',
                    showCloseButton: true,
                });

                this.view.dismiss();
                this.unsubscribe();
            } else {
                if (this.invite.user.room?.roomID) {
                    this._videoRoomService
                        .spectateRoom(
                            this.invite.user.room,
                            'video',
                            this.invite.user,
                            this.userMedia,
                            false,
                            false,
                            false
                        )
                        .catch((error) => {
                            console.error('Error joining room:', error);
                        });
                }

                this._lobbySettingsCollectionService.getDocSnapshot(this.invite.gameplay_doc_id).then((snapshot) => {
                    this.gameplay = snapshot.data();
                });
            }
        });

        const showTour = JSON.parse(localStorage.getItem(LocalStorageKey.firstTimeProfileDialog)) ?? true;
        if (showTour) {
            this.showTour();
            localStorage.setItem(LocalStorageKey.firstTimeProfileDialog, JSON.stringify(false));
        }
    }

    public canPlayOnline(): boolean {
        return this.freeMatchesService.canPlay;
    }

    public showTour(index = null) {
        let steps = ['firstStep'];
        if (index !== null) {
            steps = [steps[index]];
        }

        setTimeout(() => {
            this.joyrideService.startTour({
                steps: steps,
                showPrevButton: false,
                themeColor: '#444',
            });
        }, 500);
    }

    public decline(): void {
        this.unsubscribe();
        const response: AcceptDenyGameInviteRequestDialogResponse = {
            acceptedInvite: false,
        };
        this.view.dismiss(response);
    }

    public accept(): void {
        if (this.canPlayOnline()) {
            this.gamesLeaveBusterService.allowedToLeave = true;

            this.unsubscribe();
            const response: AcceptDenyGameInviteRequestDialogResponse = {
                acceptedInvite: true,
            };
            this.view.dismiss(response);
        }
    }

    async ngOnDestroy(): Promise<void> {
        if (this.userMedia && this.invite.user.room?.roomID) {
            await this._videoRoomService
                .leaveRoomAndDetachAllHandles(this.invite.user.room.roomID, true)
                .catch(console.error);
            this.userMedia.cleanupUserMedia(true, true);
        }
    }
}
