/* eslint-disable max-len */
import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { ContactApiService } from '@dc-api/contact.api.service';
import { User } from '@dc-core/dc-backend/dc-classes';
import { ContactCategory } from '@dc-core/dc-backend/dc-enums';
import { DropdownSelectItem } from '@dc-core/dc-statistics/statistics.globals';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { DartCounterAlertService } from 'dc-core/dc-services/alert.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { DCLoadingService } from 'src/providers/DCLoadingService';
import { SocialMediaService } from 'src/providers/SocialMediaService';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-contact',
    templateUrl: 'contact.component.html',
    styleUrls: ['contact.component.scss'],
})
export class ContactComponent {
    user: User;
    isGuest = false;

    public contactForm: FormGroup;
    public isWeb: boolean = environment.isWeb;

    categories: DropdownSelectItem[] = [
        {
            label: $localize`:@@REQUEST_NAME_FOR_MASTERCALLER:Request name for MasterCaller`,
            value: 'mastercaller' as ContactCategory,
        },
        {
            label: $localize`:@@Q_ABOUT_ACCOUNT_SUBSCRIPTION:Question about account/subscription`,
            value: 'accounts' as ContactCategory,
        },
        {
            label: $localize`:@@REQUEST_A_NEW_FEATURE:Request a new feature`,
            value: 'new_feature' as ContactCategory,
        },
        { label: $localize`:@@REPORT_A_BUG_ISSUE:Report a bug/issue`, value: 'bug_issue' as ContactCategory },
        { label: 'Target Virt Camera', value: 'virt_cam' as ContactCategory },
        { label: $localize`:@@OTHER:Other`, value: 'other' as ContactCategory },
    ];

    private isSending = false;

    private recaptchaV3Service: ReCaptchaV3Service = inject(ReCaptchaV3Service);
    private destroyRef: DestroyRef = inject(DestroyRef);

    constructor(
        public nav: NavController,
        public auth: AuthService,
        public platform: Platform,
        public navCtrl: NavController,
        public loading: DCLoadingService,
        public social: SocialMediaService,
        public modal: ModalController,
        public route: ActivatedRoute,
        private _contactApiService: ContactApiService,
        private _alertService: DartCounterAlertService,
        private formBuilder: FormBuilder
    ) {
        this.user = this.auth.user;

        this.contactForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: [''],
            email: ['', Validators.required],
            category: [this.categories[0].value, Validators.required],
            subject: [''],
            message: ['', Validators.required],
        });
        if (!this.user || this.user.first_name?.startsWith('guest_')) {
            this.isGuest = true;
        } else {
            this.contactForm.patchValue({
                firstName: this.user.first_name,
                lastName: this.user.last_name,
            });
        }

        this.contactForm.patchValue({
            email: this.user?.email,
        });

        $localize`:@@SUBJECT:Subject`;
        $localize`:@@MESSAGE:Message`;
        $localize`:@@ENTER_YOUR_NAME_HERE:Enter your name here`;
    }

    public updateCategory(category: ContactCategory): void {
        this.contactForm.patchValue({
            category: category,
        });
    }

    async send() {
        if (this.contactForm.invalid) {
            this._alertService.createAlert({
                title: $localize`:@@FILL_IN_REQUIRED_FIELDS:Please fill in the required fields`,
                icon: 'error',
            });
            return;
        }

        if (this.isSending) {
            return;
        }

        this.isSending = true;

        this.loading.ShowDefaultLoader();

        let appInfo = null;
        let deviceInfo = null;
        try {
            appInfo = await App.getInfo();
            deviceInfo = await Device.getInfo();
        } catch (_) {}

        this.recaptchaV3Service
            .execute('contact')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((token) => {
                this._contactApiService
                    .contactUs({
                        first_name: this.contactForm.get('firstName').value,
                        last_name: this.contactForm.get('lastName').value,
                        email: this.contactForm.get('email').value,
                        category: this.contactForm.get('category').value,
                        subject: this.contactForm.get('subject').value,
                        message: this.contactForm.get('message').value,
                        platform: environment.isWeb ? 'web' : this.platform.is('ios') ? 'ios' : 'android',
                        device: environment.isWeb ? 'website' : this.platform.is('tablet') ? 'tablet' : 'mobile',
                        version: appInfo?.version,
                        build: appInfo?.build,
                        device_model: deviceInfo?.model,
                        os_version: deviceInfo?.osVersion,
                        width: this.platform.width(),
                        height: this.platform.height(),
                        recaptcha: token,
                    })
                    .then(() => {
                        if (this.contactForm.get('category').value === 'mastercaller') {
                            this._alertService.createAlert({
                                title: $localize`:@@YOUR_NAME_IS_REQUESTED_TO_OUR_MASTERCALLER:Your name is requested to our master caller. It will be added within a week from now. Please keep an eye on the list.`,
                                timer: null,
                                confirmButtonText: $localize`:@@CLOSE:Close`,
                            });
                        } else {
                            this._alertService.createAlert({
                                title: $localize`:@@CONTACTED_US_ASAP_RESPONSE:We have received your message, we will get back to you as soon as possible!`,
                                timer: null,
                                confirmButtonText: $localize`:@@CLOSE:Close`,
                            });
                        }

                        this.contactForm.patchValue({
                            category: null,
                            subject: null,
                            message: null,
                        });

                        setTimeout(() => {
                            this.nav.back();
                        }, 1500);
                    })
                    .catch((err) => {
                        this._alertService.errorFromApi(err);
                    })
                    .finally(() => {
                        this.isSending = false;
                        this.loading.DismissLoader();
                    });
            });
    }
}
