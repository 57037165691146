<ion-content class="bg-white-pattern ion-black-text">
    <app-page-header mainNavigation="custom" color="white">
        <div title [translate]="'TERMS_OF_SERVICE'" i18n="@@TERMS_OF_SERVICE">Terms of Service</div>
        <app-icon icon="close" (click)="DismissModal()"></app-icon>
    </app-page-header>

    <div class="copy p-4 text-md text-black">
        <div class="space-y-4">
            <h1>End User License Agreement for DartCounter</h1>
            <h2>Scope of the license</h2>
            <p>
                DartCounter hereby grants to the legitimate acquirer of DartCounter ("you") a usage right for this App.
                This right is restricted to use by you as a natural person not acting in the course of
                business.&nbsp;<br />a This license is personal. The App may be used only on the device onto which the
                App was downloaded and installed. The purpose of the App is to provide a sports service, while users are
                of course free to use DartCounter for any purpose within the boundaries of these terms and conditions
                and the law.
            </p>
            <h2>It is not permitted</h2>
            <ul>
                <li>
                    to decompile or reverse engineer the source code of the App, except to the extent permitted by
                    mandatory law;
                </li>
                <li>to supply copies of the App to third parties;</li>
                <li>
                    to sublicense the App or to make the App available to third parties, by rental,
                    Software-as-a-Service constructs or otherwise;
                </li>
                <li>to modify the App, except to the extent permitted by mandatory law;</li>
                <li>to remove or make unreadable notices of DartCounter as copyright holder of the App.</li>
            </ul>
            <p>
                The App processes your personal data. For more information consult the latest privacy statement that can
                be found at <a href="https://dartcounter.net/privacy-policy">https://dartcounter.net/privacy-policy</a>
            </p>
            <p>
                In addition to the terms and conditions of the present document {{ getLongName() }} may set terms for
                the acquisition and use of the App, the making of in-app purchases and related matters. To find out
                more, see the terms of use and privacy statement of {{ getLongName() }} and other terms and conditions
                identified on the website of this third party.
            </p>
            <h2>Intellectual property</h2>
            <p>
                All rights to the App, accompanying documentation and all modifications and extensions there to rest and
                remain with DartCounter. You only acquire those rights and permissions that follow from this agreement
                or those that are granted separately in writing. You may not use, copy or publish the App except as
                permitted.
            </p>
            <h2>In-app purchases</h2>
            <p>
                The app offers two in-app purchases; "Ultimate Yearly" and “Ultimate Monthly”. Ultimate Yearly is an
                annual auto renewable subscription which gives you access to more features and statistics to improve
                your darts skills. Ultimate Monthly is a monthly auto renewable subscription which gives you access to
                more features and statistics to improve your darts skills.&nbsp;<br />
                When you own this subscription you will get the newest gameplays earlier than the users without the
                subscription. Also, we will keep adding more Ultimate features in the future updates to make the usage
                easier, more detailed, and even more fun!
            </p>
            <p>
                The Ultimate Yearly subscription costs $29.99 USD each year, the Ultimate Monthly subscription costs
                $3.99 USD each month. This will be automatically converted to your own currency by {{ getShortName() }}.
                For more information about the annual payment or how to cancel auto renewing, read "Payment &
                Cancellation".
            </p>
            <p>
                The payment will be charged to your {{ getShortName() }} account at confirmation of purchase. 24 hours
                before the end of each subscription period (one year) you'll automatically be charged the same amount as
                you've paid for the initial purchase. After renewing, your subscription will be valid for another year.
            </p>
            <p>
                You can turn off auto-renew at any time from your {{ getShortName() }} account settings; you must do so
                at least 24 hours before the end of the current subscription period to avoid being billed for the next
                cycle.
            </p>
            <!-- <p>
                You can turn off auto-renew at any time from your {{ getShortName() }} account settings; you must do so
                at least 24 hours before the end of the current subscription period to avoid being billed for the next
                cycle.&nbsp;<br />
                Any unused portion of a free trial period, if offered, will be forfeited when the user purchases a
                subscription to that publication, where applicable.
            </p> -->
            <h2>Updates</h2>
            <p>
                DartCounter periodically releases updates that may fix bugs or improve the functioning of the App.
                Available updates shall be notified to you through notifications provided by {{ getLongName() }}, but it
                is your responsibility to monitor these notifications. Updates are applied similarly through these
                platforms, which requires an active internet connection. Installing updates requires your separate
                consent. A proper application of updates requires the availability and good functioning of
                {{ getLongName() }}, over which DartCounter has no control. DartCounter is not responsible or liable for
                an incorrect execution of updates. No liability exists for any damage as a result of bugs fixed in an
                update you did not install. An update can set terms additional to or different from the present
                document. You will be notified in advance and you will be allowed to refuse such updates. Installing
                such an update constitutes your acceptance of the additional or different terms, which shall then be
                incorporated into this agreement.
            </p>
            <h2>Support</h2>
            <p>
                You are solely responsible for installing and activating the App and the accompanying Web service.
                DartCounter shall offer a reasonable level of support through the website and/or e-mail (or other
                channels announced to you). DartCounter however makes no guarantees that any problems or requests raised
                by you shall actually be addressed.
            </p>
            <h2>Third Parties</h2>
            <p>
                The DartCounter software is free of use. To get more functionality, a user needs to register an account.
                This extensive functionality can become even more by making an in-app purchase. The in-app purchase will
                be completely optional and will support DartCounter to make more new functionality and improve the
                software.
            </p>
            <p>
                To keep the app free without advertising, any registered user agrees to the DartCounter official
                partners to give them a first contact. A third party of DartCounter will be darts-related and
                won&rsquo;t spam your mailbox. If you got an email from a partner of DartCounter, you are able to
                immediately cancel their communication by telling them you&rsquo;re not interested.
            </p>
            <p>
                DartCounter intends to give as much functionality for the lowest price. That&rsquo;s why a third party
                could receive your for example your entered full name and emailadress. DartCounter will contractually
                tell a third party not to spam any user with too many emails or irrelevant information.
            </p>
            <p>Whatever a third party communicates, will not automatically also be the opinion of DartCounter.</p>
            <h2>Guarantees and liability</h2>
            <p>
                DartCounter guarantees it will use its best efforts to investigate any reported bugs as soon as is
                practical and to repair such bug or create a workaround (DartCounter may delay repairing bugs with
                limited impact until the next planned update); but nothing else. Except in cases of intentional
                misconduct or gross negligence or the violation of a warranty granted above DartCounter is not liable
                towards you, regardless of ground, for any damages in connection with DartCounter. No liability exists
                in case damages were not reported timely after discovery to DartCounter. A term of two months after
                discovery shall be regarded as timely in any case. In addition, no liability exists if the cause of
                damage is beyond the control of DartCounter (force majeure). The App communicates via the internet with
                a server under control of DartCounter to provide the complete service. DartCounter uses its best efforts
                to have this server available at all times, but makes no guarantees in this regard. From time-to-time
                DartCounter can make updates to the server, which may lead to a temporary unavailability. DartCounter
                will do its best to inform you in advance about unavailability. Everything in this document regarding
                updates, support and liability applies equally to the server. No liability exists for damages directly
                or indirectly resulting from incorrect functioning of {{ getLongName() }}.
            </p>
            <h2>Term of the agreement</h2>
            <p>
                This agreement enters into force when you start use of the App and remains in force until terminated.
                The agreement may be terminated by both you and DartCounter at any time by providing one month's notice.
                This agreement terminates automatically and immediately in case you enter into bankruptcy, apply for a
                suspension of payments, your assets are seized, you pass away, or in case enter into liquidation, legal
                dissolution or winding-up. After termination of the agreement (regardless of reason) you must cease all
                use of DartCounter. In addition, you must remove all copies (including backup copies) of DartCounter
                from all computer systems under your control.
            </p>
            <p>
                Dutch law applies to this end-user license agreement. Unless dictated otherwise by mandatory law, all
                disputes arising in connection with this agreement shall be brought before the competent Dutch court for
                the principal place of business of DartCounter. A finding that any particular provision of this
                agreement is legally void shall not affect the validity of the entire agreement. In such a case the
                parties shall determine a replacement provision that is legally valid and approximates the intent of the
                offending provision as much as possible. DartCounter may assign its rights and obligations under this
                agreement to a third party that acquires the relevant business or the copyrights to DartCounter from it.
            </p>
        </div>
    </div>
</ion-content>
