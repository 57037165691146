import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InAppBrowser, InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { ModalController } from '@ionic/angular';
import { ModalOptions } from '@ionic/core';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { DartCounterPreferenceService } from 'dc-core/dc-services/preference/preference.service';
import { take } from 'rxjs';
import { GA_EVENTACTIONS, GA_EVENTCATEGORIES } from 'src/app/app.globals';
import { PurchaseService } from 'src/providers/PurchaseService';
import { AuthService } from 'src/services/auth.service';

import { AgreementDialogComponent } from '../agreement/agreement';
import { PolicyDialogComponent } from '../policy/policy';

@Component({
    selector: 'app-discount-dialog',
    templateUrl: 'discount.html',
    styleUrls: ['discount.scss'],
})
export class DiscountDialogComponent {
    recurring = 'yearly';
    public title: any;
    public DiscountBG;
    public pricing;

    constructor(
        private ga: DartCounterAnalyticsService,
        private iab: InAppBrowser,
        public auth: AuthService,
        public modal: ModalController,
        private _modal: ModalController,
        public route: ActivatedRoute,
        private preferences: DartCounterPreferenceService,
        public purchases: PurchaseService
    ) {
        this.route.queryParams.pipe(take(1)).subscribe((params) => {
            this.title = params.title;
            this.ga.trackView('Ultimate subscription dialog');

            this.pricing = this.preferences.pricing;
        });
    }

    public DismissModal(returnValue: any = null): void {
        this.modal.dismiss(returnValue);
    }

    public setRecurring(value) {
        this.recurring = value;
    }

    public BuyUltimate() {
        let url = '';
        // url += 'https://dartcounter.net/' + this.preferences.lang;
        url += 'https://dartcounter.net/' + this.preferences.lang;
        if (this.recurring === 'monthly') {
            this.ga.trackEvent(GA_EVENTCATEGORIES.DISCOUNT, GA_EVENTACTIONS.BUYMONTHLY);
            url += '/dcmonthly';
        } else {
            this.ga.trackEvent(GA_EVENTCATEGORIES.DISCOUNT, GA_EVENTACTIONS.BUYYEARLY);
            url += '/dcdiscount';
        }

        url += '/' + this.auth.user.id;
        url += '/' + this.auth.getAccessToken();

        this.iab.create(url, '_system', {
            location: 'yes',
        } as InAppBrowserOptions);
    }

    OpenAgreementDialog() {
        this.ga.trackEvent(GA_EVENTCATEGORIES.DISCOUNT, GA_EVENTACTIONS.TERMSOFUSE);
        this._modal
            .create({
                component: AgreementDialogComponent,
            } as ModalOptions)
            .then((elem) => elem.present());
    }

    OpenPolicyDialog() {
        this.ga.trackEvent(GA_EVENTCATEGORIES.DISCOUNT, GA_EVENTACTIONS.PRIVACYPOLICY);
        this._modal
            .create({
                component: PolicyDialogComponent,
            } as ModalOptions)
            .then((elem) => elem.present());
    }
}
