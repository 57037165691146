<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="dismiss()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title" [translate]="'THEME'" i18n="@@THEME">Theme</div>
    </div>
    <div class="slide-modal-content text-black">
        <div class="flex flex-col">
            <div class="flex items-center mb-4 cursor-pointer" (click)="changeTheme('system')">
                <div
                    class="w-5 h-5 rounded-full border-2 border-gray-600 flex items-center justify-center bg-gray-50 mr-2">
                    @if (themeService.selectedTheme() === 'system') {
                        <div class="w-2 h-2 bg-orange rounded-full"></div>
                    }
                </div>
                <label [translate]="'SYSTEM_SETTINGS'" i18n="@@SYSTEM_SETTINGS">System settings</label>
            </div>
            <div class="flex items-center mb-4 cursor-pointer" (click)="changeTheme('light')">
                <div
                    class="w-5 h-5 rounded-full border-2 border-gray-600 flex items-center justify-center bg-gray-50 mr-2">
                    @if (themeService.selectedTheme() === 'light') {
                        <div class="w-2 h-2 bg-orange rounded-full"></div>
                    }
                </div>
                <label [translate]="'LIGHT_MODE'" i18n="@@LIGHT_MODE">Light mode</label>
            </div>
            <div class="flex items-center mb-4 cursor-pointer" (click)="changeTheme('dark')">
                <div
                    class="w-5 h-5 rounded-full border-2 border-gray-600 flex items-center justify-center bg-gray-50 mr-2">
                    @if (themeService.selectedTheme() === 'dark') {
                        <div class="w-2 h-2 bg-orange rounded-full"></div>
                    }
                </div>
                <label [translate]="'DARK_MODE'" i18n="@@DARK_MODE">Dark mode</label>
            </div>
        </div>
    </div>
</div>
