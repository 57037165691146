<div class="flex items-center space-x-1 overflow-x-auto">
    <div class="flex items-center space-x-0.5">
        <app-icon icon="star" size="small" class="text-orange"></app-icon>
        <div
            class="font-bebas-neue"
            [ngClass]="mode === 'dark' ? 'text-neutral-100' : 'text-neutral-900'"
            [class.text-xs]="size === 'xs'"
            [class.text-sm]="size === 'sm'"
            [class.text-base]="size === 'base'">
            {{ player.rating || 0 | number: '1.1-1' }}
            @if (reviewCount) {
                ({{ reviewCount }})
            }
        </div>
    </div>
    <div class="flex items-center space-x-0.5">
        <app-icon icon="three_dots" size="small" class="text-orange"></app-icon>
        <div
            class="font-bebas-neue"
            [ngClass]="mode === 'dark' ? 'text-neutral-100' : 'text-neutral-900'"
            [class.text-xs]="size === 'xs'"
            [class.text-sm]="size === 'sm'"
            [class.text-base]="size === 'base'">
            {{ player.two_month_average || 0 | number: '1.2-2' }}
        </div>
    </div>
</div>
