<modal-content>
    <app-page-header mainNavigation="custom" color="transparent">
        <div title [translate]="'UPGRADE_REASONS'" i18n="@@UPGRADE_REASONS">Upgrade reasons</div>
        <div right class="right-page-header">
            <app-icon icon="close" (click)="close()"></app-icon>
        </div>
    </app-page-header>

    <ion-col>
        <div
            style="padding: 0 1rem; margin-bottom: 1.5rem"
            class="text-center"
            [translate]="'WHY_UPGRADE_TO_ULTIMATE'"
            i18n="@@WHY_UPGRADE_TO_ULTIMATE">
            What was your main reason to upgrade to Ultimate?
        </div>

        <ion-list *ngIf="upgradeReasons.length" style="background: transparent">
            <div style="display: flex; flex-direction: column" class="space-y-2">
                <div class="px-4" *ngFor="let reason of upgradeReasons">
                    <app-checkbox
                        [model]="reason.selected"
                        color="black"
                        position="right"
                        space="between"
                        (itemChange)="toggleAnswer(reason)">
                        <div class="text-base text-white">{{ reason.title }}</div>
                    </app-checkbox>
                    <div *ngIf="reason.type === 'open_option' && reason.selected" style="padding: 0 0.5rem">
                        <textarea
                            class="input-container white-placeholder"
                            style="width: 100%; height: 5rem; margin-top: 0.5rem"
                            name="reason"
                            [(ngModel)]="reason.explanation"
                            [placeholder]="reasonPlaceholder"
                            autocapitalize="on"></textarea>
                    </div>
                </div>
            </div>
        </ion-list>
    </ion-col>
</modal-content>

<ion-footer class="ion-no-border">
    <ion-toolbar class="footer" style="height: 72px">
        <div class="bottomCTA" style="position: relative; background: var(--orange)" (click)="send()">
            <p class="text-center" style="height: 72px" [translate]="'SEND'" i18n="@@SEND">Send</p>
        </div>
    </ion-toolbar>
</ion-footer>
