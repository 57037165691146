<ng-container *ngIf="0" i18n="@@GUEST_NAME_OR_NICKNAME">Guest name or nickname</ng-container>

<div class="flex h-full flex-col overflow-y-hidden overscroll-none bg-white pt-safe-offset-4">
    <div class="relative flex items-center px-safe-offset-4">
        <ion-buttons slot="start">
            @if (!playerTypeToAdd) {
                <button
                    class="ml-1 text-center text-sm font-bold text-black"
                    (click)="close()"
                    [translate]="'CANCEL'"
                    i18n="@@CANCEL">
                    Cancel
                </button>
            } @else {
                <div class="flex items-center" (click)="back()">
                    <app-icon icon="arrow_back_ios" class="text-black mr-2" size="small"></app-icon>
                    <div class="text-center text-sm font-bold text-black" [translate]="'BACK'" i18n="@@BACK">Back</div>
                </div>
            }
        </ion-buttons>
        <div class="absolute -top-1 left-0 my-auto w-full text-center font-bebas-neue text-2xl text-black">
            {{ title }}
        </div>
    </div>

    <div class="mt-10 flex flex-1 flex-col pb-safe-offset-5 px-safe-offset-5 overflow-hidden">
        <!-- Add player -->
        @if (!playerTypeToAdd) {
            <div>
                <div
                    class="text-xl font-bold text-black"
                    [translate]="'CHOOSE_OPTION_TO_ADD_PLAYER'"
                    i18n="@@CHOOSE_OPTION_TO_ADD_PLAYER">
                    Choose an option to add the player
                </div>
                <div class="mt-6 space-y-2">
                    <button
                        class="w-full flex items-center justify-between rounded bg-neutral-50 py-3 pl-4 pr-2"
                        (click)="setPlayerTypeToAdd(addPlayerTypes.guest)">
                        <div
                            class="font-semibold leading-none text-black"
                            [translate]="'GUEST_PLAYER'"
                            i18n="@@GUEST_PLAYER">
                            Guest player
                        </div>
                        <app-icon icon="keyboard_arrow_right" class="!text-neutral-200"></app-icon>
                    </button>
                    <button
                        class="w-full flex items-center justify-between rounded bg-neutral-50 py-3 pl-4 pr-2"
                        (click)="setPlayerTypeToAdd(addPlayerTypes.friend)">
                        <div
                            class="font-semibold leading-none text-black"
                            [translate]="'SEARCH_FRIEND'"
                            i18n="@@SEARCH_FRIEND">
                            Search friend
                        </div>
                        <app-icon icon="keyboard_arrow_right" class="!text-neutral-200"></app-icon>
                    </button>
                    <button
                        class="w-full flex items-center justify-between rounded bg-neutral-50 py-3 pl-4 pr-2"
                        (click)="setPlayerTypeToAdd(addPlayerTypes.existing)">
                        <div
                            class="font-semibold leading-none text-black"
                            [translate]="'EXISTING_USER_ACCOUNT'"
                            i18n="@@EXISTING_USER_ACCOUNT">
                            Existing user account
                        </div>
                        <app-icon icon="keyboard_arrow_right" class="!text-neutral-200"></app-icon>
                    </button>
                    <button
                        class="w-full flex items-center justify-between rounded bg-neutral-50 py-3 pl-4 pr-2"
                        (click)="setPlayerTypeToAdd(addPlayerTypes.own)">
                        <div
                            class="font-semibold leading-none text-black"
                            [translate]="'MY_OWN_ACCOUNT'"
                            i18n="@@MY_OWN_ACCOUNT">
                            My own account
                        </div>
                        <app-icon icon="keyboard_arrow_right" class="!text-neutral-200"></app-icon>
                    </button>
                </div>
            </div>
        }
        <form
            #form="ngForm"
            [formGroup]="addPlayerForm"
            class="flex h-full flex-col overflow-hidden"
            (ngSubmit)="addPlayerForm.valid && addPlayer()">
            @switch (playerTypeToAdd) {
                @case (addPlayerTypes.guest) {
                    <ng-container
                        *ngTemplateOutlet="guestForm; context: { form, playerForm: addPlayerForm }"></ng-container>
                }
                @case (addPlayerTypes.friend) {
                    <ng-container *ngTemplateOutlet="friendForm; context: { form }"></ng-container>
                }
                @case (addPlayerTypes.existing) {
                    <ng-container *ngTemplateOutlet="existingUserForm; context: { form }"></ng-container>
                }
            }

            <div class="pt-4">
                @if (
                    (playerTypeToAdd !== addPlayerTypes.existing && playerTypeToAdd) ||
                    (playerTypeToAdd === addPlayerTypes.existing && addPlayerForm.get('existingUser').value)
                ) {
                    <app-primary-button
                        type="submit"
                        class="block"
                        size="normal"
                        addedClasses="w-full"
                        [translate]="'ADD_PLAYER'"
                        i18n="@@ADD_PLAYER">
                        Add player
                    </app-primary-button>
                }
            </div>
        </form>
    </div>
</div>

<ng-template #player let-user="user">
    <app-profile-photo
        [size]="40"
        [ultimate]="user.is_ultimate"
        [url]="user.profile?.profile_photo_url"></app-profile-photo>
    <div class="space-y-1 text-left">
        <div class="font-bold">{{ user.full_name }}</div>
        @if (user.profile?.nickname) {
            <div class="text-xs font-semibold">{{ user.profile?.nickname }}</div>
        }
    </div>
</ng-template>

<ng-template #guestForm let-form="form" let-playerForm="playerForm">
    <div class="text-xl font-bold text-black" [translate]="'NAME_OF_THIS_PLAYER'" i18n="@@NAME_OF_THIS_PLAYER">
        What is the name of this player?
    </div>
    <div class="mt-6 flex-1" [formGroup]="playerForm">
        <input
            class="dialog-input"
            placeholder="{{ 'GUEST_NAME_OR_NICKNAME' | translate }}"
            formControlName="guestName" />
        @if (form.submitted && addPlayerForm.get('guestName').errors) {
            <app-validation-label textColor="black" [noAbsolute]="true">
                <span [translate]="'GUEST_NAME_REQUIRED'" i18n="@@GUEST_NAME_REQUIRED"> Guest name is required </span>
            </app-validation-label>
        }
    </div>
</ng-template>

<ng-template #friendForm let-form="form">
    <div class="text-xl font-bold text-black" [translate]="'NAME_OF_THIS_FRIEND'" i18n="@@NAME_OF_THIS_FRIEND">
        What is the name of this friend?
    </div>
    <div class="mt-6 flex-1 flex flex-col overflow-hidden">
        <ion-searchbar
            class="!p-0 text-black"
            mode="ios"
            name="friendSearch"
            ngDefaultControl
            (ionChange)="searchFriends($event)"
            placeholder="{{ 'SEARCH' | translate }}"
            debounce="600"></ion-searchbar>
        @if (form.submitted && addPlayerForm.get('friend').errors) {
            <app-validation-label textColor="black" [noAbsolute]="true">
                <span [translate]="'FRIEND_NEEDS_TO_BE_SELECTED'" i18n="@@FRIEND_NEEDS_TO_BE_SELECTED">
                    Friend needs to be selected
                </span>
            </app-validation-label>
        }
        <div class="flex-1 mt-4 divide-y divide-neutral-50 overflow-y-auto">
            @for (user of friends; track user.id) {
                <button
                    type="button"
                    class="flex items-center w-full space-x-2 p-2 text-black cursor-pointer"
                    [ngClass]="{
                        'rounded-md bg-neutral-900 text-white': addPlayerForm.get('friend').value === user
                    }"
                    (click)="selectFriend(user)">
                    <ng-container *ngTemplateOutlet="player; context: { user: user }"></ng-container>
                </button>
            }
        </div>
    </div>
</ng-template>

<ng-template #existingUserForm>
    <form
        #form="ngForm"
        class="flex h-full flex-col"
        [formGroup]="loginForm"
        (ngSubmit)="loginForm.valid && loginExistingUser()">
        <div class="text-xl font-bold text-black" [translate]="'LOG_IN_ACCOUNT'" i18n="@@LOG_IN_ACCOUNT">
            Log in account
        </div>
        <div class="mt-6 flex-1 space-y-2">
            @if (!addPlayerForm.get('existingUser').value) {
                <div class="space-y-2">
                    <input
                        [ngClass]="{
                            '!border-red': form.submitted && loginForm.get('login').errors
                        }"
                        class="dialog-input"
                        placeholder="{{ 'EMAIL' | translate }}"
                        formControlName="login" />
                    @if (form.submitted && loginForm.get('login').errors) {
                        <app-validation-label textColor="black" [noAbsolute]="true">
                            <span [translate]="'EMAIL_REQUIRED'" i18n="@@EMAIL_REQUIRED"> Email is required </span>
                        </app-validation-label>
                    }
                    <input
                        [ngClass]="{
                            '!border-red': form.submitted && loginForm.get('password').errors
                        }"
                        type="password"
                        class="dialog-input"
                        placeholder="{{ 'PASSWORD' | translate }}"
                        formControlName="password" />
                    @if (form.submitted && loginForm.get('password').errors) {
                        <app-validation-label textColor="black" [noAbsolute]="true">
                            <span [translate]="'PASSWORD_REQUIRED'" i18n="@@PASSWORD_REQUIRED">
                                Password is required
                            </span>
                        </app-validation-label>
                    }
                </div>
            } @else {
                <div>
                    <div
                        class="flex items-center space-x-2 p-2 text-black cursor-pointer rounded-md bg-neutral-900 text-white">
                        <ng-container
                            *ngTemplateOutlet="
                                player;
                                context: { user: addPlayerForm.get('existingUser').value }
                            "></ng-container>
                    </div>
                </div>
            }
            <div class="pt-4">
                @if (!addPlayerForm.get('existingUser').value) {
                    <app-primary-button
                        type="submit"
                        class="block"
                        size="normal"
                        addedClasses="w-full"
                        [translate]="'LOG_IN'"
                        i18n="@@LOG_IN">
                        Log in
                    </app-primary-button>
                } @else {
                    <app-primary-button
                        type="button"
                        size="small"
                        addedClasses="w-full"
                        fill="cle qar"
                        color="white"
                        [underline]="true"
                        (click)="resetExistingPlayer(form)"
                        [translate]="'LOGOUT'"
                        i18n="@@LOGOUT">
                        Logout
                    </app-primary-button>
                }
            </div>
        </div>
    </form>
</ng-template>
