<ion-content fullscreen class="bg-white-pattern ion-text-black">
    <app-page-header mainNavigation="menu">
        <div title [translate]="'CONTACT'" i18n="@@CONTACT">Contact</div>
    </app-page-header>
    <div class="p-4" appWebContainer>
        <p class="text-center my-3" [translate]="'ANY_QUESTIONS_CONTACT_US'" i18n="@@ANY_QUESTIONS_CONTACT_US">
            Any questions, new ideas or other feedback? Contact us!
        </p>

        <form [formGroup]="contactForm" (ngSubmit)="send()" #form="ngForm">
            <div class="grid grid-cols-2 gap-x-3">
                <div>
                    <div class="dialog-input-label">
                        {{ 'FIRST_NAME' | translate }}
                    </div>
                    @if (!isGuest) {
                        <span>{{ user.first_name }}</span>
                    }
                    @if (isGuest) {
                        <input
                            type="text"
                            [ngClass]="{
                                '!border-red': form.submitted && contactForm.get('firstName').errors,
                                '!py-2': isWeb
                            }"
                            class="dialog-input"
                            placeholder="{{ 'FIRST_NAME' | translate }}"
                            formControlName="firstName"
                            required />
                    }
                </div>
                <div>
                    <div class="dialog-input-label">
                        {{ 'LAST_NAME' | translate }}
                    </div>
                    @if (!isGuest) {
                        <span>{{ user.last_name }}</span>
                    }
                    @if (isGuest) {
                        <input
                            type="text"
                            [ngClass]="{
                                '!border-red': form.submitted && contactForm.get('lastName').errors,
                                '!py-2': isWeb
                            }"
                            class="dialog-input"
                            placeholder="{{ 'LAST_NAME' | translate }}"
                            formControlName="lastName"
                            required />
                    }
                </div>
            </div>
            <div class="mt-2">
                <div class="dialog-input-label">
                    {{ 'EMAIL' | translate }}
                </div>
                @if (user?.email) {
                    <span>{{ user.email }}</span>
                }
                @if (!user?.email) {
                    <input
                        type="text"
                        [ngClass]="{ '!border-red': form.submitted && contactForm.get('email').errors, '!py-2': isWeb }"
                        class="dialog-input"
                        placeholder="{{ 'EMAIL' | translate }}"
                        formControlName="email"
                        [readonly]="user && user.email" />
                }
            </div>
            <div class="mt-2">
                <div [translate]="'CATEGORY'" i18n="@@CATEGORY" class="dialog-input-label">Category</div>
                <app-select
                    class="block mt-1"
                    [initialValue]="contactForm.get('category').value"
                    [options]="categories"
                    (selectionChange)="updateCategory($event)"
                    [error]="form.submitted && contactForm.get('category').errors"
                    mode="dialog-input"
                    size="full"></app-select>
            </div>
            @if (contactForm.get('category').value === 'other') {
                <div class="mt-2">
                    <div class="dialog-input-label">
                        {{ 'SUBJECT' | translate }}
                    </div>
                    <input
                        type="text"
                        [ngClass]="{
                            '!border-red': form.submitted && contactForm.get('subject').errors,
                            '!py-2': isWeb
                        }"
                        class="dialog-input"
                        placeholder="{{ 'SUBJECT' | translate }}"
                        formControlName="subject"
                        required />
                </div>
            }
            <div class="mt-2">
                <div class="dialog-input-label">
                    {{ 'MESSAGE' | translate }}
                </div>
                <textarea
                    class="dialog-input w-full mt-1"
                    style="height: 8rem"
                    type="text"
                    name="message"
                    [ngClass]="{ '!border-red': form.submitted && contactForm.get('message').errors, '!py-2': isWeb }"
                    formControlName="message"
                    placeholder="{{
                        (contactForm.get('category').value === 'mastercaller'
                            ? 'ENTER_YOUR_NAME_HERE'
                            : 'SEND_A_MESSAGE_DOTS'
                        ) | translate
                    }}"
                    required></textarea>
            </div>
            <app-primary-button
                class="block w-full mt-2"
                size="large"
                [translate]="'SEND'"
                i18n="@@SEND"
                color="blue"
                type="submit">
                Send
            </app-primary-button>

            <div class="text-neutral-400 text-xs mt-4 text-center">
                This site is protected by reCAPTCHA and the Google
                <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                <a href="https://policies.google.com/terms">Terms of Service</a> apply.
            </div>
        </form>
    </div>
</ion-content>
