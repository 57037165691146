<div
    class="flex relative bottom-0 w-full flex-col overflow-y-hidden overscroll-none rounded-t-lg bg-gray-pattern text-white">
    <div class="relative flex items-center rounded-t-lg py-4 px-safe-offset-4">
        <ion-buttons slot="start">
            <div
                class="ml-1 text-center text-sm font-bold text-white cursor-pointer"
                [translate]="'CLOSE'"
                i18n="@@CLOSE"
                (click)="dismiss()">
                Close
            </div>
        </ion-buttons>
        <div
            class="absolute left-0 right-0 my-auto w-full text-center font-bebas-neue text-2xl text-white"
            [translate]="'EDIT_SCORES'"
            i18n="@@EDIT_SCORES">
            Edit score
        </div>
    </div>
    <div class="flex flex-1 flex-col mx-4 overflow-y-auto pb-safe-offset-4 text-black">
        <div class="my-4">
            <div class="zoomed-omni-dart w-1/2 mx-auto">
                @if (svgContent) {
                    <div class="zoomed-container">
                        <div [innerHTML]="svgContent"></div>
                    </div>
                    <div class="bottomPart">
                        <div
                            class="relative w-full border-2 border-neutral-50 bg-neutral-50 px-2 pt-1"
                            style="border-radius: 0 0 9px 9px">
                            <app-keyboard-dart class="block w-2/3 text-black"></app-keyboard-dart>
                            <div class="in-game-score-field">
                                {{ editablePoint?.amount * editablePoint?.multiplier || '' }}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>

        <app-new-single-dart-keyboard
            class="block flex-none h-[20rem]"
            (setSingleDart)="setNewSingleDartValue($event)"
            (asetSingleDart)="setSingleDart.emit($event)"
            [myTurn]="true"></app-new-single-dart-keyboard>

        <div class="button-container">
            <app-primary-button
                type="submit"
                size="normal"
                addedClasses="w-full"
                color="orange"
                (click)="saveEditedScore()"
                [translate]="'CONFIRM'"
                i18n="@@CONFIRM">
                Confirm
            </app-primary-button>
        </div>
    </div>
</div>
