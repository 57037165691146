<ion-content fullscreen class="bg-white-pattern ion-black-white">
    <app-page-header mainNavigation="back" defaultBack="settings">
        <div title [translate]="'SUBSCRIPTIONS'" i18n="@@SUBSCRIPTIONS">Subscriptions</div>
    </app-page-header>
    <div appWebContainer>
        @if (!auth.user.is_ultimate) {
            <p class="text-center text-black" [translate]="'NO_ACTIVE_SUBSCRIPTIONS'" i18n="@@NO_ACTIVE_SUBSCRIPTIONS">
                You don't have any active subscriptions
            </p>
        }
        @if (!auth.user.is_ultimate) {
            <div class="flex items-center justify-center mt-3">
                <div
                    class="flex items-center justify-center rounded bg-blue py-1 pl-2 pr-0.5 !text-white"
                    (click)="openSubscriptionDialog()">
                    <div class="text-xs" [translate]="'UPGRADE_BTN'" i18n="@@UPGRADE_BTN">Upgrade</div>
                    <app-icon icon="arrow_forward_ios" size="extrasmall"></app-icon>
                </div>
            </div>
        }

        @if (totalTrans > 0) {
            <app-list-switch
                class="w-full"
                [item]="segment"
                [items]="segments"
                (itemChange)="changeSegment($event)"></app-list-switch>
        }

        @if (segment === 'subscriptions') {
            @for (subscription of subscriptions; track subscription) {
                <div class="col-span-1 mt-3 flex flex-col">
                    <div class="global-lobby-shadow rounded-t-md bg-black px-4 py-2.5">
                        <span class="status text-sm {{ getSubscriptionStatusColor(subscription.status) }} uppercase">
                            {{ subscription.status | subscriptionStatus }}
                        </span>
                    </div>
                    <div class="global-lobby-shadow space-y-3 rounded-b-md bg-neutral-900 px-4 py-2 text-sm">
                        <div class="flex justify-between">
                            @if (!subscription.trial_ends_at) {
                                <span class="uppercase" [translate]="'LAST_PAYMENT'" i18n="@@LAST_PAYMENT"
                                    >Last payment</span
                                >
                                <span>{{
                                    subscription.cycle_started_at | date: 'd MMM y' : '' : translateService.currentLang
                                }}</span>
                            }
                        </div>
                        <div class="flex justify-between">
                            @if (!subscription.ends_at && !subscription.trial_ends_at) {
                                <span class="uppercase" [translate]="'NEXT_PAYMENT'" i18n="@@NEXT_PAYMENT"
                                    >Next payment</span
                                >
                                <span>{{
                                    subscription.cycle_ends_at | date: 'd MMM y' : '' : translateService.currentLang
                                }}</span>
                            }
                        </div>
                        <div class="flex justify-between">
                            @if (subscription.ends_at && !subscription.trial_ends_at) {
                                <span class="uppercase" [translate]="'ULTIMATE_UNTIL'" i18n="@@ULTIMATE_UNTIL">
                                    Ultimate until
                                </span>
                                <span>{{
                                    subscription.ends_at | date: 'd MMM y' : '' : translateService.currentLang
                                }}</span>
                            }
                        </div>
                        <div class="flex justify-between">
                            @if (subscription.trial_ends_at) {
                                <span class="uppercase" [translate]="'TRIAL_UNTIL'" i18n="@@TRIAL_UNTIL"
                                    >Trial until</span
                                >
                                <span>{{
                                    subscription.trial_ends_at | date: 'd MMM y' : '' : translateService.currentLang
                                }}</span>
                            }
                        </div>
                        <div class="flex flex-col mt-5 text-lg">
                            @if (subscription.plan.interval_period === 'month') {
                                <span [translate]="'ULTIMATE_MONTHLY'" i18n="@@ULTIMATE_MONTHLY">
                                    Ultimate Monthly
                                </span>
                            }
                            @if (subscription.plan.interval_period === 'year') {
                                <span [translate]="'ULTIMATE_YEARLY'" i18n="@@ULTIMATE_YEARLY"> Ultimate Yearly </span>
                            }
                            <span>{{ subscription.plan.price | euroPrice }}</span>
                        </div>
                        @if (subscription.payment_provider) {
                            <div class="flex justify-between mt-5 text-sm">
                                <span class="uppercase" [translate]="'PAYMENT_PROVIDER'" i18n="@@PAYMENT_PROVIDER">
                                    Payment provider
                                </span>
                                <div class="flex rounded-3xl px-2 py-1 bg-white">
                                    <img
                                        class="w-14"
                                        src="assets/images/payment-providers/{{ subscription.payment_provider }}.svg" />
                                </div>
                            </div>
                        }
                        @if (
                            subscription.active &&
                            !subscription.on_grace_period &&
                            subscription.id === ultimateSubscription?.id
                        ) {
                            <div class="flex mt-5 text-red-500 cursor-pointer" (click)="cancelSubscription()">
                                <span [translate]="'CANCEL_SUBSCRIPTION'" i18n="@@CANCEL_SUBSCRIPTION"
                                    >Cancel subscription</span
                                >
                            </div>
                        }
                    </div>
                </div>
            }
            @if (subscriptions !== null && total !== null && subscriptions.length < total) {
                <ion-infinite-scroll (ionInfinite)="loadSubscriptions($event)">
                    <ion-row class="mt-5">
                        <ion-col class="text-center">
                            <ion-spinner class="w-10 h-10" color="light" name="crescent"></ion-spinner>
                        </ion-col>
                    </ion-row>
                </ion-infinite-scroll>
            }
        }

        @if (segment === 'transactions') {
            @for (transaction of transactions; track transaction) {
                <div class="col-span-1 mt-3 flex flex-col">
                    <div class="global-lobby-shadow rounded-t-md bg-black px-4 py-2.5">
                        <div class="grid grid-cols-11 items-center">
                            <div class="col-span-8 flex items-center">
                                <span
                                    class="status text-sm {{
                                        getTransactionStatusColor(transaction.status)
                                    }} uppercase">
                                    {{ transaction.status | transactionStatus }}
                                </span>
                            </div>
                            <div class="col-span-3 text-center text-sm text-neutral-400">
                                {{ transaction.created_at | date: 'dd MMM yyyy' : '' : translateService.currentLang }}
                                <br />{{ transaction.created_at | date: 'H:mm' }}
                            </div>
                        </div>
                    </div>
                    <div class="global-lobby-shadow space-y-3 rounded-b-md bg-neutral-900 px-4 py-2 text-sm">
                        <div class="flex flex-col text-lg">
                            <span>{{ transaction.price | euroPrice }}</span>
                        </div>
                        <div class="flex justify-between mt-5 text-sm">
                            <span class="uppercase" [translate]="'PAYMENT_PROVIDER'" i18n="@@PAYMENT_PROVIDER">
                                Payment provider
                            </span>
                            <div class="flex rounded-3xl px-2 py-1 bg-white">
                                <img
                                    class="w-14"
                                    src="assets/images/payment-providers/{{ transaction.payment_provider }}.svg" />
                            </div>
                        </div>
                        @if (transaction.payment_method) {
                            <div class="flex justify-between mt-5 text-sm">
                                <span class="uppercase" [translate]="'PAYMENT_METHOD'" i18n="@@PAYMENT_METHOD">
                                    Payment method
                                </span>
                                <div class="flex rounded-3xl px-2 py-1 bg-white">
                                    <img
                                        class="w-14"
                                        src="assets/images/payment-providers/{{ transaction.payment_method }}.svg" />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
            @if (transactions !== null && totalTrans !== null && transactions.length < totalTrans) {
                <ion-infinite-scroll (ionInfinite)="loadTransactions($event)">
                    <ion-row class="mt-20">
                        <ion-col class="text-center">
                            <ion-spinner class="w-10 h-10" color="light" name="crescent"></ion-spinner>
                        </ion-col>
                    </ion-row>
                </ion-infinite-scroll>
            }
        }

        @if (!isWeb) {
            <div
                class="flex flex-col items-center justify-center text-center border-1 border-solid border-white rounded-md bg-gray-50 p-4 mt-5 global-lobby-shadow mx-4 text-black outline outline-1 outline-white">
                <p
                    [translate]="'RESTORE_PURCHASE_WHEN_NO_SUBSCRIPTIONS'"
                    i18n="@@RESTORE_PURCHASE_WHEN_NO_SUBSCRIPTIONS">
                    Is your subscription not visible in the app? Try to restore your purchase
                </p>
                <app-primary-button
                    color="black"
                    fill="outline"
                    size="extrasmall"
                    [translate]="'RESTORE_PURCHASE'"
                    i18n="@@RESTORE_PURCHASE"
                    (click)="purchases.restore(true)"
                    class="mt-2">
                    Restore purchase
                </app-primary-button>
            </div>
        }
    </div>
</ion-content>
