/* eslint-disable max-len */
import { Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrivateChatApiService } from '@dc-api/private-chat.api.service';
import { ChatConversation, ChatMessage } from '@dc-core/dc-backend/dc-classes';
import { AlertPayload, DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { ModalController, NavController, PopoverController } from '@ionic/angular';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { AuthService } from '@services/auth.service';
import { PopoverService } from '@services/popover.service';
import { take } from 'rxjs';
import {
    ChatsConversationOptionsPopoverComponent,
    ChatsConversationOptionsPopoverResponse,
} from 'src/app/modules/chats/components/chats-conversation-options-popover/chats-conversation-options-popover.component';
import { EchoService } from 'src/app/services/echo.service';
import { PromptDialogComponent } from 'src/dialogs/prompt/prompt.dialog';
import { ReportUserDialogComponent } from 'src/dialogs/report-user/report-user.dialog';
import { MaintainHeightDirective } from 'src/directives/maintain-height.directive';
import { ScrollToBottomDirective } from 'src/directives/scroll-to-bottom.directive';
import { environment } from 'src/environments/environment';
import { ProfileDialogComponent } from 'src/pages/online/profileDialog/profileDialog';

@Component({
    selector: 'app-private-chat-conversation',
    templateUrl: 'private-chat-conversation.component.html',
})
export class PrivateChatConversationComponent implements OnInit, OnDestroy {
    public auth: AuthService = inject(AuthService);

    private route: ActivatedRoute = inject(ActivatedRoute);
    private nav: NavController = inject(NavController);
    private ga: DartCounterAnalyticsService = inject(DartCounterAnalyticsService);
    private privateChatApiService: PrivateChatApiService = inject(PrivateChatApiService);
    private modalController: ModalController = inject(ModalController);
    private popoverController: PopoverController = inject(PopoverController);
    private popoverService: PopoverService = inject(PopoverService);
    private echoService: EchoService = inject(EchoService);
    private alertService: DartCounterAlertService = inject(DartCounterAlertService);

    @ViewChild(ScrollToBottomDirective, { static: false }) scrollToBottomDirective;
    @ViewChild(MaintainHeightDirective, { static: false }) maintainHeightDirective;

    public chatName: string = null;
    public conversationId: number = null;
    public chat: ChatConversation = null;
    public disableChat = false;
    public chatMessage = '';
    public chatPlaceholder: string = $localize`:@@SEND_A_MESSAGE_DOTS:Send a message...`;

    public loaded = false;
    public skip = 0;
    public take = 50;
    public total: number = null;
    public messages: ChatMessage[] = [];

    public ngOnInit(): void {
        this.route.params.pipe(take(1)).subscribe(async (params) => {
            if (params.id) {
                this.conversationId = params.id;
                this.privateChatApiService
                    .showPrivateChat({
                        conversationId: this.conversationId,
                        limit: this.take,
                        page: this.skip === 0 ? 1 : this.skip / this.take + 1,
                    })
                    .then((res) => {
                        this.ga.trackView('Private chat');

                        this.privateChatApiService.currentConversation = params.id;

                        const chat: ChatConversation = res.data;
                        if (chat.direct_message) {
                            chat.name = chat.participants.filter(
                                (participant) => participant.user_id !== this.auth.user.id
                            )[0].user.full_name;
                        }

                        this.chat = chat;
                        this.messages = chat.paginated_messages.data;

                        if (chat.paginated_messages.data.length) {
                            this.skip += this.take;
                        }

                        this.total = chat.paginated_messages.total;

                        this.markAsRead();
                        this.scrollToBottomDirective?.scrollToBottom();

                        setTimeout(() => {
                            this.loaded = true;
                        }, 250);

                        this.echoService.privateChannel(
                            `chat.${this.conversationId}`,
                            '.private-chat.message.sent',
                            (data: { message: ChatMessage }) => {
                                const message = data.message;
                                if (message.participant.user_id != this.auth.user.id) {
                                    this.messages.push(message);
                                    this.scrollToBottomDirective?.scrollToBottom(250);
                                    this.markAsRead();
                                }
                            }
                        );
                    })
                    .catch(() => {
                        this.nav.navigateBack('chats');
                    });
            } else {
                this.nav.navigateBack('chats');
            }
        });
    }

    public ngOnDestroy(): void {
        this.echoService.leave(`chat.${this.conversationId}`);

        this.privateChatApiService.currentConversation = null;
        this.markAsRead();
    }

    public onKeydown(event?: KeyboardEvent): void {
        if (event.key.toLowerCase() === 'enter') {
            if (!event.shiftKey) {
                this.sendChatMessage();
            }
        }
    }

    public sendChatMessage(): void {
        if (this.disableChat) {
            return;
        }

        this.chatMessage = this.chatMessage.replace(/\n/g, '');

        if (!this.chatMessage.length) {
            return;
        }

        this.disableChat = true;

        this.privateChatApiService
            .sendPrivateChatMessage({ conversationId: this.chat.id, message: this.chatMessage })
            .then((res) => {
                this.chatMessage = '';
                this.maintainHeightDirective?.reset();
                this.messages.push(res.data);
                this.scrollToBottomDirective?.scrollToBottom(250);
            })
            .catch((err) => this.alertService.errorFromApi(err))
            .finally(() => {
                this.disableChat = false;
            });
    }

    public openOptionsPopover(): void {
        this.popoverController
            .create({
                event,
                component: ChatsConversationOptionsPopoverComponent,
            })
            .then((popover) => {
                popover.present();
                this.popoverService.init(popover);
                popover.onDidDismiss().then((res) => {
                    this.popoverService.destroy();

                    const user = this.chat.participants.filter(
                        (participant) => participant.user_id !== this.auth.user.id
                    )[0].user;

                    const result = res.data as ChatsConversationOptionsPopoverResponse;
                    switch (result) {
                        case 'profile':
                            this.modalController
                                .create({
                                    component: ProfileDialogComponent,
                                    componentProps: {
                                        user,
                                        canReport: false,
                                    },
                                    cssClass: environment.isWeb
                                        ? ['slide-modal', 'web']
                                        : ['slide-modal', 'from-bottom'],
                                    backdropDismiss: true,
                                    showBackdrop: true,
                                })
                                .then((elem) => {
                                    elem.present();
                                });
                            break;
                        case 'report':
                            this.modalController
                                .create({
                                    component: ReportUserDialogComponent,
                                    cssClass: 'auto-height',
                                    componentProps: {
                                        user,
                                    },
                                })
                                .then((elem) => {
                                    elem.present();
                                });
                            break;
                        case 'delete':
                            this.modalController
                                .create({
                                    component: PromptDialogComponent,
                                    componentProps: {
                                        title: $localize`:@@DELETE_CHAT:Delete chat`,
                                        text: $localize`:@@DELETING_CHAT:Are you sure you want to delete this chat?`,
                                        cancelText: $localize`:@@CANCEL:Cancel`,
                                        confirmText: $localize`:@@DELETE:Delete`,
                                        confirmColor: 'red',
                                    } as AlertPayload,
                                    cssClass: 'auto-height',
                                    showBackdrop: true,
                                    backdropDismiss: false,
                                })
                                .then((elem) => {
                                    elem.present();
                                    elem.onDidDismiss().then((dialogRes) => {
                                        if (dialogRes.data) {
                                            this.privateChatApiService
                                                .deletePrivateChat({ conversationId: this.chat.id })
                                                .then(() => {
                                                    this.nav.navigateBack('chats');
                                                });
                                        }
                                    });
                                });
                            break;
                    }
                });
            });
    }

    public getMessages(): void {
        if (this.skip <= this.total || this.total === null) {
            this.privateChatApiService
                .showPrivateChat({
                    conversationId: this.conversationId,
                    limit: this.take,
                    page: this.skip === 0 ? 1 : this.skip / this.take + 1,
                })
                .then((res) => {
                    if (res.data.paginated_messages.data.length) {
                        this.skip += this.take;
                    }

                    this.total = res.data.paginated_messages.total;

                    this.messages.unshift(...res.data.paginated_messages.data);
                })
                .catch(() => {
                    this.nav.navigateBack('chats');
                });
        }
    }

    private markAsRead(): void {
        this.privateChatApiService.markPrivateChatAsRead({ conversationId: this.chat.id });
    }
}
