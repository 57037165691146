/* eslint-disable max-len */
import { CommonModule } from '@angular/common';
import { Component, computed, effect, inject, input, InputSignal, Signal } from '@angular/core';
import { CameraStreamClipApiService } from '@dc-api/camera-stream-clip.api.service';
import { dateTimeFormat } from '@dc-core/dc-gamelogic/settings/settings.globals';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import {
    ActionReplayPromptType,
    InGameCameraService,
    UpcomingActionReplay,
} from '@dc-core/dc-services/camera/ingame-camera.service';
import { DartCounterPreferenceService } from '@dc-core/dc-services/preference/preference.service';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { GA_EVENTACTIONS, GA_EVENTCATEGORIES } from 'src/app/app.globals';

import { IconComponent } from '../../../../shared/components/icon/icon.component';

@Component({
    selector: 'app-games-online-action-replay',
    standalone: true,
    templateUrl: './games-online-action-replay.component.html',
    imports: [CommonModule, IconComponent],
})
export class GamesOnlineActionReplayComponent {
    public authenticatedUserId: InputSignal<number> = input.required<number>();

    public ingameCameraService: InGameCameraService = inject(InGameCameraService);
    public preferenceService: DartCounterPreferenceService = inject(DartCounterPreferenceService);

    private alertService: DartCounterAlertService = inject(DartCounterAlertService);
    private cameraStreamClipApiService: CameraStreamClipApiService = inject(CameraStreamClipApiService);
    private ga: DartCounterAnalyticsService = inject(DartCounterAnalyticsService);

    public promptActionReplay: Signal<ActionReplayPromptType> = computed(() =>
        this.ingameCameraService.promptActionReplay()
    );
    public currentActionReplay: Signal<UpcomingActionReplay> = computed(() =>
        this.ingameCameraService.currentActionReplay()
    );

    public canSave = false;
    public canShowPrompt = false;
    public promptType: ActionReplayPromptType = null;
    public showPrompt = false;
    public saveState: 'prompt' | 'saving' | 'saved' | 'error' = 'prompt';
    public promptPreview = null;

    private promptShowTimeout: any = null;
    private promptHideTimeout: any = null;

    private lastPromptAt: string = null;
    private lastSavedAt: string = null;

    constructor() {
        effect(() => {
            const promptActionReplay = this.promptActionReplay();
            if (this.ingameCameraService.currentActionReplay() && promptActionReplay) {
                this.lastPromptAt = moment().format(dateTimeFormat);
                this.prompt(promptActionReplay);
            } else {
                this.checkAndClearPromptTimeouts();

                this.canSave = false;
                this.showPrompt = false;
            }
        });
    }

    prompt(promptType: ActionReplayPromptType, force = false): void {
        this.checkAndClearPromptTimeouts();

        this.saveState = 'prompt';
        this.canSave = true;
        this.canShowPrompt = true;

        if (this.preferenceService.promptActionReplay || force) {
            if (promptType === 'full') {
                this.promptType = 'full';
                this.showPrompt = true;
                this.promptHideTimeout = setTimeout(() => {
                    this.promptHideTimeout = null;
                    this.showPrompt = false;
                }, 5000);
            } else {
                this.promptType = 'icon';
                this.showPrompt = false;
            }
        } else {
            this.promptType = 'icon';
            this.showPrompt = false;
        }
    }

    save(): void {
        if (this.saveState !== 'prompt') {
            return;
        }

        this.cameraStreamClipApiService
            .checkCameraStreamClipsLimit({})
            .then((res) => {
                if (res.data.reached) {
                    this.ga.trackEvent(GA_EVENTCATEGORIES.ACTION_REPLAYS, GA_EVENTACTIONS.REACHEDMAXREPLAYS);

                    this.alertService.createAlert({
                        title: $localize`:@@REACHED_MAX_AMOUNT_OF_ACTION_REPLAYS:You have reached the maximum amount of Action Replays.`,
                        icon: 'error',
                        timer: 3000,
                    });
                } else {
                    this.saveClip();
                }
            })
            .catch(() => {
                this.saveState = 'error';
                this.hidePromptAfterSave();
            });
    }

    private saveClip(): void {
        this.lastSavedAt = cloneDeep(this.lastPromptAt);

        this.ga.trackEvent(GA_EVENTCATEGORIES.ACTION_REPLAYS, GA_EVENTACTIONS.SAVEDREPLAY);

        this.checkAndClearPromptTimeouts();

        this.saveState = 'saving';
        this.showPrompt = true;
        this.promptHideTimeout = setTimeout(() => {
            this.promptHideTimeout = null;
            this.showPrompt = false;
        }, 2000);

        this.ingameCameraService
            .saveActionReplay()
            .then(() => {
                setTimeout(() => {
                    if (this.lastPromptAt === this.lastSavedAt) {
                        this.saveState = 'saved';
                        this.hidePromptAfterSave();
                    }
                }, 250);
            })
            .catch(() => {
                setTimeout(() => {
                    if (this.lastPromptAt === this.lastSavedAt) {
                        this.saveState = 'error';
                        this.hidePromptAfterSave();
                    }
                }, 250);
            });
    }

    private hidePromptAfterSave(): void {
        this.checkAndClearPromptTimeouts();

        this.showPrompt = true;
        this.promptHideTimeout = setTimeout(() => {
            this.promptHideTimeout = null;
            this.showPrompt = false;
        }, 3000);
    }

    private checkAndClearPromptTimeouts(): void {
        if (this.promptShowTimeout) {
            clearTimeout(this.promptShowTimeout);
            this.promptShowTimeout = null;
        }

        if (this.promptHideTimeout) {
            clearTimeout(this.promptHideTimeout);
            this.promptHideTimeout = null;
        }
    }
}
