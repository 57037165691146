import { Component, Input, OnInit } from '@angular/core';
import { MatchThrow, MatchTurn } from '@dc-core/dc-backend/dc-classes';
import { MatchEditScoreResponse } from '@dc-core/dc-gamelogic/game-interfaces';
import { X01_IMPOSSIBLETHROWS } from '@dc-core/dc-gamelogic/x01_constants';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { ModalController } from '@ionic/angular';
import _ from 'lodash';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-edit-scores-dialog',
    templateUrl: 'edit-scores.dialog.html',
})
export class EditScoresDialogComponent implements OnInit {
    @Input() turns: MatchTurn[];
    public editTurn: MatchTurn;
    public selectedTurnIndex: number = null;
    public numberValues: { value: number; label: string }[] = [
        { value: 0, label: '0' },
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
        { value: 6, label: '6' },
        { value: 7, label: '7' },
        { value: 8, label: '8' },
        { value: 9, label: '9' },
        { value: 10, label: '10' },
        { value: 11, label: '11' },
        { value: 12, label: '12' },
        { value: 13, label: '13' },
        { value: 14, label: '14' },
        { value: 15, label: '15' },
        { value: 16, label: '16' },
        { value: 17, label: '17' },
        { value: 18, label: '18' },
        { value: 19, label: '19' },
        { value: 20, label: '20' },
        { value: 25, label: 'BULL' },
    ];
    public multiplierValues: { value: number; label: string }[] = [
        { value: 1, label: $localize`:@@SINGLE:Single` },
        { value: 2, label: $localize`:@@DOUBLE:Double` },
        { value: 3, label: $localize`:@@TREBLE:Treble` },
    ];

    private _remaining: number;

    constructor(
        public view: ModalController,
        public auth: AuthService,
        private _alertService: DartCounterAlertService
    ) {}

    ngOnInit(): void {
        if (this.turns && this.turns.length) {
            this._remaining = _.last(this.turns).remaining_score;
        }
    }

    edit(turn: MatchTurn, turnIndex: number): void {
        if (this.editTurn && this.selectedTurnIndex === turnIndex) {
            this.editTurn = null;
            this.selectedTurnIndex = null;
        } else {
            this.editTurn = null;
            this.editTurn = { ...turn };
            this.selectedTurnIndex = turnIndex;
        }
    }

    throwChanged(throwNumber: string, multiplier: string, matchThrow: MatchThrow): void {
        const previousValue = _.cloneDeep(matchThrow.value);

        matchThrow.number = parseInt(throwNumber, 10);
        matchThrow.multiplier = parseInt(multiplier, 10);
        if (matchThrow.number === 25 && matchThrow.multiplier === 3) {
            matchThrow.number = 25;
            matchThrow.multiplier = 2;
            matchThrow.value = 50;
        } else {
            matchThrow.value = matchThrow.number * matchThrow.multiplier;
        }

        this.editTurn.total_score += matchThrow.value - previousValue;
    }

    save(index: number): void {
        const oldScore = this.turns[index].total_score;
        const newScore = Math.round(this.editTurn.total_score);
        const difference = newScore - oldScore;

        if (difference == 0) {
            this._alertService.createAlert({
                title: $localize`:@@NEW_SCORE_IS_THE_SAME_AS_OLD_SCORE:The new score is the same as the old score`,
                icon: 'warning',
            });
            this.editTurn = null;
            return;
        } else if (this._remaining == difference) {
            this._alertService.createAlert({
                title: $localize`:@@YOU_CANT_EDIT_A_SCORE_TO_FINISH:You can't edit a score to finish the leg`,
                icon: 'warning',
            });
            this.editTurn = null;
            return;
        } else if (this._remaining - difference <= 1) {
            this._alertService.createAlert({
                title: $localize`:@@THIS_EDITED_SCORE_TOO_HIGH:This edited score is too high`,
                icon: 'error',
            });
            this.editTurn = null;
            return;
        } else if (newScore > 180 || X01_IMPOSSIBLETHROWS.indexOf(newScore) >= 0 || newScore < 0) {
            this._alertService.createAlert({
                title: $localize`:@@IMPOSSIBLE_SCORE:This score is not possible`,
                icon: 'error',
            });
            this.editTurn = null;
            return;
        }

        this.editTurn.total_score = newScore;

        const response: MatchEditScoreResponse = {
            turnIndex: index,
            difference,
            oldScore: oldScore,
            newTurn: this.editTurn,
        };

        this.view.dismiss(response);
    }

    public close(): void {
        this.view.dismiss();
    }
}
