import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaymentMethod } from '@dc-core/dc-backend/dc-enums';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { AuthService } from 'src/services/auth.service';

import { PrimaryButtonComponent } from '../../app/primary-button/primary-button.component';
import { DialogViewComponent } from '../../app/shared/components/dialog/dialog-view/dialog-view.component';

export type Method = { name: string; src: string; method: PaymentMethod };

@Component({
    selector: 'app-select-payment-method-dialog',
    standalone: true,
    templateUrl: 'select-payment-method.dialog.html',
    imports: [CommonModule, PrimaryButtonComponent, DialogViewComponent],
})
export class SelectPaymentMethodDialogComponent implements OnInit {
    @Input() info: string = null;

    public methods: Method[];

    private modalController: ModalController = inject(ModalController);
    private translateService: TranslateService = inject(TranslateService);

    constructor(
        public view: ModalController,
        public auth: AuthService,
        private ga: DartCounterAnalyticsService,
        public modal: ModalController,
        public _modal: ModalController,
        public route: ActivatedRoute
    ) {}

    public ngOnInit(): void {
        const creditCard: Method = {
            name: 'Credit Card',
            src: 'creditcard.svg',
            method: 'creditcard',
        };

        const iDeal: Method = {
            name: 'iDeal',
            src: 'ideal.svg',
            method: 'ideal',
        };

        const belfius: Method = {
            name: 'Belfius',
            src: 'belfius.svg',
            method: 'belfius',
        };

        const eps: Method = {
            name: 'EPS',
            src: 'eps.svg',
            method: 'eps',
        };

        const kbc: Method = {
            name: 'KBC/CBC',
            src: 'kbc_cbc.svg',
            method: 'kbc_cbc',
        };

        const paypal: Method = {
            name: 'PayPal',
            src: 'paypal.svg',
            method: 'paypal',
        };

        const banContact: Method = {
            name: 'Bancontact',
            src: 'bancontact.svg',
            method: 'bancontact',
        };

        if (this.translateService.currentLang === 'nl') {
            this.methods = [iDeal, paypal, creditCard, kbc, belfius, banContact, eps];
        } else {
            this.methods = [paypal, creditCard, iDeal, kbc, belfius, banContact, eps];
        }
    }

    public dismiss(returnValue: PaymentMethod = null): void {
        this.modalController.dismiss(returnValue);
    }
}
