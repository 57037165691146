<div class="main-content overflow-hidden flex h-full w-full flex-col pt-0 box-border">
    @if (isLargeDevice()) {
        <div class="flex flex-col h-full w-full py-6 px-3 border-box space-y-8">
            <div class="w-full flex justify-between gap-2">
                @for (hit of ingameOmniService.dartHits$ | async; track hit) {
                    <app-zoomed-point
                        class="flex h-full w-full min-w-[4rem] max-w-[10rem]"
                        [point]="hit"
                        [dartIndex]="$index">
                    </app-zoomed-point>
                }
            </div>
            <div class="flex h-full w-full">
                <app-omni-dartboard #opponentDartboard class="h-[82%] w-full"></app-omni-dartboard>
            </div>
        </div>
    } @else {
        <div class="flex h-16 min-h-16 bg-black bg-opacity-80">
            <button
                class="inline-flex basis-1/2 items-center justify-center"
                [class.inner-border-b-2-orange]="ingameOmniService.omniViewMode === 'zoomed'"
                [class.opacity-60]="ingameOmniService.omniViewMode !== 'zoomed'"
                (click)="ingameOmniService.changeOmniView('zoomed')">
                <div
                    class="font-bebas-neue text-xl text-white"
                    [translate]="'OMNI_ZOOMED_VIEW'"
                    i18n="@@OMNI_ZOOMED_VIEW">
                    Zoomed
                </div>
            </button>
            <button
                class="inline-flex basis-1/2 items-center justify-center"
                [class.inner-border-b-2-orange]="ingameOmniService.omniViewMode === 'board'"
                [class.opacity-60]="ingameOmniService.omniViewMode !== 'board'"
                (click)="ingameOmniService.changeOmniView('board')">
                <div
                    class="font-bebas-neue text-xl text-white"
                    [translate]="'OMNI_BOARD_VIEW'"
                    i18n="@@OMNI_BOARD_VIEW">
                    Full board
                </div>
            </button>
        </div>

        @if (ingameOmniService.omniViewMode === 'zoomed') {
            <div class="zoomed-points w-full mt-4">
                @for (hit of ingameOmniService.dartHits$ | async; track hit) {
                    <app-zoomed-point class="zoomed-point" [point]="hit" [dartIndex]="$index"> </app-zoomed-point>
                }
            </div>
        } @else if (ingameOmniService.omniViewMode === 'board') {
            <div class="flex h-full w-full p-6">
                <app-omni-dartboard #opponentDartboard class="h-full w-full"></app-omni-dartboard>
            </div>
        }
    }
</div>
