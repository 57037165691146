import { Injectable } from '@angular/core';
import {
    addDoc,
    collection,
    CollectionReference,
    deleteDoc,
    doc,
    DocumentReference,
    Firestore,
    getDocs,
    onSnapshot,
    query,
    QuerySnapshot,
    Timestamp,
    updateDoc,
    where,
} from '@angular/fire/firestore';
import { DocumentSnapshot, getDoc, writeBatch } from 'firebase/firestore';
import { BehaviorSubject } from 'rxjs';
import { OnlineGameplay, ONLINEGAMESTATUS, PLAYERSTATUS } from '../../dc-backend/dc-interfaces';
import { FireStoreAuthService } from '../firestore-auth.service';
import { FireStoreCollectionsService } from '../firestore-collections.service';
import { DCFireStoreUser, FIRESTORE_COLLECTION } from '../globals/firestore.tables';
import { PublicGamesCollectionService } from './public_games.collection.service';
import { UsersCollectionService } from './users.collection.service';

@Injectable()
export class ActiveGamesCollectionService extends FireStoreCollectionsService {
    private defaultClass = new OnlineGameplay();

    private collection_name: FIRESTORE_COLLECTION = FIRESTORE_COLLECTION.ACTIVE_GAMES;
    private firestore_collection: CollectionReference<OnlineGameplay>;
    public inGame: boolean = false;

    public isJoining: boolean = false;
    public disableJoin: boolean = false;
    public redirectBack = '/online-games/global-lobby';
    public activeGameRef: DocumentReference<OnlineGameplay> | null = null;
    public activeGameRef$: BehaviorSubject<DocumentReference<OnlineGameplay>> = new BehaviorSubject<
        DocumentReference<OnlineGameplay>
    >(null);

    constructor(
        private firestore: Firestore,
        private _dcFireAuth: FireStoreAuthService,
        private _usersCollection: UsersCollectionService,
        private _dcFirePublicGames: PublicGamesCollectionService
    ) {
        super(firestore);
        this.firestore_collection = this.getConvertedData<OnlineGameplay>(this.collection_name);
    }

    setActiveGameRef(id: string): void {
        this.activeGameRef = doc(this.firestore_collection, id);
        this.activeGameRef$.next(this.activeGameRef);
    }

    getActiveGame(): Promise<DocumentSnapshot<OnlineGameplay>> | null {
        return this.activeGameRef ? getDoc(this.activeGameRef) : null;
    }

    addItem(newDoc: OnlineGameplay): void {
        addDoc(this.firestore_collection, newDoc).then((docRef) => {
            this.activeGameRef = docRef;
            this.activeGameRef$.next(this.activeGameRef);

            // Add the activeGameRef to the users
            this.addActiveGameRefToUsers(newDoc.owners);
        });
    }

    // Set the activeGame field on the UserDoc for all the owners of the game
    addActiveGameRefToUsers(owners: string[]) {
        this.isJoining = true;

        owners.forEach((user_uid) => {
            let userDocRef = this._usersCollection.getDocByID(user_uid);

            updateDoc(userDocRef, {
                ...(<DCFireStoreUser>{
                    activeGameRef: this.activeGameRef,
                }),
            });
        });
    }

    removeActiveGameRef(user_uid: string | null = null): void {
        if (!user_uid) {
            user_uid = this._dcFireAuth.getCurrentUID();
        }
        let userDocRef = this._usersCollection.getDocByID(user_uid);
        this.activeGameRef = null;
        this.activeGameRef$.next(this.activeGameRef);
        updateDoc(userDocRef, {
            ...(<DCFireStoreUser>{
                activeGameRef: null,
            }),
        });
    }

    getDocByID(id: string): DocumentReference<OnlineGameplay> {
        return doc(this.firestore_collection, id);
    }

    watchDoc(id: string, observerFn) {
        let docRef = this.getDocByID(id);
        return onSnapshot(docRef, observerFn);
    }

    removeItem(docRef: DocumentReference<OnlineGameplay>): void {
        deleteDoc(docRef);
    }

    updateItem(ref: DocumentReference<OnlineGameplay>, updatedValues: OnlineGameplay): Promise<void> {
        return updateDoc(ref, { ...updatedValues });
    }

    async setReady(docRef: DocumentReference<OnlineGameplay>, gameplay: OnlineGameplay) {
        gameplay.last_updated = Timestamp.now();

        this.updateItem(docRef, gameplay);
    }

    async switchPlayers(gameplay: OnlineGameplay) {
        gameplay.players = [gameplay.players[1], gameplay.players[0]];
        gameplay.last_updated = Timestamp.now();

        this.updateItem(gameplay.doc_ref!, gameplay);
        1!!;
    }

    async updateOnlineGameByRef(
        ref: DocumentReference<OnlineGameplay>,
        doc: OnlineGameplay,
        status: ONLINEGAMESTATUS | null = null
    ) {
        // Update only the game
        const fsGameplay = <OnlineGameplay>{
            game: doc.game,
            onlineGameStatus: status || doc.onlineGameStatus,
            timer_ends_at: doc.timer_ends_at,
            last_updated: Timestamp.now(),
        };

        this.updateItem(ref, fsGameplay);
    }

    async updateOnlineGame(doc: OnlineGameplay, status: ONLINEGAMESTATUS | null = null) {
        // Update only the game
        const fsGameplay = <OnlineGameplay>{
            game: doc.game,
            onlineGameStatus: status || doc.onlineGameStatus,
            timer_ends_at: doc.timer_ends_at,
            last_updated: Timestamp.now(),
        };

        this.updateItem(this.activeGameRef!, fsGameplay);
    }

    async updateOnlinePlayers(doc?: OnlineGameplay, players?: DCFireStoreUser[]) {
        const onlinePlayers = doc ? doc.players : players;
        // Update only the game
        const fsGameplay = <OnlineGameplay>{
            players: onlinePlayers,
            last_updated: Timestamp.now(),
        };

        this.updateItem(this.activeGameRef!, fsGameplay);
    }

    launchPublicGame(gameplay: OnlineGameplay) {
        const publicGame = this._dcFirePublicGames.getPublicGameByGameplay(gameplay);
        this._dcFirePublicGames.addItem(publicGame, gameplay.doc_id);
    }

    async inviteOnlyOnlineGame(doc: OnlineGameplay) {
        if (doc.owners.length > 0) {
            const fsGameplay = <OnlineGameplay>{
                players: doc.players,
                onlineGameStatus: ONLINEGAMESTATUS.INVITE_ONLY,
            };
            this.updateItem(doc.doc_ref!, fsGameplay);
        } else {
            // Remove the document completely
            deleteDoc(doc.doc_ref!);
        }
    }

    async resumeOnlineGame(doc_id: string) {
        let docRef = await this.getDocByID(doc_id);
        this.activeGameRef = docRef;
        this.activeGameRef$.next(this.activeGameRef);

        const fsGameplay = <OnlineGameplay>{
            onlineGameStatus: ONLINEGAMESTATUS.READY_TO_START,
            playerOneStatus: PLAYERSTATUS.NONE,
            playerTwoStatus: PLAYERSTATUS.NONE,
        };

        this.updateItem(docRef, fsGameplay);

        let gameDoc = (await getDoc(docRef)).data();
        if (gameDoc) {
            gameDoc.doc_id = doc_id;
            this.addActiveGameRefToUsers(gameDoc.owners);
            this.launchPublicGame(gameDoc);
        }
    }

    async leaveOnlineGame(doc: OnlineGameplay | null | undefined = null) {
        if (doc == null) {
            doc = (await getDoc(this.activeGameRef!)).data();
        }

        if (doc) {
            if (doc.owners.length > 0) {
                const fsGameplay = <OnlineGameplay>{
                    players: doc.players,
                    game: doc.game,
                    onlineGameStatus:
                        doc.onlineGameStatus != ONLINEGAMESTATUS.REMOVED &&
                        doc.onlineGameStatus != ONLINEGAMESTATUS.FINISHED
                            ? ONLINEGAMESTATUS.SAVED
                            : doc.onlineGameStatus,
                    timer_ends_at: doc.timer_ends_at,
                    last_updated: Timestamp.now(),
                };
                this.updateItem(this.activeGameRef!, fsGameplay);
            } else {
                // Remove the document completely
                deleteDoc(this.activeGameRef!);
            }
        }

        this.removeActiveGameRef();
    }

    async quitOnlineGame(doc: OnlineGameplay = null, ref: DocumentReference<OnlineGameplay> = null) {
        if (doc == null) {
            doc = (await getDoc(this.activeGameRef)).data();
        }

        if (ref == null) {
            ref = this.activeGameRef;
        }

        const current_uid = this._dcFireAuth.getCurrentUID();
        // Remove myself from owners
        doc.owners.forEach((owner_uid, index) => {
            this.removeActiveGameRef(owner_uid);

            if (current_uid == owner_uid) {
                doc.owners.splice(index, 1);
            }
        });

        if (doc.owners.length > 0) {
            const fsGameplay = <OnlineGameplay>{
                owners: doc.owners,
                onlineGameStatus: ONLINEGAMESTATUS.REMOVED,
            };
            await this.updateItem(ref, fsGameplay);
        } else {
            // Remove the document completely
            await this.deleteActiveGame(ref);
        }
    }

    async deleteActiveGame(ref: DocumentReference<any> = null) {
        if (ref == null) {
            ref = this.activeGameRef;
        }

        const subcollections = [
            FIRESTORE_COLLECTION.GAME_EVENTS,
            FIRESTORE_COLLECTION.CHATS,
            FIRESTORE_COLLECTION.ACTIONS,
            FIRESTORE_COLLECTION.PAUSES,
            FIRESTORE_COLLECTION.SPECTATORS,
        ];

        for (let subcollection of subcollections) {
            const childCollectionRef = collection(ref, subcollection);
            await getDocs(childCollectionRef).then(async (childDocs) => {
                const batch = writeBatch(this.firestore);

                childDocs.forEach((childDoc: any) => {
                    batch.delete(childDoc.ref);
                });

                await batch.commit();
            });
        }

        await deleteDoc(ref);
    }

    getOwnGamesByStatuses(statuses: ONLINEGAMESTATUS[]): Promise<QuerySnapshot<OnlineGameplay>> {
        try {
            const whereClause = this.getAttributeString(this.defaultClass, (obj: OnlineGameplay) => obj.owners);
            const onlineGameStatusClause = this.getAttributeString(
                this.defaultClass,
                (obj: OnlineGameplay) => obj.onlineGameStatus
            );

            const testQuery = query(
                this.firestore_collection,
                where(whereClause, 'array-contains', this._dcFireAuth.getCurrentUID()),
                where(onlineGameStatusClause, 'in', statuses)
            );

            return getDocs(testQuery);
        } catch (err) {
            console.error(err);
        }
    }

    spectateGameFromUID(uid: string): Promise<QuerySnapshot<OnlineGameplay>> {
        try {
            const whereClause = this.getAttributeString(this.defaultClass, (obj: OnlineGameplay) => obj.owners);
            const onlineGameStatusClause = this.getAttributeString(
                this.defaultClass,
                (obj: OnlineGameplay) => obj.onlineGameStatus
            );

            const testQuery = query(
                this.firestore_collection,
                where(whereClause, 'array-contains', uid),
                where(onlineGameStatusClause, '==', ONLINEGAMESTATUS.STARTED)
            );

            return getDocs(testQuery);
        } catch (err) {
            console.error(err);
        }
    }
}
