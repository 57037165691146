<!-- Translation containers-->
@if (false) {
    <ng-container i18n="@@NAME_OF_PLAYER">Player name</ng-container>
}
<!-- End of translation containers-->

<app-page-header mainNavigation="custom" color="transparent">
    <app-icon left icon="arrow_back" (click)="dismiss()"></app-icon>
    <div title [translate]="'SELECT_YOUR_NAME'" i18n="@@SELECT_YOUR_NAME">Call your name</div>
</app-page-header>

<ion-content scrollY="false" fullscreen class="bg-white-pattern ion-text-black">
    <div class="mx-4 mb-2 text-black text-center">
        @if (!relink) {
            <div [translate]="'TEXT_LINK_SOUND'" i18n="@@TEXT_LINK_SOUND" class="text-sm">
                Search for your name and link it to your account
            </div>
            @if (!searchLoading && hideImage) {
                <div [translate]="'NAME_NOT_FOUND'" i18n="@@NAME_NOT_FOUND" (click)="contact()" class="text-xs">
                    Is your name not in the list? Contact us and we will add it!
                </div>
            }
        } @else {
            <div [translate]="'TEXT_RELINK_SOUND'" i18n="@@TEXT_RELINK_SOUND">
                Link your name once again for the online game
            </div>
        }
    </div>

    @if (!hideImage) {
        <div class="teaser">
            <img src="assets/images/Ray.jpg" />
        </div>
    }

    <ion-searchbar
        class="text-black !p-0"
        mode="ios"
        placeholder="{{ 'NAME_OF_PLAYER' | translate }}"
        [(ngModel)]="searchString"
        (ionChange)="SearchSounds(searchString)"
        (click)="hideImage = true; selectedSound = null"
        debounce="600">
    </ion-searchbar>

    @if (searchLoading) {
        <ion-row style="background: transparent">
            <ion-col class="text-center">
                <ion-spinner name="crescent"></ion-spinner>
            </ion-col>
        </ion-row>
    }

    @if (sounds.length) {
        <div class="scrollList">
            <div class="dialog-input-label mx-4" [translate]="'NAMES_FOUND'" i18n="@@NAMES_FOUND">Names found</div>
            @for (sound of sounds; track sound) {
                <div
                    class="scroll-list-item m-4 text-black flex justify-between items-center cursor-pointer"
                    [class.selected]="sound.id === selectedSound?.id"
                    (click)="selectSound(sound)">
                    <ion-label>{{ sound.name }}</ion-label>
                    @if (sound.id === selectedSound?.id) {
                        <ion-icon name="checkmark-outline" color="secondary" style="font-size: 20px"></ion-icon>
                    }
                </div>
            }
        </div>
    }
</ion-content>
<ion-footer class="ion-no-border">
    <ion-col>
        @if (user.is_ultimate && user.profile?.sound) {
            <app-primary-button
                (click)="UnlinkSound()"
                fill="clear"
                color="black"
                addedClasses="w-full"
                size="small"
                [translate]="'UNLINK_NAME'"
                i18n="@@UNLINK_NAME">
                Unlink name
            </app-primary-button>
        }
        <div style="padding: 15px 15px 0 15px">
            <app-primary-button
                color="orange"
                class="w-full"
                [disabled]="!selectedSound"
                [addedClasses]="'w-full'"
                size="normal"
                fill="full"
                [translate]="'LINK_SOUND'"
                i18n="@@LINK_SOUND"
                (click)="LinkSound(selectedSound)">
                Link name
            </app-primary-button>
        </div>
    </ion-col>
</ion-footer>
