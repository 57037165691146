<div class="popover-container">
    @if (isAdmin) {
        <div class="popover-button" (click)="ClosePopover('add')" [translate]="'ADD_DEVICE'" i18n="@@ADD_DEVICE">
            Add device
        </div>
        <div
            class="popover-button"
            (click)="ClosePopover('rename')"
            [translate]="'RENAME_NETWORK'"
            i18n="@@RENAME_NETWORK">
            Rename network
        </div>
        <div
            class="popover-button"
            (click)="ClosePopover('invite')"
            [translate]="'INVITE_PLAYER'"
            i18n="@@INVITE_PLAYER">
            Invite player
        </div>
    }

    <div class="popover-button" (click)="ClosePopover('delete')" [translate]="'REMOVE_NETWORK'" i18n="@@REMOVE_NETWORK">
        Remove network
    </div>
</div>
