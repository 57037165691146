/* eslint-disable max-len */
import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Camera } from '@capacitor/camera';
import { Wifi } from '@capacitor-community/wifi';
import { CameraStreamApiService } from '@dc-api/camera-stream.api.service';
import { SmartDeviceApiService } from '@dc-api/smart-device.api.service';
import { SmartDevice, SmartDeviceNetwork } from '@dc-core/dc-backend/dc-classes';
import { SmartDeviceType } from '@dc-core/dc-backend/dc-enums';
import { AlertPayload, DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { CAMERA_TYPE, JanusRoom } from '@dc-core/dc-services/camera/camera.models';
import { UserMedia } from '@dc-core/dc-services/dc-janus/DartCounterUserMedia';
import { JanusVideoRoomService, PublishingMediaDevices } from '@dc-core/dc-services/dc-janus/janus-video-room.service';
import { UserMediaErrorType, UserMediaService } from '@dc-core/dc-services/dc-janus/usermedia.service';
import { VirtDisableCamType } from '@dc-core/dc-services/smart-device/virt-cam.globals';
import { DropdownSelectItem } from '@dc-core/dc-statistics/statistics.globals';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { SmartDeviceService } from '@services/smart-device.service';
import _ from 'lodash';
import { PowerMode } from 'power-mode';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';

import { PromptDialogComponent } from '../prompt/prompt.dialog';

@Component({
    selector: 'app-activate-camera-dialog',
    templateUrl: 'activate-camera.dialog.html',
    animations: [
        trigger('slideAnimation', [
            transition(':enter', [
                style({ transform: 'translateX(100%)' }),
                animate('300ms ease-out', style({ transform: 'translateX(0)' })),
            ]),
            transition(':leave', [
                style({ transform: 'translateX(0)' }),
                animate('150ms ease-out', style({ transform: 'translateX(-100%)' })),
            ]),
        ]),
    ],
    styles: [
        `
            .web-height {
                max-height: calc(100vh - 150px);
            }
        `,
    ],
})
export class ActivateCameraDialogComponent implements OnInit, OnDestroy {
    @ViewChild('codeInput') codeInput: ElementRef;
    @ViewChild('passInput') passInput: ElementRef;

    @Input() cameraType: CAMERA_TYPE = null;
    @Input() preSelectedSmartDevice: SmartDevice = null;

    public currentSlide: 'select' | 'external_info' | 'external_code' | 'smart_device' | 'web_smart_device' | 'end' =
        'select';

    public finalCode: number[] = [null, null, null, null];
    public pass: string[] = ['', '', '', ''];
    focusedField: HTMLInputElement | null = null;

    public allowedTypes: SmartDeviceType[] = ['virt_cam'];
    public networks: SmartDeviceNetwork[] = [];
    public totalDevices: number = null;

    public userMedia: UserMedia = new UserMedia();
    public media: PublishingMediaDevices = null;
    public smartDevice: SmartDevice = null;

    public disableVirtCamOption: VirtDisableCamType = 'none';
    public disableVirtCamOptions: DropdownSelectItem[] = [
        {
            label: $localize`:@@SHOW_PLAYER_CAM:Show player camera`,
            value: 'none' as VirtDisableCamType,
        },
        {
            label: $localize`:@@HIDE_PLAYER_CAM_FOR_SPECTATORS:Hide your player camera for spectators`,
            value: 'spectators' as VirtDisableCamType,
        },
        {
            label: $localize`:@@HIDE_PLAYER_CAM_FOR_OPPONENT_AND_SPECTATORS:Hide your player camera for your oppponent and spectators`,
            value: 'all' as VirtDisableCamType,
        },
    ];

    public isConfirmed = false;

    public isWeb = environment.isWeb;

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private _backButtonSubscription: Subscription;

    constructor(
        public smartDeviceService: SmartDeviceService,
        public videoRoomService: JanusVideoRoomService,
        public userMediaService: UserMediaService,
        private _view: ModalController,
        private _modal: ModalController,
        private _smartDeviceApiService: SmartDeviceApiService,
        private _alertService: DartCounterAlertService,
        private _cameraStreamApiService: CameraStreamApiService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private _ngZone: NgZone,
        private _changeDetectorRef: ChangeDetectorRef,
        private _nav: NavController
    ) {}

    ngOnInit(): void {
        this.isConfirmed = false;

        this._backButtonSubscription = this._platform.backButton.subscribeWithPriority(9999, () => {
            this.close();
        });

        if (this.videoRoomService.externalCameraStream && this.videoRoomService.useOldCameraStream) {
            this.videoRoomService.externalCameraStream.code
                .toString()
                .split('')
                .forEach((code, index) => {
                    document.getElementById('codeInput' + index).setAttribute('value', code);
                    this.finalCode[index] = parseInt(code);
                });

            this.videoRoomService.externalCameraStream.password.split('').forEach((pass, index) => {
                document.getElementById('passInput' + index).setAttribute('value', pass);
                this.pass[index] = pass;
            });
        }

        let totalDevices = 0;
        this.networks = [];
        _.cloneDeep(this._smartDeviceApiService.smartDeviceNetworks).forEach((network) => {
            const devices = [];
            network.devices.forEach((device) => {
                if (!(this.allowedTypes.length && !this.allowedTypes.includes(device.type))) {
                    devices.push(device);
                }
            });
            totalDevices += devices.length;
            network.devices = devices;

            if (devices.length) {
                this.networks.push(network);
            }
            this.totalDevices = totalDevices;
        });

        this.smartDeviceService.clean();

        try {
            if (this._platform.is('ios') && this._platform.is('capacitor')) {
                PowerMode.lowPowerModeEnabled().then((res) => {
                    if (res.lowPowerModeEnabled) {
                        this._alertService.createAlert({
                            title: $localize`:@@LOW_POWER_MODE:Low power mode`,
                            text: $localize`:@@SWITCH_OFF_LOWER_POWER_MODE_FOR_CAM_STREAMS:Switch off ‘Low power mode’ in your device settings to prevent camera streams from pausing.`,
                            icon: 'warning',
                            timer: null,
                            confirmButtonText: $localize`:@@OK:OK`,
                        });
                    }
                });
            }
        } catch (_) {}

        if (this.cameraType !== null) {
            this.checkCameraType();
        }
    }

    setCameraType(cameraType) {
        this.cameraType = cameraType;
    }

    checkCameraType(): void {
        if (this.cameraType == CAMERA_TYPE.EXTERNAL_DEVICE) {
            this.currentSlide = 'external_info';
        } else if (this.cameraType == CAMERA_TYPE.CURRENT_DEVICE) {
            this.currentSlide = 'end';
            this.requestMediaAccess();
        } else if (this.cameraType == CAMERA_TYPE.SMART_DEVICE) {
            if (environment.isWeb) {
                this.currentSlide = 'web_smart_device';
            } else {
                if (!this._smartDeviceApiService.hasVirt$.value) {
                    this._alertService.createAlert({
                        title: $localize`:@@NO_TARGET_CAM_LINKED_TO_ACCOUNT:There is no Target VirtCam linked to your account`,
                        text: $localize`:@@NO_TARGET_CAM_LINKED_TO_ACCOUNT_INFO:Do you want to set up your Target VirtCam now?`,
                        icon: 'warning',
                        timer: null,
                        confirmButtonText: $localize`:@@YES:Yes`,
                        cancelButtonText: $localize`:@@NO:No`,
                        onConfirm: () => {
                            this.close();
                            this._nav.navigateForward('smart-devices');
                        },
                    });
                } else {
                    this.currentSlide = 'smart_device';

                    if (this.preSelectedSmartDevice) {
                        this.selectedSmartDevice(this.preSelectedSmartDevice);
                    }
                }
            }
        }
    }

    goToSlide(slide: 'select' | 'external_info' | 'external_code' | 'smart_device' | 'end'): void {
        this.currentSlide = slide;
    }

    tryCode() {
        if (this.finalCode) {
            const trimmed_pass = this.pass.join('').toUpperCase();

            this._cameraStreamApiService
                .getCameraStreamRoomNumber({ code: parseInt(this.finalCode.join('')), password: trimmed_pass })
                .then((res) => {
                    if (
                        (this.cameraType === CAMERA_TYPE.EXTERNAL_DEVICE && !res.data.virt_cam_id) ||
                        (this.cameraType === CAMERA_TYPE.SMART_DEVICE && res.data.virt_cam_id)
                    ) {
                        this.videoRoomService.externalCameraStream = res.data;

                        this.goToSlide('end');
                        this.checkExternal(res.data.janus_server_hostname, res.data.room);
                    } else {
                        this._alertService.createAlert({
                            icon: 'error',
                            title: $localize`:@@ERROR_INCORRECT_CODE_OR_PASS:Incorrect code or password`,
                        });
                    }
                })
                .catch(() => {
                    this._alertService.createAlert({
                        icon: 'error',
                        title: $localize`:@@ERROR_INCORRECT_CODE_OR_PASS:Incorrect code or password`,
                    });
                });
        }
    }

    async requestMediaAccess(): Promise<void> {
        if (this._platform.is('capacitor')) {
            try {
                const checkCamPermissions = await Camera.checkPermissions();
                if (checkCamPermissions.camera !== 'granted') {
                    const requestCamResult = await Camera.requestPermissions();
                    if (requestCamResult.camera === 'granted') {
                        setTimeout(() => {
                            this.requestMediaAccess();
                        }, 500);
                        return;
                    } else {
                        throw 'No permission';
                    }
                }
            } catch (_) {
                this._alertService.createAlert({
                    title: $localize`:@@ENABLE_CAM_APP_PERMS:Please enable camera permissions in your device settings.`,
                    icon: 'error',
                    timer: 2000,
                    timerProgressBar: true,
                    showCloseButton: true,
                });
                return;
            }
        }

        this._ngZone.run(() => {
            if (this.videoRoomService.isPublishingVideo) {
                this.videoRoomService.ownUserMedia?.cleanupUserMedia(true, true);
            }

            this.userMediaService
                .getUserMedia()
                .then((stream) => {
                    stream.getTracks().forEach((track) => track.stop());

                    setTimeout(() => {
                        this.userMediaService.getDevices(this.userMedia);
                    }, 500);
                })
                .catch((error) => {
                    const userMediaError = this.userMediaService.mapToUserMediaErrorType(error);
                    if (userMediaError === UserMediaErrorType.PermissionDenied) {
                        this._alertService.createAlert({
                            title: $localize`:@@ENABLE_CAM_APP_PERMS:Please enable camera permissions in your device settings.`,
                            icon: 'error',
                            timer: 2000,
                            timerProgressBar: true,
                            showCloseButton: true,
                        });
                    } else {
                        setTimeout(() => {
                            this.userMediaService.getDevices(this.userMedia);
                        }, 500);
                    }
                });
        });
    }

    updatePreview(usermedia: UserMedia) {
        this.userMediaService
            .updatePreview(usermedia)
            .then(() => {})
            .catch(() => {
                //Error while starting stream
                setTimeout(() => {
                    this.userMediaService.getDevices(this.userMedia);
                }, 500);
            });
    }

    checkExternal(janusServerHost: string, roomId: number): void {
        this.userMedia = new UserMedia();
        this.userMedia.startRemoteEvents();
        this.userMedia.remoteEvents$.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
            this._changeDetectorRef.detectChanges();
        });

        const janusRoom: JanusRoom = { janusServerHost, roomID: roomId, camType: this.cameraType };
        this.videoRoomService
            .spectateRoom(janusRoom, 'video', null, this.userMedia, true, false, false)
            .then(() => {
                this.userMedia.janusRoom = janusRoom;
            })
            .catch(console.error);
    }

    selectSmartDevice(network: SmartDeviceNetwork, smartDevice: SmartDevice): void {
        Wifi.getSSID()
            .then((res) => {
                if (res.ssid) {
                    if (res.ssid == network.ssid) {
                        this.selectedSmartDevice(smartDevice);
                    } else {
                        this.askForCorrectWiFi(network.ssid, smartDevice);
                    }
                } else {
                    this.askForCorrectWiFi(network.ssid, smartDevice);
                }
            })
            .catch(() => {
                this.askForCorrectWiFi(network.ssid, smartDevice);
            });
    }

    askForCorrectWiFi(ssid: string, smartDevice: SmartDevice): void {
        $localize`:@@CONNECTED_TO_WIFI_NETWORK_SSID_Q:Are you connected to the Wi-Fi network ${ssid}:ssid:?`;
        this._modal
            .create({
                component: PromptDialogComponent,
                componentProps: {
                    title: $localize`:@@CORRECT_WIFI_NETWORK:Correct Wi-Fi network`,
                    text: this._translateService.instant('CONNECTED_TO_WIFI_NETWORK_SSID_Q', {
                        ssid,
                    }),
                    cancelText: $localize`:@@CANCEL:Cancel`,
                    confirmText: $localize`:@@CONFIRM:Confirm`,
                } as AlertPayload,
                cssClass: 'auto-height',
                showBackdrop: true,
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.selectedSmartDevice(smartDevice);
                    }
                });
            });
    }

    selectedSmartDevice(smartDevice: SmartDevice, fromRestart = false): void {
        this.smartDevice = smartDevice;
        this.smartDeviceService.initSmartDevice(smartDevice);
        this.smartDeviceService.startStreaming(smartDevice, true).then((janusRoom) => {
            this._ngZone.run(() => {
                if (!fromRestart) {
                    this.userMedia.startRemoteEvents();
                    this.userMedia.remoteEvents$.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
                        this._changeDetectorRef.detectChanges();
                    });
                }

                this.videoRoomService
                    .spectateRoom(janusRoom, 'video', null, this.userMedia, true, false, false)
                    .then(() => {
                        this.userMedia.janusRoom = janusRoom;
                    })
                    .catch(console.error);
            });
        });

        if (!fromRestart) {
            this.goToSlide('end');
        }
    }

    async restartSmartDevice(): Promise<void> {
        if (this.userMedia.janusRoom.roomID) {
            await this.videoRoomService
                .leaveRoomAndDetachAllHandles(this.userMedia.janusRoom.roomID, true)
                .catch(console.error);
        }
        this.userMedia.cleanupUserMedia({ video: true, audio: false }, { video: true, audio: false });

        this.smartDeviceService
            .stopStreaming(this.smartDevice, true, true)
            .then(() => {
                this.selectedSmartDevice(this.smartDevice, true);
            })
            .catch(console.error);
    }

    onInputKeyup(
        event: KeyboardEvent,
        model: any[],
        key: string | number, // key or index to identify the model property
        currentInput: HTMLInputElement,
        nextInput: HTMLInputElement | null,
        previousInput: HTMLInputElement | null
    ) {
        // Handle backspace separately
        if (event.key.toLowerCase() === 'backspace') {
            currentInput.value = null; // Clear the current input
            model[key] = null;
            if (previousInput) {
                this.focusedField = previousInput;
                previousInput.value = null;
                previousInput.focus();
            }
        } else if (currentInput.value.length === 1) {
            // If it's a character key, replace the input's value with the new key
            model[key] = currentInput.value;
            if (nextInput) {
                this.focusedField = nextInput;
                nextInput.focus();
            } else {
                currentInput.blur();
                this.focusedField = null;
            }
        }

        // Stop the default behavior to prevent the input from being added twice
        event.preventDefault();
    }

    focusField(input: HTMLInputElement) {
        this.focusedField = input;
    }

    startPublishing(): void {
        this.media = this.userMediaService.getMediaConstraints(true, false);
        this.userMediaService
            .getUserMedia(this.media)
            .then((stream) => {
                this.userMediaService.setStreamAsOwnUserMedia(this.videoRoomService.ownUserMedia, stream);

                setTimeout(() => {
                    this.userMediaService.applyVideoConstraints(this.videoRoomService.ownUserMedia);
                    this.videoRoomService
                        .startPublishing('video', this.media, this.videoRoomService.ownUserMedia)
                        .then(() => {
                            this.close(this.videoRoomService.ownCamera);
                        })
                        .catch((err) => {
                            this._alertService.createAlert({
                                icon: 'error',
                                text: err,
                            });
                        });
                }, 500);
            })
            .catch((error) => {
                const userMediaError = this.userMediaService.mapToUserMediaErrorType(error);
                if (userMediaError === UserMediaErrorType.PermissionDenied) {
                    this._alertService.createAlert({
                        title: $localize`:@@ENABLE_CAM_APP_PERMS:Please enable camera permissions in your device settings.`,
                        icon: 'error',
                        timer: 2000,
                        timerProgressBar: true,
                        showCloseButton: true,
                    });
                }
            });
    }

    public async confirm(): Promise<void> {
        if (this.isConfirmed) {
            this._alertService.createAlert({
                title: $localize`:@@PLEASE_WAIT_DOTS:Please wait...`,
                icon: 'loading',
                timer: 'default',
            });
            return;
        }

        // Start publishing to the Janus server
        if (this.userMedia.videoStreams.hasStreams) {
            this.isConfirmed = true;

            this.userMedia.cleanupUserMedia(true, true, true);

            this.videoRoomService.stopOwnStream().finally(() => {
                switch (this.cameraType) {
                    case CAMERA_TYPE.CURRENT_DEVICE:
                        this.startPublishing();
                        break;
                    case CAMERA_TYPE.EXTERNAL_DEVICE:
                    case CAMERA_TYPE.SMART_DEVICE:
                        if (this.cameraType === CAMERA_TYPE.SMART_DEVICE) {
                            if (this.disableVirtCamOption === 'none') {
                                this.videoRoomService.ownCamera.disablePlayerCamForSpectators = false;
                                this.videoRoomService.ownCamera.disablePlayerCamForAll = false;
                            } else if (this.disableVirtCamOption === 'spectators') {
                                this.videoRoomService.ownCamera.disablePlayerCamForSpectators = true;
                                this.videoRoomService.ownCamera.disablePlayerCamForAll = false;
                            } else if (this.disableVirtCamOption === 'all') {
                                this.videoRoomService.ownCamera.disablePlayerCamForSpectators = false;
                                this.videoRoomService.ownCamera.disablePlayerCamForAll = true;
                            }
                        }

                        this.videoRoomService
                            .leaveRoomAndDetachAllHandles(this.userMedia.janusRoom.roomID, true)
                            .finally(() => {
                                this.videoRoomService.ownUserMedia.audioMuted = this.userMedia.audioMuted;
                                this.videoRoomService
                                    .useExternalRoom(this.userMedia.janusRoom, this.cameraType)
                                    .finally(() => {
                                        this.close(this.videoRoomService.ownCamera);
                                    });
                            });
                        break;
                }
            });
        }
    }

    public close(returnValue: JanusRoom = null): void {
        this._view.dismiss(returnValue);
    }

    async ngOnDestroy(): Promise<void> {
        if (this._backButtonSubscription) {
            this._backButtonSubscription.unsubscribe();
        }

        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        this.videoRoomService.useOldCameraStream = false;

        if (!this.isConfirmed) {
            if (this.userMedia.janusRoom.roomID) {
                await this.videoRoomService
                    .leaveRoomAndDetachAllHandles(this.userMedia.janusRoom.roomID, true)
                    .catch(console.error);
            }
            this.userMedia.cleanupUserMedia({ video: true, audio: false }, { video: true, audio: false });
        }
    }
}
