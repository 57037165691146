import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-toggle-switch',
    templateUrl: 'toggle-switch.component.html',
})
export class ToggleSwitchComponent {
    @Input() model: boolean;
    @Input() mode: 'normal' | 'game_menu' = 'normal';
    @Input() disabled = false;
    @Output() itemChange = new EventEmitter();

    changeItem(event: any): void {
        event.stopImmediatePropagation();
        event.stopPropagation();
        event.preventDefault();

        if (this.disabled) {
            return;
        }

        this.itemChange.emit(!this.model);
    }
}
