<!-- Translation containers-->
@if (false) {
    <ng-container i18n="@@LOADING">Loading</ng-container>
    <ng-container i18n="@@UNVERIFIED_MATCHES">Unconfirmed</ng-container>
}
<!-- End of translation containers-->

<app-page-header mainNavigation="back" defaultBack="dashboard">
    <div title [translate]="'UNFINISHED_GAMES'" i18n="@@UNFINISHED_GAMES">Unfinished games</div>
</app-page-header>

<ion-content class="bg-white-pattern text-black">
    <ion-row>
        <ion-col style="padding: 0 1rem" class="text-center">
            <p i18n="@@UNFINISHED_GAMES_AUTO_REMOVED">Unfinished games will automatically be removed after 14 days</p>
        </ion-col>
    </ion-row>

    <ion-row>
        <ion-col>
            <ion-button
                class="extra-btn"
                color="danger"
                expand="block"
                (click)="removeAll()"
                [translate]="'REMOVE_ALL'"
                i18n="@@REMOVE_ALL">
                Remove all
            </ion-button>
        </ion-col>
    </ion-row>

    <div
        *ngFor="let gameplay of unfinishedGamesService.unfinishedGames; let index = index"
        style="padding: 0 0.5rem; margin-bottom: 0.5rem">
        <ion-row class="joinrow">
            <ion-col>
                <app-profile-photo
                    [ultimate]="gameplay.players[0].is_ultimate"
                    [badge]="gameplay.players[0].badge"
                    [rating]="gameplay.players[0].rating"
                    [timesRated]="gameplay.players[0].times_rated"
                    [ratingColor]="'dark'"
                    [size]="34"
                    [url]="gameplay.players[0].profile_photo_url"></app-profile-photo>
            </ion-col>
            <ion-col size="3" class="text text-left capitalize">
                <ion-icon
                    class="liveCamIcon"
                    color="light"
                    name="videocam-outline"
                    *ngIf="gameplay.players[0].room"></ion-icon>
                {{ gameplay.players[0].first_name }}
            </ion-col>
            <ion-col size="2" class="text text-center"> vs. </ion-col>
            <ion-col size="3" class="text text-right capitalize">
                <ion-icon
                    class="liveCamIcon"
                    color="light"
                    name="videocam-outline"
                    *ngIf="gameplay.players[1].room"></ion-icon>
                {{ gameplay.players[1].first_name }}
            </ion-col>
            <ion-col>
                <app-profile-photo
                    [ultimate]="gameplay.players[1].is_ultimate"
                    [badge]="gameplay.players[1].badge"
                    [rating]="gameplay.players[1].rating"
                    [timesRated]="gameplay.players[1].times_rated"
                    [ratingColor]="'dark'"
                    [size]="34"
                    [url]="gameplay.players[1].profile_photo_url"></app-profile-photo>
            </ion-col>
        </ion-row>
        <ion-row class="joinrowOdd">
            <ion-col class="text" style="padding-left: 10px">
                {{ onlineFunctions.onlineGameplayName(gameplay) }}
            </ion-col>
            <ion-col class="text centerFlex" style="padding-left: 10px">
                {{ onlineFunctions.getInterim(gameplay) }}
            </ion-col>
        </ion-row>
        <ion-row
            class="joinrowOdd"
            style="
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
                justify-content: space-between;
                align-items: center;
            ">
            <div style="padding: 0 0.75rem; font-size: 0.9em">
                {{ unfinishedGamesService.getDaysLeft(gameplay) }}
                <span style="text-transform: lowercase" [translate]="'DAYS_LEFT'" i18n="@@DAYS_LEFT">Days left</span>
            </div>
            <ion-row>
                <ion-button
                    style="padding: 0px 2px; margin-top: 4px"
                    padding
                    size="small"
                    color="danger"
                    (click)="deleteGame(gameplay, index)">
                    <span [translate]="'REMOVE'" i18n="@@REMOVE">Remove</span>
                </ion-button>
                <ion-button
                    style="padding: 0px 2px; margin-top: 4px"
                    padding
                    size="small"
                    color="primary"
                    (click)="saveGame(gameplay, index)">
                    <span [translate]="'SAVE'" i18n="@@SAVE">Save</span>
                </ion-button>
                <ion-button
                    style="padding: 0px 2px; margin-top: 4px; margin-right: 3px"
                    padding
                    size="small"
                    color="dark"
                    (click)="reinvite(gameplay)"
                    *ngIf="gameplay.onlineGameStatus !== 'removed'">
                    <span [translate]="'RE_INVITE'" i18n="@@RE_INVITE">Re-invite</span>
                </ion-button>
            </ion-row>
        </ion-row>
    </div>
</ion-content>
