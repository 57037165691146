<ion-content fullscreen class="bg-white-pattern ion-text-black">
    <app-page-header mainNavigation="back" defaultBack="account-settings">
        <div title [translate]="'EDIT_PASSWORD'" i18n="@@EDIT_PASSWORD">Change password</div>
    </app-page-header>
    <div appWebContainer class="max-w-xl mx-auto py-4 px-8 w-full">
        <form #form="ngForm" (ngSubmit)="SubmitPassword()" [formGroup]="editPasswordForm">
            <div class="relative flex flex-col">
                <div class="dialog-input-label">
                    {{ 'NEW_PASSWORD' | translate }}
                </div>
                <input
                    type="text"
                    class="dialog-input"
                    placeholder="{{ 'NEW_PASSWORD' | translate }}"
                    required
                    formControlName="new_password" />
                @if (form.submitted && editPasswordForm.get('new_password').errors) {
                    <app-validation-label [noAbsolute]="true">
                        <span [translate]="'PASS_ERROR'" i18n="@@PASS_ERROR">
                            The password must be at least 6 characters!
                        </span>
                    </app-validation-label>
                }
            </div>
            <div class="relative flex flex-col mt-2">
                <div class="dialog-input-label">
                    {{ 'REPEAT_NEW_PASSWORD' | translate }}
                </div>
                <input
                    type="text"
                    class="dialog-input"
                    placeholder="{{ 'REPEAT_NEW_PASSWORD' | translate }}"
                    required
                    formControlName="confirm_password" />
                @if (form.submitted && editPasswordForm.errors?.['PasswordNoMatch']) {
                    <app-validation-label [noAbsolute]="true">
                        <span [translate]="'REPEATPASS_ERROR'" i18n="@@REPEATPASS_ERROR">
                            The two passwords don't match!
                        </span>
                    </app-validation-label>
                }
            </div>
            <app-primary-button
                class="block w-full mt-4"
                size="medium"
                addedClasses="w-full"
                [translate]="'CONFIRM'"
                i18n="@@CONFIRM"
                color="orange"
                type="submit"
                [disabled]="!form.valid">
                Confirm
            </app-primary-button>
        </form>
    </div>
</ion-content>
