import { Injectable, signal, WritableSignal } from '@angular/core';
import { LocalStorageKey } from '@dc-core/dc-localstorage';

export type AppTheme = 'light' | 'dark';
export type SelectableTheme = 'system' | AppTheme;

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    public theme: WritableSignal<AppTheme> = signal(null);
    public selectedTheme: WritableSignal<SelectableTheme> = signal(null);

    public initTheme(): void {
        const theme = localStorage.getItem(LocalStorageKey.theme);
        if (theme) {
            this.changeTheme(theme as SelectableTheme);
        } else {
            this.changeTheme('dark', false);
        }
    }

    public changeTheme(theme: SelectableTheme, saveTheme = true): void {
        this.selectedTheme.set(theme);

        if (theme === 'light' || theme === 'dark') {
            this.applyTheme(theme);
        } else {
            this.applySystemPreference();
        }

        if (saveTheme) {
            localStorage.setItem(LocalStorageKey.theme, theme);
        }
    }

    private applyTheme(theme: AppTheme) {
        if (theme === 'dark') {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
        this.theme.set(theme);
    }

    private applySystemPreference() {
        const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
        this.applyTheme(prefersDark ? 'dark' : 'light');
    }
}
