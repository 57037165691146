<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="close()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title" [translate]="'VS_DARTBOT'" i18n="@@VS_DARTBOT">vs. Dartbot</div>
    </div>
    <div class="slide-modal-content text-black">
        <div>
            <ion-range
                min="1"
                style="padding-top: 0; padding-bottom: 0"
                [max]="dartbotLevels?.length"
                name="dartbotMax"
                ngDefaultControl
                [value]="cpuLevel"
                [(ngModel)]="cpuLevel"
                color="secondary">
            </ion-range>
        </div>
        <div class="text-md text-center font-bold">
            <span class="bold" [translate]="'DARTBOT_LEVEL'" i18n="@@DARTBOT_LEVEL">Dartbot level</span> {{ cpuLevel }}
        </div>
        @if (gameMode === 'match') {
            <div class="text-md text-center">{{ GetCPUAverage() }}</div>
        }
        <app-primary-button
            class="mt-4"
            type="submit"
            size="normal"
            (click)="confirm()"
            addedClasses="w-full"
            color="orange"
            [translate]="'CONFIRM'"
            i18n="@@CONFIRM">
            Confirm
        </app-primary-button>
    </div>
</div>
