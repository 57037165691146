import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { CameraStreamClipApiService } from '@dc-api/camera-stream-clip.api.service';
import { CameraStreamClip } from '@dc-core/dc-backend/dc-classes';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { InGameCameraService } from '@dc-core/dc-services/camera/ingame-camera.service';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { UpgradeService } from '@providers/UpgradeService';
import { AuthService } from '@services/auth.service';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { GA_EVENTACTIONS, GA_EVENTCATEGORIES } from 'src/app/app.globals';

@Component({
    selector: 'app-highlights-dialog',
    templateUrl: 'highlights.dialog.html',
})
export class HighlightsDialogComponent implements OnInit, OnDestroy {
    @Input() clips: CameraStreamClip[] = [];

    private authService: AuthService = inject(AuthService);
    private navController: NavController = inject(NavController);
    private modalController: ModalController = inject(ModalController);
    private upgradeService: UpgradeService = inject(UpgradeService);
    private ga: DartCounterAnalyticsService = inject(DartCounterAnalyticsService);
    private alertService: DartCounterAlertService = inject(DartCounterAlertService);
    private platform: Platform = inject(Platform);
    private translateService: TranslateService = inject(TranslateService);
    private cameraStreamClipApiService: CameraStreamClipApiService = inject(CameraStreamClipApiService);
    private ingameCameraService: InGameCameraService = inject(InGameCameraService);

    public savedReplaysText: string = null;
    public recordedText: string = null;

    private backButtonSubscription: Subscription;

    public ngOnInit(): void {
        this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(9999, () => {
            this.close();
        });

        this.clips.forEach((clip) => {
            clip.durationText = moment('2015-01-01').startOf('day').seconds(clip.duration).format('mm:ss');
        });

        $localize`:@@NUM_REPLAYS_SAVED:${this.clips.length}:num: replays saved`;
        $localize`:@@RECORDED_AT:Recorded at ${moment().format('D MMM yyyy')}:date:`;
        this.savedReplaysText = this.translateService.instant('NUM_REPLAYS_SAVED', { num: this.clips.length });
        this.recordedText = this.translateService.instant('RECORDED_AT', { date: moment().format('D MMM yyyy') });
    }

    public ngOnDestroy(): void {
        if (this.backButtonSubscription) {
            this.backButtonSubscription.unsubscribe();
        }

        this.ingameCameraService.clips = [];
    }

    public close(returnValue: any = null): void {
        this.modalController.dismiss(returnValue);
    }

    public async toggleFavorite(clip: CameraStreamClip): Promise<void> {
        if (!this.authService.user.is_ultimate) {
            this.ga.trackEvent(GA_EVENTCATEGORIES.ACTION_REPLAYS, GA_EVENTACTIONS.FAVORITENOTULTIMATE);

            const dialogSettings = await this.upgradeService.GetUpgradeDialog(GA_EVENTCATEGORIES.ACTION_REPLAYS);
            this.modalController.create(dialogSettings).then((elem) => {
                elem.present();
            });
            return;
        }

        this.cameraStreamClipApiService
            .updateCameraStreamClipById({
                clipId: clip.id,
                favorite: clip.favorited_at ? false : true,
            })
            .then((res) => {
                clip.favorited_at = res.data.favorited_at;

                this.ga.trackEvent(
                    GA_EVENTCATEGORIES.ACTION_REPLAYS,
                    clip.favorited_at ? GA_EVENTACTIONS.FAVORITED : GA_EVENTACTIONS.UNFAVORITED
                );
            })
            .catch((err) => {
                this.ga.trackEvent(GA_EVENTCATEGORIES.ACTION_REPLAYS, GA_EVENTACTIONS.REACHEDMAXFAVORITES);
                this.alertService.errorFromApi(err, null, true);
            });
    }

    public gallery(): void {
        this.close();
        this.navController.navigateForward('action-replays');
    }
}
