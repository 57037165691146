/* eslint-disable max-len */
import { Component, inject, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GameApiService } from '@dc-api/game.api.service';
import { GameLabelDataList } from '@dc-core/dc-backend/dc-interfaces';
import { AlertPayload } from '@dc-core/dc-services/alert.service';
import { ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { RouterEventsService } from '@services/router-events.service';
import { Match, MatchLeg, MatchSet, MatchTeam, MatchUser } from 'dc-core/dc-backend/dc-classes';
import _, { round } from 'lodash';
import * as moment from 'moment';
import { take } from 'rxjs';
import { GA_EVENTCATEGORIES } from 'src/app/app.globals';
import {
    AssignGameLabelsDialogComponent,
    AssignGameLabelsDialogPayload,
} from 'src/dialogs/assign-game-labels/assign-game-labels.dialog';
import { PromptDialogComponent } from 'src/dialogs/prompt/prompt.dialog';
import { SocialShareDialogComponent } from 'src/dialogs/socialshare/socialshare';
import { DCLoadingService } from 'src/providers/DCLoadingService';
import { UpgradeService } from 'src/providers/UpgradeService';
import { AuthService } from 'src/services/auth.service';

// w-1/2 w-1/3 w-1/4 KEEP THIS LINE

export interface MatchLegDetail {
    name: string;
    hasWon: boolean;
    threeDartAverage: number;
    dartsThrown: number;
    remainingScores: { score; remaining }[];
}
@Component({
    selector: 'app-match-details',
    templateUrl: 'match-details.component.html',
    styleUrls: ['match-details.component.scss'],
})
export class MatchDetailsComponent {
    @ViewChild('captureSquare') captureSquare;
    @ViewChild('capturePortrait') capturePortrait;
    @ViewChild('captureLandscape') captureLandscape;

    public translateService: TranslateService = inject(TranslateService);

    public title: string;
    public item: Match = null;
    public enableCapture = false;
    public contenders: MatchUser[] | MatchTeam[];
    public scores: GameLabelDataList[] = [];
    public sets: {
        index: number;
        winner: string | null;
        expanded: boolean;
        legs: { index: number; winner: string | null; details: MatchLegDetail[]; expanded: boolean }[];
    }[] = [];
    public ownGame = false;

    public squareSharable: string = null;
    public portraitSharable: string = null;

    constructor(
        public nav: NavController,
        public auth: AuthService,
        public loading: DCLoadingService,
        private upgrade: UpgradeService,
        public modal: ModalController,
        public route: ActivatedRoute,
        private gameApiService: GameApiService,
        private _previousRouteService: RouterEventsService
    ) {
        this.route.queryParams.pipe(take(1)).subscribe((params) => {
            let item = params.item;
            if ((!item || !item.id) && params.gameId) {
                item = {
                    id: params.gameId,
                };
            }

            this.GetItemDetail(item);
        });
    }

    getMatchUserName(matchUser: MatchUser): string {
        let name = '';

        if (matchUser.user) {
            name = matchUser.user.first_name;
        } else {
            name = matchUser.ghost_name;
        }

        if (matchUser.is_cpu) {
            name = name + ' (' + matchUser.cpu_level + ')';
        }

        return name;
    }

    GetItemDetail(item) {
        this.loading.ShowCustomLoader({ showBackdrop: false });
        this.item = item;
        this.gameApiService
            .getMatchById({ matchId: item.id })
            .then((res) => {
                this.item = res.data;
                this.item.started_at = moment(this.item.started_at).toISOString();
                setTimeout(() => {
                    this.loading.DismissLoader();
                }, 250);
                if (
                    (!this.item.has_teams && this.item.users && this.item.users.length == 2) ||
                    (this.item.has_teams && this.item.teams && this.item.teams.length == 2)
                ) {
                    this.enableCapture = true;
                }

                if (this.item.has_teams) {
                    this.item.teams.forEach((team) => {
                        if (_.find(team.users, (user) => user.user_id === this.auth.user.id)) {
                            this.ownGame = true;
                        }
                    });
                    this.contenders = this.item.teams;
                } else {
                    this.contenders = this.item.users;
                    if (_.find(this.item.users, (user) => user.user_id === this.auth.user.id)) {
                        this.ownGame = true;
                    }
                }

                this.loadGameScoreList();

                if (this.item.has_sets) {
                    this.item.sets.forEach((set, setIndex) => {
                        const legs = [];
                        set.legs.forEach((leg, legIndex) => {
                            legs.push({
                                index: legIndex + 1,
                                winner: this.getWinnerForTeamLeg(leg),
                                details: this.getLegDetails(leg),
                            });
                        });
                        this.sets.push({
                            index: setIndex + 1,
                            winner: this.getWinnerForTeamSet(set),
                            legs,
                            expanded: false,
                        });
                    });
                } else {
                    const legs = [];
                    this.item.legs.forEach((leg, index) => {
                        legs.push({
                            index: index + 1,
                            winner: this.getWinnerForTeamLeg(leg),
                            details: this.getLegDetails(leg),
                        });
                    });
                    this.sets.push({
                        index: 1,
                        winner: this.getWinnerForMatch(),
                        legs,
                        expanded: false,
                    });
                }
            })
            .catch(() => {
                setTimeout(() => {
                    this.loading.DismissLoader();
                }, 250);
                // Error go back
                this.nav.pop();
            });
    }

    getLegDetails(leg: MatchLeg): MatchLegDetail[] {
        const legDetails: MatchLegDetail[] = [];
        this.contenders.forEach((contender: MatchTeam | MatchUser) => {
            let totalScore = 0;
            let dartsThrown = 0;
            const remainingScores: { score: number; remaining: number }[] = [];

            if (this.item.has_teams) {
                leg.turns.forEach((turn) => {
                    (contender as MatchTeam).users.forEach((user) => {
                        if (turn.match_user_id === user.id) {
                            totalScore += turn.total_score;
                            dartsThrown += turn.darts_thrown;
                            remainingScores.push({ score: turn.total_score, remaining: turn.remaining_score });
                        }
                    });
                });
            } else {
                leg.turns
                    .filter((turn) => turn.match_user_id === contender.id)
                    .forEach((turn) => {
                        totalScore += turn.total_score;
                        dartsThrown += turn.darts_thrown;
                        remainingScores.push({ score: turn.total_score, remaining: turn.remaining_score });
                    });
            }

            legDetails.push({
                name: this.item.has_teams ? this.GetPlayers(contender) : this.getMatchUserName(contender as MatchUser),
                hasWon: this.getHasWonForTeamLeg(leg, contender),
                threeDartAverage: dartsThrown > 0 ? round((totalScore / dartsThrown) * 3, 2) : 0,
                dartsThrown,
                remainingScores,
            });
        });
        return legDetails;
    }

    getWinnerForMatch(): string {
        if (this.item.has_teams) {
            for (const team of this.item.teams) {
                for (const user of team.users) {
                    if (user.result === 'won') {
                        return this.GetPlayers(team);
                    }
                }
            }
        } else {
            for (const user of this.item.users) {
                if (user.result === 'won') {
                    return this.getMatchUserName(user);
                }
            }
        }
        return '';
    }

    getWinnerForTeamSet(set: MatchSet): string | null {
        if (this.item.has_teams) {
            for (const team of this.item.teams) {
                for (const user of team.users) {
                    if (set.winner_id === user.id) {
                        return team.name;
                    }
                }
            }
        } else {
            for (const user of this.item.users) {
                if (set.winner_id === user.id) {
                    return user.name;
                }
            }
        }
        return null;
    }

    getWinnerForTeamLeg(leg: MatchLeg): string | null {
        if (this.item.has_teams) {
            for (const team of this.item.teams) {
                for (const user of team.users) {
                    if (leg.winner_id === user.id) {
                        return team.name;
                    }
                }
            }
        } else {
            for (const user of this.item.users) {
                if (leg.winner_id === user.id) {
                    return user.name;
                }
            }
        }
        return null;
    }

    getHasWonForTeamLeg(leg: MatchLeg, contender: MatchTeam | MatchUser): boolean {
        if (this.item.has_teams) {
            for (const team of this.item.teams) {
                if (team.id === contender.id) {
                    for (const user of team.users) {
                        if (leg.winner_id === user.id) {
                            return true;
                        }
                    }
                }
            }
        } else {
            if (leg.winner_id === contender.id) {
                return true;
            }
        }
        return false;
    }

    GetHundredPlus(scores) {
        let totalHundreds = 0;
        totalHundreds += scores[100];
        totalHundreds += scores[120];
        totalHundreds += scores[140];
        totalHundreds += scores[180];

        return totalHundreds;
    }

    Share() {
        this.modal
            .create({
                component: SocialShareDialogComponent,
                componentProps: {
                    matchId: this.item.id,
                    squareSharable: this.squareSharable,
                    portraitSharable: this.portraitSharable,
                },
                cssClass: 'auto-height',
                backdropDismiss: true,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    this.squareSharable = dialogRes.data.squareSharable;
                    this.portraitSharable = dialogRes.data.portraitSharable;
                });
            });
    }

    async OpenSubscriptionDialog() {
        const dialogSettings = await this.upgrade.GetUpgradeDialog(GA_EVENTCATEGORIES.GAMEDETAILSX01);
        this.modal.create(dialogSettings).then((elem) => {
            elem.present();
        });
    }

    loadGameScoreList(): void {
        const gameScoreList = [];
        const scoresList = [
            { label: '180', from: 180, to: 180 },
            { label: '160+', from: 160, to: 179 },
            { label: '140+', from: 140, to: 159 },
            { label: '120+', from: 120, to: 139 },
            { label: '100+', from: 100, to: 119 },
            { label: '80+', from: 80, to: 99 },
            { label: '60+', from: 60, to: 79 },
            { label: '40+', from: 40, to: 59 },
        ];

        const names = [];
        this.contenders.forEach((contender: MatchTeam | MatchUser) => {
            names.push(contender.name);
        });
        gameScoreList.push({
            label: null,
            data: names,
        });

        for (const scoreItem of scoresList) {
            const counts = [];
            this.contenders.forEach((contender: MatchTeam | MatchUser) => {
                if (this.item.has_teams) {
                    let count = 0;
                    (contender as MatchTeam).users.forEach((user) => {
                        this.item.legs.forEach((leg) => {
                            leg.turns
                                .filter(
                                    (turn) =>
                                        turn.match_user_id === user.id &&
                                        turn.total_score >= scoreItem.from &&
                                        turn.total_score <= scoreItem.to
                                )
                                .forEach(() => count++);
                        });
                    });
                    counts.push(count);
                } else {
                    let count = 0;
                    this.item.legs.forEach((leg) => {
                        leg.turns
                            .filter(
                                (turn) =>
                                    turn.match_user_id === (contender as MatchUser).id &&
                                    turn.total_score >= scoreItem.from &&
                                    turn.total_score <= scoreItem.to
                            )
                            .forEach(() => count++);
                    });
                    counts.push(count);
                }
            });

            gameScoreList.push({
                label: scoreItem.label,
                data: counts,
            });
        }

        this.scores = gameScoreList;
    }

    GetPlayers(team): string {
        const playerNames = team.users.map((user) => {
            return this.getMatchUserName(user);
        });

        return playerNames.join(', ');
    }

    expandItem(item, items): void {
        if (item.expanded) {
            item.expanded = false;
        } else {
            items.map((listItem) => {
                if (item === listItem) {
                    listItem.expanded = !listItem.expanded;
                } else {
                    listItem.expanded = false;
                }
                return listItem;
            });
        }
    }

    TryRemove() {
        //Show Dialog, do you want to invite your opponent to continue this match?
        let promptDialog;
        this.modal
            .create({
                component: PromptDialogComponent,
                componentProps: {
                    title: $localize`:@@REMOVE_GAME:Remove game`,
                    text: $localize`:@@REMOVING_UNVERIFIED_GAME:Are you sure you want to delete this game from your statistics?`,
                    cancelText: $localize`:@@CANCEL:Cancel`,
                    confirmText: $localize`:@@CONFIRM:Confirm`,
                    confirmColor: 'red',
                } as AlertPayload,
                cssClass: 'auto-height',
                showBackdrop: true,
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();

                promptDialog = elem;
                promptDialog.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.loading.ShowCustomLoader({ showBackdrop: false }).then(() => {
                            this.loading.DismissLoader();
                            this.gameApiService
                                .removeMatches({
                                    match_ids: [this.item.id],
                                })
                                .then(() => {
                                    this.loading.DismissLoader();
                                    this.back(true);
                                })
                                .catch(() => {
                                    this.loading.DismissLoader();
                                });
                        });
                    }
                });
            });
    }

    assignGameLabels() {
        this.modal
            .create({
                component: AssignGameLabelsDialogComponent,
                componentProps: {
                    gameMode: 'match',
                    gameId: this.item.id,
                    assignedGameLabels: this.item.labels,
                } as AssignGameLabelsDialogPayload,
                cssClass: 'auto-height',
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();
                elem.onWillDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.item.labels = dialogRes.data;
                    }
                });
            });
    }

    back(refresh: boolean): void {
        if (this._previousRouteService.getPreviousUrl()?.includes('statistics/match/list')) {
            this.nav.navigateBack('statistics/match/list', {
                queryParams: {
                    refresh,
                },
            });
        } else {
            this.nav.back();
        }
    }
}
