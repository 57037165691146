import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { StatisticsApiService } from '@dc-api/statistics.api.service';
import { RecapStatisticsResponse } from '@dc-core/dc-backend/dc-responses';
import { LocalStorageKey } from '@dc-core/dc-localstorage';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { DCLoadingService } from '@providers/DCLoadingService';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-year-recap-dialog',
    templateUrl: 'year-recap.dialog.html',
    styleUrls: ['year-recap.dialog.scss'],
})
export class YearRecapDialogComponent implements AfterViewInit {
    @Input() year = new Date().getFullYear();
    animate = true;
    recap: RecapStatisticsResponse = null;

    public slideIn = [];

    constructor(
        public view: ModalController,
        public route: ActivatedRoute,
        public nav: NavController,
        public auth: AuthService,
        public modal: ModalController,
        public _modal: ModalController,
        public platform: Platform,
        public loading: DCLoadingService,
        private _socialSharing: SocialSharing,
        private _statisticsApiService: StatisticsApiService,
        private _changeDetectorRef: ChangeDetectorRef
    ) {}

    ngAfterViewInit(): void {
        this._statisticsApiService.getRecapStatistics({}).then((res) => {
            this.recap = res.data;
            this.year = res.data.year;

            this.showAnimations(0, 10, 750);

            localStorage.setItem(LocalStorageKey.lastRecapYear, JSON.stringify(this.year));
        });
    }

    showAnimations(index, stop, delay = 500) {
        this.slideIn[index] = true;
        this._changeDetectorRef.detectChanges();

        if (index < stop) {
            setTimeout(() => {
                index++;
                this.showAnimations(index, stop, delay);
            }, delay);
        }
    }

    refresh(): void {
        this.animate = false;
        this.recap = null;

        this._statisticsApiService.getRecapStatistics({ refresh: true }).then((res) => {
            this.recap = res.data;
        });
    }

    share(): void {
        this.loading.ShowDefaultLoader();

        this.auth.fetcher
            .path('/statistics/recap/share')
            .method('get')
            .create()({})
            .then((res) => {
                const image = 'data:image/png;base64,' + res.data.base64;
                this._socialSharing
                    .share(null, this.year + ' recap', image)
                    .then(() => {
                        this.dismissModal();
                    })
                    .finally(() => this.loading.DismissLoader());
            })
            .catch(() => {
                this.loading.DismissLoader();
            });
    }

    public dismissModal(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }
}
