import { Component, inject, Input, OnInit, output, OutputEmitterRef } from '@angular/core';
import { SetSingleDartPayload } from '@dc-core/dc-gamelogic/in-game/ingame.globals';
import { OmniSingleDart } from '@dc-core/dc-services/omni/omni-ingame.service';
import { ModalController } from '@ionic/angular';

export type EditOmniScorePayload = {
    point: OmniSingleDart;
    svgContent: string;
    dartIndex: number;
};

@Component({
    selector: 'app-edit-omni-score-dialog',
    templateUrl: 'edit-omni-score.dialog.html',
})
export class EditOmniScoreDialogComponent implements OnInit {
    @Input() point: OmniSingleDart;
    @Input() svgContent: string;
    @Input() dartIndex: number;

    private modalController: ModalController = inject(ModalController);
    public editablePoint: SetSingleDartPayload = null;
    public setSingleDart: OutputEmitterRef<SetSingleDartPayload> = output<SetSingleDartPayload>();

    public ngOnInit(): void {
        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.
        this.editablePoint = { ...this.point.singleDart };
    }

    public saveEditedScore(): void {
        this.point.singleDart = this.editablePoint;
        this.modalController.dismiss();
    }

    public dismiss(returnValue: any = null): void {
        if (returnValue !== null) {
            returnValue = Math.floor(returnValue);
        }
        this.modalController.dismiss(returnValue);
    }

    public setNewSingleDartValue(payload: SetSingleDartPayload) {
        this.editablePoint = payload;
    }
}
