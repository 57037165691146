import { Component, Input, OnInit } from '@angular/core';
import { UserApiService } from '@dc-api/user.api.service';
import { User } from '@dc-core/dc-backend/dc-classes';
import { SuspensionReason } from '@dc-core/dc-backend/dc-enums';
import { ChatsCollectionService } from '@dc-core/dc-firestore/collection-helpers/chats.collection.service';
import { FSCHatMessage } from '@dc-core/dc-firestore/globals/firestore.tables';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { InGameCameraService } from '@dc-core/dc-services/camera/ingame-camera.service';
import { DropdownSelectItem } from '@dc-core/dc-statistics/statistics.globals';
import { ModalController } from '@ionic/angular';
import _ from 'lodash';

@Component({
    selector: 'app-report-user-dialog',
    templateUrl: 'report-user.dialog.html'
})
export class ReportUserDialogComponent implements OnInit {
    @Input() user: User;
    public reason = '';
    public block = true;

    suspensionReason: SuspensionReason = null;
    suspensionReasons: DropdownSelectItem[] = [
        {
            label: $localize`:@@SELECT_A_REASON:Select a reason`,
            value: null,
            hidden: true,
        },
        {
            label: $localize`:@@VERBAL_ABUSE_INFO:Verbal abuse (Harassment, offensive language)`,
            value: 'verbal_abuse' as SuspensionReason,
        },
        {
            label: $localize`:@@VISUAL_ABUSE_INFO:Visual abuse (Hateful video content)`,
            value: 'visual_abuse' as SuspensionReason,
        },
        {
            label: $localize`:@@HATE_SPEECH_INFO:Hate speech (Bullying, racism, sexism, homophobia, etc.)`,
            value: 'hate_speech' as SuspensionReason,
        },
        {
            label: $localize`:@@SEXUAL_MISCONDUCT_INFO:Sexual misconduct (Inappropriate video / chat content)`,
            value: 'sexual_misconduct' as SuspensionReason,
        },
        { label: $localize`:@@CHEATING:Cheating`, value: 'cheating' as SuspensionReason },
        {
            label: $localize`:@@OFFENSIVE_OR_INAPPROPRIATE_PROFILE:Offensive or Inappropriate profile`,
            value: 'offensive_or_inappropriate_profile' as SuspensionReason,
        },
    ];
    errors: { reason: boolean; suspensionReason: boolean } = { reason: false, suspensionReason: false };

    ADDITIONAL_INFORMATION = $localize`:@@ADDITIONAL_INFORMATION:Additional information`;

    private reportClipFormData: FormData = null;

    constructor(
        public view: ModalController,
        private _alertService: DartCounterAlertService,
        private _userApiService: UserApiService,
        private _chatsCollectionService: ChatsCollectionService,
        private _ingameCameraService: InGameCameraService
    ) {}

    public ngOnInit(): void {
        try {
            this._ingameCameraService.stopRecording(this.user.id, false).finally(async () => {
                this.reportClipFormData = this._ingameCameraService.getReportClipFormData(this.user.id);
            });
        } catch (err) {
            console.error(err);
        }
    }

    public report(): void {
        if (!this.reason || !this.suspensionReason) {
            this.errors.reason = !this.reason;
            this.errors.suspensionReason = !this.suspensionReason;

            this._alertService.createAlert({
                title: $localize`:@@FILL_IN_REQUIRED_FIELDS:Please fill in the required fields`,
                icon: 'error',
            });
            return;
        }

        const chatLog: FSCHatMessage[] = _.cloneDeep(this._chatsCollectionService.chatLog);
        chatLog.forEach((chat) => {
            chat.sender = {
                user_id: chat.sender.user_id,
            } as any;
        });
        this._userApiService
            .reportUserById({
                userId: this.user.id,
                reason: this.reason,
                suspension_reason: this.suspensionReason,
                chat_log: JSON.stringify(chatLog),
                block: this.block,
            })
            .then((res) => {
                this._alertService.createAlert({
                    title: $localize`:@@REPORTED_THE_PLAYER:You reported the player`,
                    icon: 'success',
                });

                if (this.block) {
                    this.user.is_blocked = true;
                }

                if (this.reportClipFormData) {
                    this._ingameCameraService.finishReportClip(res.data, this.reportClipFormData);
                }

                this.close(true);
            })
            .catch(() => this.close(false));
    }

    public close(reported = false): void {
        this.view.dismiss(reported);
    }
}
