/* eslint-disable max-len */
import { HttpClient } from '@angular/common/http';
import {
    Component,
    DestroyRef,
    effect,
    ElementRef,
    inject,
    input,
    InputSignal,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { OmniIngameService } from '@dc-core/dc-services/omni/omni-ingame.service';
import * as d3 from 'd3';
import * as h337 from 'heatmap.js';

export interface PolarCoordinate {
    r: number; // radius
    theta: number; // angle in radians
}

export interface CartesianCoordinate {
    x: number;
    y: number;
    value?: number;
}

@Component({
    selector: 'app-omni-heatmap',
    standalone: true,
    templateUrl: './omni-heatmap.component.html',
    styleUrls: ['./omni-heatmap.component.scss'],
})
export class OmniHeatmapComponent implements OnInit {
    @ViewChild('omniHeatmapWrapper', { static: true }) public svgWrapper: ElementRef;
    public coordinates: InputSignal<CartesianCoordinate[]> = input<CartesianCoordinate[]>();

    private dartboardSvgUrl: string = 'assets/images/tor_board.svg'; // Path to your SVG file
    public dartboardSvg: SVGElement;
    public loaded: boolean = false;

    @ViewChild('heatmapContainer') public heatmapContainer: ElementRef;
    private heatmapInstance: any;
    svgSize: number;
    svgContent: string;

    public dartHitsByPlayer: CartesianCoordinate[] = [];
    private static cachedSvg: SVGElement | null = null; // Cache static variable for the SVG

    private screenOrientation: ScreenOrientation = inject(ScreenOrientation);
    private destroyRef: DestroyRef = inject(DestroyRef);
    private retryCount = 0;
    private maxRetries = 10;

    constructor(
        private _http: HttpClient,
        private _renderer: Renderer2,
        private _elementRef: ElementRef,
        private _ingameOmniService: OmniIngameService
    ) {
        effect(() => {
            if (this.coordinates() && this.heatmapInstance) {
                this.generateHeatmap();
            }
        });
    }

    ngOnInit(): void {
        this.loadSvg();

        this.screenOrientation
            .onChange()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                this.clearHeatmap();
                this.setSVGSize();
            });
    }

    private loadSvg(): void {
        if (OmniHeatmapComponent.cachedSvg) {
            // Use cached SVG
            this.dartboardSvg = OmniHeatmapComponent.cachedSvg.cloneNode(true) as SVGElement;
            this.setSVGSize();
        } else {
            // Load SVG via HTTP only if not cached
            this._http.get(this.dartboardSvgUrl, { responseType: 'text' }).subscribe((dartboardSvg) => {
                this.svgContent = dartboardSvg;

                const tempDiv = document.createElement('div');
                tempDiv.innerHTML = dartboardSvg.trim();
                this.dartboardSvg = tempDiv.querySelector('svg');

                if (this.dartboardSvg) {
                    OmniHeatmapComponent.cachedSvg = this.dartboardSvg.cloneNode(true) as SVGElement; // Cache the SVG
                    this.setSVGSize();
                }
            });
        }
    }

    private setSVGSize() {
        if ((!this.svgWrapper || !this.heatmapContainer) && this.retryCount < this.maxRetries) {
            this.retryCount++;
            setTimeout(() => this.setSVGSize(), 50);
            return;
        } else if (this.retryCount >= this.maxRetries) {
            console.error('Failed to initialize SVG after multiple attempts.');
            return;
        }
        this.retryCount = 0;

        const viewportWidth = this.svgWrapper.nativeElement.clientWidth;
        const viewportHeight = this.svgWrapper.nativeElement.clientHeight;

        if (!viewportWidth || !viewportHeight) {
            return;
        }

        const svgSize = Math.min(viewportWidth, viewportHeight);

        this.svgSize = svgSize;
        this.dartboardSvg.setAttribute('width', `${svgSize}`);
        this.dartboardSvg.setAttribute('height', `${svgSize}`);

        const container = this._elementRef.nativeElement.querySelector('.heatmap-container');
        container.appendChild(this.dartboardSvg); // Append the SVG element

        this.initializeHeatmap();
        this.generateHeatmap();
    }

    private initializeHeatmap(): void {
        if (this.heatmapInstance) {
            return;
        }

        const container = this.heatmapContainer.nativeElement;

        this.heatmapInstance = h337.create({
            container: container,
            radius: 12, // Increased slightly for better visual spread
            maxOpacity: 1, // Maximum opacity for visible heatmap spots
            minOpacity: 0.4, // Minimum opacity to make the edges blend
            blur: 0.9, // Slight blur to smooth out the heatmap spots
            gradient: {
                0.0: 'rgba(0, 255, 0, 0)', // Greenish color (transparent edges)
                0.2: 'yellow', // Yellow for low intensity
                0.4: 'orange', // Orange for medium intensity
                0.6: 'red', // Red for high intensity
                0.8: 'darkred', // Dark red for very high intensity
                1.0: 'black', // Black for the highest intensity (center spots)
            },
            // gradient: {
            //     0.0: 'blue',
            //     0.2: 'cyan',
            //     0.4: 'lime',
            //     0.6: 'yellow',
            //     0.8: 'orange',
            //     1.0: 'red', // Red for highest intensity
            // },
        });

        const heatmapCanvas = this.heatmapContainer.nativeElement.querySelector('canvas');
        if (heatmapCanvas) {
            heatmapCanvas.style.zIndex = '10'; // Ensure heatmap is on top of the overlay
        }
    }

    public generateHeatmap(): void {
        this.clearHeatmap();

        // Get viewBox dimensions from the SVG
        const svg = d3.select(this.dartboardSvg);

        const viewBox = svg.attr('viewBox');
        if (viewBox) {
            const [minX, minY, viewBoxWidth, viewBoxHeight] = viewBox.split(' ').map(Number);
            this.svgSize = viewBoxWidth;
            this.svgSize = viewBoxHeight;
        } else {
            console.error('SVG dimensions could not be determined.');
            return;
        }

        // Get the actual rendered size of the SVG
        const renderedWidth = this.heatmapContainer.nativeElement.clientWidth;

        // Compute the scaling factor between the viewBox and the rendered size
        const scale = renderedWidth / this.svgSize;

        let hitpoints = this.coordinates();
        if (!hitpoints) {
            hitpoints = this._ingameOmniService.coordinates;
        }

        // Pass raw coordinates to heatmap.js, scaling them based on viewBox and the rendered size
        hitpoints = hitpoints.map((coord) => ({
            x: Math.round((coord.x + this.svgSize / 2) * scale), // Scale and translate X-coordinate
            y: Math.round((coord.y + this.svgSize / 2) * scale), // Scale and translate Y-coordinate
            value: 1, // Each point adds 1 to the heatmap intensity
        }));

        // Dynamically set the maximum value based on the number of hitpoints
        const totalPoints = hitpoints.length;
        const dynamicMax = Math.min(5, Math.max(2, Math.round(totalPoints / 30))); // Set a cap on max (5), min (2) and adjust based on the number of points

        // console.log('Total points:', totalPoints);
        // console.log('Dynamic max:', dynamicMax);

        // Now set the heatmap data
        this.heatmapInstance.setData({
            max: dynamicMax, // Dynamically adjust max based on the number of points
            data: hitpoints,
        });

        this.loaded = true;
    }

    clearHeatmap(): void {
        if (this.heatmapInstance) {
            // Set empty data to effectively clear the heatmap
            this.heatmapInstance.setData({
                min: 0,
                max: 1, // Setting max to 1, but no data points
                data: [],
            });
        }
    }

    ionViewWillLeave(): void {
        this.clearHeatmap(); // Clear heatmap when leaving the view
    }
}
