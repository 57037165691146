import { Component, OnInit } from '@angular/core';
import { InAppBrowser, InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { ConfigApiService } from '@dc-api/config.api.service';
import { GameApiService } from '@dc-api/game.api.service';
import { LocalStorageKey } from '@dc-core/dc-localstorage';
import { MenuController, ModalController, NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { AuthService } from '@services/auth.service';
import { DartCounterPreferenceService } from 'dc-core/dc-services/preference/preference.service';
import { GA_EVENTACTIONS, GA_EVENTCATEGORIES } from 'src/app/app.globals';
import { RateAppDialogDialogComponent } from 'src/dialogs/rate-app/rate-app.dialog';
import { environment } from 'src/environments/environment';

/**
 * Generated class for the Games page.
 *
 * See http://ionicframework.com/docs/components/#navigation for more info
 * on Ionic pages and navigation.
 */

@Component({
    selector: 'app-page-local-games',
    templateUrl: 'local-games.component.html',
})
export class LocalGamesComponent implements OnInit {
    public banner: any;
    public bannerURL: any;
    public games: { title: string; link: string; new?: boolean }[];

    constructor(
        public auth: AuthService,
        private iab: InAppBrowser,
        private nav: NavController,
        public menu: MenuController,
        public modal: ModalController,
        public platform: Platform,
        private ga: DartCounterAnalyticsService,
        private translate: TranslateService,
        public preferenceService: DartCounterPreferenceService,
        private _configApiService: ConfigApiService,
        public gameApiService: GameApiService
    ) {}

    ionViewDidEnter() {
        this.ga.trackView('New local game');
        this.menu.enable(true, 'mainmenu');
        this.menu.enable(false, 'ingamemenu');
    }

    ngOnInit() {
        if (this.platform.is('capacitor')) {
            const appRating = localStorage.getItem(LocalStorageKey.appRating);
            if (appRating) {
                const appRatingNum = parseInt(appRating);
                if (appRatingNum < 2) {
                    localStorage.setItem(LocalStorageKey.appRating, (appRatingNum + 1).toString());
                } else if (appRatingNum === 2) {
                    localStorage.setItem(LocalStorageKey.appRating, (appRatingNum + 1).toString());
                    this.modal
                        .create({
                            component: RateAppDialogDialogComponent,
                            cssClass: 'auto-height',
                            backdropDismiss: false,
                            showBackdrop: true,
                        })
                        .then((elem) => {
                            elem.present();
                        });
                }
            } else {
                localStorage.setItem(LocalStorageKey.appRating, '1');
            }
        }

        this.gameApiService.getUnfinishedLocalGames();

        this._configApiService.getConfig({}).then((res) => {
            if (
                res.data['Banner_' + this.preferenceService.lang.toUpperCase()] &&
                res.data['Banner_' + this.preferenceService.lang.toUpperCase()]['value']
            ) {
                this.banner =
                    environment.apiUrl +
                    '/config/file/' +
                    res.data['Banner_' + this.preferenceService.lang.toUpperCase()]['value'];
                this.bannerURL = res.data['Banner_' + this.preferenceService.lang.toUpperCase() + '_link']['value'];
            } else if (res.data['Banner_DEFAULT'] && res.data['Banner_DEFAULT']['value']) {
                this.banner = environment.apiUrl + '/config/file/' + res.data['Banner_DEFAULT']['value'];
                this.bannerURL = res.data['Banner_DEFAULT_link']['value'];
            }
        });

        this.games = [
            {
                title: $localize`:@@MATCH:Match`,
                link: '/local-games/match',
            },
            {
                title: $localize`:@@AROUND_THE_CLOCK:Around The Clock`,
                link: '/local-games/around-the-clock',
                new: true,
            },
            {
                title: $localize`:@@BOBS_27:Bob's 27`,
                link: '/local-games/bobs-27',
            },
            {
                title: $localize`:@@CRICKET_TACTICS:Cricket / Tactics`,
                link: '/local-games/cricket-tactics',
            },
            {
                title: $localize`:@@DOUBLES_TRAINING:Doubles training`,
                link: '/local-games/doubles-training',
            },
            {
                title: $localize`:@@SCORE_TRAINING:Score training`,
                link: '/local-games/score-training',
            },
            this.auth.user.is_admin
                ? {
                      title: $localize`:@@SHANGHAI:Shanghai`,
                      link: '/local-games/shanghai',
                      new: true,
                  }
                : { title: null, link: null },
            {
                title: $localize`:@@SINGLES_TRAINING:Singles training`,
                link: '/local-games/singles-training',
            },
        ];
    }

    openSettings(game: any) {
        this.ga.trackEvent(GA_EVENTCATEGORIES.NEWGAME, 'Open ' + this.translate.instant(game.title) + ' setup');
        this.nav.navigateForward(game.link);
    }

    openLink(url) {
        this.ga.trackEvent(GA_EVENTCATEGORIES.NEWGAME, GA_EVENTACTIONS.OPENBANNERLINK, url);

        this.iab.create(url, '_system', {
            location: 'yes',
        } as InAppBrowserOptions);
    }

    toggleMenu() {
        this.menu.open('mainmenu');
    }
}
