@if (!onlineCore.pause) {
    @for (stream of cameraStreams() | keyvalue; track $index) {
        @if (stream.value.videoStreams.player) {
            <div
                class="overflow-hidden h-full w-full"
                [hidden]="
                    !visible() ||
                    (currentUserId() === authenticatedUserId() && !isSpectator() && !allowOwnCamView()) ||
                    stream.key !== currentUserId()
                ">
                <video
                    [id]="'player-cam-' + stream.key"
                    class="ingame-camera h-full w-full object-cover object-center"
                    [srcObject]="stream.value.videoStreams.player.stream"
                    autoplay
                    playsinline
                    webkit-playsinline
                    muted></video>
            </div>
        }
    }
}
