import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { ConnectionStatus, OmniCommunicationService } from './omni-communication.service';
import { OmniRequest, OmniResponse } from './omni-interfaces';
import { SetSingleDartPayload, SingleDartEvent } from '@dc-core/dc-gamelogic/in-game/ingame.globals';
import { OMNIVIEW_BOARD, OMNIVIEW_ZOOMED } from '@dc-core/dc-gamelogic/settings/settings.globals';
import { DCFireStoreUser } from '@dc-core/dc-firestore/globals/firestore.tables';

export interface CoordinatedGame {
    sets: CoordinatedSet[];
}

export interface CoordinatedSet {
    legs: CoordinatedLeg[];
}

export interface CoordinatedLeg {
    coordinates: CartesianCoordinate[];
}

export interface HeatmapPayload {
    coordinates: CartesianCoordinate[];
}

export interface PolarCoordinate {
    r: number; // radius
    theta: number; // angle in radians
}

export interface CartesianCoordinate {
    x: number;
    y: number;
    value?: number;
}

export interface OmniSingleDart {
    coordinates: CartesianCoordinate;
    singleDart: SetSingleDartPayload;
    isValidThrow: boolean;
}

export interface OmniViewMode {
    label: string;
    tag: string;
    type: 'zoomed' | 'board' | 'heatmap';
}

@Injectable({
    providedIn: 'root',
})
export class OmniIngameService {
    private ngUnsubscribe = new Subject<void>();

    public coordinates: CartesianCoordinate[] = [];

    public dartHitsSubject = new BehaviorSubject<OmniSingleDart[]>([null, null, null]);
    public dartHits$ = this.dartHitsSubject.asObservable();

    public omniIngameEventsSubject = new Subject<boolean>();
    public omniIngameEvent$ = this.omniIngameEventsSubject.asObservable();

    public forcedAllThrown: boolean = false;
    public currentSingleDartEvent: SingleDartEvent = null;
    public omniViewModes: OmniViewMode[];
    public omniViewMode: 'zoomed' | 'board' | 'heatmap';
    public usersWithOmni: number[] = [];

    constructor(private omniCommunicationService: OmniCommunicationService) {
        // this.omniViewModes = [OMNIVIEW_ZOOMED, OMNIVIEW_BOARD, OMNIVIEW_HEATMAP];
        this.omniViewModes = [OMNIVIEW_ZOOMED, OMNIVIEW_BOARD];
        this.omniViewMode = this.omniViewModes[0].type;
    }

    public changeOmniView(type) {
        this.omniViewMode = type;
    }

    public omniConnected(): boolean {
        return this.omniCommunicationService.statusSubject.value === ConnectionStatus.CONNECTED;
    }

    public enableOmni() {
        this.omniCommunicationService.startWaitingForDarts();
    }

    public disconnect(clearSmartDevice = false) {
        this.omniCommunicationService.disconnect();
        if (clearSmartDevice) {
            this.omniCommunicationService.smartDevice = null;
        }
    }

    public stopOmni() {
        this.omniCommunicationService.stopWaitingForDarts();
        this.usersWithOmni = [];
        this.cleanupSubscriptions();
    }

    public subscribeToEvents(): Observable<OmniResponse> {
        return this.omniCommunicationService.events$.pipe(takeUntil(this.ngUnsubscribe));
    }

    public subscribeToRequests(): Observable<OmniRequest> {
        return this.omniCommunicationService.requests$.pipe(takeUntil(this.ngUnsubscribe));
    }

    public prepareNextTurn() {
        this.dartHitsSubject.next([null, null, null]);
        this.currentSingleDartEvent = null;
        this.forcedAllThrown = false;
        this.resetOmniTurns();
    }

    public resetOmniTurns() {
        this.omniIngameEventsSubject.next(true);
    }

    public addDartHit(
        coordinates: CartesianCoordinate,
        singleDartPayload: SetSingleDartPayload,
        isValidThrow: boolean
    ) {
        // Find the index of the first dart thats NULL
        const index = this.dartHitsSubject.value.findIndex((dart) => dart === null);

        if (index !== -1) {
            this.dartHitsSubject.value[index] = {
                coordinates: coordinates,
                isValidThrow: isValidThrow,
                singleDart: singleDartPayload,
            };
            this.dartHitsSubject.next(this.dartHitsSubject.value);

            // Add a valid throw to the heatmap
            if (isValidThrow) {
                this.coordinates.push(coordinates);
            }
        }
    }

    public checkPlayerOmnis(players: DCFireStoreUser[]) {
        this.usersWithOmni = players.filter((player) => player.has_omni).map((player) => player.user_id);
    }

    private cleanupSubscriptions(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();

        // Reset the subject in case we want to set up subscriptions again later
        this.ngUnsubscribe = new Subject<void>();
    }
}
