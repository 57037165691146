<div class="flex h-full w-[2.625rem] flex-col landscape:flex-col-reverse">
    <div (click)="toggleDropdown()" class="p-2.5 cursor-pointer">
        @for (keyboardFromList of keyboards; track keyboardFromList) {
            @if (keyboard === keyboardFromList.name) {
                @if (keyboardFromList.name === 'hotkeys') {
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 398.54 356.98">
                        <g>
                            <rect
                                [attr.fill]="themeService.theme() === 'dark' ? '#000000' : '#000000'"
                                width="61.64"
                                height="61.64"
                                rx="11.81"
                                ry="11.81" />
                            <rect
                                [attr.fill]="themeService.theme() === 'dark' ? '#000000' : '#000000'"
                                x="96.03"
                                width="61.64"
                                height="61.64"
                                rx="11.81"
                                ry="11.81" />
                            <rect
                                [attr.fill]="themeService.theme() === 'dark' ? '#000000' : '#000000'"
                                x="192.07"
                                width="61.64"
                                height="61.64"
                                rx="11.81"
                                ry="11.81" />
                        </g>
                        <g>
                            <rect
                                [attr.fill]="themeService.theme() === 'dark' ? '#000000' : '#000000'"
                                y="95.31"
                                width="61.64"
                                height="61.64"
                                rx="11.81"
                                ry="11.81" />
                            <rect
                                [attr.fill]="themeService.theme() === 'dark' ? '#000000' : '#000000'"
                                x="96.03"
                                y="95.31"
                                width="61.64"
                                height="61.64"
                                rx="11.81"
                                ry="11.81" />
                            <rect
                                [attr.fill]="themeService.theme() === 'dark' ? '#000000' : '#000000'"
                                x="192.07"
                                y="95.31"
                                width="61.64"
                                height="61.64"
                                rx="11.81"
                                ry="11.81" />
                        </g>
                        <g>
                            <rect
                                [attr.fill]="themeService.theme() === 'dark' ? '#000000' : '#000000'"
                                y="190.61"
                                width="61.64"
                                height="61.64"
                                rx="11.81"
                                ry="11.81" />
                            <rect
                                [attr.fill]="themeService.theme() === 'dark' ? '#000000' : '#000000'"
                                x="96.03"
                                y="190.61"
                                width="61.64"
                                height="61.64"
                                rx="11.81"
                                ry="11.81" />
                        </g>
                        <rect
                            [attr.fill]="themeService.theme() === 'dark' ? '#000000' : '#000000'"
                            x="96.03"
                            y="285.92"
                            width="61.64"
                            height="61.64"
                            rx="11.81"
                            ry="11.81" />
                        <polygon
                            fill="#c64e09"
                            points="326.33 256.63 343.39 357.19 252.24 309.47 161.08 357.19 178.14 256.63 105.93 185.98 207.18 171.08 252.23 80.2 297.29 171.08 398.54 185.98 326.33 256.63" />
                    </svg>
                } @else {
                    <app-icon [icon]="keyboardFromList.icon_name" class="text-black dark:text-black"></app-icon>
                }
            }
        }
    </div>
    <div class="relative z-50 w-full">
        @if (dropdownOpened) {
            <div
                class="absolute right-0 z-50 space-y-2 rounded-md bg-white"
                [ngClass]="{
                    'top-0': isWeb && isExtraLargeDevice(),
                    'bottom-4': isWeb && !isExtraLargeDevice(),
                    'landscape:bottom-4': !isWeb,
                    'portrait:top-4': !isWeb
                }"
                style="box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px">
                @for (keyboardFromList of keyboards; track keyboardFromList) {
                    <div
                        class="flex items-center justify-center rounded-md p-2 cursor-pointer"
                        [class.bg-neutral-100]="keyboard === keyboardFromList.name"
                        (click)="changeKeyboard(keyboardFromList.name)">
                        @if (keyboardFromList.name === 'hotkeys') {
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 398.54 356.98">
                                <g>
                                    <rect
                                        [attr.fill]="themeService.theme() === 'dark' ? '#FFFFFF' : '#000000'"
                                        width="61.64"
                                        height="61.64"
                                        rx="11.81"
                                        ry="11.81" />
                                    <rect
                                        [attr.fill]="themeService.theme() === 'dark' ? '#FFFFFF' : '#000000'"
                                        x="96.03"
                                        width="61.64"
                                        height="61.64"
                                        rx="11.81"
                                        ry="11.81" />
                                    <rect
                                        [attr.fill]="themeService.theme() === 'dark' ? '#FFFFFF' : '#000000'"
                                        x="192.07"
                                        width="61.64"
                                        height="61.64"
                                        rx="11.81"
                                        ry="11.81" />
                                </g>
                                <g>
                                    <rect
                                        [attr.fill]="themeService.theme() === 'dark' ? '#FFFFFF' : '#000000'"
                                        y="95.31"
                                        width="61.64"
                                        height="61.64"
                                        rx="11.81"
                                        ry="11.81" />
                                    <rect
                                        [attr.fill]="themeService.theme() === 'dark' ? '#FFFFFF' : '#000000'"
                                        x="96.03"
                                        y="95.31"
                                        width="61.64"
                                        height="61.64"
                                        rx="11.81"
                                        ry="11.81" />
                                    <rect
                                        [attr.fill]="themeService.theme() === 'dark' ? '#FFFFFF' : '#000000'"
                                        x="192.07"
                                        y="95.31"
                                        width="61.64"
                                        height="61.64"
                                        rx="11.81"
                                        ry="11.81" />
                                </g>
                                <g>
                                    <rect
                                        [attr.fill]="themeService.theme() === 'dark' ? '#FFFFFF' : '#000000'"
                                        y="190.61"
                                        width="61.64"
                                        height="61.64"
                                        rx="11.81"
                                        ry="11.81" />
                                    <rect
                                        [attr.fill]="themeService.theme() === 'dark' ? '#FFFFFF' : '#000000'"
                                        x="96.03"
                                        y="190.61"
                                        width="61.64"
                                        height="61.64"
                                        rx="11.81"
                                        ry="11.81" />
                                </g>
                                <rect
                                    [attr.fill]="themeService.theme() === 'dark' ? '#FFFFFF' : '#000000'"
                                    x="96.03"
                                    y="285.92"
                                    width="61.64"
                                    height="61.64"
                                    rx="11.81"
                                    ry="11.81" />
                                <polygon
                                    fill="#c64e09"
                                    points="326.33 256.63 343.39 357.19 252.24 309.47 161.08 357.19 178.14 256.63 105.93 185.98 207.18 171.08 252.23 80.2 297.29 171.08 398.54 185.98 326.33 256.63" />
                            </svg>
                        } @else {
                            <app-icon [icon]="keyboardFromList.icon_name" class="text-black"></app-icon>
                        }
                    </div>
                }
            </div>
        }
    </div>
</div>
