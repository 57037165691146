<app-page-header mainNavigation="custom" color="transparent">
    <div title [translate]="'RATE_PLAYER'" i18n="@@RATE_PLAYER">Rate this player</div>
    <div right class="right-page-header"><app-icon icon="close" (click)="DismissModal()"></app-icon></div>
</app-page-header>

<ion-content scrollY="false" fullscreen>
    <!-- <div
        class="DCBackdrop"
        [ngClass]="{ 'visible': showChat || incomingComms?.length }"
        (click)="RemoveBackdrop()"></div>
    <div class="in-game-chat-container" [ngClass]="{ 'hidden': !showChat }">
        <div class="chat-header text-center">
            <h2>Tap to send</h2>
        </div>

        <div class="commsOptions text-center">
            <ion-badge mode="md" color="light" (click)="AddComms(comm.text)" *ngFor="let comm of ingameComms">
                {{comm.text}}
            </ion-badge>
        </div>
    </div>
    <div class="IncomingCommsSection" *ngIf="incomingComms?.length">
        <div class="commsOptions text-center" (click)="RemoveComm(i)" *ngFor="let comm of incomingComms; let i = index">
            <div class="name">{{comm.name}}</div>
            <div class="commText">{{comm.text}}</div>
        </div>
    </div> -->

    <!--Show friend status-->
    <div
        class="text-center friendsbar"
        *ngIf="user"
        [ngClass]="{
            green: user.friend_status === null || user.friend_status === 'no_friends',
            orange: user.friend_status === 'friends',
            dark: user.friend_status === 'incoming' || user.friend_status === 'pending'
        }">
        <p *ngIf="!user.friend_status" (click)="AddFriend(user.id)" [translate]="'ADD_FRIEND'" i18n="@@ADD_FRIEND">
            Add friend
        </p>
        <p
            class="green border"
            *ngIf="user.friend_status === 'incoming'"
            (click)="AcceptFriend(user.id)"
            [translate]="'ACCEPT_REQUEST'"
            i18n="@@ACCEPT_REQUEST">
            Accept
        </p>
        <p
            class="red border"
            *ngIf="user.friend_status === 'incoming'"
            (click)="RemoveFriend(user.id)"
            [translate]="'REMOVE_REQUEST'"
            i18n="@@REMOVE_REQUEST">
            Remove request
        </p>
        <p
            *ngIf="user.friend_status === 'pending'"
            (click)="ShowPendingOptions(user.id)"
            [translate]="'REMOVE_REQUEST'"
            i18n="@@REMOVE_REQUEST">
            Pending
        </p>
        <p
            *ngIf="user.friend_status === 'friends'"
            (click)="ShowFriendOptions(user.id)"
            [translate]="'FRIENDS'"
            i18n="@@FRIENDS">
            Friends
        </p>
    </div>

    <div class="">
        <app-profile-photo
            [ultimate]="user.is_ultimate"
            [url]="user?.profile?.profile_photo_url"
            [size]="40"></app-profile-photo>
        <div class="name">{{ user?.full_name }}</div>
    </div>
    <ion-row class="starsRow">
        <ion-col *ngFor="let star of stars">
            <ion-icon *ngIf="star.value <= selectedValue" class="star orange" (click)="selectStar(star)" name="star">
            </ion-icon>
            <ion-icon *ngIf="star.value > selectedValue" class="star" (click)="selectStar(star)" name="star-outline">
            </ion-icon>
        </ion-col>
    </ion-row>

    <div class="text-center" *ngIf="user">
        <p
            *ngIf="user.is_blocked && !blockLoading"
            class="unblocktext"
            (click)="tryUnblock()"
            [translate]="'UNBLOCK_PLAYER'"
            i18n="@@UNBLOCK_PLAYER">
            Unblock player
        </p>
        <p
            *ngIf="!user.is_blocked && !blockLoading"
            class="blocktext"
            (click)="tryBlock()"
            [translate]="'BLOCK_PLAYER'"
            i18n="@@BLOCK_PLAYER">
            Block player
        </p>
        <ion-spinner
            *ngIf="blockLoading"
            style="height: 20px; width: 20px"
            color="secondary"
            name="crescent"></ion-spinner>
    </div>

    <div class="bottomCTA blue" (click)="SendRating()" [ngClass]="{ disabled: !selectedValue }">
        <p [translate]="'SEND'" i18n="@@SEND">Send</p>
    </div>
</ion-content>
