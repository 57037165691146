import { CricketTactics, CricketTacticsTeam, CricketTacticsUser, User } from 'dc-core/dc-backend/dc-classes';
import { CricketTacticsSettings } from 'dc-core/dc-backend/dc-enums';
import { RemovePlayerPayload } from 'dc-core/dc-gamelogic/game-interfaces';
import _ from 'lodash';
import * as moment from 'moment';
import { LOCAL_GAME_SETTINGS_STORAGE_KEYS } from '../../../game-storage';
import {
    BESTOFMODE,
    BestOfFirstTo,
    CRICKET_MODE,
    FIRSTTOMODE,
    LEGMODE,
    PLAYERMODE,
    PlayerType,
    SETSMODE,
    SetOrLeg,
    TACTICS_MODE,
    TEAMMODE,
    dateTimeFormat,
} from '../../settings.globals';
import { DartbotCricketTacticsLevels, DartbotLevel } from '../dartbot/dartbot.config';
import { DartCounterGameSettings } from '../settings-classes';

export class DartCounterCricketTacticsSettings extends DartCounterGameSettings {
    //CPU Settings
    public vsDartbot: boolean;
    public dartbotAdded: boolean = false;
    public dartbotLevel: DartbotLevel;

    public modes: { label: string; tag: string; type: CricketTacticsSettings }[] = [];
    public mode: CricketTacticsSettings = 'cricket';

    public bestOfFirstTos: { label: string; tag: string; type: BestOfFirstTo }[] = [];
    public bestOfFirstTo: BestOfFirstTo = 'best_of';
    public goalAmount: number = 5;
    public minGoalAmount = 1;
    public maxGoalAmount = 30;
    public setsOrLegs: { label: string; tag: string; type: SetOrLeg }[] = [];
    public setOrLeg: SetOrLeg = 'legs';

    public showTwoLegsDifference: boolean = false;
    public twoLegsDifference: boolean = false;
    public suddenDeath: boolean = false;

    public showCutThroat: boolean = false;
    public cutThroat: boolean = false;

    constructor() {
        //Set the super-class settings
        super();
        this.storageKey = LOCAL_GAME_SETTINGS_STORAGE_KEYS.CRICKET_TACTICS;

        //Set the specific settings
        this.playerTypes = [PLAYERMODE, TEAMMODE];
        this.modes = [CRICKET_MODE, TACTICS_MODE];
        this.bestOfFirstTos = [BESTOFMODE, FIRSTTOMODE];
        this.setsOrLegs = [LEGMODE, SETSMODE];

        const storedSettings = JSON.parse(localStorage.getItem(this.storageKey));
        if (storedSettings) {
            this.setStoredSettings(storedSettings);
        } else {
            this.setDefaults();
        }
    }

    private setDefaults() {
        this.vsDartbot = false;
        this.dartbotAdded = false;
        this.dartbotLevel = DartbotCricketTacticsLevels[DartbotCricketTacticsLevels.length / 2];

        this.mode = 'cricket';
        this.playerType = PLAYERMODE.type;

        this.bestOfFirstTo = BESTOFMODE.type;
        this.goalAmount = 5;
        this.setOrLeg = LEGMODE.type;

        this.showTwoLegsDifference = false;
        this.twoLegsDifference = false;
        this.suddenDeath = false;

        this.showCutThroat = false;
        this.cutThroat = false;

        this.users = [];
        this.teams = [];
    }

    private setStoredSettings(storedSettings: DartCounterCricketTacticsSettings) {
        this.vsDartbot = storedSettings.vsDartbot;
        this.dartbotAdded = storedSettings.dartbotAdded ?? false;
        this.dartbotLevel = storedSettings.dartbotLevel;

        this.mode = storedSettings.mode;
        this.playerType = storedSettings.playerType;

        this.bestOfFirstTo = storedSettings.bestOfFirstTo ?? this.bestOfFirstTo;
        this.goalAmount = storedSettings.goalAmount ?? this.goalAmount;
        this.setOrLeg = storedSettings.setOrLeg ?? this.setOrLeg;

        this.showTwoLegsDifference = storedSettings.showTwoLegsDifference ?? this.showTwoLegsDifference;
        this.twoLegsDifference = storedSettings.twoLegsDifference ?? this.twoLegsDifference;
        this.suddenDeath = storedSettings.suddenDeath ?? this.suddenDeath;

        this.showCutThroat = storedSettings.showCutThroat ?? false;
        this.cutThroat = storedSettings.cutThroat ?? false;

        this.users = storedSettings.users;
        this.teams = storedSettings.teams;
    }

    public changePlayerType(playerType: PlayerType): void {
        super.changePlayerType(playerType);
        this.enableTwoLegsDifference();
        this.enableCutThroat();
    }

    public addUser(user, team): void {
        super.addUser(user, team);
        this.enableTwoLegsDifference();
        this.enableCutThroat();
    }

    public removePlayer(event: RemovePlayerPayload): User {
        let removedUser: User = super.removePlayer(event);
        if (removedUser && removedUser.is_cpu) {
            this.vsDartbot = false;
            this.dartbotAdded = false;
        }

        this.enableTwoLegsDifference();
        this.enableCutThroat();
        return removedUser;
    }

    private enableTwoLegsDifference(): void {
        if (this.playerType == 'players') {
            if (this.users.length > 1) {
                this.showTwoLegsDifference = true;
            } else {
                this.showTwoLegsDifference = false;
            }
        } else if (this.playerType == 'teams') {
            if (this.teams.length > 1) {
                this.showTwoLegsDifference = true;
            } else {
                this.showTwoLegsDifference = false;
            }
        }

        // Best of 'even number' -> Dont allow two legs difference
        if (this.bestOfFirstTo === 'best_of' && this.goalAmount % 2 == 0) {
            this.showTwoLegsDifference = false;
        }
    }

    private enableCutThroat(): void {
        if (this.playerType == 'players') {
            if (this.users.length > 1) {
                this.showCutThroat = true;
            } else {
                this.showCutThroat = false;
            }
        } else if (this.playerType == 'teams') {
            if (this.teams.length > 1) {
                this.showCutThroat = true;
            } else {
                this.showCutThroat = false;
            }
        }
    }

    changeMode(mode: CricketTacticsSettings): void {
        this.mode = mode;
    }

    changeBestOfFirstTo(bestOfFirstTo: BestOfFirstTo): void {
        this.bestOfFirstTo = bestOfFirstTo;
        this.enableTwoLegsDifference();
    }

    changeSetOrLeg(setOrLeg: SetOrLeg): void {
        this.setOrLeg = setOrLeg;
    }

    toggleCutThroat(): void {
        this.cutThroat = !this.cutThroat;
    }

    toggleTwoLegsDifference(): void {
        this.twoLegsDifference = !this.twoLegsDifference;
    }

    vsDartbotChangedWithUser(value: boolean, dartbot: User, dartbotLevel: DartbotLevel): void {
        const user = _.cloneDeep(dartbot);

        if (value === true && dartbot) {
            this.vsDartbot = true;
            this.dartbotLevel = dartbotLevel;

            user.cpu_level = dartbotLevel.level;
            user.first_name += ' (lvl. ' + dartbotLevel.level + ')';
            user.full_name += ' (lvl. ' + dartbotLevel.level + ')';

            if (this.dartbotAdded === true) {
                this.dartbotAdded = false;
                if (this.playerType === 'players') {
                    this.users.forEach((userFromList, userIndex) => {
                        if (userFromList.is_cpu) {
                            this.users[userIndex] = user;
                        }
                    });
                } else if (this.playerType === 'teams') {
                    this.teams.forEach((team, teamIndex) => {
                        team.users.forEach((userFromList, userIndex) => {
                            if (userFromList.is_cpu) {
                                this.teams[teamIndex].users[userIndex] = user;
                            }
                        });
                    });
                }
            } else {
                this.dartbotAdded = false;
                if (this.playerType === 'players') {
                    if (this.users.length === 4) {
                        this.users.splice(3, 1);
                    }
                    this.addPlayer({ user });
                } else if (this.playerType === 'teams') {
                    if (this.teams.length < 2) {
                        this.teams.push({ users: [user] });
                    } else {
                        if (this.teams[1].users.length === 2) {
                            this.teams[1].users.splice(3, 1);
                        }
                        this.addPlayer({ user, team: this.teams[1] });
                    }
                }
            }

            this.dartbotAdded = true;
        } else {
            this.vsDartbot = false;
            this.dartbotAdded = false;
            if (this.playerType === 'players') {
                this.users.forEach((user, userIndex) => {
                    if (user.is_cpu) {
                        this.removePlayer({ userIndex });
                    }
                });
            } else if (this.playerType === 'teams') {
                this.teams.forEach((team, teamIndex) => {
                    team.users.forEach((user, userIndex) => {
                        if (user.is_cpu) {
                            this.removePlayer({ userIndex, teamIndex });
                        }
                    });
                });
            }
        }
    }

    checkDartbotOnly(): boolean {
        let hasOtherUser = false;
        if (this.playerType === 'players') {
            this.users.forEach((user) => {
                if (!user.is_cpu) {
                    hasOtherUser = true;
                }
            });
        } else if (this.playerType === 'teams') {
            this.teams.forEach((team) => {
                team.users.forEach((user) => {
                    if (!user.is_cpu) {
                        hasOtherUser = true;
                    }
                });
            });
        }
        return !hasOtherUser;
    }

    checkForErrors(): string[] {
        let errors = [];

        if (this.checkDartbotOnly()) {
            errors.push($localize`:@@CANT_START_GAME_WITH_DARTBOT_ONLY:You cannot start a game with only the Dartbot`);

            return errors;
        }

        if (this.goalAmount < this.minGoalAmount) {
            this.goalAmount = this.minGoalAmount;
            errors.push($localize`:@@ERROR_MIN_GOAL_AMOUNT:The minimum goal amount is 1 sets or legs`);
        } else if (this.goalAmount > this.maxGoalAmount) {
            this.goalAmount = this.maxGoalAmount;
            errors.push($localize`:@@ERROR_MAX_GOAL_AMOUNT:The maximum goal amount is 30 sets or legs`);
        }

        this.enableTwoLegsDifference();

        return errors;
    }

    saveGameSettings() {
        let storageObject = {
            mode: this.mode,
            vsDartbot: this.vsDartbot,
            dartbotAdded: this.dartbotAdded,
            dartbotLevel: this.dartbotLevel,
            bestOfFirstTo: this.bestOfFirstTo,
            goalAmount: this.goalAmount,
            setOrLeg: this.setOrLeg,
            showTwoLegsDifference: this.showTwoLegsDifference,
            twoLegsDifference: this.twoLegsDifference,
            suddenDeath: this.suddenDeath,
            showCutThroat: this.showCutThroat,
            cutThroat: this.cutThroat,
            playerType: this.playerType,
            users: this.users,
            teams: this.teams,
        };

        localStorage.setItem(this.storageKey, JSON.stringify(storageObject));
    }

    mapToCricketTacticsObject(): CricketTactics {
        const teams: CricketTacticsTeam[] = [];
        if (this.playerType === 'players') {
            this.users.forEach((user, index) => {
                const newTeam: CricketTacticsTeam = { id: index + 1, cricket_tactics_id: 0, users: [] };
                let convertedUser = this.convertUserToCricketTacticsUser(user, newTeam.id, index + 1);
                if (user.is_cpu) {
                    convertedUser.is_cpu = true;
                    convertedUser.cpu_level = this.dartbotLevel.level;
                } else {
                    convertedUser.is_cpu = false;
                }
                newTeam.users.push(convertedUser);
                teams.push(newTeam);
            });
        } else {
            let userIndex = 1;
            this.teams.forEach((team, index) => {
                const newTeam: CricketTacticsTeam = { id: index + 1, cricket_tactics_id: 0, users: [] };
                team.users.forEach((user) => {
                    let convertedUser = this.convertUserToCricketTacticsUser(user, newTeam.id, userIndex++);
                    if (user.is_cpu) {
                        convertedUser.is_cpu = true;
                        convertedUser.cpu_level = this.dartbotLevel.level;
                    } else {
                        convertedUser.is_cpu = false;
                    }

                    newTeam.users.push(convertedUser);
                });
                teams.push(newTeam);
            });
        }

        return <CricketTactics>{
            id: 1,
            has_teams: this.playerType === 'teams',
            settings: this.mode,
            is_best_of: this.bestOfFirstTo === 'best_of',
            has_sets: this.setOrLeg === 'sets',
            goal_amount: this.goalAmount,
            vs_cpu: this.vsDartbot === true,
            cpu_level: this.vsDartbot ? this.dartbotLevel.level : null,
            two_legs_difference: this.showTwoLegsDifference ? this.twoLegsDifference : false,
            enable_sudden_death: this.showTwoLegsDifference && this.twoLegsDifference ? this.suddenDeath : false,
            is_cut_throat: this.showCutThroat ? this.cutThroat : false,
            is_online: false,
            started_at: moment().utc().format(dateTimeFormat),
            teams,
            sets: [],
            legs: [],
        };
    }

    convertUserToCricketTacticsUser(user: User, team_id: number, id: number): CricketTacticsUser {
        return <CricketTacticsUser>{
            id,
            cricket_tactics_id: 0,
            name: user.id === null ? user.full_name : user.first_name,
            user_id: user.id,
            user,
            ghost_name: user.id === null ? user.full_name : null,
            team_id: team_id,
            is_verified: this.user.id === user.id ? true : false,
        };
    }
}
