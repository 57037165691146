<!-- Translation containers-->
<ng-container *ngIf="0" i18n="@@ENTER_NEW_VALUE">Enter new value</ng-container>
<!-- End of translation containers-->

<app-page-header mainNavigation="custom" color="white">
    <div title [translate]="'EDIT_SCORES'" i18n="@@EDIT_SCORES">Edit score</div>
    <app-option-button right icon="close" (click)="close()" color="text-black"></app-option-button>
</app-page-header>

<ion-content class="bg-white-pattern text-black" scrollY="false">
    <div style="padding: 0 10px; overflow: scroll; height: 100%">
        <h4
            *ngIf="!turns.length"
            class="text-center mt-4 text-black"
            [translate]="'THERE_ARE_NO_THROWS'"
            i18n="@@THERE_ARE_NO_THROWS">
            There are no throws!
        </h4>
        <h4
            *ngIf="turns.length"
            class="text-center my-4 text-black"
            [translate]="'SELECT_SCORE_TO_EDIT'"
            i18n="@@SELECT_SCORE_TO_EDIT">
            Which score do you want to edit?
        </h4>

        <div class="flex w-full flex-col space-y-4" *ngIf="turns.length">
            <ion-accordion-group class="space-y-2" [value]="editTurn ? selectedTurnIndex : null">
                <ion-accordion
                    class="bg-white"
                    style="border-radius: 8px !important"
                    [value]="turnIndex"
                    *ngFor="let turn of turns; let turnIndex = index">
                    <ion-item
                        slot="header"
                        color="transparent"
                        class="sectionTitle"
                        lines="none"
                        style="padding: 0"
                        (click)="edit(turn, turnIndex)">
                        <ion-label
                            style="margin: 0; text-align: left; font-size: medium; display: flex; align-items: center">
                            <div class="label-bold text-black">
                                <span [translate]="'TURN'" i18n="@@TURN">Turn</span>
                                <span class="ml-1">#{{ turnIndex + 1 }}</span>
                            </div>
                            <div class="ml-auto text-black">
                                <span [translate]="'TOTAL_SCORE'" i18n="@@TOTAL_SCORE">Total score</span>:
                                {{ turn.total_score }}
                            </div>
                        </ion-label>
                    </ion-item>
                    <div slot="content">
                        <div class="p-4 space-y-2 text-black" *ngIf="editTurn">
                            <div *ngIf="!editTurn.single_dart_mode">
                                <div class="dialog-input-label" [translate]="'TOTAL_SCORE'" i18n="@@TOTAL_SCORE">
                                    Total score
                                </div>
                                <input
                                    type="number"
                                    min="0"
                                    max="180"
                                    class="dialog-input text-sm w-full mt-1"
                                    placeholder="{{ 'ENTER_NEW_VALUE' | translate }}"
                                    [(ngModel)]="editTurn.total_score" />
                            </div>
                            <div class="space-y-2" *ngIf="editTurn.single_dart_mode && editTurn.throws.length">
                                <div
                                    class="flex justify-between space-x-2"
                                    *ngFor="let throw of editTurn.throws; let throwIndex = index">
                                    <div class="w-full bg-white">
                                        <div class="dialog-input-label" [translate]="'MULTIPLIER'" i18n="@@MULTIPLIER">
                                            Multiplier
                                        </div>
                                        <select
                                            id="multiplier-{{ throwIndex }}"
                                            name="multiplier-{{ throwIndex }}"
                                            class="dialog-input text-sm w-full mt-1 cursor-pointer"
                                            [ngModel]="throw.multiplier"
                                            (ngModelChange)="throwChanged(throw.number, $event, throw)">
                                            <option
                                                [value]="multiplierValue.value"
                                                [disabled]="multiplierValue.value === 3 && throw.number === 25"
                                                *ngFor="let multiplierValue of multiplierValues">
                                                {{ multiplierValue.label }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="w-full bg-white">
                                        <div class="dialog-input-label" [translate]="'NUMBER'" i18n="@@NUMBER">
                                            Number
                                        </div>
                                        <select
                                            id="number-{{ throwIndex }}"
                                            name="number-{{ throwIndex }}"
                                            class="dialog-input text-sm w-full mt-1 cursor-pointer"
                                            [ngModel]="throw.number"
                                            (ngModelChange)="throwChanged($event, throw.multiplier, throw)">
                                            <option
                                                [value]="numberValue.value"
                                                *ngFor="let numberValue of numberValues">
                                                {{ numberValue.label }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="w-full bg-white">
                                        <div class="dialog-input-label" [translate]="'VALUE'" i18n="@@VALUE">Value</div>
                                        <input
                                            id="value-{{ throwIndex }}"
                                            name="value-{{ throwIndex }}"
                                            class="dialog-input text-sm mt-1 w-full"
                                            disabled
                                            matInput
                                            value="{{ throw.value }}" />
                                    </div>
                                </div>
                            </div>
                            <div class="flex justify-end">
                                <app-primary-button
                                    class="block"
                                    size="small"
                                    addedClasses="w-full"
                                    (click)="save(turnIndex)">
                                    <span [translate]="'SAVE'" i18n="@@SAVE">Save</span>
                                </app-primary-button>
                            </div>
                        </div>
                    </div>
                </ion-accordion>
            </ion-accordion-group>
        </div>
    </div>
</ion-content>
