import { Component } from '@angular/core';
import { AuthApiService } from '@dc-api/auth.api.service';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DartCounterAlertService } from 'dc-core/dc-services/alert.service';
import { DartCounterPreferenceService } from 'dc-core/dc-services/preference/preference.service';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-force-change-password-dialog',
    templateUrl: 'force-change-password.html',
    styleUrls: ['force-change-password.scss'],
})
export class ForceChangePasswordDialogComponent {
    Password: string;
    Password2: string;
    showLoading = false;

    constructor(
        public view: ModalController,
        public auth: AuthService,
        private authApiService: AuthApiService,
        public preferenceService: DartCounterPreferenceService,
        private _alertService: DartCounterAlertService,
        private translate: TranslateService
    ) {}

    public DismissModal(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }

    SubmitNewPassword() {
        if (this.Password !== this.Password2) {
            $localize`:@@REPEATPASS_ERROR:The two passwords don't match!`;
            this._alertService.createAlert({
                title: this.translate.instant('REPEATPASS_ERROR'),
                icon: 'error',
            });
        } else if (this.Password.length < 6) {
            $localize`:@@PASS_ERROR:The password must be at least 6 characters!`;
            this._alertService.createAlert({
                title: this.translate.instant('PASS_ERROR'),
                icon: 'error',
            });
        } else {
            this.showLoading = true;
            this.authApiService
                .resetPassword({ new_password: this.Password, confirm_password: this.Password })
                .then((res) => {
                    this.auth.saveAuthentication(res.data.user, res.data.access_token);

                    this.showLoading = false;
                    this.DismissModal(true);
                })
                .catch((err) => {
                    this._alertService.errorFromApi(err);
                    this.showLoading = false;
                });
        }
    }
}
