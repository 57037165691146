<div class="flex h-full flex-col overflow-y-auto overscroll-none bg-white-pattern">
    <app-page-header mainNavigation="custom" color="white">
        <div
            left
            class="text-center text-sm font-bold text-black"
            (click)="dismissModal()"
            [translate]="'CANCEL'"
            i18n="@@CANCEL">
            Cancel
        </div>
        @if (currentSlide) {
            <div title>{{ currentSlide.title }}</div>
        }
    </app-page-header>

    <div class="mt-2 flex-1 rounded-t-4xl bg-white pt-6 px-safe" *ngIf="currentSlide">
        <div class="space-y-3 bg-white px-5" *ngIf="!isEnd">
            <div class="dialog-input-label">{{ currentSlide.name }}</div>
            <div class="flex items-center justify-between space-x-2">
                <div
                    class="h-1.5 w-full rounded-full"
                    [class.bg-orange-50]="slide.index > currentSlide.index"
                    [class.bg-orange]="slide.index <= currentSlide.index"
                    *ngFor="let slide of slides"></div>
            </div>
        </div>

        <div class="mt-8 flex overflow-x-scroll bg-white scrollbar-hide">
            <div class="flex-remain px-5" *ngIf="currentSlide.type === 'upgrade_to_yearly'" [@slideAnimation]>
                <div *ngIf="subscription.plan.interval_period === 'month'">
                    <div
                        class="text-xl font-bold text-black"
                        [translate]="'SWITCH_TO_YEARLY'"
                        i18n="@@SWITCH_TO_YEARLY">
                        Switch to Yearly
                    </div>
                    <div
                        class="text-neutral-400"
                        [translate]="'SWITCH_TO_YEARLY_INTRODUCTION'"
                        i18n="@@SWITCH_TO_YEARLY_INTRODUCTION">
                        Can't get enough of DartCounter? Change to a yearly Ultimate subscription and save 38% per year
                        instantly!
                    </div>
                </div>
                <div *ngIf="subscription.plan.interval_period === 'year'">
                    <div
                        class="text-xl font-bold text-black"
                        [translate]="'SWITCH_TO_MONTHLY'"
                        i18n="@@SWITCH_TO_MONTHLY">
                        Switch to Monthly
                    </div>
                    <div
                        class="text-neutral-400"
                        [translate]="'SWITCH_TO_MONTHLY_INTRODUCTION'"
                        i18n="@@SWITCH_TO_MONTHLY_INTRODUCTION">
                        Can't get enough of DartCounter? Change to a monthly Ultimate subscription and always be able to
                        cancel within a month.
                    </div>
                </div>
                <div>
                    <div class="mt-4 grid grid-cols-12 gap-3">
                        <div class="col-span-2 inline-flex items-center">
                            <div class="inline-flex h-7 w-7 items-center justify-center rounded-full bg-orange">
                                <div class="font-bebas-neue text-lg text-white">1</div>
                            </div>
                        </div>
                        <div class="col-span-10">
                            <div
                                class="text-neutral-400"
                                [translate]="'FEATURE_PLAYONLINE'"
                                i18n="@@FEATURE_PLAYONLINE">
                                Play unlimited online games
                            </div>
                        </div>
                    </div>
                    <div class="mt-2 grid grid-cols-12 gap-3">
                        <div class="col-span-2 inline-flex items-center">
                            <div class="inline-flex h-7 w-7 items-center justify-center rounded-full bg-orange">
                                <div class="font-bebas-neue text-lg text-white">2</div>
                            </div>
                        </div>
                        <div class="col-span-10">
                            <div class="text-neutral-400" [translate]="'FEATURE_CALLER'" i18n="@@FEATURE_CALLER">
                                MasterCaller calls your name
                            </div>
                        </div>
                    </div>
                    <div class="mt-2 grid grid-cols-12 gap-3">
                        <div class="col-span-2 inline-flex items-center">
                            <div class="inline-flex h-7 w-7 items-center justify-center rounded-full bg-orange">
                                <div class="font-bebas-neue text-lg text-white">3</div>
                            </div>
                        </div>
                        <div class="col-span-10">
                            <div class="text-neutral-400" [translate]="'FEATURE_ALLTIME'" i18n="@@FEATURE_ALLTIME">
                                Detailed all-time statistics
                            </div>
                        </div>
                    </div>
                    <div class="mt-2 grid grid-cols-12 gap-3">
                        <div class="col-span-2 inline-flex items-center">
                            <div class="inline-flex h-7 w-7 items-center justify-center rounded-full bg-orange">
                                <div class="font-bebas-neue text-lg text-white">4</div>
                            </div>
                        </div>
                        <div class="col-span-10">
                            <div class="text-neutral-400" [translate]="'FEATURE_CHARTS'" i18n="@@FEATURE_CHARTS">
                                Unlimited statistics on our website
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="mt-8 grid grid-cols-12 items-center rounded-lg bg-neutral-50 p-3 text-black"
                    (click)="setRecurring('yearly')"
                    *ngIf="subscription.plan.interval_period === 'month'">
                    <div class="col-span-7">
                        <div [translate]="'YEARLY'" i18n="@@YEARLY" class="text-xl font-semibold">Yearly</div>
                        <div class="mt-1 text-xxs font-extralight text-neutral-400">
                            {{ 'BILLED_ANNUALLY' | translate }} ({{
                                removeSpaces(purchases.yearlyProduct?.pricing?.price)
                            }})
                        </div>
                        <div
                            *ngIf="purchases.yearlyProduct?.pricing?.price"
                            class="mt-2 inline-flex flex-row items-end rounded-md border-2 border-orange px-3 py-2 font-bebas-neue text-black">
                            <div class="text-4xl">{{ yearPricePerMonth(purchases.yearlyProduct?.pricing) }}</div>
                            <div class="ml-1 text-lg" [translate]="'PER_MONTH'" i18n="@@PER_MONTH">per month</div>
                        </div>
                        <div class="mt-2 font-bebas-neue text-sm font-extralight text-neutral-400">
                            <div class="old-price relative inline-block">
                                {{ removeSpaces(purchases.monthlyProduct?.pricing?.price) }}
                                <div [translate]="'PER_MONTH'" i18n="@@PER_MONTH" class="inline">per month</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-3 col-start-8 inline-flex items-end">
                        <div
                            class="sale inline-flex w-full flex-col items-center rounded-md bg-orange p-2 text-center font-bebas-neue text-white">
                            <div class="text-1xl" [translate]="'SAVE_DISCOUNT'" i18n="@@SAVE_DISCOUNT">Save</div>
                            <div class="text-4xl">38%</div>
                        </div>
                    </div>
                    <div class="col-span-1 col-start-12 inline-flex items-center justify-end text-neutral-400">
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                    </div>
                </div>
                <div
                    class="mt-3 grid grid-cols-12 items-center rounded-lg bg-neutral-50 p-3 text-black"
                    (click)="setRecurring('monthly')"
                    *ngIf="subscription.plan.interval_period === 'year'">
                    <div class="col-span-7">
                        <div [translate]="'MONTHLY'" i18n="@@MONTHLY" class="text-xl font-semibold">Monthly</div>
                        <div
                            [translate]="'MONTHLY_PAYMENT'"
                            i18n="@@MONTHLY_PAYMENT"
                            class="mt-1 text-xxs font-extralight text-neutral-400">
                            Monthly payment
                        </div>
                        <div
                            *ngIf="purchases.monthlyProduct?.pricing?.price"
                            class="mt-2 inline-flex flex-row items-end rounded-md border-2 border-black px-3 py-2 font-bebas-neue text-black">
                            <div class="text-4xl">{{ removeSpaces(purchases.monthlyProduct?.pricing?.price) }}</div>
                            <div class="ml-1 text-lg" [translate]="'PER_MONTH'" i18n="@@PER_MONTH">per month</div>
                        </div>
                    </div>
                    <div class="col-span-1 col-start-12 inline-flex items-center justify-end text-neutral-400">
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                    </div>
                </div>
            </div>
            <div class="flex-remain px-5" *ngIf="currentSlide.type === 'advantages_dartcounter'" [@slideAnimation]>
                <div>
                    <swiper-container class="dc-swiper" [pagination]="true" style="max-width: calc(100vw - 2.5rem)">
                        <swiper-slide class="space-y-4">
                            <div class="m-3 px-2 py-4 shadow-lg shadow-gray-300">
                                <img src="assets/images/features/1-playonline.png" />
                                <h5 class="mt-5 w-full font-bebas-neue text-2xl text-black">
                                    {{ 'FEATURE_PLAYONLINE' | translate }}
                                </h5>
                            </div>
                        </swiper-slide>
                        <swiper-slide class="space-y-4">
                            <div class="m-3 px-2 py-4 shadow-lg shadow-gray-300">
                                <img src="assets/images/features/2-callname.png" />
                                <h5 class="mt-5 w-full font-bebas-neue text-2xl text-black">
                                    {{ 'FEATURE_CALLER' | translate }}
                                </h5>
                            </div>
                        </swiper-slide>
                        <swiper-slide class="space-y-4">
                            <div class="m-3 px-2 py-4 shadow-lg shadow-gray-300">
                                <img src="assets/images/features/3-alltime.png" />
                                <h5 class="mt-5 w-full font-bebas-neue text-2xl text-black">
                                    {{ 'FEATURE_ALLTIME' | translate }}
                                </h5>
                            </div>
                        </swiper-slide>
                        <swiper-slide class="space-y-4">
                            <div class="m-3 px-2 py-4 shadow-lg shadow-gray-300">
                                <img src="assets/images/features/4-details.png" />
                                <h5 class="mt-5 w-full font-bebas-neue text-2xl text-black">
                                    {{ 'FEATURE_MATCHDETAILS' | translate }}
                                </h5>
                            </div>
                        </swiper-slide>
                        <swiper-slide class="space-y-4">
                            <div class="m-3 px-2 py-4 shadow-lg shadow-gray-300">
                                <img src="assets/images/features/5-charts.png" />
                                <h5 class="mt-5 w-full font-bebas-neue text-2xl text-black">
                                    {{ 'FEATURE_CHARTS' | translate }}
                                </h5>
                            </div>
                        </swiper-slide>
                    </swiper-container>
                </div>
            </div>
            <div class="flex-remain px-5" *ngIf="currentSlide.type === 'cancel_subscription'" [@slideAnimation]>
                <div>
                    <div
                        class="text-xl font-bold text-black"
                        [translate]="'WERE_SORRY_TO_SEE_YOU_GO'"
                        i18n="@@WERE_SORRY_TO_SEE_YOU_GO">
                        We're sorry to see you go
                    </div>
                    <div
                        style="margin-bottom: 1.5rem"
                        *ngIf="!selectedReasons.length"
                        [translate]="'WHY_CANCEL_YOUR_SUBSCRIPTION'"
                        i18n="@@WHY_CANCEL_YOUR_SUBSCRIPTION"
                        class="text-neutral-400">
                        Why do you want to cancel your subscription?
                    </div>
                    <div class="text-neutral-400" style="margin-bottom: 1.5rem" *ngIf="lastSelectedReason?.addon_text">
                        {{ lastSelectedReason.addon_text }}
                    </div>
                    <ion-list *ngIf="currentCancelReasons.length" style="background: transparent">
                        <ion-radio-group
                            [value]="selectedReasonId"
                            mode="md"
                            *ngIf="!lastSelectedReason || lastSelectedReason?.type === 'list'">
                            <ion-item
                                (click)="selectReason(reason)"
                                *ngFor="let reason of currentCancelReasons"
                                style="--background: transparent"
                                class="text-black">
                                <ion-radio color="secondary" slot="start" [value]="reason.id"></ion-radio>
                                <ion-label style="white-space: normal">{{ reason.title }}</ion-label>
                            </ion-item>
                        </ion-radio-group>

                        <div
                            style="display: flex; flex-direction: column"
                            class="space-y-2 text-black"
                            *ngIf="lastSelectedReason?.type === 'multiple'">
                            <div *ngFor="let reason of currentCancelReasons">
                                <app-checkbox
                                    [model]="reason.selected"
                                    color="white"
                                    position="right"
                                    space="between"
                                    (itemChange)="toggleAnswer(reason)">
                                    <div class="text-base text-black">{{ reason.title }}</div>
                                </app-checkbox>
                                <div *ngIf="reason.type === 'open_option' && reason.selected">
                                    <textarea
                                        class="input-container white-placeholder w-full"
                                        style="height: 5rem; margin-bottom: 0.5rem; border: 1px solid var(--black)"
                                        name="reason"
                                        [(ngModel)]="reason.explanation"
                                        [placeholder]="reasonPlaceholder"
                                        autocapitalize="on"></textarea>
                                </div>
                            </div>
                        </div>
                    </ion-list>
                    <div *ngIf="selectedReason?.addon_text && selectedReason?.type === 'open'">
                        {{ selectedReason.addon_text }}
                    </div>
                    <div *ngIf="selectedReason?.type === 'open'">
                        <textarea
                            class="input-container white-placeholder w-full"
                            style="height: 5rem; margin-bottom: 0.5rem; border: 1px solid var(--black)"
                            name="reason"
                            [(ngModel)]="explainedReason"
                            [placeholder]="reasonPlaceholder"
                            autocapitalize="on"></textarea>
                    </div>
                    <app-primary-button
                        size="normal"
                        color="black"
                        addedClasses="w-full mt-4"
                        [translate]="'NEXT'"
                        (click)="continueToCancel()"
                        i18n="@@NEXT"
                        *ngIf="cancelStage !== 3">
                        {{ currentSlide.nextText }}
                    </app-primary-button>
                    <app-primary-button
                        size="normal"
                        color="orange"
                        addedClasses="w-full mt-4"
                        [translate]="'CONFIRM_CANCELLATION'"
                        i18n="@@CONFIRM_CANCELLATION"
                        (click)="confirmCancel()"
                        *ngIf="!howToCancel && cancelStage === 3">
                        Confirm cancellation
                    </app-primary-button>
                    <div class="mt-4 text-center text-sm text-neutral-400" *ngIf="howToCancel && cancelStage === 3">
                        {{ howToCancel }}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="flex flex-col space-y-2 bg-white px-5 pl-5 pr-5 pt-5 pb-safe-offset-5">
        <div *ngIf="currentSlide.index === 2 && purchases.yearlyProduct && purchases.monthlyProduct">
            <div
                class="text-xl font-bold text-black"
                [translate]="'REMAIN_DARTCOUNTER_ULTIMATE'"
                i18n="@@REMAIN_DARTCOUNTER_ULTIMATE">
                Remain DartCounter Ultimate
            </div>
            <div
                class="grid grid-cols-12 items-center rounded-lg bg-neutral-50 p-3 text-black"
                (click)="setRecurring('yearly')"
                *ngIf="subscription.plan.interval_period === 'month'">
                <div class="col-span-7">
                    <div [translate]="'YEARLY'" i18n="@@YEARLY" class="text-xl font-semibold">Yearly</div>
                    <div
                        class="mt-1 text-xxs font-extralight text-neutral-400"
                        *ngIf="purchases.yearlyProduct?.pricing?.price">
                        {{ 'BILLED_ANNUALLY' | translate }} ({{
                            removeSpaces(purchases.yearlyProduct?.pricing?.price)
                        }})
                    </div>
                    <div
                        *ngIf="purchases.yearlyProduct?.pricing?.price"
                        class="mt-2 inline-flex flex-row items-end rounded-md border-2 border-orange px-3 py-2 font-bebas-neue text-black">
                        <div class="text-4xl">{{ yearPricePerMonth(purchases.yearlyProduct?.pricing) }}</div>
                        <div class="ml-1 text-lg" [translate]="'PER_MONTH'" i18n="@@PER_MONTH">per month</div>
                    </div>
                    <div class="mt-2 font-bebas-neue text-sm font-extralight text-neutral-400">
                        <div class="old-price relative inline-block" *ngIf="purchases.monthlyProduct?.pricing?.price">
                            {{ removeSpaces(purchases.monthlyProduct?.pricing?.price) }}
                            <div [translate]="'PER_MONTH'" i18n="@@PER_MONTH" class="inline">per month</div>
                        </div>
                    </div>
                </div>
                <div class="col-span-3 col-start-8 inline-flex items-end">
                    <div
                        class="sale inline-flex w-full flex-col items-center rounded-md bg-orange p-2 text-center font-bebas-neue text-white">
                        <div class="text-1xl" [translate]="'SAVE_DISCOUNT'" i18n="@@SAVE_DISCOUNT">Save</div>
                        <div class="text-4xl">38%</div>
                    </div>
                </div>
                <div class="col-span-1 col-start-12 inline-flex items-center justify-end text-neutral-400">
                    <ion-icon name="chevron-forward-outline"></ion-icon>
                </div>
            </div>
            <div
                class="mt-3 grid grid-cols-12 items-center rounded-lg bg-neutral-50 p-3 text-black"
                (click)="setRecurring('monthly')"
                *ngIf="subscription.plan.interval_period === 'year'">
                <div class="col-span-7">
                    <div [translate]="'MONTHLY'" i18n="@@MONTHLY" class="text-xl font-semibold">Monthly</div>
                    <div
                        [translate]="'MONTHLY_PAYMENT'"
                        i18n="@@MONTHLY_PAYMENT"
                        class="mt-1 text-xxs font-extralight text-neutral-400">
                        Monthly payment
                    </div>
                    <div
                        *ngIf="purchases.monthlyProduct?.pricing?.price"
                        class="mt-2 inline-flex flex-row items-end rounded-md border-2 border-black px-3 py-2 font-bebas-neue text-black">
                        <div class="text-4xl">{{ removeSpaces(purchases.monthlyProduct?.pricing?.price) }}</div>
                        <div class="ml-1 text-lg" [translate]="'PER_MONTH'" i18n="@@PER_MONTH">per month</div>
                    </div>
                </div>
                <div class="col-span-1 col-start-12 inline-flex items-center justify-end text-neutral-400">
                    <ion-icon name="chevron-forward-outline"></ion-icon>
                </div>
            </div>
        </div>
        <app-primary-button
            size="normal"
            addedClasses="w-full"
            [translate]="'REMAIN_ULTIMATE'"
            i18n="@@REMAIN_ULTIMATE"
            (click)="dismissModal()"
            *ngIf="!isEnd && currentSlide.index === 1">
            Remain ultimate
        </app-primary-button>
        <!-- <app-primary-button
            size="normal"
            addedClasses="w-full"
            (click)="nextSlide()"
            *ngIf="!isEnd && currentSlide.index !== 0">
            {{ currentSlide.nextText }}
        </app-primary-button> -->
        <div class="pl-10 pr-10">
            <app-primary-button
                size="extrasmall"
                color="white"
                addedClasses="w-full text-neutral-200 font-extralight"
                fill="clear"
                [underline]="false"
                [bold]="false"
                (click)="nextSlide()">
                <ng-container
                    *ngIf="currentSlide.index === 0"
                    [translate]="'NO_CANCEL_INSTEAD'"
                    i18n="@@NO_CANCEL_INSTEAD">
                    No, I want to cancel my subscription
                </ng-container>
                <ng-container
                    *ngIf="currentSlide.index === 1"
                    [translate]="'CONTINUE_CANCELLATION'"
                    i18n="@@CONTINUE_CANCELLATION">
                    Continue cancellation
                </ng-container>
            </app-primary-button>
        </div>
    </div>
</div>
