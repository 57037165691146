/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { ConfigApiService } from '@dc-api/config.api.service';
import { PaymentApiService } from '@dc-api/payment.api.service';
import { Plan } from '@dc-core/dc-backend/dc-classes';
import { DiscountEvent } from '@dc-core/dc-backend/dc-enums';
import { UltimateHelper } from '@dc-core/dc-helpers/ultimate.helper';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { round } from 'lodash';
import { GA_EVENTACTIONS } from 'src/app/app.globals';
import { AllFeaturesDialogComponent } from 'src/dialogs/all-features/all-features';
import { DiscountDialogComponent } from 'src/dialogs/discount/discount';
import { UltimateSubscriptionDialogComponent } from 'src/dialogs/ultimate-subscription/ultimate-subscription';
import { environment } from 'src/environments/environment';

import { AuthService } from '../services/auth.service';

/*
  Generated class for the DartCounter AudioService.
*/
@Injectable()
export class UpgradeService {
    public monthlyPlan: Plan & { title?: string; monthlyPrice?: number; percentage?: number };
    public yearlyPlan: Plan & { title?: string; monthlyPrice?: number; percentage?: number };
    public plans: Plan[] & { title?: string; monthlyPrice?: number; percentage?: number }[] = [];
    public ultimateFeatures: string[] = UltimateHelper.getFeatures();

    public discountEvent: DiscountEvent = null;
    public discountEventBanner: string = null;
    public discountEventBannerMobile: string = null;
    public discountEventLoginTitle: string = null;

    private dcConfig;

    constructor(
        private auth: AuthService,
        private ga: DartCounterAnalyticsService,
        private _configApiService: ConfigApiService,
        private _paymentApiService: PaymentApiService
    ) {}

    loadPlans(withDiscount: boolean): void {
        this._paymentApiService.getPlans({ is_active: true }).then((res) => {
            this.monthlyPlan = res.data.find((plan) => plan.interval_period === 'month');
            this.monthlyPlan.title = $localize`:@@ULTIMATE_MONTHLY:Ultimate Monthly`;
            this.monthlyPlan.monthlyPrice = this.monthlyPlan.price / 100;
            this.monthlyPlan.first_payment_price = round(this.monthlyPlan.first_payment_price / 100, 2);
            this.monthlyPlan.final_first_payment_price = round(this.monthlyPlan.final_first_payment_price / 100, 2);

            this.yearlyPlan = res.data.find((plan) => plan.interval_period === 'year');
            this.yearlyPlan.title = $localize`:@@ULTIMATE_YEARLY:Ultimate Yearly`;

            this.yearlyPlan.monthlyPrice = round(Math.floor(this.yearlyPlan.price / 12) / 100, 2);
            this.yearlyPlan.first_payment_price = round(this.yearlyPlan.first_payment_price / 100, 2);
            this.yearlyPlan.final_first_payment_price = round(this.yearlyPlan.final_first_payment_price / 100, 2);
            this.yearlyPlan.percentage = round(
                (1 - this.yearlyPlan.monthlyPrice / this.monthlyPlan.monthlyPrice) * 100,
                0
            );

            if (withDiscount && environment.isWeb && this.yearlyPlan.active_discount) {
                this.discountEvent = this.yearlyPlan.active_discount.event;
                switch ((this, this.discountEvent)) {
                    case 'black_friday':
                        this.discountEventBanner = 'BlackFriday33Off_web.jpg';
                        this.discountEventBannerMobile = 'BlackFriday33Off_mobile.jpg';
                        this.discountEventBannerMobile = 'BlackFriday33Off_mobile.jpg';
                        this.discountEventLoginTitle = $localize`:@@LOGIN_FOR_BLACK_FRIDAY:Login to use this Black Friday deal!`;
                        break;
                }
            }

            this.plans.push(this.yearlyPlan);
            this.plans.push(this.monthlyPlan);
        });
    }

    async GetUpgradeDialog(triggeredFrom, forceDiscount = false) {
        let dialog: any = UltimateSubscriptionDialogComponent;
        const componentProps = { title: 'UPGRADE_ACCOUNT', slidesOrder: [] };

        let cssClass = environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal';

        await this._configApiService.getConfig({}).then((res) => {
            this.dcConfig = res.data;
        });

        if (this.auth.user.is_ultimate) {
            this.ga.trackEvent(triggeredFrom, GA_EVENTACTIONS.ALLFEATURES);
            dialog = AllFeaturesDialogComponent;
            cssClass = environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal';
        } else if (
            forceDiscount ||
            (this.dcConfig['DashboardCTALink'] && this.dcConfig['DashboardCTALink']['value'] === 'DISCOUNT')
        ) {
            this.ga.trackEvent(triggeredFrom, GA_EVENTACTIONS.OPENDISCOUNT);
            dialog = DiscountDialogComponent;
            cssClass = 'auto-height';
            componentProps.title = this.dcConfig['DiscountTitle']['value'];
        } else {
            this.ga.trackEvent(triggeredFrom, GA_EVENTACTIONS.OPENULTIMATE);
        }

        // Return the dialog data
        return {
            component: dialog,
            componentProps,
            cssClass,
            showBackdrop: true,
        };
    }
}
