/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';
import { DocumentSnapshot, Timestamp, Unsubscribe } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';
import { SoundPath } from '@dc-core/dc-backend/dc-enums';
import { OnlineGameplay, ONLINEGAMESTATUS, PLAYERSTATUS } from '@dc-core/dc-backend/dc-interfaces';
import { ActiveGamesCollectionService } from '@dc-core/dc-firestore/collection-helpers/active_games.collection.service';
import { InviteCollectionService } from '@dc-core/dc-firestore/collection-helpers/invite.collection.service';
import { LobbySettingsCollectionService } from '@dc-core/dc-firestore/collection-helpers/lobby_settings.collection.service';
import { FireStoreAuthService } from '@dc-core/dc-firestore/firestore-auth.service';
import { OnlineFunctions } from '@dc-core/dc-gamelogic/online-game/online.functions';
import { IntervalManager } from '@dc-core/dc-logging/interval.manager';
import { INTERVAL_KEY } from '@dc-core/dc-logging/subscription_enums';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { DartCounterAudioService } from '@dc-core/dc-services/audio/audio.service';
import { OnlineGamesService } from '@dc-core/dc-services/online-games.service';
import { DartCounterPreferenceService } from '@dc-core/dc-services/preference/preference.service';
import {
    IonRouterOutlet,
    MenuController,
    ModalController,
    ModalOptions,
    NavController,
    Platform,
} from '@ionic/angular';
import { PowerMode } from 'capacitor-power-mode/dist/esm';
import moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { QuitOnlyDialogComponent } from 'src/dialogs/quit-only/quit-only';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/services/auth.service';

import { HeadToHeadDialogOneComponent } from '../headtohead1.dialog/headtohead1.dialog';

export interface PreGameDialogPayload {
    onlineGameplay: OnlineGameplay;
}
@Component({
    selector: 'app-page-pre-game',
    templateUrl: 'pre-game.html',
    styleUrls: ['pre-game.scss'],
})
export class PreGameDialogComponent implements OnInit {
    public onlineGameplay: OnlineGameplay;
    public is_ready = false;
    public isOwner: boolean = null;
    public isStartingPlayer: boolean = null;
    private playersReady = 0;

    public timer = 40;
    public startedTimer = false;

    private startedHeadToHead = false;
    private isStarting = false;

    private backButtonSubscription: Subscription;

    private _unsubscribeActiveGame: Unsubscribe = null;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private routerOutlet: IonRouterOutlet,
        private nav: NavController,
        public menu: MenuController,
        public platform: Platform,
        public auth: AuthService,
        private route: ActivatedRoute,
        private modal: ModalController,
        public onlineFunctions: OnlineFunctions,
        private _online: OnlineGamesService,
        private _dcAudio: DartCounterAudioService,
        private _preferenceService: DartCounterPreferenceService,
        private _alertService: DartCounterAlertService,
        private _lobbySettingsService: LobbySettingsCollectionService,
        private _activeGamesCollectionService: ActiveGamesCollectionService,
        private _intervalManager: IntervalManager,
        private _dcFireAuth: FireStoreAuthService,
        private _dcInvites: InviteCollectionService
    ) {}

    ngOnInit(): void {
        this._online.controlSmartDeviceInactivity(false, false, false);

        if (!environment.isWeb) {
            this.menu.enable(false, 'mainmenu');
        }

        this._dcInvites.allowInvites = false;
        this.routerOutlet.swipeGesture = false;

        try {
            if (this.platform.is('ios') && this.platform.is('capacitor')) {
                PowerMode.lowPowerModeEnabled().then((res) => {
                    if (res.lowPowerModeEnabled) {
                        this._alertService.createAlert({
                            title: $localize`:@@LOW_POWER_MODE:Low power mode`,
                            text: $localize`:@@SWITCH_OFF_LOWER_POWER_MODE_FOR_CAM_STREAMS:Switch off ‘Low power mode’ in your device settings to prevent camera streams from pausing.`,
                            icon: 'warning',
                            timer: null,
                            confirmButtonText: $localize`:@@OK:OK`,
                        });
                    }
                });
            }
        } catch (_) {}

        // Remove own items from Lobby if you're going into pre-game
        this._lobbySettingsService.removeOwnItems();

        this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(9999, () => {
            this.quit();
        });

        this.route.queryParams.pipe(takeUntil(this._unsubscribeAll)).subscribe(async (params) => {
            this._unsubscribeActiveGame = this._activeGamesCollectionService.watchDoc(
                params.doc_id,
                (docSnapshot: DocumentSnapshot<OnlineGameplay>) => {
                    const gameplay = docSnapshot.data();

                    if (!gameplay) {
                        this._alertService.createAlert({
                            icon: 'warning',
                            // eslint-disable-next-line max-len
                            title: $localize`:@@MATCHMAKING_CANCELED:The matchmaking has been cancelled by your opponent`,
                        });
                        this.matchmakingCancelled();
                    } else {
                        gameplay.doc_id = docSnapshot.id;
                        gameplay.doc_ref = docSnapshot.ref;

                        this.onlineGameplay = gameplay;
                        this.checkReadyPlayers();
                        this.checkStartingPlayer();

                        if (this.playersReady == this.onlineGameplay.players.length) {
                            this.checkH2H();
                        }
                    }
                }
            );
        });
    }

    public matchmakingCancelled(): void {
        if (this._unsubscribeActiveGame) {
            this._unsubscribeActiveGame();
        }

        this._dcInvites.allowInvites = true;
        this.stopTimer();
        this._activeGamesCollectionService.removeItem(this.onlineGameplay.doc_ref);
        this.nav.navigateBack(this._activeGamesCollectionService.redirectBack);
    }

    private checkH2H() {
        if (this._unsubscribeActiveGame) {
            this._unsubscribeActiveGame();
        }

        this.stopTimer();

        if (!environment.production) {
            return this.startGame();
        }

        if (this.onlineGameplay.type === 'match') {
            if (!this.startedHeadToHead) {
                this.startedHeadToHead = true;

                this.modal
                    .create({
                        component: HeadToHeadDialogOneComponent,
                        componentProps: {
                            users: this.onlineGameplay.players,
                            isOnline: true,
                        },
                        backdropDismiss: false,
                        showBackdrop: true,
                        cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
                    } as ModalOptions)
                    .then((elem) => {
                        elem.present();
                        elem.onDidDismiss().then(() => {
                            this.startGame();
                        });
                    });
            }
        } else {
            this.startGame();
        }
    }

    private startGame(forceStart = false): void {
        if (!this.isStarting || forceStart) {
            this.isStarting = true;

            this._preferenceService.transition = true;
            setTimeout(() => {
                this._preferenceService.transition = false;
            }, 2500);

            setTimeout(() => {
                if (this._preferenceService.allowCaller) {
                    const sound = this.onlineGameplay.players[0].sound;
                    if (sound) {
                        this._dcAudio.playAudioFromBackend(SoundPath.NAMES, sound, true);
                        this._dcAudio.addDeviceToAudioStack(SoundPath.TEXTS, 'tothrowfirst.mp3');
                        this._dcAudio.addDeviceToAudioStack(SoundPath.TEXTS, 'gameon.mp3');
                    } else {
                        this._dcAudio.playAudioFromDevice(SoundPath.TEXTS, 'gameon.mp3', true);
                    }
                }

                this.onlineGameplay.timer_ends_at = Timestamp.fromDate(
                    moment().add(this.onlineGameplay.inGameTimer, 'seconds').toDate()
                );
                if (this._dcFireAuth.getCurrentUID() === this.onlineGameplay.owners[0]) {
                    this.onlineFunctions.setTeams(this.onlineGameplay);
                    this._activeGamesCollectionService.updateOnlineGameByRef(
                        this.onlineGameplay.doc_ref,
                        this.onlineGameplay,
                        ONLINEGAMESTATUS.STARTED
                    );
                    this._activeGamesCollectionService.launchPublicGame(this.onlineGameplay);
                }

                switch (this.onlineGameplay.type) {
                    case 'match':
                        this.nav.navigateForward('online-game/match/' + this.onlineGameplay.doc_id);
                        break;
                    case 'cricket_tactics':
                        this.nav.navigateForward('online-game/cricket-tactics/' + this.onlineGameplay.doc_id);
                        break;
                }
            }, 1000);
        }
    }

    checkReadyPlayers() {
        let playersReady = 0;

        if (this.onlineGameplay.playerOneStatus == PLAYERSTATUS.READY) {
            playersReady++;
        }
        if (this.onlineGameplay.playerTwoStatus == PLAYERSTATUS.READY) {
            playersReady++;
        }

        if (playersReady > 0) {
            this.startTimer();
        }

        this.playersReady = playersReady;
    }

    checkStartingPlayer(): void {
        if (this.onlineGameplay.owners[0] === this._dcFireAuth.getCurrentUID()) {
            this.isOwner = true;
        } else {
            this.isOwner = false;
        }

        if (this.onlineGameplay.players[0].uid === this._dcFireAuth.getCurrentUID()) {
            this.isStartingPlayer = true;
        } else {
            this.isStartingPlayer = false;
        }
    }

    switchPlayers(): void {
        if (this.onlineGameplay.owners[0] === this._dcFireAuth.getCurrentUID()) {
            this._activeGamesCollectionService.switchPlayers(this.onlineGameplay);
        }
    }

    ready() {
        if (this.is_ready) {
            return false;
        }

        this.is_ready = true;

        let myIndex: number = null;
        this.onlineGameplay.owners.forEach((uid, index) => {
            if (uid === this._dcFireAuth.getCurrentUID()) {
                myIndex = index;
            }
        });

        const firestoreReady = {} as OnlineGameplay;
        if (myIndex === 0) {
            firestoreReady.playerOneStatus = PLAYERSTATUS.READY;
        } else {
            firestoreReady.playerTwoStatus = PLAYERSTATUS.READY;
        }
        this.playersReady++;

        this._activeGamesCollectionService.setReady(this.onlineGameplay.doc_ref, firestoreReady);
        if (this.playersReady !== this.onlineGameplay.players.length) {
            this.startTimer();
        } else {
            this._activeGamesCollectionService.updateOnlineGameByRef(this.onlineGameplay.doc_ref, this.onlineGameplay);
        }
    }

    startTimer() {
        if (this.startedTimer) {
            return;
        }

        this.startedTimer = true;

        const preGameTimerInterval = setInterval(() => {
            if (this.timer >= 1) {
                this.timer--;
            } else {
                $localize`:@@MATCH_START_SUSPENDED:Matchmaking cancelled`;
                $localize`:@@CLOSE:Close`;

                let info = $localize`:@@OPPONENT_DID_NOT_RESPOND:Your opponent wasn't ready in time`;
                if (!this.is_ready) {
                    info = $localize`:@@YOU_DID_NOT_RESPOND:You were not ready in time`;
                }

                this._alertService.createAlert({
                    title: $localize`:@@MATCH_START_SUSPENDED:Matchmaking cancelled`,
                    text: info,
                    icon: 'warning',
                    showCloseButton: true,
                });

                this.matchmakingCancelled();
            }
        }, 1000);

        this._intervalManager.track('pre-game', INTERVAL_KEY.PREGAME_TIMER, preGameTimerInterval);
    }

    stopTimer() {
        this._intervalManager.clear(INTERVAL_KEY.PREGAME_TIMER);
    }

    ionViewWillLeave(): void {
        this.menu.enable(true, 'mainmenu');
        this.routerOutlet.swipeGesture = true;

        if (this.backButtonSubscription) {
            this.backButtonSubscription.unsubscribe();
        }

        if (this._unsubscribeActiveGame) {
            this._unsubscribeActiveGame();
        }

        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        this.stopTimer();
    }

    showTimer(): string {
        return moment('2015-01-01').startOf('day').seconds(this.timer).format('mm:ss');
    }

    quit(): void {
        this.modal
            .create({
                component: QuitOnlyDialogComponent,
                cssClass: 'auto-height',
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data === 'quit') {
                        this.matchmakingCancelled();
                    }
                });
            });
    }
}
