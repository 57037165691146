<!-- Translation containers-->
@if (false) {
    <ng-container i18n="@@SEARCH">Search</ng-container>
}
<!-- End of translation containers-->

<app-page-header mainNavigation="back" defaultBack="dashboard">
    <div title [translate]="'FRIENDS'" i18n="@@FRIENDS">Friends</div>
    <app-option-button icon="person_add" (click)="OpenFriends()" color="text-white"></app-option-button>
</app-page-header>

<div class="flex flex-col h-full bg-white-pattern overflow-y-auto p-4">
    <div
        class="flex flex-col overflow-y-auto scrollbar-hide"
        appWebContainer
        infiniteScroll
        [scrollWindow]="false"
        (scrolled)="getFriends()">
        @if (incomingFriends?.length) {
            <div class="mt-2 text-black p-4 w-full">
                <div class="flex items-center justify-center space-x-2">
                    <div [translate]="'FRIEND_REQUESTS'" i18n="@@FRIEND_REQUESTS">Friend requests</div>
                    <ion-badge mode="md" item-end class="ion-bg-yellow !text-black">
                        {{ incomingFriends.length }}
                    </ion-badge>
                </div>

                @for (friend of incomingFriends; track friend) {
                    <div class="flex items-center justify-between space-x-2 p-2 text-black">
                        <div class="flex items-center space-x-2 cursor-pointer" (click)="OpenProfile(friend)">
                            <app-profile-photo
                                [size]="40"
                                [ultimate]="friend.is_ultimate"
                                [url]="friend.profile?.profile_photo_url"></app-profile-photo>
                            <div class="space-y-1">
                                <div class="font-semibold">{{ friend.full_name }}</div>
                                <div class="text-xs">
                                    {{ friend.profile?.nickname || ('NO_NICKNAME' | translate) }}
                                </div>
                            </div>
                        </div>
                        <div class="flex justify-end items-center space-x-2">
                            <div
                                class="rounded-sm border border-black text-black bg-transparent w-8 h-8 inline-flex justify-center items-center cursor-pointer"
                                (click)="DenyFriend(friend)">
                                <app-icon icon="close" size="medium"></app-icon>
                            </div>
                            <div
                                class="rounded-sm border border-blue bg-blue !text-white w-8 h-8 inline-flex justify-center items-center cursor-pointer"
                                (click)="AcceptFriend(friend)">
                                <app-icon icon="done" size="medium"></app-icon>
                            </div>
                        </div>
                    </div>
                }
            </div>
        }

        @if (friends === null) {
            <ion-row class="mt-8">
                <ion-col class="text-center">
                    <ion-spinner name="crescent"></ion-spinner>
                </ion-col>
            </ion-row>
        }

        @if (friends?.length) {
            <div class="mx-4">
                <div class="dialog-input search-input">
                    <input type="text" [formControl]="searchControl" placeholder="{{ 'SEARCH' | translate }}" />
                    <app-icon icon="person_search"></app-icon>
                </div>
            </div>
        }

        @if (friends && friends.length) {
            <div class="flex-1 p-4">
                @for (friend of friends; track $index) {
                    <div
                        class="flex items-center space-x-2 p-2 text-black cursor-pointer"
                        (click)="OpenProfile(friend)">
                        <app-profile-photo
                            [size]="40"
                            [ultimate]="friend.is_ultimate"
                            [url]="friend.profile?.profile_photo_url"></app-profile-photo>
                        <div class="space-y-1">
                            <div class="font-semibold">{{ friend.full_name }}</div>
                            <div class="text-xs">
                                {{ friend.profile?.nickname || ('NO_NICKNAME' | translate) }}
                            </div>
                        </div>
                    </div>
                }
            </div>
        }

        @if (friends && !friends.length) {
            <div class="text-center text-black mx-4 mt-4">
                <span [translate]="'NO_FRIENDS_YET'" i18n="@@NO_FRIENDS_YET"
                    >No friends added yet. Find your friends here!</span
                >

                <app-primary-button
                    addedClasses="w-full mt-2"
                    addedSlotClasses="font-semibold"
                    color="orange"
                    size="small"
                    [bold]="false"
                    [translate]="'SEARCH_FRIENDS'"
                    i18n="@@SEARCH_FRIENDS"
                    (click)="OpenFriends()">
                    Search friends
                </app-primary-button>
            </div>
        }
    </div>
</div>
