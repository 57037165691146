import { Injectable, inject } from '@angular/core';
import * as moment from 'moment';
import { User } from '../dc-backend/dc-classes';
import { DartCounterLocalizeService } from './localize.service';

@Injectable()
export class FreeMatchesService {
    public localizeService: DartCounterLocalizeService = inject(DartCounterLocalizeService);

    public count: number;
    public freeOnlineMatchesMessage: string;
    public canPlay: boolean = true;
    public durationInterval;
    public durationLeft: moment.Duration;
    public timeLeft: {
        days: number;
        hours: number;
        minutes: number;
        seconds: number;
    };

    check(count: number, date: string = null, user: User): void {
        this.count = count;

        if (user.is_ultimate) {
            this.canPlay = true;
        } else if (count > 0) {
            this.freeOnlineMatchesMessage = this.localizeService.FREE_ONLINE_GAMES_LEFT_THIS_WEEK_COUNT(count);
            this.canPlay = true;
        } else if (date) {
            this.freeOnlineMatchesMessage = $localize`:@@NO_MORE_FREE_GAMES_THIS_WEEK:Your free online games for this week have been used`;
            this.canPlay = false;
            this.showTimeLeft(date);
        }
    }

    showTimeLeft(date: string): void {
        if (this.durationInterval) {
            clearInterval(this.durationInterval);
        }

        // Unix timestamp
        const currentTime = moment().utc().unix();
        this.durationLeft = moment.duration((currentTime - moment(date).add(1, 'week').unix()) * 1000, 'milliseconds');

        this.durationInterval = setInterval(() => {
            this.durationLeft = moment.duration(this.durationLeft.asMilliseconds() + 1000, 'milliseconds');
            this.timeLeft = {
                days: moment.duration(this.durationLeft).days() * -1,
                hours: moment.duration(this.durationLeft).abs().hours(),
                minutes: moment.duration(this.durationLeft).abs().minutes(),
                seconds: moment.duration(this.durationLeft).abs().seconds(),
            };
        }, 1000);
    }

    clear() {
        if (this.durationInterval) {
            clearInterval(this.durationInterval);
        }
    }
}
