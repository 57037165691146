<app-page-header mainNavigation="custom" color="transparent">
    <div title [translate]="'QUIT_GAME'" i18n="@@QUIT_GAME">Quit game</div>
</app-page-header>

<ion-content>
    <ion-row>
        <ion-col>
            <p [translate]="'SAVE_FOR_LATER'" i18n="@@SAVE_FOR_LATER">
                Do you want to save this game to be able to continue later?
            </p>
        </ion-col>
    </ion-row>

    <ion-row class="fixed bottom-0 w-full">
        <ion-col>
            <ion-button color="danger" expand="full" (click)="DismissModal()">
                <span [translate]="'QUIT'" i18n="@@QUIT">Quit</span>
            </ion-button>
        </ion-col>
        <ion-col>
            <ion-button color="primary" expand="full" (click)="DismissModal(true)">
                <span [translate]="'SAVE'" i18n="@@SAVE">Save</span>
            </ion-button>
        </ion-col>
    </ion-row>
</ion-content>
