import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { DartCounterMatchTeam } from '@dc-core/dc-gamelogic/in-game/match/match.in-game.classes';

@Component({
    selector: 'app-checkout-suggestion',
    templateUrl: 'checkout-suggestion.component.html',
    standalone: true,
    imports: [CommonModule],
})
export class CheckoutSuggestionComponent {
    @Input() team: DartCounterMatchTeam;
}
