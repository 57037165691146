/* eslint-disable max-len */
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { Camera } from '@capacitor/camera';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Media } from '@capacitor-community/media';
import { CameraStreamClipApiService } from '@dc-api/camera-stream-clip.api.service';
import { CameraStreamClip } from '@dc-core/dc-backend/dc-classes';
import { AlertPayload, DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { ModalController, NavController, Platform, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { DCLoadingService } from '@providers/DCLoadingService';
import { UpgradeService } from '@providers/UpgradeService';
import _ from 'lodash';
import moment from 'moment';
import { GA_EVENTACTIONS, GA_EVENTCATEGORIES } from 'src/app/app.globals';
import { PromptDialogComponent } from 'src/dialogs/prompt/prompt.dialog';
import { RenameDialogComponent, RenameDialogPayload } from 'src/dialogs/rename/rename.dialog';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/services/auth.service';

import { ClipPopoverComponent } from './popovers/clip-popover/clip-popover.component';

@Component({
    selector: 'app-action-replays',
    templateUrl: 'action-replays.component.html',
})
export class ActionReplaysComponent implements OnInit {
    public translateService: TranslateService = inject(TranslateService);

    private modalController: ModalController = inject(ModalController);
    private popoverController: PopoverController = inject(PopoverController);
    private screenOrientation: ScreenOrientation = inject(ScreenOrientation);
    private authService: AuthService = inject(AuthService);
    private ga: DartCounterAnalyticsService = inject(DartCounterAnalyticsService);
    private alertService: DartCounterAlertService = inject(DartCounterAlertService);
    private cameraStreamClipApiService: CameraStreamClipApiService = inject(CameraStreamClipApiService);
    private upgradeService: UpgradeService = inject(UpgradeService);
    private navController: NavController = inject(NavController);
    private platform: Platform = inject(Platform);
    private loadingService: DCLoadingService = inject(DCLoadingService);

    public clips: CameraStreamClip[] = null;
    public isUltimate: boolean;
    public activeTab: 'all' | 'favorites' = 'all';

    private currentPopover: HTMLIonPopoverElement = null;

    protected destroyRef: DestroyRef = inject(DestroyRef);

    ngOnInit(): void {
        this.isUltimate = this.authService.user.is_ultimate;
        this.authService.userIsUltimate.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isUltimate) => {
            this.isUltimate = isUltimate;
        });

        this.cameraStreamClipApiService.getCameraStreamClips({}).then((res) => {
            res.data.forEach((clip: CameraStreamClip) => {
                clip.durationText = moment('2015-01-01').startOf('day').seconds(clip.duration).format('mm:ss');
                clip.expiresAt = this.getDaysLeft(clip, this.isUltimate ? 30 : 7);
            });
            this.clips = res.data as CameraStreamClip[];
        });

        this.screenOrientation
            .onChange()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                if (this.currentPopover) {
                    this.currentPopover.dismiss();
                    this.currentPopover = null;
                }
            });
    }

    ionViewWillEnter() {
        this.ga.trackView('Action Replays');
    }

    public changeActiveTab(activeTab: 'all' | 'favorites'): void {
        this.activeTab = activeTab;
    }

    public deleteClip(clip: CameraStreamClip, index: number) {
        this.modalController
            .create({
                component: PromptDialogComponent,
                componentProps: {
                    title: $localize`:@@DELETE_ACTION_REPLAY:Delete Action Replay`,
                    text: $localize`:@@DELETING_ACTION_REPLAY:Do you want to delete this Action Replay?`,
                    cancelText: $localize`:@@CANCEL:Cancel`,
                    confirmText: $localize`:@@CONFIRM:Confirm`,
                    confirmColor: 'red',
                } as AlertPayload,
                cssClass: 'auto-height',
                showBackdrop: true,
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.cameraStreamClipApiService.deleteCameraStreamClipById({ clipId: clip.id }).then(() => {
                            this.alertService.createAlert({
                                title: $localize`:@@DELETED_YOUR_ACTION_REPLAY:Deleted your Action Replay`,
                                icon: 'success',
                            });
                            this.clips.splice(index, 1);
                        });
                    }
                });
            });
    }

    public showClipPopover(clip: CameraStreamClip, index: number) {
        if (clip.favorited_at && !this.authService.user.is_ultimate) {
            this.openSubscriptionDialog();
            return;
        }

        this.popoverController
            .create({
                event,
                component: ClipPopoverComponent,
                componentProps: {
                    isProcessed: clip.video_path != null,
                },
            })
            .then((popover) => {
                this.currentPopover = popover;
                this.currentPopover.present();
                this.currentPopover.onDidDismiss().then(async (res) => {
                    this.currentPopover = null;

                    if (res.data === 'rename') {
                        this.rename(clip);
                    } else if (res.data == 'delete') {
                        this.deleteClip(clip, index);
                    } else if (res.data == 'download') {
                        if (this.platform.is('capacitor')) {
                            this.downloadMobile(clip, index);
                        } else {
                            const response = await fetch(
                                environment.apiUrl + '/camera-stream-clips/' + clip.id + '/download',
                                {
                                    headers: {
                                        Authorization: `Bearer ${this.authService.accessToken}`,
                                    },
                                    method: 'POST',
                                }
                            );
                            if (response.ok) {
                                this.ga.trackEvent(GA_EVENTCATEGORIES.ACTION_REPLAYS, GA_EVENTACTIONS.DOWNLOAD_REPLAY);

                                this.cameraStreamClipApiService
                                    .showCameraStreamClipById({ clipId: clip.id })
                                    .then(async (res) => {
                                        this.clips[index] = res.data as CameraStreamClip;

                                        const blob = await response.blob();
                                        const data = window.URL.createObjectURL(blob);
                                        const a = document.createElement('a');
                                        a.href = data;
                                        a.download = this.getFileName(clip);
                                        document.body.appendChild(a);
                                        a.click();
                                        a.remove();
                                    });
                            }
                        }
                    }
                });
            });
    }

    public async toggleFavorite(clip: CameraStreamClip): Promise<void> {
        if (!clip.favorited_at && !this.authService.user.is_ultimate) {
            this.ga.trackEvent(GA_EVENTCATEGORIES.ACTION_REPLAYS, GA_EVENTACTIONS.FAVORITENOTULTIMATE);

            this.openSubscriptionDialog();
            return;
        }

        if (clip.favorited_at) {
            const days = this.authService.user.is_ultimate ? 30 : 7;
            if (this.getDaysLeft(clip, days) <= 0) {
                $localize`:@@UNFAVORITE_ACTION_REPLAY_DELETION_WARNING:This Action Replay is older than ${days}:days: days. It will be deleted from your gallery if you remove it from favourites.`;
                this.alertService.createAlert({
                    title: this.translateService.instant('UNFAVORITE_ACTION_REPLAY_DELETION_WARNING', { days }),
                    text: $localize`:@@ARE_YOU_SURE_Q:Are you sure?`,
                    icon: 'warning',
                    timer: null,
                    confirmButtonText: $localize`:@@YES:Yes`,
                    cancelButtonText: $localize`:@@CANCEL:Cancel`,
                    onConfirm: () => {
                        this.updateFavorite(clip);
                    },
                });
            } else {
                this.updateFavorite(clip);
            }
        } else {
            this.updateFavorite(clip);
        }
    }

    public async play(clip: CameraStreamClip): Promise<void> {
        this.navController.navigateForward('/action-replays/view', { state: { clip } });
    }

    public async openSubscriptionDialog() {
        const dialogSettings = await this.upgradeService.GetUpgradeDialog(GA_EVENTCATEGORIES.ACTION_REPLAYS);
        this.modalController.create(dialogSettings).then((elem) => {
            elem.present();
        });
    }

    private rename(clip: CameraStreamClip): void {
        this.modalController
            .create({
                component: RenameDialogComponent,
                backdropDismiss: true,
                componentProps: {
                    title: $localize`:@@RENAME_YOUR_ACTION_REPLAY:Rename your Action Replay`,
                    renamable: _.cloneDeep(clip.title),
                } as RenameDialogPayload,
                cssClass: 'auto-height',
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        clip.title = dialogRes.data;
                        this.cameraStreamClipApiService
                            .updateCameraStreamClipById({
                                clipId: clip.id,
                                title: clip.title,
                            })
                            .finally(() => {
                                this.alertService.createAlert({
                                    title: $localize`:@@RENAMED:Renamed`,
                                    icon: 'success',
                                });
                            });
                    }
                });
            });
    }

    private async downloadMobile(clip: CameraStreamClip, index: number): Promise<void> {
        try {
            const checkFilesystemPermissions = await Filesystem.checkPermissions();
            if (checkFilesystemPermissions.publicStorage !== 'granted') {
                const filesystemPermissionsResult = await Filesystem.requestPermissions();
                if (filesystemPermissionsResult.publicStorage === 'granted') {
                    setTimeout(() => {
                        this.downloadMobile(clip, index);
                    }, 500);
                    return;
                } else {
                    throw 'No permission';
                }
            }
        } catch (_) {
            this.alertService.createAlert({
                title: $localize`:@@ENABLE_STORAGE_APP_PERMS:Please enable storage permissions in your device settings.`,
                icon: 'error',
                timer: 2000,
                timerProgressBar: true,
                showCloseButton: true,
            });
            return;
        }

        try {
            const checkCamPermissions = await Camera.checkPermissions();
            if (checkCamPermissions.photos !== 'granted') {
                const requestCamResult = await Camera.requestPermissions();
                if (requestCamResult.photos === 'granted') {
                    setTimeout(() => {
                        this.downloadMobile(clip, index);
                    }, 500);
                    return;
                } else {
                    throw 'No permission';
                }
            }
        } catch (_) {
            this.alertService.createAlert({
                title: $localize`:@@ENABLE_PHOTOS_APP_PERMS:Please enable photos permissions in your device settings.`,
                icon: 'error',
                timer: 2000,
                timerProgressBar: true,
                showCloseButton: true,
            });
            return;
        }

        this.loadingService.ShowDefaultLoader();

        try {
            const downloadResult = await Filesystem.downloadFile({
                path: this.getFileName(clip),
                url: environment.apiUrl + '/camera-stream-clips/' + clip.id + '/download',
                headers: {
                    Authorization: `Bearer ${this.authService.accessToken}`,
                },
                method: 'POST',
                directory: Directory.Data,
            });

            const { albums } = await Media.getAlbums();
            let albumIdentifier = '';
            let album = albums.find((a) => a.name === 'DartCounter');
            if (album) {
                albumIdentifier = album.identifier;
            } else {
                await Media.createAlbum({ name: 'DartCounter' });
                const { albums } = await Media.getAlbums();
                album = albums.find((a) => a.name === 'DartCounter');
                albumIdentifier = album.identifier;
            }

            this.cameraStreamClipApiService.showCameraStreamClipById({ clipId: clip.id }).then((res) => {
                this.clips[index] = res.data as CameraStreamClip;

                Media.saveVideo({ path: downloadResult.path, albumIdentifier })
                    .then(() => {
                        this.ga.trackEvent(GA_EVENTCATEGORIES.ACTION_REPLAYS, GA_EVENTACTIONS.DOWNLOAD_REPLAY);
                        this.alertService.createAlert({
                            title: $localize`:@@DOWNLOADED:Downloaded`,
                            icon: 'success',
                        });
                    })
                    .finally(() => {
                        this.loadingService.DismissLoader();
                    });
            });
        } catch (_) {
            this.loadingService.DismissLoader();
        }
    }

    private updateFavorite(clip: CameraStreamClip): void {
        this.cameraStreamClipApiService
            .updateCameraStreamClipById({
                clipId: clip.id,
                favorite: clip.favorited_at ? false : true,
            })
            .then((res) => {
                if (res.data) {
                    clip.favorited_at = res.data.favorited_at;

                    this.ga.trackEvent(
                        GA_EVENTCATEGORIES.ACTION_REPLAYS,
                        clip.favorited_at ? GA_EVENTACTIONS.FAVORITED : GA_EVENTACTIONS.UNFAVORITED
                    );
                } else {
                    _.remove(this.clips, (clipFromList) => clipFromList.id === clip.id);
                }
            })
            .catch((err) => {
                this.ga.trackEvent(GA_EVENTCATEGORIES.ACTION_REPLAYS, GA_EVENTACTIONS.REACHEDMAXFAVORITES);
                this.alertService.errorFromApi(err, null, true);
            });
    }

    private getDaysLeft(clip: CameraStreamClip, days: number): number {
        // Getting the time difference in milliseconds
        const timeDiff: number = new Date().getTime() - new Date(clip.created_at).getTime();

        // Converting the time difference to days
        const daysDiff: number = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

        // Now you can check if the difference is less than 30
        if (daysDiff < days) {
            return days - daysDiff;
        }

        return 0;
    }

    private getFileName(clip: CameraStreamClip): string {
        return 'action-replay-' + moment(clip.created_at).format('YYYY-MM-DD--HH-mm') + '.mp4';
    }
}
