import { Pipe, PipeTransform } from '@angular/core';
import { FinishMode } from 'dc-core/dc-backend/dc-enums';

@Pipe({
    name: 'finishMode',
})
export class FinishModePipe implements PipeTransform {
    transform(finishMode: FinishMode): string {
        switch (finishMode) {
            case 'double_out':
                return $localize`:@@DOUBLE_OUT:Double out`;
            case 'master_out':
                return $localize`:@@MASTER_OUT:Master out`;
            case 'straight_out':
                return $localize`:@@STRAIGHT_OUT:Straight out`;
        }
    }
}
