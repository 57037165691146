import { Component, Input } from '@angular/core';
import { FriendApiService } from '@dc-api/friend.api.service';
import { StatisticsApiService } from '@dc-api/statistics.api.service';
import { UserApiService } from '@dc-api/user.api.service';
import { ResultState } from '@dc-core/dc-backend/dc-enums';
import { ProfileStatisticsResponse } from '@dc-core/dc-backend/dc-responses';
import { OnlineUser } from '@dc-core/dc-gamelogic/online-game/online.ingame.globals';
import { LocalStorageKey } from '@dc-core/dc-localstorage';
import { InGameCameraService } from '@dc-core/dc-services/camera/ingame-camera.service';
import { ActionSheetController, ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { GA_EVENTACTIONS, GA_EVENTCATEGORIES } from 'src/app/app.globals';
import { DCLoadingService } from 'src/providers/DCLoadingService';
import { JoyrideService } from 'src/providers/ngx-joyride/src/lib/services/joyride.service';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-profile-dialog',
    templateUrl: 'profileDialog.html',
})
export class ProfileDialogComponent {
    @Input() user: OnlineUser;
    @Input() canReport = false;
    @Input() showMoreButton = true;

    public statistics: ProfileStatisticsResponse = null;

    public skeletonThemes = {
        info: {
            'border-radius': '5px',
            height: '20px',
            width: '25%',
            'background-color': 'rgb(76 76 76)',
        },
        infosmall: {
            'border-radius': '5px',
            height: '14px',
            width: '10%',
            'background-color': 'rgb(76 76 76)',
        },
        infosmall2: {
            'border-radius': '5px',
            height: '14px',
            width: '25%',
            'background-color': 'rgb(76 76 76)',
        },
        info2: {
            'border-radius': '5px',
            height: '16px',
            width: '25%',
            'background-color': '#ECECEC',
        },
        info3: {
            'border-radius': '5px',
            height: '20px',
            width: '25%',
            'background-color': '#ECECEC',
        },
        stats: {
            'border-radius': '5px',
            height: '50px',
            width: '50%',
            'background-color': 'rgb(76 76 76)',
        },
        profilepic: {
            border: '10px solid #2d2d2d',
            'border-radius': '50%',
            height: '135px',
            width: '135px',
            margin: '0 auto',
            'background-color': 'rgb(76 76 76)',
        },
    };

    public lastResults: ResultState[] = [];
    public twoMonthAverage: number = null;
    public isSuspended = false;
    public doneLoading = false;

    constructor(
        public modal: ModalController,
        public _modal: ModalController,
        private nav: NavController,
        private loading: DCLoadingService,
        private sheet: ActionSheetController,
        private readonly joyrideService: JoyrideService,
        public auth: AuthService,
        private translate: TranslateService,
        private friendApiService: FriendApiService,
        private _userApiService: UserApiService,
        private _statisticsApiService: StatisticsApiService,
        private _ingameCameraService: InGameCameraService,
        private ga: DartCounterAnalyticsService
    ) {}

    ionViewDidEnter() {
        this.GetPlayerDetails();

        const showTour = JSON.parse(localStorage.getItem(LocalStorageKey.firstTimeProfileDialog)) ?? true;
        if (showTour) {
            this.showTour();
            localStorage.setItem(LocalStorageKey.firstTimeProfileDialog, JSON.stringify(false));
        }
    }

    async GetPlayerDetails() {
        this.doneLoading = false;

        await this._statisticsApiService.getOnlineStatistics({ user_id: this.user.id }).then((res) => {
            this.lastResults = res.data.last_results;
            this.twoMonthAverage = res.data.two_month_average;
        });

        await this._userApiService.getUserById({ userId: this.user.id }).then((res) => {
            const user = res.data as OnlineUser;
            user.countryFlag = this.user.countryFlag;
            this.user = user;
        });

        await this._statisticsApiService.getProfileStatistics({ user_id: this.user.id }).then((res) => {
            this.statistics = res.data;
        });

        this.doneLoading = true;
    }

    public showTour(index = null) {
        this.ga.trackEvent(GA_EVENTCATEGORIES.PROFILEDIALOG, GA_EVENTACTIONS.SHOWTOUR);
        let steps = ['firstStep'];
        if (index !== null) {
            steps = [steps[index]];
        }

        setTimeout(() => {
            this.joyrideService.startTour({
                steps: steps,
                showPrevButton: false,
                themeColor: '#444',
            });
        }, 250);
    }

    public DismissModal(returnValue: any = null): void {
        this.modal.dismiss(returnValue);
    }

    AddFriend(userId) {
        this.ga.trackEvent(GA_EVENTCATEGORIES.PROFILEDIALOG, GA_EVENTACTIONS.ADDFRIEND);
        this.loading.ShowDefaultLoader();
        this.friendApiService
            .addFriendByUserId({ userId })
            .then(() => {
                this.loading.DismissLoader();
                this.user.friend_status = 'pending';
            })
            .catch(() => {
                this.loading.DismissLoader();
            });
    }

    ShowPendingOptions(accountID) {
        $localize`:@@CANCEL_REQUEST:Cancel`;
        const actionSheet = this.sheet.create({
            backdropDismiss: true,
            buttons: [
                {
                    text: this.translate.instant('CANCEL_REQUEST'),
                    icon: 'close',
                    handler: () => {
                        this.RemoveFriend(accountID);
                    },
                },
            ],
        } as any);

        actionSheet.then((elem) => elem.present());
    }

    ShowFriendOptions(accountID) {
        this.ga.trackEvent(GA_EVENTCATEGORIES.PROFILEDIALOG, GA_EVENTACTIONS.SHOWFRIENDOPTIONS);
        const actionSheet = this.sheet.create({
            backdropDismiss: true,
            buttons: [
                {
                    text: $localize`:@@REMOVE_FRIEND:Remove friend`,
                    icon: 'close',
                    handler: () => {
                        this.RemoveFriend(accountID);
                    },
                },
            ],
        } as any);

        actionSheet.then((elem) => elem.present());
    }

    RemoveFriend(accountID) {
        this.ga.trackEvent(GA_EVENTCATEGORIES.PROFILEDIALOG, GA_EVENTACTIONS.REMOVEFRIEND);
        this.loading.ShowDefaultLoader();
        this.friendApiService
            .denyFriendByUserId({ userId: accountID })
            .then(() => {
                this.user.friend_status = 'no_friends';
            })
            .finally(() => this.loading.DismissLoader());
    }

    AcceptFriend(accountID) {
        this.ga.trackEvent(GA_EVENTCATEGORIES.PROFILEDIALOG, GA_EVENTACTIONS.ACCEPTFRIEND);
        this.loading.ShowDefaultLoader();
        this.friendApiService
            .acceptFriendByUserId({ userId: accountID })
            .then(() => {
                this.loading.DismissLoader();
                this.user.friend_status = 'friends';
            })
            .finally(() => this.loading.DismissLoader());
    }

    report(): void {
        this.DismissModal('report');
    }

    OpenProfile() {
        this.ga.trackEvent(GA_EVENTCATEGORIES.PROFILEDIALOG, GA_EVENTACTIONS.OPENPROFILEUSER);
        this.DismissModal();

        this.nav.navigateForward('profile/' + this.user.id);
    }
}
