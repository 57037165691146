import { Injectable } from '@angular/core';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';

@Injectable()
export class DartCounterAnalyticsService {
    public async trackView(screenName: string): Promise<void> {
        await FirebaseAnalytics.setCurrentScreen({
            screenName,
        });
    }

    public async trackEvent(name: string, event: string, action?: string): Promise<void> {
        await FirebaseAnalytics.logEvent({
            name,
            params: { event, action },
        });
    }
}
