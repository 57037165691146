<app-page-header mainNavigation="back" defaultBack="smart-devices">
    <div title>OMNI: {{ omni.smartDevice?.name }}</div>
    <app-option-button
        right
        icon="power_settings_new"
        (click)="showShutdownPopover()"
        color="text-white"></app-option-button>
</app-page-header>

<div class="flex h-full flex-col bg-white-pattern text-black">
    <div class="socketInfoArea flex flex-none flex-row w-full bg-black mb-4" *ngIf="omni.smartDevice">
        <div class="flex w-2/3 py-3 status-container">
            <h2 class="uppercase font-bebas-neue text-center text-2xl text-white">{{ getStatusText() }}</h2>
            <div class="status-circle inline-block h-[1rem] w-[1rem]" [ngClass]="getStatusClass()"></div>
        </div>
        <div class="flex flex-1">
            <div
                class="bottomCTA blue relative"
                [class.disabled]="!omni.canConnect"
                *ngIf="getStatusClass() === 'not-connected'"
                (click)="omni.connect()">
                <p class="text-center">CONNECT</p>
            </div>
            <div class="bottomCTA relative bg-red-500" *ngIf="getStatusClass() === 'connected'" (click)="disconnect()">
                <p class="text-center">DISCONNECT</p>
            </div>
        </div>
    </div>

    <!-- OMNI View -->
    @if (view === 'omni') {
        <div class="flex flex-1 flex-col overflow-y-auto">
            @if (!environment.production) {
                <div class="flex gap-2 portrait:flex-col landscape:flex-row mx-4">
                    <div class="flex flex-col space-y-2">
                        <app-toggle-switch
                            class="block w-full rounded-md bg-white p-3"
                            [model]="omni.debugSettings.saveToArchive"
                            (itemChange)="omni.toggleDebugSetting('saveToArchive')">
                            Archive calibration images
                        </app-toggle-switch>
                    </div>
                    <div class="flex flex-col space-y-2">
                        <app-toggle-switch
                            class="block w-full rounded-md bg-white p-3"
                            [model]="omni.debugSettings.saveAllImages"
                            (itemChange)="omni.toggleDebugSetting('saveAllImages')">
                            Save all throws
                        </app-toggle-switch>
                    </div>
                </div>
            }

            <div class="flex items-center justify-center mt-4">
                <app-select
                    class="block w-[80%]"
                    [initialValue]="selectedBoard"
                    [options]="dartboardOptions"
                    (selectionChange)="selectedBoard = $event"
                    mode="white"
                    size="full"></app-select>
            </div>

            <div class="portrait:w-9/12 landscape:w-1/2 mb-6 mx-auto mt-4">
                @if (getStatusClass() === 'connected') {
                    <div
                        class="flex w-full justify-center py-3 text-2xl font-bebas-neue bg-black text-white text-center"
                        style="border-radius: 5px 5px 0 0"
                        [class.opacity-50]="isCalibrating"
                        (click)="startCalibration()">
                        Calibrate
                    </div>
                }
                <div class="relative flex flex-wrap">
                    @if (getStatusClass() === 'not-connected') {
                        <div class="flex absolute justify-center items-center w-full h-full bg-[#303332d6] z-10">
                            <div class="font-bebas-neue text-2xl text-center text-white">Not connected</div>
                        </div>
                    }
                    <div
                        class="w-1/2 relative"
                        *ngFor="let calibrationSection of omniCalibrationSections; let i = index"
                        (click)="showCalibImages(i + 1)">
                        <div class="calibrationLoadingOverlay absolute z-20" *ngIf="calibrationSection.isLoading">
                            <ion-spinner
                                style="height: 25px; width: 25px"
                                color="secondary"
                                name="crescent"></ion-spinner>
                        </div>
                        <div
                            class="absolute z-20 flex w-full h-full items-center justify-center"
                            *ngIf="
                                !calibrationSection.isLoading &&
                                calibrationSection.calibrated &&
                                !calibrationSection.has_errors
                            ">
                            <div class="flex h-25 w-25 items-center justify-center rounded-full bg-green">
                                <app-icon icon="check_mark" size="medium" class="text-white p-3"></app-icon>
                            </div>
                        </div>
                        <div
                            class="absolute z-20 flex w-full h-full items-center justify-center"
                            *ngIf="
                                !calibrationSection.isLoading &&
                                calibrationSection.calibrated &&
                                calibrationSection.has_errors
                            ">
                            <div class="flex h-25 w-25 items-center justify-center rounded-full bg-red">
                                <app-icon icon="close" class="!text-white"></app-icon>
                            </div>
                        </div>
                        <img
                            src="assets/images/ftr/{{ calibrationSection.imageUrl }}"
                            class="w-full h-full relative"
                            alt="{{ calibrationSection.label }}" />
                    </div>
                </div>
            </div>
        </div>
    } @else if (view === 'debug') {
        <div class="flex flex-1 flex-col px-4 overflow-y-auto">
            <h2 class="text-lg font-bebas-neue">SOCKET INFO</h2>
            <ion-row class="socketConfig">
                <ion-col size="9">
                    <ion-input
                        type="text"
                        placeholder="{{ 'IP_ADDRESS' | translate }}"
                        name="ip"
                        [(ngModel)]="omni.smartDevice.ip_address"
                        required>
                    </ion-input>
                </ion-col>

                <ion-col>
                    <ion-input
                        type="text"
                        placeholder="{{ 'PORT' | translate }}"
                        name="port"
                        [(ngModel)]="omni.port"
                        required>
                    </ion-input>
                </ion-col>
            </ion-row>

            <div class="mt-2 text-center text-sm text-neutral-600">
                <span>MAC Address: {{ omni.smartDevice.mac_address || 'Unknown' }}</span>
            </div>

            @if (omni.smartDevice?.freespace_in_MB) {
                <div class="mt-2 text-center text-sm text-neutral-600">
                    <span>Free space: {{ omni.smartDevice.freespace_in_MB }}MB</span>
                </div>
            }
            @if (omni.smartDevice?.version) {
                <div class="mt-2 text-center text-sm text-neutral-600">
                    <span>v{{ omni.smartDevice.version }}</span>
                </div>
            }

            @if (!environment.production) {
                <div class="custom-command-container mt-2">
                    <input [(ngModel)]="customCommand" placeholder="Custom command" class="custom-command-input" />
                    <button (click)="sendCustomCommand()" class="custom-command-button">Send</button>
                </div>
            }

            <div class="socketInfoArea">
                <div class="flex flex-none items-center space-x-2 p-1" *ngIf="omni.smartDevice">
                    <div class="toggleTimestamps text-white" (click)="clearLog()">Clear Log</div>
                    <div class="toggleTimestamps text-white" (click)="toggleTimestamps()">Toggle Timestamps</div>
                </div>
                <div class="logContent" appScrollToBottom>
                    <div
                        *ngFor="let log of logs"
                        class="log-entry"
                        [ngClass]="{
                            'log-incoming': log.type === logTypes.INCOMING,
                            'log-outgoing': log.type === logTypes.OUTGOING,
                            'log-error': log.type === logTypes.ERROR,
                            'log-warning': log.type === logTypes.WARNING,
                            'log-info': log.type === logTypes.INFO
                        }">
                        <div class="timestamp" *ngIf="showTimestamps">
                            {{ log.timestamp | date: 'HH:mm:ss' : '' : translateService.currentLang }}
                        </div>
                        <div class="message">
                            <span>{{ log.message }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    } @else if (view === 'more') {
        <!-- More -->
        <div class="flex flex-1 flex-col px-4 py-5 overflow-y-auto">
            <div class="rounded-md bg-white px-4 py-3">
                <div class="flex w-full items-center justify-between" (click)="rename()">
                    <div class="font-semibold text-black" [translate]="'DEVICE_NAME'" i18n="@@DEVICE_NAME">
                        Device name
                    </div>
                    <div class="flex items-center justify-end space-x-2">
                        <div class="text-sm text-neutral-900">{{ omni.smartDevice.name }}</div>
                        @if (isAdmin) {
                            <app-icon icon="keyboard_arrow_right"></app-icon>
                        }
                    </div>
                </div>
            </div>

            <app-primary-button
                class="mt-8 block"
                size="normal"
                addedClasses="w-full"
                (click)="invitePlayer()"
                [translate]="'INVITE_PLAYER'"
                i18n="@@INVITE_PLAYER"
                *ngIf="isAdmin">
                Invite player
            </app-primary-button>

            <app-primary-button
                class="mt-2"
                size="small"
                color="white"
                addedClasses="w-full"
                fill="clear"
                [underline]="true"
                (click)="removeDevice()"
                [translate]="'REMOVE_DEVICE'"
                i18n="@@REMOVE_DEVICE"
                *ngIf="isAdmin || !networkOnlyAccess">
                Remove device
            </app-primary-button>

            @if (omni.smartDevice?.version) {
                <div class="mt-2 text-center text-sm text-neutral-600">
                    <span>v{{ omni.smartDevice.version }}</span>
                </div>
            }
        </div>
    }

    <!-- Bottom bar -->
    <div
        class="flex flex-none items-center bottom-0 z-20 w-full fixed justify-evenly bg-white px-1 font-open-sans pb-safe">
        <div
            class="flex w-16 flex-col items-center justify-center space-y-0.5 py-1.5 text-neutral-200"
            [class.border-t-2]="view === 'omni'"
            [class.border-orange]="view === 'omni'"
            [class.text-orange]="view === 'omni'"
            (click)="view = 'omni'">
            <div class="h-6 w-6">
                <app-icon icon="dartboard" class="text-black" style="padding-left: 0.35rem"></app-icon>
            </div>
            <div
                class="text-zinc-400 text-center text-xs"
                [class.font-semibold]="view !== 'omni'"
                [class.font-bold]="view === 'omni'"
                [translate]="'OMNI'"
                i18n="@@OMNI">
                Omni
            </div>
        </div>
        <!-- @if (!environment.production) { -->
        <div
            class="flex w-16 flex-col items-center justify-center space-y-0.5 py-1.5 text-neutral-200"
            [class.border-t-2]="view === 'debug'"
            [class.border-orange]="view === 'debug'"
            [class.text-orange]="view === 'debug'"
            (click)="view = 'debug'">
            <div class="h-6 w-6">
                <app-icon icon="help_center"></app-icon>
            </div>
            <div
                class="text-zinc-400 text-center text-xs"
                [class.font-semibold]="view !== 'debug'"
                [class.font-bold]="view === 'debug'">
                Debug
            </div>
        </div>
        <!-- } -->
        <div
            class="flex w-16 flex-col items-center justify-center space-y-0.5 py-1.5 text-neutral-200"
            [class.border-t-2]="view === 'more'"
            [class.border-orange]="view === 'more'"
            [class.text-orange]="view === 'more'"
            (click)="view = 'more'">
            <div class="flex items-center justify-center">
                <app-icon icon="more_horiz"></app-icon>
            </div>
            <div
                class="text-zinc-400 text-center text-xs"
                [class.font-semibold]="view !== 'more'"
                [class.font-bold]="view === 'more'"
                [translate]="'MORE'"
                i18n="@@MORE">
                More
            </div>
        </div>
    </div>
</div>
