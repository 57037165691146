import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { OnlineGameplay } from '@dc-core/dc-backend/dc-interfaces';
import { DCFireStoreUser } from '@dc-core/dc-firestore/globals/firestore.tables';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { ConnectionStatus, OmniCommunicationService } from '@dc-core/dc-services/omni/omni-communication.service';
import { ModalController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { SmartDeviceStatusColor } from 'src/app/smart-device-item/smart-device-item.component';

export type OmniActivationDialogPayload = {
    onlineGameplay: OnlineGameplay;
    user: DCFireStoreUser;
    isOwnCamera: boolean;
};

@Component({
    selector: 'app-omni-activation-dialog',
    templateUrl: 'omni-activation.dialog.html',
})
export class OmniActivationDialogComponent implements OnInit, OnDestroy {
    @Input() startGameAfterConnection: boolean = true;

    currentStatus: ConnectionStatus = ConnectionStatus.DISCONNECTED;
    private _connectionSub: Subscription;
    private _backButtonSubscription: Subscription;
    private _loading = false;

    constructor(
        public omni: OmniCommunicationService,
        private _alertService: DartCounterAlertService,
        public view: ModalController,
        private _platform: Platform
    ) {}

    ngOnInit(): void {
        this._backButtonSubscription = this._platform.backButton.subscribeWithPriority(9999, () => {
            this.close();
        });

        this._connectionSub = this.omni.connectionStatus$.subscribe((status) => {
            if (status === ConnectionStatus.CONNECTED) {
                this._alertService.clearAlerts();

                this._connectionSub.unsubscribe();
                this.close(status);
            } else if (status === ConnectionStatus.DISCONNECTED) {
                this._alertService.clearAlerts();
                this._connectionSub.unsubscribe();
                this.close(status);
            }
        });
    }

    getStatusText(): string {
        switch (this.currentStatus) {
            case ConnectionStatus.CONNECTED:
                return 'Connected';
            case ConnectionStatus.CONNECTING:
                return 'Connecting';
            case ConnectionStatus.DISCONNECTED:
                return 'Not Connected';
            default:
                return '';
        }
    }

    getStatusClass(): SmartDeviceStatusColor {
        switch (this.currentStatus) {
            case ConnectionStatus.CONNECTED:
                return 'green';
            case ConnectionStatus.CONNECTING:
                return 'yellow';
            case ConnectionStatus.DISCONNECTED:
                return 'red';
            default:
                return 'red';
        }
    }

    smartDeviceChanged(event) {
        if (!this._loading) {
            this._loading = true;
            this.omni.startGameAfterConnection = this.startGameAfterConnection;
            this.omni.loadAndConnect(event);
        }
    }

    async ngOnDestroy(): Promise<void> {
        if (this._backButtonSubscription) {
            this._backButtonSubscription.unsubscribe();
        }
        if (this._connectionSub) {
            this._connectionSub.unsubscribe();
        }
        this._loading = false;
        this._alertService.clearAlerts();
    }

    public close(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }
}
