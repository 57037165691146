<!-- Translation containers-->
@if (0) {
    <ng-container i18n="@@DATE">Date</ng-container>
    <ng-container i18n="@@TUT_CUSTOMDATE"
        >Edit the date-range to show statistics from an previous period.
    </ng-container>
    <ng-container i18n="@@STATISTICS">Statistics</ng-container>
    <ng-container i18n="@@TUT_TOP25"
        >Click to show a top 25 of your best and worst statistics. The date-filter will apply!</ng-container
    >
    <ng-container i18n="@@STATS_PER_GAME">Statistics per game</ng-container>
    <ng-container i18n="@@TUT_DETAILSTATS">Click on a gameplay to show detailed results!</ng-container>
    <ng-container i18n="@@SETTING">Setting</ng-container>
    <ng-container i18n="@@GAME_TYPE">Game type</ng-container>
    <ng-container i18n="@@PERIOD">Period</ng-container>
    <ng-container 18n="@@STARTSCORE">Startscore</ng-container>
}
<!-- End of translation containers-->
<app-page-header mainNavigation="menu">
    <div title [translate]="'PAGE_STATISTICS'" i18n="@@PAGE_STATISTICS">Statistics</div>
</app-page-header>
<div
    id="content"
    class="bg-white-pattern"
    style="position: relative; display: flex; flex-direction: column; height: 100%; overflow: hidden">
    <div class="flex flex-col flex-1 overflow-auto overscroll-none" appWebContainer>
        <div style="overflow: auto; flex: 1 1 0%">
            @if (activeGameType) {
                <app-select
                    class="m-4 block"
                    [initialValue]="activeGameType.value"
                    [options]="gameTypes"
                    (selectionChange)="setGameType($event)"
                    size="full"></app-select>

                @if (activeGameType.gameMode === 'match') {
                    <app-match-table (dateChangeEvent)="loadLastGame()"></app-match-table>
                }
                @if (activeGameType.gameMode === 'cricket_tactics') {
                    <app-cricket-tactics-table (dateChangeEvent)="loadLastGame()"></app-cricket-tactics-table>
                }
                @if (activeGameType.gameMode === 'around_the_clock') {
                    <app-around-the-clock-table (dateChangeEvent)="loadLastGame()"></app-around-the-clock-table>
                }
                @if (activeGameType.gameMode === 'bobs_27') {
                    <app-bobs-27-table (dateChangeEvent)="loadLastGame()"></app-bobs-27-table>
                }
                @if (activeGameType.gameMode === 'shanghai') {
                    <app-shanghai-table (dateChangeEvent)="loadLastGame()"></app-shanghai-table>
                }
                @if (activeGameType.gameMode === 'singles_training') {
                    <app-singles-training-table (dateChangeEvent)="loadLastGame()"></app-singles-training-table>
                }
                @if (activeGameType.gameMode === 'doubles_training') {
                    <app-doubles-training-table (dateChangeEvent)="loadLastGame()"></app-doubles-training-table>
                }
                @if (activeGameType.gameMode === 'score_training') {
                    <app-score-training-table (dateChangeEvent)="loadLastGame()"></app-score-training-table>
                }
            }

            @if (!lastGameLoaded) {
                <ion-row style="margin-top: 20px">
                    <ion-col class="text-center">
                        <ion-spinner style="height: 40px; width: 40px" color="secondary" name="crescent"></ion-spinner>
                    </ion-col>
                </ion-row>
            }

            @if (lastGameLoaded) {
                <div class="m-4">
                    @if (lastGame) {
                        <div
                            class="my-3 mt-4 text-center font-bebas-neue text-3xl text-black"
                            [translate]="'LAST_GAME'"
                            i18n="@@LAST_GAME">
                            Last game
                        </div>
                    }
                    @if (!lastGame && activeGameType.unverifiedGames === 0) {
                        <app-primary-button
                            size="normal"
                            addedClasses="w-full mt-3"
                            color="orange"
                            (click)="playFirstGame()"
                            [translate]="'PLAY_FIRST_GAME'"
                            i18n="@@PLAY_FIRST_GAME">
                            Play your first game
                        </app-primary-button>
                    }

                    @if (activeGameType.gameMode === 'match' && lastGame) {
                        <app-match-card [game]="lastGame" (click)="goToLastGameDetail()"></app-match-card>
                    }
                    @if (activeGameType.gameMode === 'cricket_tactics' && lastGame) {
                        <app-cricket-tactics-card
                            [game]="lastGame"
                            (click)="goToLastGameDetail()"></app-cricket-tactics-card>
                    }
                    @if (activeGameType.gameMode === 'around_the_clock' && lastGame) {
                        <app-around-the-clock-card
                            [game]="lastGame"
                            (click)="goToLastGameDetail()"></app-around-the-clock-card>
                    }
                    @if (activeGameType.gameMode === 'bobs_27' && lastGame) {
                        <app-bobs-27-card [game]="lastGame" (click)="goToLastGameDetail()"></app-bobs-27-card>
                    }
                    @if (activeGameType.gameMode === 'shanghai' && lastGame) {
                        <app-shanghai-card [game]="lastGame" (click)="goToLastGameDetail()"></app-shanghai-card>
                    }
                    @if (activeGameType.gameMode === 'singles_training' && lastGame) {
                        <app-singles-training-card
                            [game]="lastGame"
                            (click)="goToLastGameDetail()"></app-singles-training-card>
                    }
                    @if (activeGameType.gameMode === 'doubles_training' && lastGame) {
                        <app-doubles-training-card
                            [game]="lastGame"
                            (click)="goToLastGameDetail()"></app-doubles-training-card>
                    }
                    @if (activeGameType.gameMode === 'score_training' && lastGame) {
                        <app-score-training-card
                            [game]="lastGame"
                            (click)="goToLastGameDetail()"></app-score-training-card>
                    }
                    @if (auth.user.is_ultimate && (lastGame || activeGameType.unverifiedGames > 0)) {
                        <app-primary-button
                            size="normal"
                            addedClasses="w-full mt-3"
                            color="orange"
                            (click)="showGameType(activeGameType)">
                            <div class="flex items-center space-x-2">
                                <div [translate]="'VIEW_ALL_GAMES'" i18n="@@VIEW_ALL_GAMES">View all games</div>
                                @if (activeGameType.unverifiedGames > 0) {
                                    <ion-badge mode="md" class="ion-bg-yellow ion-text-black">
                                        {{ activeGameType.unverifiedGames }}
                                    </ion-badge>
                                }
                            </div>
                        </app-primary-button>
                    } @else if (!auth.user.is_ultimate) {
                        @if (activeGameType.unverifiedGames > 0) {
                            <app-primary-button
                                size="normal"
                                addedClasses="w-full mt-3"
                                color="orange"
                                (click)="showGameType(activeGameType)">
                                <div class="flex items-center space-x-2">
                                    <div [translate]="'VIEW_ALL_GAMES'" i18n="@@VIEW_ALL_GAMES">View all games</div>
                                    @if (activeGameType.unverifiedGames > 0) {
                                        <ion-badge mode="md" class="ion-bg-yellow ion-text-black">
                                            {{ activeGameType.unverifiedGames }}
                                        </ion-badge>
                                    }
                                </div>
                            </app-primary-button>
                        } @else if (lastGame) {
                            <app-primary-button
                                size="normal"
                                addedClasses="w-full mt-3"
                                color="blue"
                                (click)="OpenUltimateDialog()"
                                [translate]="'WANT_TO_VIEW_ALL_GAMES'"
                                i18n="@@WANT_TO_VIEW_ALL_GAMES">
                                Want to view all games? Become Ultimate!
                            </app-primary-button>
                        }
                    }
                </div>
            }
        </div>
    </div>

    <app-bottom-nav class="w-full" active="statistics"></app-bottom-nav>
</div>
