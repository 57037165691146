export enum LocalStorageKey {
    user = 'user',
    userData = 'userData',
    accessToken = 'accessToken',
    onlineSavedGameplay = 'onlineSavedGameplay',
    camZoomLevel = 'camZoomLevel',
    preferences = 'preferences',
    viewedCTAModules = 'viewedCTAModules',
    language = 'language',
    linkedOneSignalData = 'linkedOneSignalData',
    recentFriendSearches = 'recentFriendSearches',
    statisticFilters = 'statisticFilters',
    selectedGameLabels = 'selectedGameLabels',
    speechToScoreOptions = 'speechToScoreOptions',
    lastRecapYear = 'lastRecapYear',
    selectedUserMediaDevices = 'selectedUserMediaDevices',
    newOnlineFeatureDialog = 'newOnlineFeatureDialog',
    firstTime = 'firstTime',
    firstTimeDashboard = 'firstTimeDashboard',
    firstTimeDoubles = 'firstTimeDoubles',
    firstTimeMatch = 'firstTimeMatch',
    firstTimeOnlineMatch = 'firstTimeOnlineMatch',
    firstTimeProfileDialog = 'firstTimeProfileDialog',
    firstTimeStats = 'firstTimeStats',
    globalTutorial = 'globalTutorial',
    deviceId = 'deviceId',
    appRating = 'appRating',
    theme = 'theme',
}

export enum JoyrideStorageKey {
    firstTimeMatch = 'firstTimeMatch',
}

export enum OmniLocalStorageKeys {
    omniDebugSettings = 'omniDebugSettings',
    omniSettings = 'omniSettings',
}
