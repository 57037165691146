import { Injectable } from '@angular/core';
import { CapacitorSmartDeviceClient } from 'capacitor-smart-device-client';
import { Observable, Subject, Subscription } from 'rxjs';

export interface UDPSmartDeviceInfo {
    type: string;
    guid: string;
    mac: string;
}

@Injectable()
export class UDPService {
    public globalUdpSubscription: Subscription = null;

    private udpStream: Subject<any> = new Subject<any>();
    private udpListener;

    public watchUDPSocket(port: number): Observable<any> {
        if (this.globalUdpSubscription) {
            this.globalUdpSubscription.unsubscribe();
            this.globalUdpSubscription = null;
            this.stopUDPSocket();
        }

        if (this.udpListener) {
            console.error('Already listening to UDP port');
            return;
        }

        this.startListener();

        CapacitorSmartDeviceClient.startUDPSocket({ host: '0.0.0.0', port });

        return this.udpStream.asObservable();
    }

    async startListener(): Promise<void> {
        try {
            this.udpListener = await CapacitorSmartDeviceClient.addListener('udpMessageReceived', (info) => {
                this.udpStream.next(info);
            });
        } catch (_) {}
    }

    stopUDPSocket() {
        // close the socket
        try {
            CapacitorSmartDeviceClient.stopUDPSocket();
        } catch (_) {}

        if (this.udpListener) {
            this.udpListener.remove();
            this.udpListener = null;
        }

        // close the stream
        this.udpStream.complete();
        this.udpStream = new Subject<any>();
    }
}
