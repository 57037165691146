import { MediaStreamRecorder, RecordRTCPromisesHandler } from 'recordrtc';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CameraStreamClipEvent } from '../../dc-backend/dc-classes';
import {
    CAMERA_TYPE,
    DCStreamTrack,
    FacingModes,
    JanusRoom,
    StreamDisplayMetaData,
    StreamDisplayMetaDataKind,
} from '../camera/camera.models';
import { MediaRecorderMode } from '../camera/ingame-camera.service';
import { JanusVideoCodec } from './janus-video-room.service';

export interface DartCounterVideoStream {
    facingMode: FacingModes;
    stream?: MediaStream;
    feedId: number;
    mid: string;
    videoCodec?: JanusVideoCodec;
    scale?: string;
    isActive?: boolean;
}

export interface DartCounterVideoStreamRecorder {
    recorder?: VideoStreamRecorder;
    hasRecorder: boolean;
}

export interface VideoStreamRecorder {
    id: string;
    camType: CAMERA_TYPE;
    mediaRecorder?: RecordRTCPromisesHandler;
    mode: MediaRecorderMode;
    recording?: Blob;
    duration: number;
    isCountingDuration: boolean;
}

export class UserMedia {
    janusRoom: JanusRoom = { roomID: null, janusServerHost: null, camType: null };
    joinedRoomId?: number;
    audioTrack: DCStreamTrack | null = null;
    audioStream: MediaStream | null = null;
    audioMuted = false;
    videoStreams: {
        board: DartCounterVideoStream;
        player: DartCounterVideoStream;
        hasStreams: boolean;
        activeStreams: boolean;
        dualStreams: boolean;
        kind: StreamDisplayMetaDataKind;
        noticedPlayerCam: boolean;
    } = {
        board: null,
        player: null,
        hasStreams: false,
        activeStreams: false,
        dualStreams: false,
        kind: 'app',
        noticedPlayerCam: false,
    };
    recorders: {
        board: DartCounterVideoStreamRecorder;
        player: DartCounterVideoStreamRecorder;
    } = {
        board: null,
        player: null,
    };
    remoteEvents$: Subject<any> = null;
    hasAudio$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    clipTitle = '';
    autoStartRecording = true;
    clipEvents: CameraStreamClipEvent[] = [];

    constructor() {}

    addVideoTrack(
        track: DCStreamTrack,
        id: string,
        displayMetaData: StreamDisplayMetaData,
        videoCodec?: JanusVideoCodec
    ): DartCounterVideoStream {
        if (track.kind === 'video') {
            track.onmute = () => {};

            let videoStream: DartCounterVideoStream = null;
            let recorder: DartCounterVideoStreamRecorder = null;
            if (displayMetaData.facingMode === FacingModes.BOARD) {
                videoStream = this.videoStreams.board;
                recorder = this.recorders.board;
            } else if (displayMetaData.facingMode === FacingModes.PLAYER) {
                videoStream = this.videoStreams.player;
                recorder = this.recorders.player;
            }

            if (videoStream) {
                if (videoStream.stream) {
                    videoStream.stream.addTrack(track);
                } else {
                    videoStream.stream = new MediaStream([track]);
                    // if (this.audioTrack && displayMetaData.facingMode === FacingModes.BOARD) {
                    //     videoStream.stream.addTrack(this.audioTrack);
                    // }
                }
                videoStream.isActive = true;
            } else {
                const stream = new MediaStream([track]);
                // if (this.audioTrack && displayMetaData.facingMode === FacingModes.BOARD) {
                //     stream.addTrack(this.audioTrack);
                // }
                videoStream = {
                    facingMode: displayMetaData.facingMode,
                    scale: displayMetaData.scale,
                    isActive: true,
                    stream,
                    feedId: track.feedId,
                    mid: id,
                    videoCodec,
                };
                recorder = {
                    hasRecorder: false,
                    recorder: null,
                };

                if (displayMetaData.facingMode === FacingModes.BOARD) {
                    this.videoStreams.board = videoStream;
                    this.recorders.board = recorder;
                } else if (displayMetaData.facingMode === FacingModes.PLAYER) {
                    this.videoStreams.player = videoStream;
                    this.recorders.player = recorder;
                }
            }

            this.videoStreams.kind = displayMetaData.kind;

            this.setHasStreams();

            return videoStream;
        }

        return null;
    }

    addAudioTrack(track: DCStreamTrack, displayMetaData: StreamDisplayMetaData) {
        if (track.kind === 'audio' && displayMetaData.kind === 'app') {
            this.audioTrack = track as DCStreamTrack;
            this.audioTrack.enabled = !this.audioMuted;
            this.audioStream = new MediaStream([this.audioTrack]);
            this.hasAudio$.next(true);

            // if (this.videoStreams.board?.stream) {
            //     this.videoStreams.board?.stream.addTrack(this.audioTrack);
            // }
        }
    }

    removeTrack(track: DCStreamTrack, displayMetaData: StreamDisplayMetaData): void {
        if (track.kind === 'video') {
            let videoStream: DartCounterVideoStream = null;
            if (displayMetaData.facingMode === FacingModes.BOARD) {
                videoStream = this.videoStreams.board;
            } else if (displayMetaData.facingMode === FacingModes.PLAYER) {
                videoStream = this.videoStreams.player;
            }

            if (videoStream) {
                videoStream.stream.removeTrack(track);
                if (videoStream.stream.getTracks().length === 0) {
                    videoStream.isActive = false;
                }
            }
        } else if (track.kind === 'audio') {
            if (this.audioStream) {
                this.audioStream.removeTrack(track);
                if (this.audioStream.getAudioTracks().length > 0) {
                    this.audioTrack = this.audioStream.getAudioTracks()[0] as DCStreamTrack;
                } else {
                    this.audioTrack = null;
                    this.hasAudio$.next(false);
                }
            }
        }
    }

    trackExists(track: DCStreamTrack, facingMode: FacingModes): boolean {
        if (track.kind === 'audio') {
            // If the current audio track has the same feed_id, stop and remove it
            return this.audioTrack?.feedId === track.feedId;
        } else if (track.kind === 'video') {
            if (
                facingMode === FacingModes.BOARD &&
                this.videoStreams.board?.stream
                    ?.getTracks()
                    .some((existingTrack: DCStreamTrack) => existingTrack.feedId === track.feedId)
            ) {
                return true;
            }

            if (
                facingMode === FacingModes.PLAYER &&
                this.videoStreams.player?.stream
                    ?.getTracks()
                    .some((existingTrack: DCStreamTrack) => existingTrack.feedId === track.feedId)
            ) {
                return true;
            }
        }
        return false;
    }

    setHasStreams(): void {
        if (this.videoStreams.board && this.videoStreams.player) {
            this.videoStreams.hasStreams = true;
            this.videoStreams.dualStreams = true;
        } else if (this.videoStreams.board || this.videoStreams.player) {
            this.videoStreams.hasStreams = true;
            this.videoStreams.dualStreams = false;
        } else {
            this.videoStreams.hasStreams = false;
            this.videoStreams.dualStreams = false;
        }
    }

    muteAudio() {
        if (this.audioTrack) {
            this.audioMuted = true;
            this.audioTrack.enabled = !this.audioMuted;
        }
    }

    unmuteAudio() {
        if (this.audioTrack) {
            this.audioMuted = false;
            this.audioTrack.enabled = !this.audioMuted;
        }
    }

    toggleMuteAudio(): void {
        if (this.audioTrack) {
            this.audioMuted = !this.audioMuted;
            this.audioTrack.enabled = !this.audioMuted;
        }
    }

    setupMediaRecorder(mode: MediaRecorderMode, camType: CAMERA_TYPE): void {
        this.startMediaRecorder(FacingModes.BOARD, mode, camType);
        if (camType === CAMERA_TYPE.SMART_DEVICE) {
            this.startMediaRecorder(FacingModes.PLAYER, mode, camType);
        }
    }

    startMediaRecorder(facingMode: FacingModes, mode: MediaRecorderMode, camType: CAMERA_TYPE, tries = 1): void {
        let videoStream: DartCounterVideoStream = null;
        let recorder: DartCounterVideoStreamRecorder = null;
        switch (facingMode) {
            case FacingModes.BOARD:
                if (this.videoStreams.board && this.videoStreams.board.stream && this.recorders.board) {
                    videoStream = this.videoStreams.board;
                    recorder = this.recorders.board;
                }
                break;
            case FacingModes.PLAYER:
                if (this.videoStreams.player && this.videoStreams.player.stream && this.recorders.player) {
                    videoStream = this.videoStreams.player;
                    recorder = this.recorders.player;
                }
                break;
        }

        if (videoStream) {
            this.initRecorder(videoStream, recorder, facingMode, mode, camType);
        }
    }

    initRecorder(
        videoStream: DartCounterVideoStream,
        recorder: DartCounterVideoStreamRecorder,
        facingMode: FacingModes,
        mode: MediaRecorderMode,
        camType: CAMERA_TYPE
    ): void {
        if (recorder.hasRecorder || !videoStream.stream.getTracks().length) {
            return;
        }

        recorder.hasRecorder = true;

        const mediaRecorder = new RecordRTCPromisesHandler(videoStream.stream, {
            type: 'video',
            recorderType: MediaStreamRecorder,
            mimeType: 'video/webm;codecs=vp8',
            disableLogs: !environment.debug,
            checkForInactiveTracks: false,
            videoBitsPerSecond: 500 * 1000,
            audioBitsPerSecond: 0,
        });

        let mediaStreamRecorder: VideoStreamRecorder = {
            id: Math.random().toString(36).substring(2, 8),
            camType,
            mediaRecorder,
            mode,
            duration: 0,
            isCountingDuration: false,
        };
        recorder.recorder = mediaStreamRecorder;

        if (this.autoStartRecording) {
            try {
                mediaRecorder.startRecording();

                if (mode === 'clip') {
                    this.startDurationTimer();
                }
            } catch (err) {
                console.error(err);
                this.resetMediaRecorder(facingMode);
            }
        }
    }

    startDurationTimer(): void {
        if (this.recorders.board?.recorder) {
            this.recorders.board.recorder.duration = 0;
            this.recorders.board.recorder.isCountingDuration = true;
            this.addDuration(this.recorders.board.recorder);
        }

        if (this.recorders.player?.recorder) {
            this.recorders.player.recorder.duration = 0;
            this.recorders.player.recorder.isCountingDuration = true;
            this.addDuration(this.recorders.player.recorder);
        }
    }

    addDuration(recorder: VideoStreamRecorder): void {
        if (recorder.isCountingDuration) {
            recorder.duration++;
            setTimeout(() => {
                this.addDuration(recorder);
            }, 1000);
        }
    }

    stopDurationTimer(clear = false): void {
        if (this.recorders.board?.recorder) {
            this.recorders.board.recorder.isCountingDuration = false;
            if (clear) {
                this.recorders.board.recorder.duration = 0;
            }
        }

        if (this.recorders.player?.recorder) {
            this.recorders.player.recorder.isCountingDuration = false;
            if (clear) {
                this.recorders.player.recorder.duration = 0;
            }
        }
    }

    startRemoteEvents(): void {
        this.remoteEvents$ = new Subject();
    }

    stopRemoteEvents(): void {
        this.remoteEvents$ = new Subject();
    }

    cleanupUserMedia(
        stopTracks: boolean | { video: boolean; audio: boolean },
        resetStreams: boolean | { video: boolean; audio: boolean },
        resetMediaRecorder = false
    ) {
        if (resetMediaRecorder) {
            this.resetMediaRecorder();
        }

        if (typeof stopTracks === 'object') {
            this.stopTracks(stopTracks.video, stopTracks.audio);
        } else if (stopTracks === true) {
            this.stopTracks(true, true);
        }

        if (typeof resetStreams === 'object') {
            this.resetStreams(resetStreams.video, resetStreams.audio);
        } else if (resetStreams === true) {
            this.resetStreams(true, true);
        }
    }

    stopTracks(video: boolean, audio: boolean): void {
        if (video) {
            if (this.videoStreams.board) {
                this.videoStreams.board.stream?.getTracks().forEach((track) => track.stop());
            }
            if (this.videoStreams.player) {
                this.videoStreams.player.stream?.getTracks().forEach((track) => track.stop());
            }
        }

        if (audio) {
            this.audioStream?.getTracks().forEach((track) => track.stop());
        }
    }

    resetStreams(video: boolean, audio: boolean): void {
        if (video) {
            this.videoStreams = {
                board: null,
                player: null,
                hasStreams: false,
                activeStreams: false,
                dualStreams: false,
                kind: 'app',
                noticedPlayerCam: false,
            };
        }

        if (audio) {
            this.audioStream = null;
            this.audioTrack = null;
            this.hasAudio$.next(false);
        }
    }

    resetMediaRecorder(facingMode: FacingModes = null): void {
        if (this.recorders.board?.recorder?.mediaRecorder && (!facingMode || facingMode === FacingModes.BOARD)) {
            // .finally() does not work, because RecordRTC uses an older version of Promise
            this.recorders.board.recorder.mediaRecorder
                .destroy()
                .then(() => {
                    this.recorders.board.recorder = null;
                    this.recorders.board.hasRecorder = false;
                })
                .catch(() => {
                    this.recorders.board.recorder = null;
                    this.recorders.board.hasRecorder = false;
                });
        }

        if (this.recorders.player?.recorder?.mediaRecorder && (!facingMode || facingMode === FacingModes.PLAYER)) {
            // .finally() does not work, because RecordRTC uses an older version of Promise
            this.recorders.player.recorder.mediaRecorder
                .destroy()
                .then(() => {
                    this.recorders.player.recorder = null;
                    this.recorders.player.hasRecorder = false;
                })
                .catch(() => {
                    this.recorders.player.recorder = null;
                    this.recorders.player.hasRecorder = false;
                });
        }
    }
}
