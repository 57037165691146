/* eslint-disable max-len */
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, HostListener, inject, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';

import { IconComponent } from '../shared/components/icon/icon.component';

@Component({
    selector: 'app-fullscreen-icon',
    templateUrl: 'fullscreen-icon.component.html',
    standalone: true,
    imports: [IconComponent],
})
export class FullscreenIconComponent implements OnInit, AfterViewInit {
    public iconName: 'fullscreen' | 'fullscreen_exit' = 'fullscreen';
    public isWeb = environment.isWeb;
    public isMobile: boolean;

    private deviceDetectorService: DeviceDetectorService = inject(DeviceDetectorService);
    private document: Document = inject(DOCUMENT);

    constructor() {}

    public ngOnInit(): void {
        this.isMobile = this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet();
    }

    public ngAfterViewInit(): void {
        if (environment.isWeb) {
            this.setFullScreenIcon();
        }
    }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.key === 'F11') {
            // Prevent the default action (optional)
            event.preventDefault();
            // Toggle fullscreen state manually
            this.toggleFullScreen();
        }
    }

    @HostListener('document:fullscreenchange', [])
    setFullScreenIcon() {
        this.iconName = this.document.fullscreenElement
            ? (this.iconName = 'fullscreen_exit')
            : (this.iconName = 'fullscreen');
    }

    public toggleFullScreen() {
        if (!this.document.fullscreenElement) {
            this.openFullscreen();
        } else {
            this.closeFullscreen();
        }
    }

    private openFullscreen() {
        const elem = this.document.documentElement as HTMLElement & {
            mozRequestFullScreen(): Promise<void>;
            webkitRequestFullscreen(): Promise<void>;
            msRequestFullscreen(): Promise<void>;
        };

        if (elem) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                /* Firefox */
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
                /* Chrome, Safari & Opera */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {
                /* IE/Edge */
                elem.msRequestFullscreen();
            }
        }
    }

    private closeFullscreen() {
        const documentWithExit = this.document as Document & {
            mozCancelFullScreen(): Promise<void>;
            webkitExitFullscreen(): Promise<void>;
            msExitFullscreen(): Promise<void>;
        };

        if (documentWithExit.exitFullscreen) {
            documentWithExit.exitFullscreen();
        } else if (documentWithExit.mozCancelFullScreen) {
            /* Firefox */
            documentWithExit.mozCancelFullScreen();
        } else if (documentWithExit.webkitExitFullscreen) {
            /* Chrome, Safari and Opera */
            documentWithExit.webkitExitFullscreen();
        } else if (documentWithExit.msExitFullscreen) {
            /* IE/Edge */
            documentWithExit.msExitFullscreen();
        }
    }
}
