import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HelpModalComponent } from '@components/help-modal/help-modal.component';
import { GameApiService } from '@dc-api/game.api.service';
import { UserApiService } from '@dc-api/user.api.service';
import { LOCAL_GAME_UNFINISHED_KEYS } from '@dc-core/dc-gamelogic/game-storage';
import { GameLabelService } from '@dc-core/dc-services/game-label.service';
import { MenuController, ModalController, ModalOptions, NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { DCLoadingService } from '@providers/DCLoadingService';
import { Match, Sound } from 'dc-core/dc-backend/dc-classes';
import { SoundPath } from 'dc-core/dc-backend/dc-enums';
import { AddPlayerPayload, EditPlayerPayload } from 'dc-core/dc-gamelogic/game-interfaces';
import { DartbotLevel } from 'dc-core/dc-gamelogic/settings/local/dartbot/dartbot.config';
import { DartCounterMatchSettings } from 'dc-core/dc-gamelogic/settings/local/match/match-settings.default';
import { PlayerType, StartWith } from 'dc-core/dc-gamelogic/settings/settings.globals';
import { DartCounterAlertService } from 'dc-core/dc-services/alert.service';
import { DartCounterAudioService } from 'dc-core/dc-services/audio/audio.service';
import { DartCounterPreferenceService } from 'dc-core/dc-services/preference/preference.service';
import _ from 'lodash';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';
import {
    AddPlayerDialogResponse,
    GamesLocalAddPlayerDialogComponent,
} from 'src/app/modules/games/components/games-local-add-player-dialog/games-local-add-player-dialog.component';
import {
    AssignGameLabelsDialogComponent,
    AssignGameLabelsDialogPayload,
} from 'src/dialogs/assign-game-labels/assign-game-labels.dialog';
import { CustomScoreDialogComponent } from 'src/dialogs/customscore/customscore.dialog';
import { GoalAmountDialogComponent } from 'src/dialogs/goalamount/goalamount.dialog';
import { OwnAccountWarningDialogComponent } from 'src/dialogs/own-account-warning/own-account-warning';
import { PlayerOptionsModalComponent } from 'src/dialogs/playerOptions.modal/playerOptions.modal';
import { SuddenDeathDialogComponent } from 'src/dialogs/suddendeath/suddendeath.dialog';
import {
    UnfinishedGamesDialogResult,
    UnfinishedGamesPayload,
    UnfinishedLocalDialogComponent,
} from 'src/dialogs/unfinishedLocal/unfinishedLocal.dialog';
import { VSDartbotDialogComponent } from 'src/dialogs/vsdartbot/vsdartbot.dialog';
import { environment } from 'src/environments/environment';
import { HeadToHeadDialogOneComponent } from 'src/pages/online/headtohead1.dialog/headtohead1.dialog';
import { AuthService } from 'src/services/auth.service';

import { LoginOrCreateDialogComponent } from '../../../dialogs/login-or-create/login-or-create';

@Component({
    selector: 'app-match-new-game',
    templateUrl: 'match.new-game.component.html',
})
export class MatchNewGameComponent extends DartCounterMatchSettings implements OnInit {
    public isWeb = environment.isWeb;

    public disableReorder = false;
    public unfinisheds: any[] = [];
    private backButtonSubscription: Subscription;

    constructor(
        public nav: NavController,
        public auth: AuthService,
        public menu: MenuController,
        public platform: Platform,
        private loading: DCLoadingService,
        private _audioService: DartCounterAudioService,
        private _preferenceService: DartCounterPreferenceService,
        private _alertService: DartCounterAlertService,
        public modal: ModalController,
        private dragulaService: DragulaService,
        public route: ActivatedRoute,
        private _gameApiService: GameApiService,
        public gameLabelService: GameLabelService,
        private _userApiService: UserApiService,
        private ga: DartCounterAnalyticsService,
        private translate: TranslateService
    ) {
        super();
    }

    ngOnInit() {
        this.setUser(this.auth.user);
        this.unfinishedKey = LOCAL_GAME_UNFINISHED_KEYS.MATCH;

        this.menu.enable(true, 'mainmenu');
        this.menu.enable(false, 'ingamemenu');
    }

    ionViewWillEnter() {
        this.ga.trackView('Match | Setup');
    }

    ionViewDidEnter() {
        setTimeout(() => {
            this.unfinisheds = JSON.parse(localStorage.getItem(this.unfinishedKey)) || [];
        }, 200);
    }

    changePlayerType(playerType: PlayerType): void {
        if (this.playerType === 'players' && playerType === 'teams' && this.users.length === 1) {
            this._alertService.createAlert({
                title: $localize`:@@ADD_SECOND_PLAYER_FOR_TEAMS:Add a second player to play in teams`,
                icon: 'info',
            });
            return;
        }

        super.changePlayerType(playerType);
    }

    showPlayerOptions(event: EditPlayerPayload) {
        if (event.user.is_cpu) {
            this.vsDartbotChanged(true);
        } else {
            this.modal
                .create({
                    component: PlayerOptionsModalComponent,
                    componentProps: {
                        user: event.user.id ? event.user : _.cloneDeep(event.user),
                    },
                    cssClass: 'auto-height',
                })
                .then((elem) => {
                    elem.present();
                    elem.onDidDismiss().then((dialogRes) => {
                        if (dialogRes.data) {
                            this.updateUser(event.teamIndex, event.userIndex, dialogRes.data);
                        }
                    });
                });
        }
    }

    vsDartbotChanged(value: boolean): void {
        if (value) {
            this.modal
                .create({
                    component: VSDartbotDialogComponent,
                    backdropDismiss: false,
                    componentProps: {
                        cpuLevel: this.dartbotLevel.level,
                        gameMode: 'match',
                    },
                    cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                })
                .then((elem) => {
                    elem.present();
                    elem.onDidDismiss().then((dialogRes) => {
                        const result: DartbotLevel = dialogRes.data;
                        if (result) {
                            if (this._preferenceService.matchDartbot) {
                                this.vsDartbotChangedWithUser(true, this._preferenceService.matchDartbot, result);
                            } else {
                                this.loading.ShowDefaultLoader();

                                this._gameApiService
                                    .getDartbot({ game_mode: 'match' })
                                    .then((res) => {
                                        this._preferenceService.matchDartbot = res.data;
                                        this.vsDartbotChangedWithUser(
                                            true,
                                            this._preferenceService.matchDartbot,
                                            result
                                        );
                                    })
                                    .finally(() => this.loading.DismissLoader());
                            }
                        } else {
                            this.vsDartbotChangedWithUser(false, null, null);
                        }
                    });
                });
        } else {
            this.vsDartbotChangedWithUser(value, null, null);
        }
    }

    changeStartWith(startWith: StartWith): void {
        super.changeStartWith(startWith);
        if (startWith === 'custom') {
            this.modal
                .create({
                    component: CustomScoreDialogComponent,
                    backdropDismiss: true,
                    componentProps: {
                        customScore: this.customStartWith,
                    },
                    cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                })
                .then((elem) => {
                    elem.present();
                    elem.onDidDismiss().then((dialogRes) => {
                        if (dialogRes.data) {
                            this.customStartWith = dialogRes.data;
                            const errors = this.checkForErrors();
                            if (errors.length) {
                                this._alertService.createAlert({
                                    title: errors.join('\n'),
                                    icon: 'error',
                                    timer: 3000,
                                });
                            }
                        }
                    });
                });
        }
    }

    showGoalAmountDialog() {
        this.modal
            .create({
                component: GoalAmountDialogComponent,
                backdropDismiss: true,
                componentProps: {
                    goalAmount: JSON.parse(JSON.stringify(this.goalAmount)),
                },
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.goalAmount = dialogRes.data;
                        const errors = this.checkForErrors();
                        if (errors.length) {
                            this._alertService.createAlert({
                                title: errors.join('\n'),
                                icon: 'error',
                                timer: 3000,
                            });
                        }
                    }
                });
            });
    }

    ShowLogin() {
        this.modal.create({ component: LoginOrCreateDialogComponent }).then((elem) => elem.present());
    }

    public ToggleReorder() {
        this.disableReorder = !this.disableReorder;
    }

    toggleCheckoutPercentage() {
        this.auth.user.profile.has_checkout_rate = !this.auth.user.profile.has_checkout_rate;
        super.toggleCheckoutPercentage(this.auth.user.profile.has_checkout_rate);

        this._userApiService.updateUser({
            profile: {
                has_checkout_rate: this.auth.user.profile.has_checkout_rate,
            },
        });
    }

    toggleTwoLegsDifference() {
        super.toggleTwoLegsDifference();

        if (this.twoLegsDifference) {
            let dialogRef;
            this.modal
                .create({
                    component: SuddenDeathDialogComponent,
                    backdropDismiss: true,
                    componentProps: {
                        suddenDeath: JSON.parse(JSON.stringify(this.suddenDeath)),
                    },
                    cssClass: 'auto-height',
                })
                .then((elem) => {
                    elem.present();
                    dialogRef = elem;
                    dialogRef.onDidDismiss().then((dialogRes) => {
                        if (dialogRes.data) {
                            this.suddenDeath = dialogRes.data;
                        }
                    });
                });
        } else {
            this.suddenDeath = false;
        }
    }

    showUnfinished() {
        let dialogRef;
        this.modal
            .create({
                component: UnfinishedLocalDialogComponent,
                backdropDismiss: true,
                componentProps: <UnfinishedGamesPayload>{
                    gameMode: 'match',
                    storage_key: this.unfinishedKey,
                },
                cssClass: 'auto-height',
            })
            .then((elem) => {
                elem.present();
                dialogRef = elem;
                dialogRef.onDidDismiss().then((dialogres) => {
                    const result: UnfinishedGamesDialogResult = dialogres?.data;

                    if (result && result.game) {
                        this.goToGame(result.game);
                    } else {
                        //Refresh the count
                        this.unfinisheds = JSON.parse(localStorage.getItem(this.unfinishedKey)) || [];
                    }
                });
            });
    }

    addPlayer(event: AddPlayerPayload): void {
        if (event.user) {
            super.addUser(event.user, event.team);
        } else {
            this.modal
                .create({
                    component: GamesLocalAddPlayerDialogComponent,
                    backdropDismiss: false,
                    showBackdrop: true,
                    cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
                })
                .then((elem) => {
                    elem.present();
                    elem.onDidDismiss().then((dialogRes) => {
                        const addPlayer: AddPlayerDialogResponse = dialogRes.data;
                        if (addPlayer) {
                            this.addUser(addPlayer.user, event.team);
                        }
                    });
                });
        }
    }

    startGame(forceOwnUser = false): void {
        const errors = this.checkForErrors();
        if (errors.length) {
            this._alertService.createAlert({
                title: errors.join('\n'),
                icon: 'error',
                timer: 3000,
            });
        } else {
            if (!this.hasOwnUser() && !forceOwnUser) {
                this.modal
                    .create({
                        component: OwnAccountWarningDialogComponent,
                        componentProps: {
                            playerType: this.playerType,
                            users: JSON.parse(JSON.stringify(this.users)),
                            teams: JSON.parse(JSON.stringify(this.teams)),
                        },
                        cssClass: 'auto-height',
                        showBackdrop: true,
                    })
                    .then((elem) => {
                        elem.present();
                        elem.onDidDismiss().then((dialogRes) => {
                            if (dialogRes.data) {
                                if (this.playerType === 'players') {
                                    this.users = dialogRes.data.users;
                                } else {
                                    this.teams = dialogRes.data.teams;
                                }
                                this.startGame(true);
                            }
                        });
                    });
                return;
            }

            this.saveGameSettings();
            const match: Match = this.mapToMatchObject();

            const users = this.checkUsersForHeadToHead();
            if (users && this._preferenceService.showHeadToHead) {
                this.modal
                    .create({
                        component: HeadToHeadDialogOneComponent,
                        componentProps: {
                            users,
                            isOnline: false,
                        },
                        backdropDismiss: false,
                        showBackdrop: true,
                        cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
                    } as ModalOptions)
                    .then((elem) => {
                        this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(9999, () => {
                            //
                        });
                        elem.present();
                        elem.onDidDismiss().then((dialogRes) => {
                            this.backButtonSubscription.unsubscribe();
                            if (dialogRes.data) {
                                this.goToGame(match);
                            }
                        });
                    });
            } else {
                this.goToGame(match);
            }
        }
    }

    goToGame(match: Match): void {
        this._preferenceService.transition = true;
        setTimeout(() => {
            this._preferenceService.transition = false;
        }, 1500);

        setTimeout(() => {
            if (this._preferenceService.allowCaller) {
                let isUltimate: boolean;
                let sound: Sound = null;
                if (this.playerType === 'players') {
                    isUltimate = this.users[0].is_ultimate;
                    sound = this.users[0]?.profile?.sound;
                } else {
                    isUltimate = this.teams[0].users[0].is_ultimate;
                    sound = this.teams[0].users[0]?.profile?.sound;
                }

                if (isUltimate && sound) {
                    this._audioService.playAudioFromBackend(SoundPath.NAMES, sound.file);
                    this._audioService.addDeviceToAudioStack(SoundPath.TEXTS, 'tothrowfirst.mp3');
                    this._audioService.addDeviceToAudioStack(SoundPath.TEXTS, 'gameon.mp3');
                } else {
                    this._audioService.playAudioFromDevice(SoundPath.TEXTS, 'gameon.mp3');
                }
            }

            this.nav.navigateForward('/game/match', { state: { match, initialGame: match } });
        }, 1000);
    }

    assignGameLabels() {
        this.modal
            .create({
                component: AssignGameLabelsDialogComponent,
                componentProps: {
                    assignedGameLabels: this.gameLabelService.selectedLabels,
                } as AssignGameLabelsDialogPayload,
                cssClass: 'auto-height',
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();
                elem.onWillDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.gameLabelService.setSelectedLabels(dialogRes.data);
                    }
                });
            });
    }

    goBack(): void {
        this.nav.navigateBack('/local-games');
    }

    showHelpModal(title, contents) {
        this.modal
            .create({
                component: HelpModalComponent,
                componentProps: {
                    title: this.translate.instant(title),
                    contents: this.translate.instant(contents),
                },
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
            })
            .then((elem) => {
                elem.present();
            });
    }
}
