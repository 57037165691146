import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-opponent-not-responding-dialog',
    templateUrl: 'opponent-not-responding.dialog.html',
})
export class OpponentNotRespondingDialogComponent {
    constructor(public view: ModalController, public auth: AuthService) {}

    public DismissModal(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }
}
