<modal-content class="bg-white-pattern text-black">
    <app-page-header mainNavigation="custom" color="transparent">
        <div title>{{ title }}</div>
    </app-page-header>

    <ion-row style="color: black; justify-content: center">
        <ion-datetime
            displayFormat="DD MMM YYYY"
            presentation="date"
            name="date"
            [(ngModel)]="date"
            *ngIf="!maxDate"></ion-datetime>
        <ion-datetime
            displayFormat="DD MMM YYYY"
            presentation="date"
            name="date"
            [(ngModel)]="date"
            [max]="maxDate"
            (ngModelChange)="checkDate()"
            *ngIf="maxDate"></ion-datetime>
    </ion-row>
</modal-content>

<ion-footer class="ion-no-border">
    <ion-toolbar class="footer" style="height: 72px">
        <div class="bottomCTA" style="position: relative" (click)="dismiss()">
            <p class="text-center" style="height: 72px" [translate]="'CONFIRM'" i18n="@@CONFIRM">Confirm</p>
        </div>
    </ion-toolbar>
</ion-footer>
