import { Component } from '@angular/core';
import { UserApiService } from '@dc-api/user.api.service';
import { PreferenceLocalStorageKey } from '@dc-core/dc-services/preference/preference.models';
import { ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageKey } from 'dc-core/dc-localstorage';
import { DartCounterPreferenceService } from 'dc-core/dc-services/preference/preference.service';
import { take } from 'rxjs';
import { DC_LANGUAGES } from 'src/app/app.globals';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-preferences-dialog',
    templateUrl: 'preferences.html',
    styleUrls: ['preferences.scss'],
})
export class PreferencesDialogComponent {
    public firsttime;
    public lang = null;
    public languages = [];

    constructor(
        public view: ModalController,
        public auth: AuthService,
        public translate: TranslateService,
        public preferenceService: DartCounterPreferenceService,
        private _userApiService: UserApiService,
        private platform: Platform
    ) {
        this.languages = DC_LANGUAGES;

        if (this.translate.currentLang) {
            this.lang = this.translate.currentLang;
        } else {
            this.lang = 'en';
        }
    }

    public DismissModal(returnValue: any = null): void {
        this._userApiService.updateUser({
            locale: this.lang,
        });

        this.view.dismiss(returnValue);
    }

    loadFlags() {
        setTimeout(() => {
            const radios = document.getElementsByClassName('action-sheet-button-inner');
            for (let index = 0; index < radios.length; index++) {
                const element = radios[index];
                const flag =
                    '<img class="flag" style="width: 16px;height:16px;" src="assets/images/flags/rect/' +
                    this.languages[index].key +
                    '.png" /> ';
                element.innerHTML = flag + element.innerHTML;
            }
        }, 100);
    }

    LanguageChanged(language) {
        this.lang = language;
        this.translate.resetLang(this.translate.currentLang);
        this.translate
            .use(this.lang)
            .pipe(take(1))
            .subscribe(() => {
                localStorage.setItem(LocalStorageKey.language, this.lang);
                this.preferenceService.changePreference(PreferenceLocalStorageKey.lang, this.lang);
                this.preferenceService.langSignal.set(this.lang);
            });
    }
}
