<modal-content scrollY="false" fullscreen>
    <div class="auto-height-dialog">
        <app-icon icon="close" class="text-black close-button" (click)="dismiss()"></app-icon>
        <div class="title-container">
            <div class="title" i18n="@@LINK_ACCOUNT_OR_CONTINUE" [translate]="'LINK_ACCOUNT_OR_CONTINUE'">
                Link account or continue
            </div>
            <div class="subtitle" [translate]="'ACCOUNT_LINK_WARNING'" i18n="@@ACCOUNT_LINK_WARNING" *ngIf="!hasLinked">
                Your account is not linked to any of the players. Make sure to link your account if you are one of the
                players.
            </div>
        </div>
        <div class="mt-8">
            <ng-container *ngFor="let team of teams; let teamIndex = index">
                <div
                    class="flex items-center space-x-2 p-2 text-black"
                    *ngFor="let user of team.users; let userIndex = index">
                    <app-profile-photo
                        [size]="40"
                        [ultimate]="user.is_ultimate"
                        [url]="user.profile?.profile_photo_url"></app-profile-photo>
                    <div class="space-y-1 truncate w-full">
                        <div class="font-bold truncate">{{ user.full_name }}</div>
                        @if (user.profile?.nickname && !user.is_cpu) {
                            <div class="text-xs font-semibold">
                                {{ user.profile?.nickname }}
                            </div>
                        }
                    </div>
                    <div class="w-full" *ngIf="!user.id">
                        <app-primary-button
                            class="float-right block"
                            size="small"
                            color="blue"
                            [translate]="'LINK'"
                            i18n="@@LINK"
                            (click)="linkOwn(teamIndex, userIndex)">
                            Link
                        </app-primary-button>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="button-container">
            <app-primary-button
                size="normal"
                addedClasses="w-full"
                color="orange"
                (click)="continue()"
                *ngIf="!hasLinked"
                [translate]="'CONTINUE_ANYWAY'"
                i18n="@@CONTINUE_ANYWAY">
                Continue anyway
            </app-primary-button>
            <app-primary-button
                size="normal"
                addedClasses="w-full"
                color="orange"
                (click)="continue()"
                *ngIf="hasLinked"
                [translate]="'START_GAME'"
                i18n="@@START_GAME">
                Start game
            </app-primary-button>
        </div>
    </div>
</modal-content>
