/* eslint-disable max-len */
import { CommonModule } from '@angular/common';
import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CartesianCoordinate } from '@components/omni/omni-heatmap.component';
import { OmniThrowsComponent } from '@components/omni/omni-throws.component';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import {
    ConnectionStatus,
    OmniCommunicationService,
    OmniWebscocketCommand,
} from '@dc-core/dc-services/omni/omni-communication.service';
import { OmniIngameService } from '@dc-core/dc-services/omni/omni-ingame.service';
import { TranslateService } from '@ngx-translate/core';
import { SmartDeviceService } from '@services/smart-device.service';
import { ScrollToBottomDirective } from 'src/directives/scroll-to-bottom.directive';

import { Device_Images } from '../app.globals';
import { SmartDeviceItemComponent, SmartDeviceStatusColor } from '../smart-device-item/smart-device-item.component';

export interface OmniKeyboardCommand {
    command: OmniWebscocketCommand;
    label: string;
}

export type OmniSection = 'board' | 'debug';

// THE SUPPORTED OMNI SECTIONS IN DARTCOUNTER
export const BOARDSECTION: { label: string; tag: string; type: OmniSection } = {
    label: $localize`:@@OMNI_BOARD:Omni board`,
    tag: 'OMNI_BOARD',
    type: 'board',
};

export const DEBUGSECTION: { label: string; tag: string; type: OmniSection } = {
    label: $localize`:@@DEBUG:debug`,
    tag: 'DEBUG',
    type: 'debug',
};

@Component({
    selector: 'app-omni-keyboard',
    standalone: true,
    templateUrl: 'omni-keyboard.component.html',
    styleUrls: ['./omni-keyboard.component.scss'],
    imports: [OmniThrowsComponent, SmartDeviceItemComponent, CommonModule],
})
export class OmniKeyboardComponent implements OnInit {
    @Input() undoScore = false;
    @Output() undoSingleDart = new EventEmitter();

    @ViewChild(ScrollToBottomDirective, { static: false }) scrollToBottomDirective;

    public deviceImages = Device_Images;
    public dartHits: CartesianCoordinate[] = [null, null, null];

    currentStatus: ConnectionStatus = ConnectionStatus.NONE;
    connectionFailureCheck = false;

    private destroyRef: DestroyRef = inject(DestroyRef);

    constructor(
        public omni: OmniCommunicationService,
        public omniIngameService: OmniIngameService,
        private _translateService: TranslateService,
        private _alertService: DartCounterAlertService,
        private _smartDeviceService: SmartDeviceService
    ) {}

    ngOnInit(): void {
        this.omni.startGameAfterConnection = false;

        this.omni.connectionStatus$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((status) => {
            this.currentStatus = status;

            if (status === ConnectionStatus.DISCONNECTED && !this.connectionFailureCheck) {
                this.connectionFailureCheck = true;

                this._alertService.createAlert({
                    title: $localize`:@@NO_DEVICE_FOUND:No device found`,
                    text: this._translateService.instant('IS_YOUR_DEVICE_TURNED_ON_Q', {
                        device: this.omni.smartDevice.name,
                    }),
                    cancelButtonText: $localize`:@@CANCEL:Cancel`,
                    confirmButtonText: $localize`:@@RETRY:Retry`,
                    icon: 'warning',
                    timer: null,
                    onConfirm: () => {
                        this._alertService.createAlert({
                            title: this._translateService.instant('SEARCHING_FOR_DEVICE_IN_YOUR_NETWORK', {
                                device: this.omni.smartDevice.name,
                            }),
                            icon: 'info',
                            timer: 10000,
                            timerProgressBar: true,
                        });

                        this._smartDeviceService
                            .checkForIPChange(this.omni.smartDevice)
                            .then(() => {
                                this._alertService.createAlert({
                                    title: this._translateService.instant('DEVICE_HAS_RECONNECTED', {
                                        device: this.omni.smartDevice.name,
                                    }),
                                    icon: 'success',
                                    timer: 3000,
                                });

                                setTimeout(() => {
                                    this.connectionFailureCheck = false;
                                    this.smartDeviceChanged(this.omni.smartDevice);
                                }, 2000);
                            })
                            .catch(() => {
                                this.connectionFailureCheck = false;
                            });
                    },
                    onCancel: () => {
                        // Not turned on? Manual disconnect
                        this.omni.disconnect();
                        this.connectionFailureCheck = false;
                    },
                });
            }
        });
    }

    getStatusText(): string {
        switch (this.currentStatus) {
            case ConnectionStatus.CONNECTED:
                return 'Connected';
            case ConnectionStatus.CONNECTING:
                return 'Connecting';
            case ConnectionStatus.DISCONNECTED:
                return 'Not Connected';
            default:
                return '';
        }
    }

    getStatusClass(): SmartDeviceStatusColor {
        switch (this.currentStatus) {
            case ConnectionStatus.CONNECTED:
                return 'green';
            case ConnectionStatus.CONNECTING:
                return 'yellow';
            case ConnectionStatus.DISCONNECTED:
                return 'red';
            default:
                return 'red';
        }
    }

    smartDeviceChanged(event) {
        this.omni.loadSmartDevice(event);
        this.omni.connect(true);

        this._alertService.createAlert({
            id: 'CONNECTING_TO_OMNI',
            title: 'Activating OMNI Auto Scoring',
            timer: null,
            icon: 'loading',
        });
    }

    sendCommand(cmd: OmniKeyboardCommand) {
        // Send the command (BoardEmpty, Calib or PlayerChange)
        this.omni.sendMessage(cmd.command);

        // Remove the 'bust' or 'remove darts' info
        this.omniIngameService.currentSingleDartEvent = null;
    }

    emitUndoScore(): void {
        if (this.undoScore) {
            this.undoSingleDart.emit();
        }
    }
}
