import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { OnlineGameplay } from '@dc-core/dc-backend/dc-interfaces';
import { LobbySettingsCollectionService } from '@dc-core/dc-firestore/collection-helpers/lobby_settings.collection.service';
import { DCFireStoreInvite } from '@dc-core/dc-firestore/globals/firestore.tables';
import { OnlineFunctions } from '@dc-core/dc-gamelogic/online-game/online.functions';
import { LocalStorageKey } from '@dc-core/dc-localstorage';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { UserMedia } from '@dc-core/dc-services/dc-janus/DartCounterUserMedia';
import { JanusVideoRoomService } from '@dc-core/dc-services/dc-janus/janus-video-room.service';
import { ModalController } from '@ionic/angular';
import { Unsubscribe } from 'firebase/auth';
import { onSnapshot, QueryDocumentSnapshot } from 'firebase/firestore';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/services/auth.service';

export interface AcceptDenyJoinGameRequestDialogResponse {
    acceptedInvite: boolean;
}

@Component({
    selector: 'app-accept-deny-join-game-request-dialog',
    templateUrl: 'accept-deny-join-game-request.html',
})
export class AcceptDenyJoinGameRequestDialogComponent implements OnInit, OnDestroy {
    @Input() queryInvite: QueryDocumentSnapshot<DCFireStoreInvite>;
    gameplay: OnlineGameplay;
    invite: DCFireStoreInvite;
    unsubscribe: Unsubscribe;
    lobbySub: Subscription;

    userMedia = new UserMedia();

    constructor(
        public view: ModalController,
        public onlineFunctions: OnlineFunctions,
        private _alertService: DartCounterAlertService,
        private _lobbySettingsCollectionService: LobbySettingsCollectionService,
        public auth: AuthService,
        private _videoRoomService: JanusVideoRoomService
    ) {}

    ngOnInit() {
        this.invite = this.queryInvite.data();

        this.unsubscribe = onSnapshot(this.queryInvite.ref, (docSnapshot) => {
            if (!docSnapshot.exists()) {
                this._alertService.createAlert({
                    title: $localize`:@@INVITE_IS_CANCELLED:The invite has been cancelled`,
                    icon: 'info',
                    showCloseButton: true,
                });

                this.view.dismiss();
                this.unsubscribe();
            }
        });

        this.lobbySub = this._lobbySettingsCollectionService.watchOwnLobbyGames().subscribe((onlineGameplays) => {
            if (onlineGameplays.length) {
                this.gameplay = _.first(onlineGameplays);
                this.gameplay.players[1] = this.invite.user;

                if (this.invite.user.room?.roomID) {
                    this._videoRoomService
                        .spectateRoom(
                            this.invite.user.room,
                            'video',
                            this.invite.user,
                            this.userMedia,
                            false,
                            false,
                            false
                        )
                        .catch((error) => {
                            console.error('Error joining room:', error);
                        });
                }
            } else {
                this.decline();
            }
        });

        const firstTime = JSON.parse(localStorage.getItem(LocalStorageKey.firstTimeProfileDialog));
        if (!firstTime) localStorage.setItem(LocalStorageKey.firstTimeProfileDialog, JSON.stringify(false));
    }

    public decline(): void {
        this.unsubscribe();
        const response: AcceptDenyJoinGameRequestDialogResponse = {
            acceptedInvite: false,
        };
        this.view.dismiss(response);
    }

    public accept(): void {
        this.unsubscribe();
        const response: AcceptDenyJoinGameRequestDialogResponse = {
            acceptedInvite: true,
        };
        this.view.dismiss(response);
    }

    async ngOnDestroy(): Promise<void> {
        if (this.userMedia && this.invite.user.room?.roomID) {
            await this._videoRoomService
                .leaveRoomAndDetachAllHandles(this.invite.user.room.roomID, true)
                .catch(console.error);
            this.userMedia.cleanupUserMedia(true, true);
        }
    }
}
