/* eslint-disable max-len */
import { Component, EventEmitter, Output } from '@angular/core';
import { GameStatisticsApiService } from '@dc-api/game-statistics.api.service';
import { AmountOfHits, AroundTheClockThrowout } from '@dc-core/dc-backend/dc-enums';
import {
    AroundTheClockOverallStats,
    AroundTheClockStatDetails,
} from '@dc-core/dc-statistics/around-the-clock/around-the-clock-overall-stats';
import {
    FilterChangedFilter,
    OverallStatDetails,
    StatDetailSegment,
    StatDetailType,
} from '@dc-core/dc-statistics/game-statistics.classes';
import { DropdownSelectItem, StatisticsRange } from '@dc-core/dc-statistics/statistics.globals';
import { ModalController, NavController } from '@ionic/angular';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { UpgradeService } from '@providers/UpgradeService';
import { AuthService } from '@services/auth.service';
import { GA_EVENTACTIONS, GA_EVENTCATEGORIES } from 'src/app/app.globals';

@Component({
    selector: 'app-around-the-clock-table',
    templateUrl: 'around-the-clock-table.component.html',
    styleUrls: ['around-the-clock-table.component.scss'],
})
export class AroundTheClockTableComponent extends AroundTheClockOverallStats {
    @Output() dateChangeEvent = new EventEmitter<void>();

    public periodOptions: DropdownSelectItem[] = [
        {
            label: $localize`:@@ALL_TIME:All-time`,
            value: 'all_time',
            ultimateOnly: true,
        },
        {
            label: $localize`:@@THIS_MONTH:This month`,
            value: 'this_month',
            ultimateOnly: false,
        },
        {
            label: $localize`:@@THIS_YEAR:This year`,
            value: 'this_year',
            ultimateOnly: false,
        },
        {
            label: $localize`:@@CUSTOM:Custom`,
            value: 'custom',
            ultimateOnly: true,
        },
    ];

    public throwouts: DropdownSelectItem[] = [
        {
            label: $localize`:@@ALL:All`,
            value: 'all' as AroundTheClockThrowout,
            ultimateOnly: false,
        },
        {
            label: $localize`:@@BULL:Bull`,
            value: 'bull' as AroundTheClockThrowout,
            ultimateOnly: false,
        },
        {
            label: $localize`:@@BULLSEYE:Bullseye`,
            value: 'bullseye' as AroundTheClockThrowout,
            ultimateOnly: false,
        },
    ];

    public hits: DropdownSelectItem[] = [
        {
            label: $localize`:@@ALL:All`,
            value: 'all' as AmountOfHits,
            ultimateOnly: false,
        },
        {
            label: $localize`:@@ONCE:Once`,
            value: 'once' as AmountOfHits,
            ultimateOnly: true,
        },
        {
            label: $localize`:@@TWICE:Twice`,
            value: 'twice' as AmountOfHits,
            ultimateOnly: true,
        },
        {
            label: $localize`:@@THRICE:Thrice`,
            value: 'thrice' as AmountOfHits,
            ultimateOnly: true,
        },
    ];

    constructor(
        public nav: NavController,
        public auth: AuthService,
        private ga: DartCounterAnalyticsService,
        public gameStatisticsApiService: GameStatisticsApiService,
        public modal: ModalController,
        private upgrade: UpgradeService
    ) {
        super(auth.user, gameStatisticsApiService);
        this.ga.trackEvent(GA_EVENTCATEGORIES.STATISTICS, GA_EVENTACTIONS.OPENAROUNDTHECLOCKSTATSTABLE);

        this.setFilters(); // Set previous filters if any
        this.loadOverallStats();
    }

    changeFilter(filter: FilterChangedFilter, value: any): void {
        switch (filter) {
            // Overall
            case 'statisticsRange':
                this.updateStatisticsRange(value);
                this.dateChangeEvent.emit();
                break;

            // Game specific
            case 'throwout':
                this.throwout = value;
                break;
            case 'numberSegment':
                this.numberSegment = value;
                break;
            case 'amountOfHits':
                this.amountOfHits = value;
                break;
        }

        this.loadOverallStats();
    }

    changeStatisticsRange(statisticsRange: StatisticsRange): void {
        super.updateStatisticsRange(statisticsRange, false);
        this.loadOverallStats();
    }

    goToDetails(statsType: StatDetailType, segment: StatDetailSegment) {
        let title = '';
        switch (statsType) {
            case 'hitRate':
                title = $localize`:@@HIT_RATE:Hit rate`;
                break;
            case 'totalDarts':
                title = $localize`:@@DARTS_THROWN:Darts thrown`;
                break;
        }

        this.ga.trackEvent(GA_EVENTCATEGORIES.STATISTICS, 'View top 25 ' + segment + ' ' + statsType);

        this.nav.navigateForward('statistics/details', {
            queryParams: {
                // Overall
                title,
                statsType: statsType,
                segment: segment,
                fromDate: this.fromDate,
                untilDate: this.untilDate,
                // Game mode specific
                gameMode: 'around_the_clock',
                gameSpecific: {
                    throwout: this.throwout,
                    numberSegment: this.numberSegment,
                    amountOfHits: this.amountOfHits,
                } as AroundTheClockStatDetails,
            } as OverallStatDetails,
        } as NavigationOptions);
    }

    async OpenUltimateDialog() {
        const dialogSettings = await this.upgrade.GetUpgradeDialog(GA_EVENTCATEGORIES.STATISTICS);
        dialogSettings.componentProps.slidesOrder = [3];
        this.modal.create(dialogSettings).then((elem) => {
            elem.present();
        });
    }
}
