/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AddPlayerPayload, EditPlayerPayload, RemovePlayerPayload } from '@dc-core/dc-gamelogic/game-interfaces';
import { AuthService } from '@services/auth.service';
import { User } from 'firebase/auth';
import { DragulaOptions, DragulaService } from 'ng2-dragula';

@Component({
    selector: 'app-new-game-add-player',
    templateUrl: 'new-game-add-player.component.html',
})
export class NewGameAddPlayerComponent implements OnInit, OnDestroy {
    @Input() users: any;

    @Output() updatedUsersEvent = new EventEmitter();
    @Output() showPlayerOptionsEvent = new EventEmitter<EditPlayerPayload>();
    @Output() addPlayerEvent = new EventEmitter<AddPlayerPayload>();
    @Output() removePlayerEvent = new EventEmitter<RemovePlayerPayload>();

    constructor(
        public auth: AuthService,
        private _dragulaService: DragulaService
    ) {}

    ngOnInit(): void {
        this._dragulaService.createGroup('PLAYERS', {
            revertOnSpill: true,
            accepts: (_el) => {
                const newIndex = [].slice.call(_el.parentElement.children).indexOf(_el);
                const allowedIndex = newIndex < this.users.length;

                const draggable = !_el.className.includes('no-drag');

                return draggable && allowedIndex;
            },
        } as DragulaOptions);

        this._dragulaService.dragend().subscribe(() => {
            this.updatedUsersEvent.emit(this.users);
        });
    }

    public humanUsers(): any[] {
        return this.users.filter((user) => !user.is_cpu);
    }

    showPlayerOptions(user: User, userIndex: number): void {
        this.showPlayerOptionsEvent.emit({ teamIndex: null, userIndex, user });
    }

    addPlayer(user?: User, team?: { users: User[] }): void {
        this.addPlayerEvent.emit({ user, team });
    }

    removePlayer(userIndex: number, teamIndex: number = null): void {
        this.removePlayerEvent.emit({ userIndex, teamIndex });
    }

    ngOnDestroy(): void {
        this._dragulaService.destroy('PLAYERS');
    }
}
