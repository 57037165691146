import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GameStatisticsApiService } from '@dc-api/game-statistics.api.service';
import { GameMode, StatisticNumberFormat } from '@dc-core/dc-backend/dc-enums';
import { STATISTICS_BEST, STATISTICS_WORST } from '@dc-core/dc-gamelogic/settings/settings.globals';
import { AroundTheClockStatDetails } from '@dc-core/dc-statistics/around-the-clock/around-the-clock-overall-stats';
import { Bobs27StatDetails } from '@dc-core/dc-statistics/bobs-27/bobs-27-overall-stats';
import { CricketTacticsStatDetails } from '@dc-core/dc-statistics/cricket-tactics/cricket-tactics-overall-stats';
import { DoublesTrainingStatDetails } from '@dc-core/dc-statistics/doubles-training/doubles-training-overall-stats';
import { StatDetailSegment, StatDetailType } from '@dc-core/dc-statistics/game-statistics.classes';
import { MatchStatDetails } from '@dc-core/dc-statistics/match/match-overall-stats';
import { ScoreTrainingStatDetails } from '@dc-core/dc-statistics/score-training/score-training-overall-stats';
import { SinglesTrainingStatDetails } from '@dc-core/dc-statistics/singles-training/singles-training-overall-stats';
import { components } from '@dc-core/dc-typings/dc-backend-definitions';
import { ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { DCLoadingService } from '@providers/DCLoadingService';
import { UpgradeService } from '@providers/UpgradeService';
import { AuthService } from '@services/auth.service';
import moment from 'moment';
import { take } from 'rxjs';
import { GA_EVENTCATEGORIES } from 'src/app/app.globals';

@Component({
    selector: 'app-stat-details-list',
    templateUrl: 'stat-details-list.component.html',
})
export class StatDetailsListComponent {
    public translateService: TranslateService = inject(TranslateService);

    public items: {
        best: components['schemas']['GameStatistic'][];
        worst: components['schemas']['GameStatistic'][];
    } = {
        best: [],
        worst: [],
    };
    public segments: { label: string; tag: string; type: string }[] = [];

    title: string;
    numberFormat: StatisticNumberFormat;
    statsType: StatDetailType;
    segment: StatDetailSegment;
    fromDate: string;
    untilDate: string;
    gameMode: GameMode;
    gameSpecific:
        | MatchStatDetails
        | CricketTacticsStatDetails
        | Bobs27StatDetails
        | SinglesTrainingStatDetails
        | DoublesTrainingStatDetails
        | ScoreTrainingStatDetails;

    constructor(
        public nav: NavController,
        private ga: DartCounterAnalyticsService,
        public route: ActivatedRoute,
        public loading: DCLoadingService,
        public auth: AuthService,
        public modal: ModalController,
        private _gameStatisticsApiService: GameStatisticsApiService,
        private upgrade: UpgradeService
    ) {
        this.segments = [STATISTICS_BEST, STATISTICS_WORST];

        this.route.queryParams.pipe(take(1)).subscribe((params) => {
            this.title = params.title;
            this.numberFormat = params.numberFormat ?? 'decimal';
            this.statsType = params.statsType;
            this.segment = params.segment;
            this.fromDate = params.fromDate;
            this.untilDate = params.untilDate;
            this.gameMode = params.gameMode;
            this.gameSpecific = params.gameSpecific;

            if (!this.segment) {
                this.segment = 'best';
            }

            this.loading.ShowCustomLoader({ showBackdrop: false });

            this.loadDetails();
        });
    }

    goToBest(): void {
        if (this.segment !== 'best') {
            this.segment = 'best';
        }
    }

    goToWorst(): void {
        if (this.segment !== 'worst') {
            this.segment = 'worst';
        }
    }

    changeSegment(segment: StatDetailSegment): void {
        this.segment = segment;
    }

    GetDate(date) {
        return moment(date).toISOString();
    }

    async loadDetails(): Promise<void> {
        switch (this.gameMode) {
            case 'match':
                const matchStatDetails = this.gameSpecific as MatchStatDetails;
                switch (this.statsType) {
                    case 'threeDartAvg':
                        await this._gameStatisticsApiService
                            .getMatchThreeDartAvgStatistics({
                                type: 'best',
                                start_score: matchStatDetails.startScore,
                                is_online: matchStatDetails.isOnline,
                                vs_cpu: matchStatDetails.vsCPU,
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.best = res.data;
                            });
                        await this._gameStatisticsApiService
                            .getMatchThreeDartAvgStatistics({
                                type: 'worst',
                                start_score: matchStatDetails.startScore,
                                is_online: matchStatDetails.isOnline,
                                vs_cpu: matchStatDetails.vsCPU,
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.worst = res.data;
                            });
                        break;
                    case 'firstNineAvg':
                        await this._gameStatisticsApiService
                            .getMatchFirstNineAvgStatistics({
                                type: 'best',
                                start_score: matchStatDetails.startScore,
                                is_online: matchStatDetails.isOnline,
                                vs_cpu: matchStatDetails.vsCPU,
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.best = res.data;
                            });
                        await this._gameStatisticsApiService
                            .getMatchFirstNineAvgStatistics({
                                type: 'worst',
                                start_score: matchStatDetails.startScore,
                                is_online: matchStatDetails.isOnline,
                                vs_cpu: matchStatDetails.vsCPU,
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.worst = res.data;
                            });
                        break;
                    case 'checkoutRate':
                        await this._gameStatisticsApiService
                            .getMatchCheckoutRateStatistics({
                                type: 'best',
                                start_score: matchStatDetails.startScore,
                                is_online: matchStatDetails.isOnline,
                                vs_cpu: matchStatDetails.vsCPU,
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.best = res.data;
                            });
                        await this._gameStatisticsApiService
                            .getMatchCheckoutRateStatistics({
                                type: 'worst',
                                start_score: matchStatDetails.startScore,
                                is_online: matchStatDetails.isOnline,
                                vs_cpu: matchStatDetails.vsCPU,
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.worst = res.data;
                            });
                        break;
                    case 'checkouts':
                        await this._gameStatisticsApiService
                            .getMatchCheckoutsStatistics({
                                type: 'best',
                                start_score: matchStatDetails.startScore,
                                is_online: matchStatDetails.isOnline,
                                vs_cpu: matchStatDetails.vsCPU,
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.best = res.data;
                            });
                        await this._gameStatisticsApiService
                            .getMatchCheckoutsStatistics({
                                type: 'worst',
                                start_score: matchStatDetails.startScore,
                                is_online: matchStatDetails.isOnline,
                                vs_cpu: matchStatDetails.vsCPU,
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.worst = res.data;
                            });
                        break;
                    case 'legs':
                        await this._gameStatisticsApiService
                            .getMatchLegsStatistics({
                                type: 'best',
                                start_score: matchStatDetails.startScore,
                                is_online: matchStatDetails.isOnline,
                                vs_cpu: matchStatDetails.vsCPU,
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.best = res.data;
                            });
                        await this._gameStatisticsApiService
                            .getMatchLegsStatistics({
                                type: 'worst',
                                start_score: matchStatDetails.startScore,
                                is_online: matchStatDetails.isOnline,
                                vs_cpu: matchStatDetails.vsCPU,
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.worst = res.data;
                            });
                        break;
                }
                break;
            case 'cricket_tactics':
                const cricketTacticsStatDetails = this.gameSpecific as CricketTacticsStatDetails;
                switch (this.statsType) {
                    case 'marksPerRound':
                        await this._gameStatisticsApiService
                            .getCricketTacticsMarksPerRoundStatistics({
                                type: 'best',
                                game_setting: cricketTacticsStatDetails.gameSetting,
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.best = res.data;
                            });
                        await this._gameStatisticsApiService
                            .getCricketTacticsMarksPerRoundStatistics({
                                type: 'worst',
                                game_setting: cricketTacticsStatDetails.gameSetting,
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.worst = res.data;
                            });
                        break;
                    case 'totalScore':
                        await this._gameStatisticsApiService
                            .getCricketTacticsTotalScoreStatistics({
                                type: 'best',
                                game_setting: cricketTacticsStatDetails.gameSetting,
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.best = res.data;
                            });
                        await this._gameStatisticsApiService
                            .getCricketTacticsTotalScoreStatistics({
                                type: 'worst',
                                game_setting: cricketTacticsStatDetails.gameSetting,
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.worst = res.data;
                            });
                        break;
                }
                break;
            case 'bobs_27':
                const bobs27StatDetails = this.gameSpecific as Bobs27StatDetails;
                switch (this.statsType) {
                    case 'totalScore':
                        await this._gameStatisticsApiService
                            .getBobs27TotalScoreStatistics({
                                type: 'best',
                                game_setting: bobs27StatDetails.gameSetting,
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.best = res.data;
                            });
                        await this._gameStatisticsApiService
                            .getBobs27TotalScoreStatistics({
                                type: 'worst',
                                game_setting: bobs27StatDetails.gameSetting,
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.worst = res.data;
                            });
                        break;
                    case 'avgHitsPerTurn':
                        await this._gameStatisticsApiService
                            .getBobs27AvgHitsPerTurnStatistics({
                                type: 'best',
                                game_setting: bobs27StatDetails.gameSetting,
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.best = res.data;
                            });
                        await this._gameStatisticsApiService
                            .getBobs27AvgHitsPerTurnStatistics({
                                type: 'worst',
                                game_setting: bobs27StatDetails.gameSetting,
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.worst = res.data;
                            });
                        break;
                    case 'avgTurnsTaken':
                        await this._gameStatisticsApiService
                            .getBobs27AvgTurnsTakenStatistics({
                                type: 'best',
                                game_setting: bobs27StatDetails.gameSetting,
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.best = res.data;
                            });
                        await this._gameStatisticsApiService
                            .getBobs27AvgTurnsTakenStatistics({
                                type: 'worst',
                                game_setting: bobs27StatDetails.gameSetting,
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.worst = res.data;
                            });
                        break;
                    case 'doubleHitRate':
                        await this._gameStatisticsApiService
                            .getBobs27DoubleHitRateStatistics({
                                type: 'best',
                                game_setting: bobs27StatDetails.gameSetting,
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.best = res.data;
                            });
                        await this._gameStatisticsApiService
                            .getBobs27DoubleHitRateStatistics({
                                type: 'worst',
                                game_setting: bobs27StatDetails.gameSetting,
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.worst = res.data;
                            });
                        break;
                }
                break;
            case 'singles_training':
                const singlesTrainingStatDetails = this.gameSpecific as SinglesTrainingStatDetails;
                switch (this.statsType) {
                    case 'totalScore':
                        await this._gameStatisticsApiService
                            .getSinglesTrainingTotalScoreStatistics({
                                type: 'best',
                                game_setting: singlesTrainingStatDetails.gameSetting,
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.best = res.data;
                            });
                        await this._gameStatisticsApiService
                            .getSinglesTrainingTotalScoreStatistics({
                                type: 'worst',
                                game_setting: singlesTrainingStatDetails.gameSetting,
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.worst = res.data;
                            });
                        break;
                    case 'dartsHit':
                        await this._gameStatisticsApiService
                            .getSinglesTrainingDartsHitStatistics({
                                type: 'best',
                                game_setting: singlesTrainingStatDetails.gameSetting,
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.best = res.data;
                            });
                        await this._gameStatisticsApiService
                            .getSinglesTrainingDartsHitStatistics({
                                type: 'worst',
                                game_setting: singlesTrainingStatDetails.gameSetting,
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.worst = res.data;
                            });
                        break;
                    case 'hitRate':
                        await this._gameStatisticsApiService
                            .getSinglesTrainingHitRateStatistics({
                                type: 'best',
                                game_setting: singlesTrainingStatDetails.gameSetting,
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.best = res.data;
                            });
                        await this._gameStatisticsApiService
                            .getSinglesTrainingHitRateStatistics({
                                type: 'worst',
                                game_setting: singlesTrainingStatDetails.gameSetting,
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.worst = res.data;
                            });
                        break;
                }
                break;
            case 'doubles_training':
                const doublesTrainingStatDetails = this.gameSpecific as DoublesTrainingStatDetails;
                switch (this.statsType) {
                    case 'dartsNeeded':
                        await this._gameStatisticsApiService
                            .getDoublesTrainingDartsNeededStatistics({
                                type: 'best',
                                game_setting: doublesTrainingStatDetails.gameSetting,
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.best = res.data;
                            });
                        await this._gameStatisticsApiService
                            .getDoublesTrainingDartsNeededStatistics({
                                type: 'worst',
                                game_setting: doublesTrainingStatDetails.gameSetting,
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.worst = res.data;
                            });
                        break;
                    case 'hitRate':
                        await this._gameStatisticsApiService
                            .getDoublesTrainingHitRateStatistics({
                                type: 'best',
                                game_setting: doublesTrainingStatDetails.gameSetting,
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.best = res.data;
                            });
                        await this._gameStatisticsApiService
                            .getDoublesTrainingHitRateStatistics({
                                type: 'worst',
                                game_setting: doublesTrainingStatDetails.gameSetting,
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.worst = res.data;
                            });
                        break;
                }
                break;
            case 'score_training':
                switch (this.statsType) {
                    case 'totalScore':
                        await this._gameStatisticsApiService
                            .getScoreTrainingTotalScoreStatistics({
                                type: 'best',
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.best = res.data;
                            });
                        await this._gameStatisticsApiService
                            .getScoreTrainingTotalScoreStatistics({
                                type: 'worst',
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.worst = res.data;
                            });
                        break;
                    case 'threeDartAvg':
                        await this._gameStatisticsApiService
                            .getScoreTrainingThreeDartAverageStatistics({
                                type: 'best',
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.best = res.data;
                            });
                        await this._gameStatisticsApiService
                            .getScoreTrainingThreeDartAverageStatistics({
                                type: 'worst',
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.worst = res.data;
                            });
                        break;
                    case 'oneDartAvg':
                        await this._gameStatisticsApiService
                            .getScoreTrainingOneDartAverageStatistics({
                                type: 'best',
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.best = res.data;
                            });
                        await this._gameStatisticsApiService
                            .getScoreTrainingOneDartAverageStatistics({
                                type: 'worst',
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.worst = res.data;
                            });
                        break;
                }
                break;
            case 'around_the_clock':
                const aroundTheClockStatDetails = this.gameSpecific as AroundTheClockStatDetails;
                switch (this.statsType) {
                    case 'hitRate':
                        await this._gameStatisticsApiService
                            .getAroundTheClockHitRateStatistics({
                                type: 'best',
                                throwout: aroundTheClockStatDetails.throwout,
                                number_segment: aroundTheClockStatDetails.numberSegment,
                                amount_of_hits: aroundTheClockStatDetails.amountOfHits,
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.best = res.data;
                            });
                        await this._gameStatisticsApiService
                            .getAroundTheClockHitRateStatistics({
                                type: 'worst',
                                throwout: aroundTheClockStatDetails.throwout,
                                number_segment: aroundTheClockStatDetails.numberSegment,
                                amount_of_hits: aroundTheClockStatDetails.amountOfHits,
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.worst = res.data;
                            });
                        break;
                    case 'totalDarts':
                        await this._gameStatisticsApiService
                            .getAroundTheClockDartsThrownStatistics({
                                type: 'best',
                                throwout: aroundTheClockStatDetails.throwout,
                                number_segment: aroundTheClockStatDetails.numberSegment,
                                amount_of_hits: aroundTheClockStatDetails.amountOfHits,
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.best = res.data;
                            });
                        await this._gameStatisticsApiService
                            .getAroundTheClockDartsThrownStatistics({
                                type: 'worst',
                                throwout: aroundTheClockStatDetails.throwout,
                                number_segment: aroundTheClockStatDetails.numberSegment,
                                amount_of_hits: aroundTheClockStatDetails.amountOfHits,
                                limit: 25,
                                from_date: this.fromDate,
                                to_date: this.untilDate,
                            })
                            .then((res) => {
                                this.items.worst = res.data;
                            });
                        break;
                }
                break;
        }

        this.loading.DismissLoader();
    }

    ShowDetail(game: components['schemas']['GameStatistic']) {
        this.ga.trackEvent(
            GA_EVENTCATEGORIES.STATISTICS,
            'View ' + this.gameMode.toString().replace('_', ' ') + ' from ' + this.segment + ' ' + this.statsType
        );

        this.nav.navigateForward(
            'statistics/' + this.gameMode.toString().replace(new RegExp('_', 'g'), '-') + '/details',
            {
                queryParams: {
                    gameId: game.game_id,
                },
            }
        );
    }

    async OpenSubscriptionDialog() {
        let GA_CATEGORY = GA_EVENTCATEGORIES.TOPBEST501;
        if (this.segment === 'worst') {
            GA_CATEGORY = GA_EVENTCATEGORIES.TOPWORST501;
        }
        const dialogSettings = await this.upgrade.GetUpgradeDialog(GA_CATEGORY);
        this.modal.create(dialogSettings).then((elem) => {
            elem.present();
        });
    }
}
