/* eslint-disable max-len */
import { Component, EventEmitter, Output } from '@angular/core';
import { GameStatisticsApiService } from '@dc-api/game-statistics.api.service';
import { StatisticNumberFormat } from '@dc-core/dc-backend/dc-enums';
import {
    FilterChangedFilter,
    OverallStatDetails,
    StatDetailSegment,
    StatDetailType,
} from '@dc-core/dc-statistics/game-statistics.classes';
import {
    SinglesTrainingOverallStats,
    SinglesTrainingStatDetails,
} from '@dc-core/dc-statistics/singles-training/singles-training-overall-stats';
import { DropdownSelectItem, StatisticsRange } from '@dc-core/dc-statistics/statistics.globals';
import { ModalController, NavController } from '@ionic/angular';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { UpgradeService } from '@providers/UpgradeService';
import { AuthService } from '@services/auth.service';
import { GA_EVENTACTIONS, GA_EVENTCATEGORIES } from 'src/app/app.globals';

@Component({
    selector: 'app-singles-training-table',
    templateUrl: 'singles-training-table.component.html',
    styleUrls: ['singles-training-table.component.scss'],
})
export class SinglesTrainingTableComponent extends SinglesTrainingOverallStats {
    @Output() dateChangeEvent = new EventEmitter<void>();

    public periodOptions: DropdownSelectItem[] = [
        {
            label: $localize`:@@ALL_TIME:All-time`,
            value: 'all_time',
            ultimateOnly: true,
        },
        {
            label: $localize`:@@THIS_MONTH:This month`,
            value: 'this_month',
            ultimateOnly: false,
        },
        {
            label: $localize`:@@THIS_YEAR:This year`,
            value: 'this_year',
            ultimateOnly: false,
        },
        {
            label: $localize`:@@CUSTOM:Custom`,
            value: 'custom',
            ultimateOnly: true,
        },
    ];

    public gameSettingOptions: DropdownSelectItem[] = [
        {
            label: $localize`:@@ALL:All`,
            value: 'all',
            ultimateOnly: false,
        },
        {
            label: $localize`:@@LOW_TO_HIGH:Low to high`,
            value: 'low_to_high',
            ultimateOnly: true,
        },
        {
            label: $localize`:@@HIGH_TO_LOW:High to low`,
            value: 'high_to_low',
            ultimateOnly: true,
        },
        {
            label: $localize`:@@RANDOM:Random`,
            value: 'random',
            ultimateOnly: true,
        },
    ];

    constructor(
        public nav: NavController,
        public auth: AuthService,
        private ga: DartCounterAnalyticsService,
        public gameStatisticsApiService: GameStatisticsApiService,
        public modal: ModalController,
        private upgrade: UpgradeService
    ) {
        super(auth.user, gameStatisticsApiService);
        this.ga.trackEvent(GA_EVENTCATEGORIES.STATISTICS, GA_EVENTACTIONS.OPENSINGLESSTATSTABLE);

        this.setFilters(); // Set previous filters if any
        this.loadOverallStats();
    }

    changeFilter(filter: FilterChangedFilter, value: any): void {
        this.ga.trackEvent(GA_EVENTCATEGORIES.STATISTICS, GA_EVENTACTIONS.CHANGESINGLESFILTER);
        switch (filter) {
            // Overall
            case 'statisticsRange':
                this.updateStatisticsRange(value);
                this.dateChangeEvent.emit();
                break;

            // Game specific
            case 'gameSetting':
                this.gameSetting = value;
                break;
        }

        this.loadOverallStats();
    }

    changeStatisticsRange(statisticsRange: StatisticsRange): void {
        super.updateStatisticsRange(statisticsRange, false);
        this.loadOverallStats();
    }

    goToDetails(statsType: StatDetailType, segment: StatDetailSegment) {
        let title = '';
        let numberFormat: StatisticNumberFormat = null;
        switch (statsType) {
            case 'totalScore':
                title = $localize`:@@TOTAL_SCORE:Total score`;
                break;
            case 'dartsHit':
                title = $localize`:@@DARTS_HIT:Darts hit`;
                break;
            case 'hitRate':
                title = $localize`:@@HIT_RATE:Hit rate`;
                numberFormat = 'percentage';
                break;
        }

        this.ga.trackEvent(GA_EVENTCATEGORIES.STATISTICS, 'View top 25 ' + segment + ' ' + statsType);

        this.nav.navigateForward('statistics/details', {
            queryParams: {
                // Overall
                title,
                numberFormat,
                statsType: statsType,
                segment: segment,
                fromDate: this.fromDate,
                untilDate: this.untilDate,
                // Game mode specific
                gameMode: 'singles_training',
                gameSpecific: {
                    gameSetting: this.gameSetting,
                } as SinglesTrainingStatDetails,
            } as OverallStatDetails,
        } as NavigationOptions);
    }

    async OpenUltimateDialog() {
        const dialogSettings = await this.upgrade.GetUpgradeDialog(GA_EVENTCATEGORIES.STATISTICS);
        dialogSettings.componentProps.slidesOrder = [3];
        this.modal.create(dialogSettings).then((elem) => {
            elem.present();
        });
    }
}
