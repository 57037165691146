import { Route } from '@angular/router';

import { aroundTheClockGameRoutes } from './around-the-clock/games-around-the-clock.routes';
import { bobs27GameRoutes } from './bobs-27/games-bobs-27.routes';
import { cricketTacticsGameRoutes } from './cricket-tactics/games-cricket-tactics.routes';
import { doublesTrainingGameRoutes } from './doubles-training/games-doubles-training.routes';
import { matchGameRoutes } from './match/games-match.routes';
import { scoreTrainingGameRoutes } from './score-training/games-score-training.routes';
import { shanghaiGameRoutes } from './shanghai/games-doubles-training.routes';
import { singlesTrainingGameRoutes } from './singles-training/games-singles-training.routes';

export const gameRoutes: Route[] = [
    ...matchGameRoutes,
    ...aroundTheClockGameRoutes,
    ...bobs27GameRoutes,
    ...cricketTacticsGameRoutes,
    ...doublesTrainingGameRoutes,
    ...scoreTrainingGameRoutes,
    ...shanghaiGameRoutes,
    ...singlesTrainingGameRoutes,
];
