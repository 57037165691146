<div class="main-content overflow-hidden flex h-full w-full flex-col pt-0">
    @if (!zoomedOnly()) {
        <div class="flex relative h-16 min-h-16 bg-black opacity-80">
            <button
                class="inline-flex basis-1/2 items-center justify-center"
                [class.inner-border-b-2-orange]="ingameOmniService.omniViewMode === 'zoomed'"
                [class.opacity-60]="ingameOmniService.omniViewMode !== 'zoomed'"
                (click)="ingameOmniService.changeOmniView('zoomed')">
                <div
                    class="font-bebas-neue text-xl text-white"
                    [translate]="'OMNI_ZOOMED_VIEW'"
                    i18n="@@OMNI_ZOOMED_VIEW">
                    Zoomed
                </div>
            </button>
            <!-- <button
                class="inline-flex basis-1/2 items-center justify-center"
                [class.inner-border-b-2-orange]="ingameOmniService.omniViewMode === 'heatmap'"
                [class.opacity-60]="ingameOmniService.omniViewMode !== 'heatmap'"
                (click)="ingameOmniService.changeOmniView('heatmap')">
                <div class="font-bebas-neue text-xl text-white" [translate]="'HEATMAP'" i18n="@@HEATMAP">Heatmap</div>
            </button> -->
            <button
                class="inline-flex basis-1/2 items-center justify-center"
                [class.inner-border-b-2-orange]="ingameOmniService.omniViewMode === 'board'"
                [class.opacity-60]="ingameOmniService.omniViewMode !== 'board'"
                (click)="ingameOmniService.changeOmniView('board')">
                <div
                    class="font-bebas-neue text-xl text-white"
                    [translate]="'OMNI_BOARD_VIEW'"
                    i18n="@@OMNI_BOARD_VIEW">
                    Full board
                </div>
            </button>
            <button class="inline-flex items-center justify-center px-4" (click)="openOmniSettings()">
                <app-icon class="text-white" icon="settings"></app-icon>
            </button>
        </div>
    }

    <div class="flex flex-col relative h-full">
        @if (ingameOmniService.currentSingleDartEvent) {
            <div
                class="text-black absolute animate__animated animate__slideInUp z-[100] bg-white bottom-0 p-3 w-full mx-auto flex flex-col items-center justify-center">
                <div class="flex flex-col items-center">
                    <div class="text-center text-medium font-bold">
                        @if (ingameOmniService.currentSingleDartEvent === 'RemoveDarts') {
                            <span [translate]="'REMOVE_YOUR_DARTS'" i18n="@@REMOVE_YOUR_DARTS">Remove your darts </span>
                        } @else if (ingameOmniService.currentSingleDartEvent === 'BustedScore') {
                            <span [translate]="'BUSTED_SCORE_REMOVE_DARTS'" i18n="@@BUSTED_SCORE_REMOVE_DARTS"
                                >You have bust your score, remove your darts
                            </span>
                        } @else if (ingameOmniService.currentSingleDartEvent === 'DoubleOutError') {
                            <span [translate]="'NEED_END_WITH_DOUBLE'" i18n="@@NEED_END_WITH_DOUBLE"
                                >You need to finish with a double, remove your darts
                            </span>
                        } @else if (ingameOmniService.currentSingleDartEvent === 'MasterOutError') {
                            <span [translate]="'NEED_END_WITH_DOUBLE_OR_TRIPLE'" i18n="@@NEED_END_WITH_DOUBLE_OR_TRIPLE"
                                >You need to finish with a double or triple, remove your darts
                            </span>
                        }
                    </div>
                </div>
                <div class="flex justify-between">
                    <app-primary-button
                        size="extrasmall"
                        class="w-full"
                        [addedClasses]="'px-2 w-full'"
                        [color]="'orange'"
                        (click)="playerChange()"
                        [translate]="'DARTS_ARE_REMOVED'"
                        i18n="@@DARTS_ARE_REMOVED"
                        >Darts are removed
                    </app-primary-button>
                </div>
            </div>
        }

        @if (ingameOmniService.omniViewMode === 'zoomed' || zoomedOnly()) {
            <div class="flex zoomed-points w-full mt-4">
                @for (hit of ingameOmniService.dartHits$ | async; track hit) {
                    <app-zoomed-point [point]="hit" [dartIndex]="$index"> </app-zoomed-point>
                }
            </div>
        } @else if (ingameOmniService.omniViewMode === 'board' && !zoomedOnly()) {
            <div class="flex h-full w-full p-6">
                <app-omni-dartboard #omniCoordinateDartboard class="h-full w-full"></app-omni-dartboard>
            </div>
        }
        <!-- @else if (ingameOmniService.omniViewMode === 'heatmap' && !zoomedOnly()) {
            <div class="flex h-full w-full p-6">
                <app-omni-heatmap #omniHeatmapDartboard class="h-full w-full"></app-omni-heatmap>
            </div>
        } -->
    </div>

    <!-- <div class="dartboard-container mb-6 flex justify-center items-center w-11/12 md:w-3/4 lg:w-1/2 xl:w-1/3"> -->
    <!-- </div> -->
</div>
