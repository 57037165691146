<app-page-header mainNavigation="custom" defaultBack="dashboard" color="transparent">
    <div left>
        <div class="flex items-center cursor-pointer" (click)="quit()">
            <div class="text-center text-sm font-bold !text-white" [translate]="'QUIT'" i18n="@@QUIT">Quit</div>
        </div>
    </div>
    <div title>
        <img
            class="trbl-0 absolute m-auto h-5"
            src="assets/icon/dctarget-line-orange.png"
            alt="dartcounter logo dark" />
    </div>
</app-page-header>

<div class="relative flex h-full flex-col overflow-auto text-black bg-white-pattern">
    <div class="flex w-full flex-col space-y-2 bg-white p-6" *ngIf="onlineGameplay">
        <div class="text-center text-lg font-bold text-black">
            {{ onlineFunctions.onlineGameplayName(onlineGameplay) }}
        </div>
        <div class="flex flex-wrap items-center justify-center gap-2">
            @switch (onlineGameplay.type) {
                @case ('match') {
                    <div
                        class="rounded-full bg-black px-2 text-xxxs font-bold uppercase text-white"
                        *ngIf="onlineFunctions.asMatch(onlineGameplay.game).has_checkout_rate">
                        {{ 'CHECKOUT_RATE' | translate }}
                    </div>
                    <div class="rounded-full bg-black px-2 text-xxxs font-bold uppercase text-white">
                        {{ onlineFunctions.asMatch(onlineGameplay.game).start_with | entryMode }}
                    </div>
                    <div class="rounded-full bg-black px-2 text-xxxs font-bold uppercase text-white">
                        {{ onlineFunctions.asMatch(onlineGameplay.game).end_with | finishMode }}
                    </div>
                    <div
                        class="rounded-full bg-black px-2 text-xxxs font-bold uppercase text-white"
                        *ngIf="onlineFunctions.asMatch(onlineGameplay.game).two_legs_difference"
                        [translate]="'TWO_LEGS_DIFFERENCE'"
                        i18n="@@TWO_LEGS_DIFFERENCE">
                        Two legs difference
                    </div>
                    <div
                        class="rounded-full bg-black px-2 text-xxxs font-bold uppercase text-white"
                        *ngIf="onlineFunctions.asMatch(onlineGameplay.game).enable_sudden_death"
                        [translate]="'SUDDEN_DEATH_MODE'"
                        i18n="@@SUDDEN_DEATH_MODE">
                        Sudden death mode
                    </div>
                }
                @case ('cricket_tactics') {
                    <div
                        class="rounded-full bg-black px-2 text-xxxs font-bold uppercase text-white"
                        *ngIf="onlineFunctions.asCricketTactics(onlineGameplay.game).two_legs_difference"
                        [translate]="'TWO_LEGS_DIFFERENCE'"
                        i18n="@@TWO_LEGS_DIFFERENCE">
                        Two legs difference
                    </div>
                    <div
                        class="rounded-full bg-black px-2 text-xxxs font-bold uppercase text-white"
                        *ngIf="onlineFunctions.asCricketTactics(onlineGameplay.game).enable_sudden_death"
                        [translate]="'SUDDEN_DEATH_MODE'"
                        i18n="@@SUDDEN_DEATH_MODE">
                        Sudden death mode
                    </div>
                }
            }
        </div>
    </div>

    @if (onlineGameplay) {
        <div class="flex flex-1 flex-col justify-between px-4 pt-10 pb-safe-offset-8" appWebContainer>
            <div class="flex flex-col items-center">
                <div
                    class="font-bebas-neue text-3xl text-black"
                    [translate]="'MEET_THE_PLAYERS'"
                    i18n="@@MEET_THE_PLAYERS">
                    Players, ready?
                </div>
                <div class="mt-10 w-full grid grid-cols-11 items-center overflow-hidden">
                    <div class="col-span-5 flex flex-col justify-center">
                        <app-profile-photo
                            class="block"
                            [url]="onlineGameplay.players[0].profile_photo_url"
                            [ultimate]="onlineGameplay.players[0].is_ultimate"
                            [badge]="onlineGameplay.players[0].badge"
                            [size]="72"
                            [big]="true"></app-profile-photo>
                        <div class="mt-10 flex items-center justify-center space-x-1">
                            <div class="text-lg font-bold truncate">{{ onlineGameplay.players[0].first_name }}</div>
                            <div
                                class="inline-flex h-5 w-5 items-center justify-center rounded-full bg-green"
                                *ngIf="
                                    (onlineGameplay.players[0].uid === onlineGameplay.owners[0] &&
                                        onlineGameplay.playerOneStatus === 'ready') ||
                                    (onlineGameplay.players[0].uid === onlineGameplay.owners[1] &&
                                        onlineGameplay.playerTwoStatus === 'ready')
                                ">
                                <app-icon icon="check_mark" size="small" class="!text-white"></app-icon>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-1 font-bebas-neue text-2xl text-neutral-900 text-center">VS</div>
                    <div class="col-span-5 flex flex-col justify-center">
                        <app-profile-photo
                            class="block"
                            [url]="onlineGameplay.players[1].profile_photo_url"
                            [ultimate]="onlineGameplay.players[1].is_ultimate"
                            [badge]="onlineGameplay.players[1].badge"
                            [size]="72"
                            [big]="true"></app-profile-photo>
                        <div class="mt-10 flex items-center justify-center space-x-1">
                            <div class="text-lg font-bold truncate">{{ onlineGameplay.players[1].first_name }}</div>
                            <div
                                class="inline-flex h-5 w-5 items-center justify-center rounded-full bg-green"
                                *ngIf="
                                    (onlineGameplay.players[1].uid === onlineGameplay.owners[1] &&
                                        onlineGameplay.playerTwoStatus === 'ready') ||
                                    (onlineGameplay.players[1].uid === onlineGameplay.owners[0] &&
                                        onlineGameplay.playerOneStatus === 'ready')
                                ">
                                <app-icon icon="check_mark" size="small" class="!text-white"></app-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="mt-6 flex w-full items-center bg-white bg-opacity-90 dark:bg-opacity-90 px-4 py-2"
                    [class.justify-center]="!isOwner"
                    [class.justify-between]="isOwner"
                    *ngIf="isStartingPlayer !== null">
                    <div
                        class="font-bebas-neue text-xl uppercase"
                        *ngIf="isStartingPlayer"
                        [translate]="'YOU_ARE_THE_STARTING_PLAYER'"
                        i18n="@@YOU_ARE_THE_STARTING_PLAYER">
                        You are the starting player
                    </div>
                    <div
                        class="font-bebas-neue text-xl uppercase"
                        *ngIf="!isStartingPlayer"
                        [translate]="'OPPONENT_IS_THE_STARTING_PLAYER'"
                        i18n="@@OPPONENT_IS_THE_STARTING_PLAYER">
                        Your opponent is the starting player
                    </div>
                    @if (isOwner) {
                        <div
                            class="inline-flex h-8 w-8 items-center justify-center rounded bg-blue cursor-pointer !text-white"
                            (click)="switchPlayers()">
                            <app-icon icon="compare_arrows" class="!text-white"></app-icon>
                        </div>
                    }
                </div>
                <div class="mt-6 px-8 text-center text-base text-black">
                    <span
                        *ngIf="onlineGameplay.fastScoring"
                        [translate]="'FAST_SCORING_ENABLED'"
                        i18n="@@FAST_SCORING_ENABLED">
                        Fast scoring is enabled. Every score will be submitted immediately.
                    </span>
                    <span
                        *ngIf="!onlineGameplay.fastScoring"
                        [translate]="'FAST_SCORING_DISABLED'"
                        i18n="@@FAST_SCORING_DISABLED">
                        Fast scoring is disabled. You will have at least six seconds before you get the score from your
                        opponent.
                    </span>
                </div>
            </div>

            <div class="flex flex-col items-center pt-4">
                <div class="mb-4 w-fit items-center rounded-sm bg-white px-4 py-1 text-black" *ngIf="startedTimer">
                    <div class="pt-0.5 font-bebas-neue text-2xl leading-none">{{ showTimer() }}</div>
                </div>
                <app-primary-button
                    class="w-full"
                    size="large"
                    addedClasses="w-full"
                    (click)="ready()"
                    [disabled]="is_ready">
                    <span [translate]="'START_GAME'" i18n="@@START_GAME" *ngIf="!is_ready">Start game</span>
                    <span [translate]="'WAITING_FOR_OPPONENT'" i18n="@@WAITING_FOR_OPPONENT" *ngIf="is_ready">
                        Waiting for opponent
                    </span>
                </app-primary-button>
            </div>
        </div>
    }
</div>
