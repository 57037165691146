import { Component } from '@angular/core';
import { FriendApiService } from '@dc-api/friend.api.service';
import { RatingApiService } from '@dc-api/rating.api.service';
import { UserApiService } from '@dc-api/user.api.service';
import { User } from '@dc-core/dc-backend/dc-classes';
import { ChatMessage, POST_GAME_COMMS } from '@dc-core/dc-gamelogic/in-game/ingame.globals';
import { ActionSheetController, ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AlertPayload, DartCounterAlertService } from 'dc-core/dc-services/alert.service';
import { DartCounterPreferenceService } from 'dc-core/dc-services/preference/preference.service';
import { components } from 'dc-core/dc-typings/dc-backend-definitions';
import { FIRESTORE_TABLES } from 'src/app/app.globals';
import { DCLoadingService } from 'src/providers/DCLoadingService';
import { FireStoreService } from 'src/providers/FirebaseService';
import { AuthService } from 'src/services/auth.service';

import { PromptDialogComponent } from '../prompt/prompt.dialog';

@Component({
    selector: 'app-rating-dialog',
    templateUrl: 'rating.html',
    styleUrls: ['rating.scss'],
})
export class RatingDialogComponent {
    public stars: any[];
    public selectedValue = 0;
    public user: User;
    public actionsID;
    public rtMatchID;

    localAction: any;
    actions: [] = [];
    private actionWatcher: any;
    ingameComms: ChatMessage[];
    incomingComms: any[] = [];
    showChat: boolean;
    blockLoading: boolean;
    private end = false;

    constructor(
        public view: ModalController,
        public modal2: ModalController,
        public auth: AuthService,
        private navParams: NavParams,
        private firestore: FireStoreService,
        public preferenceService: DartCounterPreferenceService,
        private sheet: ActionSheetController,
        private loading: DCLoadingService,
        private translate: TranslateService,
        private _userApiService: UserApiService,
        private _ratingApiService: RatingApiService,
        private _alertService: DartCounterAlertService,
        private _friendApiService: FriendApiService
    ) {
        this.ingameComms = POST_GAME_COMMS;
        this.stars = [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }];

        this.end = this.navParams.get('end');
        this.user = this.navParams.get('user');
        this.actionsID = this.navParams.get('actionsID');
        this.rtMatchID = this.navParams.get('rtMatchID');
        if (this.user) {
            this.Init();
        } else {
            this.DismissModal();
        }

        if (this.actionsID) {
            // const watchdog = this.firestore.getByCollectionAndID(FIRESTORE_TABLES.actions, this.actionsID);
            // this.actionWatcher = watchdog.snapshotChanges().subscribe((firebaseItem) => {
            //   const data: any = firebaseItem.payload.data();
            //   if (!data) {
            //     this.actionWatcher.unsubscribe();
            //     this.actionsID = null;
            //   }
            //   else if (this.actions && this.actions.length < data.actions.length) {
            //     this.HandleAction(data.actions);
            //     this.actions = data.actions;
            //   }
            // });
        }
    }

    HandleAction(actions: any[]) {
        const action = actions[actions.length - 1];
        const payload = action.payload;
        if (action.player.ID !== this.auth.user.id) {
            switch (action.key) {
                case 'DC_INGAMECOMMS':
                    this.ShowComms(payload, action.player);
                    break;
                case 'DC_friend_status_UPDATE':
                    this.Updatefriend_status(payload as components['schemas']['FriendStatus'], action.player);
                    break;
            }
        }
    }

    ShowComms(payload, player) {
        this.incomingComms.push({
            name: player.Name,
            text: payload.text,
        });
    }

    Updatefriend_status(payload, player) {
        if (payload.value === 'incoming') {
            $localize`:@@PERSON_WANTS_TO_ADD_YOU:wants to add you`;
            this._alertService.createAlert({
                title: player.Name + ' ' + this.translate.instant('PERSON_WANTS_TO_ADD_YOU'),
                icon: 'info',
            });
        } else if (payload.value === 'friends') {
            $localize`:@@ACCEPTED_YOUR_FRIENDREQUEST:accepted your friend request`;
            this._alertService.createAlert({
                title: player.Name + ' ' + this.translate.instant('ACCEPTED_YOUR_FRIENDREQUEST'),
                icon: 'info',
            });
        } else if (payload.value === null) {
            if (this.user.friend_status === 'pending') {
                $localize`:@@OPPONENT_DECLINED_INVITATION:{{value}} has declined the invitation`;
                this._alertService.createAlert({
                    title: this.translate.instant('OPPONENT_DECLINED_INVITATION', { 0: player.Name }),
                    icon: 'info',
                });
            } else {
                $localize`:@@REMOVED_YOU:{{value}} removed you as a friend`;
                this._alertService.createAlert({
                    title: this.translate.instant('REMOVED_YOU', { 0: player.Name }),
                    icon: 'info',
                });
            }
        }

        this.user.friend_status = payload.value;
    }

    AddComms(text) {
        $localize`:@@OPPONENT_ALREADY_LEFT:Opponent already left!`;
        if (!this.actionsID) {
            this._alertService.createAlert({
                title: this.translate.instant('OPPONENT_ALREADY_LEFT'),
                icon: 'error',
            });
            this.showChat = false;
            return false;
        }

        this.localAction = {
            player: {
                ID: this.auth.user.id,
                Name: this.auth.user.first_name,
            },
            key: 'DC_INGAMECOMMS',
            payload: {
                text,
            },
        };

        this.SaveActions();

        $localize`:@@MSG_HAS_BEEN_SENT:Message has been sent!`;
        this._alertService.createAlert({
            title: this.translate.instant('MSG_HAS_BEEN_SENT'),
        });
        this.showChat = false;
    }

    SaveActions() {
        const tempActions: any = this.actions;
        if (this.localAction) {
            tempActions.push(this.localAction);
            this.localAction = null;
        }

        let data = {
            actions: tempActions,
        };
        data = JSON.parse(JSON.stringify(data));
        this.firestore.updateWholeItem(FIRESTORE_TABLES.actions, this.actionsID, data);
    }

    Init() {
        this._ratingApiService.getRatingToUserById({ userId: this.user.id }).then((res) => {
            this.selectedValue = res.data != null ? res.data.rating : 0;
        });
    }

    public DismissModal(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }

    selectStar(star) {
        this.selectedValue = star.value;
    }

    SendRating() {
        if (!this.selectedValue) {
            return false;
        }

        if (this.actionsID) {
            this.localAction = {
                player: {
                    ID: this.auth.user.id,
                    Name: this.auth.user.first_name,
                },
                key: 'DC_RECEIVED_RATING',
                payload: {
                    value: this.selectedValue,
                },
            };
            this.SaveActions();
        }

        this.loading.ShowDefaultLoader();
        this._userApiService.rateUserById({ userId: this.user.id, rating: this.selectedValue }).finally(() => {
            this.loading.DismissLoader();
            this.DismissModal();
        });
    }

    ToggleChat() {
        this.showChat = !this.showChat;
    }

    RemoveComm(index) {
        this.incomingComms.splice(index, 1);
    }

    RemoveBackdrop() {
        if (this.showChat) {
            this.showChat = false;
        } else {
            this.incomingComms = [];
        }
    }

    tryBlock() {
        let promptDialog;

        this.modal2
            .create({
                component: PromptDialogComponent,
                componentProps: {
                    title: $localize`:@@BLOCK_PLAYER:Block player`,
                    text: $localize`:@@SURE_TO_BLOCK_PLAYER:Are you sure you want to block this player?`,
                    cancelText: $localize`:@@CANCEL:Cancel`,
                    confirmText: $localize`:@@CONFIRM:Confirm`,
                    confirmColor: 'red',
                } as AlertPayload,
                cssClass: 'auto-height',
                showBackdrop: true,
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();

                promptDialog = elem;
                promptDialog.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.block();
                    }
                });
            });
    }

    tryUnblock() {
        let promptDialog;
        this.modal2
            .create({
                component: PromptDialogComponent,
                componentProps: {
                    title: $localize`:@@UNBLOCK_PLAYER:Unblock player`,
                    text: $localize`:@@SURE_TO_UNBLOCK_PLAYER:Are you sure you want to unblock this player?`,
                    cancelText: $localize`:@@CANCEL:Cancel`,
                    confirmText: $localize`:@@CONFIRM:Confirm`,
                    confirmColor: 'orange',
                } as AlertPayload,
                cssClass: 'auto-height',
                showBackdrop: true,
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();

                promptDialog = elem;
                promptDialog.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.unblock();
                    }
                });
            });
    }

    block() {
        this.blockLoading = true;
        this._userApiService
            .blockUserById({ userId: this.user.id })
            .then(() => {
                this.user.is_blocked = true;
                this.blockLoading = false;

                $localize`:@@BLOCKED_THIS_PLAYER:You've blocked this player`;
                this._alertService.createAlert({
                    title: this.translate.instant('BLOCKED_THIS_PLAYER'),
                });
            })
            .catch(() => {
                this.blockLoading = false;
            });
    }

    unblock() {
        this.blockLoading = true;
        this._userApiService
            .unblockUserById({ userId: this.user.id })
            .then(() => {
                this.user.is_blocked = false;
                this.blockLoading = false;
            })
            .catch(() => {
                this.blockLoading = false;
            });
    }

    ionViewWillLeave() {
        if (this.actionWatcher) {
            this.actionWatcher.unsubscribe();
        }
        if (this.actionsID && this.end) {
            this.firestore.removeItem(FIRESTORE_TABLES.actions, this.actionsID);
        }
        if (this.rtMatchID && this.end) {
            this.firestore.removeItem(FIRESTORE_TABLES.matches, this.rtMatchID);
        }
    }

    AddFriend(accountID) {
        this.loading.ShowDefaultLoader();
        this._friendApiService
            .addFriendByUserId({ userId: accountID })
            .then(() => {
                this.loading.DismissLoader();

                this.localAction = {
                    player: {
                        ID: this.auth.user.id,
                        Name: this.auth.user.first_name,
                    },
                    key: 'DC_friend_status_UPDATE',
                    payload: {
                        value: 'incoming',
                    },
                };
                this.SaveActions();

                this._alertService.createAlert({
                    title: $localize`:@@FRIEND_REQUEST_SENT:Friend request has been sent`,
                    icon: 'success',
                });

                this.user.friend_status = 'pending';
            })
            .catch(() => this.loading.DismissLoader());
    }

    ShowPendingOptions(accountID) {
        $localize`:@@CANCEL_REQUEST:Cancel`;
        const actionSheet = this.sheet.create({
            backdropDismiss: true,
            buttons: [
                {
                    text: this.translate.instant('CANCEL_REQUEST'),
                    icon: 'close',
                    handler: () => {
                        this.DenyFriend(accountID);
                    },
                },
            ],
        } as any);

        actionSheet.then((elem) => elem.present());
    }

    ShowFriendOptions(accountID) {
        const actionSheet = this.sheet.create({
            backdropDismiss: true,
            buttons: [
                {
                    text: $localize`:@@REMOVE_FRIEND:Remove friend`,
                    icon: 'close',
                    handler: () => {
                        this.RemoveFriend(accountID);
                    },
                },
            ],
        } as any);

        actionSheet.then((elem) => elem.present());
    }

    DenyFriend(accountID) {
        this.loading.ShowDefaultLoader();

        this._friendApiService
            .denyFriendByUserId({ userId: accountID })
            .then(() => {
                this.loading.DismissLoader();
                this.user.friend_status = 'no_friends';
                this.localAction = {
                    player: {
                        ID: this.auth.user.id,
                        Name: this.auth.user.first_name,
                    },
                    key: 'DC_friend_status_UPDATE',
                    payload: {
                        value: null,
                    },
                };
                this.SaveActions();

                this._alertService.createAlert({
                    title: $localize`:@@REQUEST_REMOVED:Friend request has been removed`,
                    icon: 'success',
                });
            })
            .catch(() => this.loading.DismissLoader());
    }

    RemoveFriend(accountID) {
        this.loading.ShowDefaultLoader();

        this._friendApiService
            .removeFriendByUserId({ userId: accountID })
            .then(() => {
                this.loading.DismissLoader();
                this.user.friend_status = 'no_friends';
                this.localAction = {
                    player: {
                        ID: this.auth.user.id,
                        Name: this.auth.user.first_name,
                    },
                    key: 'DC_friend_status_UPDATE',
                    payload: {
                        value: null,
                    },
                };
                this.SaveActions();

                this._alertService.createAlert({
                    title: $localize`:@@REQUEST_REMOVED:Friend request has been removed`,
                    icon: 'success',
                });
            })
            .catch(() => this.loading.DismissLoader());
    }

    AcceptFriend(accountID) {
        this.loading.ShowDefaultLoader();

        this._friendApiService
            .acceptFriendByUserId({ userId: accountID })
            .then(() => {
                this.loading.DismissLoader();
                this.user.friend_status = 'friends';
                this.localAction = {
                    player: {
                        ID: this.auth.user.id,
                        Name: this.auth.user.first_name,
                    },
                    key: 'DC_friend_status_UPDATE',
                    payload: {
                        value: 'friends' as components['schemas']['FriendStatus'],
                    },
                };
                this.SaveActions();
            })
            .catch(() => this.loading.DismissLoader());
    }
}
