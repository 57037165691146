import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { AfterGameService } from '@services/after-game.service';
import { tap } from 'rxjs';

import { GamesLeaveBusterService } from '../services/games-leave-buster.service';

export const gamesOnlineLeaveBusterGuard: CanActivateFn = (route, state) => {
    const leaveBusterService: GamesLeaveBusterService = inject(GamesLeaveBusterService);
    const alertService: DartCounterAlertService = inject(DartCounterAlertService);
    const afterGameService: AfterGameService = inject(AfterGameService);

    if (leaveBusterService.isAllowedToLeaveOnlineGame()) {
        if (!leaveBusterService.gameIsFinished) {
            afterGameService.cleanupAfterGame();
        }
        return true;
    } else {
        if (leaveBusterService.isOpponentsTurn()) {
            alertService.createAlert({
                title: $localize`:@@CANT_LEAVE_RIGHT_NOW:You can't leave right now`,
                text: $localize`:@@ONLY_LEAVE_IN_YOUR_TURN:You can only leave the match when it's your turn`,
                icon: 'warning',
            });
            return false;
        }

        return leaveBusterService.startOnlineLeaveProcess().pipe(
            tap((hasLeft) => {
                if (hasLeft && !leaveBusterService.gameIsFinished) {
                    afterGameService.cleanupAfterGame();
                }
            })
        );
    }
};
