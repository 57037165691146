/* eslint-disable max-len */
import { animate, style, transition, trigger } from '@angular/animations';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { InAppBrowser, InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { UserApiService } from '@dc-api/user.api.service';
import { DartCounterPreferenceService } from '@dc-core/dc-services/preference/preference.service';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { PurchaseService, PurchaseStatus } from '@providers/PurchaseService';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { AuthService } from '@services/auth.service';
import { Subject, takeUntil } from 'rxjs';
import {
    GA_EVENTACTIONS,
    GA_EVENTCATEGORIES,
    ULTIMATE_MONTHLY_SUBSCRIPTION,
    ULTIMATE_YEARLY_SUBSCRIPTION,
} from 'src/app/app.globals';

type SlideType = 'upgrade_to_yearly';

type Slide = {
    index: number;
    type: SlideType;
    title: string;
    name: string;
    nextText: string;
};

@Component({
    selector: 'app-switch-sales-funnel',
    templateUrl: 'switch-sales-funnel.dialog.html',
    animations: [
        trigger('slideAnimation', [
            transition(':enter', [
                style({ transform: 'translateX(100%)' }),
                animate('300ms ease-out', style({ transform: 'translateX(0)' })),
            ]),
            transition(':leave', [
                style({ transform: 'translateX(0)' }),
                animate('150ms ease-out', style({ transform: 'translateX(-100%)' })),
            ]),
        ]),
    ],
})
export class SwitchSalesFunnelDialogComponent implements OnInit, OnDestroy {
    public slides: Slide[] = [
        {
            index: 0,
            type: 'upgrade_to_yearly',
            title: '',
            name: $localize`:@@CHANGE_YOUR_SUBSCRIPTION:Change your subscription`,
            nextText: $localize`:@@CONTINUE:Continue`,
        },
    ];
    public currentSlide: Slide = this.slides[0];
    public isEnd = false;
    public animationState = 'right';

    public isIOS = false;
    @Input() recurring: 'monthly' | 'yearly';

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    currentOrientation: string;

    constructor(
        public nav: NavController,
        public auth: AuthService,
        private _screenOrientation: ScreenOrientation,
        private _ngZone: NgZone,
        private _view: ModalController,
        public purchases: PurchaseService,
        private platform: Platform,
        private preferences: DartCounterPreferenceService,
        private iab: InAppBrowser,
        private ga: DartCounterAnalyticsService,
        private _userApiService: UserApiService
    ) {}

    ngOnInit(): void {
        if (this.recurring === 'yearly') {
            this.slides[0].title = $localize`:@@UPGRADE_TO_YEARLY:Upgrade to Yearly`;
        } else if (this.recurring === 'monthly') {
            this.slides[0].title = $localize`:@@UPGRADE_TO_MONTHLY:Upgrade to Monthly`;
        }

        this.purchases.status$.pipe(takeUntil(this._unsubscribeAll)).subscribe((status: PurchaseStatus) => {
            switch (status) {
                case 'close':
                    this._userApiService.getCurrentUser({}).then((res) => {
                        this.auth.saveAuthentication(res.data);
                    });
                    this.dismissModal();
                    break;
            }
        });
    }

    ionViewDidEnter(): void {
        this.checkScreenOrientation();
        this._screenOrientation.onChange().subscribe(() => {
            this._ngZone.run(() => {
                this.checkScreenOrientation();
            });
        });
    }

    checkScreenOrientation() {
        if (this._screenOrientation.type.indexOf('portrait') >= 0) {
            this.currentOrientation = 'portrait';
        } else if (this._screenOrientation.type.indexOf('landscape') >= 0) {
            this.currentOrientation = 'landscape';
        }
    }

    goBack(): void {
        this.nav.back();
    }

    public dismissModal(returnValue = null): void {
        this._view.dismiss(returnValue);
    }

    nextSlide(): void {
        if (this.currentSlide.index === this.slides[this.slides.length - 1].index) {
            return;
        }

        this.currentSlide = this.slides.find((slide) => slide.index === this.currentSlide.index + 1);
        this.animationState = 'left';
    }

    yearPricePerMonth(pricing: any): string {
        return formatCurrency(
            Math.floor(pricing.priceMicros / 10000 / 12) / 100,
            'en',
            getCurrencySymbol(pricing.currency, 'narrow')
        );
    }

    removeSpaces(str: string): string {
        if (!str) {
            return '';
        }
        return str.split(' ').join('');
    }

    purchase(): void {
        const monthly = this.recurring === 'monthly';

        const subscription = monthly ? ULTIMATE_MONTHLY_SUBSCRIPTION : ULTIMATE_YEARLY_SUBSCRIPTION;

        if (this.platform.is('ios') || this.platform.is('android')) {
            this.purchases.purchase(subscription);
        } else {
            const url = 'https://dartcounter.net/' + this.preferences.lang + '/upgrade';
            if (monthly) {
                this.ga.trackEvent(GA_EVENTCATEGORIES.ULTIMATE, GA_EVENTACTIONS.BUYMONTHLY);
            } else {
                this.ga.trackEvent(GA_EVENTCATEGORIES.ULTIMATE, GA_EVENTACTIONS.BUYYEARLY);
            }

            this.iab.create(url, '_system', {
                location: 'yes',
            } as InAppBrowserOptions);

            this.platform.resume.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
                this._userApiService.getCurrentUser({}).then((res) => {
                    this.auth.saveAuthentication(res.data);
                });
                this.dismissModal();
            });
        }
    }

    public setRecurring(value) {
        this.recurring = value;
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
