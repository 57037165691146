<app-page-header mainNavigation="custom" color="white">
    <div title [translate]="'BREAK_SETTINGS'" i18n="@@BREAK_SETTINGS">Take a short break</div>
    <app-icon icon="close" class="text-black" (click)="DismissModal()"></app-icon>
</app-page-header>

<ion-content>
    <!-- Minutes selection -->
    <ion-list>
        <ion-list-header [translate]="'SELECT_BREAK_DURATION'" i18n="@@SELECT_BREAK_DURATION"
            >How many minutes break?</ion-list-header
        >
        <ion-item>
            <ion-segment mode="md" [(ngModel)]="selectedMinutes" color="primary" style="--color: white">
                <ion-segment-button *ngFor="let minutes of minuteOptions" [value]="minutes">
                    {{ minutes }}
                </ion-segment-button>
            </ion-segment>
        </ion-item>
    </ion-list>
    <!-- End of minutes selection -->

    <div class="bottomCTA" (click)="StartPause()">
        <app-primary-button size="large" [translate]="'START_PAUSE'" i18n="@@START_PAUSE">
            Start break
        </app-primary-button>
    </div>
</ion-content>
