<!-- Translation containers-->
@if (false) {
    <ng-container i18n="@@NEW_PASSWORD">New password</ng-container>
    <ng-container i18n="@@REPEAT_PASS">Repeat password</ng-container>
}
<!-- End of translation containers-->

<modal-content class="bg-white-pattern text-black">
    <div class="profileheader">
        <app-page-header mainNavigation="custom" color="transparent">
            <div title [translate]="'MUST_CHANGE_PASSWORD'" i18n="@@MUST_CHANGE_PASSWORD">Choose a password</div>
        </app-page-header>

        <div class="bigCenterImg">
            <ion-avatar class="big" style="display: flex; justify-content: center">
                <app-profile-photo
                    [size]="104"
                    [ultimate]="auth.user.is_ultimate"
                    [badge]="auth.user.profile?.badge"
                    [big]="true"
                    [url]="auth.user.profile?.profile_photo_url">
                </app-profile-photo>
            </ion-avatar>
        </div>
    </div>

    <ion-row>
        <ion-col class="text-center">
            <p [translate]="'MUST_CHANGE_PASSWORD_INFO'" i18n="@@MUST_CHANGE_PASSWORD_INFO">
                Please change your password to continue
            </p>
        </ion-col>
    </ion-row>

    <form (ngSubmit)="SubmitNewPassword()" class="accountform space-y-3 py-4" #newPassForm="ngForm">
        <input
            type="password"
            class="dialog-input"
            placeholder="{{ 'NEW_PASSWORD' | translate }}"
            [(ngModel)]="Password" />

        <input
            type="password"
            class="dialog-input"
            placeholder="{{ 'REPEAT_PASS' | translate }}"
            [(ngModel)]="Password2" />
    </form>
</modal-content>

<ion-footer class="ion-no-border">
    <ion-toolbar class="footer" style="height: 72px">
        <div class="bottomCTA blue" style="position: relative" (click)="SubmitNewPassword()">
            <p class="text-center" style="height: 72px" [translate]="'SAVE'" i18n="@@SAVE">Save</p>
        </div>
    </ion-toolbar>
</ion-footer>
