<app-page-header mainNavigation="back" defaultBack="dashboard">
    <div title [translate]="'ACTION_REPLAYS'" i18n="@@ACTION_REPLAYS">Action Replays</div>
</app-page-header>

<div class="h-full bg-white-pattern text-black overflow-hidden pb-safe-offset-16">
    <div class="flex h-16 min-h-16 bg-white bg-opacity-80 dark:bg-opacity-80 flex-none">
        <button
            class="inline-flex basis-1/2 items-center justify-center"
            [class.inner-border-b-2-orange]="activeTab === 'all'"
            [class.opacity-60]="activeTab !== 'all'"
            (click)="changeActiveTab('all')">
            <div class="font-bebas-neue text-xl text-black" [translate]="'ALL'" i18n="@@ALL">All</div>
        </button>
        <button
            class="inline-flex basis-1/2 items-center justify-center"
            [class.inner-border-b-2-orange]="activeTab === 'favorites'"
            [class.opacity-60]="activeTab !== 'favorites'"
            (click)="changeActiveTab('favorites')">
            <div class="font-bebas-neue text-xl text-black" [translate]="'FAVOURITES'" i18n="@@FAVOURITES">
                Favourites
            </div>
        </button>
    </div>

    @if (clips === null) {
        <div class="flex justify-center pt-8">
            <ion-spinner name="crescent"></ion-spinner>
        </div>
    }

    @if (clips && !clips.length) {
        <div
            class="text-black font-bebas-neue text-xl text-center mt-4"
            [translate]="'NO_ACTION_REPLAYS'"
            i18n="@@NO_ACTION_REPLAYS">
            You do not have any Action Replays
        </div>
    } @else if (clips && clips.length) {
        @if (activeTab === 'all') {
            <div class="h-full space-y-4 p-4 overflow-y-auto">
                @if (isUltimate) {
                    <div
                        class="font-bebas-neue text-black text-xl text-center"
                        [translate]="'ALL_ACTION_REPLAYS_ULTIMATE_INFO'"
                        i18n="@@ALL_ACTION_REPLAYS_ULTIMATE_INFO">
                        View your Action Replays. Non-favourited videos will be saved for 30 days.
                    </div>
                } @else {
                    <div
                        class="font-bebas-neue text-black text-xl text-center"
                        [translate]="'ALL_ACTION_REPLAYS_NON_ULTIMATE_INFO'"
                        i18n="@@ALL_ACTION_REPLAYS_NON_ULTIMATE_INFO">
                        View your Action Replays. Non-favourited videos will be saved for 7 days.
                    </div>
                }

                <ng-container *ngTemplateOutlet="clipList"></ng-container>
            </div>
        } @else {
            <div class="h-full space-y-4 p-4 overflow-y-auto">
                <div
                    class="font-bebas-neue text-black text-xl text-center"
                    [translate]="'VIEW_YOUR_FAVORITE_ACTION_REPLAYS'"
                    i18n="@@VIEW_YOUR_FAVORITE_ACTION_REPLAYS">
                    View your Favourite Action Replays.
                </div>

                @if (!isUltimate) {
                    <app-primary-button
                        addedClasses="w-full"
                        color="blue"
                        size="normal"
                        (click)="openSubscriptionDialog()">
                        <div class="flex space-x-2 items-center">
                            <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#ultimate_ribbon_clip_path_action_replays)">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M30.5079 15.9178L27.2712 12.2168L27.7222 7.32191L22.9334 6.23416L20.4263 1.98926L15.9161 3.92599L11.4059 1.98926L8.89871 6.22089L4.10993 7.29538L4.56095 12.2035L1.32422 15.9178L4.56095 19.6188L4.10993 24.527L8.89871 25.6148L11.4059 29.8464L15.9161 27.8964L20.4263 29.8331L22.9334 25.6015L27.7222 24.5137L27.2712 19.6188L30.5079 15.9178ZM15.9161 15.9176C17.3813 15.9176 18.5691 14.7298 18.5691 13.2645C18.5691 11.7993 17.3813 10.6114 15.9161 10.6114C14.4508 10.6114 13.263 11.7993 13.263 13.2645C13.263 14.7298 14.4508 15.9176 15.9161 15.9176ZM10.611 19.8977C10.611 18.1334 14.1462 17.2446 15.9171 17.2446C17.688 17.2446 21.2232 18.1334 21.2232 19.8977V20.5609C21.2232 20.9257 20.9247 21.2242 20.5599 21.2242H11.2742C10.9094 21.2242 10.611 20.9257 10.611 20.5609V19.8977Z"
                                        fill="url(#ultimate_ribbon_linear_gradient_action_replays)" />
                                </g>
                                <defs>
                                    <linearGradient
                                        id="ultimate_ribbon_linear_gradient_action_replays"
                                        x1="15.9161"
                                        y1="1.98926"
                                        x2="15.9161"
                                        y2="29.8464"
                                        gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#ECD676" />
                                        <stop offset="0.24" stop-color="#D39E3A" />
                                        <stop offset="0.655" stop-color="#EDB948" />
                                        <stop offset="1" stop-color="#BB8B32" />
                                    </linearGradient>
                                    <clipPath id="ultimate_ribbon_clip_path_action_replays">
                                        <rect width="31.8367" height="31.8367" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <span [translate]="'UPGRADE_TO_ULTIMATE'" i18n="@@UPGRADE_TO_ULTIMATE"
                                >Upgrade to Ultimate</span
                            >
                        </div>
                    </app-primary-button>
                }

                <ng-container *ngTemplateOutlet="clipList"></ng-container>
            </div>
        }
    }
</div>

<ng-template #clipList>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4" appWebContainer>
        @for (clip of clips; track clip.id; let clipIndex = $index) {
            @if (activeTab === 'all' || (activeTab === 'favorites' && clip.favorited_at)) {
                @if (!clip.video_path) {
                    <div class="col-span-1 w-full overflow-hidden rounded-lg relative bg-black flex flex-col space-y-2">
                        <div class="flex pt-3 pr-3 pl-5 justify-between">
                            <div
                                class="!text-neutral-400 font-semibold text-lg animate-pulse"
                                [translate]="'PROCESSING'"
                                i18n="@@PROCESSING">
                                Processing
                            </div>
                            <div class="flex items-center space-x-2">
                                <div
                                    class="bg-orange flex space-x-1 items-center rounded px-1 cursor-pointer w-fit !text-white">
                                    <div class="font-bold text-xxs">{{ clip.expiresAt }}</div>
                                    <div
                                        class="font-bold text-xxs lowercase"
                                        [translate]="'DAYS_LEFT'"
                                        i18n="@@DAYS_LEFT">
                                        Days left
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex px-5 pb-3 justify-between items-center w-full rounded-b-lg">
                            <div class="space-y-1 min-w-0 flex-1">
                                <div class="!text-black font-bold text-sm truncate">
                                    {{ clip.title }}
                                </div>
                                <div class="!text-neutral-400 font-semibold text-xs">
                                    {{ clip.created_at | date: 'd MMM yyyy H:mm' : '' : translateService.currentLang }}
                                </div>
                            </div>
                            <div class="flex items-center space-x-2">
                                <app-icon
                                    [icon]="clip.favorited_at ? 'bookmark_star' : 'bookmark_star_outline'"
                                    [class]="
                                        clip.favorited_at ? 'text-green' : 'text-neutral-100 dark:text-neutral-300'
                                    "
                                    class="cursor-pointer"
                                    (click)="toggleFavorite(clip)"></app-icon>
                                <app-icon
                                    icon="more_horiz"
                                    class="text-neutral-100 dark:text-neutral-300"
                                    (click)="showClipPopover(clip, clipIndex)"></app-icon>
                            </div>
                        </div>
                    </div>
                } @else {
                    <div class="col-span-1 w-full overflow-hidden rounded-lg relative bg-black flex flex-col">
                        <div class="relative flex-1 aspect-[16/12] cursor-pointer" (click)="play(clip)">
                            <img
                                class="rounded-lg w-full h-full object-cover object-center"
                                src="{{ clip.thumbnail_url }}" />
                            <div class="absolute top-3 right-3">
                                @if (!clip.favorited_at) {
                                    <div class="bg-black flex space-x-1 items-center rounded px-1 cursor-pointer w-fit">
                                        <div class="font-bold text-[0.575rem] text-white">
                                            {{ clip.expiresAt }}
                                        </div>
                                        <div
                                            class="font-bold text-[0.575rem] text-white lowercase"
                                            [translate]="'DAYS_LEFT'"
                                            i18n="@@DAYS_LEFT">
                                            Days left
                                        </div>
                                    </div>
                                }
                            </div>
                            <div class="absolute bottom-3 right-3">
                                <div class="bg-orange flex space-x-1 items-center rounded px-1 cursor-pointer w-fit">
                                    <app-icon icon="videocam" size="small" class="!text-white"></app-icon>
                                    <div class="font-bold text-xxs !text-white">
                                        {{ clip.durationText }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="flex-none flex px-5 py-3 justify-between items-center w-full rounded-b-lg space-x-2">
                            <div class="space-y-1 min-w-0 flex-1">
                                <div class="text-white font-bold text-sm truncate">
                                    {{ clip.title }}
                                </div>
                                <div class="text-neutral-400 font-semibold text-xs">
                                    {{ clip.created_at | date: 'd MMM yyyy H:mm' : '' : translateService.currentLang }}
                                </div>
                            </div>
                            <div class="flex items-center space-x-2">
                                <app-icon
                                    [icon]="clip.favorited_at ? 'bookmark_star' : 'bookmark_star_outline'"
                                    [class]="
                                        clip.favorited_at ? 'text-green' : 'text-neutral-100 dark:text-neutral-300'
                                    "
                                    class="cursor-pointer"
                                    (click)="toggleFavorite(clip)"></app-icon>
                                <app-icon
                                    icon="more_horiz"
                                    class="text-neutral-100 dark:text-neutral-300"
                                    (click)="showClipPopover(clip, clipIndex)"></app-icon>
                            </div>
                        </div>
                    </div>
                }
            }
        }
    </div>
</ng-template>
