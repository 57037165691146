<app-page-header mainNavigation="menu">
    <div title [translate]="'NEW_GAME'" i18n="@@NEW_GAME">New game</div>
    <app-fullscreen-icon right></app-fullscreen-icon>
</app-page-header>

<div class="relative flex h-full flex-col overflow-hidden bg-white-pattern" id="main-content">
    @if (gameApiService.unfinishedLocalMatches.length) {
        <app-unfinished-local-games-dropdown class="block"></app-unfinished-local-games-dropdown>
    }

    <div class="flex flex-col h-full overflow-hidden" appWebContainer>
        <div class="flex-1 space-y-2 overflow-auto overscroll-none py-4 px-safe-offset-4" id="behind-dropdown-modal">
            <div class="grid grid-cols-1 flex-col gap-2">
                @for (game of games; track $index) {
                    @if (game.title) {
                        <div
                            class="col-span-1 inline-flex h-fit items-center justify-between rounded-md bg-neutral-900 dark:!bg-neutral-800 py-4 pl-4 pr-2 cursor-pointer hover:bg-neutral-800 dark:hover:!bg-neutral-700 duration-200"
                            (click)="openSettings(game)">
                            <div class="flex items-center space-x-2">
                                <div class="font-bebas-neue text-xl !text-white">{{ game.title }}</div>
                                @if (game.new) {
                                    <div
                                        class="rounded-md bg-yellow-icon-alert px-1 py-1 text-xs leading-none font-bold uppercase text-black-header"
                                        [translate]="'NEW'"
                                        i18n="@@NEW">
                                        New
                                    </div>
                                }
                            </div>
                            <app-icon icon="keyboard_arrow_right" class="!text-neutral-200"></app-icon>
                        </div>
                    }
                }
            </div>

            @if (banner) {
                <div class="w-full p-2" (click)="openLink(bannerURL)">
                    <img [src]="banner" />
                </div>
            }
        </div>
    </div>

    <app-bottom-nav class="w-full" active="local"></app-bottom-nav>
</div>
