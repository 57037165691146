<app-page-header mainNavigation="custom" color="transparent">
    <div title [translate]="'ENTER_YOUR_PASSWORD'" i18n="@@ENTER_YOUR_PASSWORD">Enter your password</div>
    <app-icon right icon="close" (click)="dismiss()"></app-icon>
</app-page-header>

<ion-content scrollY="false" fullscreen class="bg-white-pattern text-black">
    <ng-container>
        <ion-row>
            <ion-col class="text-center">
                <ion-label>{{ emailText }}</ion-label>
                <ion-input name="password" ngDefaultControl [(ngModel)]="password" type="password"></ion-input>
            </ion-col>
        </ion-row>
    </ng-container>
</ion-content>

<ion-footer class="ion-no-border">
    <ion-toolbar class="footer" style="height: 72px">
        <div class="bottomCTA" style="position: relative" (click)="confirm()">
            <p class="text-center" style="height: 72px" [translate]="'CONFIRM'" i18n="@@CONFIRM">Confirm</p>
        </div>
    </ion-toolbar>
</ion-footer>
