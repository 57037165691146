<app-page-header mainNavigation="custom" color="transparent">
    <div title [translate]="'THROW_FOR_BULL'" i18n="@@THROW_FOR_BULL">Throw for the bull</div>
    <div right class="right-page-header">
        <app-icon icon="close" (click)="DismissModal()"></app-icon>
    </div>
</app-page-header>

<ion-content>
    <ion-row>
        <ion-col>
            <p class="text-center" style="font-weight: semibold" [translate]="'WHO_WON_BULL'" i18n="@@WHO_WON_BULL">
                Who is closest to the Bull?
            </p>
        </ion-col>
    </ion-row>

    <ion-list class="noMargin">
        <ion-item-group>
            <ng-container *ngFor="let team of teams; let i = index">
                <ng-container *ngFor="let player of team.players">
                    <ion-item
                        (click)="SelectPlayer(player)"
                        [ngClass]="{ selected: player === selectedPlayer }"
                        class="bullPlayer noInnerMargin">
                        <ion-avatar>
                            <app-profile-photo
                                [ultimate]="player.is_ultimate"
                                [url]="player.profile?.profile_photo_url"></app-profile-photo>
                        </ion-avatar>
                        <ion-label>{{ player.name || '-' | translate }}</ion-label>
                    </ion-item>
                </ng-container>
            </ng-container>
        </ion-item-group>
    </ion-list>
</ion-content>
<ion-footer class="ion-no-border">
    <ion-toolbar class="footer" style="height: 60px">
        <div class="bottomCTA" style="position: relative" (click)="DismissModal(selectedPlayer)">
            <p class="text-center" style="height: 60px" [translate]="'CONTINUE'" i18n="@@CONTINUE">Continue</p>
        </div>
    </ion-toolbar>
</ion-footer>
