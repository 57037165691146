<div class="relative flex flex-col overflow-visible bg-white">
    <div
        class="flex w-full flex-col py-4 px-safe-offset-4 bg-white dark:bg-neutral-100"
        [ngClass]="{ 'border-b-2 border-neutral-50': !opened }">
        <div class="flex items-center justify-between cursor-pointer" (click)="opened = !opened">
            <div class="flex items-center">
                <ion-badge mode="md" class="ion-bg-yellow !ion-text-white">
                    {{ unfinishedGamesService.unfinishedGames.length }}
                </ion-badge>
                <div
                    class="ml-1.5 text-sm font-bold !text-black"
                    [translate]="'UNFINISHED_GAMES'"
                    i18n="@@UNFINISHED_GAMES">
                    Unfinished games
                </div>
            </div>
            <app-icon
                [icon]="opened ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
                class="text-neutral-200 dark:text-neutral-800"></app-icon>
        </div>
    </div>
    <div
        style="backdrop-filter: brightness(50%); -webkit-backdrop-filter: brightness(50%)"
        class="absolute left-0 right-0 top-full z-10 overflow-scroll border-t-2 border-neutral-50"
        *ngIf="opened"
        appDropdownModalHeightDirective>
        <div class="flex flex-col rounded-b-xl bg-white p-4 text-black">
            <div
                class="mb-4 text-sm font-semibold text-neutral-400"
                [translate]="'UNFINISHED_ONLINE_GAMES_INFO'"
                i18n="@@UNFINISHED_ONLINE_GAMES_INFO">
                Re-invite your opponent to continue the game where you left off, or remove it from your record!
                Unfinished games will automatically be removed after 14 days.
            </div>
            <div class="space-y-2">
                <div
                    class="flex flex-col"
                    *ngFor="let gameplay of unfinishedGamesService.unfinishedGames; let index = index">
                    <div
                        class="flex items-center justify-between rounded-t-md bg-neutral-50 dark:!bg-black-full px-4 py-2.5">
                        <div class="font-bold text-black">{{ onlineFunctions.onlineGameplayName(gameplay) }}</div>
                        <div class="text-xxs font-semibold text-neutral-900">
                            {{ unfinishedGamesService.getDaysLeft(gameplay) }}
                            <span class="lowercase" [translate]="'DAYS_LEFT'" i18n="@@DAYS_LEFT">Days left</span>
                        </div>
                    </div>
                    <div class="grid grid-cols-11 bg-white px-4 py-2 border-x-2 border-neutral-50">
                        <ng-container
                            *ngTemplateOutlet="firstPlayer; context: { user: gameplay.players[0] }"></ng-container>
                        <div class="col-span-3 flex items-center justify-center font-bebas-neue">
                            <app-match-interim-score
                                size="lobby"
                                [type]="onlineFunctions.getInterimType(gameplay)"
                                [showLabel]="onlineFunctions.getInterimHasSets(gameplay)">
                                {{ onlineFunctions.getInterim(gameplay) }}
                            </app-match-interim-score>
                        </div>
                        <ng-container
                            *ngTemplateOutlet="secondPlayer; context: { user: gameplay.players[1] }"></ng-container>
                    </div>
                    <div
                        class="flex items-center justify-between rounded-b-md border-x-2 border-b-2 border-neutral-50 px-4 py-2">
                        <app-primary-button
                            color="red"
                            size="small"
                            [translate]="'REMOVE'"
                            i18n="@@REMOVE"
                            (click)="remove(gameplay, index)">
                            Remove
                        </app-primary-button>
                        <app-primary-button
                            color="green"
                            size="small"
                            [translate]="'SAVE'"
                            i18n="@@SAVE"
                            (click)="save(gameplay, index)">
                            Save
                        </app-primary-button>
                        <app-primary-button
                            color="orange"
                            size="small"
                            [translate]="'RE_INVITE'"
                            i18n="@@RE_INVITE"
                            (click)="reinvite(gameplay)"
                            *ngIf="gameplay.onlineGameStatus !== 'removed'">
                            Re-invite
                        </app-primary-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #firstPlayer let-user="user">
    <div class="col-span-4 flex items-center justify-start space-x-2">
        <app-profile-photo
            class="h-fit"
            [ultimate]="user.is_ultimate"
            [badge]="user.badge"
            [url]="user.profile_photo_url"
            [size]="32">
        </app-profile-photo>
        <div class="space-y-0.5 overflow-hidden">
            <div class="flex items-center space-x-1">
                <div class="truncate text-xs font-bold text-black">
                    {{ user.first_name || ('NO_NAME' | translate) }}
                </div>
                <img class="h-2.5" *ngIf="user.countryFlag" src="assets/images/flags/rect/{{ user.countryFlag }}" />
            </div>
            <app-online-player-stats [player]="user"></app-online-player-stats>
        </div>
    </div>
</ng-template>

<ng-template #secondPlayer let-user="user">
    <div class="col-span-4 flex items-center justify-end space-x-2">
        <div class="space-y-0.5 overflow-hidden">
            <div class="flex items-center justify-end space-x-1">
                <div class="truncate text-xs font-bold text-black">
                    {{ user.first_name || ('NO_NAME' | translate) }}
                </div>
                <img class="h-2.5" *ngIf="user.countryFlag" src="assets/images/flags/rect/{{ user.countryFlag }}" />
            </div>
            <app-online-player-stats [player]="user"></app-online-player-stats>
        </div>
        <app-profile-photo
            class="h-fit"
            [ultimate]="user.is_ultimate"
            [badge]="user.badge"
            [url]="user.profile_photo_url"
            [size]="32">
        </app-profile-photo>
    </div>
</ng-template>
