import { Component, Input, OnInit } from '@angular/core';
import { GameStatisticsApiService } from '@dc-api/game-statistics.api.service';
import { UserApiService } from '@dc-api/user.api.service';
import { ModalController } from '@ionic/angular';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { User } from 'dc-core/dc-backend/dc-classes';
import * as moment from 'moment';
import { GA_EVENTACTIONS, GA_EVENTCATEGORIES } from 'src/app/app.globals';
import { AuthService } from 'src/services/auth.service';

import { LinkSoundDialogComponent } from '../link-sound/link-sound';

@Component({
    selector: 'app-player-options-modal',
    templateUrl: 'playerOptions.modal.html',
    styleUrls: ['playerOptions.modal.scss'],
})
export class PlayerOptionsModalComponent implements OnInit {
    @Input() user: User;
    average: any;

    constructor(
        public auth: AuthService,
        private ga: DartCounterAnalyticsService,
        private modal: ModalController,
        private modal2: ModalController,
        private _gameStatisticsApiService: GameStatisticsApiService,
        private userApiService: UserApiService
    ) {}

    ngOnInit() {
        if (this.user && this.user.id > 0) {
            this.GetAllTimeAvg(this.user.id);
        }
    }

    GetAllTimeAvg(playerID) {
        this._gameStatisticsApiService
            .getMatchThreeDartAvgStatistics({
                type: 'average',
                user_id: playerID,
                limit: 1,
                from_date: moment().subtract(2, 'month').toISOString(),
            })
            .then((res) => {
                this.average = res.data[0].value ?? 0;
            });
    }

    ShowLinkSound() {
        this.modal2
            .create({
                component: LinkSoundDialogComponent,
                componentProps: {
                    user: this.user,
                },
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data === false) {
                        this.user.profile.sound = null;
                    } else if (dialogRes.data !== null) {
                        this.AddSound(dialogRes.data);
                        this.user.profile.sound = dialogRes.data;
                        this.ga.trackEvent(GA_EVENTCATEGORIES.PLAYER_OPTIONS, GA_EVENTACTIONS.LINKSOUND);
                    }

                    if (dialogRes.data === 'dismiss') {
                        this.DismissModal();
                    }
                });
            });
    }

    AddSound(sound) {
        if (this.user.id === this.auth.user.id) {
            this.userApiService
                .updateUser({
                    profile: {
                        sound_id: sound.id,
                    },
                })
                .then(() => {
                    this.auth.user.profile.sound = sound;
                    this.auth.user.profile.sound_id = sound.id;
                    this.user = this.auth.user;
                });
        }
    }

    RemoveSound() {
        if (this.user.id === this.auth.user.id) {
            // Unlink the sound
            this.userApiService.updateUser({
                profile: {
                    sound_id: null,
                },
            });
        }

        this.user.profile.sound = null;
    }

    public DismissModal(data: User = null): void {
        if (data && !data.id) {
            data.full_name = data.first_name;
        }

        this.modal.dismiss(data);
    }
}
