<app-page-header mainNavigation="back" defaultBack="online-games">
    <div title [translate]="'FRIENDS_MODE'" i18n="@@FRIENDS_MODE">Friends lobby</div>
    <div right class="right-page-header">
        <app-control-camera-icon></app-control-camera-icon>
        @if (isWeb) {
            <app-fullscreen-icon></app-fullscreen-icon>
        }
    </div>
</app-page-header>
<ion-content fullscreen="true" class="bg-white-pattern">
    <div class="relative flex h-full flex-col">
        <div class="flex flex-auto flex-col overflow-auto overscroll-none landscape:px-safe">
            <div class="flex h-full flex-col items-center px-4 py-8 justify-between text-black" appSmallWebContainer>
                @if (online.totalFriends === null) {
                    <div class="my-4 flex justify-center">
                        <ion-spinner class="w-10 h-10" color="secondary" name="crescent"></ion-spinner>
                    </div>
                }

                @if (online.totalFriends === 0) {
                    <div class="space-y-2 px-4 h-full flex flex-col justify-center">
                        <div
                            class="text-center text-lg font-bold"
                            [translate]="'CONNTECT_WITH_OTHERS'"
                            i18n="@@CONNTECT_WITH_OTHERS">
                            Connect with others
                        </div>
                        <div
                            class="text-center text-sm font-semibold"
                            [translate]="'NO_FRIENDS_FOR_ONLINE'"
                            i18n="@@NO_FRIENDS_FOR_ONLINE">
                            Add friends and invite them for an online match!
                        </div>
                    </div>
                    <div class="w-full">
                        <app-primary-button
                            size="normal"
                            addedClasses="w-full"
                            (click)="openFriendSearch()"
                            [translate]="'ADD_FRIENDS'"
                            i18n="@@ADD_FRIENDS">
                            Add friends
                        </app-primary-button>
                    </div>
                }

                @if (online.totalFriends > 0) {
                    <div class="w-full mx-4">
                        @if (online.totalFriends) {
                            @if (online.user) {
                                <div class="mb-4 w-full">
                                    <div
                                        class="flex justify-between items-center bg-panel px-4 py-2.5 font-bebas-neue text-xl text-black"
                                        (click)="toggleTab('online-friends', online.onlineFriends?.length || 0)"
                                        [class]="activeTab === 'online-friends' ? 'rounded-t-md' : 'rounded-md'">
                                        <div class="flex items-center space-x-1">
                                            <div [translate]="'ONLINE_FRIENDS'" i18n="@@ONLINE_FRIENDS">
                                                Online friends
                                            </div>
                                            <div class="text-lg">({{ online.onlineFriends?.length || 0 }})</div>
                                        </div>
                                        @if (online.onlineFriends?.length || 0 > 0) {
                                            <app-icon
                                                [icon]="
                                                    activeTab === 'online-friends'
                                                        ? 'keyboard_arrow_up'
                                                        : 'keyboard_arrow_down'
                                                "
                                                size="large"
                                                class="text-neutral-400"></app-icon>
                                        }
                                    </div>
                                    @if (activeTab === 'online-friends') {
                                        <div class="px-4 py-3">
                                            @for (friend of online.onlineFriends; track friend) {
                                                <ng-container
                                                    *ngTemplateOutlet="
                                                        friendContainer;
                                                        context: { friend }
                                                    "></ng-container>
                                            }
                                        </div>
                                    }
                                </div>
                                <div class="my-4 w-full">
                                    <div
                                        class="flex justify-between items-center bg-panel px-4 py-2.5 font-bebas-neue text-xl text-black"
                                        (click)="toggleTab('in-game', online.inGameFriends?.length || 0)"
                                        [class]="activeTab === 'in-game' ? 'rounded-t-md' : 'rounded-md'">
                                        <div class="flex items-center space-x-1">
                                            <div [translate]="'IN_GAME'" i18n="@@IN_GAME">In-game</div>
                                            <div class="text-lg">({{ online.inGameFriends?.length || 0 }})</div>
                                        </div>
                                        @if (online.inGameFriends?.length || 0 > 0) {
                                            <app-icon
                                                [icon]="
                                                    activeTab === 'in-game'
                                                        ? 'keyboard_arrow_up'
                                                        : 'keyboard_arrow_down'
                                                "
                                                size="large"
                                                class="text-neutral-400"></app-icon>
                                        }
                                    </div>
                                    @if (activeTab === 'in-game') {
                                        <div class="px-4 py-3">
                                            @for (friend of online.inGameFriends; track friend) {
                                                <ng-container
                                                    *ngTemplateOutlet="
                                                        friendContainer;
                                                        context: { friend }
                                                    "></ng-container>
                                            }
                                        </div>
                                    }
                                </div>

                                <div class="my-4 w-full">
                                    <div
                                        class="flex justify-between items-center bg-panel px-4 py-2.5 font-bebas-neue text-xl text-black"
                                        (click)="toggleTab('offline-friends', online.offlineFriends?.length || 0)"
                                        [class]="activeTab === 'offline-friends' ? 'rounded-t-md' : 'rounded-md'">
                                        <div class="flex items-center space-x-1">
                                            <div [translate]="'OFFLINE_FRIENDS'" i18n="@@OFFLINE_FRIENDS">
                                                Offline friends
                                            </div>
                                            <div class="text-lg">({{ online.offlineFriends?.length || 0 }})</div>
                                        </div>
                                        @if (online.offlineFriends?.length || 0 > 0) {
                                            <app-icon
                                                [icon]="
                                                    activeTab === 'offline-friends'
                                                        ? 'keyboard_arrow_up'
                                                        : 'keyboard_arrow_down'
                                                "
                                                size="large"
                                                class="text-neutral-400"></app-icon>
                                        }
                                    </div>
                                    @if (activeTab) {
                                        <div class="px-4 py-3">
                                            @for (friend of online.offlineFriends; track friend) {
                                                <ng-container
                                                    *ngTemplateOutlet="
                                                        friendContainer;
                                                        context: { friend }
                                                    "></ng-container>
                                            }
                                        </div>
                                    }
                                </div>
                            }
                        }
                    </div>
                }
            </div>
        </div>
        <div class="flex-none">
            <div class="landscape:px-safe" [class.pb-safe]="!auth.user?.is_ultimate">
                <app-online-saved-gameplay
                    class="block w-full"
                    [disable]="!!dcInvites.currentOutgoingInvite"></app-online-saved-gameplay>
            </div>

            @if (!auth.user?.is_ultimate) {
                <div
                    class="flex items-center justify-between bg-neutral-50 pt-3 pb-safe-offset-3 px-safe-offset-4 cursor-pointer"
                    (click)="openUpgradeDialog()">
                    <div class="flex flex-col">
                        <div class="flex items-center space-x-1 font-bold">
                            <div class="font-bebas-neue text-2xl text-orange">{{ freeMatchesService.count }}</div>
                            <div
                                class="text-black"
                                [translate]="'FREE_GAMES_LEFT_THIS_WEEK'"
                                i18n="@@FREE_GAMES_LEFT_THIS_WEEK">
                                FREE games left this week!
                            </div>
                        </div>
                        <div class="flex flex-wrap items-center gap-1 text-sm">
                            <div class="font-semibold text-neutral-400" [translate]="'GET_VERB'" i18n="@@GET_VERB">
                                Get
                            </div>
                            <div class="font-bold uppercase text-orange" [translate]="'ULTIMATE'" i18n="@@ULTIMATE">
                                ULTIMATE
                            </div>
                            <div
                                class="font-semibold text-neutral-400"
                                [translate]="'FOR_UNLIMITED_GAMES'"
                                i18n="@@FOR_UNLIMITED_GAMES">
                                for unlimited games
                            </div>
                        </div>
                    </div>
                    <app-icon icon="keyboard_arrow_right" class="text-neutral-200"></app-icon>
                </div>
            }
        </div>
    </div>
</ion-content>

<ng-template #friendContainer let-friend="friend">
    <div class="flex justify-between">
        <div class="cursor-pointer flex items-center justify-start space-x-2 mb-3" (click)="showPlayerInfo(friend)">
            <div class="relative">
                <app-profile-photo
                    class="h-fit"
                    [ultimate]="friend?.is_ultimate"
                    [badge]="friend?.profile?.badge"
                    [url]="friend?.profile?.profile_photo_url"
                    [size]="32">
                </app-profile-photo>
            </div>
            <div class="space-y-0.5 overflow-hidden">
                <div class="flex items-center space-x-1">
                    <div class="ucfirst truncate text-xs font-bold leading-none text-black">
                        {{ friend?.full_name || ('NO_NAME' | translate) }}
                    </div>
                    <img
                        class="h-2"
                        *ngIf="friend?.countryFlag"
                        src="assets/images/flags/rect/{{ friend?.countryFlag }}" />
                </div>
            </div>
        </div>
        @if (friend?.status === 'online') {
            @if (dcInvites.currentOutgoingInvite?.receiver_uid !== friend.uid) {
                <app-primary-button size="small" (click)="invite(friend)" [translate]="'INVITE'" i18n="@@INVITE">
                    Invite
                </app-primary-button>
            }
            @if (dcInvites.currentOutgoingInvite?.receiver_uid === friend.ui) {
                <app-primary-button size="small" color="black" (click)="cancelInvite()">
                    <span>{{ dcInvites.showTimer() }}</span>
                    <app-icon icon="close"></app-icon>
                </app-primary-button>
            }
        }
    </div>
</ng-template>
