/* eslint-disable max-len */
import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GameApiService } from '@dc-api/game.api.service';
import { StatisticsApiService } from '@dc-api/statistics.api.service';
import { AlertPayload } from '@dc-core/dc-services/alert.service';
import { ModalController, NavController } from '@ionic/angular';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { Match, MatchTeam, MatchUser } from 'dc-core/dc-backend/dc-classes';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { GA_EVENTCATEGORIES } from 'src/app/app.globals';
import { DatePickerDialogComponent } from 'src/dialogs/date-picker/date-picker.dialog';
import { PromptDialogComponent } from 'src/dialogs/prompt/prompt.dialog';
import { DCLoadingService } from 'src/providers/DCLoadingService';
import { UpgradeService } from 'src/providers/UpgradeService';

@Component({
    selector: 'app-page-match-list',
    templateUrl: 'match-list.html',
    styleUrls: ['match-list.scss'],
})
export class MatchListComponent implements OnInit {
    public translateService: TranslateService = inject(TranslateService);

    public title: string;
    public items: Array<Match> = null;
    public typeID: number;
    public startDate: string;
    public endDate: string;
    public skip = 0;
    public take = 25;
    public total: number = null;

    public skipUnverified = 0;
    public itemsUnverified: Array<any> = [];
    public totalUnverified: number = null;
    public segment: 'verified' | 'unverified' = 'verified';

    constructor(
        public auth: AuthService,
        public nav: NavController,
        public loading: DCLoadingService,
        public modal: ModalController,
        private upgrade: UpgradeService,
        public statisticsApiService: StatisticsApiService,
        public route: ActivatedRoute,
        private gameApiService: GameApiService
    ) {}

    ngOnInit() {
        this.route.queryParams.pipe(take(1)).subscribe((params) => {
            this.title = params.title;
            this.typeID = params.typeID;

            this.startDate = moment().subtract(1, 'years').toISOString();
            this.endDate = moment().toISOString();

            this.RefreshLists();

            this.totalUnverified = null;
            this.GetUnverifiedItems();
        });
    }

    ionViewWillEnter(): void {
        this.route.queryParams.pipe(take(1)).subscribe((params) => {
            if (params.refresh === true) {
                this.RefreshLists();
                this.totalUnverified = null;
                this.GetUnverifiedItems();
            }
        });
    }

    changeStartDate(): void {
        this.modal
            .create({
                component: DatePickerDialogComponent,
                cssClass: 'auto-height',
                componentProps: {
                    title: $localize`:@@START_DATE:Start date`,
                    date: this.startDate,
                },
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRef) => {
                    if (dialogRef.data) {
                        this.startDate = dialogRef.data;
                        this.RefreshLists();
                    }
                });
            });
    }

    changeEndDate(): void {
        this.modal
            .create({
                component: DatePickerDialogComponent,
                cssClass: 'auto-height',
                componentProps: {
                    title: $localize`:@@END_DATE:End date`,
                    date: this.endDate,
                },
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRef) => {
                    if (dialogRef.data) {
                        this.endDate = dialogRef.data;
                        this.RefreshLists();
                    }
                });
            });
    }

    RefreshVerified(): void {
        this.items = null;
        this.skip = 0;
        this.total = null;

        this.GetItems();
    }

    RefreshLists() {
        this.items = null;
        this.skip = 0;
        this.total = null;
        this.skipUnverified = 0;

        this.GetItems();

        this.gameApiService
            .getMatches({ limit: 1, page: 1, is_verified: false })
            .then((res) => {
                this.gameApiService.unverifiedMatchesCount = res.data.total;
            })
            .catch(() => {
                this.gameApiService.unverifiedMatchesCount = 0;
            });
    }

    GetItems(event = null): Promise<any> {
        return new Promise((resolve) => {
            if (this.skip <= this.total || this.total === null) {
                this.gameApiService
                    .getMatches({
                        limit: this.auth.user.is_ultimate ? this.take : 1,
                        page: this.skip === 0 ? 1 : this.skip / this.take + 1,
                        is_verified: true,
                        from_date: this.startDate,
                        to_date: this.endDate,
                    })
                    .then((res) => {
                        if (this.items === null) {
                            this.items = [];
                        }

                        if (res.data.data.length) {
                            this.skip += this.take;
                        }

                        this.total = this.auth.user.is_ultimate ? res.data.total : res.data.total > 0 ? 1 : 0;

                        res.data.data.forEach((item) => {
                            item.started_at = moment(item.started_at).toISOString();
                            this.items.push(item);
                        });

                        if (event) {
                            event.target.complete();
                        }

                        resolve(true);
                    })
                    .catch(() => {
                        if (event) {
                            event.target.complete();
                        }

                        resolve(true);
                    });
            } else {
                if (event) {
                    event.target.complete();
                }
                resolve(true);
            }
        });
    }

    getMatchUserName(matchUser: MatchUser): string {
        let name = '';

        if (matchUser.user) {
            name = matchUser.user.first_name;
        } else {
            name = matchUser.ghost_name;
        }

        if (matchUser.is_cpu) {
            name = name + ' (' + matchUser.cpu_level + ')';
        }

        return name;
    }

    GetUnverifiedItems(event = null): Promise<any> {
        return new Promise((resolve) => {
            if (this.skipUnverified <= this.totalUnverified || this.totalUnverified === null) {
                this.gameApiService
                    .getMatches({
                        limit: this.take,
                        page: this.skipUnverified === 0 ? 1 : this.skipUnverified / this.take + 1,
                        is_verified: false,
                        from_date: this.startDate,
                        to_date: this.endDate,
                    })
                    .then((res) => {
                        if (res.data.data.length) {
                            this.skipUnverified += this.take;
                        }
                        this.totalUnverified = res.data.total;
                        if (this.totalUnverified === 0) {
                            this.segment = 'verified';
                        }

                        res.data.data.forEach((item) => {
                            item.started_at = moment(item.started_at).toISOString();
                            this.itemsUnverified.push(item);
                        });

                        if (event) {
                            event.target.complete();
                        }
                        resolve(true);
                    })
                    .catch(() => {
                        if (event) {
                            event.target.complete();
                        }
                        resolve(true);
                    });
            } else {
                if (event) {
                    event.target.complete();
                }
                resolve(true);
            }
        });
    }

    TryRemoveAll() {
        let promptDialog;
        this.modal
            .create({
                component: PromptDialogComponent,
                componentProps: {
                    title: $localize`:@@REMOVE_GAMES:Remove games`,
                    text: $localize`:@@REMOVING_UNVERIFIED_GAMES:Are you sure you want to delete all unconfirmed games from your statistics?`,
                    cancelText: $localize`:@@CANCEL:Cancel`,
                    confirmText: $localize`:@@CONFIRM:Confirm`,
                    confirmColor: 'red',
                } as AlertPayload,
                cssClass: 'auto-height',
                showBackdrop: true,
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();

                promptDialog = elem;
                promptDialog.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        const gameIds = [];
                        this.itemsUnverified.forEach((item) => {
                            gameIds.push(item.id);
                        });

                        this.loading.ShowCustomLoader({ showBackdrop: false }).then(() => {
                            this.gameApiService
                                .removeMatches({
                                    match_ids: gameIds,
                                })
                                .then(() => {
                                    this.loading.DismissLoader();
                                    this.itemsUnverified = [];
                                    if (this.itemsUnverified.length === 0) {
                                        this.segment = 'verified';
                                    }
                                    this.gameApiService.unverifiedMatchesCount = 0;
                                })
                                .catch(() => {
                                    this.loading.DismissLoader();
                                });
                        });
                    }
                });
            });
    }

    TryRemove(item, index, prompt = true) {
        if (prompt) {
            //Show Dialog, do you want to invite your opponent to continue this match?
            let promptDialog;
            this.modal
                .create({
                    component: PromptDialogComponent,
                    componentProps: {
                        title: $localize`:@@REMOVE_GAME:Remove game`,
                        text: $localize`:@@REMOVING_UNVERIFIED_GAME:Are you sure you want to delete this game from your statistics?`,
                        cancelText: $localize`:@@CANCEL:Cancel`,
                        confirmText: $localize`:@@CONFIRM:Confirm`,
                        confirmColor: 'red',
                    } as AlertPayload,
                    cssClass: 'auto-height',
                    showBackdrop: true,
                    backdropDismiss: false,
                })
                .then((elem) => {
                    elem.present();

                    promptDialog = elem;
                    promptDialog.onDidDismiss().then((dialogRes) => {
                        if (dialogRes.data) {
                            this.remove(item, index);
                        }
                    });
                });
        } else {
            this.remove(item, index);
        }
    }

    remove(item, index) {
        this.loading.ShowCustomLoader({ showBackdrop: false }).then(() => {
            this.gameApiService
                .removeMatches({
                    match_ids: [item.id],
                })
                .then(() => {
                    this.loading.DismissLoader();
                    this.itemsUnverified.splice(index, 1);
                    if (this.itemsUnverified.length === 0) {
                        this.segment = 'verified';
                    }
                    this.gameApiService.unverifiedMatchesCount--;
                })
                .catch(() => {
                    this.loading.DismissLoader();
                });
        });
    }

    TrySaveAll() {
        let promptDialog;
        this.modal
            .create({
                component: PromptDialogComponent,
                componentProps: {
                    title: $localize`:@@SAVE_GAMES:Save games`,
                    text: $localize`:@@SAVING_UNVERIFIED_GAMES:Are you sure you want to save all unconfirmed games to your statistics?`,
                    cancelText: $localize`:@@CANCEL:Cancel`,
                    confirmText: $localize`:@@CONFIRM:Confirm`,
                    confirmColor: 'orange',
                } as AlertPayload,
                cssClass: 'auto-height',
                showBackdrop: true,
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();

                promptDialog = elem;
                promptDialog.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        const matchIds = [];
                        this.itemsUnverified.forEach((item) => {
                            matchIds.push(item.id);
                        });

                        this.loading.ShowCustomLoader({ showBackdrop: false }).then(() => {
                            this.gameApiService
                                .verifyMatches({
                                    match_ids: matchIds,
                                })
                                .then(() => {
                                    this.loading.DismissLoader();
                                    this.itemsUnverified = [];
                                    if (this.itemsUnverified.length === 0) {
                                        this.segment = 'verified';
                                    }
                                    this.RefreshVerified();
                                    this.gameApiService.unverifiedMatchesCount = 0;
                                })
                                .catch(() => {
                                    this.loading.DismissLoader();
                                });
                        });
                    }
                });
            });
    }

    TrySave(item, index, prompt = true) {
        if (prompt) {
            //Show Dialog, do you want to invite your opponent to continue this match?
            let promptDialog;
            this.modal
                .create({
                    component: PromptDialogComponent,
                    componentProps: {
                        title: $localize`:@@SAVE_GAME:Save game`,
                        text: $localize`:@@SAVING_UNVERIFIED_GAME:Are you sure you want to save this game to your statistics?`,
                        cancelText: $localize`:@@CANCEL:Cancel`,
                        confirmText: $localize`:@@CONFIRM:Confirm`,
                        confirmColor: 'orange',
                    } as AlertPayload,
                    cssClass: 'auto-height',
                    showBackdrop: true,
                    backdropDismiss: false,
                })
                .then((elem) => {
                    elem.present();

                    promptDialog = elem;
                    promptDialog.onDidDismiss().then((dialogRes) => {
                        if (dialogRes.data) {
                            this.save(item, index);
                        }
                    });
                });
        } else {
            this.save(item, index);
        }
    }

    save(item, index) {
        this.loading.ShowCustomLoader({ showBackdrop: false }).then(() => {
            this.gameApiService
                .verifyMatches({
                    match_ids: [item.id],
                })
                .then(() => {
                    this.loading.DismissLoader();
                    this.itemsUnverified.splice(index, 1);
                    if (this.itemsUnverified.length === 0) {
                        this.segment = 'verified';
                    }
                    this.RefreshVerified();
                    this.gameApiService.unverifiedMatchesCount--;
                })
                .catch(() => {
                    this.loading.DismissLoader();
                });
        });
    }

    GetPlayers(team): string {
        const playerNames = team.users.map((user) => {
            return user.user ? user.user.first_name : user.ghost_name;
        });

        return playerNames.join(', ');
    }

    GetTeamTotalScore(hasSets: boolean, team: MatchTeam): number {
        if (hasSets) {
            return team.users[0].total_score_sets;
        } else {
            return team.users[0].total_score_legs;
        }
    }

    GetTeamThreeDartAverage(team): number {
        let threeDartAverage = 0;
        team.users.forEach((user) => {
            threeDartAverage += user.three_dart_average;
        });

        return threeDartAverage / team.users.length;
    }

    ShowDetail(item) {
        this.nav.navigateForward('statistics/match/details', {
            queryParams: {
                item,
                gameId: item.id,
            },
        } as NavigationOptions);
    }

    async OpenUltimateDialog() {
        const dialogSettings = await this.upgrade.GetUpgradeDialog(GA_EVENTCATEGORIES.STATISTICS);
        dialogSettings.componentProps.slidesOrder = [3];
        this.modal.create(dialogSettings).then((elem) => {
            elem.present();
        });
    }
}
