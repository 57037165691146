/* eslint-disable max-len */
import { CommonModule } from '@angular/common';
import { Component, inject, input, InputSignal } from '@angular/core';
import { OmniCommand, OmniCommunicationService } from '@dc-core/dc-services/omni/omni-communication.service';
import { CartesianCoordinate, OmniIngameService } from '@dc-core/dc-services/omni/omni-ingame.service';
import { ModalController } from '@ionic/angular';
import { PrimaryButtonComponent } from 'src/app/primary-button/primary-button.component';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';
import { OmniSettingsDialogComponent } from 'src/dialogs/omni-settings/omni-settings.dialog';
import { environment } from 'src/environments/environment';

import { ListSwitchComponent } from '../../app/list-switch/list-switch.component';
import { OmniDartBoardComponent } from './omni-dartboard.component';
import { ZoomedPointComponent } from './zoomed-point.component';

@Component({
    selector: 'app-omni-throws',
    standalone: true,
    templateUrl: './omni-throws.component.html',
    styleUrls: ['./omni-throws.component.scss'],
    imports: [
        PrimaryButtonComponent,
        IconComponent,
        OmniDartBoardComponent,
        ListSwitchComponent,
        ZoomedPointComponent,
        CommonModule,
    ],
})
export class OmniThrowsComponent {
    public zoomedOnly: InputSignal<boolean> = input(false);
    public coordinates: CartesianCoordinate[] = [];

    public ingameOmniService: OmniIngameService = inject(OmniIngameService);

    private _modal: ModalController = inject(ModalController);
    private _omniCommunicationService: OmniCommunicationService = inject(OmniCommunicationService);

    constructor() {}

    playerChange() {
        this._omniCommunicationService.sendMessage({ command: OmniCommand.ForcePlayerChange });
    }

    openOmniSettings() {
        this._modal
            .create({
                component: OmniSettingsDialogComponent,
                backdropDismiss: true,
                componentProps: {},
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
            })
            .then((elem) => {
                elem.present();
            });
    }
}
