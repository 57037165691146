<modal-content scrollY="false" fullscreen>
    <div class="auto-height-dialog">
        <app-icon icon="close" class="text-black close-button" (click)="dismiss()"></app-icon>
        <div class="title-container">
            <div class="title" i18n="@@TRANSITION_TO_USER" [translate]="'TRANSITION_TO_USER'">Transition to user</div>
            <div
                class="subtitle"
                i18n="@@TRANSITION_TO_COMPLETE_YOUR_ACCOUNT"
                [translate]="'TRANSITION_TO_COMPLETE_YOUR_ACCOUNT'">
                Transition your guest account to complete your account
            </div>
        </div>
        <div class="mt-8 space-y-4">
            <div class="w-full space-y-2">
                <div class="dialog-input-label" [translate]="'EMAIL'" i18n="@@EMAIL">Email</div>
                <input type="text" class="dialog-input" [(ngModel)]="email" />
            </div>

            <app-checkbox
                class="block"
                [model]="newsletterOptIn"
                color="white"
                (itemChange)="newsletterOptIn = !newsletterOptIn">
                <div class="flex text-black" [translate]="'NEWSLETTER_OPT_IN_INFO'" i18n="@@NEWSLETTER_OPT_IN_INFO">
                    Yes, I want to hear about exclusive offers, announcements and the newest products from Target
                    DartCounter, as well as those tailored to my account and activity.
                </div>
            </app-checkbox>

            <div class="w-full space-y-2">
                <div class="dialog-input-label" [translate]="'PASSWORD'" i18n="@@PASSWORD">Password</div>
                <input type="password" class="dialog-input" [(ngModel)]="password" />
            </div>
        </div>
        <div class="button-container">
            <app-primary-button
                size="normal"
                addedClasses="w-full"
                color="orange"
                (click)="submit()"
                [translate]="'TRANSITION'"
                i18n="@@TRANSITION">
                Transition
            </app-primary-button>
        </div>
    </div>
</modal-content>
