import { Component, inject, OnInit } from '@angular/core';
import { UserApiService } from '@dc-api/user.api.service';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { AuthService } from 'src/services/auth.service';

import { DatePickerDialogComponent } from '../date-picker/date-picker.dialog';

@Component({
    selector: 'app-birthdate-dialog',
    templateUrl: 'birthdate.dialog.html',
})
export class BirthdateDialogComponent implements OnInit {
    public translateService: TranslateService = inject(TranslateService);

    birthdate: string = null;

    constructor(
        public view: ModalController,
        public auth: AuthService,
        public modal: ModalController,
        public _modal: ModalController,
        private _userApiService: UserApiService
    ) {}

    ngOnInit(): void {
        if (this.auth.user.profile?.birthdate) {
            this.birthdate = moment(this.auth.user.profile.birthdate).utc().toISOString();
        } else {
            this.changeBirthdate();
        }
    }

    changeBirthdate(): void {
        this._modal
            .create({
                component: DatePickerDialogComponent,
                cssClass: 'auto-height',
                componentProps: {
                    title: $localize`:@@DATE_OF_BIRTH:Date of birth`,
                    date: this.birthdate,
                    maxDate: moment().format('YYYY-MM-DD'),
                },
                showBackdrop: true,
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRef) => {
                    if (dialogRef.data) {
                        this.birthdate = dialogRef.data;
                    }
                });
            });
    }

    save() {
        this._userApiService
            .updateUser({
                profile: {
                    birthdate: this.birthdate ?? null,
                },
            })
            .then(() => {
                this.auth.user = {
                    ...this.auth.user,
                    profile: { ...this.auth.user.profile, birthdate: this.birthdate },
                };

                this.dismiss();
            })
            .catch(() => {
                this.dismiss();
            });
    }

    public dismiss(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }
}
