import { Component, DestroyRef, inject, input, InputSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Camera } from '@capacitor/camera';
import { ActionsLogicService } from '@dc-core/dc-firestore/actions.service';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { JanusRoom } from '@dc-core/dc-services/camera/camera.models';
import { ModalController, Platform, PopoverController } from '@ionic/angular';
import { PopoverService } from '@services/popover.service';
import { take } from 'rxjs';
import { UserService } from 'src/app/modules/user/services/user.service';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';
import { InputFocusManagerService } from 'src/app/shared/services/input-focus-manager.service';
import { ActivateCameraDialogComponent } from 'src/dialogs/activate-camera/activate-camera.dialog';
import {
    LiveCameraPreviewDialogComponent,
    LiveCameraPreviewDialogPayload,
} from 'src/dialogs/live-camera-preview/live-camera-preview.dialog';
import { environment } from 'src/environments/environment';
import {
    CAMERA_POPOVER_RESPONSE,
    CameraOptionsPopoverComponent,
} from 'src/pages/online/cam-options-popover/camera-options-popover.component';

@Component({
    selector: 'app-games-online-own-camera',
    standalone: true,
    imports: [IconComponent],
    templateUrl: './games-online-own-camera.component.html',
})
export class GamesOnlineOwnCameraComponent {
    public authenticatedUserId: InputSignal<number> = input.required<number>();

    private platform: Platform = inject(Platform);
    private alertService: DartCounterAlertService = inject(DartCounterAlertService);
    private popoverController: PopoverController = inject(PopoverController);
    private popoverService: PopoverService = inject(PopoverService);
    private modalController: ModalController = inject(ModalController);
    private actionsLogicService: ActionsLogicService = inject(ActionsLogicService);
    private userService: UserService = inject(UserService);
    private inputFocusManagerService: InputFocusManagerService = inject(InputFocusManagerService);

    private linkCameraInstance: Promise<HTMLIonModalElement> = null;
    private destroyRef: DestroyRef = inject(DestroyRef);

    public showPersonalCameraOptions(event: Event, authenticatedUserId: number): void {
        this.userService
            .canUseSocialFeatures()
            .pipe(takeUntilDestroyed(this.destroyRef), take(1))
            .subscribe((valid: boolean) => {
                if (valid) {
                    this.popoverController
                        .create({
                            event: event,
                            component: CameraOptionsPopoverComponent,
                            componentProps: {
                                userId: authenticatedUserId,
                                currentUserId: authenticatedUserId,
                            },
                        })
                        .then((popover) => {
                            popover.present();
                            this.popoverService.init(popover);
                            popover.onDidDismiss().then((res) => {
                                this.popoverService.destroy();

                                const result = res.data as CAMERA_POPOVER_RESPONSE;
                                switch (result) {
                                    case 'activate_camera':
                                        this.activateCamera(authenticatedUserId);
                                        break;
                                    case 'view_camera':
                                        this.viewCamera(authenticatedUserId);
                                        break;
                                    default:
                                        this.inputFocusManagerService.triggerFocusInput();
                                }
                            });
                        });
                }
            });
    }

    private async activateCamera(authenticatedUserId: number): Promise<void> {
        if (!this.linkCameraInstance) {
            try {
                if (this.platform.is('capacitor')) {
                    const camResult = await Camera.requestPermissions({ permissions: ['camera'] });
                    if (camResult.camera === 'denied') {
                        throw 'No permission';
                    }
                }
            } catch (_) {
                this.alertService.createAlert({
                    title: $localize`:@@ENABLE_CAM_APP_PERMS:Please enable camera permissions in your device settings.`,
                    icon: 'error',
                    timer: 2000,
                    timerProgressBar: true,
                    showCloseButton: true,
                });
                return;
            }

            this.linkCameraInstance = this.modalController.create({
                component: ActivateCameraDialogComponent,
                backdropDismiss: false,
                showBackdrop: true,
                cssClass: !environment.isWeb ? 'ion-fullscreen-modal' : '',
            });

            this.linkCameraInstance.then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    this.linkCameraInstance = null;
                    this.inputFocusManagerService.triggerFocusInput();

                    const camera: JanusRoom = dialogRes.data;
                    if (camera) {
                        this.actionsLogicService.sendCameraAction({ sender: authenticatedUserId, janusRoom: camera });
                        this.alertService.createAlert({
                            icon: 'success',
                            title: $localize`:@@CAMERA_IS_ACTIVATED:Your camera is activated for online games!`,
                        });
                    }
                });
            });
        }
    }

    private viewCamera(userId: number): void {
        this.modalController
            .create({
                component: LiveCameraPreviewDialogComponent,
                componentProps: {
                    userId: userId,
                } as LiveCameraPreviewDialogPayload,
                cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then(() => this.inputFocusManagerService.triggerFocusInput());
            });
    }
}
