/* eslint-disable max-len */
import { AfterViewInit, Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SmartDeviceApiService } from '@dc-api/smart-device.api.service';
import { SmartDeviceNetwork, User } from '@dc-core/dc-backend/dc-classes';
import { AlertPayload, DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import {
    ConnectionStatus,
    DartBoardInfo,
    LogEntry,
    LogType,
    OmniCalibrateCommand,
    OmniCommand,
    OmniCommunicationService,
} from '@dc-core/dc-services/omni/omni-communication.service';
import { ModalController, NavController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PopoverService } from '@services/popover.service';
import { SmartDeviceService } from '@services/smart-device.service';
import _ from 'lodash';
import { Subject, Subscription, take, takeUntil } from 'rxjs';
import { OmniKeyboardCommand } from 'src/app/omni-keyboard/omni-keyboard.component';
import { PromptDialogComponent } from 'src/dialogs/prompt/prompt.dialog';
import { RenameDialogComponent, RenameDialogPayload } from 'src/dialogs/rename/rename.dialog';
import { SearchPlayerDialogComponent } from 'src/dialogs/search-player/search-player.component';
import { ScrollToBottomDirective } from 'src/directives/scroll-to-bottom.directive';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/services/auth.service';

import {
    ShutdownPopoverComponent,
    SMARTDEVICE_SHUTDOWN_RESPONSE,
} from './omni-shutdown-popover/omni-shutdown-popover.component';

export type SmartDeviceView = 'omni' | 'debug' | 'more';

@Component({
    selector: 'app-omni',
    styleUrls: ['omni.component.scss'],
    templateUrl: 'omni.component.html',
})
export class OmniComponent implements OnInit, AfterViewInit, OnDestroy {
    public translateService: TranslateService = inject(TranslateService);

    private popoverService: PopoverService = inject(PopoverService);

    smartDeviceNetwork: SmartDeviceNetwork;

    view: SmartDeviceView = 'omni';

    isAdmin: boolean;
    public isCalibrating: boolean = false;
    networkOnlyAccess: boolean;

    showTimestamps = true;

    @ViewChild(ScrollToBottomDirective, { static: false }) scrollToBottomDirective;

    logs: LogEntry[] = [];
    logTypes = LogType;
    customCommand = '';
    connectionFailureCheck = false;

    dartboardOptions: {
        label: string;
        value: DartBoardInfo;
    }[] = [];

    selectedBoard: DartBoardInfo = null;

    public omniCalibrationSections = [
        {
            label: 'Top Left',
            isLoading: false,
            calibrated: false,
            has_errors: false,
            imageUrl: 'omni-top-left.jpg',
        },
        {
            label: 'Top Right',
            isLoading: false,
            calibrated: false,
            has_errors: false,
            imageUrl: 'omni-top-right.jpg',
        },
        {
            label: 'Bottom Left',
            isLoading: false,
            calibrated: false,
            has_errors: false,
            imageUrl: 'omni-bottom-left.jpg',
        },
        {
            label: 'Bottom Right',
            isLoading: false,
            calibrated: false,
            has_errors: false,
            imageUrl: 'omni-bottom-right.jpg',
        },
    ];

    private _unsubscribeAll: Subject<void> = new Subject<void>();
    private _unsubscribeStatusWatcher: Subject<void> = new Subject<void>();
    private _connectionStatusSubscription: Subscription;
    public environment = environment;

    public mode = null;

    constructor(
        public auth: AuthService,
        public smartDeviceService: SmartDeviceService,
        public omni: OmniCommunicationService,
        public modal: ModalController,
        private _popoverController: PopoverController,
        private _nav: NavController,
        private _route: ActivatedRoute,
        private _alertService: DartCounterAlertService,
        private _smartDeviceApiService: SmartDeviceApiService
    ) {}

    ngOnInit(): void {
        this.omni.disconnect();
        this.omni.statusSubject.next(ConnectionStatus.CONNECTING);
        this.omni.connect();

        const defaultBoard = {
            label: 'Default',
            value: {
                bullSize: 7.0,
                sbullSize: 16.7,
                innerTrebleSize: 96.5,
                outerTrebleSize: 106.5,
                innerDoubleSize: 160,
                outerDoubleSize: 170,
                boardSize: 223,
            },
        };

        const proTour = {
            label: 'Target Pro Tour',
            value: {
                bullSize: 7.0,
                sbullSize: 16.7,
                innerTrebleSize: 97,
                outerTrebleSize: 107,
                innerDoubleSize: 160,
                outerDoubleSize: 170,
                boardSize: 223,
            },
        };

        const WinmauOne80 = {
            label: 'Winmau / One80',
            value: {
                bullSize: 7.0,
                sbullSize: 16.7,
                innerTrebleSize: 97.5,
                outerTrebleSize: 107.5,
                innerDoubleSize: 160,
                outerDoubleSize: 170,
                boardSize: 223,
            },
        };

        const KotoKingPro = {
            label: 'Koto King Pro',
            value: {
                bullSize: 7.0,
                sbullSize: 16.7,
                innerTrebleSize: 96,
                outerTrebleSize: 106,
                innerDoubleSize: 160,
                outerDoubleSize: 170,
                boardSize: 223,
            },
        };

        this.dartboardOptions.push(defaultBoard);
        this.dartboardOptions.push(proTour);
        this.dartboardOptions.push(WinmauOne80);
        this.dartboardOptions.push(KotoKingPro);

        this.selectedBoard = this.dartboardOptions[0].value;
    }

    ngAfterViewInit(): void {
        this._route.params.pipe(take(1)).subscribe(async (params) => {
            if (params.id) {
                if (!this.omni.smartDevice) {
                    this.goBack();
                }

                this._smartDeviceApiService.smartDeviceNetworks.forEach((network) => {
                    network.devices.forEach((device) => {
                        if (device.id == params.id) {
                            this.smartDeviceNetwork = network;

                            this.isAdmin = network.pivot?.is_admin || device.pivot?.is_admin;
                            this.networkOnlyAccess = network.pivot && !device.pivot;

                            this.watchDeviceConnectionStatus();
                        }
                    });
                });

                if (params.mode === 'calibrate') {
                    this.startCalibration(true);
                } else if (!this.smartDeviceNetwork || !this.omni.smartDevice) {
                    this.goBack();
                }
            } else {
                this.goBack();
            }
        });
    }

    goBack(): void {
        this._nav.back();
    }

    showCalibImages(camIndex) {
        const img_basepath = `${this.omni.smartDevice.ip_address}:8080/images`;
        const frontview = `${img_basepath}/img_calib_frontview_${camIndex}.png`;
        const calibration_preview = `${img_basepath}/img_calib_preview_${camIndex}.png`;

        console.log(frontview, calibration_preview);
    }

    showShutdownPopover() {
        this._popoverController
            .create({
                event,
                component: ShutdownPopoverComponent,
            })
            .then((popover) => {
                popover.present();
                this.popoverService.init(popover);
                popover.onDidDismiss().then((res) => {
                    this.popoverService.destroy();

                    const result = res.data as SMARTDEVICE_SHUTDOWN_RESPONSE;
                    switch (result) {
                        case 'reboot':
                            this.omni.reboot();
                            break;
                        case 'shutdown':
                            this.omni.shutdown();
                            break;
                    }
                });
            });
    }

    startCalibration(initial = false) {
        if (!this.isCalibrating) {
            let calibrationText = $localize`:@@SURE_TO_CALIBRATE:Are you sure you want to calibrate? Make sure the darts are removed from the board.`;
            if (initial) {
                calibrationText = $localize`:@@INITIAL_OMNI_CALIBRATION:We're going to start an initial calibration. Make sure the darts are removed from the board.`;
            }

            this._alertService.createAlert({
                title: $localize`:@@CALIBRATE_OMNI:Calibrate the Omni Scoring`,
                text: calibrationText,
                icon: 'info',
                timer: null,
                showCloseButton: true,
                confirmButtonText: $localize`:@@CONFIRM:Confirm`,
                onConfirm: () => {
                    this.isCalibrating = true;

                    const calibrateCommand: OmniCalibrateCommand = {
                        command: OmniCommand.Calibrate,
                        saveToArchive: this.omni.debugSettings.saveToArchive,
                        boardInfo: this.selectedBoard,
                        isManual: false,
                    };

                    this.omniCalibrationSections.forEach((omniCalibSection) => {
                        omniCalibSection.isLoading = true;
                    });

                    setTimeout(() => {
                        this.omni.startCalibration(calibrateCommand);
                    }, 2000);

                    const sub2 = this.omni.camStatus$.pipe(takeUntil(this._unsubscribeAll)).subscribe((camStatus) => {
                        const the_camera = this.omniCalibrationSections[camStatus.camNumber - 1];
                        the_camera.isLoading = false;
                        the_camera.calibrated = true;
                        the_camera.has_errors = camStatus.errors.length > 0;
                    });

                    const sub = this.omni.calibrationReports$
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe((report) => {
                            this.isCalibrating = false;

                            if (report.cam1 != undefined) {
                                const cam1 = this.omniCalibrationSections[0];
                                if (cam1.isLoading) {
                                    cam1.isLoading = false;
                                    cam1.calibrated = true;
                                    cam1.has_errors = !report.cam1;
                                }
                            }

                            if (report.cam2 != undefined) {
                                const cam2 = this.omniCalibrationSections[1];
                                if (cam2.isLoading) {
                                    cam2.isLoading = false;
                                    cam2.calibrated = true;
                                    cam2.has_errors = !report.cam2;
                                }
                            }

                            if (report.cam3 != undefined) {
                                const cam3 = this.omniCalibrationSections[3];
                                if (cam3.isLoading) {
                                    cam3.isLoading = false;
                                    cam3.calibrated = true;
                                    cam3.has_errors = !report.cam3;
                                }
                            }

                            if (report.cam4 != undefined) {
                                const cam4 = this.omniCalibrationSections[2];
                                if (cam4.isLoading) {
                                    cam4.isLoading = false;
                                    cam4.calibrated = true;
                                    cam4.has_errors = !report.cam4;
                                }
                            }

                            sub2.unsubscribe();
                            sub.unsubscribe();
                        });

                    // setTimeout(() => {
                    //     this._alertService.createAlert({
                    //         id: 'CALIBRATING',
                    //         title: $localize`:@@CALIBRATING_DOTS:Calibrating...`,
                    //         text: $localize`:@@OMNI_DONT_THROW_DARTS:Please wait, don't throw any darts while calibrating.`,
                    //         icon: 'loading',
                    //         timer: null,
                    //         showCloseButton: true,
                    //         allowOutsideClick: false,
                    //     });
                    // }, 500);
                },
            });
        }
    }

    watchDeviceConnectionStatus(): void {
        if (this._connectionStatusSubscription) {
            this._connectionStatusSubscription.unsubscribe();
        }

        this._connectionStatusSubscription = this.omni.connectionStatus$.subscribe((status) => {
            console.log('OMNI STATUS UPDATE:', status);

            if (status === ConnectionStatus.CONNECTED) {
                this._alertService.clearAlerts();
                this.connectionFailureCheck = false;
            } else if (status === ConnectionStatus.DISCONNECTED && !this.connectionFailureCheck) {
                this.connectionFailureCheck = true;
                this.resetOmniCalibrationSections();

                this._alertService.createAlert({
                    title: $localize`:@@NO_DEVICE_FOUND:No device found`,
                    text: this.translateService.instant('IS_YOUR_DEVICE_TURNED_ON_Q', {
                        device: this.omni.smartDevice.name,
                    }),
                    cancelButtonText: $localize`:@@CANCEL:Cancel`,
                    confirmButtonText: $localize`:@@RETRY:Retry`,
                    icon: 'warning',
                    timer: null,
                    onConfirm: () => {
                        this._alertService.createAlert({
                            title: this.translateService.instant('SEARCHING_FOR_DEVICE_IN_YOUR_NETWORK', {
                                device: this.omni.smartDevice.name,
                            }),
                            icon: 'info',
                            timer: 10000,
                            timerProgressBar: true,
                        });

                        this.smartDeviceService
                            .checkForIPChange(this.omni.smartDevice)
                            .then(() => {
                                this._alertService.createAlert({
                                    title: this.translateService.instant('DEVICE_HAS_RECONNECTED', {
                                        device: this.omni.smartDevice.name,
                                    }),
                                    icon: 'success',
                                    timer: 3000,
                                });

                                setTimeout(() => {
                                    this.connectionFailureCheck = false;
                                    this.omni.connect();
                                }, 2000);
                            })
                            .catch(() => {
                                this.connectionFailureCheck = false;
                            });
                    },
                    onCancel: () => {
                        // Not turned on? Manual disconnect
                        this.omni.disconnect();
                        this.connectionFailureCheck = false;
                    },
                });
            }
        });

        this.omni.logs$.pipe(takeUntil(this._unsubscribeAll)).subscribe((logEntries) => {
            this.logs = logEntries;
            if (this.scrollToBottomDirective) {
                setTimeout(() => {
                    this.scrollToBottomDirective.scrollToBottom();
                }, 100);
            }
        });
    }

    resetOmniCalibrationSections() {
        this.omniCalibrationSections.forEach((omniCalibSection) => {
            omniCalibSection.isLoading = false;
            omniCalibSection.calibrated = false;
        });

        this.isCalibrating = false;
    }

    rename(): void {
        if (!this.isAdmin) {
            return;
        }

        this.modal
            .create({
                component: RenameDialogComponent,
                backdropDismiss: true,
                componentProps: {
                    title: $localize`:@@RENAME_YOUR_DEVICE:Rename your device`,
                    renamable: _.cloneDeep(this.omni.smartDevice.name),
                } as RenameDialogPayload,
                cssClass: 'auto-height',
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.omni.smartDevice.name = dialogRes.data;
                        this._smartDeviceApiService
                            .updateSmartDeviceById({
                                smartDeviceId: this.omni.smartDevice.id,
                                name: this.omni.smartDevice.name,
                            })
                            .finally(() => {
                                this._alertService.createAlert({
                                    title: $localize`:@@RENAMED:Renamed`,
                                    icon: 'success',
                                });
                            });
                    }
                });
            });
    }

    invitePlayer(): void {
        this.modal
            .create({
                component: SearchPlayerDialogComponent,
                componentProps: {},
                cssClass: 'auto-height',
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    const user: User = dialogRes.data;
                    if (user) {
                        this._smartDeviceApiService
                            .inviteUserToSmartDeviceNetwork({
                                smartDeviceNetworkId: this.smartDeviceNetwork.id,
                                user_id: user.id,
                                device_id: this.omni.smartDevice?.id,
                                has_full_access: this.omni.smartDevice ? false : true,
                                is_admin: false,
                                expires_at: null,
                            })
                            .then(() => {
                                this._alertService.createAlert({
                                    title: $localize`:@@PLAYER_INVITED:Player invited`,
                                    icon: 'success',
                                });
                            })
                            .catch((err) => this._alertService.errorFromApi(err));
                    }
                });
            });
    }

    removeDevice(): void {
        this.modal
            .create({
                component: PromptDialogComponent,
                componentProps: {
                    title: $localize`:@@DELETE_DEVICE:Remove device`,
                    text: $localize`:@@DELETING_DEVICE:Do you want to remove this device?`,
                    cancelText: $localize`:@@CANCEL:Cancel`,
                    confirmText: $localize`:@@CONFIRM:Confirm`,
                    confirmColor: 'red',
                } as AlertPayload,
                cssClass: 'auto-height',
                showBackdrop: true,
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this._smartDeviceApiService
                            .deleteSmartDeviceById({ smartDeviceId: this.omni.smartDevice.id })
                            .then(() => {
                                this.goBack();
                            });
                    }
                });
            });
    }

    sendCommand(cmd: OmniKeyboardCommand) {
        this.omni.sendMessage(cmd.command);
    }

    sendCustomCommand(): void {
        if (this.customCommand.trim()) {
            // Check if the command is not just white spaces
            this.omni.sendCustomMessage(this.customCommand);
            this.customCommand = ''; // Clear the input after sending
        }
    }

    clearLog() {
        this.omni.clearLog();
    }

    toggleTimestamps() {
        this.showTimestamps = !this.showTimestamps;
    }

    // OpenMenu() {
    //     this.menu.open('mainmenu');
    // }

    getStatusText(): string {
        switch (this.omni.statusSubject.value) {
            case ConnectionStatus.CONNECTED:
                return 'Connected';
            case ConnectionStatus.CONNECTING:
                return 'Connecting';
            case ConnectionStatus.NONE:
            case ConnectionStatus.DISCONNECTED:
                return 'Not Connected';
            default:
                return '';
        }
    }

    getStatusClass(): string {
        switch (this.omni.statusSubject.value) {
            case ConnectionStatus.CONNECTED:
                return 'connected';
            case ConnectionStatus.CONNECTING:
                return 'connecting';
            case ConnectionStatus.NONE:
            case ConnectionStatus.DISCONNECTED:
                return 'not-connected';
            default:
                return '';
        }
    }

    disconnect() {
        if (this._connectionStatusSubscription) {
            this._connectionStatusSubscription.unsubscribe();
        }

        this.omni.disconnect();
    }

    async ngOnDestroy(): Promise<void> {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();

        if (this._connectionStatusSubscription) {
            this._connectionStatusSubscription.unsubscribe();
        }
    }
}
