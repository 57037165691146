import { Component, OnInit } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { GA_EVENTACTIONS, GA_EVENTCATEGORIES } from 'src/app/app.globals';

/**
 * Generated class for the Games page.
 *
 * See http://ionicframework.com/docs/components/#navigation for more info
 * on Ionic pages and navigation.
 */

@Component({
    selector: 'app-page-pre-online',
    templateUrl: 'preOnline.html',
    styleUrls: ['preOnline.scss'],
})
export class PreOnlinePageComponent implements OnInit {
    constructor(
        private ga: DartCounterAnalyticsService,
        private nav: NavController,
        private platform: Platform
    ) {}

    ngOnInit() {
        this.ga.trackView('Pre Online - Choose Lobby');
    }

    OpenFriendsLobby() {
        this.ga.trackEvent(GA_EVENTCATEGORIES.PREONLINE, GA_EVENTACTIONS.OPENONLINE);
        this.nav.navigateForward('friends-lobby');
    }

    OpenGlobalLobby() {
        this.ga.trackEvent(GA_EVENTCATEGORIES.PREONLINE, GA_EVENTACTIONS.OPENGLOBAL);
        this.nav.navigateForward('/online-games/global-lobby');
    }
}
