import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-login-account-warning-dialog',
  templateUrl: 'login-account-warning.html',
  styleUrls: ['login-account-warning.scss'],
})
export class LoginAccountWarningDialogComponent {
  constructor(public view: ModalController, public _modal: ModalController) {}

  public DismissModal(returnValue: any = null): void {
    this.view.dismiss(returnValue);
  }
}
