import { Component, OnInit } from '@angular/core';
import { InviteCollectionService } from '@dc-core/dc-firestore/collection-helpers/invite.collection.service';
import { LobbySettingsCollectionService } from '@dc-core/dc-firestore/collection-helpers/lobby_settings.collection.service';
import { FireStoreAuthService } from '@dc-core/dc-firestore/firestore-auth.service';
import { UnfinishedGamesService } from '@dc-core/dc-firestore/unfinished-games.service';
import { OnlineFunctions } from '@dc-core/dc-gamelogic/online-game/online.functions';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { JanusVideoRoomService } from '@dc-core/dc-services/dc-janus/janus-video-room.service';
import { FreeMatchesService } from '@dc-core/dc-services/free-matches.service';
import { OmniIngameService } from '@dc-core/dc-services/omni/omni-ingame.service';
import { OnlineGamesService } from '@dc-core/dc-services/online-games.service';
import { MenuController, ModalController, NavController } from '@ionic/angular';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { AuthService } from '@services/auth.service';
import { LocalCameraService } from '@services/local-camera.service';
import { ThemeService } from '@services/theme.service';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { GA_EVENTACTIONS, GA_EVENTCATEGORIES } from 'src/app/app.globals';
import { UserService } from 'src/app/modules/user/services/user.service';
import { environment } from 'src/environments/environment';

export enum SmartDeviceStatus {
    OWNED = 'OWNED',
    ACTIVATED = 'ACTIVATED',
}
@Component({
    selector: 'app-online-games',
    templateUrl: 'online-games.component.html',
})
export class OnlineGamesComponent implements OnInit {
    public isWeb = environment.isWeb;

    public skeletonThemes = {
        info: {
            'border-radius': '5px',
            height: '25px',
            width: '33%',
            'background-color': 'rgb(76 76 76)',
        },
        info2: {
            'border-radius': '5px',
            height: '22px',
            width: '50%',
            'background-color': 'rgb(76 76 76)',
        },
        stats: {
            'border-radius': '5px',
            height: '50px',
            width: '50%',
            'background-color': 'rgb(76 76 76)',
        },
        profilepic: {
            border: '10px solid #2d2d2d',
            'border-radius': '50%',
            height: '135px',
            width: '135px',
            margin: '0 auto',
            'background-color': 'rgb(76 76 76)',
        },
    };

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        public themeService: ThemeService,
        public online: OnlineGamesService,
        public onlineFunctions: OnlineFunctions,
        public auth: AuthService,
        public unfinishedGamesService: UnfinishedGamesService,
        public videoRoomService: JanusVideoRoomService,
        private ga: DartCounterAnalyticsService,
        private _menu: MenuController,
        private nav: NavController,
        private _modal: ModalController,
        private _dcInvites: InviteCollectionService,
        private _alertService: DartCounterAlertService,
        private _dcFireAuth: FireStoreAuthService,
        private _lobbySettingsService: LobbySettingsCollectionService,
        private _freeMatchesService: FreeMatchesService,
        private _localCameraService: LocalCameraService,
        private userService: UserService,
        public omniIngameService: OmniIngameService
    ) {}

    ionViewWillEnter() {
        this.online.start(this.auth.user);

        this._menu.enable(true, 'mainmenu');
        this._menu.enable(false, 'ingamemenu');

        this._localCameraService.checkPublishingVideo();
    }

    ngOnInit() {
        this.ga.trackView('Online games');
    }

    challengeFriend() {
        this.ga.trackEvent(GA_EVENTCATEGORIES.PREONLINE, GA_EVENTACTIONS.CHALLENGEFRIENDS);
        this.nav.navigateForward('friends-lobby');
    }

    goGlobalMode(segment: 'lobby' | 'live' = 'lobby') {
        this.nav.navigateRoot('online-games/global-lobby', {
            queryParams: {
                segment,
            },
            animated: true,
        });
    }

    toggleMenu(menu = 'mainmenu') {
        this._menu.open(menu);
    }

    addToLobby(): void {
        this.ga.trackEvent(GA_EVENTCATEGORIES.PREONLINE, GA_EVENTACTIONS.ADDTOLOBBY);
        if (!this._freeMatchesService.canPlay) {
            this._alertService.createAlert({
                icon: 'warning',
                title: $localize`:@@NO_MORE_FREE_GAMES_THIS_WEEK:Your free online games for this week have been used`,
            });
        } else if (_.find(this.online.lobbyGames, (game) => game.players[0].uid == this._dcFireAuth.getCurrentUID())) {
            this._alertService.createAlert({
                icon: 'warning',
                title: $localize`:@@ALREADY_HAVE_GAME_IN_LOBBY:You already have a game in the lobby`,
            });
        } else {
            this.online.setLobbyGame();
        }
    }

    removeFromLobby(): void {
        this.ga.trackEvent(GA_EVENTCATEGORIES.PREONLINE, GA_EVENTACTIONS.REMOVEFROMLOBBY);
        this._lobbySettingsService.removeOwnItems();
        this._dcInvites.removeIncomingInvites();
    }

    toggleOwnGame(): void {
        this.userService.checkSuspension('online', true).then((suspension) => {
            if (!suspension.suspended) {
                if (this._lobbySettingsService.currentGameInLobby) {
                    this.removeFromLobby();
                } else {
                    if (this.online.onlineSavedGameplay) {
                        this.addToLobby();
                        this.goGlobalMode('lobby');
                    } else {
                        this.online.addGameAfterCreate = true;
                        this.online.redirectToLobbyAfterAdding = true;
                        this.createGame();
                    }
                }
            }
        });
    }

    createGame() {
        this.ga.trackEvent(GA_EVENTCATEGORIES.PREONLINE, GA_EVENTACTIONS.OPENONLINESETUP);
        this.nav.navigateForward('online-setup');
    }

    ionViewWillLeave(): void {
        this.online.stop();
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
