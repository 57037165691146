import { OmniViewMode } from '@dc-core/dc-services/omni/omni-ingame.service';
import {
    AmountOfHits,
    AroundTheClockSettings,
    AroundTheClockThrowout,
    Bobs27Difficulty,
    CricketTacticsSettings,
    DoublesTrainingSettings,
    EntryMode,
    FinishMode,
    NumberSegment,
    RangeMode,
    ShanghaiSettings,
    SinglesTrainingSettings,
    TrainingMode,
    WinningType,
} from 'dc-core/dc-backend/dc-enums';

export type PlayerType = 'players' | 'teams';
export type StartWith = '301' | '501' | '701' | 'custom';
export type BestOfFirstTo = 'best_of' | 'first_to';
export type SetOrLeg = 'sets' | 'legs';
export type BullOrBullseye = 'bull' | 'bullseye';
export type Field = 'single' | 'double' | 'triple';

export const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';

// THE SUPPORTED OMNIVIEWS IN DARTCOUNTER
export const OMNIVIEW_ZOOMED: OmniViewMode = {
    label: `Zoomed view`,
    tag: null,
    type: 'zoomed',
};

export const OMNIVIEW_BOARD: OmniViewMode = {
    label: `Coordinates`,
    tag: null,
    type: 'board',
};

export const OMNIVIEW_HEATMAP: OmniViewMode = {
    label: `Heatmap`,
    tag: null,
    type: 'heatmap',
};

// THE SUPPORTED DEFAULT PLAYER/TEAM MODES IN DARTCOUNTER
export const PLAYERMODE: { label: string; tag: string; type: PlayerType } = {
    label: $localize`:@@SINGLE_PLAYERS:Single players`,
    tag: 'SINGLE_PLAYERS',
    type: 'players',
};

export const TEAMMODE: { label: string; tag: string; type: PlayerType } = {
    label: $localize`:@@TEAMS:Teams`,
    tag: 'TEAMS',
    type: 'teams',
};

// THE SUPPORTED DEFAULT 'STARTSCORE' MODES IN DARTCOUNTER
export const STARTSCORE_301: { label: string; tag: string; type: StartWith } = {
    label: '301',
    tag: null,
    type: '301',
};

export const STARTSCORE_501: { label: string; tag: string; type: StartWith } = {
    label: '501',
    tag: null,
    type: '501',
};

export const STARTSCORE_701: { label: string; tag: string; type: StartWith } = {
    label: '701',
    tag: null,
    type: '701',
};

export const STARTSCORE_CUSTOM: { label: string; tag: string; type: StartWith } = {
    label: $localize`:@@CUSTOM:Custom`,
    tag: 'CUSTOM',
    type: 'custom',
};

// THE SUPPORTED GOAL MODES IN DARTCOUNTER
export const BESTOFMODE: { label: string; tag: string; type: BestOfFirstTo } = {
    label: $localize`:@@BEST_OF:Best of`,
    tag: 'BEST_OF',
    type: 'best_of',
};

export const FIRSTTOMODE: { label: string; tag: string; type: BestOfFirstTo } = {
    label: $localize`:@@FIRST_TO:First to`,
    tag: 'FIRST_TO',
    type: 'first_to',
};

// THE SUPPORTED SETS/LEGS MODES IN DARTCOUNTER
export const SETSMODE: { label: string; tag: string; type: SetOrLeg } = {
    label: $localize`:@@SETS:Sets`,
    tag: 'SETS',
    type: 'sets',
};

export const LEGMODE: { label: string; tag: string; type: SetOrLeg } = {
    label: $localize`:@@LEGS:Legs`,
    tag: 'LEGS',
    type: 'legs',
};

// THE SUPPORTED ENTRYMODES IN DARTCOUNTER
export const ENTRYMODE_STRAIGHT: { label: string; tag: string; type: EntryMode } = {
    label: $localize`:@@STRAIGHT_IN:Straight in`,
    tag: 'STRAIGHT_IN',
    type: 'straight_in',
};

export const ENTRYMODE_DOUBLE: { label: string; tag: string; type: EntryMode } = {
    label: $localize`:@@DOUBLE_IN:Double in`,
    tag: 'DOUBLE_IN',
    type: 'double_in',
};

export const ENTRYMODE_MASTER: { label: string; tag: string; type: EntryMode } = {
    label: $localize`:@@MASTER_IN:Master in`,
    tag: 'MASTER_IN',
    type: 'master_in',
};

// THE SUPPORTED FINISHMODES IN DARTCOUNTER
export const FINISHMODE_DOUBLE: { label: string; tag: string; type: FinishMode } = {
    label: $localize`:@@DOUBLE_OUT:Double out`,
    tag: 'DOUBLE_OUT',
    type: 'double_out',
};

export const FINISHMODE_MASTER: { label: string; tag: string; type: FinishMode } = {
    label: $localize`:@@MASTER_OUT:Master out`,
    tag: 'MASTER_OUT',
    type: 'master_out',
};

export const FINISHMODE_STRAIGHT: { label: string; tag: string; type: FinishMode } = {
    label: $localize`:@@STRAIGHT_OUT:Straight out`,
    tag: 'STRAIGHT_OUT',
    type: 'straight_out',
};

// THE SUPPORTED THROW_ORDERS IN DARTCOUNTER
export const THROW_LOW_TO_HIGH: {
    label: string;
    tag: string;
    type: SinglesTrainingSettings | DoublesTrainingSettings | AroundTheClockSettings;
} = {
    label: $localize`:@@LOW_TO_HIGH:Low to high`,
    tag: 'LOW_TO_HIGH',
    type: 'low_to_high',
};

export const THROW_HIGH_TO_LOW: { label: string; tag: string; type: SinglesTrainingSettings | AroundTheClockSettings } =
    {
        label: $localize`:@@HIGH_TO_LOW:High to low`,
        tag: 'HIGH_TO_LOW',
        type: 'high_to_low',
    };

export const TRHOW_RANDOM: { label: string; tag: string; type: SinglesTrainingSettings | AroundTheClockSettings } = {
    label: $localize`:@@RANDOM:Random`,
    tag: 'RANDOM',
    type: 'random',
};

export const DIFFICULTY_EASY: { label: string; tag: string; type: Bobs27Difficulty } = {
    label: $localize`:@@EASY:Easy`,
    tag: 'EASY',
    type: 'easy',
};

export const DIFFICULTY_HARD: { label: string; tag: string; type: Bobs27Difficulty } = {
    label: $localize`:@@HARD:Hard`,
    tag: 'HARD',
    type: 'hard',
};

export const CRICKET_MODE: { label: string; tag: string; type: CricketTacticsSettings } = {
    label: $localize`:@@CRICKET:Cricket`,
    tag: 'CRICKET',
    type: 'cricket',
};

export const TACTICS_MODE: { label: string; tag: string; type: CricketTacticsSettings } = {
    label: $localize`:@@TACTICS:Tactics`,
    tag: 'TACTICS',
    type: 'tactics',
};

export const TRAINING_MODES: { label: string; tag: string; type: TrainingMode }[] = [
    {
        label: $localize`:@@TRAINING:Training`,
        tag: 'TRAINING',
        type: 'training',
    },
    {
        label: $localize`:@@VERSUS:Versus`,
        tag: 'VERSUS',
        type: 'versus',
    },
];

export interface SetsLegsIndexFilter {
    label: string;
    tag: string;
    type: SetsLegsFilterOption;
}

export interface SetsLegsFilterOption {
    setIndex: number | null; // `null` means "All sets"
    legIndex: number | null; // `null` means "All legs" or unspecified
}

export const ALL: { label: string; tag: string; type: NumberSegment } = {
    label: $localize`:@@ANY:Any`,
    tag: 'ANY',
    type: 'all',
};

export const SINGLE: { label: string; tag: string; type: NumberSegment } = {
    label: $localize`:@@SINGLE:Single`,
    tag: 'SINGLE',
    type: 'single',
};

export const DOUBLE: { label: string; tag: string; type: NumberSegment } = {
    label: $localize`:@@DOUBLE:Double`,
    tag: 'DOUBLE',
    type: 'double',
};

export const TRIPLE: { label: string; tag: string; type: NumberSegment } = {
    label: $localize`:@@TRIPLE:Triple`,
    tag: 'TRIPLE',
    type: 'triple',
};

export const ONCE: { label: string; tag: string; type: AmountOfHits } = {
    label: $localize`:@@ONE:One`,
    tag: 'ONE',
    type: 'once',
};

export const TWICE: { label: string; tag: string; type: AmountOfHits } = {
    label: $localize`:@@TWO:Two`,
    tag: 'TWO',
    type: 'twice',
};

export const THRICE: { label: string; tag: string; type: AmountOfHits } = {
    label: $localize`:@@THREE:Three`,
    tag: 'THREE',
    type: 'thrice',
};

export const REQUIRE_NUMBER: { label: string; tag: string; type: boolean }[] = [
    {
        label: $localize`:@@REQUIRE_NUMBER:Require number`,
        tag: 'REQUIRE_NUMBER',
        type: true,
    },
    {
        label: $localize`:@@NOT_REQUIRE_NUMBER:Don't require number`,
        tag: 'NOT_REQUIRE_NUMBER',
        type: false,
    },
];

export const REQUIRE_TRIPLE: { label: string; tag: string; type: boolean }[] = [
    {
        label: $localize`:@@REQUIRE_TRIPLE:Require triple`,
        tag: 'REQUIRE_TRIPLE',
        type: true,
    },
    {
        label: $localize`:@@NOT_REQUIRE_NUMBER:Don't require triple`,
        tag: 'NOT_REQUIRE_NUMBER',
        type: false,
    },
];

export const RANGE_ONE_TO_SEVEN: { label: string; tag: string; type: RangeMode } = {
    label: $localize`:@@ONE_TO_SEVEN:One to seven`,
    tag: 'ONE_TO_SEVEN',
    type: 'one_to_seven',
};

export const RANGE_ONE_TO_TWENTY: { label: string; tag: string; type: RangeMode } = {
    label: $localize`:@@ONE_TO_TWENTY:One to twenty`,
    tag: 'ONE_TO_TWENTY',
    type: 'one_to_twenty',
};

export const WINNING_TYPE_SCORE: { label: string; tag: string; type: WinningType } = {
    label: $localize`:@@SCORE:Score`,
    tag: 'SCORE',
    type: 'score',
};

export const WINNING_TYPE_THROW: { label: string; tag: string; type: WinningType } = {
    label: $localize`:@@THROW:Throw`,
    tag: 'THROW',
    type: 'throw',
};

export const STATISTICS_BEST: { label: string; tag: string; type: string } = {
    label: $localize`:@@BEST:Best`,
    tag: 'BEST',
    type: 'best',
};

export const STATISTICS_WORST: { label: string; tag: string; type: string } = {
    label: $localize`:@@WORST:Worst`,
    tag: 'WORST',
    type: 'worst',
};

export const THROW_LOW_TO_HIGH_SHANGHAI: { label: string; tag: string; type: ShanghaiSettings } = {
    label: $localize`:@@LOW_TO_HIGH:Low to high`,
    tag: 'LOW_TO_HIGH',
    type: 'low_to_high',
};

export const BULLSEYEMODE_BULL: { label: string; tag: string; type: AroundTheClockThrowout } = {
    label: $localize`:@@SINGLE_BULL:Single bull`,
    tag: 'SINGLE_BULL',
    type: 'bull',
};

export const BULLSEYEMODE_BULLSEYE: { label: string; tag: string; type: AroundTheClockThrowout } = {
    label: $localize`:@@BULLSEYE:Bullseye`,
    tag: 'BULLSEYE',
    type: 'bullseye',
};

export const SUBSCRIPTIONS: { label: string; tag: string; type: string } = {
    label: $localize`:@@SUBSCRIPTIONS:Subscriptions`,
    tag: 'SUBSCRIPTIONS',
    type: 'subscriptions',
};

export const TRANSACTIONS: { label: string; tag: string; type: string } = {
    label: $localize`:@@TRANSACTIONS:Transactions`,
    tag: 'TRANSACTIONS',
    type: 'transactions',
};
