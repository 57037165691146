<modal-content fullscreen="true" class="bg-white-pattern text-black">
    <div class="result-header">
        <div class="title-container" style="width: 65%; margin: 1.15rem auto 0 auto">
            <img
                src="assets/icon/dctarget-line-orange.png"
                [ngClass]="{ 'animate__animated animate__fadeIn': animate }"
                *ngIf="!animate || (animate && slideIn[0])" />
            <img
                style="width: 60%; margin: 1.25rem auto 0 auto"
                src="assets/images/recap/{{ year }}_recap_orange.png"
                [ngClass]="{ 'animate__animated animate__fadeIn': animate }"
                *ngIf="!animate || (animate && slideIn[0])" />
        </div>
        <ion-icon
            style="position: absolute; top: 0.25rem; right: 0.25rem"
            class="share cursor-pointer"
            name="share-social-outline"
            (click)="share()"></ion-icon>
    </div>

    <div class="result-container">
        <div class="cpt_stats_wrapper">
            <div class="stats_bottom one flex-col" style="height: 350px">
                <div class="stats-container">
                    <div class="stat-item">
                        <ion-spinner
                            style="height: 44px; margin-left: auto"
                            name="dots"
                            color="light"
                            *ngIf="!recap && !animate"></ion-spinner>
                        <div
                            class="cpt_stat-no-m"
                            [ngClass]="{ 'animate__animated animate__fadeInLeft': animate }"
                            *ngIf="recap && (!animate || (animate && slideIn[1]))">
                            {{ recap.darts_thrown }}
                        </div>
                        <div
                            class="cpt_stat-no-m label"
                            [ngClass]="{ 'animate__animated animate__fadeInRight': animate }"
                            *ngIf="!animate || (animate && slideIn[1])"
                            [translate]="'DARTS_THROWN'"
                            i18n="@@DARTS_THROWN">
                            Darts thrown
                        </div>
                    </div>
                    <div class="stat-item">
                        <ion-spinner
                            style="height: 44px; margin-left: auto"
                            name="dots"
                            color="light"
                            *ngIf="!recap && !animate"></ion-spinner>
                        <div
                            class="cpt_stat-no-m"
                            [ngClass]="{ 'animate__animated animate__fadeInLeft': animate }"
                            *ngIf="recap && (!animate || (animate && slideIn[2]))">
                            {{ recap.matches_played }}
                        </div>
                        <div
                            class="cpt_stat-no-m label"
                            [ngClass]="{ 'animate__animated animate__fadeInRight': animate }"
                            *ngIf="!animate || (animate && slideIn[2])"
                            [translate]="'MATCHES_PLAYED'"
                            i18n="@@MATCHES_PLAYED">
                            Matches played
                        </div>
                    </div>
                    <div class="stat-item">
                        <ion-spinner
                            style="height: 44px; margin-left: auto"
                            name="dots"
                            color="light"
                            *ngIf="!recap && !animate"></ion-spinner>
                        <div
                            class="cpt_stat-no-m"
                            [ngClass]="{ 'animate__animated animate__fadeInLeft': animate }"
                            *ngIf="recap && (!animate || (animate && slideIn[3]))">
                            {{ recap.three_dart_average | number: '1.2-2' }}
                        </div>
                        <div
                            class="cpt_stat-no-m label"
                            [ngClass]="{ 'animate__animated animate__fadeInRight': animate }"
                            *ngIf="!animate || (animate && slideIn[3])"
                            [translate]="'THREE_DART_AVERAGE'"
                            i18n="@@THREE_DART_AVERAGE">
                            Three dart average
                        </div>
                    </div>
                    <div class="stat-item">
                        <ion-spinner
                            style="height: 44px; margin-left: auto"
                            name="dots"
                            color="light"
                            *ngIf="!recap && !animate"></ion-spinner>
                        <div
                            class="cpt_stat-no-m"
                            [ngClass]="{ 'animate__animated animate__fadeInLeft': animate }"
                            *ngIf="recap && (!animate || (animate && slideIn[4]))">
                            {{ recap.best_average | number: '1.2-2' }}
                        </div>
                        <div
                            class="cpt_stat-no-m label"
                            [ngClass]="{ 'animate__animated animate__fadeInRight': animate }"
                            *ngIf="!animate || (animate && slideIn[4])"
                            [translate]="'BEST_AVERAGE'"
                            i18n="@@BEST_AVERAGE">
                            Best average
                        </div>
                    </div>
                    <div class="stat-item">
                        <ion-spinner
                            style="height: 44px; margin-left: auto"
                            name="dots"
                            color="light"
                            *ngIf="!recap && !animate"></ion-spinner>
                        <div
                            class="cpt_stat-no-m"
                            [ngClass]="{ 'animate__animated animate__fadeInLeft': animate }"
                            *ngIf="recap && (!animate || (animate && slideIn[5]))">
                            {{ recap.best_leg }}
                            <span class="added-text" [translate]="'DARTS'" i18n="@@DARTS">Darts</span>
                        </div>
                        <div
                            class="cpt_stat-no-m label"
                            [ngClass]="{ 'animate__animated animate__fadeInRight': animate }"
                            *ngIf="!animate || (animate && slideIn[5])"
                            [translate]="'BEST_LEG'"
                            i18n="@@BEST_LEG">
                            Best leg
                        </div>
                    </div>
                    <div class="stat-item">
                        <ion-spinner
                            style="height: 44px; margin-left: auto"
                            name="dots"
                            color="light"
                            *ngIf="!recap && !animate"></ion-spinner>
                        <div
                            class="cpt_stat-no-m"
                            [ngClass]="{ 'animate__animated animate__fadeInLeft': animate }"
                            *ngIf="recap && (!animate || (animate && slideIn[6]))">
                            {{ recap.highest_finish }}
                        </div>
                        <div
                            class="cpt_stat-no-m label"
                            [ngClass]="{ 'animate__animated animate__fadeInRight': animate }"
                            *ngIf="!animate || (animate && slideIn[6])"
                            [translate]="'HIGHEST_FINISH'"
                            i18n="@@HIGHEST_FINISH">
                            Highest finish
                        </div>
                    </div>
                    <div class="stat-item">
                        <ion-spinner
                            style="height: 44px; margin-left: auto"
                            name="dots"
                            color="light"
                            *ngIf="!recap && !animate"></ion-spinner>
                        <div
                            class="cpt_stat-no-m"
                            [ngClass]="{ 'animate__animated animate__fadeInLeft': animate }"
                            *ngIf="recap && (!animate || (animate && slideIn[7]))">
                            {{ recap.highest_score }}
                        </div>
                        <div
                            class="cpt_stat-no-m label"
                            [ngClass]="{ 'animate__animated animate__fadeInRight': animate }"
                            *ngIf="!animate || (animate && slideIn[7])"
                            [translate]="'HIGHEST_SCORE'"
                            i18n="@@HIGHEST_SCORE">
                            Highest score
                        </div>
                    </div>
                    <div class="stat-item">
                        <ion-spinner
                            style="height: 44px; margin-left: auto"
                            name="dots"
                            color="light"
                            *ngIf="!recap && !animate"></ion-spinner>
                        <div
                            class="cpt_stat-no-m"
                            [ngClass]="{ 'animate__animated animate__fadeInLeft': animate }"
                            *ngIf="recap && (!animate || (animate && slideIn[8]))">
                            {{ recap.total_180s }}
                        </div>
                        <div
                            class="cpt_stat-no-m label"
                            [ngClass]="{ 'animate__animated animate__fadeInRight': animate }"
                            *ngIf="!animate || (animate && slideIn[8])"
                            style="text-transform: none">
                            180s
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="result-footer" style="height: 74px">
        <ion-button
            [ngClass]="{ 'animate__animated animate__fadeIn': animate }"
            class="extra-btn"
            color="light"
            expand="block"
            fill="outline"
            (click)="refresh()"
            [translate]="'REFRESH'"
            i18n="@@REFRESH"
            *ngIf="!animate || (animate && slideIn[9])">
            Refresh
        </ion-button>
        <ion-button
            [ngClass]="{ 'animate__animated animate__fadeIn': animate }"
            class="extra-btn"
            color="light"
            expand="block"
            fill="outline"
            (click)="dismissModal()"
            [translate]="'CLOSE'"
            i18n="@@CLOSE"
            *ngIf="!animate || (animate && slideIn[9])">
            Close
        </ion-button>
    </div>
</modal-content>
