/* eslint-disable max-len */
import { Component, EventEmitter, Output } from '@angular/core';
import { GameStatisticsApiService } from '@dc-api/game-statistics.api.service';
import {
    CricketTacticsOverallStats,
    CricketTacticsStatDetails,
} from '@dc-core/dc-statistics/cricket-tactics/cricket-tactics-overall-stats';
import {
    FilterChangedFilter,
    OverallStatDetails,
    StatDetailSegment,
    StatDetailType,
} from '@dc-core/dc-statistics/game-statistics.classes';
import { DropdownSelectItem, StatisticsRange } from '@dc-core/dc-statistics/statistics.globals';
import { ModalController, NavController } from '@ionic/angular';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { UpgradeService } from '@providers/UpgradeService';
import { AuthService } from '@services/auth.service';
import { GA_EVENTACTIONS, GA_EVENTCATEGORIES } from 'src/app/app.globals';

@Component({
    selector: 'app-cricket-tactics-table',
    templateUrl: 'cricket-tactics-table.component.html',
    styleUrls: ['cricket-tactics-table.component.scss'],
})
export class CricketTacticsTableComponent extends CricketTacticsOverallStats {
    @Output() dateChangeEvent = new EventEmitter<void>();

    public periodOptions: DropdownSelectItem[] = [
        {
            label: $localize`:@@ALL_TIME:All-time`,
            value: 'all_time',
            ultimateOnly: true,
        },
        {
            label: $localize`:@@THIS_MONTH:This month`,
            value: 'this_month',
            ultimateOnly: false,
        },
        {
            label: $localize`:@@THIS_YEAR:This year`,
            value: 'this_year',
            ultimateOnly: false,
        },
        {
            label: $localize`:@@CUSTOM:Custom`,
            value: 'custom',
            ultimateOnly: true,
        },
    ];

    public gameSettingOptions: DropdownSelectItem[] = [
        {
            label: $localize`:@@ALL:All`,
            value: 'all',
            ultimateOnly: false,
        },
        {
            label: $localize`:@@CRICKET:Cricket`,
            value: 'cricket',
            ultimateOnly: false,
        },
        {
            label: $localize`:@@TACTICS:Tactics`,
            value: 'tactics',
            ultimateOnly: false,
        },
        {
            label: $localize`:@@CRICKET_CUT_THROAT:Cricket (cut throat)`,
            value: 'cricket_cut_throat',
            ultimateOnly: false,
        },
        {
            label: $localize`:@@TACTICS_CUT_THROAT:Tactics (cut throat)`,
            value: 'tactics_cut_throat',
            ultimateOnly: false,
        },
    ];

    constructor(
        public nav: NavController,
        public auth: AuthService,
        private ga: DartCounterAnalyticsService,
        public gameStatisticsApiService: GameStatisticsApiService,
        public modal: ModalController,
        private upgrade: UpgradeService
    ) {
        super(auth.user, gameStatisticsApiService);
        this.ga.trackEvent(GA_EVENTCATEGORIES.STATISTICS, GA_EVENTACTIONS.OPENCRICKETSTATSTABLE);

        this.setFilters(); // Set previous filters if any
        this.loadOverallStats();
    }

    changeFilter(filter: FilterChangedFilter, value: any): void {
        this.ga.trackEvent(GA_EVENTCATEGORIES.STATISTICS, GA_EVENTACTIONS.CHANGECRICKETFILTER);
        switch (filter) {
            // Overall
            case 'statisticsRange':
                this.updateStatisticsRange(value);
                this.dateChangeEvent.emit();
                break;

            // Game specific
            case 'gameSetting':
                this.gameSetting = value;
                break;
        }

        this.loadOverallStats();
    }

    changeStatisticsRange(statisticsRange: StatisticsRange): void {
        super.updateStatisticsRange(statisticsRange, false);
        this.loadOverallStats();
    }

    goToDetails(statsType: StatDetailType, segment: StatDetailSegment) {
        let title = '';
        switch (statsType) {
            case 'marksPerRound':
                title = $localize`:@@MARKS_PER_ROUND:Marks per round`;
                break;
            case 'totalScore':
                title = $localize`:@@TOTAL_SCORE:Total score`;
                break;
        }

        this.ga.trackEvent(GA_EVENTCATEGORIES.STATISTICS, 'View top 25 ' + segment + ' ' + statsType);

        this.nav.navigateForward('statistics/details', {
            queryParams: {
                // Overall
                title,
                statsType: statsType,
                segment: segment,
                fromDate: this.fromDate,
                untilDate: this.untilDate,
                // Game mode specific
                gameMode: 'cricket_tactics',
                gameSpecific: {
                    gameSetting: this.gameSetting,
                } as CricketTacticsStatDetails,
            } as OverallStatDetails,
        } as NavigationOptions);
    }

    async OpenUltimateDialog() {
        const dialogSettings = await this.upgrade.GetUpgradeDialog(GA_EVENTCATEGORIES.STATISTICS);
        dialogSettings.componentProps.slidesOrder = [3];
        this.modal.create(dialogSettings).then((elem) => {
            elem.present();
        });
    }
}
