<!-- Translation containers-->
@if (false) {
    <ng-container i18n="@@501_AVG">501 avg.</ng-container>
    <ng-container i18n="@@TUT_501AVERAGE">The 501 average here, is the average over the last 2 months</ng-container>
}
<!-- End of translation containers-->

<modal-content scrollY="false" fullscreen>
    <div class="auto-height-dialog">
        <app-icon icon="close" class="text-black close-button" (click)="close()"></app-icon>
        <div class="title-container">
            <div class="title" [translate]="'INVITE_A_FRIEND'" i18n="@@INVITE_A_FRIEND">Invite a friend</div>
        </div>
        <div class="mt-8 flex w-full flex-col" *ngIf="user">
            <div class="relative flex items-center justify-between rounded-md bg-neutral-900 px-4 py-2">
                <div class="flex items-center justify-start space-x-2">
                    <app-profile-photo
                        class="h-fit"
                        [ultimate]="user.is_ultimate"
                        [badge]="user.profile?.badge"
                        [url]="user.profile?.profile_photo_url"
                        [size]="32">
                    </app-profile-photo>
                    <div class="space-y-0.5 overflow-hidden">
                        <div class="flex items-center space-x-1">
                            <div class="truncate text-xs font-bold text-white">
                                {{ user.first_name || ('NO_NAME' | translate) }}
                            </div>
                        </div>
                        <app-online-player-stats [player]="user"></app-online-player-stats>
                    </div>
                </div>
            </div>
        </div>
        <div class="titledetailbar !mt-4">
            <ion-row>
                <ion-col class="text bold text-center" [translate]="'LAST_FIVE_GAMES'" i18n="@@LAST_FIVE_GAMES"
                    >Last results</ion-col
                >
            </ion-row>
            <ion-row>
                <ion-col class="text text-center" *ngIf="user.last_results?.length > 0">
                    <span
                        *ngFor="let result of user.last_results"
                        class="dot"
                        [ngClass]="{ lose: result === 'lost', win: result === 'won', draw: result === 'tie' }"></span>
                </ion-col>
                <ion-col
                    class="text text-center"
                    *ngIf="!user.last_results?.length"
                    [translate]="'NO_ONLINE_MATCHES'"
                    i18n="@@NO_ONLINE_MATCHES"
                    >No online matches played
                </ion-col>
            </ion-row>
        </div>
        <div class="button-container">
            <app-primary-button
                size="normal"
                addedClasses="w-full"
                color="orange"
                (click)="trySendInvite()"
                [translate]="'SEND_INVITE'"
                i18n="@@SEND_INVITE">
                Send invite
            </app-primary-button>
        </div>
    </div>
</modal-content>
