<div class="flex h-full w-full flex-col overflow-y-auto overscroll-none rounded-t-md bg-white-pattern">
    <div class="relative flex items-center rounded-t-md bg-blue py-4 px-safe-offset-4" (click)="close()">
        <ion-buttons slot="start">
            <div class="ml-1 text-center text-sm font-bold !text-white" [translate]="'HIDE'" i18n="@@HIDE">Hide</div>
        </ion-buttons>
        <div
            class="absolute left-0 right-0 my-auto w-full text-center font-bebas-neue text-2xl !text-white"
            [translate]="'VOICE_CALL'"
            i18n="@@VOICE_CALL">
            Voice call
        </div>
    </div>

    <div class="mt-10 flex flex-1 flex-col pb-safe-offset-8 pt-4 px-safe-offset-4">
        @if (callingUser) {
            <div class="flex h-full flex-col items-center justify-between overflow-x-hidden">
                @switch (status) {
                    @case (VoiceCallStatus.None) {
                        <ng-container *ngTemplateOutlet="player"></ng-container>
                        <div class="flex flex-col items-center space-y-2 cursor-pointer" (click)="call()">
                            <div class="inline-flex h-12 w-12 items-center justify-center rounded-full bg-green p-2">
                                <app-icon icon="voice_call" class="!text-white"></app-icon>
                            </div>
                            <div class="text-base text-neutral-800" [translate]="'CALL'" i18n="@@CALL">Call</div>
                        </div>
                    }

                    @case (VoiceCallStatus.Calling) {
                        <ng-container *ngTemplateOutlet="player"></ng-container>
                        <div
                            class="text-[1.7rem] leading-normal text-neutral-800"
                            [translate]="'CALLING_DOTS'"
                            i18n="@@CALLING_DOTS">
                            Calling...
                        </div>
                        <div class="flex flex-col items-center space-y-2 cursor-pointer" (click)="hangup(true)">
                            <div class="inline-flex h-12 w-12 items-center justify-center rounded-full bg-red p-2">
                                <app-icon icon="voice_call" class="!text-white rotate-[134deg]"></app-icon>
                            </div>
                            <div class="text-base text-neutral-800" [translate]="'HANGUP'" i18n="@@HANGUP">Hangup</div>
                        </div>
                    }

                    @case (VoiceCallStatus.Incoming) {
                        <div class="flex flex-col items-center w-full">
                            <div
                                class="text-xl text-neutral-800 mb-4"
                                [translate]="'INCOMING_CALL'"
                                i18n="@@INCOMING_CALL">
                                Incoming call
                            </div>
                            <ng-container *ngTemplateOutlet="player"></ng-container>
                        </div>
                        <div class="flex justify-center space-x-8">
                            <div class="flex flex-col items-center space-y-2 cursor-pointer" (click)="accept()">
                                <div
                                    class="inline-flex h-12 w-12 items-center justify-center rounded-full bg-green p-2">
                                    <app-icon icon="voice_call" class="!text-white"></app-icon>
                                </div>
                                <div class="text-base text-neutral-800" [translate]="'ACCEPT'" i18n="@@ACCEPT">
                                    Accept
                                </div>
                            </div>

                            <div class="flex flex-col items-center space-y-2 cursor-pointer" (click)="decline()">
                                <div class="inline-flex h-12 w-12 items-center justify-center rounded-full bg-red p-2">
                                    <app-icon icon="close" class="!text-white"></app-icon>
                                </div>
                                <div class="text-base text-neutral-800" [translate]="'DECLINE'" i18n="@@DECLINE">
                                    Decline
                                </div>
                            </div>
                        </div>
                        <div class="p-3 rounded-md border-neutral-900 border flex justify-between items-center w-full">
                            <div
                                class="text-neutral-800 leading-none"
                                [translate]="'SEND_MESAGE_TWO_DOTS'"
                                i18n="@@SEND_MESAGE_TWO_DOTS">
                                Send message:
                            </div>
                            <div class="flex items-center space-x-2">
                                @for (message of messages; track $index) {
                                    <div
                                        class="rounded-md bg-blue !text-white py-0.5 px-1.5 font-semibold cursor-pointer"
                                        (click)="sendMessage(message)">
                                        {{ message.text }}
                                    </div>
                                }
                            </div>
                        </div>
                    }

                    @case (VoiceCallStatus.InCall) {
                        <ng-container *ngTemplateOutlet="player"></ng-container>
                        <div class="text-[1.7rem] leading-normal text-neutral-800">
                            {{ voiceCallService.showTimer() }}
                        </div>
                        <div class="flex justify-center space-x-8">
                            <div class="flex flex-col items-center space-y-2 cursor-pointer" (click)="toggleMute()">
                                <div class="inline-flex h-12 w-12 items-center justify-center rounded-full bg-blue p-2">
                                    @if (videoRoomService.ownAudioUserMedia?.audioTrack?.enabled) {
                                        <app-icon icon="mic_off" class="!text-white"></app-icon>
                                    } @else {
                                        <app-icon icon="mic"></app-icon>
                                    }
                                </div>
                                <div class="text-base text-neutral-800">
                                    @if (videoRoomService.ownAudioUserMedia?.audioTrack?.enabled) {
                                        <span [translate]="'MUTE'" i18n="@@MUTE">Mute</span>
                                    } @else {
                                        <span [translate]="'UNMUTE'" i18n="@@UNMUTE">Unmute</span>
                                    }
                                </div>
                            </div>

                            <div class="flex flex-col items-center space-y-2 cursor-pointer" (click)="hangup(true)">
                                <div class="inline-flex h-12 w-12 items-center justify-center rounded-full bg-red p-2">
                                    <app-icon icon="voice_call" class="!text-white rotate-[134deg]"></app-icon>
                                </div>
                                <div class="text-base text-neutral-800" [translate]="'HANGUP'" i18n="@@HANGUP">
                                    Hangup
                                </div>
                            </div>
                        </div>
                    }

                    @case (VoiceCallStatus.Hangup) {
                        <ng-container *ngTemplateOutlet="player"></ng-container>
                        <div class="text-[1.7rem] leading-normal text-neutral-800 text-center truncate w-full">
                            {{ translated('USER_HUNG_UP_THE_CALL') }}
                        </div>
                        <div class="flex justify-center space-x-8">
                            <div class="flex flex-col items-center space-y-2 cursor-pointer" (click)="call()">
                                <div
                                    class="inline-flex h-12 w-12 items-center justify-center rounded-full bg-green p-2">
                                    <app-icon icon="voice_call" class="!text-white"></app-icon>
                                </div>
                                <div class="text-base text-neutral-800" [translate]="'CALL'" i18n="@@CALL">Call</div>
                            </div>

                            <div class="flex flex-col items-center space-y-2 cursor-pointer" (click)="close()">
                                <div class="inline-flex h-12 w-12 items-center justify-center rounded-full bg-red p-2">
                                    <app-icon icon="close" class="!text-white"></app-icon>
                                </div>
                                <div class="text-base text-neutral-800" [translate]="'CLOSE'" i18n="@@CLOSE">Close</div>
                            </div>
                        </div>
                    }

                    @case (VoiceCallStatus.Declined) {
                        <ng-container *ngTemplateOutlet="player"></ng-container>
                        <div class="text-[1.7rem] leading-normal text-neutral-800 text-center truncate w-full">
                            {{ translated('USER_IS_BUSY') }}
                        </div>
                        <div class="flex justify-center space-x-8">
                            <div class="flex flex-col items-center space-y-2 cursor-pointer" (click)="close('chat')">
                                <div
                                    class="inline-flex h-12 w-12 items-center justify-center rounded-full bg-blue p-2 relative">
                                    <app-icon icon="chat_bubble" class="!text-white"></app-icon>
                                    <ion-badge
                                        mode="md"
                                        class="ion-bg-white ion-text-black absolute -right-0.5 -top-0.5"
                                        *ngIf="chatsCollectionService.unreadChatMessages() > 0">
                                        {{ chatsCollectionService.unreadChatMessages() }}
                                    </ion-badge>
                                </div>
                                <div class="text-base text-neutral-800" [translate]="'MESSAGE'" i18n="@@MESSAGE">
                                    Message
                                </div>
                            </div>

                            <div class="flex flex-col items-center space-y-2 cursor-pointer" (click)="close()">
                                <div class="inline-flex h-12 w-12 items-center justify-center rounded-full bg-red p-2">
                                    <app-icon icon="close" class="!text-white"></app-icon>
                                </div>
                                <div class="text-base text-neutral-800" [translate]="'CLOSE'" i18n="@@CLOSE">Close</div>
                            </div>
                        </div>
                    }

                    @case (VoiceCallStatus.NoResponse) {
                        <ng-container *ngTemplateOutlet="player"></ng-container>
                        <div
                            class="text-[1.7rem] leading-normal text-neutral-800"
                            [translate]="'NO_ANSWER'"
                            i18n="@@NO_ANSWER">
                            No answer
                        </div>
                        <div class="flex justify-center space-x-8">
                            <div class="flex flex-col items-center space-y-2 cursor-pointer" (click)="call()">
                                <div class="inline-flex h-12 w-12 items-center justify-center rounded-full bg-blue p-2">
                                    <app-icon icon="voice_call" class="!text-white"></app-icon>
                                </div>
                                <div class="text-base text-neutral-800" [translate]="'RETRY'" i18n="@@RETRY">Retry</div>
                            </div>

                            <div class="flex flex-col items-center space-y-2 cursor-pointer" (click)="close()">
                                <div class="inline-flex h-12 w-12 items-center justify-center rounded-full bg-red p-2">
                                    <app-icon icon="close" class="!text-white"></app-icon>
                                </div>
                                <div class="text-base text-neutral-800" [translate]="'CLOSE'" i18n="@@CLOSE">Close</div>
                            </div>
                        </div>
                    }
                }
            </div>
        }
    </div>
</div>

<ng-template #player>
    <div class="flex flex-col justify-center w-full">
        <app-profile-photo
            class="block h-full"
            [url]="callingUser.profile_photo_url"
            [ultimate]="callingUser.is_ultimate"
            [badge]="callingUser.badge"
            [size]="112"
            [big]="true"></app-profile-photo>
        <div class="mt-2 flex items-center justify-center space-x-1">
            <div class="text-2xl font-semibold text-black truncate">{{ callingUser.first_name }}</div>
        </div>
    </div>
</ng-template>
