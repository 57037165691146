/* eslint-disable max-len */
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { Component, computed, DestroyRef, inject, input, InputSignal, OnDestroy, OnInit, Signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { ProfilePhotoComponent } from '@components/profile-photo/profile-photo.component';
import { OnlineGameplay } from '@dc-core/dc-backend/dc-interfaces';
import { ActionsLogicService } from '@dc-core/dc-firestore/actions.service';
import { ActiveGamesCollectionService } from '@dc-core/dc-firestore/collection-helpers/active_games.collection.service';
import { ChatsCollectionService } from '@dc-core/dc-firestore/collection-helpers/chats.collection.service';
import { FSCHatMessage, FSVoiceCallAction } from '@dc-core/dc-firestore/globals/firestore.tables';
import { VoiceCallService, VoiceCallStatus } from '@dc-core/dc-services/voice/voice-call.service';
import { IonicModule, ModalController } from '@ionic/angular';
import { DocumentReference } from 'firebase/firestore';
import _ from 'lodash';
import { take } from 'rxjs';
import { UserService } from 'src/app/modules/user/services/user.service';
import { InputFocusManagerService } from 'src/app/shared/services/input-focus-manager.service';
import { InGameCommsDialogComponent, InGameCommsDialogPayload } from 'src/dialogs/in-game-comms/in-game-comms.dialog';
import {
    VoiceCallDialogComponent,
    VoiceCallDialogPayload,
    VoiceCallDialogResponse,
} from 'src/dialogs/voice-call/voice-call.dialog';

import { IconComponent } from '../../../../shared/components/icon/icon.component';
import { VoiceBarsComponent } from '../../../../voice-bars/voice-bars.component';

@Component({
    selector: 'app-games-online-chat',
    standalone: true,
    templateUrl: './games-online-chat.component.html',
    imports: [
        IonicModule,
        CdkTextareaAutosize,
        CommonModule,
        FormsModule,
        ProfilePhotoComponent,
        VoiceBarsComponent,
        IconComponent,
    ],
})
export class GamesOnlineChatComponent implements OnInit, OnDestroy {
    public authenticatedUserId: InputSignal<number> = input.required<number>();
    public authenticatedUserUUID: InputSignal<string> = input.required<string>();
    public liveGameplay: InputSignal<OnlineGameplay> = input.required<OnlineGameplay>();

    private activeGamesCollectionService: ActiveGamesCollectionService = inject(ActiveGamesCollectionService);
    private chatsCollectionService: ChatsCollectionService = inject(ChatsCollectionService);
    private voiceCallService: VoiceCallService = inject(VoiceCallService);
    private actionsLogicService: ActionsLogicService = inject(ActionsLogicService);
    private modalController: ModalController = inject(ModalController);
    private userService: UserService = inject(UserService);
    private inputFocusManagerService = inject(InputFocusManagerService);

    public currentCall: Signal<FSVoiceCallAction> = computed(() => this.voiceCallService.currentCall());
    public incomingChatMessages: Signal<FSCHatMessage[]> = computed(() =>
        this.chatsCollectionService.incomingChatMessages()
    );
    public unreadChatMessages: Signal<number> = computed(() => this.chatsCollectionService.unreadChatMessages());
    public missedCalls: Signal<number> = computed(() => this.voiceCallService.missedCalls());
    public VoiceCallStatus = VoiceCallStatus;

    private destroyRef: DestroyRef = inject(DestroyRef);

    public ngOnInit(): void {
        this.activeGamesCollectionService.activeGameRef$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((gameRef: DocumentReference<OnlineGameplay> | null) => {
                if (gameRef) {
                    this.chatsCollectionService.enableChatFeature(gameRef, this.authenticatedUserUUID());
                }
            });

        this.initVoiceCallHandler();
    }

    public ngOnDestroy(): void {
        this.chatsCollectionService.disableChat();
    }

    public removeIncomingMessage(event: Event, message: FSCHatMessage): void {
        event.stopImmediatePropagation();
        this.chatsCollectionService.removeIncomingChatMessage(message);
    }

    public showInGameComms(): void {
        this.userService
            .canUseSocialFeatures()
            .pipe(takeUntilDestroyed(this.destroyRef), take(1))
            .subscribe((valid: boolean) => {
                if (valid) {
                    this.modalController
                        .create({
                            component: InGameCommsDialogComponent,
                            componentProps: {
                                onlineGameplay: this.liveGameplay(),
                                authedUserUid: this.authenticatedUserUUID(),
                            } as InGameCommsDialogPayload,
                            cssClass: ['comms-modal', 'from-bottom'],
                        })
                        .then((elem) => {
                            elem.present();
                            elem.onDidDismiss().then(() => {
                                this.inputFocusManagerService.triggerFocusInput();
                            });
                        });
                }
            });
    }

    private initVoiceCallHandler(): void {
        this.userService
            .canUseSocialFeatures(false, false)
            .pipe(takeUntilDestroyed(this.destroyRef), take(1))
            .subscribe((valid: boolean) => {
                if (valid) {
                    this.actionsLogicService.voiceCallActions$
                        .pipe(takeUntilDestroyed(this.destroyRef))
                        .subscribe((voiceCallAction: FSVoiceCallAction) => {
                            if (voiceCallAction) {
                                this.voiceCallService.currentCall.set(voiceCallAction);
                                if (voiceCallAction.status === VoiceCallStatus.Incoming) {
                                    this.showVoiceCall();
                                }
                            }
                        });
                }
            });
    }

    private showVoiceCall(): void {
        if (this.voiceCallService.showingDialog) {
            return;
        }

        this.userService
            .canUseSocialFeatures(false, false)
            .pipe(takeUntilDestroyed(this.destroyRef), take(1))
            .subscribe((valid: boolean) => {
                if (valid) {
                    this.modalController
                        .create({
                            component: VoiceCallDialogComponent,
                            componentProps: {
                                callerUser: _.find(
                                    this.liveGameplay().players,
                                    (p) => p.uid == this.authenticatedUserUUID()
                                ),
                                callingUser: _.find(
                                    this.liveGameplay().players,
                                    (p) => p.uid != this.authenticatedUserUUID()
                                ),
                                gameplayDocId: this.liveGameplay().doc_id,
                            } as VoiceCallDialogPayload,
                            cssClass: ['comms-modal', 'from-bottom'],
                        })
                        .then((elem) => {
                            elem.present();
                            elem.onDidDismiss().then((dialogRes) => {
                                const data: VoiceCallDialogResponse = dialogRes.data;
                                if (data === 'chat') {
                                    this.showInGameComms();
                                }
                            });
                        });
                }
            });
    }
}
