@if (false) {
    <div [translate]="'FAST_OR_SLOW'" i18n="@@FAST_OR_SLOW">
        Do you want a small delay before you receive a score, or do you like to score immediately? It's your choice!
    </div>
}

<div class="flex flex-col space-y-2">
    <!-- Game settings -->
    <div class="new-game-card">
        <div class="title-container">
            <div class="icon">
                <app-icon icon="tune" size="small"></app-icon>
            </div>
            <div class="title" [translate]="'GAME_SETTINGS'" i18n="@@GAME_SETTINGS">Game settings</div>
        </div>

        <div class="flex flex-col space-y-4">
            <div class="flex flex-col space-y-2">
                <div class="flex items-center space-x-1">
                    <app-list-switch
                        class="w-full"
                        [item]="bestOfFirstTo"
                        [items]="bestOfFirstTos"
                        (itemChange)="changeBestOfFirstTo($event)"></app-list-switch>
                    <div
                        class="rounded bg-orange px-3 pb-[0.15rem] pt-[0.25rem] font-bebas-neue text-3xl !text-white cursor-pointer"
                        (click)="showGoalAmountDialog()">
                        {{ goalAmount }}
                    </div>
                    <app-list-switch
                        class="w-full"
                        [item]="setOrLeg"
                        [items]="setsOrLegs"
                        (itemChange)="changeSetOrLeg($event)"></app-list-switch>
                </div>

                <div class="list-switch">
                    <div
                        class="switch-item"
                        [ngClass]="{
                            '!py-0.5': itemFromList.type === 'custom',
                            '!px-0': itemFromList.type === 'custom'
                        }"
                        [class.selected]="startWith === itemFromList.type"
                        (click)="changeStartWith(itemFromList.type)"
                        *ngFor="let itemFromList of startWiths; let itemIndex = index">
                        @if (itemFromList.type !== 'custom') {
                            <div class="font-bebas-neue text-xl font-normal leading-none">
                                {{ itemFromList.label }}
                            </div>
                        }
                        <div class="flex flex-col items-center" *ngIf="itemFromList.type === 'custom'">
                            <div class="text-xxs text-neutral-400" [translate]="'CUSTOM'" i18n="@@CUSTOM">Custom</div>
                            <div class="font-bebas-neue text-base font-normal leading-none">{{ customStartWith }}</div>
                        </div>
                    </div>
                </div>

                <app-list-switch
                    [item]="entryMode"
                    [items]="entryModes"
                    (itemChange)="changeEntryMode($event)"></app-list-switch>

                <app-list-switch
                    [item]="finishMode"
                    [items]="finishModes"
                    (itemChange)="changeFinishMode($event)"></app-list-switch>
            </div>

            <div class="flex flex-col space-y-3">
                <app-toggle-switch
                    class="block w-full"
                    [class.opacity-50]="checkoutPercentageDisabled"
                    [model]="checkoutPercentage"
                    [disabled]="checkoutPercentageDisabled"
                    (itemChange)="toggleCheckoutPercentage()"
                    [translate]="'CHECKOUT_RATE'"
                    i18n="@@CHECKOUT_RATE">
                    Checkout rate
                </app-toggle-switch>

                <app-toggle-switch
                    class="block w-full"
                    [model]="twoLegsDifference"
                    (itemChange)="twoLegsDifference = !twoLegsDifference"
                    [translate]="'TWO_LEGS_DIFFERENCE'"
                    i18n="@@TWO_LEGS_DIFFERENCE">
                    Two legs difference
                </app-toggle-switch>

                @if (twoLegsDifference) {
                    <app-toggle-switch
                        class="block w-full"
                        [model]="suddenDeath"
                        (itemChange)="suddenDeath = !suddenDeath"
                        [translate]="'SUDDEN_DEATH'"
                        i18n="@@SUDDEN_DEATH">
                        Sudden death
                    </app-toggle-switch>
                }
            </div>
        </div>
    </div>

    <!-- In-game timer -->
    <div class="new-game-card !py-3">
        <div class="title-container">
            <div class="icon">
                <app-icon icon="timer" size="small"></app-icon>
            </div>
            <div class="title" [translate]="'INGAME_TIMER'" i18n="@@INGAME_TIMER">In-game timer</div>
            <div
                class="ml-auto rounded bg-orange px-2 pb-[0.15rem] pt-[0.25rem] font-bebas-neue !text-white cursor-pointer"
                (click)="showTimerDurationDialog()">
                <strong class="text-2xl">{{ inGameTimer }}</strong>
                s
            </div>
        </div>
    </div>

    <!-- Fast mode -->
    <div class="new-game-card">
        <div class="title-container">
            <div class="icon">
                <app-icon icon="speedmode" size="small"></app-icon>
            </div>
            <div
                class="title"
                [translate]="'SEND_SCORES_IMMEDIATELY'"
                i18n="@@SEND_SCORES_IMMEDIATELY"
                (click)="showHelpModal('SEND_SCORES_IMMEDIATELY', 'FAST_OR_SLOW')">
                Send scores immediately
            </div>
            <div
                class="bg-blue p-0.5 !text-white rounded-full cursor-pointer ml-2"
                (click)="showHelpModal('SEND_SCORES_IMMEDIATELY', 'FAST_OR_SLOW')">
                <app-icon icon="help" size="small"></app-icon>
            </div>
            <ion-toggle
                mode="ios"
                class="ml-auto !pr-2"
                style="zoom: 0.75"
                color="secondary"
                [(ngModel)]="fastScoring"></ion-toggle>
        </div>
    </div>
</div>
