@if (false) {
    <ng-container i18n="@@GROUP_NAME">Group name</ng-container>
    <ng-container i18n="@@YOUR_GROUP_NAME">Your group name</ng-container>
}

<div class="flex h-full flex-col overflow-y-auto overscroll-none bg-white pt-safe-offset-4">
    <div class="relative flex items-center px-safe-offset-4">
        @if (chatType === 'direct') {
            <button
                class="absolute z-10 top-0 left-5 my-auto text-center text-sm font-bold text-black"
                (click)="close()"
                [translate]="'CANCEL'"
                i18n="@@CANCEL">
                Cancel
            </button>

            <div
                class="absolute -top-1 left-0 my-auto w-full text-center font-bebas-neue text-2xl text-black"
                [translate]="'NEW_CHAT'"
                i18n="@@NEW_CHAT">
                New chat
            </div>
        } @else if (chatType === 'group') {
            <button
                class="absolute z-10 flex items-center top-0 left-3 py-auto text-center text-sm font-bold text-black"
                (click)="groupView === 'select' ? toggleChatType() : toggleGroupView()">
                <app-icon icon="keyboard_arrow_left" class="text-black"></app-icon>
                <div class="text-center text-sm font-bold text-black" [translate]="'BACK'" i18n="@@BACK">Back</div>
            </button>

            <div
                class="absolute -top-1 left-0 my-auto w-full text-center font-bebas-neue text-2xl text-black"
                [translate]="'NEW_GROUP_CHAT'"
                i18n="@@NEW_GROUP_CHAT">
                New group chat
            </div>

            @if (groupView === 'select') {
                <button
                    class="absolute z-10 top-0 right-5 my-auto text-center text-sm font-bold text-black"
                    (click)="toggleGroupView()"
                    [translate]="'NEXT'"
                    i18n="@@NEXT">
                    Next
                </button>
            } @else if (groupView === 'overview') {
                <button
                    class="absolute z-10 top-0 right-5 my-auto text-center text-sm font-bold text-black"
                    (click)="createGroupChat()"
                    [translate]="'CREATE'"
                    i18n="@@CREATE">
                    Create
                </button>
            }
        }
    </div>

    <div class="mt-12 flex flex-1 flex-col pb-safe-offset-4 px-safe-offset-4">
        <div class="flex flex-col h-full">
            @if (chatType === 'direct' || (chatType === 'group' && groupView === 'select')) {
                <div
                    class="flex w-full items-center space-x-2 rounded-md border border-neutral-400 px-4 py-3 focus-within:border-orange">
                    <ion-icon name="search-outline" class="ion-text-neutral-300"></ion-icon>
                    <input
                        class="w-full bg-transparent text-black placeholder:text-neutral-600"
                        [formControl]="searchControl"
                        placeholder="{{ 'SEARCH' | translate }}" />
                </div>
            }

            <!-- @if (chatType === 'direct') {
                <div
                    class="mt-6 mb-2 flex items-center rounded-lg bg-neutral-800 p-4 duration-200 hover:scale-[1.02] cursor-pointer"
                    (click)="toggleChatType()">
                    <div class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-black p-1">
                        <app-icon icon="supervisor_account" class="text-white"></app-icon>
                    </div>
                    <div class="mx-4 truncate">
                        <div
                            class="font-bebas-neue text-lg leading-none text-white"
                            [translate]="'NEW_GROUP'"
                            i18n="@@NEW_GROUP">
                            New group
                        </div>
                        <div
                            class="mt-0.5 text-xs font-semibold text-neutral-200"
                            [translate]="'ADD_AS_MANY_PEOPLE_AS_YOU_LIKE_IN_ONE_CHAT'"
                            i18n="@@ADD_AS_MANY_PEOPLE_AS_YOU_LIKE_IN_ONE_CHAT">
                            Add as many people as you like in one chat.
                        </div>
                    </div>
                    <app-icon icon="arrow_forward_ios" size="small" class="ml-auto text-neutral-300"></app-icon>
                </div>
            } -->

            @if (chatType === 'direct' || (chatType === 'group' && groupView === 'select')) {
                @if (chatType === 'group' && selected.length) {
                    <div class="mt-4 flex items-center space-x-2 overflow-x-scroll">
                        @for (user of selected; track user.id) {
                            <div class="flex flex-col items-center">
                                <div class="new-game-player-container">
                                    <app-profile-photo
                                        class="h-fit cursor-pointer"
                                        [size]="40"
                                        [ultimate]="user.is_ultimate"
                                        [badge]="user.profile?.badge"
                                        [url]="user.profile?.profile_photo_url">
                                    </app-profile-photo>
                                    <div class="circle" (click)="toggleFriend(user)">
                                        <ion-icon name="close" color="light"></ion-icon>
                                    </div>
                                </div>
                                <div class="mt-1 text-xs font-bold text-black max-w-16 truncate">
                                    {{ user.full_name }}
                                </div>
                            </div>
                        }
                    </div>
                }

                @if (friends && !friends.length) {
                    <div class="text-center text-white" style="margin-top: 15px; padding: 10px 5%">
                        <span [translate]="'NO_FRIENDS_YET'" i18n="@@NO_FRIENDS_YET"
                            >No friends added yet. Find your friends here!</span
                        >

                        <app-primary-button
                            addedClasses="w-full mt-2"
                            addedSlotClasses="font-semibold"
                            color="orange"
                            size="small"
                            [bold]="false"
                            [translate]="'SEARCH_FRIENDS'"
                            i18n="@@SEARCH_FRIENDS"
                            (click)="searchUsers()">
                            Search friends
                        </app-primary-button>
                    </div>
                }

                <div class="mt-4 divide-y divide-neutral-700 overflow-y-scroll scrollbar-hide">
                    @for (user of friends; track user.id) {
                        <div
                            class="flex items-center justify-between w-full p-2 text-black cursor-pointer"
                            (click)="toggleFriend(user)">
                            <div class="flex items-center space-x-2">
                                <app-profile-photo
                                    [size]="40"
                                    [ultimate]="user.is_ultimate"
                                    [url]="user.profile?.profile_photo_url"></app-profile-photo>
                                <div class="space-y-1 text-left">
                                    <div class="leading-none font-bold">{{ user.full_name }}</div>
                                    @if (user.profile?.nickname) {
                                        <div class="text-neutral-200 text-xs font-semibold">
                                            {{ user.profile?.nickname }}
                                        </div>
                                    }
                                </div>
                            </div>

                            @if (chatType === 'direct') {
                                <div class="bg-orange px-3 py-2 rounded">
                                    <app-icon icon="chat_bubble" class="!text-white"></app-icon>
                                </div>
                            } @else if (chatType === 'group') {
                                @if (selectedIds.includes(user.id)) {
                                    <div
                                        class="flex justify-center items-center border bg-orange border-orange w-6 h-6 rounded-full">
                                        <app-icon
                                            icon="check_mark"
                                            size="extrasmall"
                                            class="text-white text-sm"></app-icon>
                                    </div>
                                } @else {
                                    <div class="border bg-transparent w-6 h-6 rounded-full"></div>
                                }
                            }
                        </div>
                    }
                </div>
            } @else if (chatType === 'group' && groupView === 'overview') {
                <div class="flex items-center space-x-3">
                    @if (groupIcon) {
                        <img
                            class="h-[3.125rem] w-[3.125rem] min-w-[3.125rem] rounded-full"
                            [src]="groupIcon"
                            (click)="uploadGroupIcon()" />
                    } @else {
                        <div
                            class="flex justify-center items-center h-[3.125rem] w-[3.125rem] min-w-[3.125rem] rounded-full bg-neutral-800"
                            (click)="uploadGroupIcon()">
                            <app-icon icon="photo_camera" class="text-orange"></app-icon>
                        </div>
                    }

                    <div
                        class="flex w-full items-center rounded-lg bg-neutral-800 border border-transparent px-4 py-3 h-[3.125rem]"
                        [ngClass]="{ '!border-red': errors.groupName }">
                        <input
                            class="w-full bg-transparent text-white placeholder:text-neutral-200"
                            [(ngModel)]="groupName"
                            placeholder="{{ 'YOUR_GROUP_NAME' | translate }}" />
                    </div>
                </div>

                <div class="mt-6 dialog-input-label !text-white" [translate]="'MEMBERS'" i18n="@@MEMBERS">Members</div>
                <div class="mt-3 flex flex-wrap gap-y-4">
                    @for (user of selected; track user.id) {
                        <div class="flex flex-col items-center basis-1/4">
                            <div class="new-game-player-container">
                                <app-profile-photo
                                    class="h-fit cursor-pointer"
                                    [size]="52"
                                    [ultimate]="user.is_ultimate"
                                    [badge]="user.profile?.badge"
                                    [url]="user.profile?.profile_photo_url">
                                </app-profile-photo>
                                <div class="circle" (click)="toggleFriend(user)">
                                    <ion-icon name="close" color="light"></ion-icon>
                                </div>
                            </div>
                            <div class="mt-1 text-xs font-bold text-white max-w-16 truncate">
                                {{ user.full_name }}
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    </div>
</div>
