@if (false) {
    <ng-container i18n="@@THROWOUT">Throwout</ng-container>
    <ng-container i18n="@@HITS">Hits</ng-container>
}

<div class="ml-3 mr-3 flex items-end">
    <app-select
        title="{{ 'PERIOD' | translate }}"
        [options]="periodOptions"
        [initialValue]="statisticsRange"
        (selectionChange)="changeFilter('statisticsRange', $event)"
        class="mx-1 w-full"></app-select>
    <app-select
        title="{{ 'THROWOUT' | translate }}"
        [options]="throwouts"
        [initialValue]="throwout"
        (selectionChange)="changeFilter('throwout', $event)"
        class="mx-1 w-full"></app-select>
    <app-select
        title="{{ 'HITS' | translate }}"
        [options]="hits"
        [initialValue]="amountOfHits"
        (selectionChange)="changeFilter('amountOfHits', $event)"
        class="mx-1 w-full"></app-select>
</div>

<div>
    @if (statisticsRange === 'custom') {
        <ion-row style="color: white; margin: 10px 10px 0">
            @if (statisticsRange === 'custom') {
                <ion-col style="padding: 0; display: flex">
                    @if (auth.user.is_ultimate) {
                        <ion-col style="padding: 0">
                            <ion-select
                                style="overflow: auto; padding: 0px 12px; font-size: 13px; margin-top: 4px"
                                interface="popover"
                                [(ngModel)]="year"
                                (ionChange)="changeStatisticsRange('custom')">
                                <ion-select-option *ngFor="let yearOption of years" [value]="yearOption">
                                    {{ yearOption }}
                                </ion-select-option>
                            </ion-select>
                        </ion-col>
                        <ion-col style="padding: 0">
                            <ion-select
                                style="overflow: auto; padding: 0px 12px; font-size: 13px; margin-top: 4px"
                                interface="popover"
                                [(ngModel)]="month"
                                (ionChange)="changeStatisticsRange('custom')">
                                <ion-select-option *ngFor="let monthOption of months" [value]="monthOption">
                                    {{ monthOption }}
                                </ion-select-option>
                            </ion-select>
                        </ion-col>
                    } @else {
                        <ion-col style="padding: 0" (click)="OpenUltimateDialog()">
                            <ion-select
                                style="overflow: auto; padding: 0px 12px; font-size: 13px; margin-top: 4px"
                                interface="popover"
                                disabled
                                [(ngModel)]="year">
                                <ion-select-option *ngFor="let yearOption of years" [value]="yearOption">
                                    {{ yearOption }}
                                </ion-select-option>
                            </ion-select>
                        </ion-col>
                        <ion-col style="padding: 0" (click)="OpenUltimateDialog()">
                            <ion-select
                                style="overflow: auto; padding: 0px 12px; font-size: 13px; margin-top: 4px"
                                interface="popover"
                                disabled
                                [(ngModel)]="month">
                                <ion-select-option *ngFor="let monthOption of months" [value]="monthOption">
                                    {{ monthOption }}
                                </ion-select-option>
                            </ion-select>
                        </ion-col>
                    }
                </ion-col>
            }
            @if (statisticsRange !== 'custom') {
                <ion-col></ion-col>
            }
        </ion-row>
    }

    <div class="m-3 text-black">
        <div class="grid grid-cols-9 gap-1 whitespace-nowrap text-sm">
            <div class="col-span-3"></div>
            <div class="col-span-2 text-center font-normal uppercase" [translate]="'AVG'" i18n="@@AVG">Avg.</div>
            <div class="col-span-2 text-center font-normal uppercase" [translate]="'BEST'" i18n="@@BEST">Best</div>
            <div class="col-span-2 text-center font-normal uppercase" [translate]="'WORST'" i18n="@@WORST">Worst</div>
        </div>
        <div class="game-stat-main-table-container">
            <!-- Hit rate -->
            <div class="col-span-3" [translate]="'HIT_RATE'" i18n="@@HIT_RATE">Hit rate</div>
            <div class="col-span-2 py-1 text-center text-sm">
                @if (!loadedOverallStats) {
                    <app-loader mode="dark" size="6"></app-loader>
                } @else {
                    {{ overallStats.hitRate.average || 0 | number: '1.2-2' }}%
                }
            </div>
            <div class="game-specific-detail-stat" (click)="goToDetails('hitRate', 'best')">
                @if (!loadedOverallStats) {
                    <app-loader mode="orange" size="6"></app-loader>
                } @else {
                    {{ overallStats.hitRate.best || 0 | number: '1.2-2' }}%
                }
            </div>
            <div class="game-specific-detail-stat" (click)="goToDetails('hitRate', 'worst')">
                @if (!loadedOverallStats) {
                    <app-loader mode="orange" size="6"></app-loader>
                } @else {
                    {{ overallStats.hitRate.worst || 0 | number: '1.2-2' }}%
                }
            </div>
        </div>
        @if (amountOfHits !== 'all') {
            <div class="relative">
                <div class="game-stat-table-container">
                    <!-- Total score -->
                    <div class="col-span-3" [translate]="'DARTS_THROWN'" i18n="@@DARTS_THROWN">Darts thrown</div>
                    <div class="col-span-2 py-1 text-center text-sm">
                        @if (!loadedOverallStats) {
                            <app-loader mode="dark" size="6"></app-loader>
                        } @else {
                            {{ overallStats.dartsThrown.average || 0 }}
                        }
                    </div>
                    <div class="game-specific-detail-stat" (click)="goToDetails('totalDarts', 'best')">
                        @if (!loadedOverallStats) {
                            <app-loader mode="orange" size="6"></app-loader>
                        } @else {
                            {{ overallStats.dartsThrown.best || 0 }}
                        }
                    </div>
                    <div class="game-specific-detail-stat" (click)="goToDetails('totalDarts', 'worst')">
                        @if (!loadedOverallStats) {
                            <app-loader mode="orange" size="6"></app-loader>
                        } @else {
                            {{ overallStats.dartsThrown.worst || 0 }}
                        }
                    </div>

                    <!-- Upgrade -->
                    @if (auth.user.is_ultimate !== true) {
                        <div class="absolute bottom-0 left-0 right-0 top-0 grid w-full grid-cols-9">
                            <div class="locked-section top-0 col-span-6 col-start-4 rounded-md">
                                <div
                                    (click)="OpenUltimateDialog()"
                                    class="flex h-full flex-col items-center justify-center py-0.5">
                                    <ion-icon name="lock-closed-outline" color="light"></ion-icon>
                                    <ion-button
                                        size="small"
                                        color="primary"
                                        [translate]="'UPGRADE_BTN'"
                                        i18n="@@UPGRADE_BTN">
                                        Upgrade</ion-button
                                    >
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        }
    </div>
</div>
