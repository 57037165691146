import { Component, Input } from '@angular/core';
import { PublicOnlineGameplay } from '@dc-core/dc-backend/dc-interfaces';
import { ActiveGamesCollectionService } from '@dc-core/dc-firestore/collection-helpers/active_games.collection.service';
import { PublicGamesCollectionService } from '@dc-core/dc-firestore/collection-helpers/public_games.collection.service';
import { UsersCollectionService } from '@dc-core/dc-firestore/collection-helpers/users.collection.service';
import { ONLINESTATUS } from '@dc-core/dc-firestore/globals/firestore.tables';
import { OnlineFunctions } from '@dc-core/dc-gamelogic/online-game/online.functions';
import { ModalController, NavController } from '@ionic/angular';
import { AuthService } from 'src/services/auth.service';

export interface ActiveGameDialogPayload {
    gameplay: PublicOnlineGameplay;
}

@Component({
    selector: 'app-active-game-dialog',
    templateUrl: 'active-game.html'
})
export class ActiveGameDialogComponent {
    @Input() gameplay: PublicOnlineGameplay;

    constructor(
        public view: ModalController,
        public auth: AuthService,
        private nav: NavController,
        public onlineFunctions: OnlineFunctions,
        private _activeGamesCollectionService: ActiveGamesCollectionService,
        private _publicGamesCollectionService: PublicGamesCollectionService,
        private _usersCollectionService: UsersCollectionService
    ) {}

    public dismiss(): void {
        this.view.dismiss();
    }

    public quit(): void {
        this._activeGamesCollectionService.activeGameRef = this._activeGamesCollectionService.getDocByID(
            this.gameplay.doc_id
        );
        this._activeGamesCollectionService.quitOnlineGame();
        this._publicGamesCollectionService.remove(this.gameplay.doc_id);
        this._usersCollectionService.removeActiveGameRef(ONLINESTATUS.ONLINE);

        this.dismiss();
    }

    public continue(): void {
        this.dismiss();

        switch (this.gameplay.type) {
            case 'match':
                this.nav.navigateForward('online-game/match/' + this.gameplay.doc_id);
                break;
            case 'cricket_tactics':
                this.nav.navigateForward('online-game/cricket-tactics/' + this.gameplay.doc_id);
                break;
        }
    }
}
