/* eslint-disable max-len */
import 'cordova-plugin-purchase';

import { Injectable } from '@angular/core';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { PaymentApiService } from '@dc-api/payment.api.service';
import { ModalController, Platform } from '@ionic/angular';
import { DartCounterAlertService } from 'dc-core/dc-services/alert.service';
import { Observable, Subject } from 'rxjs';
import { ULTIMATE_MONTHLY_SUBSCRIPTION, ULTIMATE_YEARLY_SUBSCRIPTION } from 'src/app/app.globals';
import { EnterEmailDialogComponent } from 'src/dialogs/enter-email/enter-email';
import { SendUpgradeReasonsDialogComponent } from 'src/dialogs/send-upgrade-reasons/send-upgrade-reasons';
import { environment } from 'src/environments/environment';

import { AuthService } from '../services/auth.service';
import { DartCounterAnalyticsService } from './analytics-service';
import { DCLoadingService } from './DCLoadingService';

export type PurchaseStatus = 'cancelled' | 'success' | 'close';

@Injectable()
export class PurchaseService {
    public store: CdvPurchase.Store;
    public ultimateSubscriptions: CdvPurchase.Product[] = null;
    public monthlyProduct: CdvPurchase.Product = null;
    public yearlyProduct: CdvPurchase.Product = null;
    public iapPlatform: CdvPurchase.Platform;

    public isRestoring = false;
    public initialized = false;

    private hasInitiatedPayment = false;
    private _status$: Subject<PurchaseStatus> = new Subject<PurchaseStatus>();

    constructor(
        public auth: AuthService,
        public platform: Platform,
        private _alertService: DartCounterAlertService,
        private DCLoadingService: DCLoadingService,
        private purchasesApiService: PaymentApiService,
        private _modal: ModalController,
        private ga: DartCounterAnalyticsService
    ) {}

    initialize(): void {
        if (this.platform.is('mobile') && !this.initialized) {
            this.store = CdvPurchase.store;

            if (this.platform.is('ios')) {
                this.iapPlatform = CdvPurchase.Platform.APPLE_APPSTORE;
            } else if (this.platform.is('android')) {
                this.iapPlatform = CdvPurchase.Platform.GOOGLE_PLAY;
            }

            if (this.iapPlatform) {
                try {
                    this.registerProducts();

                    if (environment.production) {
                        this.store.verbosity = CdvPurchase.LogLevel.QUIET;
                    } else {
                        this.store.verbosity = CdvPurchase.LogLevel.DEBUG;
                    }

                    this.store.applicationUsername = () => this.auth.user.id.toString();

                    this.store.validator = null;

                    this.setupListeners();

                    this.store
                        .initialize([CdvPurchase.Platform.APPLE_APPSTORE, CdvPurchase.Platform.GOOGLE_PLAY])
                        .then(() => {
                            this.ultimateSubscriptions = this.store.products;
                            this.monthlyProduct = this.store.get(ULTIMATE_MONTHLY_SUBSCRIPTION);
                            this.yearlyProduct = this.store.get(ULTIMATE_YEARLY_SUBSCRIPTION);
                        });
                } catch (_) {}
            }
        }
    }

    get status$(): Observable<PurchaseStatus> {
        return this._status$.asObservable();
    }

    registerProducts() {
        this.store.register([
            {
                id: ULTIMATE_MONTHLY_SUBSCRIPTION,
                type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
                platform: CdvPurchase.Platform.APPLE_APPSTORE,
            },
            {
                id: ULTIMATE_YEARLY_SUBSCRIPTION,
                type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
                platform: CdvPurchase.Platform.APPLE_APPSTORE,
            },
            {
                id: ULTIMATE_MONTHLY_SUBSCRIPTION,
                type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
                platform: CdvPurchase.Platform.GOOGLE_PLAY,
            },
            {
                id: ULTIMATE_YEARLY_SUBSCRIPTION,
                type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
                platform: CdvPurchase.Platform.GOOGLE_PLAY,
            },
        ]);
    }

    setupListeners() {
        this.store.error((err) => {
            console.error('Store Error ' + JSON.stringify(err));
        });

        // General query to all products
        this.store
            .when()
            .approved(async (transaction: CdvPurchase.Transaction) => {
                const product = transaction.products[0];
                const receipt = transaction.parentReceipt;
                if (product.id == ULTIMATE_MONTHLY_SUBSCRIPTION || product.id == ULTIMATE_YEARLY_SUBSCRIPTION) {
                    if (this.isRestoring || this.hasInitiatedPayment) {
                        if (receipt.platform == CdvPurchase.Platform.APPLE_APPSTORE) {
                            await this.purchasesApiService
                                .paymentsAppleValidateSubscription({
                                    receipt: (receipt as any).nativeData.appStoreReceipt,
                                })
                                .then(() => {
                                    this.validated(transaction, product);
                                })
                                .catch(() => {
                                    this.DCLoadingService.DismissLoader();
                                });
                        } else if (receipt.platform == CdvPurchase.Platform.GOOGLE_PLAY) {
                            await this.purchasesApiService
                                .paymentsGoogleValidateSubscription({
                                    item_id: product.id,
                                    purchase_token: (receipt as any).purchaseToken,
                                })
                                .then(() => {
                                    this.validated(transaction, product);
                                })
                                .catch(() => {
                                    this.DCLoadingService.DismissLoader();
                                });
                        }
                    }
                }
            })
            .finished((transaction: CdvPurchase.Transaction) => {
                //
            });
    }

    validated(transaction: CdvPurchase.Transaction, product: any): void {
        this.DCLoadingService.DismissLoader();

        transaction.finish();

        this.auth.user.is_ultimate = true;
        this.auth.userIsUltimate.next(true);

        if (this.hasInitiatedPayment) {
            this.hasInitiatedPayment = false;

            FirebaseAnalytics.logEvent({
                name: 'purchase',
                params: {
                    currency: transaction.currency,
                    value: transaction.amountMicros / 1000000,
                    items: [
                        {
                            item_name: product.id,
                            price: transaction.amountMicros / 1000000,
                            quantity: 1,
                        },
                    ],
                },
            });

            this._alertService.createAlert({
                icon: 'success',
                title: $localize`:@@NOW_AN_ULTIMATE_MEMBER:Congrats! You are now a DartCounter Ultimate-member!`,
                timer: 1500,
            });

            setTimeout(() => {
                this._modal
                    .create({
                        component: SendUpgradeReasonsDialogComponent,
                        cssClass: 'auto-height',
                    })
                    .then((elem) => {
                        elem.present();
                    });
            }, 1500);
        }

        if (this.isRestoring) {
            this.isRestoring = false;
        }

        this._status$.next('close');
    }

    purchase(productId: string) {
        this.hasInitiatedPayment = true;
        this.DCLoadingService.ShowDefaultLoader();

        const product = this.store.get(productId, this.iapPlatform);
        try {
            this.store
                .order(product.getOffer(), {
                    applicationUsername: this.auth.user.id.toString(),
                })
                .then((result) => {
                    if (result && result.isError) {
                        this.hasInitiatedPayment = false;
                    }

                    this.DCLoadingService.DismissLoader();
                })
                .catch((err) => {
                    this.DCLoadingService.DismissLoader();
                    this._alertService.createAlert({
                        title: err.toString(),
                        icon: 'error',
                    });
                });
        } catch (e) {
            this.DCLoadingService.DismissLoader();
            this._alertService.createAlert({
                title: e.toString(),
                icon: 'error',
            });
        }
    }

    restore(showLoader = true) {
        if (!this.auth.user.email) {
            this._modal
                .create({
                    component: EnterEmailDialogComponent,
                    componentProps: {
                        email: this.auth.user.email,
                    },
                    cssClass: 'auto-height',
                })
                .then((elem) => {
                    elem.present();
                    elem.onDidDismiss().then((dialogRes) => {
                        if (dialogRes.data == true) {
                            this.restorePurchases(showLoader);
                        }
                    });
                });
        } else {
            this.restorePurchases(showLoader);
        }
    }

    restorePurchases(showLoader = true): void {
        try {
            this.isRestoring = true;
            if (showLoader) {
                this.DCLoadingService.ShowDefaultLoader();
            }

            this.store
                .restorePurchases()
                .then(() => {
                    setTimeout(() => {
                        if (showLoader) {
                            this.DCLoadingService.DismissLoader();
                        }
                    }, 1000);
                })
                .catch(() => this.DCLoadingService.DismissLoader());
        } catch (e) {
            if (showLoader) {
                this.DCLoadingService.DismissLoader();
            }
            this.isRestoring = false;
        }
    }
}
