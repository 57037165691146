import { Component } from '@angular/core';
import { UserApiService } from '@dc-api/user.api.service';
import { User } from '@dc-core/dc-backend/dc-classes';
import { ModalController, ToastController } from '@ionic/angular';
import { DCLoadingService } from 'src/providers/DCLoadingService';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-search-player-dialog',
    templateUrl: 'search-player.component.html',
    styleUrls: ['search-player.component.scss'],
})
export class SearchPlayerDialogComponent {
    searchString: string;
    users: User[] = [];
    public searchLoading = false;

    constructor(
        public view: ModalController,
        public auth: AuthService,
        private loading: DCLoadingService,
        public toast: ToastController,
        private _userApiService: UserApiService
    ) {}

    public DismissModal(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }

    searchUsers(searchString: string) {
        if (searchString.length > 0) {
            this.searchLoading = true;
            this._userApiService.getAllUsers({ search: searchString, with_self: false }).then((res) => {
                this.searchLoading = false;
                this.users = res.data.data;
            });
        }
    }
}
