import { Pipe, PipeTransform } from '@angular/core';
import { EntryMode } from 'dc-core/dc-backend/dc-enums';

@Pipe({
    name: 'entryMode',
})
export class EntryModePipe implements PipeTransform {
    transform(entryMode: EntryMode): string {
        switch (entryMode) {
            case 'double_in':
                return $localize`:@@DOUBLE_IN:Double in`;
            case 'master_in':
                return $localize`:@@MASTER_IN:Master in`;
            case 'straight_in':
                return $localize`:@@STRAIGHT_IN:Straight in`;
        }
    }
}
