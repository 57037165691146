<div
    [class.ultimate]="ultimate"
    style="display: flex; justify-content: center; align-items: center; position: relative; width: {{
        borderSize / 16
    }}rem; height: {{ borderSize / 16 }}rem;
        max-width: {{ borderSize / 16 }}rem; min-width: {{ borderSize / 16 }}rem; max-height: {{ borderSize / 16 }}rem; 
        min-height: {{ borderSize / 16 }}rem; margin-left: auto; margin-right: auto;"
    [ngClass]="{ big: big }">
    <img
        class="image"
        style="width: {{ ultimate ? size / 16 : borderSize / 16 }}rem; height: {{
            ultimate ? size / 16 : borderSize / 16
        }}rem;
        max-width: {{ ultimate ? size / 16 : borderSize / 16 }}rem; min-width: {{
            ultimate ? size / 16 : borderSize / 16
        }}rem; 
        max-height: {{ ultimate ? size / 16 : borderSize / 16 }}rem; min-height: {{
            ultimate ? size / 16 : borderSize / 16
        }}rem;"
        [src]="imageUrl" />
    <img
        *ngIf="badge && badgeImg"
        [ngClass]="{ big: big }"
        class="verification-icon"
        src="assets/images/verifications/{{ badgeImg }}" />
    <div *ngIf="showRating === true" class="rating-badge">
        <img class="ratingIcon" src="assets/images/verifications/star_{{ ratingColor }}.png" />
        <span class="ratingAverage">{{ rating | number: '1.1-1' }}</span>
    </div>
    <ng-content></ng-content>
</div>
