<ion-content fullscreen class="bg-white-pattern ion-text-black">
    <app-page-header mainNavigation="back" defaultBack="settings">
        <div title [translate]="'BLOCKED_PLAYERS'" i18n="@@BLOCKED_PLAYERS">Blocked players</div>
    </app-page-header>
    <div appWebContainer>
        @if (!loaded) {
            <div class="flex justify-center">
                <ion-spinner style="height: 20px; width: 20px" color="secondary" name="crescent"></ion-spinner>
            </div>
        }
        @if (loaded) {
            @if (blockedPlayers.length) {
                @for (user of blockedPlayers; track user; let i = $index) {
                    <div class="flex justify-between items-center">
                        <app-profile-photo
                            [ultimate]="user.is_ultimate"
                            [badge]="user?.profile?.badge === 'verified'"
                            [url]="user.profile?.profile_photo_url"
                            class="mr-4"></app-profile-photo>
                        <span class="capitalize">{{ user.first_name }} {{ user.last_name }}</span>
                        <app-primary-button
                            class="ml-auto"
                            type="submit"
                            size="extrasmall"
                            color="white"
                            fill="outline"
                            (click)="tryUnblock(user.id, i)"
                            [translate]="'UNBLOCK'"
                            i18n="@@UNBLOCK">
                            Unblock
                        </app-primary-button>
                    </div>
                }
            }
            @if (!blockedPlayers.length) {
                <div class="text-center text-black" [translate]="'NO_BLOCKED_PLAYERS'" i18n="@@NO_BLOCKED_PLAYERS">
                    You don't have any blocked players!
                </div>
            }
        }
    </div>
</ion-content>
