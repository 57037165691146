<div class="flex h-full flex-col overflow-y-auto overscroll-none bg-white-pattern">
    <app-page-header mainNavigation="custom" color="transparent">
        <div
            left
            class="text-center font-bold text-black cursor-pointer"
            [class.text-sm]="!title"
            [class.text-base]="title"
            (click)="close()"
            [translate]="'CLOSE'"
            i18n="@@CLOSE">
            Close
        </div>
        @if (!title) {
            <div title [translate]="'LIVE_CAMERA'" i18n="@@LIVE_CAMERA">Live camera</div>
        } @else {
            {{ title }}
        }
    </app-page-header>

    <div class="flex h-full portrait:flex-col portrait:sm:flex-row landscape:flex-row">
        @if (ingameCameraService.userMediaMap.get(userId)?.videoStreams.hasStreams === false) {
            <app-icon icon="loading" size="extralarge" class="mx-auto animate-spin text-orange"></app-icon>
        }

        <div
            class="relative mx-auto basis-1/2 overflow-hidden"
            *ngIf="ingameCameraService.userMediaMap.get(userId)?.videoStreams.board">
            <video
                *ngIf="ingameCameraService.userMediaMap.get(userId)?.videoStreams.board?.stream"
                class="ingame-camera w-full"
                [srcObject]="ingameCameraService.userMediaMap.get(userId)?.videoStreams.board.stream"
                [style.transform]="ingameCameraService.userMediaMap.get(userId)?.videoStreams.board.scale"
                autoplay
                playsinline
                webkit-playsinline
                muted></video>
            <div class="absolute left-4 top-4 h-7 w-7 rounded-md bg-orange p-1 inline-flex justify-center items-center">
                <app-icon icon="videocam" size="medium" class="!text-white"></app-icon>
            </div>
        </div>
        <div
            class="relative mx-auto basis-1/2 overflow-hidden"
            *ngIf="ingameCameraService.userMediaMap.get(userId)?.videoStreams.player">
            <video
                *ngIf="ingameCameraService.userMediaMap.get(userId)?.videoStreams.player?.stream"
                class="ingame-camera w-full"
                [srcObject]="ingameCameraService.userMediaMap.get(userId)?.videoStreams.player.stream"
                autoplay
                playsinline
                webkit-playsinline
                muted></video>
            <div
                class="absolute right-4 top-4 h-7 w-7 rounded-md bg-orange p-1 inline-flex justify-center items-center">
                <app-icon icon="videocam" size="medium" class="!text-white"></app-icon>
            </div>
        </div>
    </div>
</div>
