<div class="slide-modal-outer slide-modal-full" [class.web-height]="isWeb">
    <div class="slide-modal-header">
        <div class="slide-modal-close" (click)="close()" [translate]="'CANCEL'" i18n="@@CANCEL">Cancel</div>
        <div class="slide-modal-title" [translate]="'ACTIVATE_CAMERA'" i18n="@@ACTIVATE_CAMERA">Activate camera</div>
    </div>

    <div class="mt-10 flex flex-1 flex-col px-safe pb-safe-offset-4 max-h-[calc(100%-66px)]">
        <div class="flex scrollbar-hide h-full">
            <div class="flex-remain flex flex-col px-5" *ngIf="currentSlide === 'select'" [@slideAnimation]>
                <div
                    class="text-xl font-bold leading-none text-black"
                    [translate]="'SELECT_TYPE_OF_CAMERA'"
                    i18n="@@SELECT_TYPE_OF_CAMERA">
                    Select type of camera to setup for the current session
                </div>
                <div
                    class="mt-4 font-semibold text-neutral-400"
                    [translate]="'RECONNECT_CAMERA_INFO'"
                    i18n="@@RECONNECT_CAMERA_INFO">
                    You may need to re-connect the camera every time you relaunch the app
                </div>
                <div class="mt-6 grid grid-cols-2 gap-3 overflow-y-auto overflow-x-hidden">
                    <div class="col-span-1 space-y-1 cursor-pointer" (click)="setCameraType('current_device')">
                        <img
                            class="rounded-md"
                            [ngClass]="{
                                'hover:scale-[1.02] duration-200': cameraType !== 'current_device',
                                'border-2 border-orange border-opacity-80 p-1': cameraType === 'current_device'
                            }"
                            [src]="'assets/images/cam_internal_' + (isWeb ? 'web.jpg' : 'app.jpg')" />
                        <div
                            class="text-center font-bebas-neue text-lg text-black"
                            [translate]="'LINK_DEVICE_CAMERA'"
                            i18n="@@LINK_DEVICE_CAMERA">
                            Link own camera
                        </div>
                    </div>
                    <div class="col-span-1 space-y-1 cursor-pointer" (click)="setCameraType('external_device')">
                        <img
                            class="rounded-md"
                            [ngClass]="{
                                'hover:scale-[1.02] duration-200': cameraType !== 'external_device',
                                'border-2 border-orange border-opacity-80 p-1': cameraType === 'external_device'
                            }"
                            [src]="'assets/images/cam_external_' + (isWeb ? 'web.jpg' : 'app.jpg')" />
                        <div
                            class="text-center font-bebas-neue text-lg text-black"
                            [translate]="'EXTERNAL_CAMERA'"
                            i18n="@@EXTERNAL_CAMERA">
                            External camera
                        </div>
                    </div>
                    <div
                        class="col-span-2 space-y-1 md:col-span-1 cursor-pointer"
                        (click)="setCameraType('smart_device')">
                        <img
                            class="rounded-md"
                            [ngClass]="{
                                'hover:scale-[1.02] duration-200': cameraType !== 'smart_device',
                                'border-2 border-orange border-opacity-80 p-1': cameraType === 'smart_device'
                            }"
                            src="assets/images/ftr/virt-cam-setup/Virt-setup-device-sm.jpg" />
                        <div class="text-center font-bebas-neue text-lg text-black">Target VirtCam</div>
                    </div>
                </div>

                <app-primary-button
                    class="mt-auto block pt-4"
                    size="normal"
                    addedClasses="w-full"
                    (click)="checkCameraType()"
                    [disabled]="!cameraType"
                    [translate]="'CONTINUE'"
                    i18n="@@CONTINUE">
                    Continue
                </app-primary-button>
            </div>

            <div class="flex-remain flex flex-col px-5" *ngIf="currentSlide === 'external_info'" [@slideAnimation]>
                <div
                    class="text-xl font-bold leading-none text-black"
                    [translate]="'CONNECT_CAM_TITLE1'"
                    i18n="@@CONNECT_CAM_TITLE1">
                    Go to darts.camera on your external device
                </div>
                <div
                    class="mt-4 font-semibold text-neutral-400"
                    [translate]="'CONNECT_CAM_DESCRIPTION1'"
                    i18n="@@CONNECT_CAM_DESCRIPTION1">
                    Make sure the camera is connected
                </div>
                <img class="mt-8" src="assets/images/urlbar.png" alt="Enter URL in your browser" />
                <div
                    class="mt-8 text-xl font-bold leading-none text-black"
                    [translate]="'CONNECT_CAM_TITLE2'"
                    i18n="@@CONNECT_CAM_TITLE2">
                    Stream camera
                </div>
                <div
                    class="mt-4 font-semibold text-neutral-400"
                    [translate]="'CONNECT_CAM_DESCRIPTION2'"
                    i18n="@@CONNECT_CAM_DESCRIPTION2">
                    Click 'STREAM' to view your camera
                </div>

                <app-primary-button
                    class="mt-auto block pt-4"
                    size="normal"
                    addedClasses="w-full"
                    (click)="goToSlide('external_code')"
                    [translate]="'CONTINUE'"
                    i18n="@@CONTINUE">
                    Continue
                </app-primary-button>
            </div>

            <div class="flex-remain flex flex-col px-5" *ngIf="currentSlide === 'external_code'" [@slideAnimation]>
                <div
                    class="font-open-sans text-xl font-bold leading-none text-black"
                    [translate]="'CONNECT_TO_EXTERNAL_CAM'"
                    i18n="@@CONNECT_TO_EXTERNAL_CAM">
                    Connect to the external camera
                </div>
                <div
                    class="mb-8 mt-4 font-open-sans font-semibold text-neutral-400"
                    [translate]="'CONNECT_TO_EXTERNAL_CAM_INFO'"
                    i18n="@@CONNECT_TO_EXTERNAL_CAM_INFO">
                    Enter the code and password from the external device
                </div>

                <div class="external-cam-input-wrapper">
                    <div class="flex items-center justify-center">
                        <!-- Number inputs for code -->
                        <div class="external-cam-input">
                            <input
                                maxlength="1"
                                type="number"
                                placeholder="1"
                                id="codeInput0"
                                #codeInput0
                                (keyup)="onInputKeyup($event, finalCode, 0, codeInput0, codeInput1, null)"
                                (click)="focusField(codeInput0)" />
                            <div
                                class="bottom-line"
                                [class.selected]="codeInput0 === focusedField"
                                [class.filled]="finalCode[0]"></div>
                        </div>
                        <div class="external-cam-input">
                            <input
                                maxlength="1"
                                type="number"
                                id="codeInput1"
                                #codeInput1
                                placeholder="2"
                                (keyup)="onInputKeyup($event, finalCode, 1, codeInput1, codeInput2, codeInput0)"
                                (click)="focusField(codeInput1)" />
                            <div
                                class="bottom-line"
                                [class.selected]="codeInput1 === focusedField"
                                [class.filled]="finalCode[1]"></div>
                        </div>
                        <div class="external-cam-input">
                            <input
                                maxlength="1"
                                type="number"
                                id="codeInput2"
                                #codeInput2
                                placeholder="3"
                                (keyup)="onInputKeyup($event, finalCode, 2, codeInput2, codeInput3, codeInput1)"
                                (click)="focusField(codeInput2)" />
                            <div
                                class="bottom-line"
                                [class.selected]="codeInput2 === focusedField"
                                [class.filled]="finalCode[2]"></div>
                        </div>
                        <div class="external-cam-input">
                            <input
                                maxlength="1"
                                type="number"
                                id="codeInput3"
                                #codeInput3
                                placeholder="4"
                                (keyup)="onInputKeyup($event, finalCode, 3, codeInput3, passInput0, codeInput2)"
                                (click)="focusField(codeInput3)" />
                            <div
                                class="bottom-line"
                                [class.selected]="codeInput3 === focusedField"
                                [class.filled]="finalCode[3]"></div>
                        </div>
                    </div>
                </div>

                <div class="external-cam-input-wrapper">
                    <div class="flex items-center justify-center">
                        <!-- Number inputs for code -->
                        <div class="external-cam-input">
                            <input
                                maxlength="1"
                                type="text"
                                id="passInput0"
                                #passInput0
                                placeholder="A"
                                (keyup)="onInputKeyup($event, pass, 0, passInput0, passInput1, codeInput3)"
                                (click)="focusField(passInput0)" />
                            <div
                                class="bottom-line"
                                [class.selected]="passInput0 === focusedField"
                                [class.filled]="pass[0]"></div>
                        </div>
                        <div class="external-cam-input">
                            <input
                                maxlength="1"
                                type="text"
                                id="passInput1"
                                #passInput1
                                placeholder="B"
                                (keyup)="onInputKeyup($event, pass, 1, passInput1, passInput2, passInput0)"
                                (click)="focusField(passInput1)" />
                            <div
                                class="bottom-line"
                                [class.selected]="passInput1 === focusedField"
                                [class.filled]="pass[1]"></div>
                        </div>
                        <div class="external-cam-input">
                            <input
                                maxlength="1"
                                type="text"
                                id="passInput2"
                                #passInput2
                                placeholder="C"
                                (keyup)="onInputKeyup($event, pass, 2, passInput2, passInput3, passInput1)"
                                (click)="focusField(passInput2)" />
                            <div
                                class="bottom-line"
                                [class.selected]="passInput2 === focusedField"
                                [class.filled]="pass[2]"></div>
                        </div>
                        <div class="external-cam-input">
                            <input
                                maxlength="1"
                                type="text"
                                id="passInput3"
                                #passInput3
                                placeholder="D"
                                (keyup)="onInputKeyup($event, pass, 3, passInput3, null, passInput2)"
                                (click)="focusField(passInput3)" />
                            <div
                                class="bottom-line"
                                [class.selected]="passInput3 === focusedField"
                                [class.filled]="pass[3]"></div>
                        </div>
                    </div>
                </div>

                <app-primary-button
                    class="mt-auto block pt-4"
                    size="normal"
                    addedClasses="w-full"
                    (click)="tryCode()"
                    [disabled]="finalCode?.toString().length !== 4 && pass.length !== 4"
                    [translate]="'CONNECT'"
                    i18n="@@CONNECT">
                    Connect
                </app-primary-button>
            </div>

            <div class="flex-remain flex flex-col px-5" *ngIf="currentSlide === 'smart_device'" [@slideAnimation]>
                <div
                    class="font-open-sans text-xl font-bold leading-none text-black"
                    [translate]="'SELECT_A_SMART_DEVICE'"
                    i18n="@@SELECT_A_SMART_DEVICE">
                    Select a smart device
                </div>
                <div class="mt-4 space-y-4 py-2" *ngIf="networks && cameraType === 'smart_device'">
                    <ng-container *ngFor="let network of networks; let networkIndex = index">
                        <div class="rounded-md bg-neutral-50 p-4" *ngIf="network.devices.length">
                            <div class="flex items-center justify-between">
                                <div class="text-xs text-black">
                                    <span [translate]="'NETWORK'" i18n="@@NETWORK">Network</span>: {{ network.name }}
                                </div>
                            </div>
                            <div class="mt-4 space-y-3">
                                @for (device of network.devices; track $index) {
                                    @if (device.type === 'virt_cam') {
                                        <div
                                            class="rounded-sm bg-neutral-100 px-4 py-3"
                                            (click)="selectSmartDevice(network, device)">
                                            <app-smart-device-item [smartDevice]="device"></app-smart-device-item>
                                        </div>
                                    }
                                }
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div
                class="flex-remain flex flex-col px-5 text-center overflow-auto"
                *ngIf="currentSlide === 'web_smart_device'"
                [@slideAnimation]>
                <div
                    class="text-xl font-bold leading-none text-black"
                    [translate]="'CONNECT_VIRT_CAM_WEB_TITLE_1'"
                    i18n="@@CONNECT_VIRT_CAM_WEB_TITLE_1">
                    Connect via app
                </div>
                <div
                    class="mt-4 font-semibold text-neutral-400"
                    [translate]="'CONNECT_VIRT_CAM_WEB_DESCRIPTION_1'"
                    i18n="@@CONNECT_VIRT_CAM_WEB_DESCRIPTION_1">
                    Go to the DartCounter app on your phone or tablet and link your Target VirtCam.
                </div>

                <div
                    class="mt-8 text-xl font-bold leading-none text-black"
                    [translate]="'CONNECT_VIRT_CAM_WEB_TITLE_2'"
                    i18n="@@CONNECT_VIRT_CAM_WEB_TITLE_2">
                    View smart device
                </div>
                <div
                    class="mt-4 font-semibold text-neutral-400"
                    [translate]="'CONNECT_VIRT_CAM_WEB_DESCRIPTION_2'"
                    i18n="@@CONNECT_VIRT_CAM_WEB_DESCRIPTION_2">
                    Go to your smart devices and click on the Virt you want to connect.
                </div>

                <div
                    class="mt-8 text-xl font-bold leading-none text-black"
                    [translate]="'CONNECT_VIRT_CAM_WEB_TITLE_3'"
                    i18n="@@CONNECT_VIRT_CAM_WEB_TITLE_3">
                    Retrieve the code and password
                </div>
                <div
                    class="mt-4 font-semibold text-neutral-400"
                    [translate]="'CONNECT_VIRT_CAM_WEB_DESCRIPTION_3'"
                    i18n="@@CONNECT_VIRT_CAM_WEB_DESCRIPTION_3">
                    Click on the 'Get code' button to display a code and password.
                </div>

                <div
                    class="mt-8 text-xl font-bold leading-none text-black"
                    [translate]="'CONNECT_VIRT_CAM_WEB_TITLE_4'"
                    i18n="@@CONNECT_VIRT_CAM_WEB_TITLE_4">
                    Play on web
                </div>
                <div
                    class="mt-4 font-semibold text-neutral-400"
                    [translate]="'CONNECT_VIRT_CAM_WEB_DESCRIPTION_4'"
                    i18n="@@CONNECT_VIRT_CAM_WEB_DESCRIPTION_4">
                    Fill in the code and password in the next step.
                </div>

                <app-primary-button
                    class="mt-auto block pt-4"
                    size="normal"
                    addedClasses="w-full"
                    (click)="goToSlide('external_code')"
                    [translate]="'CONTINUE'"
                    i18n="@@CONTINUE">
                    Continue
                </app-primary-button>
            </div>

            <div
                class="flex-remain flex flex-col px-5 overflow-y-auto"
                *ngIf="currentSlide === 'end'"
                [@slideAnimation]>
                @if (userMedia?.videoStreams.hasStreams === false && !smartDeviceService.startStreamError) {
                    <app-icon icon="loading" size="extralarge" class="mx-auto animate-spin text-orange"></app-icon>
                }

                <div
                    class="mt-6 text-center text-lg font-semibold text-neutral-400"
                    *ngIf="
                        userMedia?.videoStreams.hasStreams === false &&
                        smartDeviceService.startStreamStatus &&
                        !smartDeviceService.startStreamError
                    ">
                    <span
                        *ngIf="smartDeviceService.startStreamStatus === 'server'"
                        [translate]="'WAITING_FOR_SERVER_DOTS'"
                        i18n="@@WAITING_FOR_SERVER_DOTS">
                        Waiting for server...
                    </span>
                    <span
                        *ngIf="smartDeviceService.startStreamStatus === 'client'"
                        [translate]="'STARTING_CAMERA_STREAMS_DOTS'"
                        i18n="@@STARTING_CAMERA_STREAMS_DOTS">
                        Starting camera streams...
                    </span>
                    <span
                        *ngIf="smartDeviceService.startStreamStatus === 'restarting'"
                        [translate]="'RESTARTING_CAMERA_STREAMS_DOTS'"
                        i18n="@@RESTARTING_CAMERA_STREAMS_DOTS">
                        Restarting camera streams...
                    </span>
                </div>
                <div
                    class="mt-6 text-center text-lg font-semibold text-neutral-400"
                    *ngIf="userMedia?.videoStreams.hasStreams === false && smartDeviceService.startStreamError">
                    <span
                        *ngIf="smartDeviceService.startStreamError === 'server'"
                        [translate]="'SERVER_CONNECTION_FAILED'"
                        i18n="@@SERVER_CONNECTION_FAILED">
                        Server connection failed
                    </span>
                    <span
                        *ngIf="smartDeviceService.startStreamError === 'client'"
                        [translate]="'STARTING_CAMERA_STREAMS_FAILED'"
                        i18n="@@STARTING_CAMERA_STREAMS_FAILED">
                        Starting camera streams failed
                    </span>
                    <span
                        *ngIf="smartDeviceService.startStreamError === 'restarting'"
                        [translate]="'RESTARTING_CAMERA_STREAMS_FAILED_RESTART_MANUALLY'"
                        i18n="@@RESTARTING_CAMERA_STREAMS_FAILED_RESTART_MANUALLY">
                        Restarting camera streams failed, please restart manually
                    </span>
                </div>

                <app-primary-button
                    size="small"
                    color="white"
                    addedClasses="w-full mt-4"
                    fill="clear"
                    *ngIf="userMedia?.videoStreams.hasStreams === false && cameraType === 'smart_device'"
                    (click)="restartSmartDevice()">
                    <div [translate]="'IS_IT_TAKING_TOO_LONG'" i18n="@@IS_IT_TAKING_TOO_LONG">
                        Is it taking too long?
                    </div>
                    <div class="text-xs underline" [translate]="'CLICK_HERE_TO_RESTART'" i18n="@@CLICK_HERE_TO_RESTART">
                        Click here to restart
                    </div>
                </app-primary-button>

                @if (userMedia.videoStreams.hasStreams) {
                    <div
                        class="flex gap-4 overflow-y-visible"
                        [ngClass]="{ 'portrait:flex-col landscape:flex-row': !isWeb, 'flex-col': isWeb }">
                        <div
                            class="flex flex-row justify-center gap-2"
                            [ngClass]="{ 'portrait:w-full landscape:basis-1/2': !isWeb, 'w-full': isWeb }">
                            <div
                                class="overflow-hidden landscape:basis-1/2"
                                *ngIf="userMedia.videoStreams.board"
                                [ngClass]="{ 'basis-1/2': userMedia.videoStreams.player }">
                                <video
                                    *ngIf="userMedia.videoStreams.board.stream"
                                    class="ingame-camera mx-auto"
                                    [srcObject]="userMedia.videoStreams.board.stream"
                                    [style.transform]="userMedia.videoStreams.board.scale"
                                    autoplay
                                    playsinline
                                    webkit-playsinline
                                    muted></video>
                            </div>
                            <div
                                class="relative overflow-hidden landscape:basis-1/2"
                                *ngIf="userMedia.videoStreams.player"
                                [class.bg-gray-50]="
                                    userMedia.videoStreams.player.stream && disableVirtCamOption !== 'none'
                                "
                                [ngClass]="{ 'basis-1/2': userMedia.videoStreams.board }">
                                <img
                                    class="absolute bottom-0 left-0 right-0 top-0 m-auto w-28 grayscale"
                                    src="assets/icon/dctarget_stacked_white.png"
                                    *ngIf="userMedia.videoStreams.player.stream && disableVirtCamOption !== 'none'" />
                                <video
                                    *ngIf="userMedia.videoStreams.player.stream"
                                    [class.opacity-0]="disableVirtCamOption !== 'none'"
                                    class="ingame-camera mx-auto"
                                    [srcObject]="userMedia.videoStreams.player.stream"
                                    autoplay
                                    playsinline
                                    webkit-playsinline
                                    muted></video>
                            </div>
                        </div>

                        <div [ngClass]="{ 'portrait:w-full landscape:basis-1/2': !isWeb, 'w-full': isWeb }">
                            <div class="mt-4 flex items-center justify-between" *ngIf="cameraType === 'current_device'">
                                @if (userMediaService.selectRearCam && userMediaService.rearCameraDevices.length) {
                                    <app-select
                                        class="block w-full"
                                        [initialValue]="userMediaService.selectedRearVideoDevice"
                                        [options]="userMediaService.getRearCameraDevicesOptions()"
                                        (selectionChange)="
                                            userMediaService.selectedRearVideoDevice = $event; updatePreview(userMedia)
                                        "
                                        mode="dialog-input"
                                        size="full"></app-select>
                                }

                                @if (!userMediaService.selectRearCam) {
                                    <app-select
                                        class="block w-full"
                                        [initialValue]="userMediaService.selectedFaceVideoDevice"
                                        [options]="userMediaService.getFaceCameraDevicesOptions()"
                                        (selectionChange)="
                                            userMediaService.selectedFaceVideoDevice = $event; updatePreview(userMedia)
                                        "
                                        mode="dialog-input"
                                        size="full"></app-select>
                                }

                                @if (userMediaService.faceCameraDevices?.length || !userMediaService.selectRearCam) {
                                    <app-icon
                                        icon="camera_reverse_outline"
                                        class="text-black ml-4 mr-2"
                                        size="medium"
                                        (click)="userMediaService.reverseCamera(userMedia)"></app-icon>
                                }
                            </div>

                            <div
                                class="overflow-none mt-4 overscroll-none"
                                *ngIf="
                                    userMediaService.isZoomSupported !== null &&
                                    cameraType === 'current_device' &&
                                    userMedia.videoStreams.hasStreams
                                ">
                                <div
                                    class="px-2 text-xl font-bold uppercase text-black"
                                    [translate]="'ZOOM'"
                                    i18n="@@ZOOM">
                                    Zoom
                                </div>
                                <ion-range
                                    class="flex-none py-0"
                                    [min]="userMediaService.camCapabilities.zoom.min"
                                    [max]="userMediaService.camCapabilities.zoom.max"
                                    [step]="userMediaService.camCapabilities.zoom.step"
                                    name="zoomLevel"
                                    ngDefaultControl
                                    [(ngModel)]="userMediaService.zoomLevel"
                                    (ionChange)="userMediaService.updateCamSettings(userMedia)"
                                    color="secondary">
                                </ion-range>
                            </div>

                            <div class="mt-4 flex flex-col space-y-2">
                                @if (cameraType === 'smart_device') {
                                    <app-select
                                        class="block w-full"
                                        [initialValue]="disableVirtCamOption"
                                        [options]="disableVirtCamOptions"
                                        (selectionChange)="disableVirtCamOption = $event"
                                        mode="dialog-input"
                                        size="full"></app-select>
                                }
                            </div>
                        </div>
                    </div>
                }

                <app-primary-button
                    class="block pt-8"
                    size="normal"
                    addedClasses="w-full"
                    (click)="confirm()"
                    [disabled]="!userMedia.videoStreams.hasStreams">
                    @if (!isConfirmed) {
                        <span [translate]="'SAVE'" i18n="@@SAVE">Save</span>
                    } @else {
                        <span [translate]="'PLEASE_WAIT'" i18n="@@PLEASE_WAIT">Please wait</span>
                    }
                </app-primary-button>
            </div>
        </div>
    </div>
</div>
