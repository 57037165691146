<!-- logged in menu (ionOpen)="showAds()" (ionClose)="hideAds()" -->
<ion-menu
    side="end"
    contentId="main"
    class="ingame-menu text-black scrollbar-hide"
    menuId="ingamemenu"
    (ionWillOpen)="onMenuOpen()">
    <div class="flex flex-col h-full bg-white-pattern">
        <div class="pt-safe-offset-4 flex items-center space-x-4 px-4">
            <app-option-button icon="settings" [clickable]="false"></app-option-button>
            <div class="font-bebas-neue text-3xl" [translate]="'SETTINGS'" i18n="@@SETTINGS">Settings</div>
        </div>

        @if (auth.user && ingameData) {
            @if (ingameMode === 'match') {
                <div class="mt-6 flex flex-wrap gap-2 items-start px-4">
                    <div class="rounded-full bg-blue px-2 text-xxxs font-bold uppercase !text-white">
                        {{ ingameData.start_with | entryMode }}
                    </div>
                    <div class="rounded-full bg-blue px-2 text-xxxs font-bold uppercase !text-white">
                        {{ ingameData.end_with | finishMode }}
                    </div>
                    <div
                        class="rounded-full bg-blue px-2 text-xxxs font-bold uppercase !text-white"
                        *ngIf="ingameData.two_legs_difference"
                        [translate]="'TWO_LEGS_DIFFERENCE'"
                        i18n="@@TWO_LEGS_DIFFERENCE">
                        Two legs difference
                    </div>
                    <div
                        class="rounded-full bg-blue px-2 text-xxxs font-bold uppercase !text-white"
                        *ngIf="ingameData.enable_sudden_death"
                        [translate]="'SUDDEN_DEATH_MODE'"
                        i18n="@@SUDDEN_DEATH_MODE">
                        Sudden death mode
                    </div>
                </div>
            }

            <div class="mt-4 space-y-0">
                @if (ingameMode === 'match') {
                    <!-- General -->
                    <div>
                        <div
                            class="font-bebas-neue text-[1.375rem] bg-neutral-100 dark:bg-black py-2 px-4"
                            [translate]="'GENERAL'"
                            i18n="@@GENERAL">
                            General
                        </div>
                        <div class="divide-y-1 divide-neutral-100">
                            <app-toggle-switch
                                class="block w-full bg-game-card-bottom px-4 py-2"
                                [model]="preferenceService.showCheckoutHint"
                                mode="game_menu"
                                (itemChange)="
                                    preferenceService.showCheckoutHint = !preferenceService.showCheckoutHint;
                                    preferenceService.savePreferences()
                                "
                                [translate]="'SHOW_CHECKOUT'"
                                i18n="@@SHOW_CHECKOUT">
                                Show checkout
                            </app-toggle-switch>
                            @if (ingameData.is_online) {
                                <app-toggle-switch
                                    class="block w-full bg-game-card-bottom px-4 py-2"
                                    [model]="preferenceService.showInGameChat"
                                    mode="game_menu"
                                    (itemChange)="
                                        preferenceService.showInGameChat = !preferenceService.showInGameChat;
                                        preferenceService.savePreferences()
                                    "
                                    [translate]="'SHOW_IN_GAME_CHAT'"
                                    i18n="@@SHOW_IN_GAME_CHAT">
                                    Show in-game chat
                                </app-toggle-switch>
                            }
                        </div>
                    </div>
                }

                <!-- Audio -->
                <div>
                    <div
                        class="font-bebas-neue text-[1.375rem] bg-neutral-100 dark:bg-black py-2 px-4"
                        [translate]="'SOUND_SETTINGS'"
                        i18n="@@SOUND_SETTINGS">
                        Audio
                    </div>
                    <div class="divide-y-1 divide-neutral-100">
                        <app-toggle-switch
                            class="block w-full bg-game-card-bottom px-4 py-2"
                            [model]="preferenceService.allowCaller"
                            mode="game_menu"
                            (itemChange)="
                                preferenceService.allowCaller = !preferenceService.allowCaller;
                                preferenceService.savePreferences()
                            ">
                            MasterCaller
                        </app-toggle-switch>
                        @if (appFeaturesService.enabledAppFeatures.speech_to_score) {
                            <div
                                (click)="showSpeechToScore()"
                                class="block w-full bg-game-card-bottom px-4 py-2 text-black"
                                [translate]="'SPEECH_TO_SCORE'"
                                i18n="@@SPEECH_TO_SCORE">
                                Speech to score
                            </div>
                        }
                    </div>
                </div>

                <!-- Advanced sound settings -->
                @if (ingameMode === 'match') {
                    <div>
                        <div
                            class="font-bebas-neue text-[1.375rem] bg-neutral-100 dark:bg-black py-2 px-4"
                            [translate]="'ADVANCED_SOUND_SETTINGS'"
                            i18n="@@ADVANCED_SOUND_SETTINGS">
                            Advanced sound settings
                        </div>
                        <div class="divide-y-1 divide-neutral-100">
                            @if (ingameData.vs_cpu) {
                                <app-toggle-switch
                                    class="block w-full bg-game-card-bottom px-4 py-2"
                                    [model]="preferenceService.callDartbot"
                                    mode="game_menu"
                                    [disabled]="!preferenceService.allowCaller || !auth.user.is_ultimate"
                                    (itemChange)="
                                        preferenceService.callDartbot = !preferenceService.callDartbot;
                                        preferenceService.savePreferences()
                                    "
                                    [translate]="'DARTBOT'"
                                    i18n="@@DARTBOT">
                                    Dartbot
                                </app-toggle-switch>
                            }
                            <app-toggle-switch
                                class="block w-full bg-game-card-bottom px-4 py-2 text-black"
                                [model]="preferenceService.callYouRequire"
                                mode="game_menu"
                                [disabled]="!preferenceService.allowCaller || !auth.user.is_ultimate"
                                (itemChange)="
                                    preferenceService.callYouRequire = !preferenceService.callYouRequire;
                                    preferenceService.savePreferences()
                                "
                                [translate]="'CALL_YOU_REQUIRE'"
                                i18n="@@CALL_YOU_REQUIRE">
                                'You require'
                            </app-toggle-switch>
                            @if (ingameData.is_online) {
                                <app-toggle-switch
                                    class="block w-full bg-game-card-bottom px-4 py-2"
                                    [model]="preferenceService.callOmniImmediately"
                                    mode="game_menu"
                                    (itemChange)="
                                        preferenceService.callOmniImmediately = !preferenceService.callOmniImmediately;
                                        preferenceService.savePreferences()
                                    "
                                    [translate]="'CALL_OPPONENT_OMNI_IMMEDIATELY'"
                                    i18n="@@CALL_OPPONENT_OMNI_IMMEDIATELY">
                                    Play opponents OMNI score immediately
                                </app-toggle-switch>
                            }
                            @if (!auth.user.is_ultimate) {
                                <app-primary-button
                                    addedClasses="w-full"
                                    color="blue"
                                    size="normal"
                                    (click)="OpenSubscriptionDialog()">
                                    <div class="flex space-x-2 items-center">
                                        <svg
                                            width="32"
                                            height="32"
                                            viewBox="0 0 32 32"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#ultimate_ribbon_clip_path_ingame_menu)">
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M30.5079 15.9178L27.2712 12.2168L27.7222 7.32191L22.9334 6.23416L20.4263 1.98926L15.9161 3.92599L11.4059 1.98926L8.89871 6.22089L4.10993 7.29538L4.56095 12.2035L1.32422 15.9178L4.56095 19.6188L4.10993 24.527L8.89871 25.6148L11.4059 29.8464L15.9161 27.8964L20.4263 29.8331L22.9334 25.6015L27.7222 24.5137L27.2712 19.6188L30.5079 15.9178ZM15.9161 15.9176C17.3813 15.9176 18.5691 14.7298 18.5691 13.2645C18.5691 11.7993 17.3813 10.6114 15.9161 10.6114C14.4508 10.6114 13.263 11.7993 13.263 13.2645C13.263 14.7298 14.4508 15.9176 15.9161 15.9176ZM10.611 19.8977C10.611 18.1334 14.1462 17.2446 15.9171 17.2446C17.688 17.2446 21.2232 18.1334 21.2232 19.8977V20.5609C21.2232 20.9257 20.9247 21.2242 20.5599 21.2242H11.2742C10.9094 21.2242 10.611 20.9257 10.611 20.5609V19.8977Z"
                                                    fill="url(#ultimate_ribbon_linear_gradient_ingame_menu)" />
                                            </g>
                                            <defs>
                                                <linearGradient
                                                    id="ultimate_ribbon_linear_gradient_ingame_menu"
                                                    x1="15.9161"
                                                    y1="1.98926"
                                                    x2="15.9161"
                                                    y2="29.8464"
                                                    gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#ECD676" />
                                                    <stop offset="0.24" stop-color="#D39E3A" />
                                                    <stop offset="0.655" stop-color="#EDB948" />
                                                    <stop offset="1" stop-color="#BB8B32" />
                                                </linearGradient>
                                                <clipPath id="ultimate_ribbon_clip_path_ingame_menu">
                                                    <rect width="31.8367" height="31.8367" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <span [translate]="'UPGRADE_TO_ULTIMATE'" i18n="@@UPGRADE_TO_ULTIMATE"
                                            >Upgrade to Ultimate</span
                                        >
                                    </div>
                                </app-primary-button>
                            }
                        </div>
                    </div>
                }

                @if (ingameMode === 'match') {
                    <!-- Statistics -->
                    <div>
                        <div
                            class="font-bebas-neue text-[1.375rem] bg-neutral-100 dark:bg-black py-2 px-4"
                            [translate]="'STATISTICS'"
                            i18n="@@STATISTICS">
                            Statistics
                        </div>
                        <div class="divide-y-1 divide-neutral-100">
                            <app-toggle-switch
                                class="block w-full bg-game-card-bottom px-4 py-2 text-black"
                                [model]="preferenceService.showAvg"
                                mode="game_menu"
                                (itemChange)="
                                    preferenceService.showAvg = !preferenceService.showAvg;
                                    preferenceService.savePreferences()
                                "
                                [translate]="'SHOW_AVG_IN_MATCH'"
                                i18n="@@SHOW_AVG_IN_MATCH">
                                Show avg. in match
                            </app-toggle-switch>
                        </div>
                    </div>
                }

                @if (
                    appFeaturesService.enabledAppFeatures.action_replays &&
                    videoRoomService.ownCamera.roomID &&
                    videoRoomService.ownUserMedia?.videoStreams?.hasStreams &&
                    ingameData.is_online &&
                    ingameMode === 'match'
                ) {
                    <!-- Action Replays -->
                    <div>
                        <div
                            class="font-bebas-neue text-[1.375rem] bg-neutral-100 dark:bg-black py-2 px-4"
                            [translate]="'ACTION_REPLAYS'"
                            i18n="@@ACTION_REPLAYS">
                            Action Replays
                        </div>
                        <div class="divide-y-1 divide-neutral-100">
                            <app-toggle-switch
                                class="block w-full bg-game-card-bottom px-4 py-2"
                                [model]="preferenceService.enableActionReplays"
                                mode="game_menu"
                                (itemChange)="toggleActionReplays()"
                                [translate]="'ENABLE_ACTiON_REPLAYS'"
                                i18n="@@ENABLE_ACTiON_REPLAYS">
                                Enable Action Replays
                            </app-toggle-switch>
                            <app-toggle-switch
                                class="block w-full bg-game-card-bottom px-4 py-2"
                                [model]="preferenceService.promptActionReplay"
                                mode="game_menu"
                                [disabled]="!preferenceService.enableActionReplays"
                                (itemChange)="
                                    preferenceService.promptActionReplay = !preferenceService.promptActionReplay;
                                    preferenceService.savePreferences()
                                "
                                [translate]="'SHOW_ACTION_REPLAY_SLIDER'"
                                i18n="@@SHOW_ACTION_REPLAY_SLIDER">
                                Show Action Replay slider
                            </app-toggle-switch>
                        </div>
                    </div>
                }

                <!-- Dartbot -->
                @if (ingameMode === 'match' && ingameData.vs_cpu) {
                    <div>
                        <div
                            class="font-bebas-neue text-[1.375rem] bg-neutral-100 dark:bg-black py-2 px-4"
                            [translate]="'DARTBOT'"
                            i18n="@@DARTBOT">
                            Dartbot
                        </div>
                        <div class="divide-y-1 divide-neutral-100">
                            <div class="block w-full bg-game-card-bottom px-4 pt-2 text-black">
                                <div class="flex items-center space-x-2">
                                    <div [translate]="'DARTBOT_TIMEOUT'" i18n="@@DARTBOT_TIMEOUT">Dartbot timeout</div>
                                    <span class="text-black font-bold"> {{ preferenceService.dartbotTimeout }}s</span>
                                </div>
                                <ion-range
                                    min="2"
                                    step=".5"
                                    class="px-[0.3rem] py-0"
                                    style="--bar-background: white"
                                    [max]="15"
                                    (ionChange)="preferenceService.savePreferences()"
                                    name="dartbotTimeout"
                                    ngDefaultControl
                                    [(ngModel)]="preferenceService.dartbotTimeout"
                                    color="secondary">
                                </ion-range>
                            </div>
                        </div>
                    </div>
                }
            </div>
        }

        <div class="mt-auto pb-2 text-neutral-700 text-sm text-center">
            @if (version) {
                <p class="appVersion">v{{ version }}</p>
            } @else {
                <p class="appVersion">v0.0.0</p>
            }
        </div>
    </div>
</ion-menu>
