import { Component, Input } from '@angular/core';
import { DCFireStoreUser } from '@dc-core/dc-firestore/globals/firestore.tables';

@Component({
    selector: 'app-online-player-stats',
    templateUrl: 'online-player-stats.component.html',
})
export class OnlinePlayerStatsComponent {
    @Input() player: DCFireStoreUser;
    @Input() reviewCount?: number;
    @Input() mode?: 'dark' | 'light';
    @Input() size?: 'xs' | 'sm' | 'base' = 'xs';
}
