/* eslint-disable max-len */
import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { SmartDeviceApiService } from '@dc-api/smart-device.api.service';
import { SmartDevice } from '@dc-core/dc-backend/dc-classes';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { CAMERA_TYPE, FacingModes, JanusRoom, StreamDisplayMetaData } from '@dc-core/dc-services/camera/camera.models';
import { UserMedia } from '@dc-core/dc-services/dc-janus/DartCounterUserMedia';
import { JanusVideoRoomService } from '@dc-core/dc-services/dc-janus/janus-video-room.service';
import { SmartDeviceFeatureVersions } from '@dc-core/dc-services/smart-device/smart-device-feature-versions';
import { TranslateService } from '@ngx-translate/core';
import { VirtServerService } from '@services/virt-server.service';
import { Subscription, take } from 'rxjs';
import { SmartDeviceStatus } from 'src/pages/online/online-games/online-games.component';

import { UDPService, UDPSmartDeviceInfo } from './udp.service';

export interface DeviceMetaData {
    name: string;
    facingMode?: FacingModes;
    port?: string;
    status?: CameraDeviceStatus;
}

export enum CameraDeviceStatus {
    Connecting = 'CONNECTING',
    Disconnecting = 'DISCONNECTING',
    Created = 'CREATED',
    Connected = 'CONNECTED',
    Joined = 'JOINED',
    Published = 'PUBLISHED',
    Restarting = 'RESTARTING',
    Disconnected = 'DISCONNECTED',
    Reconnecting = 'RECONNECTING',
    Stopped = 'STOPPED',
    Unavailable = 'UNAVAILABLE',
}

export type JanusStatusCheck = {
    type: 'off' | 'ready' | 'streaming';
    room?: number | null;
    hostname?: string | null;
    ws_port?: number | null;
};

@Injectable()
export class SmartDeviceService {
    private alertService: DartCounterAlertService = inject(DartCounterAlertService);
    private udpService: UDPService = inject(UDPService);
    private virtServerService: VirtServerService = inject(VirtServerService);
    private smartDeviceApiService: SmartDeviceApiService = inject(SmartDeviceApiService);
    private videoRoomService: JanusVideoRoomService = inject(JanusVideoRoomService);
    private translateService: TranslateService = inject(TranslateService);

    public cameraPort = 443;
    public virtServerPort = 8070;
    public deviceTurnedOnCheck = false;

    public startStreamStatus: 'server' | 'client' | 'restarting' = null;
    public startStreamError: 'server' | 'client' | 'restarting' = null;
    public startStreamTimeout: any = null;

    public userMedia: UserMedia = new UserMedia();

    private _udpSubscription: Subscription;

    public omniStatus: WritableSignal<SmartDeviceStatus> = signal(null);
    public virtStatus: WritableSignal<SmartDeviceStatus> = signal(null);

    initSmartDevice(smartDevice: SmartDevice): void {
        if (!smartDevice) {
            return;
        }

        this.clean();

        switch (smartDevice.type) {
            case 'virt_cam':
                this.getVersion(smartDevice);
                break;
        }
    }

    checkStatus(smartDevice: SmartDevice): Promise<JanusStatusCheck> {
        return new Promise((resolve) => {
            this.virtServerService
                .sendToVirt({}, smartDevice.ip_address + ':' + this.virtServerPort + '/status', 3)
                .pipe(take(1))
                .subscribe({
                    next: (res) => {
                        if (
                            typeof res === 'object' &&
                            JSON.stringify(res).toString().indexOf('The request timed out') >= 0
                        ) {
                            resolve({ type: 'off' });
                        } else if (res.toString().indexOf('An error occurred') >= 0) {
                            resolve({ type: 'off' });
                        } else if (res.room) {
                            resolve({
                                type: 'streaming',
                                room: res.room,
                                hostname: res.hostname,
                                ws_port: res.ws_port,
                            });
                        } else {
                            resolve({ type: 'ready' });
                        }
                    },
                    error: () => {
                        resolve({ type: 'off' });
                    },
                });
        });
    }

    startStreaming(smartDevice: SmartDevice, useAsCamera: boolean): Promise<JanusRoom> {
        return new Promise((resolve, reject) => {
            if (this.startStreamTimeout) {
                clearTimeout(this.startStreamTimeout);
            }

            this.virtServerService
                .sendToVirt({}, smartDevice.ip_address + ':' + this.virtServerPort + '/status', 3)
                .pipe(take(1))
                .subscribe({
                    next: (res) => {
                        if (
                            typeof res === 'object' &&
                            JSON.stringify(res).toString().indexOf('The request timed out') >= 0
                        ) {
                            this.connectionTimedOut(smartDevice, useAsCamera);
                            reject();
                        } else if (res.toString().indexOf('An error occurred') >= 0) {
                            reject();
                        } else if (res.room) {
                            if (useAsCamera) {
                                this.videoRoomService.smartDevice = smartDevice;
                            }

                            this.startStreamStatus = null;
                            this.startStreamError = null;

                            if (smartDevice.room != res.room) {
                                this.updateSmartDeviceRoom(smartDevice, res.room);
                            }

                            resolve({
                                roomID: res.room,
                                janusServerHost: res.hostname,
                                camType: CAMERA_TYPE.SMART_DEVICE,
                            });
                        } else {
                            if (useAsCamera) {
                                this.videoRoomService.smartDevice = smartDevice;
                            }

                            this.createRoom(smartDevice)
                                .then((janusRoom) => {
                                    this.startStreamStatus = 'server';
                                    this.startStreamError = null;

                                    const janusServer = this.videoRoomService.getJanusServer(janusRoom.janusServerHost);

                                    if (!janusServer) {
                                        reject();
                                        return;
                                    }

                                    this.virtServerService
                                        .sendToVirt(
                                            {
                                                janus_endpoint: this.videoRoomService.getJanusEndpoint(janusServer),
                                                janus_hostname: janusServer.hostname,
                                                janus_ws_port: janusServer.ws_port,
                                                room: janusRoom.roomID,
                                            },
                                            smartDevice.ip_address + ':' + this.virtServerPort + '/start',
                                            10,
                                            'post'
                                        )
                                        .pipe(take(1))
                                        .subscribe({
                                            next: () => {
                                                this.startStreamStatus = 'client';

                                                const boardCamMetaData: StreamDisplayMetaData = {
                                                    facingMode: FacingModes.BOARD,
                                                    scale: 'scale(1)',
                                                    kind: 'virt',
                                                };
                                                const playerCamMetaData: StreamDisplayMetaData = {
                                                    facingMode: FacingModes.PLAYER,
                                                    scale: 'scale(1)',
                                                    kind: 'virt',
                                                };

                                                this.virtServerService
                                                    .sendToVirt(
                                                        {
                                                            // facing_modes: JSON.stringify([
                                                            //     FacingModes.BOARD,
                                                            //     FacingModes.PLAYER,
                                                            // ]),
                                                            board_cam_display: encodeURIComponent(
                                                                JSON.stringify(boardCamMetaData)
                                                            ),
                                                            player_cam_display: encodeURIComponent(
                                                                JSON.stringify(playerCamMetaData)
                                                            ),
                                                        },
                                                        smartDevice.ip_address + ':' + this.virtServerPort + '/stream',
                                                        10,
                                                        'post'
                                                    )
                                                    .pipe(take(1))
                                                    .subscribe({
                                                        next: () => {
                                                            this.startStreamTimeout = setTimeout(() => {
                                                                this.startStreamStatus = null;
                                                                this.startStreamError = null;
                                                                this.startStreamTimeout = null;
                                                            }, 5000);
                                                            resolve(janusRoom);
                                                        },
                                                        error: () => {
                                                            this.startStreamError = this.startStreamStatus;
                                                            reject();
                                                        },
                                                    });
                                            },
                                            error: () => {
                                                this.startStreamError = this.startStreamStatus;
                                                reject();
                                            },
                                        });
                                })
                                .catch(() => {
                                    this.startStreamError = this.startStreamStatus;
                                    reject();
                                });
                        }
                    },
                    error: (err) => {
                        console.error('Janus streaming page error', err);

                        this.connectionTimedOut(smartDevice, useAsCamera);
                        reject();
                    },
                });
        });
    }

    stopStreaming(smartDevice: SmartDevice, isRestarting: boolean, resolveTimeout: boolean): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!smartDevice || smartDevice.type !== 'virt_cam') {
                reject();
                return;
            }

            if (!smartDevice.version) {
                reject();
                return;
            }

            if (!this.isVersionSameOrAbove(smartDevice.version, SmartDeviceFeatureVersions.stopStreaming)) {
                reject();
                return;
            }

            if (this.startStreamTimeout) {
                clearTimeout(this.startStreamTimeout);
            }

            if (isRestarting) {
                this.startStreamStatus = 'restarting';
                this.startStreamError = null;
            }

            this.virtServerService
                .sendToVirt({}, smartDevice.ip_address + ':' + this.virtServerPort + '/stop', 10, 'post')
                .pipe(take(1))
                .subscribe({
                    next: (res) => {
                        if (
                            typeof res === 'object' &&
                            JSON.stringify(res).toString().indexOf('The request timed out') >= 0
                        ) {
                            if (isRestarting) {
                                this.startStreamError = 'restarting';
                            }
                            reject();
                        } else if (res.toString().indexOf('An error occurred') >= 0) {
                            if (isRestarting) {
                                this.startStreamError = 'restarting';
                            }
                            reject();
                        } else {
                            if (isRestarting) {
                                this.startStreamTimeout = setTimeout(() => {
                                    this.startStreamStatus = null;
                                    this.startStreamError = null;
                                    this.startStreamTimeout = null;
                                }, 3000);
                            }

                            setTimeout(
                                () => {
                                    resolve();
                                },
                                resolveTimeout ? 2000 : 0
                            );
                        }
                    },
                    error: (err) => {
                        console.error('Janus streaming page error', err);
                        reject();
                    },
                });
        });
    }

    getVersion(smartDevice: SmartDevice): void {
        if (!smartDevice || smartDevice.type !== 'virt_cam') {
            return;
        }

        try {
            this.virtServerService
                .sendToVirt({}, smartDevice.ip_address + ':' + this.virtServerPort + '/version', 10, 'get')
                .pipe(take(1))
                .subscribe({
                    next: (res) => {
                        if (res.version) {
                            if (smartDevice.id && smartDevice.version !== res.version) {
                                smartDevice.version = res.version;

                                this.smartDeviceApiService.updateSmartDeviceById({
                                    smartDeviceId: smartDevice.id,
                                    version: res.version,
                                });
                            }
                        }
                    },
                });
        } catch (_) {}
    }

    createRoom(smartDevice: SmartDevice): Promise<JanusRoom> {
        return new Promise((resolve, reject) => {
            this.videoRoomService.startSmartDevice().then(
                (janusRoom) => {
                    this.updateSmartDeviceRoom(smartDevice, janusRoom.roomID);

                    resolve(janusRoom);
                },
                (err) => {
                    this.alertService.createAlert({
                        icon: 'error',
                        title: err,
                    });
                    reject();
                }
            );
        });
    }

    updateSmartDeviceRoom(smartDevice: SmartDevice, room: number): void {
        if (smartDevice && smartDevice.room != room) {
            smartDevice.room = room;
            if (smartDevice.id) {
                this.smartDeviceApiService
                    .updateSmartDeviceById({
                        smartDeviceId: smartDevice.id,
                        room: room,
                    })
                    .then((res) => {
                        smartDevice = res.data;
                    })
                    .catch(console.error);
            }
        }
    }

    connectionTimedOut(smartDevice: SmartDevice, useAsCamera: boolean): void {
        if (this.deviceTurnedOnCheck) {
            return;
        }

        this.deviceTurnedOnCheck = true;

        $localize`:@@IS_YOUR_DEVICE_TURNED_ON_Q:Is your ${smartDevice.name}:device: turned on?`;
        this.alertService.createAlert({
            title: $localize`:@@NO_DEVICE_FOUND:No device found`,
            text: this.translateService.instant('IS_YOUR_DEVICE_TURNED_ON_Q', {
                device: smartDevice.name,
            }),
            cancelButtonText: $localize`:@@NO:No`,
            confirmButtonText: $localize`:@@YES:Yes`,
            icon: 'warning',
            timer: null,
            onConfirm: () => {
                this.deviceTurnedOnCheck = false;

                this.alertService.createAlert({
                    title: this.translateService.instant('SEARCHING_FOR_DEVICE_IN_YOUR_NETWORK', {
                        device: smartDevice.name,
                    }),
                    icon: 'info',
                    timer: 10000,
                    timerProgressBar: true,
                });

                this.checkForIPChange(smartDevice)
                    .then(() => {
                        $localize`:@@DEVICE_HAS_RECONNECTED:${smartDevice.name}:device: is reconnected`;
                        this.alertService.createAlert({
                            title: this.translateService.instant('DEVICE_HAS_RECONNECTED', {
                                device: smartDevice.name,
                            }),
                            icon: 'success',
                            timer: 3000,
                        });

                        setTimeout(() => {
                            this.startStreaming(smartDevice, useAsCamera);
                        }, 2000);
                    })
                    .catch(console.error);
            },
            onCancel: () => {
                this.deviceTurnedOnCheck = false;
                // Not turned on? Manual disconnect
                this.clean();
                smartDevice = null;

                $localize`:@@TURN_ON_DEVICE_AND_TRY_AGAIN:Turn on your ${smartDevice.name}:device: and try again!`;
                this.alertService.createAlert({
                    title: this.translateService.instant('TURN_ON_DEVICE_AND_TRY_AGAIN', {
                        device: smartDevice.name,
                    }),
                    icon: 'info',
                    timer: 3000,
                });
            },
        });
    }

    checkForIPChange(smartDevice: SmartDevice): Promise<string> {
        return new Promise((resolve, reject) => {
            if (this._udpSubscription) {
                this._udpSubscription.unsubscribe();
                this.udpService.stopUDPSocket();
            }

            // Auto stop searching after 10 seconds
            const timeout = setTimeout(() => {
                if (this._udpSubscription) {
                    this._udpSubscription.unsubscribe();
                    this.udpService.stopUDPSocket();
                }
                reject();
                clearTimeout(timeout);
            }, 10000);

            this._udpSubscription = this.udpService.watchUDPSocket(30000).subscribe((response: any) => {
                if (response.data) {
                    const parsedResponse: UDPSmartDeviceInfo = JSON.parse(response.data);
                    const guid = parsedResponse.guid;
                    const type = parsedResponse.type;

                    if (!guid || guid != smartDevice.guid) {
                        return;
                    }

                    const remoteAddress = response.remoteAddress;

                    let foundAddress = false;
                    if (remoteAddress && !remoteAddress.startsWith(':')) {
                        foundAddress = true;
                    }

                    let correctType = false;
                    switch (smartDevice.type) {
                        case 'virt_cam':
                            if (type === 'virt') {
                                correctType = true;
                            }
                            break;
                        case 'omni_scoring':
                            if (!type || type === 'omni') {
                                correctType = true;
                            }
                            break;
                    }

                    if (foundAddress && correctType) {
                        if (timeout) {
                            clearTimeout(timeout);
                        }

                        if (smartDevice.id) {
                            this.smartDeviceApiService
                                .updateSmartDeviceById({
                                    smartDeviceId: smartDevice.id,
                                    ip_address: remoteAddress,
                                    mac_address: parsedResponse.mac,
                                })
                                .catch(console.error);
                        }

                        smartDevice.ip_address = remoteAddress;
                        smartDevice.mac_address = remoteAddress;

                        this._udpSubscription.unsubscribe();
                        this._udpSubscription = null;
                        this.udpService.stopUDPSocket();

                        resolve(remoteAddress);
                    }
                }
            });
        });
    }

    compareVersions(featureVersion: string, deviceVersion: string): number {
        const featureParts = featureVersion.split('.').map(Number);
        const deviceParts = deviceVersion.split('.').map(Number);

        for (let i = 0; i < Math.max(featureParts.length, deviceParts.length); i++) {
            const featurePart = featureParts[i] || 0;
            const devicePart = deviceParts[i] || 0;

            if (featurePart > devicePart) {
                return 1;
            }
            if (featurePart < devicePart) {
                return -1;
            }
        }
        return 0;
    }

    isVersionSameOrAbove(featureVersion: string, deviceVersion: string): boolean {
        return this.compareVersions(featureVersion, deviceVersion) >= 0;
    }

    clean(): void {
        if (this._udpSubscription) {
            this._udpSubscription.unsubscribe();
            delete this._udpSubscription;
        }

        this.deviceTurnedOnCheck = false;

        if (this.startStreamTimeout) {
            clearTimeout(this.startStreamTimeout);
        }

        this.startStreamStatus = null;
        this.startStreamError = null;
    }
}
