export enum SUBSCRIPTION_KEY {
    OUTGOING_INVITES = 'watchOutgoingInvites',

    UNFINISHED_GAMES = 'unfinishedGames',
    OWN_LOBBY_GAMES = 'ownLobbyGames',
    LOBBY_GAMES = 'lobbyGames',
    PUBLIC_GAMES = 'publicGames',
    STARTED_GAMES = 'startedGames',

    WATCH_OWN_ACTIVE_GAME = 'watch_own_active_game',
    INCOMING_INVITES = 'incoming_invites',
    INCOMING_RATINGS = 'incoming_ratings',
    BACKEND_EVENTS = 'backend_events',
    ONLINE_USERS = 'online_users',
    FRIENDS = 'friends',
    PLAYING_USERS = 'playing_users',

    //ONLINE MATCH
    SCREENORIENTATION = 'ScreenOrientation',
    ROUTEPARAMS = 'routeparams',
    OMNI_EVENTS = 'omni_events',
    OMNI_REQUESTS = 'omni_requests',
    OMNI_STATES = 'omni_requests',
    SINGLE_DART_EVENTS = 'single_dart_events',
    GAME_EVENTS = 'game_events',
    SCORE_EVENTS = 'score_events',
    PAUSES = 'pauses',
    CORE_PAUSES = 'corePauseFeature',
    ACTIONS = 'actions',
    END_GAME_ACTIONS = 'end_game_actions',
    CHAT = 'chat',
    SPECTATORS = 'spectators',
    LOGGED_IN = 'logged_in',
    INCOMING_CHAT = 'incoming_chat',
    CAMERA_ACTIONS = 'camera_actions',
    VOICE_CALL_ACTIONS = 'voice_call_actions',
    SENT_SOUNDS = 'sent_sounds',
    INCOMING_SOUNDS = 'incoming_sounds',
    ONLINE_GAME_EVENTS = 'online_game_events',
    ONLINE_GAME_STATE_ACTIONS = 'online_game_state_actions',
    ONLINE_LOCAL_ACTIONS = 'online_local_actions',

    MEDIA_WATCHER = 'media_watcher',
}

export enum INTERVAL_KEY {
    USER_LIST = 'user_list',
    LIVE_GAMES = 'live_games',
    OUTGOING_INVITE_TIMER = 'outgoing_invite_timer',
    INGAME_TIMER = 'ingame_timer',
    PAUSE_TIMER = 'pause_timer',
    PREGAME_TIMER = 'pregame_timer',
    WAITFORSUBMIT_TIMER = 'waitforsubmit_timer',
    INVITATION_TIMEOUT = 'invitation_timeout',
}
