@if (false) {
    <ng-container i18n="@@SEARCH">Search</ng-container>
    <ng-container i18n="@@CAM_TUTORIAL_TITLE">Link your camera</ng-container>
    <ng-container i18n="@@CAM_TUTORIAL_TEXT">Tap here to play online with camera</ng-container>
}

<app-page-header mainNavigation="custom" defaultBack="online-games">
    <div left class="flex items-center cursor-pointer -ml-2" (click)="goBack()">
        <app-icon icon="keyboard_arrow_left" size="large"></app-icon>
        <div class="text-center text-sm font-bold !text-white" [translate]="'ONLINE'" i18n="@@ONLINE">Online</div>
    </div>
    <div title [translate]="'GLOBAL_MODE'" i18n="@@GLOBAL_MODE">Global lobby</div>
    <div right class="right-page-header">
        <app-control-camera-icon [refreshGame]="true"></app-control-camera-icon>
        @if (isWeb) {
            <app-fullscreen-icon></app-fullscreen-icon>
        }
    </div>
</app-page-header>

<div class="relative flex h-full flex-col overflow-hidden bg-white-pattern text-black">
    <div class="flex h-16 min-h-16 flex-none bg-white bg-opacity-80 text-xl font-bebas-neue landscape:px-safe">
        <div
            class="inline-flex basis-1/2 items-center justify-center cursor-pointer"
            [class.inner-border-b-2-orange]="segment === 'lobby'"
            [class.opacity-60]="segment !== 'lobby'"
            (click)="changeSegment('lobby')">
            <div [translate]="'LOBBY'" i18n="@@LOBBY">Lobby</div>
        </div>
        <div
            class="inline-flex basis-1/2 items-center justify-center cursor-pointer"
            [class.inner-border-b-2-orange]="segment === 'live'"
            [class.opacity-60]="segment !== 'live'"
            (click)="changeSegment('live')">
            <div [translate]="'LIVE_GAMES'" i18n="@@LIVE_GAMES">Live games</div>
        </div>
    </div>

    <div class="flex flex-auto flex-col overflow-auto overscroll-none landscape:px-safe">
        @if (segment === 'lobby') {
            <!-- Lobby -->
            <div class="grid grid-cols-1 gap-4 p-4 md:grid-cols-2 2xl:grid-cols-3">
                <!-- <app-activate-smart-devices
                    appWebContainer
                    class="block col-span-1 md:col-span-2 2xl:col-span-3"
                    [collapsible]="true"></app-activate-smart-devices> -->

                @if (!online.loaded) {
                    <div class="col-span-1 flex justify-center md:col-span-2 2xl:col-span-3" *ngIf="!online.loaded">
                        <ion-spinner class="h-10 w-10" color="secondary" name="crescent"></ion-spinner>
                    </div>
                }
                @if (!online.lobbyGames.length && online.loaded) {
                    <div
                        class="col-span-1 py-4 text-center font-bebas-neue text-xl text-black md:col-span-2 2xl:col-span-3"
                        [translate]="'NO_GAMES_IN_LOBBY'"
                        i18n="@@NO_GAMES_IN_LOBBY">
                        No games in lobby
                    </div>
                }
                @if (lobbySettingsService.currentGameInLobby) {
                    <ng-container
                        *ngTemplateOutlet="
                            lobbyGame;
                            context: { gameplay: lobbySettingsService.currentGameInLobby, yourGame: true }
                        "></ng-container>
                }

                @for (gameplay of online.lobbyGames; track $index) {
                    @if (
                        !online.isBlocked(gameplay.players[0].user_id) &&
                        gameplay.players[0].user_id !== online.user?.id
                    ) {
                        <ng-container
                            *ngTemplateOutlet="lobbyGame; context: { gameplay, yourGame: false }"></ng-container>
                    }
                }
            </div>
        } @else if (segment === 'live') {
            <!-- Live games -->
            <ion-content
                style="
                    --padding-start: 1rem !important;
                    --padding-end: 1rem !important;
                    --padding-top: 1rem !important;
                    --padding-bottom: 1rem !important;
                    --background: none !important;
                ">
                @if (online.loaded) {
                    <div
                        class="mb-4 flex w-full items-center space-x-2 rounded-md border border-neutral-500 px-4 py-3 focus-within:border-orange">
                        <ion-icon name="search-outline" class="ion-text-neutral-400"></ion-icon>
                        <input
                            class="w-full bg-transparent text-black placeholder:text-neutral-400"
                            [placeholder]="searchControlPlaceholder"
                            [formControl]="liveGamesSearchControl" />
                    </div>
                }

                @if (!online.loaded || online.liveGames === null) {
                    <div class="col-span-1 flex justify-center md:col-span-2">
                        <ion-spinner class="h-10 w-10" color="secondary" name="crescent"></ion-spinner>
                    </div>
                }
                @if (online.liveGames?.length === 0 && online.loaded) {
                    <div
                        class="py-4 text-center font-bebas-neue text-xl text-black"
                        [translate]="'NO_LIVE_GAMES'"
                        i18n="@@NO_LIVE_GAMES">
                        No live games
                    </div>
                }
                <div class="grid grid-cols-1 gap-4 md:grid-cols-2 2xl:grid-cols-3">
                    @if (online.liveGames !== null && online.loaded) {
                        @for (gameplay of online.liveGames; track $index) {
                            <ng-container *ngTemplateOutlet="liveGame; context: { gameplay }"></ng-container>
                        }
                    }
                </div>

                @if (online.liveGames !== null && online.loaded) {
                    <ion-infinite-scroll [threshold]="'20%'" (ionInfinite)="loadPublicGames($event)">
                        <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="{{ 'LOADING' | translate }}">
                        </ion-infinite-scroll-content>
                    </ion-infinite-scroll>
                }
            </ion-content>
        }
    </div>

    <div class="flex-none" [class.hidden]="segment !== 'lobby'">
        <div class="landscape:px-safe" [class.pb-safe]="!online.onlineSavedGameplay && online.user?.is_ultimate">
            <app-online-saved-gameplay class="block w-full"></app-online-saved-gameplay>
        </div>
        @if (online.user?.is_ultimate || (!online.user?.is_ultimate && freeMatchesService.canPlay)) {
            @if (online.onlineSavedGameplay && videoRoomService.ownCamera.roomID) {
                <div class="!bg-black pb-2 px-safe-offset-2">
                    <app-toggle-switch
                        class="block w-full rounded-md bg-panel px-3 py-2"
                        [model]="online.onlineSavedGameplay?.cameraOnly"
                        (itemChange)="toggleCameraOnly()"
                        [translate]="'CAMERA_ONLY'"
                        i18n="@@CAMERA_ONLY">
                        Camera only
                    </app-toggle-switch>
                </div>
            }
            <app-toggle-switch
                class="block w-full bg-panel py-3 px-safe-offset-4 text-black"
                [class.pb-safe-offset-3]="online.user?.is_ultimate"
                [model]="online.showGameInLobby"
                (itemChange)="toggleOwnGame()"
                *ngIf="online.onlineSavedGameplay">
                <span [translate]="'ADD_YOUR_GAME_IN_LOBBY'" i18n="@@ADD_YOUR_GAME_IN_LOBBY"
                    >Add your game to lobby</span
                >
            </app-toggle-switch>
        }
        <div
            class="flex items-center justify-between bg-white pt-3 pb-safe-offset-3 px-safe-offset-4 cursor-pointer"
            (click)="openUpgradeDialog()"
            *ngIf="!online.user?.is_ultimate">
            <div class="flex flex-col">
                <div class="flex items-center space-x-1 font-bold">
                    <div class="font-bebas-neue text-2xl text-orange">{{ freeMatchesService.count }}</div>
                    <div
                        class="text-black"
                        [translate]="'FREE_GAMES_LEFT_THIS_WEEK'"
                        i18n="@@FREE_GAMES_LEFT_THIS_WEEK">
                        FREE games left this week!
                    </div>
                </div>
                <div class="flex flex-wrap items-center gap-1 text-sm">
                    <div class="font-semibold text-neutral-400" [translate]="'GET_VERB'" i18n="@@GET_VERB">Get</div>
                    <div class="font-bold uppercase text-orange" [translate]="'ULTIMATE'" i18n="@@ULTIMATE">
                        ULTIMATE
                    </div>
                    <div
                        class="font-semibold text-neutral-400"
                        [translate]="'FOR_UNLIMITED_GAMES'"
                        i18n="@@FOR_UNLIMITED_GAMES">
                        for unlimited games
                    </div>
                </div>
            </div>
            <app-icon icon="keyboard_arrow_right" class="text-neutral-200"></app-icon>
        </div>
    </div>
</div>

<ng-template #lobbyGame let-gameplay="gameplay" let-yourGame="yourGame">
    <div class="global-lobby-shadow col-span-1 flex flex-col rounded-md h-fit">
        <div class="flex items-center justify-between rounded-t-md bg-game-card-top px-4 py-2.5">
            <div class="font-bold text-black">{{ onlineFunctions.onlineGameplayName(gameplay) }}</div>
            @if (gameplay.players[0].room) {
                <div
                    class="flex justify-center space-x-2 cursor-pointer"
                    (click)="showCameraPreview(gameplay, gameplay.players[0])">
                    <div
                        class="flex items-center rounded-sm bg-panel dark:bg-black-full text-black dark:text-white p-1.5">
                        <app-icon icon="videocam" size="small"></app-icon>
                        @if (gameplay.cameraOnly) {
                            <div
                                class="ml-1 text-xxxs font-bold uppercase leading-none"
                                [translate]="'CAM_ONLY'"
                                i18n="@@CAM_ONLY">
                                Cam only
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
        <div class="flex w-full flex-col rounded-b-md bg-game-card-bottom px-4 py-2">
            @if (gameplay.players[0].has_omni || gameplay.players[0].room?.camType === 'smart_device') {
                <div class="mb-[0.675rem] flex justify-center items-center rounded-md bg-neutral-50 space-x-1.5">
                    @if (gameplay.players[0].has_omni) {
                        <img class="w-16 py-2" [src]="'assets/icon/ftr/omni_scoring_logo.webp'" />
                    }
                    @if (gameplay.players[0].has_omni && gameplay.players[0].room?.camType === 'smart_device') {
                        <div class="text-white leading-none">+</div>
                    }
                    @if (gameplay.players[0].room?.camType === 'smart_device') {
                        <img class="w-16 py-2" [src]="'assets/icon/ftr/virt_cam_logo.webp'" />
                    }
                </div>
            }

            <div class="flex items-center justify-between space-x-2 overflow-hidden">
                <div
                    class="flex items-center justify-center space-x-2 overflow-hidden cursor-pointer text-black"
                    (click)="showPlayerInfo(gameplay.players[0])">
                    <app-profile-photo
                        class="h-fit"
                        [ultimate]="gameplay.players[0].is_ultimate"
                        [badge]="gameplay.players[0].badge"
                        [url]="gameplay.players[0].profile_photo_url"
                        [size]="32">
                    </app-profile-photo>
                    <div class="space-y-0.5 overflow-hidden">
                        <div class="flex items-center space-x-1">
                            <div class="ucfirst truncate text-xs font-bold leading-none">
                                {{ gameplay.players[0].first_name || ('NO_NAME' | translate) }}
                            </div>
                            @if (gameplay.players[0].countryFlag) {
                                <img class="h-2" src="assets/images/flags/rect/{{ gameplay.players[0].countryFlag }}" />
                            }
                        </div>
                        <app-online-player-stats [player]="gameplay.players[0]"></app-online-player-stats>
                    </div>
                </div>
                <div class="flex items-center space-x-2">
                    @if (yourGame) {
                        <div
                            class="rounded-full bg-black px-2 text-xxxs font-bold uppercase text-white whitespace-nowrap"
                            [translate]="'YOUR_GAME'"
                            i18n="@@YOUR_GAME">
                            Your game
                        </div>
                    }

                    <app-primary-button
                        size="small"
                        color="orange"
                        (click)="showGameInfo(gameplay)"
                        *ngIf="!yourGame && gameplay.doc_id !== dcInvites.currentOutgoingInvite?.gameplay_doc_id"
                        [translate]="'JOIN_EXCLAMATION'"
                        i18n="@@JOIN_EXCLAMATION">
                        Join!
                    </app-primary-button>
                    <div
                        class="flex items-center space-x-2 cursor-pointer"
                        (click)="cancelJoin()"
                        *ngIf="!yourGame && gameplay.doc_id === dcInvites.currentOutgoingInvite?.gameplay_doc_id">
                        <div class="h-full items-center rounded-sm bg-white px-2 py-1.5 text-black">
                            <div class="pt-0.5 font-bebas-neue text-xl leading-none">
                                {{ dcInvites.showTimer() }}
                            </div>
                        </div>
                        <app-primary-button size="small" color="red" [translate]="'CANCEL'" i18n="@@CANCEL">
                            Cancel
                        </app-primary-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #liveGame let-gameplay="gameplay">
    <div class="global-lobby-shadow col-span-1 flex flex-col rounded-md h-fit">
        <div class="flex items-center justify-between rounded-t-md bg-game-card-top px-4 py-2.5">
            <div class="font-bold text-black">{{ onlineFunctions.publicOnlineGameplayName(gameplay) }}</div>
            <app-primary-button
                size="small"
                color="orange"
                (click)="watch(gameplay)"
                *ngIf="!gameplay.isFinished"
                [translate]="'WATCH_GAME'"
                i18n="@@WATCH_GAME">
                Watch game
            </app-primary-button>
            <app-primary-button
                size="small"
                color="red"
                *ngIf="gameplay.isFinished"
                [translate]="'ENDED'"
                i18n="@@ENDED"
                >Ended</app-primary-button
            >
        </div>
        <div class="grid grid-cols-11 rounded-b-md bg-game-card-bottom px-4 py-2">
            @if (gameplay.has_omni || gameplay.has_virt_cam) {
                <div
                    class="col-span-11 mb-[0.675rem] flex justify-center items-center rounded-md bg-neutral-50 space-x-1.5">
                    @if (gameplay.has_omni) {
                        <img class="w-16 py-2" [src]="'assets/icon/ftr/omni_scoring_logo.webp'" />
                    }
                    @if (gameplay.has_omni && gameplay.has_virt_cam) {
                        <div class="text-white leading-none">+</div>
                    }
                    @if (gameplay.has_virt_cam) {
                        <img class="w-16 py-2" [src]="'assets/icon/ftr/virt_cam_logo.webp'" />
                    }
                </div>
            }

            <div
                class="col-span-4 flex items-center justify-start space-x-2 cursor-pointer"
                (click)="showPlayerInfo(gameplay.players[0])">
                <div class="relative">
                    <app-profile-photo
                        class="h-fit"
                        [ultimate]="gameplay.players[0].is_ultimate"
                        [badge]="gameplay.players[0].badge"
                        [url]="gameplay.players[0].profile_photo_url"
                        [size]="32">
                    </app-profile-photo>
                    <div
                        class="absolute -bottom-1 inline-flex h-4 w-4 items-center justify-center bg-white dark:bg-black-full text-black dark:text-white p-0.5"
                        [ngClass]="{ '-left-1': gameplay.players[0].badge, '-right-1': !gameplay.players[0].badge }">
                        @if (gameplay.players[0].room) {
                            <app-icon icon="videocam" size="small"></app-icon>
                        } @else {
                            <app-icon icon="videocam_off" size="small" class="opacity-50 text-black"></app-icon>
                        }
                    </div>
                </div>

                <div class="space-y-0.5 overflow-hidden">
                    <div class="flex items-center space-x-1">
                        <div class="ucfirst truncate text-xs font-bold leading-none text-black">
                            {{ gameplay.players[0].first_name || ('NO_NAME' | translate) }}
                        </div>
                        <img
                            class="h-2"
                            *ngIf="gameplay.players[0].countryFlag"
                            src="assets/images/flags/rect/{{ gameplay.players[0].countryFlag }}" />
                    </div>
                    <div class="flex items-center">
                        <app-online-player-stats [player]="gameplay.players[0]"></app-online-player-stats>
                    </div>
                </div>
            </div>

            <div class="col-span-3 flex items-center justify-center font-bebas-neue">
                <app-match-interim-score size="lobby" [type]="gameplay.has_sets ? 'sets' : 'legs'" [showLabel]="true">
                    {{ onlineFunctions.getPublicInterim(gameplay) }}
                </app-match-interim-score>
            </div>

            <div
                class="col-span-4 flex items-center justify-end space-x-2 cursor-pointer"
                (click)="showPlayerInfo(gameplay.players[1])">
                <div class="space-y-0.5 overflow-hidden">
                    <div class="flex items-center justify-end space-x-1">
                        @if (gameplay.players[1].countryFlag) {
                            <img class="h-2" src="assets/images/flags/rect/{{ gameplay.players[1].countryFlag }}" />
                        }
                        <div class="ucfirst truncate text-xs font-bold leading-none text-black">
                            {{ gameplay.players[1].first_name || ('NO_NAME' | translate) }}
                        </div>
                    </div>
                    <div class="flex items-center justify-end">
                        <app-online-player-stats [player]="gameplay.players[1]"></app-online-player-stats>
                    </div>
                </div>
                <div class="relative">
                    <app-profile-photo
                        class="h-fit"
                        [ultimate]="gameplay.players[1].is_ultimate"
                        [badge]="gameplay.players[1].badge"
                        [url]="gameplay.players[1].profile_photo_url"
                        [size]="32">
                    </app-profile-photo>
                    <div
                        class="absolute -bottom-1 -left-1 inline-flex h-4 w-4 items-center justify-center bg-white dark:bg-black-full text-black dark:text-white p-0.5">
                        @if (gameplay.players[1].room) {
                            <app-icon icon="videocam" size="small"></app-icon>
                        } @else {
                            <app-icon icon="videocam_off" size="small" class="opacity-50 text-black"></app-icon>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
