<!-- Translation containers-->
@if (false) {
    <ng-container i18n="@@CANCEL">Cancel</ng-container>
}
<!-- End of translation containers-->
<ion-content fullscreen class="bg-white-pattern ion-text-black">
    <app-page-header mainNavigation="back" defaultBack="dashboard">
        <div title [translate]="'PAGE_SETTINGS'" i18n="@@PAGE_SETTINGS">Settings</div>
    </app-page-header>
    <div appWebContainer>
        <div class="flex max-w-xl mx-auto px-8 py-4 w-full flex-col text-black">
            <div
                class="flex items-center justify-between w-full py-3 border-neutral-100 dark:!border-neutral-700 border-b-1 cursor-pointer"
                (click)="showAccount()">
                <span [translate]="'ACCOUNT'" i18n="@@ACCOUNT">Account</span>
                <div class="flex items-center">
                    @if (!auth.user?.username || !auth.user?.profile?.birthdate || !auth.user?.email_verified_at) {
                        <div class="h-2.5 w-2.5 rounded-full bg-yellow-icon-alert mr-2"></div>
                    }
                    <app-icon icon="arrow_forward_ios" size="small" class="text-neutral-400"></app-icon>
                </div>
            </div>
            <div
                class="flex items-center justify-between w-full py-3 border-neutral-100 dark:!border-neutral-700 border-b-1 cursor-pointer"
                (click)="subscriptions()">
                <span [translate]="'SUBSCRIPTIONS'" i18n="@@SUBSCRIPTIONS">Subscriptions</span>
                <app-icon icon="arrow_forward_ios" size="small" class="text-neutral-400"></app-icon>
            </div>
            <div
                class="flex items-center justify-between w-full py-3 border-neutral-100 dark:!border-neutral-700 border-b-1 cursor-pointer"
                (click)="showLanguages()">
                <span [translate]="'LANGUAGE'" i18n="@@LANGUAGE">Language</span>
                <app-icon icon="arrow_forward_ios" size="small" class="text-neutral-400"></app-icon>
            </div>
            <div
                class="flex items-center justify-between w-full py-3 border-neutral-100 dark:!border-neutral-700 border-b-1 cursor-pointer"
                (click)="changeTheme()">
                <span [translate]="'THEME'" i18n="@@THEME">Theme</span>
                <app-icon icon="arrow_forward_ios" size="small" class="text-neutral-400"></app-icon>
            </div>
            <div
                class="flex items-center justify-between w-full py-3 border-neutral-100 dark:!border-neutral-700 border-b-1 cursor-pointer"
                (click)="showGlobalGameSettings()">
                <span [translate]="'GAME_SETTINGS'" i18n="@@GAME_SETTINGS">Game settings</span>
                <app-icon icon="arrow_forward_ios" size="small" class="text-neutral-400"></app-icon>
            </div>
            <div
                class="flex items-center justify-between w-full py-3 border-neutral-100 dark:!border-neutral-700 border-b-1 cursor-pointer"
                (click)="blockedPlayers()">
                <span [translate]="'BLOCKED_PLAYERS'" i18n="@@BLOCKED_PLAYERS">Blocked players</span>
                <app-icon icon="arrow_forward_ios" size="small" class="text-neutral-400"></app-icon>
            </div>
            <div
                class="flex items-center justify-between w-full py-3 border-neutral-100 dark:!border-neutral-700 border-b-1 cursor-pointer"
                (click)="ShowAccountReset()">
                <span [translate]="'RESET_ACCOUNT'" i18n="@@RESET_ACCOUNT">Reset statistics</span>
                <app-icon icon="arrow_forward_ios" size="small" class="text-neutral-400"></app-icon>
            </div>
            <div
                class="flex items-center justify-between w-full py-3 border-neutral-100 dark:!border-neutral-700 border-b-1 cursor-pointer"
                (click)="OpenAgreementDialog()">
                <span [translate]="'TERMS_OF_SERVICE'" i18n="@@TERMS_OF_SERVICE">Terms of Service</span>
                <app-icon icon="arrow_forward_ios" size="small" class="text-neutral-400"></app-icon>
            </div>
            <div
                class="flex items-center justify-between w-full py-3 border-neutral-100 dark:!border-neutral-700 border-b-1 cursor-pointer"
                (click)="OpenPolicyDialog()">
                <span [translate]="'PRIVACY_POLICY'" i18n="@@PRIVACY_POLICY">Privacy policy</span>
                <app-icon icon="arrow_forward_ios" size="small" class="text-neutral-400"></app-icon>
            </div>
            <div
                class="flex items-center justify-between w-full py-3 ion-text-orange-accent dark:ion-text-yellow cursor-pointer"
                (click)="Logout()">
                <span [translate]="'LOGOUT'" i18n="@@LOGOUT">Logout</span>
                <app-icon icon="logout" size="small"></app-icon>
            </div>
        </div>
    </div>
</ion-content>
