import { DC_Environment, SUPPORTED_ENVIRONMENTS } from './environment.models';

export const environment: DC_Environment = {
    isWeb: true,
    local: false,
    debug: true,
    production: false,
    apiUrl: 'https://api.staging.prod.k8s.dc.managed.argo-ict.net',
    stage: SUPPORTED_ENVIRONMENTS.STAGING,
    firebase: {
        apiKey: 'AIzaSyC2M8xROcl9mOozAvu4sgb68KPPHPP3Gv4',
        authDomain: 'dartcounter-nosql---staging.firebaseapp.com',
        projectId: 'dartcounter-nosql---staging',
        storageBucket: 'dartcounter-nosql---staging.appspot.com',
        messagingSenderId: '3040993988',
        measurementId: 'G-C29RC4YG2X',
        appId: '1:3040993988:web:c877c8b8f93be288f41f91',
    },
    echo: {
        key: 'gw15jtm74slpgmfwyw3a',
        wsHost: 'wss.staging.prod.k8s.dc.managed.argo-ict.net',
        wsPort: 443,
        wssPort: 443,
        scheme: 'https',
    },
    chromecast: {
        receiverApplicationId: '2B7D4C6C',
    },
    recaptcha: '6Ld0Zj8lAAAAAExqwkT4H9hPUwbX97k3_z8PdIXG',
};

export const socials = {
    facebook: {
        clientId: '224780451025678',
    },
    apple: {
        clientId: 'com.dartcounter.ios',
        redirectUrl: 'https://dartcounter.net',
    },
    google: {
        clientId: '1031691752383-lmrthmuhotrgj1pk6o7n7fk1kcve4m65.apps.googleusercontent.com',
    },
};
