<div class="flex items-center w-full justify-center">
    <div class="flex items-start" dragula="PLAYERS" [(dragulaModel)]="users">
        @for (user of users; track user; let userIndex = $index) {
            <div class="basis-1/4 p-4">
                <div class="flex flex-col items-center">
                    <div class="new-game-player-container">
                        <app-profile-photo
                            class="h-fit cursor-pointer"
                            [size]="52"
                            [ultimate]="user.is_ultimate"
                            [badge]="user.profile?.badge"
                            [url]="user.profile?.profile_photo_url"
                            (click)="showPlayerOptions(user, userIndex)">
                        </app-profile-photo>
                        @if (humanUsers().length > 1 || user.is_cpu) {
                            <div class="circle cursor-pointer" (click)="removePlayer(userIndex)">
                                <app-icon icon="close" class="text-white" size="small"></app-icon>
                            </div>
                        }
                        @if (auth.user && user.is_ultimate && user.profile?.sound) {
                            <div class="circle high orange cursor-pointer" (click)="showPlayerOptions(user, userIndex)">
                                <ion-icon name="volume-high-outline" color="light"></ion-icon>
                            </div>
                        }
                        @if (auth.user && user.is_ultimate && !user.profile?.sound) {
                            <div class="circle high orange cursor-pointer" (click)="showPlayerOptions(user, userIndex)">
                                <ion-icon name="volume-mute" color="light"></ion-icon>
                            </div>
                        }
                    </div>
                    <div
                        class="mt-1 text-xs font-bold text-neutral-900"
                        [ngClass]="{ 'max-w-16 truncate': !user.is_cpu, 'whitespace-nowrap': user.is_cpu }">
                        {{ user.first_name || ('NO_NAME' | translate) }}
                    </div>
                </div>
            </div>
        }

        @if (users.length < 4) {
            <div class="no-drag inline-flex basis-1/4 justify-center px-4 pt-5">
                <div
                    class="inline-flex h-[3.25rem] w-[3.25rem] items-center justify-center rounded-full border-2 border-neutral-50 cursor-pointer"
                    (click)="addPlayer()">
                    <app-icon icon="add" size="large" class="text-neutral-400"></app-icon>
                </div>
            </div>
        }
    </div>
</div>
