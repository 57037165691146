import { Component, OnInit } from '@angular/core';
import { SubscriptionApiService } from '@dc-api/subscription.api.service';
import { SubscriptionUpgradeReason, UpgradeReasonAnswer } from '@dc-core/dc-backend/dc-classes';
import { ModalController, Platform } from '@ionic/angular';
import { DartCounterAlertService } from 'dc-core/dc-services/alert.service';
import { AuthService } from 'src/services/auth.service';

type ExtendedSubscriptionUpgradeReason = SubscriptionUpgradeReason & { selected?: boolean; explanation?: string };

@Component({
    selector: 'app-dialog-send-upgrade-reasons',
    templateUrl: 'send-upgrade-reasons.html',
    styleUrls: ['send-upgrade-reasons.scss'],
})
export class SendUpgradeReasonsDialogComponent implements OnInit {
    public reasonPlaceholder: string = $localize`:@@YOUR_REASON:Your reason`;
    public upgradeReasons: ExtendedSubscriptionUpgradeReason[] = [];

    constructor(
        public view: ModalController,
        public auth: AuthService,
        public modal: ModalController,
        public platform: Platform,
        public alertService: DartCounterAlertService,
        public subscriptionApiService: SubscriptionApiService
    ) {}

    ngOnInit(): void {
        this.subscriptionApiService.getSubscriptionUpgradeReasons({}).then((res) => {
            this.upgradeReasons = res.data;
            if (!this.upgradeReasons.length) {
                this.close();
            }
        });
    }

    toggleAnswer(reason: ExtendedSubscriptionUpgradeReason): void {
        if (reason.selected) {
            reason.selected = false;
        } else {
            reason.selected = true;
        }
    }

    public close(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }

    public send(): void {
        const answers: UpgradeReasonAnswer[] = [];
        this.upgradeReasons.forEach((upgradeReason) => {
            if (upgradeReason.selected) {
                answers.push({
                    upgrade_reason_id: upgradeReason.id,
                    user_id: null,
                    subscription_id: null,
                    explanation: upgradeReason.explanation,
                });
            }
        });

        this.subscriptionApiService
            .sendSubscriptionUpgradeReasons({
                answers,
            })
            .then(async () => {
                this.alertService.createAlert({
                    title: $localize`:@@SENT:Sent`,
                    icon: 'success',
                });

                this.close(true);
            })
            .catch((err) => this.alertService.errorFromApi(err));
    }
}
