<!-- Translation containers-->
@if (false) {
    <ng-container i18n="@@NAME_OF_PLAYER">Player name</ng-container>
    <ng-container i18n="@@USERNAME">Username</ng-container>
    <ng-container i18n="@@PASSWORD">Password</ng-container>
}
<!-- End of translation containers-->

<modal-content fullscreen="true" class="bg-white-pattern text-black">
    <app-page-header mainNavigation="custom" color="transparent">
        <div title [translate]="'SEARCH_PLAYER'" i18n="@@SEARCH_PLAYER">Search player</div>
        <div right class="right-page-header">
            <app-icon icon="close" (click)="DismissModal()"></app-icon>
        </div>
    </app-page-header>

    <div class="dcplayerList" name="friend" ngDefaultControl>
        <ion-searchbar
            class="!p-0 text-black"
            name="searchString"
            ngDefaultControl
            [(ngModel)]="searchString"
            (ionChange)="searchUsers(searchString)"
            debounce="500"></ion-searchbar>

        <ion-row *ngIf="searchLoading" style="background: transparent">
            <ion-col class="text-center">
                <ion-spinner name="crescent"></ion-spinner>
            </ion-col>
        </ion-row>

        <div *ngIf="users?.length" class="scroll-users">
            <ion-row *ngFor="let user of users" (click)="DismissModal(user)">
                <ion-col size="3">
                    <ion-avatar>
                        <app-profile-photo
                            [size]="36"
                            [ultimate]="user.is_ultimate"
                            [url]="user.profile?.profile_photo_url"></app-profile-photo>
                    </ion-avatar>
                </ion-col>
                <ion-col class="text">{{ user.full_name }}</ion-col>
            </ion-row>
        </div>
    </div>
</modal-content>
