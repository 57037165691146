import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { OnlineGameplay } from '@dc-core/dc-backend/dc-interfaces';
import { LobbySettingsCollectionService } from '@dc-core/dc-firestore/collection-helpers/lobby_settings.collection.service';
import { DCFireStoreUser } from '@dc-core/dc-firestore/globals/firestore.tables';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { UserMedia } from '@dc-core/dc-services/dc-janus/DartCounterUserMedia';
import { JanusVideoRoomService } from '@dc-core/dc-services/dc-janus/janus-video-room.service';
import { ModalController, Platform } from '@ionic/angular';
import { Unsubscribe } from 'firebase/auth';
import { DocumentSnapshot } from 'firebase/firestore';
import { Subscription } from 'rxjs';

export type CameraPreviewDialogPayload = {
    onlineGameplay: OnlineGameplay;
    user: DCFireStoreUser;
    isOwnCamera: boolean;
};

@Component({
    selector: 'app-camera-preview-dialog',
    templateUrl: 'camera-preview.dialog.html',
})
export class CameraPreviewDialogComponent implements OnInit, OnDestroy {
    @Input() onlineGameplay: OnlineGameplay;
    @Input() user: DCFireStoreUser;
    @Input() isOwnCamera: boolean;
    userMedia = new UserMedia();

    private _backButtonSubscription: Subscription;
    private _unsubscribeLobbyGame: Unsubscribe;

    constructor(
        public view: ModalController,
        private _platform: Platform,
        private _alertService: DartCounterAlertService,
        private _videoRoomService: JanusVideoRoomService,
        private _lobbySettingsService: LobbySettingsCollectionService
    ) {}

    ngOnInit(): void {
        this._backButtonSubscription = this._platform.backButton.subscribeWithPriority(9999, () => {
            this.close();
        });

        this._unsubscribeLobbyGame = this._lobbySettingsService.watchDoc(
            this.onlineGameplay.doc_id,
            (docSnapshot: DocumentSnapshot<OnlineGameplay>) => {
                const gameplay = docSnapshot.data();

                if (!gameplay) {
                    this._alertService.createAlert({
                        icon: 'warning',
                        title: $localize`:@@LOBBY_MATCH_REMOVED:The game is removed from the lobby`,
                    });
                    this.close();
                }
            }
        );

        if (this.isOwnCamera) {
            this.userMedia = this._videoRoomService.ownUserMedia;
        } else {
            const janusRoom = this.user.room;
            this._videoRoomService
                .spectateRoom(janusRoom, 'video', this.user, this.userMedia, false, false, true)
                .catch((error) => {
                    console.error('Error joining room:', error);
                });
        }
    }

    async ngOnDestroy(): Promise<void> {
        if (this._backButtonSubscription) {
            this._backButtonSubscription.unsubscribe();
        }

        if (this._unsubscribeLobbyGame) {
            this._unsubscribeLobbyGame();
        }

        if (this.user.room.roomID && !this.isOwnCamera) {
            await this._videoRoomService.leaveRoomAndDetachAllHandles(this.user.room.roomID, true).catch(console.error);
            this.userMedia.cleanupUserMedia(true, true);
        }
    }

    public close(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }
}
