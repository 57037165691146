import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    input,
    InputSignal,
    OnInit,
    Output,
} from '@angular/core';
import { DropdownSelectItem } from '@dc-core/dc-statistics/statistics.globals';
import { ModalController } from '@ionic/angular';
import { UpgradeService } from '@providers/UpgradeService';
import { AuthService } from '@services/auth.service';
import _ from 'lodash';
import { GA_EVENTCATEGORIES } from 'src/app/app.globals';

@Component({
    selector: 'app-select',
    templateUrl: './app-select.component.html',
    styleUrls: ['./app-select.component.scss'],
})
export class AppSelectComponent implements OnInit {
    public relativePositioning: InputSignal<boolean> = input(true);
    @Input() title: string;
    @Input() i18n: string;
    @Input() options: DropdownSelectItem[] = [];
    @Input() mode: 'white' | 'dialog-input' = 'white';
    @Input() size: string;
    @Input() titleColor: string;
    @Input() initialValue: string;
    @Input() error = false;
    @Output() selectionChange = new EventEmitter<any>();

    public activeOption: any;
    public showOptions = false;
    public isUltimate: boolean;

    constructor(
        private auth: AuthService,
        public modal: ModalController,
        private upgrade: UpgradeService,
        private _elementRef: ElementRef,
        private _changeDetectorRef: ChangeDetectorRef
    ) {}

    @HostListener('document:click', ['$event.target'])
    onClick(targetElement: HTMLElement): void {
        if (!targetElement) {
            return;
        }

        const clickedInside = this._elementRef.nativeElement.contains(targetElement);
        if (!clickedInside && this.showOptions) {
            this.showOptions = false;
            this._changeDetectorRef.detectChanges();
        }
    }

    ngOnInit(): void {
        if (this.initialValue) {
            this.activeOption = _.find(this.options, (option) => option.value === this.initialValue);
        } else {
            this.activeOption = this.options[0];
        }

        if (this.auth.user.is_ultimate) {
            this.isUltimate = true;
        }
    }

    emitValue(selectedValue: any) {
        const option = _.find(this.options, { value: selectedValue });
        if (option.ultimateOnly && !this.auth.user.is_ultimate) {
            this.checkUltimate();
            return false;
        }
        this.selectionChange.emit(selectedValue);
        this.activeOption = option;
        this.toggleOptions();
    }

    async checkUltimate() {
        const dialogSettings = await this.upgrade.GetUpgradeDialog(GA_EVENTCATEGORIES.STATISTICS);
        this.modal.create(dialogSettings).then((elem) => {
            elem.present();
        });
    }

    toggleOptions() {
        this.showOptions = !this.showOptions;
    }
}
