<app-page-header mainNavigation="menu">
    <div title [translate]="'LINK_YOUR_CAMERA'" i18n="@@LINK_YOUR_CAMERA">Link your camera</div>
</app-page-header>

<div class="relative flex h-full flex-col bg-white-pattern overflow-y-auto p-4">
    <div class="flex flex-col" appWebContainer>
        <div class="mt-8 mb-8 flex justify-center text-center text-lg text-black">
            <span *ngIf="!janusRoom" i18n="@@LINK_YOUR_CAMERA_DESC_1">
                Link the camera from this device. Stream it and enter the digits to link it in the DartCounter app!
            </span>
            <span
                *ngIf="videoRoomService.ownUserMedia?.videoStreams?.hasStreams && videoRoomService.externalCode"
                i18n="@@LINK_YOUR_CAMERA_DESC_2">
                Enter the code and password in the DartCounter app to link this stream!
            </span>
        </div>

        <div class="bg-white rounded-lg p-4 space-y-4">
            @if (!janusRoom) {
                <div class="flex items-center justify-between">
                    <select
                        class="dialog-input w-full"
                        *ngIf="userMediaService.selectRearCam && userMediaService.rearCameraDevices.length"
                        [(ngModel)]="userMediaService.selectedRearVideoDevice"
                        (change)="userMediaService.updatePreview(videoRoomService.ownUserMedia)">
                        <option *ngFor="let device of userMediaService.rearCameraDevices" [value]="device.deviceId">
                            {{ device.humanReadable || device.label }}
                        </option>
                    </select>
                    <select
                        class="dialog-input w-full"
                        *ngIf="!userMediaService.selectRearCam"
                        [(ngModel)]="userMediaService.selectedFaceVideoDevice"
                        (change)="userMediaService.updatePreview(videoRoomService.ownUserMedia)">
                        <option *ngFor="let device of userMediaService.faceCameraDevices" [value]="device.deviceId">
                            {{ device.humanReadable || device.label }}
                        </option>
                    </select>
                    @if (userMediaService.faceCameraDevices?.length || !userMediaService.selectRearCam) {
                        <app-icon
                            icon="camera_reverse_outline"
                            class="ml-4 mr-2"
                            size="medium"
                            class="text-black"
                            (click)="userMediaService.reverseCamera(videoRoomService.ownUserMedia)"></app-icon>
                    }
                </div>
            }

            <!-- Local video preview element -->
            <div class="widget-group" *ngIf="videoRoomService.ownUserMedia?.videoStreams?.hasStreams && janusRoom">
                <div class="widget cameraInfo">
                    <div *ngIf="videoRoomService.externalCode && videoRoomService.externalPassword">
                        <div class="dcSegmentedInputWrapper">
                            <div
                                class="text-base font-bold uppercase text-black font-open-sans text-center"
                                [translate]="'CODE'"
                                i18n="@@CODE">
                                Code
                            </div>
                            <div class="segmentContainer">
                                <div class="inputSegment">
                                    <div class="character">
                                        {{ videoRoomService.externalCode?.toString()[0] || 1 }}
                                    </div>
                                </div>
                                <div class="inputSegment">
                                    <div class="character">
                                        {{ videoRoomService.externalCode?.toString()[1] || 2 }}
                                    </div>
                                </div>
                                <div class="inputSegment">
                                    <div class="character">
                                        {{ videoRoomService.externalCode?.toString()[2] || 3 }}
                                    </div>
                                </div>
                                <div class="inputSegment">
                                    <div class="character">
                                        {{ videoRoomService.externalCode?.toString()[3] || 4 }}
                                    </div>
                                </div>
                            </div>

                            <input
                                class="hide"
                                name="Code"
                                maxlength="4"
                                ngDefaultControl
                                [(ngModel)]="videoRoomService.externalCode"
                                type="number" />
                        </div>

                        <div class="dcSegmentedInputWrapper">
                            <div
                                class="text-base font-bold uppercase text-black font-open-sans text-center"
                                [translate]="'PASSWORD'"
                                i18n="@@PASSWORD">
                                Password
                            </div>
                            <div class="segmentContainer">
                                <div class="inputSegment">
                                    <div class="character">
                                        {{ videoRoomService.externalPassword?.toString()[0] || 'A' }}
                                    </div>
                                </div>
                                <div class="inputSegment">
                                    <div class="character">
                                        {{ videoRoomService.externalPassword?.toString()[1] || 'B' }}
                                    </div>
                                </div>
                                <div class="inputSegment">
                                    <div class="character">
                                        {{ videoRoomService.externalPassword?.toString()[2] || 'C' }}
                                    </div>
                                </div>
                                <div class="inputSegment">
                                    <div class="character">
                                        {{ videoRoomService.externalPassword?.toString()[3] || 'D' }}
                                    </div>
                                </div>
                            </div>
                            <input
                                class="hide"
                                name="Password"
                                maxlength="4"
                                ngDefaultControl
                                [(ngModel)]="videoRoomService.externalPassword"
                                name="password"
                                type="text" />
                        </div>
                    </div>

                    <div class="flex justify-center mt-2">
                        @if (videoRoomService.externalCode && videoRoomService.externalPassword) {
                            <app-primary-button
                                size="small"
                                (click)="disableCode()"
                                [translate]="'DISABLE_CODE'"
                                i18n="@@DISABLE_CODE">
                                Disable code
                            </app-primary-button>
                        }

                        @if (!videoRoomService.externalCode && !videoRoomService.externalPassword) {
                            <app-primary-button
                                size="small"
                                (click)="generateCredentials()"
                                [translate]="'GET_CODE'"
                                i18n="@@GET_CODE">
                                Get code
                            </app-primary-button>
                        }
                    </div>
                </div>
            </div>

            <div class="widget-group" *ngIf="videoRoomService.ownUserMedia?.videoStreams?.hasStreams">
                <div class="fuse-widget-front centerFlex mt-2">
                    <div class="targetVideo space-y-1">
                        <!-- Local video element for displaying the video stream -->
                        <div class="boardCam" *ngIf="videoRoomService.ownUserMedia.videoStreams.board">
                            <div
                                class="stream-paused-overlay"
                                *ngIf="!videoRoomService.ownUserMedia.videoStreams.board.isActive">
                                <div i18n="@@CAMERA_PAUSED">Camera paused</div>
                            </div>
                            <div class="big">
                                <video
                                    [srcObject]="videoRoomService.ownUserMedia.videoStreams.board.stream"
                                    [style.transform]="videoRoomService.ownUserMedia.videoStreams.board.scale"
                                    autoplay
                                    playsinline
                                    webkit-playsinline
                                    muted></video>
                            </div>
                        </div>
                        <!-- Local audio element for processing the audio stream (hidden, no playback) -->
                        <!-- <audio
                                *ngIf="videoRoomService.ownUserMedia?.audioStream && userMediaService.isAudioEnabled"
                                #localAudioElem
                                [srcObject]="videoRoomService.ownUserMedia.audioStream"
                                autoplay
                                [muted]="userMediaService.isAudioMuted"></audio> -->
                    </div>
                </div>
            </div>

            <div>
                @if (
                    userMediaService.isZoomSupported === true ||
                    (userMediaService.isZoomSupported === false &&
                        videoRoomService.ownUserMedia?.videoStreams?.hasStreams &&
                        !janusRoom)
                ) {
                    <div class="overflow-none mt-4 overscroll-none">
                        <div class="px-2 text-xl font-bold uppercase text-black" [translate]="'ZOOM'" i18n="@@ZOOM">
                            Zoom
                        </div>
                        <ion-range
                            class="flex-none py-0"
                            [min]="userMediaService.camCapabilities.zoom.min"
                            [max]="userMediaService.camCapabilities.zoom.max"
                            [step]="userMediaService.camCapabilities.zoom.step"
                            name="zoomLevel"
                            ngDefaultControl
                            [(ngModel)]="userMediaService.zoomLevel"
                            (ionChange)="userMediaService.updateCamSettings(videoRoomService.ownUserMedia)"
                            color="secondary">
                        </ion-range>
                    </div>
                }
            </div>

            @if (!videoRoomService.ownUserMedia?.videoStreams?.hasStreams) {
                <div class="flex justify-center">
                    <ion-spinner class="h-16 w-16" color="secondary" name="crescent"></ion-spinner>
                </div>
            }

            @if (videoRoomService.ownUserMedia?.videoStreams?.hasStreams && !janusRoom) {
                <div class="flex justify-center pt-8">
                    <app-primary-button
                        class="w-full"
                        addedClasses="w-full"
                        size="normal"
                        (click)="startPublishing()"
                        [translate]="'START_STREAM'"
                        i18n="@@START_STREAM">
                        Start stream
                    </app-primary-button>
                </div>
            }
        </div>
    </div>
</div>
