import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Instagram } from '@awesome-cordova-plugins/instagram/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { ModalController, NavParams, Platform } from '@ionic/angular';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { DartCounterPreferenceService } from 'dc-core/dc-services/preference/preference.service';
import { GA_EVENTACTIONS, GA_EVENTCATEGORIES } from 'src/app/app.globals';
import { DCLoadingService } from 'src/providers/DCLoadingService';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-social-share-dialog',
    templateUrl: 'socialshare.html',
})
export class SocialShareDialogComponent {
    public matchId: number;

    public squareSharable: string = null;
    public portraitSharable: string = null;

    public loaded = false;

    constructor(
        public view: ModalController,
        public modal2: ModalController,
        public auth: AuthService,
        private ga: DartCounterAnalyticsService,
        private route: ActivatedRoute,
        private platform: Platform,
        public preferenceService: DartCounterPreferenceService,
        private socialSharing: SocialSharing,
        private loading: DCLoadingService,
        private instagram: Instagram,
        private navParams: NavParams,
        private alertService: DartCounterAlertService
    ) {
        this.matchId = this.navParams.get('matchId');
        this.squareSharable = this.navParams.get('squareSharable');
        this.portraitSharable = this.navParams.get('portraitSharable');
    }

    ionViewDidEnter(): void {
        if (!this.squareSharable) {
            this.getImage('square')
                .then((shareable: any) => {
                    this.squareSharable = shareable;
                })
                .catch(() => {
                    this.loading.DismissLoader();
                });
        }

        if (!this.portraitSharable) {
            this.getImage('portrait')
                .then((shareable: any) => {
                    this.portraitSharable = shareable;
                })
                .catch(() => {
                    this.loading.DismissLoader();
                });
        }
    }

    public DismissModal(): void {
        this.view.dismiss({
            squareSharable: this.squareSharable,
            portraitSharable: this.portraitSharable,
        });
    }

    share(format: 'square' | 'portrait'): void {
        this.loading.ShowDefaultLoader();

        setTimeout(() => {
            let image = null;
            switch (format) {
                case 'square':
                    image = this.squareSharable;
                    break;
                case 'portrait':
                    image = this.portraitSharable;
                    break;
            }

            this.socialSharing
                .share(null, 'Match result', image)
                .then(() => {
                    // Success!
                    this.ga.trackEvent(GA_EVENTCATEGORIES.SHAREMATCH, GA_EVENTACTIONS.SHARESOCIAL, 'shared');
                    this.DismissModal();
                })
                .catch(() => {
                    this.ga.trackEvent(GA_EVENTCATEGORIES.SHAREMATCH, GA_EVENTACTIONS.SHARESOCIAL, 'cancelled');
                })
                .finally(() => this.loading.DismissLoader());
        }, 200);
    }

    getImage(format: 'square' | 'portrait') {
        return new Promise((resolve, reject) => {
            this.auth.fetcher
                .path('/matches/{matchId}/share')
                .method('get')
                .create()({ matchId: this.matchId, format, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone })
                .then((res) => {
                    resolve('data:image/png;base64,' + res.data.base64);
                })
                .catch(() => reject());
        });
    }
}
