import { Component, inject } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IonicModule } from '@ionic/angular';
import { SelectableTheme, ThemeService } from '@services/theme.service';

@Component({
    selector: 'app-theme-preference-modal',
    templateUrl: './theme-preference-modal.component.html',
    standalone: true,
    imports: [IonicModule],
})
export class ThemePreferenceModalComponent {
    public themeService: ThemeService = inject(ThemeService);

    private modalController: ModalController = inject(ModalController);

    dismiss() {
        this.modalController.dismiss();
    }

    changeTheme(theme: SelectableTheme) {
        this.themeService.changeTheme(theme);
    }
}
