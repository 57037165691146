import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'chatDate',
    standalone: true,
})
export class ChatDatePipe implements PipeTransform {
    transform(value: string): string {
        const today = moment().utc().startOf('day').toDate();
        const inputDate = moment(value).startOf('day').toDate();
        return this.getDisplayText(today, inputDate, value);
    }

    private getDisplayText(today: Date, inputDate: Date, value: string): string {
        const oneDay = 24 * 60 * 60 * 1000; // milliseconds in a day
        const diffDays = Math.floor((today.getTime() - inputDate.getTime()) / oneDay);

        if (diffDays === 0) {
            $localize`:@@TODAY:Today`;
            return moment(value).format('HH:mm');
        } else if (diffDays === 1) {
            return $localize`:@@YESTERDAY:Yesterday`;
        } else {
            return moment(inputDate).format('D MMM yyyy'); // format date as per your requirement
        }
    }
}
