import { Component, DestroyRef, inject, input, InputSignal, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OnlineGameplay } from '@dc-core/dc-backend/dc-interfaces';
import { ActiveGamesCollectionService } from '@dc-core/dc-firestore/collection-helpers/active_games.collection.service';
import { PausesCollectionService } from '@dc-core/dc-firestore/collection-helpers/pauses.collection.service';
import { DCFireStorePause } from '@dc-core/dc-firestore/globals/firestore.tables';
import { DCOnlineCore } from '@dc-core/dc-gamelogic/online-core.functions';
import { InGameCameraService } from '@dc-core/dc-services/camera/ingame-camera.service';
import { JanusVideoRoomService } from '@dc-core/dc-services/dc-janus/janus-video-room.service';
import { ModalController } from '@ionic/angular';
import { ModalOptions } from '@ionic/core';
import { AuthService } from '@services/auth.service';
import { DocumentReference, DocumentSnapshot, Timestamp } from 'firebase/firestore';
import moment from 'moment';
import { Subject } from 'rxjs';
import { AppFeaturesService } from 'src/app/core/app-features/services/app-features.service';
import { PrimaryButtonComponent } from 'src/app/primary-button/primary-button.component';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';
import { InputFocusManagerService } from 'src/app/shared/services/input-focus-manager.service';
import { AddBreakDialogComponent } from 'src/pages/online/addBreakDialog/addBreakDialog';

@Component({
    selector: 'app-games-online-pause',
    standalone: true,
    imports: [PrimaryButtonComponent, IconComponent],
    templateUrl: './games-online-pause.component.html',
    styleUrl: './games-online-pause.component.css',
})
export class GamesOnlinePauseComponent implements OnInit, OnDestroy {
    public authenticatedUserUUID: InputSignal<string> = input.required<string>();
    public currentUserId: InputSignal<number> = input.required<number>();
    public liveGameplay: InputSignal<OnlineGameplay> = input.required<OnlineGameplay>();
    public pauseTrigger: InputSignal<Subject<void>> = input<Subject<void>>();
    public isSpectator: InputSignal<boolean> = input<boolean>(false);

    public onlineCore: DCOnlineCore = inject(DCOnlineCore);

    private activeGamesCollectionService: ActiveGamesCollectionService = inject(ActiveGamesCollectionService);
    private pausesCollectionService: PausesCollectionService = inject(PausesCollectionService);
    private authService: AuthService = inject(AuthService);
    private modalController: ModalController = inject(ModalController);
    private ingameCameraService: InGameCameraService = inject(InGameCameraService);
    private inputFocusManagerService: InputFocusManagerService = inject(InputFocusManagerService);
    private videoRoomService: JanusVideoRoomService = inject(JanusVideoRoomService);
    private appFeaturesService: AppFeaturesService = inject(AppFeaturesService);

    private pauseDialog: HTMLIonModalElement;
    private destroyRef: DestroyRef = inject(DestroyRef);

    public authenticatedUserId = this.authService.user.id;

    public ngOnInit(): void {
        this.activeGamesCollectionService.activeGameRef$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((gameRef: DocumentReference<OnlineGameplay> | null) => {
                if (gameRef) {
                    this.initPausingHandler(gameRef, this.authenticatedUserUUID());
                }
            });

        if (this.pauseTrigger()) {
            this.pauseTrigger()
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe(() => this.togglePause());
        }
    }

    public ngOnDestroy(): void {
        this.pausesCollectionService.disablePauses();
        if (this.pauseDialog) this.pauseDialog.dismiss();
    }

    private togglePause(): void {
        this.modalController
            .create({
                component: AddBreakDialogComponent,
                componentProps: {
                    minuteOptions: [1, 3, 5],
                },
                cssClass: 'breakDialog',
                showBackdrop: true,
            } as ModalOptions)
            .then((elem) => {
                this.pauseDialog = elem;
                this.pauseDialog.present();
                this.pauseDialog.onDidDismiss().then((dialogRes) => {
                    const pauseTime = dialogRes.data;
                    if (pauseTime > 0) {
                        this.startPause(pauseTime);
                    }
                });
            });
    }

    private initPausingHandler(gameRef: DocumentReference<OnlineGameplay>, authenticatedUserUuid: string): void {
        this.pausesCollectionService.enablePauseFeature(gameRef, authenticatedUserUuid);

        this.pausesCollectionService.pauses$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((activePause: DCFireStorePause) => {
                if (activePause.break_ends_at > Timestamp.now() && activePause.creator_uid != authenticatedUserUuid) {
                    this.onlineCore.pauseTimer();
                    this.onlineCore.timeLeft = this.liveGameplay().inGameTimer;

                    this.onlineCore.unsubscribeActivePause = this.pausesCollectionService.watchDoc(
                        activePause.doc_id,
                        (docSnapshot: DocumentSnapshot<DCFireStorePause>) => {
                            const pause = docSnapshot.data();
                            if (pause) {
                                pause.doc_id = activePause.doc_id;
                                pause.doc_ref = docSnapshot.ref;
                                this.onlineCore.pause = pause;
                                if (pause.completed) {
                                    this.onlineCore.resetPause(pause.completed_at);
                                    this.startTimer(this.currentUserId(), this.authenticatedUserId);
                                } else {
                                    this.onlineCore.initPauseTimer();
                                    this.onlineCore.checkPauseReadyPlayers();
                                }
                            }
                        }
                    );
                }
            });
    }

    private startPause(pauseTime: number): void {
        // Reset is_ready to use it for the new Break
        this.liveGameplay().players.forEach((player) => {
            player.is_ready = false;
        });

        const newPause: DCFireStorePause = {
            creator_uid: this.authenticatedUserUUID(),
            owners: this.liveGameplay().owners,
            players: this.liveGameplay().players,
            minutes: pauseTime,
            break_ends_at: Timestamp.fromDate(moment().add(pauseTime, 'minutes').toDate()),
            completed: false,
        };

        this.pausesCollectionService.addItem(newPause).then((pauseDocRef) => {
            this.onlineCore.pauseTimer();
            this.onlineCore.timeLeft = this.liveGameplay().inGameTimer;

            this.onlineCore.unsubscribeActivePause = this.pausesCollectionService.watchDoc(
                pauseDocRef.id,
                (docSnapshot: DocumentSnapshot<DCFireStorePause>) => {
                    const pause = docSnapshot.data();
                    if (pause) {
                        pause.doc_id = pauseDocRef.id;
                        pause.doc_ref = docSnapshot.ref;
                        this.onlineCore.pause = pause;
                        this.onlineCore.initPauseTimer();
                        if (pause.completed) {
                            this.onlineCore.resetPause(pause.completed_at);
                            this.startTimer(this.currentUserId(), this.authenticatedUserId);
                            this.pausesCollectionService.deletePauseSubcollection();
                            this.inputFocusManagerService.triggerFocusInput();

                            if (this.appFeaturesService.enabledAppFeatures.action_replays) {
                                this.ingameCameraService.startRecording(
                                    this.authenticatedUserId,
                                    'clip',
                                    this.videoRoomService.ownCamera.camType
                                );
                            }
                        } else {
                            this.onlineCore.checkPauseReadyPlayers();

                            if (this.appFeaturesService.enabledAppFeatures.action_replays) {
                                this.ingameCameraService
                                    .stopRecording(this.authenticatedUserId, false)
                                    .catch(console.error);
                            }
                        }
                    }
                }
            );
        });
    }

    private startTimer(currentUserId: number, authenticatedUserId: number): void {
        if (!this.onlineCore.isSpectating) {
            this.onlineCore.startTimer(currentUserId === authenticatedUserId);
        }
    }
}
